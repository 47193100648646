export const notificationRedirect = ({
  entity_id,
  entity_type,
  additional_data,
  notification_key,
  navigate,
}) => {
  if (entity_type === "ExchangeForum") {
    // forum_comment_created, forum_created, vote_created, forum_comment_threshold, forum_liked_threshold
    navigate(`/exchange/${entity_id}/discuss`);
    return;
  }
  if (entity_type === "Post") {
    // new_post
    if (additional_data.post_type === "client_connect") {
      navigate(`/nfp-clientconnect`);
      return;
    }
    if (additional_data.post_type === "resource") {
      navigate(`/nfp-resources`);
      return;
    }
    if (additional_data.post_type === "protool") {
      navigate(`/nfp-protools`);
      return;
    }
  }
  if (entity_type === "CourseSessionContent") {
    // new_course_content
    navigate(`/programs/${additional_data?.course_id}/learn/${entity_id}`);
    return;
  }

  if (notification_key === "forum_comment_reply_created") {
    navigate(`/exchange/${additional_data?.exchange_forum_id}/discuss`);
    return;
  }
  if (
    [
      "payment_reminder",
      "pro_member_renewal_reminder",
      "qpfp_renewal_reminder",
    ].includes(notification_key)
  ) {
    // payment_reminder, pro_member_renewal_reminder
    navigate("/payments");
    return;
  }
  if (notification_key === "update_public_profile_reminder") {
    // update_public_profile_reminder
    navigate("/public-profile");
    return;
  }
  if (notification_key === "corporate_status_update") {
    // corporate_status_update
    navigate("/corporate/profile");
    return;
  }
  if (notification_key === "membership_update") {
    // membership_update
    navigate("/");
    return;
  }
  if (notification_key === "qpfp_certificate_uploaded") {
    // membership_update
    navigate("/certificates");
    return;
  }
  if (notification_key === "admin_trigger") {
    // admin_trigger
    const url = new URL(additional_data.url ?? window.location.href);
    navigate(url.pathname ?? "/notifications");
    return;
  }
  if (
    [
      "ace_point_credited",
      "pro_member_ace_points_opportunity",
      "pro_member_pending_ace_quizzes",
    ].includes(notification_key)
  ) {
    // ace_point_credited pro_member_ace_points_opportunity pro_member_pending_ace_quizzes
    navigate("/qpfp-certificant/ace-points-tracker");
    return;
  }
  navigate("/");
  return;
};
