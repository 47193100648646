import React from "react";
import PropTypes from "prop-types";

import { useProfile } from "../../../context/userProvider";
import { useNavigate } from "@reach/router";

/**
 * Component for Card with Profile Completion Percentage
 * @return {React.ReactElement} Card with Profile Completion Percentage
 */
function CardWithProfilePercentage({ label, children, url }) {
  const { userDetails } = useProfile();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div
        className="px-5 py-3.5 flex justify-between items-center border border-white border-b-gray-300 rounded-t-md bg-white cursor-pointer"
        onClick={() => navigate(url)}
      >
        <h3 className="text-lg">{label}</h3>
        <div className="px-2 py-1 bg-theme-orange rounded-full">
          <p className="font-semibold text-xs text-white">
            {userDetails?.profile_complete_percentage}% Complete
          </p>
        </div>
      </div>

      <div className="px-5 py-4 border border-white rounded-b-md bg-white">
        {children}
      </div>
    </div>
  );
}

CardWithProfilePercentage.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  url: PropTypes.string,
};
export default CardWithProfilePercentage;
