import React from "react";
import PropTypes from "prop-types";

import { CaretRight } from "@phosphor-icons/react";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import classNames from "classnames";

/**
 * Component for Card with a Header that is also a link
 * @return {React.ReactElement} Card with a Header that is also a link
 */
function CardWithHeaderAsLink({ label, children, url }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          "px-5 py-4 flex justify-between items-center border border-white border-b-gray-300 rounded-t-md bg-white",
          !_.isEmpty(url) && "cursor-pointer"
        )}
        onClick={!_.isEmpty(url) ? () => navigate(url) : null}
      >
        <h3 className="font-semibold text-lg">{label}</h3>
        {!_.isEmpty(url) && <CaretRight className="w-6 h-6" weight="bold" />}
      </div>

      <div className="px-5 py-4 border border-white rounded-b-md bg-white">
        {children}
      </div>
    </div>
  );
}

CardWithHeaderAsLink.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  url: PropTypes.string,
};
export default CardWithHeaderAsLink;
