/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { PublicProfileService } from "../../services/PublicProfileService";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import AppSkeleton from "../AppSkeleton";
import Loader from "../Shimmer/Loader";

const FirmInviteUpdate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const invitation = JSON.parse(window.atob(search.get("invitation")));
    setStatus(invitation.status);
    updateRequest(invitation.firm_user_id, invitation.status);
  }, []);

  const updateRequest = async (id, status) => {
    try {
      const response = await PublicProfileService.updateFirmInviteStatus({
        firm_id: id,
        status: status,
      });
      setIsLoading(false);
      CustomToast.success(response.message);
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages);
      navigate("/");
    }
  };

  if (isLoading)
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  return (
    <AppSkeleton
      hideHeaderLabel
      hideMobileBackButton
      hideSidebar
      hideProfileDetails
    >
      <div className="flex flex-col items-center justify-center w-full h-[calc(100vh-80px)]">
        <div className="flex flex-col max-w-lg p-6 bg-white">
          <div className="text-2xl font-bold leading-7 tracking-tight text-theme-black-color">
            {status === "approved" ? "Request Accepted" : "Request Rejected"}
          </div>
          {status === "approved" ? (
            <div className="mt-3 mb-6 text-sm font-light leading-6 tracking-tight text-theme-black-300">
              Woo! Welcome aboard.<br></br>
              Your have successfully accepted your request. You have taken a
              significant step by joining the firm
            </div>
          ) : (
            <div className="mt-3 mb-6 text-sm font-light leading-6 tracking-tight text-theme-black-300">
              Your have successfully declined the request to join the firm. If
              you want to join the firm again you can request your admin to
              invite you again.
            </div>
          )}
          <Button
            buttonStyle="primary"
            size="xl"
            width="100%"
            onClick={() => {
              navigate("/", { replace: true });
            }}
          >
            CONTINUE TO DASHBOARD
          </Button>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default FirmInviteUpdate;
