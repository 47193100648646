/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import ThankYouEmailPlaceholder from "../../assets/svgs/thankyou_onboarding.svg";
import { useWindowSize } from "../../hooks/useWindowSize";
import AppSkeleton from "../AppSkeleton";
import { useLocation, useNavigate } from "@reach/router";
import { confettiAnimation } from "../../util/confettiAnimation";

const UnbounceOnboardingThankyou = () => {
  const confettiCanvas = useRef();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.form_submit_status !== true) {
      navigate("/onboarding");
    }

    confettiAnimation(confettiCanvas.current);
  }, []);

  return (
    <AppSkeleton
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      <div className="relative flex flex-col items-center w-full max-w-5xl px-6 py-12 mx-auto my-12 bg-white lg:py-20">
        <img src={ThankYouEmailPlaceholder} className="h-60 lg:h-72" alt="" />
        <div className="mt-8 text-3xl font-semibold text-center lg:mt-16 lg:text-5xl text-theme-black-color">
          Thank you for registration!
        </div>
        <div className="max-w-2xl mt-4 text-base text-center text-theme-black-300">
          Please check your inbox for the next steps.
          <br />
          <br />
          <b className="text-theme-red-300"> Enjoy Learning!</b>
        </div>
        <canvas ref={confettiCanvas} className="absolute z-50" />
      </div>
    </AppSkeleton>
  );
};

export default UnbounceOnboardingThankyou;
