import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import { CaretLeft } from "@phosphor-icons/react/dist/ssr";
import _ from "lodash";

import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import Loader from "../../Shimmer/Loader";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import ModuleCard from "../../../stories/ModuleCard";

import { useCourseCurriculum } from "../../../services/CourseDetails";

/**
 * Component for ACE Program Detail Page
 * @return {React.ReactElement} ACE Program Detail Page
 */
function ACEProgramDetail({ ace_program_id }) {
  const navigate = useNavigate();

  const {
    curriculum,
    loading: isLoading,
    error,
    mutate: mutateCurriculum,
  } = useCourseCurriculum(ace_program_id, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });

  const curriculumRef = useRef(curriculum);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    if (!curriculum) {
      mutateCurriculum();
    }
  }, [curriculum, mutateCurriculum]);

  useEffect(() => {
    if (curriculum && curriculum?.modules?.length > 0) {
      setSessions(curriculum?.modules[0]?.sessions);
    }
  }, [curriculum]);

  if (isLoading) {
    return (
      <QPFPSkeleton showFooter={true}>
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </QPFPSkeleton>
    );
  }
  if (error) {
    return (
      <QPFPSkeleton showFooter={true}>
        <ErrorScreen />
      </QPFPSkeleton>
    );
  }
  return (
    <QPFPSkeleton showFooter={true}>
      <div className="flex flex-col space-y-6 w-full py-10 relative z-0">
        <div
          className="flex items-center space-x-2 px-6 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <CaretLeft className="w-5 h-5" />
          <p className="font-normal text-sm">ACE Program &amp; Tests</p>
        </div>

        <div className="flex flex-col space-y-4 px-6">
          <h1 className="font-bold text-2xl">
            General Personal Finance UpSkill Program
          </h1>
          <p className="text-sm text-theme-black-300 mt-3">
            General Personal Finance UpSkill Program
          </p>
        </div>

        <div className="px-6 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
          {sessions?.map((session, s_index) => {
            return (
              <ModuleCard
                curriculumRef={curriculumRef}
                key={s_index}
                eventDetails={{}}
                onClick={null}
                allowUserToSkipRenewal={true}
                isLocked={module.is_locked}
                availablePartPaymentOptions={undefined}
                hasCompletedUnlockedModule={true}
                hasMadeFullPayment={true}
                courseName={curriculum?.name}
                entityType={curriculum?.course_type}
                entityId={curriculum?.entity_id}
                refreshCallback={() => mutateCurriculum()}
                remainingAmount={0}
                percentCompleted={0}
                firstSessionContentId={
                  _.head(session.session_content ?? [])?.id
                }
                session_thumnail_src={session?.session_thumbnail?.url ?? ""}
                courseDate=""
                cummulativeIndex={null}
                courseTitle={session.name}
                sessionNo={session.session}
                course_id={curriculum.id}
                moduleIndex={s_index}
                proToolNo={session.protool}
                status=""
                authorName={
                  curriculum.course_type !== "qpfp" ? session.presenter : ""
                }
              />
            );
          })}
        </div>
      </div>
    </QPFPSkeleton>
  );
}

ACEProgramDetail.propTypes = {
  ace_program_id: PropTypes.string,
};

export default ACEProgramDetail;
