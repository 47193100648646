/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Dropdown from "./Dropdown";
import _ from "lodash";
import TextInput from "./TextInput";
import CheckList from "./CheckList";
import TextArea from "./TextArea";
import FileInput1 from "./FileInput1";
import FileInput from "./FileInput";
import ProfilePictureInput from "./ProfilePictureInput";
import Datepicker from "./DatePicker";
import CheckBox from "./CheckBox";
const FormBuilder = ({
  initialValues,
  onFormSubmit,
  validationSchema,
  inputFieldsDetails,
  cancelButtonRef,
  formClassName,
  submitButtonRef,
  enableReinitialize = true,
}) => {
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validationSchema={() => validationSchema}
      onSubmit={async (values) => await onFormSubmit(values)}
    >
      {({ values, ...formProps }) => {
        if (
          !formProps.isSubmitting &&
          !formProps.isValidating &&
          !formProps.isValid &&
          isSubmitClick
        ) {
          setIsSubmitClick(false);

          document
            .getElementsByClassName(
              Object.keys(formProps.errors)[0] + "ErrorMessage"
            )[0]
            ?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
        }
        return (
          <Form id="personal_info_form" className={classNames(formClassName)}>
            {inputFieldsDetails.map(
              (
                {
                  label,
                  isRequired = true,
                  name = null,
                  type = "text",
                  viewOnlyValues = undefined,
                  placeholder = "",
                  className = "",
                  isHidden = () => false,
                  isViewOnly,
                  renderValue = undefined,
                  onValueChange = undefined,
                  allowCreateNewOption = false,
                  onClick,
                  onFieldBlur = undefined,
                  ...props
                },
                index
              ) => {
                const inputName = name || _.snakeCase(label);
                if (props.emptyGrid)
                  return <div key={`${name}-${index}`}></div>;
                if (isHidden(values)) return null;
                if (isViewOnly) {
                  if (props.renderContent) {
                    return <props.renderContent key={`${name}-${index}`} />;
                  }
                  let displayValue = _.head(_.at(values, inputName));
                  if (viewOnlyValues)
                    displayValue = viewOnlyValues(
                      _.head(_.at(values, inputName))
                    );
                  if (renderValue) displayValue = renderValue(values);
                  return (
                    <ViewOnlyField
                      key={`${name}-${index}`}
                      label={label}
                      className={className}
                      value={displayValue}
                    />
                  );
                }

                if (type === "dropdown") {
                  return (
                    <Dropdown
                      {...props}
                      key={`${name}-${index}`}
                      className={className}
                      name={inputName}
                      label={label}
                      placeholder={placeholder}
                      options={
                        _.isEmpty(props.optionFormFormikName)
                          ? props.options
                          : values?.options?.[props.optionFormFormikName]
                      }
                      displayCurrentOption={true}
                      displayKey={props.displayKey}
                      idKey={props.idKey}
                      id={inputName}
                      type="text"
                      allowCreateNewOption={allowCreateNewOption ?? false}
                      isRequired={isRequired}
                      labelClassName={props.labelClassName}
                      onOptionSelect={(val) => {
                        props.onOptionSelect &&
                          props.onOptionSelect({ value: val, formProps });
                      }}
                    />
                  );
                }
                if (type === "checkBox") {
                  return (
                    <CheckBox
                      key={`${name}-${index}`}
                      name={name}
                      inputLabel={label}
                      className={className}
                    />
                  );
                }
                if (type === "checklist") {
                  return (
                    <CheckList
                      key={`${name}-${index}`}
                      label={label}
                      isRequired={isRequired}
                      name={inputName}
                      formProps={formProps}
                      values={values}
                      options={props.options}
                      displayKey={props.displayKey}
                      idKey={props.idKey}
                      className={className}
                      {...props}
                    />
                  );
                }
                if (type === "fileinput") {
                  return (
                    <FileInput
                      key={`${name}-${index}`}
                      name={inputName}
                      id={inputName}
                      placeholder={placeholder}
                      showPointer={true}
                      height="h-10"
                      label={label}
                      suggestion={props.suggestion}
                      accept={props.accept ?? "application/pdf, image/*"}
                      onFileChange={(file) => {
                        formProps.setFieldTouched(inputName, true, false);
                        formProps.setFieldValue(inputName, file, true);
                      }}
                      {...props}
                    />
                  );
                }
                if (type === "fileinput1") {
                  return (
                    <FileInput1
                      key={`${name}-${index}`}
                      name={inputName}
                      id={inputName}
                      placeholder={placeholder}
                      showPointer={true}
                      label={label}
                      suggestion={props.suggestion}
                      accept={props.accept ?? "application/pdf, image/*"}
                      onFileChange={(file) => {
                        formProps.setFieldTouched(inputName, true, false);
                        formProps.setFieldValue(inputName, file, true);
                      }}
                      {...props}
                    />
                  );
                }
                if (type === "avatarImgUpload") {
                  return (
                    <ProfilePictureInput
                      key={`${name}-${index}`}
                      onFileChange={(file) => {
                        if (file === -1) {
                          formProps.setFieldValue(inputName, values[inputName]);
                          return;
                        }
                        formProps.setFieldTouched(inputName, true, false);
                        formProps.setFieldValue(inputName, file, true);
                      }}
                      name={inputName}
                      {...props}
                    />
                  );
                }
                if (type === "date") {
                  return (
                    <Datepicker
                      key={`${name}-${index}`}
                      name="dob"
                      placeholder="Birth Date"
                      label="Date of Birth"
                      filterDate={(d) => d < new Date()}
                      isRequired
                      {...props}
                    />
                  );
                }
                if (type === "textarea") {
                  return (
                    <TextArea
                      key={`${name}-${index}`}
                      label={label}
                      isRequired={isRequired}
                      name={inputName}
                      labelClassname={props.labelClassName}
                      placeholder={placeholder}
                      rows={props.rows}
                      id={inputName}
                      type="text"
                      className={className}
                      {...props}
                    />
                  );
                }
                return (
                  <TextInput
                    {...props}
                    key={`${name}-${index}`}
                    className={className}
                    name={inputName}
                    label={label}
                    placeholder={placeholder}
                    id={inputName}
                    type={type}
                    {...(onValueChange
                      ? {
                          onChange: (e) =>
                            onValueChange({ event: e, formikProps: formProps }),
                        }
                      : {})}
                    {...(onFieldBlur
                      ? {
                          onBlur: (e) =>
                            onFieldBlur({ event: e, formikProps: formProps }),
                        }
                      : {})}
                    isRequired={isRequired}
                  />
                );
              }
            )}
            <button
              className="hidden"
              ref={submitButtonRef}
              disabled={formProps.isSubmitting}
              onClick={() => {
                setIsSubmitClick(true);
              }}
              type="submit"
            >
              submit
            </button>
            {cancelButtonRef && (
              <button
                className="hidden"
                ref={cancelButtonRef}
                type="button"
                onClick={() => {
                  formProps.resetForm();
                }}
              >
                cancel
              </button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const ViewOnlyField = ({ label, value, className }) => {
  return (
    <div className={classNames("flex w-full flex-col", className)}>
      <div className="text-sm font-medium tracking-tight text-left break-words text-theme-gray-600">
        {label}
      </div>
      <div className="mt-0.5 break-words text-base leading-6 text-theme-black-color tracking-tight text-left font-normal">
        {value !== undefined && value !== null && value !== "" ? value : "-"}
      </div>
    </div>
  );
};

ViewOnlyField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
};

FormBuilder.propTypes = {
  cancelButtonRef: PropTypes.any,
  className: PropTypes.any,
  enableReinitialize: PropTypes.bool,
  formClassName: PropTypes.any,
  initialValues: PropTypes.any,
  inputFieldsDetails: PropTypes.array,
  onFormSubmit: PropTypes.func,
  submitButtonRef: PropTypes.any,
  validationSchema: PropTypes.any,
};

export default React.memo(FormBuilder);
