import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState } from "react";
import CustomLink from "../../../stories/Link";
import { CustomToast } from "../../../stories/Toast";
import { PublicProfileService } from "../../../services/PublicProfileService";
import ToggleSwitch from "../../../stories/Switch";

const ViewProfileCard = ({
  className,
  profileLink,
  isPromember,
  showProfileToDirectory = true,
  onUpdate = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={classNames("relative flex flex-col w-full", className)}>
      <div className="flex text-xl font-bold leading-none text-theme-black-color">
        View Profile
      </div>
      <div className="flex items-center justify-between text-xl font-bold leading-none text-theme-black-color">
        <div className="flex flex-col mt-2 text-xl font-bold leading-none text-theme-black-color">
          <CustomLink
            textColor="text-blue-600"
            activeTextColor="text-blue-600"
            showUnderLine={false}
            underLineOnlyOnHover={false}
            fontSize="sm"
            href={profileLink}
            target="_blank"
            className="font-normal leading-5 tracking-tight break-all"
          >
            {profileLink}
          </CustomLink>
          <div
            className={classNames(
              "mt-3 items-center flex",
              !isPromember && "hidden"
            )}
          >
            <ToggleSwitch
              className="mr-4"
              defaultValue={showProfileToDirectory}
              isLoading={isLoading}
              onChange={async (value) => {
                setIsLoading(true);
                try {
                  await PublicProfileService.updatePublicProfile({
                    show_profile_to_directory: value,
                  });
                  CustomToast.success(
                    value
                      ? "Your profile is visible on NFP Members Directory"
                      : "Your profile is hidden on NFP Members Directory"
                  );
                  onUpdate().then(() => setIsLoading(false));
                } catch (e) {
                  console.log(e);
                }
              }}
            />
            <span className="text-sm leading-4 text-theme-black-300">
              Show me on NFP Members Directory
            </span>
            {/* <Formik
              enableReinitialize
              initialValues={{
                showMe: showProfileToDirectory,
              }}
              onSubmit={async (values) => {
                const result = await PublicProfileService.updatePublicProfile({
                  show_profile_to_directory: values.showMe,
                });
                if (result.message) {
                  // setFieldValue("showMe", val);
                  // setInitialValues({ showMe: val });
                  CustomToast.success(result.message);
                  onUpdate();
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <CheckBox
                    inputLabel={"Show me on NFP Members Directory"}
                    labelClassName=""
                    name="showMe"
                    errorMessage=""
                    errorColor=""
                    className=""
                    checked={values.showMe}
                    value={values.showMe}
                    type="checkbox"
                    onChange={async (e) => {
                      setFieldValue("showMe", e.target.checked);
                      submitref.current.click();
                      // try {
                      //   const val = e.target.checked;
                      //   const result =
                      //     await PublicProfileService.updatePublicProfile({
                      //       show_profile_to_directory: val,
                      //     });
                      //   if (result.message) {
                      //     setInitialValues({ showMe: val });
                      //     CustomToast.success(result.message);
                      //     onUpdate();
                      //   }
                      //   return result;
                      // } catch (e) {
                      //   CustomToast.error(e.error_messages[0]);
                      // }
                    }}
                  />
                  <button
                    ref={submitref}
                    type="submit"
                    className="hidden"
                  ></button>
                </Form>
              )}
            </Formik> */}
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText(profileLink);
          CustomToast.success("Copied to clipboard", { duration: 30000 });
        }}
        className="absolute text-sm font-semibold cursor-pointer right-4 text-theme-red-300 hover:text-theme-red"
      >
        Copy Link
      </div>
    </div>
  );
};

ViewProfileCard.propTypes = {
  className: PropTypes.string,
  onUpdate: PropTypes.func,
  profileLink: PropTypes.string,
  isPromember: PropTypes.bool,
  showProfileToDirectory: PropTypes.bool,
};

export default ViewProfileCard;
