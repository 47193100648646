/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";
import _ from "lodash";

const userAttribute = (obj) => {
  return {
    user: obj,
  };
};
const visitorAttribute = (obj) => {
  return {
    visitor: obj,
  };
};

const sendOTP = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.sendOtp,
      data,
    },
    false
  );
};

const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.login,
      data,
    },
    false
  );
};
const visitorEnquiry = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.get_started,
      data: visitorAttribute(data),
    },
    false
  );
};

const unbounceOnboarding = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.user_onboardings,
      data: { user: data },
    },
    false
  );
};
const signup = (data, token = undefined) => {
  return API(
    {
      method: "PUT",
      url: config.urls.auth.signup,
      data: userAttribute(data),
    },
    token ? true : false,
    token && token
  );
};

const forgotPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.forgotPassword,
      data,
    },
    false
  );
};

const resetPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.resetPassword,
      data,
    },
    false
  );
};

const verifyEmail = (email, code) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.verifyEmail,
      params: {
        email,
        code,
      },
    },
    false
  );
};

const fetchCityDetails = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getCityDetails,
    },
    true
  );
};

const getCities = (data, state_id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.location,
      params: { location_type: data, state_id },
    },
    false
  );
};

const getDescriptionOptions = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getDescriptionOptions,
    },
    false
  );
};

const getGradStatusOptions = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getGradStatusOptions,
    },
    false
  );
};
const fetchPincodeDetails = (pin) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.fetchPincodeDetails(pin),
    },
    true
  );
};
const validateEmail = ({ email, phone_number }) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.validate,
      date: {},
      params: {
        email: _.isEmpty(email) ? undefined : email,
        phone_number: _.isEmpty(phone_number) ? undefined : phone_number,
      },
    },
    false
  );
};
const sendEmailVerificationLink = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.sendEmailVerificationLink,
      data,
    },
    false
  );
};

const addGoogleIdentity = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.oauth2.google.add_identity,
      data: data,
    },
    true
  );
};

export const AuthService = {
  login,
  sendOTP,
  visitorEnquiry,
  unbounceOnboarding,
  signup,
  validateEmail,
  verifyEmail,
  forgotPassword,
  resetPassword,
  fetchCityDetails,
  getCities,
  getDescriptionOptions,
  getGradStatusOptions,
  sendEmailVerificationLink,
  fetchPincodeDetails,
  addGoogleIdentity,
};
