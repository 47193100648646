import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import TextInput from "../../stories/TextInput";
import { Form, Formik } from "formik";
import { UserService } from "../../services/UserService";
import { CustomToast } from "../../stories/Toast";
import { X } from "@phosphor-icons/react";
import { Validations } from "../../constants/validations";

const ChangePasswordModal = ({ open, onClose, onUpdate, className }) => {
  const initialValues = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="mb-3 text-base font-semibold leading-6 text-theme-black-color"
              >
                Change Password
              </Dialog.Title>
              <Formik
                initialValues={initialValues}
                validationSchema={() =>
                  Validations.popup.changePasswordPopupValidationSchema
                }
                onSubmit={async (values) => {
                  try {
                    const res = await UserService.updatePassword(values);
                    onUpdate();
                    CustomToast.success(res.message);
                    onClose();
                  } catch (e) {
                    CustomToast.error(e.error_messages[0]);
                  }
                }}
              >
                <Form className="flex flex-col space-y-2.5 w-full">
                  <TextInput
                    name="current_password"
                    label="Current Password"
                    placeholder={""}
                    id="current_password"
                    type="password"
                    isRequired={true}
                    autoFocus={true}
                  />
                  <TextInput
                    name="password"
                    label="New Password"
                    placeholder={""}
                    id="password"
                    type="password"
                    isRequired={true}
                  />
                  <TextInput
                    name="password_confirmation"
                    label="Confirm Password"
                    placeholder={""}
                    id="password_confirmation"
                    type="password"
                    isRequired={true}
                  />
                  <div className="flex flex-col items-end w-full mt-1 ">
                    <Button
                      buttonStyle="primary"
                      type="submit"
                      size="md"
                      height="36px"
                      width="100px"
                    >
                      SAVE
                    </Button>
                  </div>
                </Form>
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ChangePasswordModal.defaultProps = {
  open: false,
  className: "",
};

ChangePasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  className: PropTypes.string,
};
export default ChangePasswordModal;
