import PropTypes from "prop-types";
import { Circle } from "@phosphor-icons/react";
import React from "react";
import Card from "./Card";
import _ from "lodash";
import Text from "./Text";

export const RedCircle = () => (
  <div className="flex items-center h-5">
    <Circle className="w-2 h-2 mr-2" weight="fill" color="#f36c04" />
  </div>
);
export const BulletItem = ({ index, children }) => {
  if (!children) return null;
  return (
    <div className="flex items-start text-sm tracking-tight text-theme-black-300">
      <RedCircle />
      {children}
    </div>
  );
};

BulletItem.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
};

export const BulletList = ({ list = [] }) => {
  return (
    <div className="flex flex-col w-full space-y-3">
      {list?.map(
        (eventContent, index) =>
          eventContent && (
            <BulletItem key={index} index={index}>
              <Text
                content={eventContent}
                type="text"
                className="tracking-tight text-theme-black-300"
                size="text-sm"
              />
            </BulletItem>
          )
      )}
    </div>
  );
};

BulletList.propTypes = {
  list: PropTypes.array,
};

export const BulletCard = ({ list, header, className }) => {
  if (_.chain(list).compact().isEmpty().value()) return null;
  return (
    <Card
      className={className}
      header={header}
      cardBody={
        list.length === 1 ? (
          <div className="text-sm tracking-tight text-theme-black-300">
            {list[0]}
          </div>
        ) : (
          <BulletList list={list} />
        )
      }
      cardType="outline"
    />
  );
};

BulletCard.propTypes = {
  className: PropTypes.string,
  header: PropTypes.any,
  list: PropTypes.array,
};
