import { ErrorMessage } from "formik";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ShowFormError = ({ name, className }) => {
  return (
    <ErrorMessage
      name={name}
      component="span"
      className={classNames(
        "flex w-full mt-1 text-sm text-left text-red-700",
        name + "ErrorMessage",
        className
      )}
    ></ErrorMessage>
  );
};

ShowFormError.defaultProps = { className: "" };

ShowFormError.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
export default ShowFormError;
