import React from "react";
import { useCertificates } from "../../services/BasicDetails";
import EmptyState from "../../stories/EmptyState";
import AppSkeleton from "../AppSkeleton";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import Button from "../../stories/Button";
import { useNavigate } from "@reach/router";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import PopupImage from "../../stories/PopupImage";

const Certificates = () => {
  const {
    loading: isCertificatesLoading,
    certificates,
    error,
  } = useCertificates({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });
  const navigate = useNavigate();

  const pageData = [
    { name: "Dashboard", to: "/" },
    {
      name: "My Certificates",
      to: "/certificates",
      current: true,
    },
  ];

  const ShowEmptyState = () => {
    return (
      <EmptyState
        heading="No Certificates Yet!"
        subHeading={
          "You haven't finished any program yet, to continue learning click on the below button."
        }
        cta={
          <Button className="!mt-4" onClick={() => navigate("/programs")}>
            <div className="flex items-center px-3 py-1">
              <span>VIEW MY PROGRAMS</span>
            </div>
          </Button>
        }
        image={
          <img
            alt="emptyStatePlaceHolder"
            className="mb-8 max-h-72"
            src={EmptyStatePlaceHolder}
          />
        }
      />
    );
  };
  if (isCertificatesLoading)
    return (
      <AppSkeleton pageLabel="My Certificates" breadCrumbData={pageData}>
        <div className="flex flex-col w-full p-5">
          <div className="absolute top-0 flex items-center justify-center w-full h-screen">
            <Loader />
          </div>
        </div>
      </AppSkeleton>
    );

  if (error) {
    return (
      <AppSkeleton pageLabel="My Certificates" breadCrumbData={pageData}>
        <ErrorScreen />
      </AppSkeleton>
    );
  }
  if (certificates.length === 0)
    return (
      <AppSkeleton pageLabel="My Certificates" breadCrumbData={pageData}>
        <div className="flex flex-col w-full">
          <ShowEmptyState />
        </div>
      </AppSkeleton>
    );

  return (
    <AppSkeleton pageLabel="My Certificates" breadCrumbData={pageData}>
      <div className="flex flex-col w-full p-5">
        <div className="flex flex-col">
          {certificates?.map((course, index) => (
            <div
              key={`${course?.certificate_event_name}-${index}`}
              className="grid grid-cols-2 gap-6 p-4 mb-4 bg-white rounded md:grid-cols-3 lg:grid-cols-4"
            >
              <div className="flex w-full pb-4 text-xl font-bold leading-none border-b-2 col-span-full text-theme-black-color ">
                {course?.certificate_event_name}
              </div>

              {course?.user_certifications?.map((certificate, j_index) => {
                return (
                  <PopupImage
                    key={certificate.id}
                    imgSrc={certificate?.certificate_url}
                    title={certificate?.name ?? course?.certificate_event_name}
                    allowDownload={true}
                  >
                    <div className="flex flex-col items-center cursor-pointer">
                      <img
                        className="object-contain"
                        alt="certificate thumbnail"
                        src={certificate?.certificate_url}
                      />
                      <div className="w-full mt-3 font-bold text-left select-none text-theme-black-300">
                        {certificate?.name ?? course?.certificate_event_name}
                      </div>
                    </div>
                  </PopupImage>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </AppSkeleton>
  );
};

export default Certificates;
