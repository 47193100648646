/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import React, { Fragment, useEffect, useRef } from "react";
import AppSkeleton from "../AppSkeleton";
import classNames from "classnames";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import usePosts from "../../hooks/usePost";
import { useLocation, useNavigate } from "@reach/router";
import queryString from "qs";
import ClientConnectCard from "../../stories/ClientConnectCard";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import { Formik } from "formik";
import Dropdown from "../../stories/Dropdown";
import NFPLogo from "../../assets/images/nfp.png";
import SearchBar from "../../stories/SearchBar";
import CustomLink from "../../stories/Link";
import { useProfile } from "../../context/userProvider";
import { CustomToast } from "../../stories/Toast";

const initialValues = {
  filter: null,
};

const filterOptions = [
  { filter: "All", value: "" },
  { filter: "Images", value: "image" },
  { filter: "Videos", value: "video" },
  { filter: "Documents", value: "document" },
  { filter: "Text Content", value: "text_content" },
];

const NFPProtools = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const searchTimeoutRef = useRef();
  const parsed = queryString.parse(location.search);

  const [filter, setFilter] = React.useState("");
  const [page, setPageNumber] = React.useState(parsed.pageNo ?? 1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const { loading, error, posts, hasMore } = usePosts(
    "protool",
    filter,
    page,
    searchQuery
  );

  const pageData = [
    { name: "Dashboard", to: "/", current: false },
    { name: "My Programs", to: "/programs", current: false },
    { name: "NFP ProTools", current: true },
  ];

  // const [course, setCourse] = useState();

  useEffect(() => {
    if (userDetails?.pm_signup_status === "overdue") {
      CustomToast.error(
        "Your ProMembership has expired. Please renew it to continue learning."
      );
      navigate(
        `/programs/${props.location?.state?.curriculumData?.course?.id}`
      );
    }
  }, [userDetails]);

  // useEffect(async () => {
  //   if (posts.length === 0) return;
  //   if (filter === "" && searchQuery === "") {
  //     setFilteredPosts([]);
  //     return;
  //   }
  //   setFilteredPosts(
  //     posts.filter((e) =>
  //       `${e.title} ${e.text_content}`
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase())
  //     )
  //   );
  // }, [filter, searchQuery, posts]);

  const observer = React.useRef();
  const lastPostElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const showEmptyScreen = !loading && posts?.length === 0;

  return (
    <>
      <AppSkeleton
        showNfpLogo={true}
        pageLabel="NFP ProTools"
        hideSidebar={true}
        breadCrumbData={pageData}
        showMobileHeader={true}
        secondaryHeading="NFP ProTools"
      >
        <div
          className={classNames(
            "flex flex-col justify-start py-12 pt-0 lg:pt-12 sm:px-6 lg:px-8 min-h-[80vh]"
          )}
        >
          <div className="mx-6 md:mx-auto md:w-full md:max-w-3xl xl:max-w-6xl">
            {posts && (
              <div>
                <div className="pb-2 lg:grid lg:max-w-3xl lg:grid-cols-2">
                  <div className="hidden lg:flex lg:flex-col lg:w-fit">
                    <span className="text-3xl font-bold text-theme-black-color pb-1.5">
                      NFP ProTools
                    </span>
                    <div className="flex">
                      <img alt="NFP" className="w-8 h-8 mr-2.5" src={NFPLogo} />
                      <span className="self-center text-sm font-semibold text-theme-black-color">
                        Network FP
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-end justify-between mb-3 space-y-2 md:flex-row md:space-y-0 md:space-x-6">
                  <SearchBar
                    className="w-full basis-2/3"
                    backgroundColor="bg-white !border-gray-200 !border-1 !border-solid"
                    placeholder={"Search here..."}
                    searchTerm={searchQuery}
                    onSearch={(val) => {
                      clearTimeout(searchTimeoutRef.current);
                      searchTimeoutRef.current = setTimeout(() => {
                        setSearchQuery(val);
                        setPageNumber(1);
                      }, 700);
                    }}
                  />
                  <div className="self-center w-full basis-1/3 justify-self-end">
                    <Formik initialValues={initialValues} enableReinitialize>
                      <Dropdown
                        name="filter"
                        id="filter"
                        type="text"
                        label="Filter by"
                        placeholder="None"
                        height={"h-12"}
                        options={filterOptions}
                        keepShadow={false}
                        buttonBgColor="bg-white"
                        displayCurrentOption={true}
                        displayKey="filter"
                        idKey="value"
                        onChange={(e) => {
                          setFilter(e ?? "");
                          setPageNumber(1);
                        }}
                      />
                    </Formik>
                  </div>
                </div>
                <div className="pb-6 ">
                  <b>Steps to Download Protools Image:-</b>
                  <ol className="ml-4 text-sm list-decimal text-theme-black-300">
                    <li className="pl-1"> Click Download Button.</li>
                    <li className="pl-1">
                      Select Folder in which you want to save this Image.
                    </li>
                    <li className="pl-1">
                      Index of ProTools:&nbsp;
                      <CustomLink
                        target="_blank"
                        href="https://bit.ly/NFP-ProTools-Index"
                        textColor="text-theme-red-300"
                        activeTextColor="text-red-700"
                        showUnderLine={false}
                        underLineOnlyOnHover={true}
                        className="leading-none"
                      >
                        https://bit.ly/NFP-ProTools-Index
                      </CustomLink>
                    </li>
                  </ol>
                </div>
                {showEmptyScreen && (
                  <div className="w-full p-3 mt-6 text-center lg:p-6">
                    <EmptyState
                      heading="No post available"
                      subHeading={
                        filter === "" && searchQuery === ""
                          ? "We will add posts soon. Please come back after sometime"
                          : "No result found. Please try again with another keywords."
                      }
                      cta={<></>}
                      image={
                        <img
                          alt="emptyStatePlaceHolder"
                          className="py-9 max-h-80"
                          src={EmptyStatePlaceHolder}
                        />
                      }
                    />
                  </div>
                )}
                {posts?.length > 0 && (
                  <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                    {posts.map((post, index) => {
                      if (posts.length === index + 1)
                        return (
                          <div
                            key={post.id}
                            ref={lastPostElementRef}
                            className="pb-3"
                          >
                            <ClientConnectCard
                              postId={post.id}
                              title={post.title}
                              isLiked={post.is_user_liked ?? false}
                              likesCount={post.votes_count ?? 0}
                              attachments={post.attachments}
                              textContent={post.text_content}
                              enableLike={false}
                            />
                          </div>
                        );
                      return (
                        <div key={post.id} className="pb-3">
                          <ClientConnectCard
                            postId={post.id}
                            title={post.title}
                            isLiked={post.is_user_liked ?? false}
                            likesCount={post.votes_count ?? 0}
                            attachments={post.attachments}
                            textContent={post.text_content}
                            enableLike={false}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                {loading && (
                  <div className="mt-5">
                    <Loader />
                  </div>
                )}
                <div>{!loading && error && <ErrorScreen />}</div>
              </div>
            )}
          </div>
        </div>
      </AppSkeleton>
    </>
  );
};
export default NFPProtools;
