/* eslint-disable react-hooks/exhaustive-deps */
import "../index.css";
import React, { useEffect, useState } from "react";
import TopicComponent from "./TopicComponent";
import {
  Check,
  LockSimple,
  SquaresFour,
  Trophy,
  X,
} from "@phosphor-icons/react";
import ProgressBar from "./ProgressBar";
import PropTypes from "prop-types";
import classNames from "classnames";
import AccordionComponent from "./AccordionComponent";
import _ from "lodash";
import PaymentModal from "../components/Modals/PaymentModal";
import { useNavigate } from "@reach/router";
import SearchBar from "./SearchBar";
import EmptyState from "./EmptyState";
import Button from "./Button";
import EmptyStatePlaceHolder from "../assets/svgs/empty_state_bg.svg";
import { CustomToast } from "./Toast";
import { PaymentServices } from "../services/PaymentServices";
import { useWindowSize } from "../hooks/useWindowSize";
import StepperProgressBar from "./StepperProgressBar";

const getProgressComponent = (courseCompletionPercentage = 0) => {
  return (
    <div className="flex w-full space-x-0.5">
      <StepperProgressBar
        className="w-full mt-4"
        currentStep={1}
        percentCompleted={courseCompletionPercentage}
        totalSteps={1}
      />
    </div>
  );
};

const ModuleAccordian = ({
  data,
  prevCount,
  courseName,
  entityType,
  entityId,
  courseDataRef,
  courseId,
  unlockCourse = false,
  availablePartPaymentOptions = [],
  refreshCallback = () => {},
  hasCompletedUnlockedModule,
  hasMadeFullPayment = true,
  currentState,
  ratingStats = {},
  courseStats = {},
  courseSessionStats = {},
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  return (
    <div>
      <AccordionComponent
        Heading={() => (
          <div className="flex items-center text-base font-bold md:text-lg text-theme-black-color">
            {data.name}
            {!unlockCourse && data.is_locked && (
              <div className="h-6 w-6 flex justify-center items-center">
                <LockSimple
                  size={18}
                  weight="fill"
                  className="ml-2 text-sm font-semibold leading-5 tracking-tighter md:text-lg lg:text-md text-theme-red"
                />
              </div>
            )}
          </div>
        )}
        stickyHeader
        stickyTopPosition="top-10 lg:top-0"
        stickyZIndex="z-[15]"
        accordianProps={{
          defaultOpen: currentState.currentModuleId === data.id,
        }}
        Children={() => {
          if (!unlockCourse && data.is_locked) {
            if (!["qpfp", "pro_member", "general"].includes(entityType)) {
              return (
                <span className="italic">Recordings are no more available</span>
              );
            }
            if (!hasCompletedUnlockedModule)
              return (
                <span className="italic">
                  Content for this session is locked untill you finish first
                  module of this course
                </span>
              );
            if (!hasMadeFullPayment)
              return (
                <span className="italic">
                  Content for this session is locked till you renew your
                  subscription{" "}
                  <span
                    className="cursor-pointer text-theme-red hover:underline"
                    onClick={() => setShowPaymentModal(true)}
                  >
                    Click here
                  </span>{" "}
                  to renew now
                </span>
              );

            return (
              <span className="italic">
                This module will open automatically once you finishes previous
                modules
              </span>
            );
          }
          if (_.size(data?.sessions) === 0)
            return <span className="italic">No Sessions added</span>;
          return data.sessions.map((session, s_index) => {
            return (
              <SessionAccordian
                key={s_index}
                session={session}
                index={prevCount + s_index}
                isLocked={data.is_locked}
                courseDataRef={courseDataRef}
                currentState={currentState}
                courseId={courseId}
                entityType={entityType}
                ratingStats={ratingStats}
                courseStats={courseStats}
                session_completion_percentage={
                  courseSessionStats?.[session.id] ?? 0
                }
                className={
                  data.sessions.length === 1
                    ? ""
                    : s_index === 0
                    ? "pt-4 border-b pb-6"
                    : s_index === data.sessions.length - 1
                    ? "pt-6"
                    : "border-b py-6"
                }
              />
            );
          });
        }}
      />
      {showPaymentModal && (
        <PaymentModal
          open={showPaymentModal}
          title={`Payment for ${courseName}`}
          entityProps={{
            entity_type: entityType,
            entity_id: entityId,
            part_payment_order:
              _.head(availablePartPaymentOptions ?? [])?.part_payment_order ??
              0,
          }}
          availablePartPaymentOptions={availablePartPaymentOptions}
          onClose={() => {
            refreshCallback();
            setShowPaymentModal(false);
          }}
        />
      )}
    </div>
  );
};

ModuleAccordian.propTypes = {
  availablePartPaymentOptions: PropTypes.array,
  courseDataRef: PropTypes.any,
  courseId: PropTypes.any,
  courseName: PropTypes.string,
  courseSessionStats: PropTypes.object,
  courseStats: PropTypes.object,
  currentState: PropTypes.shape({
    currentModuleId: PropTypes.any,
  }),
  data: PropTypes.shape({
    id: PropTypes.any,
    is_locked: PropTypes.any,
    name: PropTypes.any,
    sessions: PropTypes.array,
  }),
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  hasCompletedUnlockedModule: PropTypes.bool,
  hasMadeFullPayment: PropTypes.bool,
  prevCount: PropTypes.number,
  ratingStats: PropTypes.object,
  refreshCallback: PropTypes.func,
  unlockCourse: PropTypes.bool,
};

const SessionAccordian = ({
  session,
  index,
  className,
  currentState,
  courseDataRef,
  courseId,
  ratingStats = {},
  session_completion_percentage = 0,
  courseStats = {},
  entityType,
}) => (
  <div className={classNames(className, "z-[9]")}>
    <AccordionComponent
      accordianProps={{
        defaultOpen: currentState.currentSessionId === session.id,
      }}
      stickyHeader={true}
      stickyTopPosition="top-[88px] lg:top-[52px]"
      stickyZIndex="z-[12]"
      panelClassNames="!pl-0"
      Heading={(open) => (
        <TopicHeader
          title={session.name}
          progress={session_completion_percentage ?? 0}
          index={index}
          subtitle={
            !open ? null : (
              <TopicDetailsBar
                topic={
                  entityType === "new_user"
                    ? `Day ${index + 1}`
                    : session.session_date
                }
                author={session.presenter}
              />
            )
          }
        />
      )}
      Children={() => {
        if (_.size(session?.session_content) === 0)
          return <span className="italic">No Content added</span>;
        return session.session_content.map((content, c_index) => (
          <SessionContentComponent
            content={{ ...content, progress: courseStats?.[content.id] ?? {} }}
            key={c_index}
            courseDataRef={courseDataRef}
            index={c_index}
            courseId={courseId}
            currentState={currentState}
            ratings={ratingStats[content.id]}
          />
        ));
      }}
    />
  </div>
);

SessionAccordian.propTypes = {
  className: PropTypes.any,
  courseDataRef: PropTypes.any,
  courseId: PropTypes.any,
  courseStats: PropTypes.object,
  currentState: PropTypes.shape({
    currentSessionId: PropTypes.any,
  }),
  entityType: PropTypes.string,
  index: PropTypes.number,
  ratingStats: PropTypes.object,
  session: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.any,
    presenter: PropTypes.any,
    session_content: PropTypes.array,
    session_date: PropTypes.string,
  }),
  session_completion_percentage: PropTypes.number,
};

const SessionContentComponent = ({
  content,
  index,
  courseId,
  courseDataRef,
  currentState,
  ratings = {},
}) => (
  <div className="py-2 md:ml-11">
    <TopicComponent
      courseId={courseId}
      sessionContentId={content.id}
      courseDataRef={courseDataRef}
      title={content.title}
      isCompleted={content.progress?.status === "completed" ?? false}
      type={content.kind}
      isDownloadable={content.is_downloadable ?? true}
      speaker={content.author_name}
      sessionTime={`${content.duration_in_hours ?? "00"}:${
        content.duration_in_mins ?? "00"
      }:${content.duration_in_sec ?? "00"}`}
      ratings={ratings.average_rating}
      url={content?.attachment?.url ?? ""}
      currentState={currentState}
    />
  </div>
);

SessionContentComponent.propTypes = {
  content: PropTypes.shape({
    attachment: PropTypes.shape({
      url: PropTypes.string,
    }),
    author_name: PropTypes.string,
    average_rating: PropTypes.string,
    duration_in_hours: PropTypes.number,
    duration_in_mins: PropTypes.number,
    duration_in_sec: PropTypes.number,
    id: PropTypes.any,
    is_downloadable: PropTypes.bool,
    kind: PropTypes.string,
    progress: PropTypes.shape({
      status: PropTypes.string,
    }),
    title: PropTypes.any,
  }),
  courseDataRef: PropTypes.any,
  courseId: PropTypes.any,
  currentState: PropTypes.object,
  index: PropTypes.number,
  ratings: PropTypes.object,
};

const TopicHeader = ({ title, progress, subtitle, index }) => {
  return (
    <div className="flex items-start space-x-4 ">
      <div className="w-8 h-8 min-w-[32px]">
        <ProgressBar
          isCircular={true}
          title={(index + 1).toString()}
          percent={progress}
          className="z-0"
        ></ProgressBar>
      </div>
      <div className="flex flex-col">
        <div className="md:text-lg !leading-6 text-base text-theme-black-color font-semibold w-full max-w-md pb-1.5">
          {title}
        </div>
        {subtitle}
      </div>
    </div>
  );
};

TopicHeader.propTypes = {
  index: PropTypes.number,
  progress: PropTypes.number,
  subtitle: PropTypes.any,
  title: PropTypes.string,
};

const TopicDetailsBar = ({ topic, author }) => {
  return (
    <div className="flex flex-col md:flex-row items-start md:items-center  md:space-x-1.5 w-full md:text-sm text-xs">
      <div className=" text-neutral-600">{topic}</div>
      {topic && author ? (
        <div>
          <div className="w-1 h-1 min-w-[4px] hidden lg:block rounded-full bg-theme-gray-400" />
        </div>
      ) : null}
      <div className=" text-neutral-400">{author}</div>
    </div>
  );
};

TopicDetailsBar.propTypes = {
  author: PropTypes.string,
  topic: PropTypes.string,
};

const CurriculumAccordion = ({
  curriculumData,
  courseDataRef,
  initialProgress = 0,
  className,
  courseName = "",
  entityType,
  entityId,
  courseCompletionPercentage = 0,
  refreshCallback = () => {},
  showCloseButton = false,
  onCloseShowButton = () => {},
  isMarkingCompleted = false,
  markCurrentAsCompleted = () => {},
  unlockCourse = false,
  currentState = {},
  courseId,
  hasCompletedUnlockedModule,
  availablePartPaymentOptions = [],
  hasMadeFullPayment,
  sessionCountList,
  ratingStats = {},
  courseStats = {},
  courseSessionStats = {},
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredModules, setFilteredModules] = useState([]);
  const [isCourseCompleted, setIsCourseCompleted] = useState(false);
  const [openCertificatPaymentModal, setOpenCertificatePaymentModal] =
    useState(-1);

  useEffect(() => {
    setIsCourseCompleted(initialProgress === curriculumData.length);
  }, [initialProgress, courseSessionStats]);

  const GetCourseCertificate = () => {
    if (!isCourseCompleted) return null;
    return (
      <div className="flex items-center justify-between w-full px-2 space-x-3 rounded bg-theme-orange/10">
        <div className="flex">
          <Trophy
            weight="fill"
            className="min-w-[24px] w-6 h-6 text-theme-orange"
          />
          <div className="ml-2 text-base font-bold text-theme-black-color">
            Course Completed
          </div>
        </div>

        <div
          className="text-base cursor-pointer text-theme-orange"
          onClick={async () => {
            try {
              await PaymentServices.fetchPricingDetails({
                entity_type: "course",
                entity_id: courseId,
                part_payment_order: 0,
              });
              try {
                const response =
                  await PaymentServices.addUserExternalPurchaseDetails({
                    user_external_purchase_detail: {
                      entity_type: "Course",
                      entity_id: courseId,
                    },
                  });
                setOpenCertificatePaymentModal(response.entity_id);
              } catch (e) {
                console.log(e);
                if (e.error_code === "payment_already_made") {
                  // already paid
                  navigate("/certificates");
                } else {
                  // something else
                  CustomToast.error("Something went wrong");
                }
              }
            } catch (e) {
              if (e.error_code === "pricing_not_found") {
                // free certification - no need to pay
                navigate("/certificates");
              }
            }
          }}
        >
          Get Certificate
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (_.isEmpty(searchQuery)) {
      setFilteredModules([]);
      return;
    }
    const tempModules = [];
    curriculumData.forEach((module, m_index) => {
      if (module.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        tempModules.push(module);
        return;
      }
      const tempSessions = [];
      module?.sessions?.forEach((session, s_index) => {
        if (
          `${session.session_date} ${session.presenter} ${session.name}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        ) {
          tempSessions.push(session);
          return;
        }
        const tempContent = [];
        session?.session_content?.forEach((content, c_index) => {
          if (
            `${content.title} ${content.author_name} ${content.description}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ) {
            tempContent.push(content);
          }
        });
        if (tempContent.length > 0) {
          tempSessions.push({ ...session, session_content: tempContent });
        }
      });
      if (tempSessions.length > 0) {
        tempModules.push({ ...module, sessions: tempSessions });
      }
    });
    setFilteredModules(tempModules);
  }, [searchQuery]);

  useEffect(() => {
    const x = document.getElementById(
      `topic_component_${currentState.currentSessionContentId}`
    );
    if (x) {
      x.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentState]);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;

  return (
    <div className={classNames("w-full pt-0 lg:pt-6 relative", className)}>
      {courseStats?.[currentState.currentSessionContentId]?.status !==
        "completed" &&
        entityType !== "qpfp" && (
          <div className="flex w-full lg:hidden">
            <Button
              className="w-full h-10 text-base bg-theme-gray-300 hover:bg-theme-gray-400 text-theme-orange"
              width="100%"
              disabled={
                isMarkingCompleted ||
                courseStats?.[currentState.currentSessionContentId]?.status ===
                  "completed"
              }
              onClick={async () => await markCurrentAsCompleted()}
            >
              <span className="flex items-center">
                <Check size={20} className="mr-1" />
                MARK AS COMPLETED
              </span>
            </Button>
          </div>
        )}
      {showCloseButton && (
        <Button
          className="flex lg:hidden sticky top-0 z-20 mt-4 w-full text-base bg-theme-gray-300 hover:bg-theme-gray-400 !text-theme-black-color"
          width="100%"
          height="42px"
          onClick={() => onCloseShowButton()}
        >
          <span className="flex items-center">
            <X size={20} className="mr-1" />
            Close curriculum
          </span>
        </Button>
      )}
      <div className="flex items-center w-full space-x-6 text-2xl font-bold text-theme-black-color">
        <div className="flex items-center justify-between">
          Curriculum{" "}
          <SquaresFour
            className="ml-2 text-2xl cursor-pointer"
            onClick={() => {
              navigate(`/programs/${courseId}`);
            }}
          />
        </div>
        <div className="flex w-full">
          <SearchBar
            className="w-full"
            placeholder={isDesktop ? "Search by Topic or Trainer" : "Search"}
            alwaysOpen={true}
            searchTerm={searchQuery}
            onSearch={(val) => setSearchQuery(val)}
          />
        </div>
      </div>
      {isCourseCompleted ? (
        <div className="flex w-full h-10 my-4">
          <GetCourseCertificate />
        </div>
      ) : (
        entityType !== "qpfp" && (
          <div>
            <div className="flex items-center justify-between">
              <div className="w-full text-base font-bold md:text-lg text-theme-black-color">
                Your Progress{" "}
                <span className="text-theme-orange">
                  ({courseCompletionPercentage}%)
                </span>
              </div>
              <Trophy size={24} weight="fill" className="text-theme-orange" />
            </div>
            {getProgressComponent(courseCompletionPercentage)}
          </div>
        )
      )}
      {!_.isEmpty(searchQuery) && _.isEmpty(filteredModules) && (
        <EmptyState
          heading="No Result found!"
          subHeading={
            "Please modify your search to view result or click button below to resume learning"
          }
          cta={
            <Button className="!mt-4" onClick={() => setSearchQuery("")}>
              <div className="flex items-center px-3 py-1">
                <span>Clear Search</span>
              </div>
            </Button>
          }
          image={
            <img
              alt="emptyStatePlaceHolder"
              className="py-9 max-h-80"
              src={EmptyStatePlaceHolder}
            />
          }
        />
      )}
      {(_.isEmpty(searchQuery) ? curriculumData : filteredModules)?.map(
        (item, index) => (
          <div
            className={classNames(
              "w-full max-w-full pb-4",
              index === curriculumData.length - 1 ? "pb-8 lg:pb-12" : "border-b"
            )}
            key={item.id}
          >
            <ModuleAccordian
              prevCount={sessionCountList[index]}
              data={item}
              courseDataRef={courseDataRef}
              unlockCourse={unlockCourse}
              courseName={courseName}
              entityType={entityType}
              entityId={entityId}
              courseId={courseId}
              refreshCallback={refreshCallback}
              currentState={currentState}
              availablePartPaymentOptions={availablePartPaymentOptions}
              hasCompletedUnlockedModule={hasCompletedUnlockedModule}
              hasMadeFullPayment={hasMadeFullPayment}
              ratingStats={ratingStats}
              courseStats={courseStats}
              courseSessionStats={courseSessionStats}
            />
          </div>
        )
      )}
      {openCertificatPaymentModal !== -1 && (
        <PaymentModal
          open={openCertificatPaymentModal !== -1}
          title={`Certification Fee for ${courseName}`}
          entityProps={{
            entity_type: "course",
            entity_id: courseId,
            part_payment_order: 0,
          }}
          paymentEntityProps={{
            entity_type: "user_external_purchase_detail",
            entity_id: openCertificatPaymentModal,
          }}
          availablePartPaymentOptions={[{ label: "", part_payment_order: 0 }]}
          onPaymentSuccessCallback={() => {
            setOpenCertificatePaymentModal(-1);
            navigate("/certificates");
          }}
          onClose={() => {
            setOpenCertificatePaymentModal(-1);
          }}
        />
      )}
    </div>
  );
};

CurriculumAccordion.defaultProps = { currentState: {} };

CurriculumAccordion.propTypes = {
  availablePartPaymentOptions: PropTypes.array,
  className: PropTypes.string,
  courseCompletionPercentage: PropTypes.number,
  courseDataRef: PropTypes.any,
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  courseName: PropTypes.string,
  courseSessionStats: PropTypes.object,
  courseStats: PropTypes.object,
  currentState: PropTypes.object,
  curriculumData: PropTypes.array.isRequired,
  entityId: PropTypes.any,
  entityType: PropTypes.any,
  hasCompletedUnlockedModule: PropTypes.bool,
  hasMadeFullPayment: PropTypes.bool,
  initialProgress: PropTypes.number.isRequired,
  isMarkingCompleted: PropTypes.bool,
  markCurrentAsCompleted: PropTypes.func,
  onCloseShowButton: PropTypes.func,
  ratingStats: PropTypes.object,
  refreshCallback: PropTypes.func,
  sessionCountList: PropTypes.array,
  showCloseButton: PropTypes.bool,
  unlockCourse: PropTypes.bool,
};

export default CurriculumAccordion;
