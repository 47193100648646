/* eslint-disable new-cap */
import config from "../api/config";
import API from "../api/manager";

const getAllForumCategories = async () => {
  return API(
    {
      method: "GET",
      url: config.urls.exchange_forum.categories,
    },
    true
  );
};

const getAllForums = async (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.exchange_forum.list,
      params: data,
    },
    true
  );
};
const getForum = async (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.exchange_forum.fetch_forum(id),
    },
    true
  );
};

const fetchComments = async (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.exchange_forum.fetch_comments,
      params: {
        exchange_forum_id: id,
      },
    },
    true
  );
};
const createComment = async ({ data }) => {
  return API(
    {
      method: "POST",
      url: config.urls.exchange_forum.create_comments,
      data: { forum_comment: data },
    },
    true
  );
};
const createCommentReply = async ({ data }) => {
  return API(
    {
      method: "POST",
      url: config.urls.exchange_forum.create_reply,
      data: { forum_comment_reply: data },
    },
    true
  );
};
const createForum = async ({ data }) => {
  return API(
    {
      method: "POST",
      url: config.urls.exchange_forum.create,
      data: data,
    },
    true
  );
};
const likeForum = async (postId) => {
  const response = await API(
    {
      method: "POST",
      url: config.urls.clientConnect.likePost,
      data: {
        entity_id: postId,
        entity_type: "ExchangeForum",
      },
    },
    true
  );
  return response;
};
const unlikeForum = async (postId) => {
  const response = await API(
    {
      method: "DELETE",
      url: config.urls.clientConnect.likePost,
      data: {
        entity_id: postId,
        entity_type: "ExchangeForum",
      },
    },
    true
  );
  return response;
};
export const ForumService = {
  getAllForumCategories,
  getAllForums,
  getForum,
  createForum,
  fetchComments,
  createComment,
  createCommentReply,
  likeForum,
  unlikeForum,
};
