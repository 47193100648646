/* eslint-disable react-hooks/exhaustive-deps */
import { Popover, Tab, Transition } from "@headlessui/react";
import { useNavigate } from "@reach/router";
import classNames from "classnames";
import { BellSimple, Circle, CircleNotch } from "@phosphor-icons/react";
import React, { Fragment, useState } from "react";
import CustomLink from "./Link";
import moment from "moment";
import { useProfile } from "../context/userProvider";
import { UserService } from "../services/UserService";
import { onMessageListener } from "../firebase";
import { notificationRedirect } from "../constants/notificationRedirect";
import { User } from "../store";

const formatDate = (date) => {
  if (moment(date).dayOfYear() === moment().dayOfYear()) {
    return moment(date).format("[Today, ] hh:mm A");
  }
  if (moment(date).dayOfYear() + 1 === moment().dayOfYear()) {
    return moment(date).format("[Yesterday, ] hh:mm A");
  }
  return moment(date).format("DD MMM YYYY, hh:mm A");
};

const HeaderNotificationIcon = () => {
  const isLoggedIn = !!User.getToken();
  const [type, setType] = useState("notifications");
  const navigate = useNavigate();
  const { unreadNotificationCount, setUnreadNotificationCount } = useProfile();
  const [notifications, setNotifications] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  onMessageListener()
    .then((payload) => {
      const data = { ...payload.data, ...payload.notification };
      if (payload.data.kind === "notification") {
        setNotifications((prev) => [data, ...prev]);
      } else {
        setAlerts((prev) => [data, ...prev]);
      }
      setUnreadNotificationCount((prev) => prev + 1);
    })
    .catch((err) => console.log("failed: ", err));

  const fetchData = async () => {
    setIsLoading(true);
    const response1 = await UserService.fetchNotifications({
      kind: "notification",
    });
    setNotifications(response1.user_notifications);
    const response2 = await UserService.fetchNotifications({ kind: "alert" });
    setAlerts(response2.user_notifications);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const onNotificationClick = async (notification, status) => {
    try {
      await UserService.readNotification(notification.id);
      if (status === "unread") {
        setUnreadNotificationCount((prev) => prev - 1);
        setNotifications((prev) =>
          prev.map((notif) =>
            notif.id === notification.id ? { ...notif, status: "read" } : notif
          )
        );
      }
      notificationRedirect({
        entity_id: notification.entity_id,
        entity_type: notification.entity_type,
        additional_data: notification.additional_data,
        notification_key: notification.notification_key,
        navigate,
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (!isLoggedIn) return null;

  return (
    <Popover>
      <Popover.Button
        className="relative block my-auto"
        onClick={(e) => {
          setType("notifications");
          fetchData();
        }}
      >
        <BellSimple size={24} weight="fill" />
        {(unreadNotificationCount ?? 0) !== 0 && (
          <div className="absolute flex items-center justify-center leading-4 text-white border-2 border-white rounded-full min-w-[20px] aspect-square text-xxs -top-1 -right-1 bg-theme-orange">
            {unreadNotificationCount}
          </div>
        )}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-10 w-[calc(100%-32px)] p-4 md:max-w-sm max-h-[75vh] overflow-auto">
          <div className="flex flex-col w-full py-4 bg-white rounded-md shadow-sm overflow-clip ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="flex justify-between px-4 mb-6">
              <div className="text-base font-bold leading-none">
                Notifications
              </div>
              <CustomLink
                href="/notifications"
                textColor="text-theme-red"
                activeTextColor="text-theme-red"
                fontSize="sm"
                showUnderLine={false}
                className="leading-5"
              >
                VIEW ALL
              </CustomLink>
            </div>
            <Tab.Group>
              <Tab.List className="flex p-0.5 mx-4 space-x-1 w-[calc(100%-32px)] md:min-w-328 rounded bg-neutral-100">
                {["notifications", "alerts"].map((category) => (
                  <Tab
                    key={category}
                    onClick={() => {
                      setType(category);
                    }}
                    className={({ selected }) =>
                      classNames(
                        "w-full text-center rounded py-1.5 leading-none text-sm text-theme-black-color capitalize ring-offset-0 focus:outline-none focus:ring-0",
                        selected ? "bg-white shadow" : "hover:bg-white/[0.12]"
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
            <div className="flex flex-col w-full">
              {isLoading && (
                <div className="mt-5">
                  <CircleNotch
                    className="mx-auto text-theme-black-color animate-spin"
                    size={44}
                    weight="fill"
                  />
                </div>
              )}
              {!isLoading &&
                (type === "notifications" ? notifications : alerts).length ===
                  0 && (
                  <div className="flex items-center justify-center gap-4 p-4 pb-0 text-sm italic font-medium leading-4 text-theme-black-color">
                    No {type}
                  </div>
                )}
              {!isLoading &&
                (type === "notifications" ? notifications : alerts)?.map(
                  (notification) => (
                    <div
                      key={notification.id}
                      className={classNames(
                        "flex flex-col gap-2 w-full p-4 border-b hover:bg-neutral-100/30 border-neutral-100 cursor-pointer",
                        notification.status === "read" && "bg-neutral-100/50"
                      )}
                      onClick={() =>
                        onNotificationClick(notification, notification.status)
                      }
                    >
                      <div className="flex items-start gap-4 text-sm leading-4 text-theme-black-color">
                        {notification?.title || ""}
                        {notification.status === "unread" && (
                          <Circle
                            className="inline-block min-w-[8px] w-2 h-2 my-1"
                            weight="fill"
                            color="#f36c04"
                          />
                        )}
                      </div>
                      <div className="text-xs text-neutral-400">
                        {formatDate(notification?.created_at)}
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default HeaderNotificationIcon;
