import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import Button from "../../stories/Button";
import ChangeMobileNumberModal from "./ChangeMobileNumberModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { useProfile } from "../../context/userProvider";
import { X } from "@phosphor-icons/react";
import UpdateAddressModal from "./UpdateAddressModal";

const AccountCredentialModal = ({ open, onClose, className }) => {
  const { userDetails, updateUserData } = useProfile();
  const [openPhonePopup, setOpenPhonePopup] = useState(false);
  const [openPasswordPopup, setOpenPasswordPopup] = useState(false);
  const [opneAddressPopup, setOpenAddressPopup] = useState(false);

  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full p-4 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-3 text-base font-semibold leading-6 text-theme-black-color"
                >
                  Account Credentials
                </Dialog.Title>
                <div className="flex flex-col mt-2 space-y-4">
                  <div className={classNames("flex w-full flex-col")}>
                    <div className="text-sm font-medium tracking-tight text-left text-theme-black-300">
                      Email Address
                    </div>
                    <div className="mt-0.5 break-words text-base leading-6 text-theme-black-color tracking-tight text-left font-normal">
                      {userDetails.email}
                    </div>
                  </div>

                  <div className="flex items-start justify-between w-full">
                    <div className="flex flex-col ">
                      <div className="text-sm font-medium tracking-tight text-left text-theme-black-300">
                        Mobile No.
                      </div>
                      <div className="mt-0.5 text-base leading-6 text-theme-black-color tracking-tight text-left font-normal">
                        {userDetails.phone_number}
                      </div>
                    </div>
                    <div className="flex flex-col ">
                      <Button
                        buttonStyle="transparent-textonly-primary"
                        size="no-size"
                        onClick={async () => setOpenPhonePopup(true)}
                      >
                        <div className="text-sm ">UPDATE NUMBER</div>
                      </Button>
                    </div>
                  </div>
                  <div className="flex items-start justify-between w-full">
                    <div className="flex flex-col ">
                      <div className="text-sm font-medium tracking-tight text-left text-theme-black-300">
                        Password
                      </div>
                      <div className="mt-0.5 text-base leading-6 text-theme-black-color tracking-tight text-left font-normal">
                        {userDetails.masked_password}
                      </div>
                    </div>
                    <div className="flex flex-col ">
                      <Button
                        buttonStyle="transparent-textonly-primary"
                        size="no-size"
                        onClick={async () => setOpenPasswordPopup(true)}
                      >
                        <div className="text-sm ">CHANGE PASSWORD</div>
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between text-sm font-medium tracking-tight text-left text-theme-black-300">
                      Address
                      <Button
                        buttonStyle="transparent-textonly-primary"
                        size="no-size"
                        width="130px"
                        className="!justify-end"
                        onClick={async () => setOpenAddressPopup(true)}
                      >
                        <div className="text-sm">UPDATE ADDRESS</div>
                      </Button>
                    </div>

                    <div className="mt-0.5 flex text-base leading-6 text-theme-black-color tracking-tight text-left font-normal">
                      {userDetails?.address?.id
                        ? `${userDetails.address.line_1}, ${userDetails.address.city}, ${userDetails.address.state} - ${userDetails.address.pin}`
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
          {openPhonePopup && (
            <ChangeMobileNumberModal
              open={openPhonePopup}
              onClose={() => setOpenPhonePopup(false)}
            />
          )}
          {openPasswordPopup && (
            <ChangePasswordModal
              open={openPasswordPopup}
              onUpdate={() => updateUserData()}
              onClose={() => setOpenPasswordPopup(false)}
            />
          )}
          {opneAddressPopup && (
            <UpdateAddressModal
              open={opneAddressPopup}
              onUpdate={() => updateUserData()}
              onClose={() => setOpenAddressPopup(false)}
            />
          )}
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

AccountCredentialModal.defaultProps = {
  open: false,
  className: "",
};

AccountCredentialModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default AccountCredentialModal;
