import React, { useState } from "react";

// eslint-disable-next-line react/prop-types
const Pagination = ({ totalEntries, entriesPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage;
    let endPage;

    if (totalPages <= 6) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 6;
      } else if (currentPage > totalPages - 3) {
        startPage = totalPages - 5;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 3;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handleClick(i)}
          className={`px-3 py-1 border-r ${
            currentPage === i
              ? "bg-orange-400 bg-opacity-10 text-[#F15822]"
              : "text-gray-700 border-gray-300"
          }`}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="text-gray-700">
        Showing {Math.min((currentPage - 1) * entriesPerPage + 1, totalEntries)}{" "}
        to {Math.min(currentPage * entriesPerPage, totalEntries)} of{" "}
        {totalEntries} Entries
      </div>

      <div className="flex items-center border rounded border-gray-300">
        {/* First Button */}
        <button
          onClick={() => handleClick(1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 border-r ${
            currentPage === 1
              ? "text-gray-300 border-gray-300"
              : "text-gray-700"
          }`}
        >
          First
        </button>

        {/* Previous Button */}
        <button
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 border-r ${
            currentPage === 1
              ? "text-gray-300 border-gray-300"
              : "text-gray-700"
          }`}
        >
          {"<"}
        </button>

        {/* Page Numbers */}
        {renderPageNumbers()}

        {/* Next Button */}
        <button
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 border-r ${
            currentPage === totalPages ? "text-gray-300" : "text-gray-700"
          }`}
        >
          {">"}
        </button>

        {/* Last Button */}
        <button
          onClick={() => handleClick(totalPages)}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 ${
            currentPage === totalPages ? "text-gray-300" : "text-gray-700"
          }`}
        >
          Last
        </button>
      </div>
    </div>
  );
};

export default Pagination;
