/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "@reach/router";
import axios from "axios";
import QueryString from "qs";
import React, { useEffect } from "react";
import { AuthService } from "../services/AuthService";
import { useProfile } from "../context/userProvider";
import { CustomToast } from "./Toast";
import { CalendarService } from "../services/CalendarService";

const RedirectTo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateUserData } = useProfile();

  const handleGoogleSignInCallback = async () => {
    try {
      const params = new URL(location.href).searchParams;
      const response = await axios.post(
        "https://oauth2.googleapis.com/token",
        QueryString.stringify({
          code: params.get("code"),
          client_id: process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID,
          client_secret: process.env.REACT_APP_PROD_GOOGLE_CLIENT_SECRET,
          redirect_uri: `${window.location.origin}/redirectTo/google`,
          grant_type: "authorization_code",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const access_token = response.data?.access_token;
      const refresh_token = response.data?.refresh_token;

      const response1 = await axios.get(
        "https://www.googleapis.com/oauth2/v1/userinfo",
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      const uid = response1.data?.id;
      const response2 = await AuthService.addGoogleIdentity({
        provider: "google_oauth2",
        uid: uid,
        credentials: {
          access_token: access_token,
          refresh_token: refresh_token,
        },
      });
      const action = JSON.parse(params.get("state"));
      if (action.action === "addToCalendar") {
        const course_session_content_id = action.course_session_content_id;
        await addToCalendar(course_session_content_id);
        await updateUserData();
        navigate("/nfp-calendar");
      }
      CustomToast.success("Google sign in successfully");
    } catch (e) {
      console.log(e);
    }
  };

  const addToCalendar = async (course_session_content_id) => {
    const response = await CalendarService.addMeetingToGoogleCalendar({
      data: {
        provider: "google",
        course_session_content_id: course_session_content_id,
      },
    });
    CustomToast.success(response.message);
  };

  const handleNotificationClick = () => {};

  useEffect(() => {
    if (props.from === "google") {
      handleGoogleSignInCallback();
    } else if (props.from === "notification") {
      handleNotificationClick();
    }
  }, []);
  return <div>Redirecting...</div>;
};

RedirectTo.propTypes = {
  from: PropTypes.string,
};

export default RedirectTo;
