import classNames from "classnames";
import React from "react";
import Card from "./Card";
import PropTypes from "prop-types";
import { BulletCard } from "./BulletList";

const ProUpgradeCard = ({
  headingClassName,
  descriptionClassName,
  heading,
  description,
  content_label = "What you will get",
  benefits,
  className,
  cta,
}) => {
  return (
    <Card
      className={className}
      header={
        <div
          className={classNames(
            "flex items-center text-base font-semibold leading-5 text-left text-theme-black-color tracking-tighter",
            headingClassName
          )}
        >
          {heading}
        </div>
      }
      cta={cta}
      cardBody={
        <div className="flex flex-col items-start justify-start w-full mt-2 ">
          <div
            className={classNames(
              "flex w-full text-xs font-light leading-none text-left text-theme-black-300 -tracking-[0.63px]",
              descriptionClassName
            )}
          >
            {description}
          </div>
          <BulletCard
            header={content_label}
            list={benefits}
            className="my-4 border-none"
          />
        </div>
      }
    />
  );
};
ProUpgradeCard.defaultProps = {
  heading: "",
  description: "",
  benefits: [],
};
ProUpgradeCard.propTypes = {
  benefits: PropTypes.array,
  className: PropTypes.string,
  content_label: PropTypes.string,
  cta: PropTypes.element,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  heading: PropTypes.string,
  headingClassName: PropTypes.string,
};

export default ProUpgradeCard;
