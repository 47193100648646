import React, { useState } from "react";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import OnboardingHeader from "../../stories/OnboardingHeader";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import { Formik, Form } from "formik";
import { AuthService } from "../../services/AuthService";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { CustomToast } from "../../stories/Toast";
import Link from "../../stories/Link";
import { Validations } from "../../constants/validations";
import email_anim from "../../assets/animations/email_lottie_anim.gif";

const ForgetPasswordScreen = () => {
  const [isMailSent, setIsMailSent] = useState(false);
  const [initialValues, setinitialValues] = useState({ email: "" });
  useDocumentTitle("Forgot Password");

  const handleForgotPassword = async ({ email }) => {
    try {
      const result = await AuthService.forgotPassword({
        email,
      });
      if (result.message) {
        setinitialValues({ email });
        setIsMailSent(true);
        CustomToast.success(result.message);
      }
    } catch (e) {
      if (!e.success) {
        CustomToast.error(e.error_messages[0]);
      } else {
        CustomToast.error("Something went wrong");
      }
    }
  };

  const sendEmailVerificationLink = async (e) => {
    e.preventDefault();

    try {
      const result = await AuthService.forgotPassword({
        email: initialValues.email,
      });

      if (result.message) {
        CustomToast.success(result.message);
      } else {
        CustomToast.error(result.errors);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          {!isMailSent ? (
            <div className="flex flex-col">
              <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
                Forgot Password
              </div>
              <div className="flex mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
                Enter your email and we&apos;ll send you a link to reset your
                password.
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={() =>
                  Validations.onBoarding.forgotPasswordValidationSchema
                }
                onSubmit={handleForgotPassword}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="w-full mt-8 md:mt-12">
                      <AuthTextInput
                        name="email"
                        id="email"
                        type="text"
                        label="Email"
                      />
                    </div>
                    <Button
                      type="submit"
                      buttonStyle="primary"
                      height="60px"
                      width="100%"
                      className="mt-6"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      EMAIL ME RECOVERY LINK
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
                Forgot Password
              </div>
              <div className="mt-3">
                <Link
                  textColor="text-theme-red"
                  activeTextColor="text-theme-red"
                  showUnderLine={false}
                  className="text-base leading-none"
                  onClick={(e) => sendEmailVerificationLink(e)}
                >
                  Click here
                </Link>
                , if you have not received the email with reset instructions.
              </div>
              <div className="flex items-center w-full mt-2">
                <img
                  src={email_anim}
                  alt="email sent"
                  width="378.3px"
                  height="329px"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
    </div>
  );
};

export default ForgetPasswordScreen;
