/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "@reach/router";
import { Validations } from "../../../constants/validations";
import { Form, Formik } from "formik";
import Button from "../../../stories/Button";
import PaymentForm from "../../Payments/PaymentForm";
import { PaymentServices } from "../../../services/PaymentServices";
import Loader from "../../Shimmer/Loader";
import { CustomToast } from "../../../stories/Toast";
import { useProfile } from "../../../context/userProvider";

const PaymentScreen = ({ courseId, publicProfileData, onSubmit }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const entityProps = {
    entity_type: "course",
    entity_id: courseId,
    part_payment_order: 0,
  };
  const externalPurchaseDetails = useRef({});
  const [initialValues, setInitialValues] = useState({
    part_payment_order: entityProps.part_payment_order,
    firmName: "",
    wantGST: true,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState: "",
  });
  const submitRef = useRef();
  const [priceDetails, setPriceDetails] = useState({});
  const { userDetails } = useProfile();

  const fetchPricingDetails = async () => {
    try {
      const response = await PaymentServices.fetchPricingDetails(entityProps);
      setPriceDetails(response);
    } catch (e) {}
  };
  const fetchData = async () => {
    await fetchGSTDetails();
    await fetchPricingDetails();
    setIsLoading(false);
  };
  const fetchGSTDetails = async () => {
    try {
      const response = await PaymentServices.fetchPreviousGSTDetails();

      setInitialValues((prevState) => {
        if (response.gst_invoice_check === false) return prevState;
        return {
          ...prevState,
          wantGST: response.gst_invoice_check,
          gstin: response.gst_number,
          gstFirmName: response.firm_name,
          gstCommunicationAddress: response.address.line_1,
          gstPincode: response.address.pin,
          gstCity_id: response.address.city_id,
          gstCity: response.address.city,
          gstState_id: response.address.state_id,
        };
      });
    } catch (e) {
      console.log("no previous details found");
    }
  };

  const makePayment = async (values) => {
    let postBody = {
      pricing_id: priceDetails.pricing_id,
      coupon_ids: priceDetails.coupons?.map((e) => e.code),
      payment_gateway: "razorpay",
      course_fees: priceDetails.course_fees,
      gst: priceDetails.gst,
      to_be_paid: priceDetails.to_be_paid,
    };
    if (values.wantGST) {
      postBody = {
        ...postBody,
        gst_invoice_check: values.wantGST,
        gst_number: values.gstin,
        firm_name: values.gstFirmName,
        address_attributes: {
          line_1: values.gstCommunicationAddress,
          city_id: values.gstCity_id,
          pin: values.gstPincode,
        },
      };
    }

    const orderDetails = await PaymentServices.createOrder({
      purchase: postBody,
      ...externalPurchaseDetails.current,
    });

    if (orderDetails && orderDetails.payment_status === "initiated") {
      await PaymentServices.makeRazorpayPayment(
        {
          name: userDetails.first_name + " " + userDetails.last_name,
          email: userDetails.email,
          contact: userDetails.phone_number,
        },
        orderDetails,
        () => {
          CustomToast.success("Payment Successful" ?? "");
          onSubmit();
        }
      );
    } else if (orderDetails && orderDetails.payment_status === "success") {
      CustomToast.info(orderDetails.message);
      onSubmit();
    } else {
      CustomToast.error(orderDetails.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="w-full max-w-3xl bg-white p-4 shadow-card-shadow -mt-20 flex mx-auto flex-col rounded z-10">
        <Loader />
      </div>
    );
  }
  return (
    <div className="w-full max-w-3xl text-center flex flex-col bg-white shadow-card-shadow items-start -mt-20 mx-auto rounded p-4 z-10">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Validations.qpfpCertification.paymentValidationSchema}
        onSubmit={async (values) => {
          try {
            const response =
              await PaymentServices.addUserExternalPurchaseDetails({
                user_external_purchase_detail: {
                  entity_type: "Course",
                  entity_id: courseId,
                },
              });
            externalPurchaseDetails.current = {
              entity_id: response.entity_id,
              entity_type: response.entity_type,
            };
            const result1 = await makePayment(values);
            return result1;
          } catch (e) {
            CustomToast.error(e.error_messages[0]);
            if (e.error_code === "already_paid") {
            }
            console.log("error saving the form");
            return e;
          }
        }}
      >
        {({ values, ...formProps }) => {
          return (
            <Form className="w-full">
              <div className="flex flex-col w-full gap-6 mt-2 bg-white rounded-sm shadow-sm lg:shadow-none">
                <div className="flex flex-col w-full items-start">
                  <div className="text-xl font-medium leading-5 tracking-tight text-theme-black-color">
                    QPFP Certification Payment
                  </div>
                  <div className="mt-1 text-sm font-normal text-left tracking-tight text-theme-black-300">
                    QPFP Certification first-time fees is towards first-time
                    certification benefits and first-year certification
                    benefits.
                  </div>
                </div>

                {userDetails?.pm_signup_status === "approved" ||
                userDetails?.pm_signup_status === "expires_soon" ? (
                  <div className="flex mt-2 flex-col items-start bg-[#FFBE5C20] border border-theme-orange/20 rounded p-4 gap-2">
                    <p className="font-bold text-sm text-theme-black-color text-left">
                      Get Your QPFP Certification with discount
                    </p>
                    <p className="font-medium text-xs text-theme-red text-left">
                      As a valued Pro Member, enjoy an exclusive ₹3000 discount
                      just for you!
                    </p>
                  </div>
                ) : (
                  <div className="flex mt-2 flex-col items-start bg-[#FFBE5C20] border border-theme-orange/20 rounded p-4 gap-2">
                    <p className="font-bold text-sm text-theme-black-color text-left">
                      {userDetails?.pm_signup_status === "overdue"
                        ? "Renew your NFP Promembership"
                        : "Become a NFP ProMember"}{" "}
                      for additional &amp; advanced benefits
                    </p>

                    <div className="flex flex-col items-center space-y-3 md:flex-row md:space-y-0">
                      <p className="flex-1 font-medium text-xs text-theme-red text-left">
                        {userDetails?.pm_signup_status === "overdue"
                          ? "Renew"
                          : "Enroll"}{" "}
                        in Pro Membership now and receive an exclusive ₹3000
                        discount on your QPFPcertification payment!
                      </p>

                      <Button
                        onClick={() =>
                          navigate(
                            userDetails?.pm_signup_status === "overdue"
                              ? "/payments"
                              : "/members/pro_member/register"
                          )
                        }
                        className="!rounded-md"
                      >
                        {userDetails?.pm_signup_status === "overdue"
                          ? "Renew Your"
                          : "Enroll For"}{" "}
                        ProMembership
                      </Button>
                    </div>
                  </div>
                )}

                <PaymentForm
                  programTitle={"QPFP Certification Fees"}
                  allowPartPayment={false}
                  entityProps={entityProps}
                  availablePartPaymentOptions={[
                    { label: "", part_payment_order: 0 },
                  ]}
                  priceDetails={priceDetails}
                  setPriceDetails={setPriceDetails}
                  submitRef={submitRef}
                  formikProps={{ values, ...formProps }}
                  isReadOnly={false}
                  cta={
                    <React.Fragment>
                      <Button
                        buttonStyle="primary"
                        height="40px"
                        width="100%"
                        className="mt-3"
                        onClick={async () => {
                          await submitRef.current?.click();
                        }}
                      >
                        PAY NOW
                      </Button>
                      {publicProfileData?.firm?.status === "approved" && (
                        <Button
                          buttonStyle="secondary"
                          height="40px"
                          width="100%"
                          className="mt-3"
                          onClick={async () => {
                            // const response = await formProps.validateForm(
                            //   values
                            // );
                            // if (_.isEmpty(response)) {
                            //   await sendPaymentRequestToAdmin();
                            //   setOpenPaymentRequestedModal(true);
                            // } else {
                            //   formProps.setTouched({
                            //     part_payment_order: true,
                            //     firmName: true,
                            //     wantGST: true,
                            //     gstin: true,
                            //     gstFirmName: true,
                            //     gstCommunicationAddress: true,
                            //     gstPincode: true,
                            //     gstCity_id: true,
                            //     gstState: true,
                            //   });
                            // }
                          }}
                        >
                          SEND PAYMENT DETAILS TO ADMIN
                        </Button>
                      )}
                    </React.Fragment>
                  }
                />

                <button
                  disabled={formProps.isSubmitting}
                  className="hidden"
                  ref={submitRef}
                  type="submit"
                >
                  submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PaymentScreen.propTypes = {
  courseId: PropTypes.number,
  onSubmit: PropTypes.func,
  publicProfileData: PropTypes.object,
};

export default PaymentScreen;
