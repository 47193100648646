import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import Button from "../../../stories/Button";
import promember_badge from "../../../assets/images/pro_badge.png";
import SearchUserModal from "./SearchUserModal";
import { Form, Formik } from "formik";
import TextInput from "../../../stories/TextInput";
import _ from "lodash";
import { Validations } from "../../../constants/validations";
import ImageComponent from "../../../stories/ImageComponent";
import { User, UserMinus } from "@phosphor-icons/react";
import { PublicProfileService } from "../../../services/PublicProfileService";
import { AuthService } from "../../../services/AuthService";
import { CustomToast } from "../../../stories/Toast";
import { useProfile } from "../../../context/userProvider";

const AddMembers = ({ stepProps, firmId, corporateProfileData }) => {
  const [inviteEmails, setInviteEmails] = useState([
    { email: "", edit: true, id: "id-" + Math.random().toString(16).slice(2) },
  ]);
  const [openSearchUserModal, setOpenSearchUserModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const submitButtonRef = useRef();
  const { userDetails } = useProfile();
  useEffect(() => {
    setSelectedUsers(
      corporateProfileData?.firm_users
        ?.filter(
          (e) => e.status !== "new_user_pending" && e.status !== "rejected"
        )
        ?.map((e) => e.user)
    );
    const tempEmails =
      corporateProfileData?.firm_users
        ?.filter((e) => e.status === "new_user_pending")
        ?.map((e) => ({
          email: e.user.email,
          edit: false,
          id: "id-" + Math.random().toString(16).slice(2),
        })) ?? [];
    setInviteEmails(
      tempEmails.length === 0
        ? [
            {
              email: "",
              edit: true,
              id: "id-" + Math.random().toString(16).slice(2),
            },
            {
              email: "",
              edit: true,
              id: "id-" + Math.random().toString(16).slice(2),
            },
          ]
        : tempEmails.length === 1
        ? [
            ...tempEmails,
            {
              email: "",
              edit: true,
              id: "id-" + Math.random().toString(16).slice(2),
            },
          ]
        : [...tempEmails]
    );
  }, [corporateProfileData?.firm_users]);

  const onFormSubmit = async (values) => {
    try {
      const existingFirmUserId =
        corporateProfileData?.firm_users?.map((e) => e.user.id) ?? [];
      const tempSelectedUsers = [...selectedUsers].filter(
        (u) => !existingFirmUserId.includes(u.id)
      );
      const response = await PublicProfileService.inviteNewUsers({
        data: {
          id: firmId,
          user_id: tempSelectedUsers.map((u) => u.id),
          email: values.users.map((u) => u.email),
        },
      });
      const emailList = values.users.map((u) => u.email);
      const selectedIdList = selectedUsers.map((u) => u.id);
      let deleteIds = [];
      deleteIds = [
        ...corporateProfileData?.firm_users
          ?.filter(
            (e) =>
              e.status === "new_user_pending" &&
              !emailList.includes(e.user.email)
          )
          .map((e) => e.id),
        ...corporateProfileData?.firm_users
          ?.filter(
            (e) => e.status === "pending" && !selectedIdList.includes(e.user.id)
          )
          .map((e) => e.id),
      ];
      if (deleteIds.length > 0) {
        await PublicProfileService.deleteFirmUsers({
          firm_user_list: deleteIds,
        });
      }
      if (
        response.firm_user_data &&
        _.values(response.firm_user_data ?? {}).includes("not_created")
      ) {
        CustomToast.error(
          `${tempSelectedUsers
            .filter((u) => response.firm_user_data[u.id] !== "created")
            .map((u) => `${u.first_name} ${u.last_name}`)
            .join(", ")} was not invited`
        );
      }
      stepProps.handleNext();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <div className={classNames("flex")}>
        <div
          className={classNames(
            "max-w-3xl lg:min-w-[764px] w-full gap-y-3 flex flex-col p-3 bg-white rounded-sm shadow-sm lg:p-4"
          )}
        >
          <div className="flex flex-col">
            <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
              Add Existing NFP Users & Members
            </div>
            <div className="mt-1 text-sm font-light tracking-tight text-theme-black-300">
              You can add any of your team members who already have an account
              with Network FP i.e. they already have an account at{" "}
              <b>learn.networkfp.com</b> as a Basic Member, Community Member,
              QPFP Certicant, QPFP Candidate or ProMember
            </div>
            <div className="flex flex-col gap-4 p-3 mt-3 border border-gray-200 rounded">
              {selectedUsers && selectedUsers.length > 0 && (
                <div className="flex-col flex gap-2.5 max-h-64 overflow-y-auto">
                  {selectedUsers.map((user, index) => (
                    <div key={user.id} className="flex gap-x-1.5 items-center">
                      <ImageComponent
                        alt="profile-icon"
                        src={user?.profile_picture?.signed_url ?? ""}
                        errorComponent={
                          <div className="w-12 h-12 flex min-w-[48px] justify-center items-center bg-gray-200 rounded-full">
                            <User
                              size={24}
                              weight="bold"
                              className="text-gray-400"
                            />
                          </div>
                        }
                        className="object-cover w-12 h-12 rounded-full lg:h-12 lg:w-12 "
                      />
                      <div className="flex flex-col items-start">
                        <div className="flex gap-1 text-xs font-medium leading-4 text-theme-black-color">
                          {user.first_name} {user.last_name}
                          {user.is_pro_member && (
                            <img
                              alt="pro"
                              src={promember_badge}
                              className="object-contain w-4 h-4 min-w-[16px]"
                            />
                          )}
                        </div>
                        {user.email && (
                          <div className="leading-4 tracking-tight text-xxs text-theme-black-300">
                            {user.email}
                          </div>
                        )}
                        {(user.city_name || user.firm_name) && (
                          <div className="leading-4 tracking-tight text-xxs text-theme-black-300">
                            {user.firm_name}
                            {user.city_name && user.firm_name && " - "}
                            {user.city_name}
                          </div>
                        )}
                      </div>
                      {userDetails.id !== user.id && (
                        <div
                          className="flex ml-4 items-center justify-center border group cursor-pointer rounded hover:bg-theme-red-300 border-theme-red-300 w-6 h-6 min-w-[24px]"
                          onClick={() => {
                            setSelectedUsers((prev) => {
                              const temp = [...prev];
                              temp.splice(index, 1);
                              return temp;
                            });
                          }}
                        >
                          <UserMinus
                            size={16}
                            weight="duotone"
                            className="text-theme-red-300 group-hover:text-white"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div
                className="text-sm font-semibold tracking-tight uppercase cursor-pointer w-fit text-theme-red"
                onClick={() => setOpenSearchUserModal(true)}
              >
                Search User / Member
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
              Invite New Users
            </div>
            <div className="mt-1 text-sm font-light tracking-tight text-theme-black-300">
              You may also invite your team members to create an account and
              avail of any of the paid/ free benefits.<br></br>{" "}
              <b>A email will be sent to them with account creation link.</b>
            </div>

            <Formik
              validationSchema={
                Validations.corporate.inviteUserValidationSchema
              }
              initialValues={{ users: inviteEmails }}
              enableReinitialize
              onSubmit={onFormSubmit}
            >
              {({ values, ...formProps }) => (
                <Form className="mt-3">
                  <div className="grid grid-cols-2 gap-6">
                    {values.users.map((user, index) =>
                      user.edit ? (
                        <TextInput
                          key={user.id}
                          name={`users.${index}.email`}
                          label="Enter Email of Team Member"
                          placeholder="Email Address"
                          id={`users.${index}.email`}
                          type="text"
                          onBlur={async () => {
                            formProps.setFieldTouched(
                              `users.${index}.email`,
                              true
                            );
                            if (
                              !_.isEmpty(values.users[index].email) &&
                              _.isEmpty(
                                formProps?.errors?.users?.[index]?.email
                              )
                            ) {
                              await AuthService.validateEmail({
                                email: values.users[index].email,
                              })
                                .then(async (res) => {
                                  formProps.setFieldValue(
                                    `users.${index}.edit`,
                                    false,
                                    true
                                  );
                                })
                                .catch((e) => {
                                  formProps.setFieldError(
                                    `users.${index}.email`,
                                    "Email address already present"
                                  );
                                });
                            }
                          }}
                        />
                      ) : (
                        <div
                          key={user.id}
                          className="flex gap-x-1.5 items-center"
                        >
                          <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                          <div className="flex flex-col items-start ">
                            <div className="flex gap-1 text-xs font-medium leading-4 text-theme-black-color">
                              {user.email || "EMAIL"}
                            </div>
                            <div
                              className="font-medium leading-4 tracking-tight cursor-pointer text-xxs text-theme-red"
                              onClick={() => {
                                formProps.setFieldValue(
                                  `users.${index}.edit`,
                                  true
                                );
                              }}
                            >
                              EDIT
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="flex w-full mt-1 text-sm font-medium text-left text-red-600">
                    {_.isString(formProps.errors.users) &&
                      formProps.errors.users}
                  </div>
                  <div
                    className="mt-4 text-sm font-medium tracking-tight uppercase cursor-pointer w-fit col-span-full text-theme-red"
                    onClick={() => {
                      formProps.setFieldValue(
                        "users",
                        [
                          ...values.users,
                          {
                            email: "",
                            edit: true,
                            id: "id-" + Math.random().toString(16).slice(2),
                          },
                        ],
                        true
                      );
                    }}
                  >
                    + Invite More
                  </div>
                  <button
                    className="hidden"
                    ref={submitButtonRef}
                    disabled={formProps.isSubmitting}
                    type="submit"
                  >
                    submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="grid grid-cols-1 mt-3 lg:mt-6 lg:grid-cols-2 gap-y-3 lg:gap-x-6">
            <Button
              buttonStyle="gray"
              height="40px"
              className="order-2 lg:order-1"
              onClick={async () => {
                stepProps.handleBack();
              }}
            >
              BACK
            </Button>
            <Button
              buttonStyle="primary"
              height="40px"
              className="order-1 lg:order-2"
              onClick={async () => {
                submitButtonRef.current.click();
              }}
            >
              NEXT
            </Button>
          </div>
        </div>
      </div>
      {openSearchUserModal && (
        <SearchUserModal
          open={openSearchUserModal}
          preSelectedUsers={selectedUsers}
          onAdd={(users) => setSelectedUsers(users)}
          showPreselectedUsers={false}
          onClose={() => setOpenSearchUserModal(false)}
        />
      )}
    </React.Fragment>
  );
};

AddMembers.propTypes = {
  corporateProfileData: PropTypes.shape({
    firm_users: PropTypes.any,
  }),
  firmId: PropTypes.any,
  stepProps: PropTypes.shape({
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
  }),
};

export default AddMembers;
