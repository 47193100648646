import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import Ratings from "./Ratings";
import ImageComponent from "./ImageComponent";
import { User } from "@phosphor-icons/react";
import moment from "moment";
import CustomLink from "./Link";
import { getDirectoryMemberURL } from "../util/getURLs";

const RatingsCard = ({
  id,
  profileSlug,
  userImage,
  rating,
  time,
  content,
  name,
}) => {
  return (
    <div className="flex w-full space-x-2" data-review-id={id}>
      <CustomLink
        textColor="text-blue-600"
        activeTextColor="text-blue-600"
        showUnderLine={false}
        underLineOnlyOnHover={false}
        fontSize="sm"
        target="_blank"
        href={getDirectoryMemberURL(profileSlug)}
        className="font-normal leading-5 tracking-tight h-fit"
      >
        <ImageComponent
          src={userImage}
          alt="user"
          errorComponent={
            <div className="flex items-center justify-center min-w-[32px] md:min-w-[48px] w-8 h-8 bg-gray-200 rounded-full md:h-12 md:w-12">
              <User weight="bold" className="w-6 h-6 text-gray-400" />
            </div>
          }
          className="object-cover w-8 h-8 rounded-full md:h-12 md:w-12 min-w-[32px] md:min-w-[48px]"
        />
      </CustomLink>
      <div className="flex flex-col space-y-1.5">
        <div>
          <CustomLink
            textColor="text-blue-600"
            activeTextColor="text-blue-600"
            showUnderLine={false}
            underLineOnlyOnHover={false}
            fontSize="sm"
            target="_blank"
            href={getDirectoryMemberURL(profileSlug)}
            className="font-normal leading-5 tracking-tight"
          >
            <Text
              type="text"
              size="text-sm md:text-base"
              content={name}
              className="font-semibold text-theme-black-color"
            />
          </CustomLink>
          <Ratings className="text-sm" ratings={rating} />
        </div>
        <Text
          content={content}
          type="text"
          size="text-sm md:text-base"
          className="text-theme-black-color "
        />
        <div className="flex items-center text-xs leading-none mt-1.5 font-normal text-neutral-400 md:text-sm">
          {moment(time).fromNow()}
        </div>
      </div>
    </div>
  );
};

Text.defaultProps = {
  className: "",
};

RatingsCard.propTypes = {
  content: PropTypes.string,
  id: PropTypes.any,
  location: PropTypes.string,
  name: PropTypes.string,
  profileSlug: PropTypes.string,
  rating: PropTypes.number,
  time: PropTypes.string,
  userImage: PropTypes.string,
};

export default RatingsCard;
