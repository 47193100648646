import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import { X } from "@phosphor-icons/react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { canvasPreview } from "./CanvasPreview";
import "react-image-crop/dist/ReactCrop.css";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        x: 25,
        y: 25,
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const CropImageModal = ({ open, file, onClose, onSubmit, className }) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const aspect = 1;
  const scale = 1;
  const rotate = 0;

  useEffect(() => {
    if (file) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(file);
    }
  }, [file]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  const submitCurrentCrop = () => {
    const base64 = previewCanvasRef.current.toDataURL("image/jpeg");
    onSubmit(base64);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="w-full inline-block my-auto mx-2 p-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 max-h-[75%]">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={() => {
                    onSubmit(-1);
                    onClose();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="mb-3 text-base font-semibold leading-6 text-theme-black-color"
              >
                Crop Image
              </Dialog.Title>
              {Boolean(imgSrc) && (
                <div className="max-h-[65%] bg-theme-black-300">
                  <div className="mx-auto w-fit">
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                          maxHeight: "55vh",
                        }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  </div>
                </div>
              )}
              <div>
                {Boolean(completedCrop) && (
                  <canvas
                    ref={previewCanvasRef}
                    className="hidden"
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                )}
              </div>
              <div className="flex flex-col items-end w-full mt-4">
                <Button
                  buttonStyle="primary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="130px"
                  onClick={() => {
                    submitCurrentCrop();
                    onClose();
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

CropImageModal.defaultProps = {
  open: false,
  className: "",
};

CropImageModal.propTypes = {
  className: PropTypes.string,
  file: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
};
export default CropImageModal;
