import { confetti } from "@tsparticles/confetti";

export const confettiAnimation = (canvas) => {
  const end = Date.now() + 2 * 1000;

  const colors = ["#bb0000", "#ffffff"];

  (async function frame() {
    canvas.confetti =
      canvas.confetti || (await confetti.create(canvas, { resize: true }));

    canvas.confetti({
      particleCount: 1,
      angle: 60,
      spread: 44,
      origin: { x: 0 },
      colors: colors,
    });

    canvas.confetti({
      particleCount: 1,
      angle: 120,
      spread: 44,
      origin: { x: 1 },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
};
