/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import Tabs from "../../../stories/Tabs";
import AppSkeleton from "../../AppSkeleton";
import * as Constants from "../../../Constants";
import InvoiceTable from "../../../stories/InvoiceTable";
import {
  useCorporateInvoices,
  useCorporatePaymentRequest,
  useCorporateSubscriptions,
} from "../../../services/BasicDetails";
import _ from "lodash";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import SearchBar from "../../../stories/SearchBar";
import { useEffect, useState } from "react";
import CorporateSubscriptionTable from "./CorporateSubscriptionTable";
import SelectQPFPModuleModal from "../../Modals/SelectQPFPModuleModal";
import { CustomToast } from "../../../stories/Toast";
import { useProfile } from "../../../context/userProvider";
import { useNavigate } from "@reach/router";

const CorporatePayments = () => {
  const { userDetails } = useProfile();
  const navigate = useNavigate();
  const {
    loading: isPaymentRequestsLoading,
    requests,
    error: paymentRequestError,
    mutate: mutatePaymentRequest,
  } = useCorporatePaymentRequest({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const {
    loading: isSubscriptionsLoading,
    subscriptions,
    error: subscriptionError,
    mutate: mutateSubscriptions,
  } = useCorporateSubscriptions({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const {
    loading: isInvoicesLoading,
    invoices,
    error: invoiceError,
    mutate: mutateInvoices,
  } = useCorporateInvoices({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedQPFPModules, setSelectedQPFPModules] = useState({});
  const [openSelectModulePopup, setOpenSelectModulePopup] = useState(false);
  const [tempSelectedRow, setTempSelectedRow] = useState(undefined);
  const [filteredSubscription, setFilteredSubscription] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);

  useEffect(() => {
    if (
      !_.isEmpty(userDetails) &&
      userDetails.firm_signup_status !== "approved"
    ) {
      CustomToast.error("Please become a corporate admin to access this page");
      navigate("/");
    }
  }, [userDetails]);

  useEffect(() => {
    setFilteredRequests(
      requests.filter(
        (s) =>
          currentIndex !== 0 ||
          (currentIndex === 0 &&
            `${s.name} ${s.course_type} ${s.payment_status} ${s.subscription_status}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      )
    );
    setFilteredSubscription(
      subscriptions?.filter(
        (s) =>
          currentIndex !== 1 ||
          (currentIndex === 1 &&
            `${s.name} ${s.course_type} ${s.payment_status} ${s.subscription_status}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      )
    );
    setFilteredInvoices(
      invoices.filter(
        (s) =>
          currentIndex !== 2 ||
          (currentIndex === 2 &&
            `${s.program_name} ${s.program_detail} ${s.invoice_number} ${s.start_date}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      )
    );
  }, [currentIndex, searchQuery]);

  const onClickSelectModule = ({ rowIndex, rowValue }) => {
    setTempSelectedRow({ ...rowValue, index: rowIndex });
    setOpenSelectModulePopup(true);
  };

  const removeSelectModule = ({ rowIndex, rowValue }) => {
    const tempData = { ...selectedQPFPModules };
    delete tempData[
      `${tempSelectedRow.entity_details.entity_type}_${tempSelectedRow.entity_details.entity_id}`
    ];
    setSelectedQPFPModules(tempData);
  };

  const tabList = [
    {
      heading: "Payment Request",
      component: (
        <CorporateSubscriptionTable
          key={0}
          isLoading={isPaymentRequestsLoading}
          selectedQPFPModules={selectedQPFPModules}
          columns={Constants.CorporatePaymentRequestColumns({
            selectedQPFPModules,
            onClickSelectModule,
            removeSelectModule,
          })}
          onClickSelectModule={onClickSelectModule}
          removeSelectModule={removeSelectModule}
          disableCheckboxIndex={(_.isEmpty(searchQuery)
            ? requests
            : filteredRequests
          ).map((s, i) =>
            !["Overdue", "pending"].includes(s.status) ? i : undefined
          )}
          data={_.isEmpty(searchQuery) ? requests : filteredRequests}
          error={paymentRequestError}
          refreshData={() => {
            mutatePaymentRequest();
            mutateSubscriptions();
            mutateInvoices();
            setSelectedQPFPModules({});
          }}
          showEmptyState={!isPaymentRequestsLoading && _.isEmpty(requests)}
        />
      ),
    },
    {
      heading: "Subscriptions",
      component: (
        <CorporateSubscriptionTable
          key={1}
          isLoading={isSubscriptionsLoading}
          selectedQPFPModules={selectedQPFPModules}
          disableCheckboxIndex={(_.isEmpty(searchQuery)
            ? subscriptions
            : filteredSubscription
          ).map((s, i) =>
            !["Overdue", "Expires Soon", "Payment pending"].includes(
              s.payment_status
            )
              ? i
              : undefined
          )}
          onClickSelectModule={onClickSelectModule}
          removeSelectModule={removeSelectModule}
          columns={Constants.CorporateSubscriptionColumns({
            selectedQPFPModules,
            onClickSelectModule,
            removeSelectModule,
          })}
          data={_.isEmpty(searchQuery) ? subscriptions : filteredSubscription}
          error={subscriptionError}
          refreshData={() => {
            mutatePaymentRequest();
            mutateSubscriptions();
            mutateInvoices();
            setSelectedQPFPModules({});
          }}
          showEmptyState={!isSubscriptionsLoading && _.isEmpty(subscriptions)}
        />
      ),
    },
    {
      heading: "Invoices",
      component: (
        <InvoiceTable
          key={2}
          isLoading={isInvoicesLoading}
          columns={Constants.CorporateInvoiceColumns}
          data={_.isEmpty(filteredInvoices) ? invoices : filteredInvoices}
          error={invoiceError}
          showEmptyState={!isInvoicesLoading && _.isEmpty(invoices)}
        />
      ),
    },
  ];

  if (subscriptionError && invoiceError) {
    return (
      <AppSkeleton
        pageLabel="My Payments"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          { name: "Corporate Account", to: "/corporate/profile" },
          { name: "My Payments", to: "/corporate/payments", current: true },
        ]}
      >
        <ErrorScreen />
      </AppSkeleton>
    );
  }
  return (
    <AppSkeleton
      pageLabel="My Payments"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "Corporate Account", to: "/corporate/profile" },
        { name: "My Payments", to: "/corporate/payments", current: true },
      ]}
    >
      <div className="flex flex-col w-full px-6 py-4 h-fit">
        <Tabs
          tabList={tabList}
          onTabClick={(index) => {
            setCurrentIndex(index);
            setSearchQuery("");
            setTempSelectedRow(undefined);
            setSelectedQPFPModules({});
          }}
          headingLastComponent={
            <div className="justify-end hidden w-full md:flex grow">
              <div className="w-full max-w-lg">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={
                    currentIndex === 0
                      ? "Search payment request"
                      : currentIndex === 1
                      ? "Search subscription"
                      : "Search invoice"
                  }
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
          }
          middleComponent={
            <div className="flex justify-start w-full md:hidden">
              <div className="w-full">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={
                    currentIndex === 0
                      ? "Search payment request"
                      : currentIndex === 1
                      ? "Search subscription"
                      : "Search invoice"
                  }
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
          }
        />
        <div className="w-full h-16" />
      </div>
      {openSelectModulePopup && (
        <SelectQPFPModuleModal
          onClose={() => setOpenSelectModulePopup(false)}
          options={
            (tempSelectedRow.course_type ??
              tempSelectedRow.course?.course_type) === "qpfp"
              ? currentIndex === 0
                ? tempSelectedRow.payment_details?.available_part_payment_option
                : tempSelectedRow.available_part_payment_option
              : []
          }
          preSelectedModule={
            selectedQPFPModules[
              `${tempSelectedRow.entity_details.entity_type}_${tempSelectedRow.entity_details.entity_id}`
            ] ?? {}
          }
          userName={tempSelectedRow.user_full_name}
          onSelect={(e) => {
            setSelectedQPFPModules((prev) => ({
              ...prev,
              [`${tempSelectedRow.entity_details.entity_type}_${tempSelectedRow.entity_details.entity_id}`]:
                e,
            }));
            setOpenSelectModulePopup(false);
          }}
          open={openSelectModulePopup}
        />
      )}
    </AppSkeleton>
  );
};

export default CorporatePayments;
