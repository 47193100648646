/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const fetchLandingPageContent = () => {
  return API(
    {
      method: "GET",
      url: config.urls.landing.dynamic_content,
    },
    true
  );
};

export const ContentService = { fetchLandingPageContent };
