/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import CustomLink from "../../stories/Link";
import AuthTextInput from "../../stories/AuthTextInput";
import { Form, Formik } from "formik";
import CheckBox from "../../stories/CheckBox";
import { Validations } from "../../constants/validations";
import { useSWRConfig } from "swr";
import { useNavigate } from "@reach/router";
import { User } from "../../store";
import { AuthService } from "../../services/AuthService";
import { CustomToast } from "../../stories/Toast";
import { useProfile } from "../../context/userProvider";
import OTPField from "../../stories/OTPField";
import ShowFormError from "../../stories/ShowFormError";
import { useLoginModal } from "../../context/loginModalProvider.js";

const LoginWithPassword = ({ prefill_data, handleLogin, setScreen }) => {
  const initialValues = {
    email: prefill_data.email ?? "",
    password: "",
    rememberMe: false,
  };

  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
        <Dialog.Title
          as="h3"
          className="flex justify-between text-5xl font-bold tracking-tight text-theme-black-color"
        >
          Login
        </Dialog.Title>
        <div className="flex flex-col w-full">
          <div className="block mt-6 text-lg font-light leading-5 text-theme-black-300">
            You have you an account on Network FP. Login to continue
            registration.
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={() =>
              Validations.onBoarding.loginValidationSchema
            }
            onSubmit={handleLogin}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="w-full mt-8 md:mt-12">
                  <AuthTextInput
                    name="email"
                    id="email"
                    type="text"
                    label="Email"
                    autoComplete="email"
                  />
                </div>
                <div className="w-full mt-4">
                  <AuthTextInput
                    name="password"
                    id="password"
                    type="password"
                    label="Password"
                    showErrorIcon={true}
                    autoComplete="current-password"
                  />
                </div>
                <div className="flex justify-between w-full mt-3">
                  <CheckBox name="rememberMe" inputLabel="Remember Password" />
                  <CustomLink
                    target="_blank"
                    href="/forgot-password"
                    textColor="text-blue-500"
                    activeTextColor="text-red-700"
                    showUnderLine={false}
                    underLineOnlyOnHover={false}
                    className="leading-none"
                  >
                    Forgot Password?
                  </CustomLink>
                </div>

                <div className="flex items-center gap-2 my-6">
                  <div className="w-full border-t border-theme-gray-100" />
                  <div className="italic font-light text-theme-gray-600">
                    or
                  </div>
                  <div className="w-full border-t border-theme-gray-100" />
                </div>

                <p
                  className="text-theme-red leading-none text-center cursor-pointer"
                  onClick={() => setScreen("otp")}
                >
                  Login with OTP
                </p>

                <Button
                  type="submit"
                  buttonStyle="primary"
                  height="60px"
                  width="100%"
                  className="mt-6 rounded-lg"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  LOG IN
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Transition.Child>
  );
};

LoginWithPassword.propTypes = {
  prefill_data: PropTypes.object,
  handleLogin: PropTypes.func,
  setScreen: PropTypes.func,
};

const Form1 = ({ email, sendOtp, setScreen }) => {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
        <Dialog.Title
          as="h3"
          className="flex justify-between text-5xl font-bold tracking-tight text-theme-black-color"
        >
          Enter your Email ID / Mobile Number
        </Dialog.Title>
        <div className="flex flex-col w-full">
          <div className="block mt-6 text-lg font-light leading-5 text-theme-black-300">
            We will send an OTP verification to you.
          </div>
          <Formik
            initialValues={{
              email: email ?? "",
            }}
            validationSchema={() =>
              Validations.onBoarding.otpLoginValidationSchema
            }
            onSubmit={sendOtp}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className="w-full mt-8 md:mt-12">
                  <AuthTextInput
                    name="email"
                    id="email"
                    type="text"
                    label="Email address/Mobile Number"
                    autoComplete="email"
                  />
                </div>

                <div className="flex items-center gap-2 my-6">
                  <div className="w-full border-t border-theme-gray-100" />
                  <div className="italic font-light text-theme-gray-600">
                    or
                  </div>
                  <div className="w-full border-t border-theme-gray-100" />
                </div>

                <p
                  className="text-theme-red leading-none text-center cursor-pointer"
                  onClick={() => setScreen("password")}
                >
                  Login with Password
                </p>

                <Button
                  type="submit"
                  buttonStyle="primary"
                  height="60px"
                  width="100%"
                  className="mt-8"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  REQUEST OTP
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Transition.Child>
  );
};

Form1.propTypes = {
  email: PropTypes.string,
  sendOtp: PropTypes.func,
  setScreen: PropTypes.func,
};

const Form2 = ({ email, goBack, onSubmit, sendOtp }) => {
  let masked = "";
  if (email.includes("@")) {
    masked = `*****${email
      ?.split("@")[0]
      ?.substring(email?.split("@")[0]?.length - 3)}@${email.split("@")[1]}`;
  } else {
    masked = `*******${email.substring(6, email?.length)}`;
  }

  const [seconds, setSeconds] = useState(0);
  const timeOutCallback = useCallback(
    () => setSeconds((currTimer) => currTimer - 1),
    []
  );
  const resetTimer = function () {
    if (!seconds) {
      setSeconds(120);
    }
  };
  useEffect(() => {
    seconds > 0 && setTimeout(timeOutCallback, 1000);
  }, [seconds, timeOutCallback]);

  useEffect(() => {
    resetTimer();
  }, []);

  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
        <Dialog.Title
          as="h3"
          className="flex justify-between text-5xl font-bold tracking-tight text-theme-black-color"
        >
          OTP Verification
        </Dialog.Title>
        <div className="flex flex-col w-full">
          <div className="block mt-6 text-lg font-light leading-5 text-theme-black-300">
            Enter OTP sent to &nbsp;
            <b>{masked}</b>.
          </div>
          <div className="flex justify-start">
            <CustomLink
              textColor="text-theme-red"
              activeTextColor="text-theme-red"
              fontSize="md"
              showUnderLine={false}
              underLineOnlyOnHover={false}
              className="leading-5"
              onClick={() => {
                resetTimer();
                goBack();
              }}
            >
              Edit
            </CustomLink>
          </div>
          <Formik
            initialValues={{ otp: Array(6).fill("") }}
            validationSchema={Validations.onBoarding.otpVerification}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col items-start w-full mt-4">
                <div className="flex flex-row items-center justify-start w-full gap-2">
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField index={0} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField index={1} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField index={2} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField index={3} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField index={4} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField index={5} />
                  </div>
                </div>
                <ShowFormError name="otp" />
                <div className="flex justify-end w-full mt-3">
                  <Button
                    disabled={seconds > 0}
                    buttonStyle="transparent-textonly-primary"
                    onClick={(e) => {
                      resetTimer();
                      sendOtp({ email: email });
                    }}
                    type="button"
                  >
                    {seconds === 0 ? (
                      "Resend OTP"
                    ) : (
                      <>
                        Resend OTP in{" "}
                        {_.toInteger(seconds / 60) < 10
                          ? `0${_.toInteger(seconds / 60)}`
                          : _.toInteger(seconds / 60)}{" "}
                        :{" "}
                        {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                      </>
                    )}
                  </Button>
                </div>
                <Button
                  type="submit"
                  buttonStyle="primary"
                  height="60px"
                  width="100%"
                  className="mt-6"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  VERIFY OTP
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Transition.Child>
  );
};

Form2.propTypes = {
  email: PropTypes.string,
  goBack: PropTypes.func,
  onSubmit: PropTypes.func,
  sendOtp: PropTypes.func,
};

const LoginWithOTP = ({
  email,
  setEmail,
  otpSent,
  setOtpSent,
  sendOtp,
  submitOtp,
  setScreen,
}) => {
  // const initialValues = {
  //   email: email ?? "",
  // };

  return (
    <>
      {!otpSent ? (
        <Form1 email={email} sendOtp={sendOtp} setScreen={setScreen} />
      ) : (
        <Form2
          email={email}
          goBack={() => setOtpSent(false)}
          onSubmit={submitOtp}
          sendOtp={sendOtp}
        />
      )}
    </>
  );
};

LoginWithOTP.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  otpSent: PropTypes.bool,
  setOtpSent: PropTypes.func,
  sendOtp: PropTypes.func,
  submitOtp: PropTypes.func,
  setScreen: PropTypes.func,
};

const LoginUserModal = ({
  open,
  onClose,
  prefill_data,
  onLoginSuccess = () => {
    window.location.reload();
  },
  className,
}) => {
  const { cache } = useSWRConfig();
  const navigate = useNavigate();
  const { updateUserData } = useProfile();

  const { openLoginPopup, setOpenLoginPopup } = useLoginModal();

  const [screen, setScreen] = useState("password");
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(prefill_data.email);
  }, [prefill_data]);

  const logoutUser = () => {
    window.fcWidget.user.get().then((res) => {
      window.fcWidget.user.clear();
    });
    window.fcWidget.init({
      token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
      host: "https://wchat.in.freshchat.com",
    });
    User.clearToken();
    cache.clear();
  };

  const handleLogin = async ({ email, password, rememberMe }) => {
    logoutUser();
    try {
      const result = await AuthService.login({
        email,
        password,
        type: "email",
        login_type: "password",
      });
      if (result.token) {
        CustomToast.success(result.message);
        User.setToken(result.token);
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: email ?? null,
        });
        updateUserData(true);
        onLoginSuccess();
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
      if (e.error_code === "signup_not_completed") {
        navigate("/complete-signup", {
          state: {
            token: e.payload?.token,
            email: email,
            user: e.payload?.user,
          },
        });
      } else if (e.error_code === "email_not_verified") {
        navigate("/verify-email", { state: { email } });
      }
    }
  };

  const sendOtp = async (values) => {
    logoutUser();
    try {
      const key = values.email.includes("@") ? "email" : "phone_number";
      const result = await AuthService.sendOTP({
        [key]: values.email,
        type: key,
      });
      setEmail(values.email);
      setOtpSent(true);
      CustomToast.success(result.message);
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  const submitOtp = async (values, { setFieldError }) => {
    try {
      const otp = values.otp.join("");
      if (_.isEmpty(otp)) {
        CustomToast.error("Please enter a otp.");
        setFieldError("otp", "Please enter a otp.");
        return;
      }
      const key = email.includes("@") ? "email" : "phone_number";
      const result = await AuthService.login({
        [key]: email,
        code: otp,
        type: key,
        login_type: "otp",
      });
      if (result.token) {
        CustomToast.success(result.message);
        User.setToken(result.token);
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: email ?? null,
        });
        updateUserData(true);
        onLoginSuccess();
      }
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0]);
      if (e.error_code === "signup_not_completed") {
        navigate("/complete-signup", {
          state: {
            token: e.payload?.token,
            email: email,
            user: e.payload?.user,
          },
        });
      } else if (e.error_code === "email_not_verified") {
        navigate("resend-email", { state: { email } });
      }
    }
  };

  return (
    <React.Fragment>
      <Transition.Root show={open ? open : openLoginPopup} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open ? open : openLoginPopup}
          onClose={() => {
            if (onClose) {
              onClose();
            } else {
              setOpenLoginPopup(false);
            }
          }}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {screen === "password" ? (
              <LoginWithPassword
                prefill_data={prefill_data}
                handleLogin={handleLogin}
                setScreen={setScreen}
              />
            ) : (
              <LoginWithOTP
                email={email}
                setEmail={setEmail}
                otpSent={otpSent}
                setOtpSent={setOtpSent}
                sendOtp={sendOtp}
                submitOtp={submitOtp}
                setScreen={setScreen}
              />
            )}
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

LoginUserModal.defaultProps = {
  className: "",
  open: null,
  onClose: null,
};

LoginUserModal.propTypes = {
  open: PropTypes.any,
  onClose: PropTypes.any,
  className: PropTypes.string,
  prefill_data: PropTypes.object,
  onLoginSuccess: PropTypes.func,
};

export default LoginUserModal;
