import PropTypes from "prop-types";
import React from "react";
import ImageComponent from "./ImageComponent";
import promember_badge from "../assets/images/pro_badge.png";
import { User as Usericon } from "@phosphor-icons/react";
import { useProfile } from "../context/userProvider";
import classNames from "classnames";

const UserProfileAvatar = ({
  imageUrl,
  isLoggedInUser,
  sizeClass = "h-7 w-7 min-w-[28px] lg:h-10 lg:w-10 lg:min-w-[40px]",
  showBadge = true,
  errorIconSize = 24,
}) => {
  const { userDetails } = useProfile();

  return (
    <div className="relative">
      <ImageComponent
        alt="profile-icon"
        src={imageUrl ?? ""}
        errorComponent={
          <div
            className={classNames(
              "flex items-center justify-center bg-gray-200 rounded-full",
              sizeClass
            )}
          >
            <Usericon
              size={errorIconSize}
              weight="bold"
              className="text-gray-400"
            />
          </div>
        }
        className={classNames("object-cover rounded-full", sizeClass)}
      />
      {showBadge &&
        userDetails.is_pro_member &&
        isLoggedInUser &&
        ["approved", "expires_soon"].includes(userDetails.pm_signup_status) && (
          <img
            alt="pro"
            src={promember_badge}
            className="absolute -top-0.5 -right-0.5 object-contain w-4 h-4"
          />
        )}
    </div>
  );
};

UserProfileAvatar.propTypes = {
  errorIconSize: PropTypes.number,
  imageUrl: PropTypes.string,
  isLoggedInUser: PropTypes.bool,
  showBadge: PropTypes.bool,
  sizeClass: PropTypes.string,
};

export default UserProfileAvatar;
