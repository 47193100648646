import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PublicProfileCard from "../../stories/PublicProfileCard";
import linkedIn_icon from "../../assets/svgs/linkedIn_icon.svg";
import { PublicProfileService } from "../../services/PublicProfileService";
import fileUploadManager from "../../api/fileUploadManager";
import { User } from "@phosphor-icons/react";
import _ from "lodash";
import { CustomToast } from "../../stories/Toast";
import ImageComponent from "../../stories/ImageComponent";
import FormBuilder from "../../stories/FormBuilder";
import moment from "moment";
import { Validations } from "../../constants/validations";
import Tooltip from "../../stories/Tooltip";
import { genderOptions } from "../../Constants";

const PersonalInfoCard = ({
  className,
  publicProfileData,
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  useEffect(() => {
    setInitialValues({
      profileImage: publicProfileData?.profile_picture?.signed_url ?? null,
      firstName: publicProfileData?.first_name,
      lastName: publicProfileData?.last_name,
      displayName:
        publicProfileData.display_name ??
        `${publicProfileData?.first_name} ${publicProfileData?.last_name}`,
      linkedIn: publicProfileData?.profile_detail?.linkedin_link ?? "",
      dob:
        publicProfileData?.dob && moment(publicProfileData?.dob, "DD/MM/YYYY"),
      gender: publicProfileData?.gender,
      bio: publicProfileData?.profile_detail?.short_bio ?? "",
    });
  }, [publicProfileData]);

  const handleSubmit = async (data) => {
    try {
      let profileResponse;
      let postBody = {
        first_name: data.firstName,
        last_name: data.lastName,
        display_name: data.displayName,
        dob: moment(data.dob).toLocaleString(),
        gender: data.gender,
      };
      // if (!_.isEmpty(data.linkedIn)) {
      postBody = {
        ...postBody,
        profile_detail_attributes: {
          ...postBody.profile_detail_attributes,
          linkedin_link: data.linkedIn,
        },
      };
      // }
      // if (!_.isEmpty(data.bio)) {
      postBody = {
        ...postBody,
        profile_detail_attributes: {
          ...postBody.profile_detail_attributes,
          short_bio: data.bio,
        },
      };
      // }

      if (
        typeof data.profileImage !== "string" &&
        typeof data.profileImage !== "undefined" &&
        data.profileImage !== null
      ) {
        profileResponse = await fileUploadManager({
          file: data.profileImage,
          key: "profile-pictures",
        });
        postBody = {
          ...postBody,
          profile_picture_attributes: {
            ...postBody.profile_picture_attributes,
            url: profileResponse.signed_url,
            file_type: "image",
            attachment_type: "profile_picture",
          },
        };
        if (publicProfileData["profile_picture"]?.id !== undefined) {
          postBody = {
            ...postBody,
            profile_picture_attributes: {
              ...postBody.profile_picture_attributes,
              id: publicProfileData["profile_picture"]?.id,
            },
          };
        }
      }

      if (publicProfileData["profile_detail"]?.id) {
        postBody = {
          ...postBody,
          profile_detail_attributes: {
            ...postBody.profile_detail_attributes,
            id: publicProfileData["profile_detail"].id,
          },
        };
      }
      try {
        const result = await PublicProfileService.updatePublicProfile(postBody);
        if (result.message) {
          CustomToast.success(result.message);
          onUpdate();
          setIsEditing(false);
        }
      } catch (e) {
        CustomToast.error(e.error_messages[0]);
      }
    } catch (e) {}
  };

  const textInputDetails = [
    {
      name: "profileImage",
      type: "avatarImgUpload",
      ErrorComponent: () => (
        <User size={40} weight="bold" className="text-gray-400" />
      ),
    },
    {
      label: "First Name",
      name: "firstName",
      placeholder: "Rahul",
      className: "lg:min-w-186",
      type: "text",
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "Subramanian",
      className: "lg:min-w-186",
      type: "text",
    },
    {
      label: "Display Name",
      name: "displayName",
      placeholder: "Rahul Subramanian",
      className: "lg:min-w-186",
      type: "text",
    },
    {
      label: "LinkedIn Profile link",
      name: "linkedIn",
      placeholder: "LinkedIn profile link",
      className: "lg:min-w-186",
      type: "text",
      infoContent: "Copy Paste your LinkedIn profile url link here",
      isRequired: false,
    },
    {
      label: "Date of Birth",
      name: "dob",
      placeholder: "Birth Date",
      className: "lg:min-w-186",
      filterDate: (d) =>
        d < new Date(new Date() - 18 * 365 * 24 * 60 * 60 * 1000),
      maxDate: new Date(new Date() - 18 * 365 * 24 * 60 * 60 * 1000),
      type: "date",
    },
    {
      label: "Gender",
      placeholder: "Select Gender",
      type: "dropdown",
      displayCurrentOption: true,
      isClearable: false,
      isSearchable: false,
      options: genderOptions,
      displayKey: "gender",
      idKey: "id",
      name: "gender",
      className: "lg:min-w-186",
    },
    {
      label: "Profile Link",
      name: "profile_slug",
      renderValue: () => "/" + publicProfileData.profile_slug ?? "",
      isViewOnly: true,
    },
    {
      label: "Age",
      name: "age",
      renderValue: (values) => {
        if (!values["dob"]) {
          return "-";
        }
        const ageInMilliseconds = new Date() - new Date(values["dob"]);
        return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
      },
      isViewOnly: true,
    },
    {
      label: "Bio",
      name: "bio",
      placeholder: "Write down here . . .",
      className: "col-span-full",
      rows: 4,
      isRequired: false,
      maxLength: 1000,
      infoContent: "Add a short bio here",
      type: "textarea",
    },
  ];

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Personal Info"
        subHeading="Update your Personal Info here."
        isEditing={isEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isLoading={isLoading}
        setIsEditing={setIsEditing}
        cardBody={
          <div className="w-full mt-5">
            {isEditing ? (
              <div className="flex ">
                <FormBuilder
                  initialValues={initialValues}
                  validationSchema={
                    Validations.publicProfile.personalInfovalidationSchema
                  }
                  onFormSubmit={handleSubmit}
                  submitButtonRef={submitRef}
                  cancelButtonRef={cancelRef}
                  inputFieldsDetails={textInputDetails}
                  formClassName="grid w-full grid-cols-2 gap-y-5 gap-x-6"
                />
              </div>
            ) : (
              <div className="flex flex-col items-start w-full">
                <div className="flex items-center justify-between w-full">
                  <div className="flex justify-start">
                    {/* user details and profile pic */}
                    <div className="flex items-center justify-center w-20 h-20 rounded-full bg-theme-gray-300">
                      {initialValues.profileImage !== undefined &&
                      initialValues.profileImage !== null ? (
                        <ImageComponent
                          src={initialValues.profileImage}
                          className="object-cover w-full h-full rounded-full"
                          errorComponent={
                            <User
                              size={24}
                              weight="bold"
                              className="text-gray-400"
                            />
                          }
                        />
                      ) : (
                        <User
                          size={40}
                          weight="bold"
                          className="text-gray-400"
                        />
                      )}
                    </div>
                    <div className="flex flex-col ml-3.5 items-start justify-center ">
                      <div className="flex text-lg font-semibold leading-6 tracking-tighter text-left text-theme-black-color">
                        {(initialValues.firstName ?? "") +
                          " " +
                          (initialValues.lastName ?? "")}
                      </div>
                      <div className="flex mt-1 text-sm font-normal leading-none tracking-0.3px text-left lg:font-semibold text-neutral-400">
                        <span>
                          {" "}
                          {(() => {
                            if (_.isEmpty(initialValues.dob)) {
                              return "";
                            }
                            const ageInMilliseconds =
                              new Date() -
                              new Date(moment(initialValues.dob, "DD/MM/YYYY"));
                            return (
                              Math.floor(
                                ageInMilliseconds / 1000 / 60 / 60 / 24 / 365
                              ).toString() + " Years"
                            );
                          })()}{" "}
                        </span>
                        {!_.isEmpty(initialValues.dob) && (
                          <span className="h-full w-[1px] bg-theme-gray mx-2 "></span>
                        )}
                        <span>
                          Member Since {publicProfileData?.member_since}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex-row hidden ml-4 lg:flex">
                    {/* linkedIn icon */}
                    {!_.isEmpty(initialValues.linkedIn) && (
                      <Tooltip infoContent={initialValues.linkedIn}>
                        <ImageComponent
                          src={linkedIn_icon}
                          alt={initialValues.linkedIn}
                          onClick={() =>
                            window.open(initialValues.linkedIn, "_blank")
                          }
                          className="w-8 h-8 rounded-full cursor-pointer"
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between w-full mt-4">
                  <div className="flex flex-col min-w-[90px] leading-6 lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                    Bio
                  </div>
                  <div className="flex flex-col w-full text-base font-normal leading-6 tracking-tight text-left text-theme-black-color">
                    {_.isEmpty(initialValues.bio)
                      ? "-"
                      : initialValues.bio?.split("\n").map((item, idx) => (
                          <span key={idx}>
                            {item}
                            <br></br>
                          </span>
                        ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

PersonalInfoCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default PersonalInfoCard;
