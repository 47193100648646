import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import { Link } from "@reach/router";
const CustomLink = ({
  fontSize,
  textColor,
  activeTextColor,
  showUnderLine,
  underLineOnlyOnHover,
  children,
  className,
  href,
  target = "_self",
  ...props
}) => {
  const sizeStyle = () => {
    switch (fontSize) {
      case "xs":
        return "text-xs";
      case "sm":
        return "text-sm ";
      case "md":
        return "text-sm";
      case "lg":
        return "text-base";
      case "xl":
        return "text-base";
      default:
        return "";
    }
  };
  return (
    <>
      {target === "_self" ? (
        <Link
          // href={href ?? ""}
          to={href ?? ""}
          className={classNames(
            "cursor-pointer",
            textColor,
            "hover:" + activeTextColor,
            showUnderLine && "underline underline-offset-2",
            underLineOnlyOnHover && "hover:underline underline-offset-2",
            children && sizeStyle(),
            className
          )}
          {...props}
        >
          {children}
        </Link>
      ) : (
        <a
          href={href ?? ""}
          target={target}
          className={classNames(
            "cursor-pointer",
            textColor,
            "hover:" + activeTextColor,
            showUnderLine && "underline underline-offset-2",
            underLineOnlyOnHover && "hover:underline underline-offset-2",
            children && sizeStyle(),
            className
          )}
          {...props}
        >
          {children}
        </a>
      )}
    </>
  );
};

CustomLink.defaultProps = {
  fontSize: "sm",
  textColor: "text-black-600",
  activeTextColor: "text-theme-red",
  showUnderLine: false,
  underLineOnlyOnHover: true,
};

CustomLink.propTypes = {
  fontSize: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  textColor: PropTypes.string,
  activeTextColor: PropTypes.string,
  showUnderLine: PropTypes.bool,
  underLineOnlyOnHover: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
};
export default CustomLink;
