import PropTypes from "prop-types";
import "../index.css";
import React from "react";
import classNames from "classnames";
const EmptyState = ({ className, heading, subHeading, cta, image }) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center p-6 space-y-1 ",
        className
      )}
    >
      <div className="max-w-[30vw]">{image}</div>
      {heading && (
        <div className="text-base font-bold text-center">{heading}</div>
      )}
      <div className="text-sm font-normal text-center text-gray-500">
        {subHeading}
      </div>
      {cta}
    </div>
  );
};
EmptyState.defaultProps = {
  className: "",
  cta: <></>,
  image: <></>,
};
EmptyState.propTypes = {
  heading: PropTypes.any.isRequired,
  subHeading: PropTypes.any.isRequired,
  className: PropTypes.string,
  cta: PropTypes.element,
  image: PropTypes.element,
};
export default EmptyState;
