import React from "react";
import { RadioGroup } from "@headlessui/react";
import PropTypes from "prop-types";

const QuizRadioGroup = ({
  question,
  score,
  options,
  onOptionSelect,
  selected,
}) => {
  return (
    <RadioGroup value={selected} onChange={onOptionSelect}>
      <RadioGroup.Label
        className={
          "text-base select-none lg:text-xl font-medium lg:font-semibold text-theme-black-color mb-8 inline-block"
        }
      >
        {question?.split("\n").map((item, idx) => (
          <span key={idx}>
            {item}
            <br></br>
          </span>
        ))}
        <div className="mt-6 mb-2 text-base font-semibold text-theme-orange">{`${
          score ?? 0
        } Mark(s)`}</div>
      </RadioGroup.Label>
      {options.map((item, i) => (
        <RadioGroup.Option
          value={item.id}
          key={item.id}
          className="mb-4 last:mb-0"
        >
          {({ checked }) => (
            <div className="flex items-center px-4 py-6 text-base font-medium bg-white border-gray-200 rounded shadow select-none border-1 text-theme-black-color">
              <div>
                <div
                  className={`${
                    checked ? "border-theme-orange" : "border-gray-400"
                  } border rounded-full w-4 h-4 mr-3 flex justify-center items-center`}
                >
                  <div
                    className={`${
                      checked ? "bg-theme-orange" : "bg-white"
                    } w-2.5 h-2.5 rounded-full`}
                  ></div>
                </div>
              </div>
              {item.content?.split("\n").map((item, idx) => (
                <span key={idx}>
                  {item}
                  <br></br>
                </span>
              ))}
            </div>
          )}
        </RadioGroup.Option>
      ))}
      <div className="min-h-[24px] w-full flex justify-start h-6">
        {selected !== false && (
          <div
            className="text-base font-normal cursor-pointer w-fit text-neutral-600"
            onClick={() => onOptionSelect(null)}
          >
            Clear Response
          </div>
        )}
      </div>
    </RadioGroup>
  );
};

QuizRadioGroup.defaultProps = {
  options: [],
};
QuizRadioGroup.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  score: PropTypes.number,
  selected: PropTypes.bool.isRequired,
};
export default QuizRadioGroup;
