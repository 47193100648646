/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const fetchCalendarSessions = ({ start_date, end_date }) => {
  return API(
    {
      method: "GET",
      url: config.urls.calendar.fetch_calendar_session,
      params: {
        start_date,
        end_date,
      },
    },
    true
  );
};
const addMeetingToGoogleCalendar = ({ data }) => {
  return API(
    {
      method: "POST",
      url: config.urls.calendar.add_meetings_to_calendar,
      data: data,
    },
    true
  );
};

export const CalendarService = {
  fetchCalendarSessions,
  addMeetingToGoogleCalendar,
};
