import React from "react";
import PropTypes from "prop-types";
import Button from "../../stories/Button";
import _ from "lodash";

const QuizFooter = ({
  showReview = false,
  onShowReviewBtnClick = () => {},
  quizQuestions = [],
  currentQuizQuestion,
  onPreviousBtnClick = () => {},
  onNextBtnClick = () => {},
  onStartSkippedQuestionBtnClick = () => {},
  onSubmitBtnClick = () => {},
  isSubmittingAnswer = false,
  showSkippedQuestion = false,
}) => {
  if (showReview) {
    return (
      <Button className="px-6 py-1 lg:px-15" onClick={() => onSubmitBtnClick()}>
        SUBMIT
      </Button>
    );
  }
  return (
    <div className="flex flex-col justify-center space-y-3 md:space-y-0 md:space-x-3 md:flex-row">
      {(!showSkippedQuestion &&
        currentQuizQuestion === quizQuestions.length - 1) ||
      (showSkippedQuestion &&
        _.findIndex(
          quizQuestions,
          (e, index) =>
            _.size(e.chosen_answer_ids) === 0 &&
            currentQuizQuestion !== index &&
            index > currentQuizQuestion
        ) === -1) ? (
        <>
          {!showSkippedQuestion &&
            currentQuizQuestion === quizQuestions.length - 1 &&
            ![-1, currentQuizQuestion].includes(
              _.findIndex(
                quizQuestions,
                (e, index) => _.size(e.chosen_answer_ids) === 0
              )
            ) && (
              <Button
                onClick={async () => await onStartSkippedQuestionBtnClick()}
                className="px-6 py-1 lg:px-15"
              >
                START SKIPPED QUESTIONS
              </Button>
            )}
          <Button
            className="px-6 py-1 lg:px-16"
            onClick={() => onShowReviewBtnClick()}
          >
            REVIEW ANSWERS
          </Button>
        </>
      ) : (
        <>
          {currentQuizQuestion !== 0 && (
            <Button
              onClick={() => onPreviousBtnClick()}
              buttonStyle="transparent-primary"
              className="order-2 px-3 py-1 lg:px-11 md:order-1"
            >
              PREVIOUS QUESTION
            </Button>
          )}
          <Button
            onClick={async () => await onNextBtnClick()}
            className="order-1 px-6 py-1 lg:px-15 md:order-2"
            loading={isSubmittingAnswer}
          >
            NEXT QUESTION
          </Button>
        </>
      )}
    </div>
  );
  // if (
  //   quizQuestions.filter((e) => !e.isSubmitted).length === 1 &&
  //   _.head(quizQuestions.filter((e) => !e.isSubmitted))?.id ===
  //     quizQuestions[currentQuizQuestion].id
  // )
  //   return (
  //     <div className="flex flex-col justify-center space-y-3 md:space-y-0 md:space-x-3 md:flex-row">
  //       {currentQuizQuestion !== 0 && (
  //         <Button
  //           onClick={() => onPreviousBtnClick()}
  //           buttonStyle="transparent-primary"
  //           className="px-3 py-1 lg:px-11"
  //         >
  //           PREVIOUS QUESTION
  //         </Button>
  //       )}
  //       <Button
  //         className="px-6 py-1 lg:px-16"
  //         onClick={() => onShowReviewBtnClick()}
  //       >
  //         REVIEW ANSWERS
  //       </Button>
  //     </div>
  //   );
  // if (
  //   quizQuestions[currentQuizQuestion] !==
  //   quizQuestions[quizQuestions.length - 1]
  // ) {
  //   return (
  //     <div className="flex flex-col justify-center md:space-x-3 md:flex-row">
  //       {currentQuizQuestion !== 0 && (
  //         <Button
  //           onClick={() => onPreviousBtnClick()}
  //           buttonStyle="transparent-primary"
  //           className="px-3 py-1 lg:px-11"
  //         >
  //           PREVIOUS QUESTION
  //         </Button>
  //       )}
  //       <Button
  //         onClick={async () => await onNextBtnClick()}
  //         className="px-6 py-1 lg:px-15"
  //         loading={isSubmittingAnswer}
  //       >
  //         NEXT QUESTION
  //       </Button>
  //     </div>
  //   );
  // } else if (quizQuestions.filter((e) => !e.isSubmitted).length > 0) {
  //   return (
  //     <div className="flex flex-col justify-center md:space-x-3 md:flex-row">
  //       <Button
  //         buttonStyle="transparent-primary"
  //         className="px-6 py-1 lg:px-15"
  //         onClick={() => onShowReviewBtnClick()}
  //       >
  //         REVIEW ANSWERS
  //       </Button>
  //       <Button
  //         onClick={async () => await onStartSkippedQuestionBtnClick()}
  //         className="px-6 py-1 lg:px-15"
  //       >
  //         START SKIPPED QUESTIONS
  //       </Button>
  //     </div>
  //   );
  // }
  // return (
  //   <Button
  //     className="px-6 py-1 lg:px-15"
  //     onClick={() => onShowReviewBtnClick()}
  //   >
  //     REVIEW ANSWERS
  //   </Button>
  // );
};

QuizFooter.propTypes = {
  currentQuizQuestion: PropTypes.number,
  isSubmittingAnswer: PropTypes.bool,
  onNextBtnClick: PropTypes.func,
  onPreviousBtnClick: PropTypes.func,
  onShowReviewBtnClick: PropTypes.func,
  onStartSkippedQuestionBtnClick: PropTypes.func,
  onSubmitBtnClick: PropTypes.func,
  quizQuestions: PropTypes.array,
  showReview: PropTypes.bool,
  showSkippedQuestion: PropTypes.bool,
};

export default QuizFooter;
