export const getCourseColor = (course_type) => {
  switch (course_type) {
    case "pro_member":
    case "pro_membership_demo":
      return "pro-member-color";
    case "provisional_member":
      return "theme-orange-400";
    case "qpfp":
      return "qpfp-color";
    case "event":
    case "webinar":
    case "workshop":
      return "green-500";
    case "general":
      return "blue-500";
    case "demo":
      return "showcase-color";
    case "new_user":
    case "free-training":
      return "theme-yellow";
    default:
      return "theme-orange";
  }
};
