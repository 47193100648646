/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Card from "../../stories/Card";
import AppSkeleton from "../AppSkeleton";
import { useLocation, useNavigate } from "@reach/router";
import Button from "../../stories/Button";
import PublicProfileSection from "./PublicProfileSection";
import ProgramCard from "../../stories/ProgramCard";
import {
  useCurrentUserPublicProfile,
  useDashboardCoursesData,
  useDashboardData,
} from "../../services/BasicDetails";
import MembershipSection from "./MembershipSection";
import ShowEventCard from "../Register/ShowEventCard";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import { useProfile } from "../../context/userProvider";
import RenewalBar from "../../stories/RenewalBar";
import CorporateSection from "./CorporateSection";
import _ from "lodash";
import { useTourContext } from "../../context/tourProvider";
import { tourSteps } from "../../util/Tour";
import QueryString from "qs";
import QPFPCertificateRenewalSection from "../../stories/QPFPCertificateRenewalSection";
import AdditionalDetailsModal from "../Modals/AdditionalDetailsModal";
import RegisteredNationalEventSection from "./RegisteredNationalEvent";

const AllProgramCard = ({ navigate }) => {
  return (
    <Card
      className="min-w-full bg-white lg:bg-theme-gray-300"
      cardType="filled"
      header={
        <div className="flex items-center text-2xl font-bold leading-none tracking-tighter text-theme-black-color">
          Register for NFP Programs
        </div>
      }
      cta={
        <Button
          buttonStyle="primary"
          size="xl"
          width="100%"
          onClick={() => navigate("/register")}
        >
          VIEW ALL PROGRAMS
        </Button>
      }
      cardBody={
        <div className="flex flex-col mt-4 mb-5 text-sm tracking-tight text-theme-black-300">
          You can register for any of the Network FP Programs, Events, Trainings
          here. Click on NFP Program that you wish to join, fill the form and
          make payment.
        </div>
      }
    />
  );
};

AllProgramCard.propTypes = {
  navigate: PropTypes.func,
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const { setState, state } = useTourContext();
  const location = useLocation();
  const parsed = QueryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );
  const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] =
    useState(false);

  const {
    loading: publicProfileLoading,
    publicProfileData,
    // mutate: mutatePublicProfile,
  } = useCurrentUserPublicProfile({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });

  useEffect(() => {
    if (publicProfileData?.is_profile_completed) {
      setShowAdditionalDetailsModal(false);
    } else {
      setShowAdditionalDetailsModal(true);
    }
  }, [publicProfileData]);

  useEffect(() => {
    if (parsed.guide_user === "true" || state.tourActive === true)
      setState((prev) => ({
        ...prev,
        run: true,
        tourActive: true,
        steps: tourSteps,
      }));
  }, [location.search]);

  const {
    dashboardData,
    loading: dashboardLoading,
    error,
  } = useDashboardData(true, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const {
    dashboardCoursesData,
    loading: dashboardCoursesLoading,
    error: error1,
  } = useDashboardCoursesData(true, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });

  if (dashboardLoading || dashboardCoursesLoading || publicProfileLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  if (error || error1) {
    return (
      <AppSkeleton>
        <ErrorScreen />
      </AppSkeleton>
    );
  }

  return (
    <AppSkeleton>
      <div className="grid grid-cols-1 mx-4 mt-2 mb-6 lg:mt-6 gap-y-6">
        {/* Promembership status section */}
        {/* isRegistered && ( (status== accepted && isNotCourseStarted) ||  (status!= accepted ) ) */}
        <RenewalBar
          entity_type={"pro_member"}
          status={userDetails?.pm_signup_status}
        />
        <RenewalBar
          entity_type={"qpfp"}
          status={userDetails?.qpfp_signup_status}
        />
        <RenewalBar
          entity_type={"provisional_member"}
          status={userDetails?.provisonal_member_signup_status}
        />
        <QPFPCertificateRenewalSection
          status={userDetails?.qpfp_certification_status}
        />
        {userDetails?.has_national_event_registered?.has_registered &&
          userDetails?.has_national_event_registered?.is_paid && (
            <RegisteredNationalEventSection
              onClick={() => {
                window.open(
                  `/events/${userDetails?.has_national_event_registered?.national_event_id}/register`,
                  "_blank"
                );
              }}
            />
          )}
        <CorporateSection status={userDetails.firm_signup_status} />
        {dashboardCoursesData.pro_member?.registered &&
          ((!dashboardCoursesData.pro_member?.course?.has_started &&
            dashboardCoursesData.pro_member.status === "approved") ||
            dashboardCoursesData.pro_member.status !== "approved") && (
            <MembershipSection
              data={dashboardCoursesData.pro_member}
              entity_type="pro_member"
              reason={dashboardCoursesData.pro_member.reason}
              program_title="ProMembership Program"
              program_description="Network FP's ProMember ACE (Annual Continuous Education) is a highly structured & a very practical education program for financial advisors in India to help them grow & succeed. Currently 1,000+ members in 100+ cities are benefiting from the program!"
            />
          )}
        {/* Provisional member status section */}
        {dashboardCoursesData.provisional_member?.registered &&
          ((!dashboardCoursesData.provisional_member?.course?.has_started &&
            dashboardCoursesData.provisional_member.status === "approved") ||
            dashboardCoursesData.provisional_member.status !== "approved") && (
            <MembershipSection
              data={dashboardCoursesData.provisional_member}
              entity_type="community_member"
              reason={dashboardCoursesData.provisional_member.reason}
              program_title="Network FP Community Membership"
              program_description="Take the first step to be part of Network FP by joining our Community Membership. Learn directly from practitioners each week as they talk about their practices and help you avoid the mistakes they have committed. You also get access to the NFP Exchange which is by the community for the community - Ask questions, exchange ideas and be part of an dynamic and driven set of personal finance professionals."
            />
          )}
        {/* QPFP membership status section */}
        {dashboardCoursesData.qpfp?.registered_course?.map((qpfp, index) => {
          if (
            qpfp?.registered &&
            ((!qpfp?.course?.has_started && qpfp.status === "approved") ||
              qpfp.status !== "approved")
          ) {
            return (
              <MembershipSection
                key={index}
                data={qpfp}
                reason={qpfp.reason}
                entity_type="qpfp"
                program_title="QPFP Certification"
                program_description="QPFP Certification is a highly practical foundation program for existing and aspiring personal finance professionals. Candidates completing the rigorous program over 6 months are awarded with prestigious QPFP Certification and designation of - Qualified Personal Finance Professional (QPFP)."
              />
            );
          }
          return null;
        })}
        {(dashboardCoursesData.qpfp?.registered_course ?? []).length === 0 &&
          !dashboardCoursesData.pro_member?.registered &&
          dashboardData.welcome_course &&
          (dashboardData.welcome_course?.course_completion_percentage ?? 0) <
            100 && (
            <ProgramCard
              isHideOnlyComplete={true}
              isStarted={dashboardData.welcome_course?.has_started}
              isFreeCourse={false}
              course_icon={
                dashboardData.welcome_course?.last_viewed_content
                  ?.session_thumbnail ??
                dashboardData.welcome_course?.course_icon
              }
              courseType={dashboardData.welcome_course?.course_type}
              courseName={dashboardData.welcome_course?.name}
              courseTitle={dashboardData.welcome_course?.title}
              courseDescription={dashboardData.welcome_course?.description}
              courseContent={dashboardData.welcome_course?.content?.map(
                (e) => e.name
              )}
              courseFeature={dashboardData.welcome_course?.highlights?.map(
                (e) => e.name
              )}
              courseCompletionProgress={
                dashboardData.welcome_course?.course_completion_percentage ?? 0
              }
              courseId={dashboardData.welcome_course?.id}
              lastViewedSessionContentId={
                dashboardData.welcome_course?.last_viewed_content
                  ?.course_session_content_id
              }
            />
          )}
        {/* Public Profile Section */}
        {(dashboardData?.profile_info?.profile_sections
          ?.percentage_completion ?? 0) < 100 && (
          <PublicProfileSection
            profilePercentage={
              dashboardData?.profile_info?.profile_sections
                ?.percentage_completion ?? 0
            }
            dashboardData={dashboardData}
          />
        )}
        {/* Promembership course section */}
        {dashboardCoursesData.pro_member?.registered &&
          dashboardCoursesData.pro_member?.course_started &&
          (dashboardCoursesData.pro_member.course
            ?.course_completion_percentage ?? 0) < 100 && (
            <ProgramCard
              isHideOnlyComplete={true}
              isStarted={dashboardCoursesData.pro_member.course.has_started}
              isFreeCourse={false}
              course_icon={
                dashboardData.pro_member?.last_viewed_content
                  ?.session_thumbnail ?? dashboardData.pro_member?.course_icon
              }
              courseName={dashboardCoursesData.pro_member.course.name}
              courseTitle={dashboardCoursesData.pro_member.course.title}
              courseDescription={
                dashboardCoursesData.pro_member.course.description
              }
              courseContent={dashboardCoursesData.pro_member.course.contents?.map(
                (e) => e.name
              )}
              courseType="pro_member"
              courseFeature={dashboardCoursesData.pro_member.course.highlights?.map(
                (e) => e.name
              )}
              courseCompletionProgress={
                dashboardCoursesData.pro_member.course
                  .course_completion_percentage ?? 0
              }
              courseId={dashboardCoursesData.pro_member.course.id}
              lastViewedSessionContentId={
                dashboardCoursesData.pro_member.course?.last_viewed_content
                  ?.course_session_content_id
              }
            />
          )}
        {/* Provisional membersip course section */}
        {dashboardCoursesData.provisional_member?.registered &&
          dashboardCoursesData.provisional_member?.course_started &&
          (dashboardCoursesData.provisional_member.course
            ?.course_completion_percentage ?? 0) < 100 && (
            <ProgramCard
              isHideOnlyComplete={true}
              isStarted={
                dashboardCoursesData.provisional_member.course.has_started
              }
              isFreeCourse={false}
              course_icon={
                dashboardData.provisional_member?.last_viewed_content
                  ?.session_thumbnail ??
                dashboardData.provisional_member?.course_icon
              }
              courseName={dashboardCoursesData.provisional_member.course.name}
              courseTitle={dashboardCoursesData.provisional_member.course.title}
              courseDescription={
                dashboardCoursesData.provisional_member.course.description
              }
              courseContent={dashboardCoursesData.provisional_member.course.contents?.map(
                (e) => e.name
              )}
              courseType="provisional_member"
              courseFeature={dashboardCoursesData.provisional_member.course.highlights?.map(
                (e) => e.name
              )}
              courseCompletionProgress={
                dashboardCoursesData.provisional_member.course
                  .course_completion_percentage ?? 0
              }
              courseId={dashboardCoursesData.provisional_member.course.id}
              lastViewedSessionContentId={
                dashboardCoursesData.provisional_member.course
                  ?.last_viewed_content?.course_session_content_id
              }
            />
          )}
        {/* QPFP membership course status section */}
        {dashboardCoursesData.qpfp?.registered_course?.map((qpfp, index) => {
          if (
            _.isEmpty(qpfp.course) ||
            !qpfp?.course_started ||
            (qpfp.course?.course_completion_percentage ?? 0) < 100
          )
            return null;

          return (
            <ProgramCard
              key={index}
              isHideOnlyComplete={true}
              isStarted={qpfp?.course?.has_started}
              isFreeCourse={false}
              course_icon={
                qpfp?.last_viewed_content?.session_thumbnail ??
                qpfp?.course_icon
              }
              courseType={qpfp.course?.course_type}
              courseName={qpfp.course?.name}
              courseTitle={qpfp.course.title}
              courseDescription={qpfp.course?.description}
              courseContent={qpfp.course?.contents?.map((e) => e.name)}
              courseFeature={qpfp.course?.highlights?.map((e) => e.name)}
              courseCompletionProgress={
                qpfp.course?.course_completion_percentage ?? 0
              }
              courseId={qpfp.course.id}
              lastViewedSessionContentId={
                qpfp.course?.last_viewed_content?.course_session_content_id
              }
            />
          );
        })}
        {/* Free Course */}
        {((dashboardCoursesData.qpfp?.registered_course ?? []).length > 0 ||
          dashboardCoursesData.pro_member?.registered) &&
          dashboardData.welcome_course &&
          (dashboardData.welcome_course?.course_completion_percentage ?? 0) <
            100 && (
            <ProgramCard
              isHideOnlyComplete={true}
              isStarted={dashboardData.welcome_course.has_started}
              isFreeCourse={false}
              course_icon={
                dashboardData.welcome_course?.last_viewed_content
                  ?.session_thumbnail ??
                dashboardData.welcome_course?.course_icon
              }
              courseType={dashboardData.welcome_course.course_type}
              courseName={dashboardData.welcome_course.name}
              courseTitle={dashboardData.welcome_course.title}
              courseDescription={dashboardData.welcome_course.description}
              courseContent={dashboardData.welcome_course.contents?.map(
                (e) => e.name
              )}
              courseFeature={dashboardData.welcome_course.highlights?.map(
                (e) => e.name
              )}
              courseCompletionProgress={
                dashboardData.welcome_course.course_completion_percentage ?? 0
              }
              courseId={dashboardData.welcome_course.id}
              lastViewedSessionContentId={
                dashboardData.welcome_course?.last_viewed_content
                  ?.course_session_content_id
              }
            />
          )}
        {/* Show Upcoming unregistered events */}
        {(((dashboardCoursesData.qpfp?.registered_course ?? []).length === 0 &&
          dashboardCoursesData.qpfp?.new_course) ||
          (dashboardCoursesData?.events?.new_courses ?? []).length > 0) && (
          <div className="grid grid-cols-1 gap-6 p-3 bg-white lg:p-6 lg:grid-cols-2 xl:grid-cols-3">
            <AllProgramCard navigate={navigate} />
            {dashboardCoursesData.qpfp?.new_course?.map((qpfp, index) => (
              <ShowEventCard
                key={index}
                program_type="qpfp"
                program={qpfp}
                index={0}
              />
            ))}
            {dashboardCoursesData?.events?.new_courses.map((event, index) => (
              <ShowEventCard
                program_type="event"
                program={event}
                key={event.id}
                index={index}
              />
            ))}
          </div>
        )}
      </div>
      {showAdditionalDetailsModal && (
        <AdditionalDetailsModal
          open={true}
          onClose={() => setShowAdditionalDetailsModal(false)}
          publicProfileData={publicProfileData}
        />
      )}
    </AppSkeleton>
  );
};

export default Dashboard;
