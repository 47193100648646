import React from "react";
import PropTypes from "prop-types";
import { Star } from "@phosphor-icons/react";
import classNames from "classnames";
import _ from "lodash";

const Ratings = ({ ratings, onChange, enabled = false, className }) => {
  return (
    <div className="flex">
      {_.range(1, 6).map((ratingValue) => {
        return (
          <label key={ratingValue}>
            <input
              type="radio"
              className={classNames("hidden")}
              name="rating"
              value={ratingValue}
              onClick={() => {
                if (enabled) {
                  onChange(ratingValue);
                }
              }}
            />
            <Star
              className={classNames(
                "text-theme-yellow-500",
                enabled && "hover:cursor-pointer",
                className
              )}
              weight={ratingValue <= ratings ? "fill" : "regular"}
            />
          </label>
        );
      })}
    </div>
  );
};

Ratings.propTypes = {
  ratings: PropTypes.number,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default Ratings;
