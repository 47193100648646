import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "./Label";
import _ from "lodash";
const CouponInput = ({
  className,
  id,
  name,
  placeholder,
  height,
  onClick,
  inputClassName,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue ?? "");
  const [isSuccess, setIsSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!_.isEmpty(defaultValue)) {
      setValue(defaultValue);
      setIsSuccess(true);
      setShowMessage(true);
      setMessage(defaultValue + " is applied successfully");
    } else {
      setValue("");
      setIsSuccess(false);
      setShowMessage(false);
      setMessage("");
    }
  }, [defaultValue]);

  return (
    <div className={classNames("flex flex-col items-start", className)}>
      <Label
        labelFor={name}
        className={`capitalize text-xs leading-none font-semibold text-theme-black-300`}
      >
        Have any discount code?
      </Label>
      <div
        className={classNames(
          "w-full flex rounded-sm mt-[5px] border",
          showMessage && !isSuccess
            ? "border-theme-red-200 focus:ring-theme-red-200 focus:border-theme-red-200"
            : "border-gray-200 focus:ring-gray-900 focus:border-theme-black-color",
          inputClassName,
          "rounded-sm shadow-[0_1px_4px_0_rgba(0,0,0,0.04)]",
          height
        )}
        // onClick={openFileHandler}
      >
        <input
          type="text"
          name={name}
          value={value}
          onChange={(e) => {
            setShowMessage(false);
            setIsSuccess(false);
            setValue(e.target.value);
          }}
          disabled={isSuccess}
          placeholder="Discount Code"
          className={classNames(
            "flex items-center grow border-none focus:ring-0 bg-transparent  text-sm tracking-tight pl-2.5",
            value === ""
              ? "text-neutral-400 placeholder-theme-black-color"
              : "text-theme-black-color"
          )}
          id={id}
        />
        <div
          className="flex items-center justify-center pl-4 pr-4 text-sm tracking-tight cursor-pointer bg-black/10 font-semi-bold text-theme-red"
          onClick={async () => {
            if (_.isEmpty(value)) {
              setShowMessage(true);
              setMessage("Please add a coupon.");
              return;
            }
            const prev = isSuccess; // success means coupon was applied and user has clicked to remove it
            if (prev) {
              setValue("");
            }
            const response = await onClick(prev ? "" : value);
            if (!prev) {
              setShowMessage(true);
              if (response.success) {
                setIsSuccess(true);
                setMessage(value + " is applied successfully");
              } else {
                setIsSuccess(false);
                setMessage(response.message);
              }
            } else {
              setShowMessage(false);
              setIsSuccess(false);
            }
          }}
        >
          {!isSuccess ? "APPLY" : "REMOVE"}
        </div>
      </div>
      {showMessage && (
        <div
          className={classNames(
            "flex text-sm mt-1 leading-none tracking-tight text-left",
            isSuccess ? "text-green-600 " : "text-theme-red-300"
          )}
        >
          {message}
        </div>
      )}
    </div>
  );
};
CouponInput.defaultProps = {
  className: "",
  inputClassName: "",
};

CouponInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
};

export default CouponInput;
