import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const QuizReport = ({ question }) => {
  return (
    <>
      <div
        className={
          "text-base select-none font-semibold text-theme-black-color mb-3 inline-block"
        }
      >
        {question?.content?.split("\n").map((item, idx) => (
          <span key={idx}>
            {item}
            <br></br>
          </span>
        ))}
        <div className="mt-6 mb-2 text-base font-semibold text-theme-orange">{`${question.score} Mark(s)`}</div>
      </div>
      <div className="mt-4 select-none">
        {question?.answers?.map((item, i) => (
          <Fragment key={i}>
            <div
              className={classNames(
                question.attempted_answer_ids?.includes(item.id)
                  ? item.is_right
                    ? "border-green-600"
                    : "border-theme-orange"
                  : item.is_right
                  ? "border-green-600"
                  : "border-gray-200",
                "flex items-center  py-4 px-4 border bg-white shadow-sm rounded text-base font-bold text-theme-black-color mb-3 last:mb-0"
              )}
            >
              <div>
                <div
                  className={classNames(
                    question.attempted_answer_ids?.includes(item.id)
                      ? item.is_right
                        ? "border-green-600"
                        : "border-theme-orange"
                      : item.is_right
                      ? "border-green-600"
                      : "border-gray-400",
                    "border rounded-full w-4 h-4 mr-3 flex justify-center items-center"
                  )}
                >
                  <div
                    className={`${
                      question.attempted_answer_ids?.includes(item.id)
                        ? item.is_right
                          ? "bg-green-600"
                          : "bg-theme-orange"
                        : "bg-white"
                    } w-2.5 h-2.5 rounded-full`}
                  ></div>
                </div>
              </div>
              {item.content}
            </div>
            {item.is_right &&
              !question.attempted_answer_ids?.includes(item.id) && (
                <div className="flex mb-3 -mt-3 text-sm font-semibold">
                  Reason:{" "}
                  <span className="ml-1 font-normal text-theme-black-300">
                    {item.reason}
                  </span>
                </div>
              )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

QuizReport.defaultProps = {
  question: "",
};
QuizReport.propTypes = {
  question: PropTypes.object,
};
export default QuizReport;
