import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";
import GoogleOAuth from "./GoogleOAuth";
import { X } from "@phosphor-icons/react";
import Button from "./Button";
import { useNavigate } from "@reach/router";
import moment from "moment";
import { getCourseColor } from "../constants/courseColor";
import Text from "./Text";

const CalendarSessionDetails = ({
  eventToDisplay,
  className,
  setEventToDisplay,
}) => {
  const navigate = useNavigate();

  const redirectToRegister = (event) => {
    if (
      ["pro_member", "provisional_member", "qpfp"].includes(event.course_type)
    ) {
      navigate(`members/${event.course_type}/register`);
      return;
    }
    if (["event", "webinar", "workshop"].includes(event.course_type)) {
      navigate(`events/${event.event_id}/register`);
      return;
    }
    navigate(`register`);
    return;
  };

  if (_.isEmpty(eventToDisplay)) return null;
  return (
    <div className={classNames("flex flex-col w-full gap-4", className)}>
      {eventToDisplay.map((event, e_index) => {
        const color = getCourseColor(event.course_type);
        return (
          <div
            key={e_index}
            className={classNames(
              "flex flex-col gap-4 p-6 bg-white h-fit",
              eventToDisplay.length === 1 && "sticky top-20"
            )}
          >
            {eventToDisplay.length === 1 && (
              <div
                className="cursor-pointer rounded-full w-fit absolute top-6 right-4 text-theme-orange hover:bg-theme-orange/10 py-1 px-2.5"
                onClick={() => setEventToDisplay(undefined)}
              >
                <X className="text-theme-orange w-4 h-4 min-w-[16px]" />
              </div>
            )}
            <div className="">
              <div className="">
                <span
                  className={`border text-xxs rounded-full py-1 px-2.5 text-${color} border-${color} bg-${color}/10`}
                >
                  {event.course_name}
                </span>
              </div>
              <div className="text-base mt-2.5 font-semibold text-theme-black-color">
                {event.topic}
              </div>
              <div className="text-sm mt-0.5 text-theme-black-300">
                {event.session_name}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-8 h-8 rounded-full bg-theme-gray-400"></div>
                <div className="flex flex-col">
                  <div className="text-xs font-semibold leading-none text-theme-black-color">
                    {event.trainer}
                  </div>
                  {event.email && (
                    <div className="mt-1 text-sm text-theme-black-300">
                      {event.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-sm mt-0.5 text-theme-black-300">
                <Text
                  content={event.description}
                  type="text"
                  shouldBreakWord
                  className="text-sm text-theme-black-300"
                  size="text-sm"
                  isMultiline
                />
              </div>
            </div>
            <div className="w-full h-0 border border-theme-gray-50" />

            <div className="flex flex-col items-start">
              <div className="text-xs text-theme-black-300">Date</div>
              <div className="text-sm font-medium text-theme-black-color">
                {moment(event.date).format("DD MMMM YYYY")}
              </div>
            </div>
            <div className="flex flex-col items-start">
              <div className="text-xs text-theme-black-300">Time</div>
              <div className="text-sm font-medium text-theme-black-color">
                {moment(event.start_time).format("hh:mm A")} to{" "}
                {moment(event.end_time).format("hh:mm A")}
              </div>
            </div>
            {!_.isEmpty(event.for) && (
              <div className="flex flex-col items-start">
                <div className="text-xs text-theme-black-300">For</div>
                <div className="text-sm font-medium text-theme-black-color">
                  {event.for ?? "-"}
                </div>
              </div>
            )}
            {event.has_course_registered &&
              event.course_type !== "financial_wellbing_camp" && (
                <Button
                  buttonStyle="gray"
                  className="!text-blue-500"
                  onClick={() => {
                    navigate(`programs/${event.course_id}/learn/${event.id}`);
                  }}
                >
                  Session Link
                </Button>
              )}
            {!event.has_course_registered &&
              event.course_type !== "financial_wellbing_camp" && (
                <Button
                  className={""}
                  size="no-size"
                  height={"40px"}
                  buttonStyle={"primary"}
                  onClick={async () => redirectToRegister(event)}
                >
                  REGISTER
                </Button>
              )}
            {event.has_course_registered && (
              <GoogleOAuth course_session_content_id={event.id} />
            )}
          </div>
        );
      })}
      {eventToDisplay.length > 1 && (
        <div
          onClick={() => setEventToDisplay(undefined)}
          className="border rounded-full text-theme-orange border-theme-orange bg-theme-orange/10 py-1 px-2.5"
        >
          Hide
        </div>
      )}
    </div>
  );
};

export default CalendarSessionDetails;

CalendarSessionDetails.propTypes = {
  className: PropTypes.string,
  eventToDisplay: PropTypes.array,
  setEventToDisplay: PropTypes.any,
};
