/* eslint-disable react/prop-types */
import { createContext, useState, useContext, useMemo } from "react";
const LoginModalContext = createContext();

export const useLoginModal = () => {
  return useContext(LoginModalContext);
};
export const LoginModalProvider = ({ children }) => {
  const [openLoginPopup, setOpenLoginPopup] = useState(false);

  const value = useMemo(
    () => ({
      openLoginPopup,
      setOpenLoginPopup,
    }),
    [openLoginPopup, setOpenLoginPopup]
  );

  return (
    <>
      <LoginModalContext.Provider value={value}>
        {children}
      </LoginModalContext.Provider>
    </>
  );
};
