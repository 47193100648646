import { useNavigate } from "@reach/router";
import React from "react";
import networkfp_logo from "../assets/svgs/NFP_logo.svg";

const OnboardingHeader = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex py-4 pl-6 md:pb-0 md:pt-12 md:pl-16 bg-theme-gray-300 md:bg-transparent"
      onClick={() => navigate("/login")}
    >
      <img
        src={networkfp_logo}
        className="h-10 cursor-pointer md:h-16 md:w-56"
        alt=""
      />
    </div>
  );
};

export default OnboardingHeader;
