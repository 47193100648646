import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { PublicProfileService } from "./services/PublicProfileService";

const firebaseConfig = {
  apiKey: "AIzaSyChWbj5X9PkHruXSlVuhAbcG02NZ7PhZgg",
  authDomain: "networkfp-b8ca4.firebaseapp.com",
  projectId: "networkfp-b8ca4",
  storageBucket: "networkfp-b8ca4.appspot.com",
  messagingSenderId: "450591763573",
  appId: "1:450591763573:web:7fda6f0b2ef5d76ff5dc3c",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getTokenFromFirebase = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") return;
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BA849EYnl9MSSzKxogvQTPcMzh-NyNFpb83QTgmbBZo2ap53UqAha4Td_SJ4etrMfex9g3htK2mmPPhzw_-5luU",
    });
    if (currentToken) {
      localStorage.setItem("fcm_token", currentToken);
      await PublicProfileService.updatePublicProfile({
        user_devices_attributes: [{ notification_token: currentToken }],
      });

      return { success: true, token: currentToken };
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // shows on the UI that permission is required
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    return { success: false };
    // catch error while creating client token
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("Received on message onMessageListener", payload);
//       resolve(payload);
//     });

//     // onMessage(messaging, (payload) => {
//     //   resolve(payload);
//     // });
//   });
