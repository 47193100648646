import PropTypes from "prop-types";
import React from "react";
import { DownloadSimple, Star } from "@phosphor-icons/react";
import _ from "lodash";
import classNames from "classnames";

import { CourseService } from "../services/CourseService";

const DownloadActionBar = ({
  type = "",
  id,
  url,
  ratings,
  modalId,
  isCompleted,
}) => {
  const markAsCompleted = async () => {
    if (isCompleted) return;

    await CourseService.updateCourseProgress({
      course_id: modalId,
      last_viewed_content: {
        course_session_content_id: id,
        last_seen_in_sec: 0,
      },
      progress: {
        course_content_id: `${parseInt(id)}`,
        value: { progress_in_sec: 0, status: "completed" },
      },
    }).then(() => {
      window.location.reload();
    });
  };
  return (
    <div
      className={classNames(
        "flex flex-row items-center w-full",
        type !== "client-connect" ? "justify-between" : "justify-center"
      )}
    >
      <button
        className="flex items-center space-x-1.5 hover:cursor-pointer"
        onClick={() => {
          if (url) {
            fetch(url)
              .then((resp) => resp.blob())
              .then((blob) => {
                const blobURL = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = blobURL;
                const filename = _.last(_.head(url.split("?")).split("/"));
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(blobURL);
                markAsCompleted();
              })
              .catch(() => {
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                const filename = _.last(_.head(url.split("?")).split("/"));
                a.download = filename;
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                // CustomToast.error(
                //   "Something went wrong!! Cannot download file"
                // );
              });
          }
        }}
      >
        <DownloadSimple className="text-theme-red" size={20} weight="fill" />
        <div className="text-sm text-theme-red">DOWNLOAD</div>
      </button>
      {ratings && (
        <div className="flex space-x-1">
          <Star
            className="text-sm text-theme-yellow-500 md:text-xl"
            weight="fill"
          />
          <div className="text-theme-black-color">
            {ratings
              ? ratings.toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 1,
                  minimumIntegerDigits: 1,
                })
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

DownloadActionBar.propTypes = {
  id: PropTypes.any,
  onDownloadClick: PropTypes.func,
  ratings: PropTypes.shape({
    toLocaleString: PropTypes.func,
  }),
  type: PropTypes.string,
  url: PropTypes.string,
  modalId: PropTypes.string,
  isCompleted: PropTypes.bool,
};
export default DownloadActionBar;
