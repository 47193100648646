import PropTypes from "prop-types";
import React, { Fragment } from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
const ProMemberDetailPopup = ({
  open,
  heading,
  modalBody,
  handleClose,
  className,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-20 overflow-y-auto"
        open={open}
        onClose={handleClose}
      >
        <div
          className={classNames(
            "flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0",
            className
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                "my-auto inline-block align-center bg-white lg:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-full lg:max-w-xl w-full p-6"
              )}
            >
              <div className="absolute top-0 right-0 z-40 p-6 sm:block">
                <button
                  type="button"
                  className="h-6 bg-white rounded-md hover:text-gray-500"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <X size={22} className="text-theme-red " />
                </button>
              </div>
              <div className="block mr-6 text-xl font-semibold text-theme-black-color ">
                {heading}
              </div>

              {modalBody}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ProMemberDetailPopup.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
  heading: PropTypes.any,
  modalBody: PropTypes.element,
  open: PropTypes.bool,
};

export default ProMemberDetailPopup;
