/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const fetchActiveMemberships = () => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.fetch_active_memberships,
      data: {},
    },
    true
  );
};
const fetchAllCourses = () => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.fetch_all_courses,
      data: {},
    },
    true
  );
};

const fetchCurriculum = ({ course_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.curriculum(course_id),
      data: {},
    },
    true
  );
};

const fetchCourseDetails = ({ course_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.course_details(course_id),
      data: {},
    },
    true
  );
};

const createUserCourse = (id) => {
  return API(
    {
      method: "POST",
      url: config.urls.courses.create_user_course(id),
      data: {},
    },
    true
  );
};

const fetchAllResources = ({ course_session_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.fetch_resources(course_session_id),
      data: {},
    },
    true
  );
};

const fetchCommentsForSession = ({
  entity_id,
  entity_type = "CourseSessionContent",
}) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.fetch_comments,
      data: {},
      params: { entity_id, entity_type },
    },
    true
  );
};

const postCommentForSession = ({
  replied_to_comment_id,
  content,
  attachments_attributes,
  entity_id,
  entity_type = "CourseSessionContent",
}) => {
  return API(
    {
      method: "POST",
      url: config.urls.courses.post_comments,
      data: {
        comment: {
          comment_id: replied_to_comment_id,
          content: content,
          entity_id: entity_id,
          entity_type: entity_type,
          attachments_attributes: attachments_attributes,
        },
      },
    },
    true
  );
};

const fetchReviewsForSession = ({
  entity_id,
  entity_type = "CourseSessionContent",
  reviewer_id,
  reviewer_type = "User",
}) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.fetch_reviews,
      data: {},
      params: { entity_id, entity_type, reviewer_id, reviewer_type },
    },
    true
  );
};
const postReviewsForSession = ({
  review,
  rating_value,
  entity_id,
  entity_type = "CourseSessionContent",
  user_id,
}) => {
  return API(
    {
      method: "POST",
      url: config.urls.courses.post_reviews,
      data: {
        rating: {
          review: review ?? null,
          rating_value: rating_value ?? 0.0,
          reviewer_id: user_id,
          reviewer_type: "User",
          entity_id: entity_id,
          entity_type: entity_type,
        },
      },
    },
    true
  );
};

const updateCourseProgress = async ({
  course_id,
  progress,
  last_viewed_content,
}) => {
  return API(
    {
      method: "PUT",
      url: config.urls.courses.update_course_progress(course_id),
      data: {
        user_course: {
          last_viewed_content: last_viewed_content,
          progress: progress,
        },
      },
    },
    true
  );
};
const fetchQuizInstruction = ({ quiz_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.quiz.fetch_instruction(quiz_id),
    },
    true
  );
};

const fetchQuiz = ({ quiz_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.quiz.quiz_api(quiz_id),
    },
    true
  );
};

const updateCurrentQuizQuestion = (props) => {
  return API(
    {
      method: "PUT",
      url: config.urls.courses.quiz.update_current_view_question(props.quiz_id),
      data: props.data,
    },
    true
  );
};

const submitQuizQuestionResponse = ({
  quiz_id,
  quiz_question_id,
  response,
  seconds_spent,
  is_submit,
}) => {
  let body = {
    quiz_question_id: quiz_question_id,
    answer_ids: response,
    is_submitted: is_submit,
  };
  if (is_submit) {
    body = { ...body, seconds_spent };
  }
  return API(
    {
      method: "PUT",
      url: config.urls.courses.quiz.quiz_api(quiz_id),
      data: body,
    },
    true
  );
};

const fetchQuizSummary = ({ quiz_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.quiz.quiz_result(quiz_id),
    },
    true
  );
};

const addRegistrantToZoomWebinar = ({ course_session_content_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.zoom.add_registrant,
      params: { id: course_session_content_id },
    },
    true
  );
};

const fetchCourseDetailsForMembership = ({ course_type, course_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.promember.course_details,
      params: { course_type, course_id },
    },
    true
  );
};

const fetchQPFPCertificatesBadges = ({ course_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.qpfp_certificates_badges(course_id),
      data: {},
    },
    true
  );
};
const fetchQPFPLeaderboard = ({ course_id }) => {
  return API(
    {
      method: "GET",
      url: config.urls.courses.qpfp_leaderboard(course_id),
      data: {},
    },
    true
  );
};

export const CourseService = {
  fetchActiveMemberships,
  fetchAllCourses,
  fetchCurriculum,
  fetchCourseDetails,
  createUserCourse,
  fetchAllResources,
  fetchCommentsForSession,
  postCommentForSession,
  fetchReviewsForSession,
  postReviewsForSession,
  updateCourseProgress,
  fetchQuizInstruction,
  fetchQuiz,
  updateCurrentQuizQuestion,
  submitQuizQuestionResponse,
  fetchQuizSummary,
  addRegistrantToZoomWebinar,
  fetchCourseDetailsForMembership,
  fetchQPFPCertificatesBadges,
  fetchQPFPLeaderboard,
};
