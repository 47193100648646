/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Loader from "../Shimmer/Loader";
import { useLocation, useNavigate } from "@reach/router";
import QueryString from "qs";
import { PaymentServices } from "../../services/PaymentServices";
import { CustomToast } from "../../stories/Toast";
import _ from "lodash";

const AppPayment = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const parsed = QueryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );

  useEffect(() => {
    if (_.isEmpty(parsed)) {
      return;
    }

    openRazorPayPopup();
  }, [parsed]);

  const openRazorPayPopup = async () => {
    const userDetails = JSON.parse(parsed.userDetails);
    const orderDetails = JSON.parse(parsed.orderDetails);
    try {
      const response = await PaymentServices.makeRazorpayPayment(
        {
          name: userDetails.first_name + " " + userDetails.last_name,
          email: userDetails.email,
          contact: userDetails.mobile_number,
        },
        orderDetails,
        (status) => {
          if (status) {
            CustomToast.success("Payment successfull.");
          } else {
            CustomToast.error("Payment Failed.");
          }
          window.open(`${parsed.cb}?session=${parsed.session}`, "_self");
          return;
        },
        parsed.token ?? ""
      );
    } catch (e) {
      console.error("Error in payment", e);
    }
  };

  if (loading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  return <div>Showing Razorpay</div>;
};

export default AppPayment;
