import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { User as Usericon } from "@phosphor-icons/react";

import { useProfile } from "../../context/userProvider";

import ImageComponent from "../ImageComponent";
import QPFPFullOrangeLogoTransparent from "../../assets/images/QPFPFullOrangeLogoTransparent.png";

/**
 * Component for QPFP Logo Image
 * @return {React.ReactElement} QPFP Logo Image
 */
function QPFPLogo() {
  return (
    <div className="flex items-center justify-start h-15">
      <img
        alt="network fp orange logo"
        src={QPFPFullOrangeLogoTransparent}
        className="h-15"
      />
    </div>
  );
}

/**
 * Component for User Avatar image
 * @return {React.ReactElement} User Avatar image
 */
function UserAvatar() {
  const { userDetails } = useProfile();

  return (
    <div className="relative">
      {userDetails.profile_picture?.signed_url ? (
        <ImageComponent
          alt="profile-icon"
          src={userDetails.profile_picture?.signed_url ?? ""}
          errorComponent={
            <div className="flex items-center justify-center bg-gray-200 rounded-full h-15 w-15">
              <Usericon size={36} weight="bold" className="text-gray-400" />
            </div>
          }
          className="object-cover w-15 h-15 rounded-full"
        />
      ) : (
        <div className="flex items-center justify-center bg-gray-200 rounded-full h-15 w-15">
          <Usericon size={36} weight="bold" className="text-gray-400" />
        </div>
      )}
    </div>
  );
}

/**
 * Component for hero section user card in QPFP Dashboard
 * @return {React.ReactElement} Hero section user card in QPFP Dashboard
 */
function HeroSectionUserCard({ validFrom, validTill }) {
  const { userDetails } = useProfile();

  return (
    <section
      className="p-6 w-full bg-white z-10 md:w-fit"
      style={{
        boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.1)",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(106.56deg, #DFDFDF -1.7%, #C7C7C7 101.75%)",
      }}
    >
      <div className="flex justify-between items-center">
        <UserAvatar />
        <QPFPLogo />
      </div>

      <div className="mt-6 flex flex-col space-y-2 items-start">
        <h3 className="font-semibold text-md md:text-lg">
          {userDetails?.first_name} {userDetails?.last_name}, QPFP{" "}
          <sup>&#xae;</sup>
        </h3>
        <p className="font-light text-sm md:text-base">
          Qualified Personal Finance Professional <sup>&#xae;</sup>
        </p>
      </div>

      <div className="mt-6 flex items-center space-x-5 md:space-x-10">
        <div className="flex space-x-2 items-center">
          <p className="font-light text-xs md:text-sm">Issued on: </p>
          <p className="font-semibold text-sm md:text-md">
            {moment(validFrom).format("D MMMM YYYY")}
          </p>
        </div>

        <div className="flex space-x-2 items-center">
          <p className="font-light text-xs md:text-sm">Valid till:</p>
          <p className="font-semibold text-sm md:text-md">
            {moment(validTill).format("D MMMM YYYY")}
          </p>
        </div>
      </div>
    </section>
  );
}

HeroSectionUserCard.propTypes = {
  validFrom: PropTypes.string,
  validTill: PropTypes.string,
};

export default HeroSectionUserCard;
