import PropTypes from "prop-types";
import React from "react";

import { useProfile } from "../../context/userProvider";
import HeroSectionUserCard from "./HeroSectionUserCard";

/**
 * Component for hero section in QPFP Dashboard
 * @return {React.ReactElement} Hero section in QPFP Dashboard
 */
function HeroSection({ certificationStatus }) {
  const { userDetails } = useProfile();

  return (
    <article className="relative flex flex-col space-y-6 px-4 py-6 pt-10 md:flex-row md:justify-between md:items-center lg:space-y-0 lg:px-20">
      <section className="w-full flex flex-col space-y-5 z-10 md:w-1/2">
        <h1 className="font-semibold text-2xl leading-normal md:text-4xl">
          Welcome {userDetails.first_name} to QPFP Certification Dashboard
        </h1>
        <p className="text-md leading-normal md:text-xl">
          Congratulations for earning the QPFP <sup>&#xae;</sup> credentials -
          the mark that inspired trust and progress!
        </p>
        <p className="font-light text-sm md:text-lg">
          You can here access all your QPFP Certification and Renewal benefits,
          download QPFP brand resources and manage your renewals.
        </p>
      </section>

      <HeroSectionUserCard
        validFrom={certificationStatus.valid_from}
        validTill={certificationStatus.valid_upto}
      />
    </article>
  );
}

HeroSection.propTypes = {
  certificationStatus: PropTypes.object,
};

export default HeroSection;
