/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import officeWebview from "@pdftron/webviewer";
import WebViewer from "@pdftron/pdfjs-express-viewer";

export const PDFTron = ({ url, isDownloadable = true }) => {
  const viewer = useRef(null);

  useEffect(() => {
    if (url.toLowerCase().includes(".pdf")) {
      WebViewer(
        {
          path: "/lib/pdfviewer",
          initialDoc: url,
          licenseKey: "S5dO14Wu2BHVg4nIdpc6",
        },
        viewer.current
      ).then((instance) => {
        if (!isDownloadable) {
          instance.UI.disableElements(["downloadButton", "printButton"]);
        }
        instance.UI.disableElements([
          "languageButton",
          "ribbons",
          "toolsHeader",
          "ribbonsDropdown",
          "selectToolButton",
          "toggleNotesButton",
          "notesPanelResizeBar",
          "notesPanel",
        ]);
        instance.UI.loadDocument(url);
      });
    } else {
      officeWebview(
        {
          path: "/lib/officeviewer",
          licenseKey: "S5dO14Wu2BHVg4nIdpc6",
        },
        viewer.current
      ).then((instance) => {
        if (!isDownloadable) {
          instance.UI.disableElements(["downloadButton", "printButton"]);
        }
        instance.UI.disableElements([
          "languageButton",
          "ribbons",
          "toolsHeader",
          "ribbonsDropdown",
          "selectToolButton",
          "toggleNotesButton",
          "notesPanelResizeBar",
          "notesPanel",
        ]);
        instance.UI.loadDocument(url);
      });
    }
  }, []);

  return <div className="w-full h-full webviewer" ref={viewer}></div>;
};

PDFTron.propTypes = {
  isDownloadable: PropTypes.bool,
  url: PropTypes.string,
};
