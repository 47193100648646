/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import {
  useCorporateProfile,
  useCurrentUserPublicProfile,
} from "../../../services/BasicDetails";
import { useProfile } from "../../../context/userProvider";
import AppSkeleton from "../../AppSkeleton";
import LoadingModal from "../../Modals/LoadingModal";
import DetailsAndStepper from "../../MembershipRegistration/DetailsAndStepper";
import AddMembers from "./AddMembers";
import CompanyDetails from "./CompanyDetails";
import PaymentScreen from "./PaymentScreen";
import ThankYouScreen from "./ThankYouScreen";
import UploadDocuments from "./UploadDocuments";
import _ from "lodash";

const stepsText = [
  {
    heading: "Company Details",
    description: "Update your company information here.",
  },
  {
    heading: "Add & Invite Users",
    description: "Add users to your company.",
  },
  {
    heading: "Payment",
    description: "Make payment for corporate registration.",
  },
  {
    heading: "Upload Documents",
    description: "Upload company incorporation certificate.",
  },
];

const CorporateFlowRegistration = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 4;
  const [maxStep, setMaxStep] = useState(0);
  const [firmId, setFirmId] = useState();
  const { updateUserData } = useProfile();
  const { loading: publicProfileLoading, publicProfileData } =
    useCurrentUserPublicProfile({
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    });
  const {
    loading: corporateProfileLoading,
    corporateProfileData,
    mutate: mutateCorporateProfile,
  } = useCorporateProfile({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });

  const changeStep = (step) => {
    if (step < maxStep) {
      setCurrentStep(step);
    }
  };
  const handleNext = () => {
    mutateCorporateProfile();
    updateUserData();
    if (currentStep === totalSteps) {
      navigate("/", { replace: true });
    }
    if (currentStep !== totalSteps) {
      window.scroll(0, 0);
      setMaxStep(currentStep + 1);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      return;
    } else {
      window.scroll(0, 0);
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    setFirmId(publicProfileData?.firm?.id);
  }, [publicProfileData]);

  useEffect(() => {
    document.querySelector("body").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  const isFirstStepInComplete = () => {
    return (
      corporateProfileData?.firm?.firm_admin_id === null ||
      corporateProfileData?.firm?.no_of_members === null ||
      _.isEmpty(corporateProfileData?.firm?.short_bio) ||
      _.isEmpty(corporateProfileData?.firm?.public_name) ||
      _.isEmpty(corporateProfileData?.firm?.public_email)
    );
  };

  useEffect(() => {
    const c_step = corporateProfileData?.firm?.firm_incorporation_document
      ? 4
      : corporateProfileData?.payment
      ? 3
      : !isFirstStepInComplete()
      ? 1
      : 0;
    setCurrentStep(c_step);
    setMaxStep(c_step);
  }, [corporateProfileLoading]);

  const steps = [
    {
      component: (
        <CompanyDetails
          stepProps={{
            currentStep,
            totalSteps,
            handleNext,
            handleBack,
            changeStep,
          }}
          setFirmId={setFirmId}
          firmId={firmId}
          corporateProfileData={corporateProfileData}
          publicProfileData={publicProfileData}
        />
      ),
    },
    {
      component: (
        <AddMembers
          stepProps={{
            currentStep,
            totalSteps,
            handleNext,
            handleBack,
            changeStep,
          }}
          firmId={firmId}
          corporateProfileData={corporateProfileData}
        />
      ),
    },
    {
      component: (
        <PaymentScreen
          stepProps={{
            currentStep,
            totalSteps,
            handleNext,
            handleBack,
            changeStep,
          }}
          firmId={firmId}
          corporateProfileData={corporateProfileData}
        />
      ),
    },
    {
      component: (
        <UploadDocuments
          stepProps={{
            currentStep,
            totalSteps,
            handleNext,
            handleBack,
            changeStep,
          }}
          firmId={firmId}
          corporateProfileData={corporateProfileData}
        />
      ),
    },
    {
      component: (
        <ThankYouScreen
          stepProps={{
            currentStep,
            totalSteps,
            handleNext,
            handleBack,
            changeStep,
          }}
          firmId={firmId}
          corporateProfileData={corporateProfileData}
        />
      ),
    },
  ];

  return (
    <AppSkeleton
      hideSidebar={true}
      hideProfileDetails={true}
      hideHeaderLabel={true}
    >
      <div className="flex justify-center py-6 h-fit">
        <div className="flex flex-col justify-center h-full gap-3 lg:flex-row">
          <DetailsAndStepper
            className="h-full"
            isNewUser={false}
            stepProps={{
              currentStep,
              totalSteps,
              handleNext,
              changeStep,
              steps: stepsText,
            }}
            heading={"NFP Corporate Membership Application"}
            displayPoints={[
              "Fees - ₹ 13,000 + GST",
              "Validity - 1 year",
              <div key="who">
                Why should one opt for Corporate Membership?
                <ul className="list-decimal">
                  <li className="pl-1 mb-0.5 ml-3">
                    <span className="font-bold text-theme-red">
                      Corporate Members Profile and Listing
                    </span>
                    <span>
                      {" "}
                      - Enhance your presence within our network with a
                      dedicated corporate profile.
                    </span>
                  </li>
                  <li className="pl-1 mb-0.5 ml-3">
                    <span className="font-bold text-theme-red">
                      Team Training Program
                    </span>
                    <span>
                      {" "}
                      - Access a tailor-made 6-hour program focusing on
                      essential soft skills and emotional intelligence for
                      financial advisory and distribution teams.
                    </span>
                  </li>
                  <li className="pl-1 mb-0.5 ml-3">
                    <span className="font-bold text-theme-red">
                      Additional Discounts
                    </span>
                    <span>
                      {" "}
                      - Enjoy exclusive discounts on NFP ProMembership and QPFP
                      Certification Program for bulk registrations.
                    </span>
                  </li>
                </ul>
              </div>,
            ]}
          />
          <div className="flex flex-col">{steps[currentStep]?.component}</div>
        </div>
      </div>
      {publicProfileLoading && corporateProfileLoading && (
        <LoadingModal open={publicProfileLoading && corporateProfileLoading} />
      )}
    </AppSkeleton>
  );
};

export default CorporateFlowRegistration;
