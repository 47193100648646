/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useNavigate } from "@reach/router";
import { Formik, Form } from "formik";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import OnboardingHeader from "../../stories/OnboardingHeader";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import CheckBox from "../../stories/CheckBox";
import CustomLink from "../../stories/Link";
import { AuthService } from "../../services/AuthService";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { User } from "../../store";
import { CustomToast } from "../../stories/Toast";
import { useProfile } from "../../context/userProvider";
import { Validations } from "../../constants/validations";
import _ from "lodash";
import { getTokenFromFirebase } from "../../firebase";
import mixpanel from "mixpanel-browser";
import moment from "moment";

const EmailLoginScreen = (props) => {
  const navigate = useNavigate();
  useDocumentTitle("Login");
  const redirectTo =
    !_.isEmpty(props.location?.state?.pathname) &&
    props.location?.state.pathname !== "/login"
      ? props.location.state.pathname + props.location.state.search
      : "/?guide_user=true";

  const [initialValues] = useState({
    email: props.location?.state?.email ?? "",
    password: "",
    rememberMe: false,
  });

  const { updateUserData } = useProfile();

  const handleNotificationToken = async () => {
    try {
      getTokenFromFirebase();
      updateUserData();
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogin = async ({ email, password, rememberMe }) => {
    try {
      const result = await AuthService.login({
        email,
        password,
        type: "email",
        login_type: "password",
      });
      if (result.token) {
        console.log(props);
        CustomToast.success(result.message);
        User.setToken(result.token);
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: email ?? null,
        });
        updateUserData(true).then((response) => {
          mixpanel.people.set({
            $email: response["email"],
            $name: `${response["first_name"]} ${response["last_name"]}`,
            "User ID": response["id"],
            "Phone number": response["phone_number"],
            "Profile Complete Percentage":
              response["profile_complete_percentage"],
            "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
          });
          mixpanel.identify(response["email"]);
          mixpanel.track("User Login", {
            distinct_id: response["email"],
            id: email,
            "Login Type": "Password",
          });
        });
        await handleNotificationToken();
        setTimeout(() => navigate(redirectTo ?? "/?guide_user=true"), 1000);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
      if (e.error_code === "signup_not_completed") {
        navigate("/complete-signup", {
          state: {
            token: e.payload?.token,
            email: email,
            user: e.payload?.user,
          },
        });
      } else if (e.error_code === "email_not_verified") {
        navigate("/verify-email", { state: { email } });
      }
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          <div className="flex flex-col">
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Login
            </div>
            <div className="flex mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
              Welcome! Enter your registered email ID and password to access
              your account and programs.
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={() =>
                Validations.onBoarding.loginValidationSchema
              }
              enableReinitialize
              onSubmit={handleLogin}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <div className="w-full mt-8 md:mt-12">
                    <AuthTextInput
                      name="email"
                      id="email"
                      type="text"
                      label="Email"
                      autoComplete="email"
                    />
                  </div>
                  <div className="w-full mt-4">
                    <AuthTextInput
                      name="password"
                      id="password"
                      type="password"
                      label="Password"
                      showErrorIcon={true}
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <CheckBox name="rememberMe" inputLabel="Remember me" />
                    <CustomLink
                      target="_blank"
                      href="/forgot-password"
                      textColor="text-blue-500"
                      activeTextColor="text-red-700"
                      showUnderLine={false}
                      underLineOnlyOnHover={false}
                      className="leading-none"
                    >
                      Forgot Password
                    </CustomLink>
                  </div>
                  <Button
                    type="submit"
                    buttonStyle="primary"
                    height="60px"
                    width="100%"
                    className="mt-6"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    LOGIN
                  </Button>
                  <div className="flex items-center justify-start w-full mt-3 text-sm leading-5">
                    New to NetworkFP? &nbsp;
                    <CustomLink
                      href="/signup"
                      textColor="text-theme-red"
                      activeTextColor="text-theme-red"
                      fontSize="md"
                      showUnderLine={true}
                      className="leading-5"
                    >
                      Sign Up for Free
                    </CustomLink>
                  </div>
                  <div className="flex items-center gap-2 mb-6 mt-12">
                    <div className="w-full border-t border-theme-gray-100" />
                    <div className="italic font-light text-theme-gray-600">
                      or
                    </div>
                    <div className="w-full border-t border-theme-gray-100" />
                  </div>
                  <div className="flex justify-center">
                    <CustomLink
                      href="/login/otp"
                      state={{
                        email: values["email"],
                        ...(props.location?.state ?? {}),
                      }}
                      textColor="text-theme-red"
                      activeTextColor="text-theme-red"
                      showUnderLine={false}
                      underLineOnlyOnHover={false}
                      className="leading-none"
                    >
                      Login with OTP
                    </CustomLink>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
    </div>
  );
};

export default EmailLoginScreen;
