import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import { Warning, X } from "@phosphor-icons/react";

const ConfirmSubmitQuizPopup = ({
  open,
  hasSkippedQuestions,
  onClose,
  onConfirm,
  className,
}) => {
  const [forceSubmit, setForceSubmit] = React.useState(!hasSkippedQuestions);
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full px-4 pt-5 pb-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="flex items-center mb-3 text-2xl font-semibold leading-6 tracking-tight text-theme-black-color"
              >
                <div className="flex items-center justify-center mr-4 rounded-full min-w-10 bg-theme-orange bg-opacity-30">
                  <Warning
                    className="m-2 text-theme-orange"
                    size={24}
                    weight="fill"
                  />
                </div>
                Submit Quiz
              </Dialog.Title>
              <div className="flex pl-2 ml-12 text-base w-fit">
                {forceSubmit
                  ? "Are you sure you want to submit the quiz"
                  : "You have skipped few questions."}
              </div>
              <div className="flex flex-col items-center w-full mt-10 md:justify-end md:flex-row">
                <Button
                  buttonStyle="secondary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="204px"
                  onClick={onClose}
                >
                  CANCEL and GO BACK
                </Button>
                <Button
                  buttonStyle="primary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="204px"
                  disabled={isBtnLoading}
                  className="mt-2 ml-0 md:mt-0 lg:ml-2"
                  onClick={async () => {
                    if (forceSubmit) {
                      setIsBtnLoading(true);
                      await onConfirm();
                      setIsBtnLoading(false);
                    } else {
                      setForceSubmit(true);
                    }
                  }}
                >
                  SUBMIT QUIZ
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ConfirmSubmitQuizPopup.defaultProps = {
  open: false,
  className: "",
};

ConfirmSubmitQuizPopup.propTypes = {
  className: PropTypes.string,
  hasSkippedQuestions: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

export default ConfirmSubmitQuizPopup;
