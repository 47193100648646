/* eslint-disable react/prop-types */
import React, { useState } from "react";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import OnboardingHeader from "../../stories/OnboardingHeader";
import { useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import CustomLink from "../../stories/Link";
import { AuthService } from "../../services/AuthService";
import { CustomToast } from "../../stories/Toast";
import qs from "qs";
import _ from "lodash";
import { Validations } from "../../constants/validations";
import AuthDropdown from "../../stories/AuthDropdown";
import { useLocationList } from "../../services/BasicDetails";
import FirmInviteRequestModal from "../Modals/FirmInviteRequestModal";
import { User } from "../../store";
import { useProfile } from "../../context/userProvider";
import mixpanel from "mixpanel-browser";

const SignUpScreen = (props) => {
  const navigate = useNavigate();
  const { locations, loading: loadingLocation } = useLocationList();
  const [showFirmInviteModal, setShowFirmInviteModal] = useState(false);
  const [firmData, setFirmData] = useState({});
  useDocumentTitle("Signup");
  const { updateUserData } = useProfile();

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    mobileno: "",
    city: "",
  };

  const handleSignUp = async (data) => {
    const myObj = qs.parse(_.last(props.location.search.split("?")));
    try {
      const { fname, lname, mobileno, city, email, password } = data;
      const result = await AuthService.signup({
        first_name: fname,
        last_name: lname,
        phone_number: mobileno,
        city_id: city,
        email: email,
        password: password,
        src: myObj.src,
      });
      mixpanel.people.set({
        distinct_id: email,
        $email: email,
        $name: `${fname} ${lname}`,
        $city: locations.find((location) => location.id === city).name,
        "Phone number": mobileno,
      });
      mixpanel.identify(email);
      mixpanel.track("Sign Up", {
        distinct_id: email,
        $email: email,
        $name: `${fname} ${lname}`,
        $city: locations.find((location) => location.id === city).name,
        "Phone number": mobileno,
      });
      if (result.token) {
        User.setToken(result.token);
        const decodedData = JSON.parse(window.atob(result.token.split(".")[1]));
        window.fcWidget?.init({
          token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
          host: "https://wchat.in.freshchat.com",
          externalId: decodedData?.data?.unique_id ?? null,
        });
        updateUserData(true).then((response) => {
          mixpanel.people.set({
            distinct_id: email,
            $email: response["email"],
            $name: `${response["first_name"]} ${response["last_name"]}`,
            $city: locations.find((location) => location.id === city).name,
            "User ID": response["id"],
            "Phone number": response["phone_number"],
          });
        });
        if (result?.firm_user?.firm_user_id) {
          setFirmData({
            firm_id: result?.firm_user?.firm_user_id,
            firm_name: result?.firm_user?.firm_name,
          });
          setShowFirmInviteModal(true);
        } else {
          setTimeout(() => navigate("/"), 1000);
          CustomToast.success(result.message);
        }
        return;
      }
      if (result.message) {
        CustomToast.success(result.message);
        navigate("/verify-email", {
          state: { email: email },
        });
      }
    } catch (e) {
      if (e.error_code === "duplicate_email") {
        CustomToast.error(e.error_messages[0]);
        navigate("/login");
      } else {
        CustomToast.error(e.error_messages[0] ?? "Something went wrong");
      }
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-6 pb-20 mt-6 bg-white md:px-16 lg:mt-10">
          <div className="flex flex-col">
            <div className="py-1.5 px-3 bg-theme-orange rounded-full text-white w-fit text-xs leading-none mb-2 lg:mb-4">
              Free account
            </div>
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Sign Up for free
            </div>
            <div className="flex mt-4 text-base leading-6 tracking-tight text-left lg:mt-6 md:leading-5 lg:text-lg text-theme-black-300 ">
              Set-up your free account to access free trainings, create your
              profile & register for all programs and events.
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={() =>
                Validations.onBoarding.signupValidationSchema
              }
              onSubmit={handleSignUp}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col w-full gap-4 mt-4 md:mt-8">
                  <AuthTextInput
                    name="fname"
                    id="fname"
                    type="text"
                    label="First Name"
                  />
                  <AuthTextInput
                    name="lname"
                    id="lname"
                    type="text"
                    label="Last Name"
                  />
                  <AuthTextInput
                    name="email"
                    id="email"
                    type="text"
                    label="Email"
                    autoComplete="email"
                  />
                  <AuthTextInput
                    name="password"
                    id="password"
                    type="password"
                    label="Password"
                    autoComplete="new-password"
                  />
                  <AuthTextInput
                    showLeading
                    name="mobileno"
                    id="mobileno"
                    type="text"
                    label="Mobile No."
                    leadingComponent={
                      <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                        +91
                      </span>
                    }
                  />
                  <AuthDropdown
                    name="city"
                    id="city"
                    type="text"
                    placeholder="City"
                    options={locations}
                    displayCurrentOption
                    displayKey="name"
                    idKey="id"
                  />
                  <div className="flex flex-col mt-2 gap-2.5 w-full">
                    <div className="text-sm">
                      By clicking Sign Up, you agree to our{" "}
                      <a
                        href="https://networkfp.com/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-theme-black-color hover:underline"
                        alt="Terms and Conditions"
                      >
                        Terms and Conditions
                      </a>
                      &nbsp;and that you&apos;ve read our{" "}
                      <a
                        href="https://networkfp.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-theme-black-color hover:underline"
                        alt="Privacy Policy"
                      >
                        Privacy Policy
                      </a>
                      .
                    </div>
                    <Button
                      type="submit"
                      buttonStyle="primary"
                      height="60px"
                      width="100%"
                      loading={loadingLocation || isSubmitting}
                      disabled={loadingLocation || isSubmitting}
                    >
                      SIGN UP
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="flex items-center justify-start w-full mt-3 text-sm leading-5">
              Already have an account? &nbsp;
              <CustomLink
                href="/login"
                textColor="text-theme-red"
                activeTextColor="text-theme-red"
                fontSize="md"
                showUnderLine={true}
                className="leading-5"
              >
                Log In
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex " />
      </div>
      {showFirmInviteModal && (
        <FirmInviteRequestModal
          open={showFirmInviteModal}
          onClose={() => {
            setShowFirmInviteModal(false);
            setFirmData({});
            CustomToast.success("Successfully logged in");
            navigate("/");
          }}
          firmDetails={firmData}
        />
      )}
    </div>
  );
};

export default SignUpScreen;
