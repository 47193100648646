/* eslint-disable no-useless-escape */
import moment from "moment";
import * as yup from "yup";
import _ from "lodash";

const websiteRegex =
  /(\A\z)|^((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,256}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)\.[^\s]{2,})$/;

const personalInfovalidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Enter First Name"),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Enter Last Name"),
  displayName: yup
    .string()
    .max(255, "Max 255 chars allowed")
    .required("Display name cannot be empty"),
  linkedIn: yup.string(),
  dob: yup
    .string()
    .required("DOB is Required")
    .nullable()
    .test("DOB", "Age should be greater than 18", (value) => {
      return (
        Math.ceil(
          (new Date() -
            new Date(
              value && value.includes("/") ? moment(value, "DD/MM/YYYY") : value
            )) /
            (1000 * 60 * 60 * 24 * 365)
        ) >= 18
      );
    }),
  gender: yup.string().nullable().required("Required"),
  bio: yup.string().max(1000, "Max 1000 chars allowed"),
});

const firmInfoValidationSchema = yup.object().shape({
  firmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .nullable()
    .required("Firm name is required"),
  // firmName: yup
  //   .object()
  //   .nullable()
  //   .shape({
  //     id: yup.number().nullable(),
  //     name: yup
  //       .string("Must be a string")
  //       .max(255, "Max 255 chars allowed")
  //       .nullable(),
  //   })
  //   .required("Firm name is required"),
  firmWebsite: yup
    .string("Must be a string")
    .matches(websiteRegex, "Enter a valid website url"),
  addressLine1: yup
    .string("Address is required")
    .max(255, "Max 255 chars allowed")
    .required("Address is required"),
  city: yup.string("Must be a string"),
  pincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .required("Pincode is required"),
  state: yup.string("Must be a string"),
});
const FILE_SIZE = 2 * 1024 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const educationInfoValidationSchema = yup.object().shape(
  {
    graduation: yup.array(
      yup.object().shape({
        id: yup.string(),
        graduation_level: yup.string(),
        field_of_study: yup.string(),
      })
    ),
    industryExam: yup.array(
      yup.object().shape({
        id: yup.string(),
        graduation_level: yup.string(),
        field_of_study: yup.string(),
      })
    ),
    professional: yup.array(
      yup.object().shape({
        id: yup.string(),
        graduation_level: yup.string(),
        field_of_study: yup.string(),
      })
    ),
    // graduationCollegeName: yup
    //   .string()
    //   .when(["graduationCertificate", "graduationCompleted"], {
    //     is: (graduationCertificate, graduationCompleted) =>
    //       graduationCertificate || graduationCompleted,
    //     then: yup.string().required("College name is required"),
    //     otherwise: yup.string(),
    //   }),
    // graduationCompleted: yup
    //   .string()
    //   .when(["graduationCertificate", "graduationCollegeName"], {
    //     is: (graduationCertificate, graduationCollegeName) =>
    //       graduationCertificate || graduationCollegeName,
    //     then: yup.string().required("Field of study is required"),
    //     otherwise: yup.string(),
    //   }),
    // graduationCertificate: yup
    //   .mixed()
    //   // .when(["graduationCollegeName", "graduationCompleted"], {
    //   //   is: (graduationCollegeName, graduationCompleted) =>
    //   //     graduationCollegeName || graduationCompleted,
    //   //   then: yup.mixed().required("Graduation Certificate is required"),
    //   //   otherwise: yup.mixed(),
    //   // })
    //   .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
    //     if (typeof value === "string") return true;
    //     return (
    //       !value ||
    //       (value && value.size <= FILE_SIZE) ||
    //       value.signed_url !== undefined
    //     );
    //   })
    //   .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
    //     if (typeof value === "string") return true;
    //     return (
    //       !value ||
    //       (value && SUPPORTED_FORMATS.includes(value?.type)) ||
    //       value.signed_url !== undefined
    //     );
    //   }),
    // postGraduationCollegeName: yup.string(),
    // // .when(["postGraduationCompleted", "postGraduationCertificate"], {
    // //   is: (postGraduationCompleted, postGraduationCertificate) =>
    // //     postGraduationCompleted || postGraduationCertificate,
    // //   then: yup.string().required("Certifying institute is required"),
    // //   otherwise: yup.string(),
    // // }),
    // postGraduationCompleted: yup.string(),
    // // .when(["postGraduationCollegeName", "postGraduationCertificate"], {
    // //   is: (postGraduationCollegeName, postGraduationCertificate) =>
    // //     postGraduationCollegeName || postGraduationCertificate,
    // //   then: yup.string().required("Field of study is required"),
    // //   otherwise: yup.string(),
    // // }),
    // postGraduationCertificate: yup
    //   .mixed()
    //   // .when(["postGraduationCollegeName", "postGraduationCompleted"], {
    //   //   is: (postGraduationCollegeName, postGraduationCompleted) =>
    //   //     postGraduationCollegeName || postGraduationCompleted,
    //   //   then: yup.mixed().required("Industry Certificate is required"),
    //   //   otherwise: yup.mixed(),
    //   // })
    //   .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
    //     if (typeof value === "string") return true;
    //     return (
    //       !value ||
    //       (value && value.size <= FILE_SIZE) ||
    //       value.signed_url !== undefined
    //     );
    //   })
    //   .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
    //     if (typeof value === "string") return true;
    //     return (
    //       !value ||
    //       (value && SUPPORTED_FORMATS.includes(value?.type)) ||
    //       value.signed_url !== undefined
    //     );
    //   }),
    // professionalCollegeName: yup
    //   .string()
    //   .when(["professionalCompleted", "professionalCertificate"], {
    //     is: (professionalCompleted, professionalCertificate) =>
    //       professionalCompleted || professionalCertificate,
    //     then: yup.string().required("Certifying institute is required"),
    //     otherwise: yup.string(),
    //   }),
    // professionalCompleted: yup
    //   .string()
    //   .when(["professionalCollegeName", "professionalCertificate"], {
    //     is: (professionalCollegeName, professionalCertificate) =>
    //       professionalCollegeName || professionalCertificate,
    //     then: yup.string().required("Field of study is required"),
    //     otherwise: yup.string(),
    //   }),
    // professionalCertificate: yup
    //   .mixed()
    //   // .when(["professionalCollegeName", "professionalCompleted"], {
    //   //   is: (professionalCollegeName, professionalCompleted) =>
    //   //     professionalCollegeName || professionalCompleted,
    //   //   then: yup.mixed().required("Professional Certificate is required"),
    //   //   otherwise: yup.mixed(),
    //   // })
    //   .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
    //     if (typeof value === "string") return true;
    //     return (
    //       !value ||
    //       (value && value.size <= FILE_SIZE) ||
    //       value.signed_url !== undefined
    //     );
    //   })
    //   .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
    //     if (typeof value === "string") return true;
    //     return (
    //       !value ||
    //       (value && SUPPORTED_FORMATS.includes(value?.type)) ||
    //       value.signed_url !== undefined
    //     );
    //   }),
  }
  // [
  //   ["professionalCollegeName", "professionalCompleted"],
  //   ["professionalCollegeName", "professionalCertificate"],
  //   ["professionalCompleted", "professionalCertificate"],
  //   ["postGraduationCollegeName", "postGraduationCompleted"],
  //   ["postGraduationCollegeName", "postGraduationCertificate"],
  //   ["postGraduationCompleted", "postGraduationCertificate"],
  //   ["graduationCollegeName", "graduationCompleted"],
  //   ["graduationCertificate", "graduationCollegeName"],
  //   ["graduationCertificate", "graduationCompleted"],
  // ]
);

const practiceInfoValidationSchema = yup.object().shape({
  experience: yup
    .number("Number Required")
    .min(0, "Experience must be greater than or equal to 0")
    .max(99, "Experience must be less than or equal to 99"),
  noOfFamilies: yup
    .number("Number Required")
    .min(0, "No of families served must be greater than or equal to 0"),
  assets: yup
    .number("Number Required")
    .min(0, "Assets managed must be greater than or equal to 0"),
  natureOfEarnings: yup.string("Please select one option"),
  categoryServed: yup.string("Required").max(255, "Max 255 chars allowed"),
  licenses: yup
    .array()
    .min(1, "Please select atleast one option")
    .of(yup.string())
    .required("Please select atleast one option"),
  service_delivery_model: yup
    .array()
    .min(1, "Please select atleast one option")
    .of(yup.string())
    .required("Please select atleast one option"),
});

const servicesOfferedValidationSchema = yup.object().shape({
  solutions: yup
    .array()
    .min(1, "Please select atleast one option")
    .of(yup.string())
    .nullable()
    .required("Please select atleast one option"),
  products: yup
    .array()
    .min(1, "Please select atleast one option")
    .of(yup.string())
    .nullable()
    .required("Please select atleast one option"),
});

const contactInfoValidationSchema = yup.object().shape({
  email: yup
    .string("Required")
    .email("Enter valid email address")
    .required("Enter Email Address"),
  phone: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits"),
  whatsapp_link: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits"),
});

const complimentaryServicesCardValidationSchema = yup.object().shape({
  solutions: yup
    .array()
    .min(1, "Please select atleast one option")
    .nullable()
    .of(yup.string())
    .required("Please select atleast one option"),
});

const rsvpFormvalidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  email: yup
    .string("Required")
    .email("Enter valid email address")
    .required("Email ID is required"),
  mobileNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  rsvp: yup
    .array()
    .of(yup.number("RSVP is required").nullable().required("RSVP is required"))
    .nullable()
    .required("RSVP is required"),
});

const eventFormvalidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  email: yup
    .string("Required")
    .email("Enter valid email address")
    .required("Email ID is required"),
  mobileNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  firmName: yup
    .string()
    .max(255, "Max 255 chars allowed")
    .required("Firm name is required"),
  city: yup.string().when("enable_rsvp", {
    is: true,
    then: yup.string().nullable(),
    otherwise: yup.string().required("City is required"),
  }),
  venue: yup.string().nullable(),
  referrer_name: yup.string().max(255, "Max 255 chars allowed"),
  referrer_contact: yup
    .string()
    .test("Email_Validation", "Enter a valid detail", (value) => {
      const emailpattern =
        /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
      const mobilepattern = /^\d{10}$/;
      return (
        !value ||
        (value && (emailpattern.test(value) || mobilepattern.test(value)))
      );
    }),
  growth_partner_id: yup.string().nullable(),
  rsvp: yup.array().when("enable_rsvp", {
    is: true,
    then: yup
      .array()
      .of(
        yup.number("RSVP is required").nullable().required("RSVP is required")
      )
      .nullable()
      .required("RSVP is required"),
    otherwise: yup.array().nullable(),
  }),
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches("^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$", {
        message: "Invalid GST no",
      })
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter firm name"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCommunicationAddress: yup
    .string("Required")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter address"),
      otherwise: yup.string("Must be a string"),
    }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("City is required"),
    otherwise: yup.string("Must be a string"),
  }),
});

const unbounceOnboardingFormvalidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  email: yup
    .string("Required")
    .email("Enter valid email address")
    .required("Email ID is required"),
  mobileNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  city: yup.string().when("enable_rsvp", {
    is: true,
    then: yup.string().nullable(),
    otherwise: yup.string().required("City is required"),
  }),
  growth_partner_id: yup.string().nullable(),
  rsvp: yup.array().when("enable_rsvp", {
    is: true,
    then: yup
      .array()
      .of(
        yup.number("RSVP is required").nullable().required("RSVP is required")
      )
      .nullable()
      .required("RSVP is required"),
    otherwise: yup.array().nullable(),
  }),
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches("^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$", {
        message: "Invalid GST no",
      })
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter firm name"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCommunicationAddress: yup
    .string("Required")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter address"),
      otherwise: yup.string("Must be a string"),
    }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required(),
    otherwise: yup.string("Must be a string"),
  }),
});

const contributionFormvalidationSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Amount must be a number")
    .test(
      "amount",
      "Enter amount in multiple of 1000 and should be less than 12000.",
      (value) => {
        return value > 0 && value <= 12000 && value % 1000 === 0;
      }
    )
    .required("Amount is required"),
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches("^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$", {
        message: "Invalid GST no",
      })
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter firm name"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCommunicationAddress: yup
    .string("Required")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter address"),
      otherwise: yup.string("Must be a string"),
    }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required(),
    otherwise: yup.string("Must be a string"),
  }),
});

const changePhoneNumberPopupValidationSchema = yup.object().shape({
  phone_number: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .length(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
});
const changePasswordPopupValidationSchema = yup.object().shape({
  current_password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should be of minimum 8 characters"),
  password: yup
    .string()
    .required("New Password is required")
    .notOneOf(
      [yup.ref("current_password"), null],
      "New password and current password cannot be same"
    )
    .min(8, "Password should be of minimum 8 characters"),
  password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords does not match"),
});
const updateAddressValidationSchema = yup.object().shape({
  address_attributes: yup.object().shape({
    line_1: yup
      .string("Required")
      .required("Please enter complete address")
      .max(255, "Max 255 chars allowed"),
    city: yup.string("Must be a string").required(),
    pin: yup
      .string("Enter a valid pincode")
      .matches(/^[0-9_ ]*$/, "Only numeric digits are allowed")
      .length(6, "Pincode should be 6 digits only")
      .required("Enter a pincode"),
    state: yup.string("Must be a string").required(),
  }),
});

const additionalDetailsValidationSchema = yup.object().shape({
  dob: yup
    .string()
    .required("DOB is Required")
    .nullable()
    .test("DOB", "Age should be greater than 18", (value) => {
      return (
        Math.ceil(
          (new Date() -
            new Date(
              value && value.includes("/") ? moment(value, "DD/MM/YYYY") : value
            )) /
            (1000 * 60 * 60 * 24 * 365)
        ) >= 18
      );
    }),
  gender: yup.string().nullable().required("Required"),
  graduation_id: yup.number().nullable().required("Required"),
  industry_ids: yup
    .array()
    .nullable()
    .min(1, "Please select at least one option")
    .of(yup.number())
    .required("Please select at least one option"),
  professional_ids: yup
    .array()
    .nullable()
    .min(1, "Please select at least one option")
    .of(yup.number())
    .required("Please select at least one option"),
});

const paymentPopupValidationSchema = yup.object().shape({
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches(
        "^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$",
        "Invalid GST no"
      )
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("Enter firm name"),
    otherwise: yup.string("Must be a string"),
  }),
  gstCommunicationAddress: yup.string("Required").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("Enter address"),
    otherwise: yup.string("Must be a string"),
  }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .length(6, "Enter a valid pincode")
    .when("wantGST", {
      is: true,
      then: yup
        .string("Must be a string")
        .length(6, "Enter a valid pincode")
        .required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("City is required."),
    otherwise: yup.string("Must be a string"),
  }),
});

const reviewPopupValidationSchema = yup.object().shape({
  rating: yup
    .number("Please add ratings")
    .min(1, "Please add ratings")
    .required("Please add ratings"),
  review: yup.string(),
});

const visitorEnquiryValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone_number: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .length(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
});

const completeSignupValidationSchema = yup.object().shape({
  fname: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  lname: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  mobileno: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .length(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  city: yup.string().required("City is required"),
});

const forgotPasswordValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});
const otpLoginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .test("Email_Validation", "Invalid email or mobile number", (value) => {
      const emailpattern =
        /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
      const mobilepattern = /^\d{10}$/;
      return (
        !value ||
        (value && (emailpattern.test(value) || mobilepattern.test(value)))
      );
    })
    .required("Enter your registered Email or Mobile Number"),
});

const loginValidationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});
const otpVerification = yup.object().shape({
  otp: yup
    .array(yup.string().length(1))
    .test("OTP_validation", "Enter a valid OTP", (value) => {
      const otp = value.join("");
      const otppattern = /^\d{6}$/;
      return !otp || (otp && otppattern.test(otp));
    })
    .required("Enter a valid otp"),
});

const resetPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters")
    .required("Password is required"),
  cpassword: yup
    .string()
    .required("Re-enter password")
    .oneOf([yup.ref("password"), null], "Passwords does not match"),
});

const signupValidationSchema = yup.object().shape({
  fname: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  lname: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters")
    .required("Password is required"),
  mobileno: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .length(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  city: yup.string().required("City is required"),
});

const paymentScreenValidationSchema = yup.object().shape({
  membership: yup.object().shape({
    first_name: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
      .max(255, "Max 255 chars allowed")
      .required("First name is required"),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
      .max(255, "Max 255 chars allowed")
      .required("Last name is required"),
    email: yup
      .string("Required")
      .email("Enter valid email address")
      .required("Email ID is required"),
    mobile_number: yup
      .string()
      .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
      .min(10, "Mobile number should be of 10 digits")
      .max(10, "Mobile number should be of 10 digits")
      .required("Mobile number is required"),
    city_id: yup.string("Must be a string").required("City is required"),
    referrer_name: yup.string().max(255, "Max 255 chars allowed"),
    referrer_contact: yup
      .string()
      .test("Email_Validation", "Enter a valid detail", (value) => {
        const emailpattern =
          /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
        const mobilepattern = /^\d{10}$/;
        return (
          !value ||
          (value && (emailpattern.test(value) || mobilepattern.test(value)))
        );
      }),
  }),
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches(
        "^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$",
        "Invalid GST no"
      )
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter firm name"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCommunicationAddress: yup
    .string("Required")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter address"),
      otherwise: yup.string("Must be a string"),
    }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9 ]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("City is required"),
    otherwise: yup.string("Must be a string"),
  }),
});

const shareDetailsScreenValidationSchema = yup.object().shape({
  firm_name: yup.string("Must be a string").max(255, "Max 255 chars allowed"),
  designation: yup.string("Must be a string").max(255, "Max 255 chars allowed"),
  address_attributes: yup.object().shape({
    line_1: yup
      .string("Required")
      .notOneOf(["-"], "Please enter valid address")
      .required("Please enter complete address")
      .max(255, "Max 255 chars allowed"),
    city: yup.string("Must be a string").required(),
    pin: yup
      .string("Enter a valid pincode")
      .matches(/^[0-9_ ]*$/, "Pincode should be 6 digits only")
      .length(6, "Pincode should be 6 digits only")
      .required("Enter a pincode"),
    state: yup.string("Must be a string").required(),
  }),
  isGraduationCompleted: yup.bool(),
  graduation_id: yup
    .number()
    .nullable()
    .required("Please select atleast one graduation stream"),
  professional_ids: yup.array().when("entity_type", {
    is: "pro_member",
    then: yup
      .array()
      .min(1, "Please select atleast one option")
      .of(yup.string())
      .required("Please select atleast one option"),
  }),
  industry_ids: yup.array().when("entity_type", {
    is: "pro_member",
    then: yup
      .array()
      .min(1, "Please select atleast one option")
      .of(yup.string())
      .required("Please select atleast one option"),
  }),
});
const personalDetailsValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("First name is required"),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed")
    .max(255, "Max 255 chars allowed")
    .required("Last name is required"),
  email: yup
    .string("Required")
    .email("Enter valid email address")
    .required("Email ID is required"),
  mobile_number: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  dob: yup
    .string()
    .required("DOB is Required")
    .nullable()
    .test("DOB", "Age should be greater than 18", (value) => {
      return (
        Math.ceil(
          (new Date() -
            new Date(
              value?.includes("/") ? moment(value, "DD/MM/YYYY") : value
            )) /
            (1000 * 60 * 60 * 24 * 365)
        ) >= 18
      );
    }),
  gender: yup.string().nullable().required("Select gender"),
  firm_name: yup.string("Must be a string").max(255, "Max 255 chars allowed"),
  designation: yup.string("Must be a string").max(255, "Max 255 chars allowed"),
  address_attributes: yup.object().shape({
    line_1: yup
      .string("Required")
      .required("Please enter complete address")
      .max(255, "Max 255 chars allowed"),
    city: yup.string("Must be a string").required(),
    pin: yup
      .string("Enter a valid pincode")
      .matches(/^[0-9_ ]*$/, "Only numeric digits are allowed")
      .length(6, "Pincode should be 6 digits only")
      .required("Enter a pincode"),
    state: yup.string("Must be a string").required(),
  }),
  qpfp_interested_id: yup
    .number()
    .nullable()
    .when("entity_type", {
      is: "provisional_member",
      then: yup.number().required("Please select your interest"),
    }),
  referrer_name: yup.string().max(255, "Max 255 chars allowed"),
  referrer_contact: yup
    .string()
    .test("Email_Validation", "Enter a valid detail", (value) => {
      const emailpattern =
        /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
      const mobilepattern = /^\d{10}$/;
      return (
        !value ||
        (value && (emailpattern.test(value) || mobilepattern.test(value)))
      );
    }),
});

const paymentDetailsValidationSchema = yup.object().shape({
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches(
        "^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$",
        "Invalid GST no"
      )
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter firm name"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCommunicationAddress: yup
    .string("Required")
    .max(255, "Max 255 chars allowed")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter address"),
      otherwise: yup.string("Must be a string"),
    }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9 ]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .when("wantGST", {
      is: true,
      then: yup.string("Must be a string").required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required(),
    otherwise: yup.string("Must be a string"),
  }),
});

const educationDetailsValidationSchema = yup.object().shape({
  isGraduationCompleted: yup.bool(),
  entity_type: yup.string(),
  graduationFieldOfStudy: yup
    .number()
    .nullable()
    .required("Please select atleast one graduation stream"),
  qualifications: yup.array().when("entity_type", {
    is: "pro_member",
    then: yup
      .array()
      .min(1, "Please select atleast one option")
      .of(yup.string())
      .required("Please select atleast one option"),
  }),
  exams: yup.array().when("entity_type", {
    is: "pro_member",
    then: yup
      .array()
      .min(1, "Please select atleast one option")
      .of(yup.string())
      .required("Please select atleast one option"),
  }),
  career_goal: yup.string().when("entity_type", {
    is: "qpfp",
    then: yup.string().required("This is a required field"),
  }),
  expectation: yup.string().when("entity_type", {
    is: "qpfp",
    then: yup.string().required("This is a required field"),
  }),
});

const documentDetailsValidationSchema = yup.object().shape({
  graduationCertificate: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    }),
  // .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
  //   if (typeof value === "string") return true;
  //   return (
  //     !value ||
  //     (value && SUPPORTED_FORMATS.includes(value?.type)) ||
  //     value.signed_url !== undefined
  //   );
  // }),
  // .test("FILE_NAME", "File name is invalid.", (value) => {
  //   if (typeof value === "string") return true;
  //   return (
  //     !value ||
  //     (value && /^[\w\s-]+\.[A-Za-z]{3,4}$/.test(value)) ||
  //     value.signed_url !== undefined
  //   );
  // }),
  industryExamCertificate: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    })
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && SUPPORTED_FORMATS.includes(value?.type)) ||
        value.signed_url !== undefined
      );
    }),
  // .test("FILE_NAME", "File name is invalid.", (value) => {
  //   if (typeof value === "string") return true;
  //   return (
  //     !value ||
  //     (value && /^[\w\s-]+\.[A-Za-z]{3,4}$/.test(value)) ||
  //     value.signed_url !== undefined
  //   );
  // }),
  professionalCertificate: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    })
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && SUPPORTED_FORMATS.includes(value?.type)) ||
        value.signed_url !== undefined
      );
    }),
  // .test("FILE_NAME", "File name is invalid.", (value) => {
  //   if (typeof value === "string") return true;
  //   return (
  //     !value ||
  //     (value && /^[\w\s-]+\.[A-Za-z]{3,4}$/.test(value)) ||
  //     value.signed_url !== undefined
  //   );
  // }),
  photoId: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    })
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && SUPPORTED_FORMATS.includes(value?.type)) ||
        value.signed_url !== undefined
      );
    }),
  // .test("FILE_NAME", "File name is invalid.", (value) => {
  //   if (typeof value === "string") return true;
  //   return (
  //     !value ||
  //     (value && /^[\w\s-]+\.[A-Za-z]{3,4}$/.test(value)) ||
  //     value.signed_url !== undefined
  //   );
  // }),
  profilePhoto: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    })
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && SUPPORTED_FORMATS.includes(value?.type)) ||
        value.signed_url !== undefined
      );
    }),
  // .test("FILE_NAME", "File name is invalid.", (value) => {
  //   if (typeof value === "string") return true;
  //   return (
  //     !value ||
  //     (value && /^[\w\s-]+\.[A-Za-z]{3,4}$/.test(value)) ||
  //     value.signed_url !== undefined
  //   );
  // }),
});

const rsvpInfoValidationSchema = yup.object().shape({
  rsvp: yup
    .array()
    .of(
      yup
        .array()
        .of(
          yup.number("RSVP is required").nullable().required("RSVP is required")
        )
        .nullable()
        .required("RSVP is required")
    )
    .nullable()
    .required(),
});

const companyDetailsValidationSchema = yup.object().shape({
  name: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .nullable()
    .required("Firm name is required"),
  website: yup
    .string("Must be a string")
    .nullable()
    .matches(websiteRegex, "Enter a valid website url"),
  address_attributes: yup.object().shape({
    line_1: yup
      .string("Required")
      .required("Please enter complete address")
      .max(255, "Max 255 chars allowed"),
    city_id: yup.string("Must be a string").required("City is required"),
    pin: yup
      .string("Enter a valid pincode")
      .matches(/^[0-9_ ]*$/, "Only numeric digits are allowed")
      .length(6, "Pincode should be 6 digits only")
      .required("Enter a pincode"),
    state_id: yup.string("Must be a string").required("State is required"),
  }),
  no_of_members: yup
    .number("Number Required")
    .min(0, "No of Members must be greater than or equal to 0")
    .required("Enter no of members in your team."),
  short_bio: yup
    .string()
    .max(1000, "Max 1000 chars allowed")
    .required("Short bio is required"),
  public_name: yup
    .string("Required")
    .max(255, "Max 255 chars allowed")
    .nullable()
    .required("Enter valid contact name"),
  public_email: yup
    .string("Required")
    .email("Enter valid email address")
    .nullable()
    .required("Enter Email Address"),
  public_mobile_number: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .nullable(),
  whatsapp_number: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .nullable(),
});

const inviteUserValidationSchema = yup.object().shape({
  users: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().email("Enter valid email address"),
      })
    )
    .test("unique", "Enter unique email addresses.", (value) => {
      if (!value) return true;
      if (value.filter((e) => !_.isEmpty(e.email)).length === 0) return true;
      return (
        value.filter((e) => !_.isEmpty(e)).length ===
        new Set(value.map((e) => e.email))?.size
      );
    }),
});
const uploadDocumentValidationSchema = yup.object().shape({
  incorporation_document: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    }),
});

const companyInfoValidationSchema = yup.object().shape({
  firmName: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .nullable()
    .required("Firm name is required"),
  firmWebsite: yup
    .string("Must be a string")
    .matches(websiteRegex, "Enter a valid website url"),
  addressLine1: yup
    .string("Address is required")
    .max(255, "Max 255 chars allowed")
    .required("Address is required"),
  city: yup.string("Must be a string").required(),
  pincode: yup
    .string("Enter a valid pincode")
    .matches(/^[0-9]*$/, "Only numeric digits are allowed")
    .length(6, "Pincode should be 6 digits only")
    .required("Pincode is required"),
  state: yup.string("Must be a string"),
  linkedin_link: yup.string(),
  no_of_members: yup
    .number("Number Required")
    .min(0, "No of members must be greater than or equal to 0"),
  short_bio: yup.string().max(1000, "Max 1000 chars allowed"),
});

const corporateContactInfoValidationSchema = yup.object().shape({
  name: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .nullable()
    .required("Firm name is required"),
  email: yup
    .string("Required")
    .email("Enter valid email address")
    .required("Enter Email Address"),
  phone: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits"),
  whatsapp_link: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits"),
});

const landingPageContactFormValidationSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^[aA-zZ\s]*$/, "Please Enter Valid Name")
    .min(3, "Must be 3 characters or long")
    .max(30, "Must be less than 30 characters or less")
    .required("First Name is Required")
    .trim(),
  lastName: yup
    .string()
    .matches(/^[aA-zZ\s]*$/, "Please Enter Valid Name")
    .min(3, "Must be 3 characters or long")
    .max(30, "Must be less than 30 characters or less")
    .required("Last Name is Required")
    .trim(),
  mobileNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is Required"),
  message: yup.string(),
});

const createFormValidation = yup.object({
  title: yup
    .string("Must be a string")
    .max(255, "Max 255 chars allowed")
    .nullable()
    .required("Title name is required"),
  description: yup
    .string("Must be a string")
    .max(5000, "Max 5000 chars allowed")
    .nullable()
    .required("Description is required"),
  attachment: yup
    .mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      if (typeof value === "string") return true;
      if (_.isEmpty(value)) return true;
      return (
        !value ||
        (value && value.size <= FILE_SIZE) ||
        value.signed_url !== undefined
      );
    })
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (typeof value === "string") return true;
      if (_.isEmpty(value)) return true;
      return (
        !value ||
        (value && SUPPORTED_FORMATS.includes(value?.type)) ||
        value.signed_url !== undefined
      );
    }),
  categories: yup
    .array()
    .min(1, "Please select atleast one cateogry.")
    .required("Please select atleast one cateogry."),
});

const registrationValidationSchema = yup.object({
  first_name: yup
    .string()
    .matches(/^[aA-zZ\s]*$/, "Please Enter Valid Name")
    .max(30, "Must be less than 30 characters or less")
    .required("First Name is Required")
    .trim(),
  last_name: yup
    .string()
    .matches(/^[aA-zZ\s]*$/, "Please Enter Valid Name")
    .min(1, "Must be 1 characters or long")
    .max(30, "Must be less than 30 characters or less")
    .required("Last Name is Required")
    .trim(),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is Required"),
  mobile_number: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should only contains 10 digits")
    .min(10, "Mobile number should be of 10 digits")
    .max(10, "Mobile number should be of 10 digits")
    .required("Mobile number is required"),
  dob: yup
    .string()
    .required("DOB is Required")
    .nullable()
    .test("DOB", "Age should be greater than 18", (value) => {
      return (
        Math.ceil(
          (new Date() -
            new Date(
              value && value.includes("/") ? moment(value, "DD/MM/YYYY") : value
            )) /
            (1000 * 60 * 60 * 24 * 365)
        ) >= 18
      );
    }),
  gender: yup.string().nullable().required("Required"),
  firm_name: yup.string("Must be a string").max(255, "Max 255 chars allowed"),
  designation: yup.string("Must be a string").max(255, "Max 255 chars allowed"),
  address_attributes: yup.object().shape({
    line_1: yup
      .string("Required")
      .notOneOf(["-"], "Please enter valid address")
      .required("Please enter complete address")
      .max(255, "Max 255 chars allowed"),
    pin: yup
      .string("Enter a valid pincode")
      .matches(/^[0-9_ ]*$/, "Pincode should be 6 digits only")
      .length(6, "Pincode should be 6 digits only")
      .required("Enter a pincode"),
    city_id: yup.string("Must be a string").required("City is required"),
    state_id: yup.string("Must be a string").required("State is required"),
  }),
});
const paymentValidationSchema = yup.object().shape({
  wantGST: yup.bool(),
  gstin: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup
      .string("Must be a string")
      .matches(
        "^\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$",
        "Invalid GST no"
      )
      .required("Enter GST number"),
    otherwise: yup.string("Must be a string"),
  }),
  gstFirmName: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("Enter firm name"),
    otherwise: yup.string("Must be a string"),
  }),
  gstCommunicationAddress: yup.string("Required").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("Enter address"),
    otherwise: yup.string("Must be a string"),
  }),
  gstPincode: yup
    .string("Enter a valid pincode")
    .length(6, "Enter a valid pincode")
    .when("wantGST", {
      is: true,
      then: yup
        .string("Must be a string")
        .length(6, "Enter a valid pincode")
        .required("Enter pincode"),
      otherwise: yup.string("Must be a string"),
    }),
  gstCity_id: yup.string("Must be a string").when("wantGST", {
    is: true,
    then: yup.string("Must be a string").required("City is required."),
    otherwise: yup.string("Must be a string"),
  }),
});

export const Validations = {
  publicProfile: {
    personalInfovalidationSchema,
    firmInfoValidationSchema,
    educationInfoValidationSchema,
    practiceInfoValidationSchema,
    servicesOfferedValidationSchema,
    contactInfoValidationSchema,
    complimentaryServicesCardValidationSchema,
  },
  corporateProfile: {
    companyInfoValidationSchema,
    corporateContactInfoValidationSchema,
  },
  eventFormvalidationSchema,
  unbounceOnboardingFormvalidationSchema,
  contributionFormvalidationSchema,
  rsvpFormvalidationSchema,
  popup: {
    changePhoneNumberPopupValidationSchema,
    changePasswordPopupValidationSchema,
    paymentPopupValidationSchema,
    reviewPopupValidationSchema,
    updateAddressValidationSchema,
    additionalDetailsValidationSchema,
  },
  onBoarding: {
    visitorEnquiryValidationSchema,
    otpLoginValidationSchema,
    completeSignupValidationSchema,
    forgotPasswordValidationSchema,
    loginValidationSchema,
    otpVerification,
    resetPasswordValidationSchema,
    signupValidationSchema,
  },
  membership: {
    paymentScreenValidationSchema,
    shareDetailsScreenValidationSchema,
    personalDetailsValidationSchema,
    paymentDetailsValidationSchema,
    educationDetailsValidationSchema,
    documentDetailsValidationSchema,
    rsvpInfoValidationSchema,
  },
  corporate: {
    companyDetailsValidationSchema,
    inviteUserValidationSchema,
    uploadDocumentValidationSchema,
  },
  exchangeForum: {
    createFormValidation,
  },
  qpfpCertification: {
    registrationValidationSchema,
    paymentValidationSchema,
  },
  landingPageContactFormValidationSchema,
};
