import PropTypes from "prop-types";
import React from "react";
import { User as Usericon } from "@phosphor-icons/react";
import ImageComponent from "../../../stories/ImageComponent";
import EmailIcon from "../../../assets/svgs/qpfp-email-icon.svg";
import PhoneIcon from "../../../assets/svgs/qpfp-phone-icon.svg";
import WhatsAppIcon from "../../../assets/svgs/qpfp-whatsapp-icon.svg";
import _ from "lodash";

/**
 * Component for Relationship Manager Dashboard Element
 * @return {React.ReactElement} Relationship Manager Dashboard Element
 */
function RelationshipManager({ managerDetails }) {
  return (
    <div className="flex space-x-3">
      <ImageComponent
        alt="profile-icon"
        src={managerDetails?.profile_picture}
        errorComponent={
          <div className="w-20 h-20 flex items-center justify-center min-w-[80px] bg-gray-100">
            <Usericon size={24} weight="bold" className="text-gray-400" />
          </div>
        }
        className="w-20 h-20 min-w-[80px] object-contain"
      />

      <div className="flex flex-col space-y-2">
        <p className="font-medium text-base">{managerDetails?.fullname}</p>

        <div className="flex items-center space-x-2">
          <img src={EmailIcon} alt="Email Icon" />
          <p className="font-light text-sm text-[#525252]">
            {managerDetails?.email}
          </p>
        </div>

        {!_.isEmpty(managerDetails?.mobile_number) && (
          <div className="flex items-center space-x-2">
            <img src={PhoneIcon} alt="Phone Icon" />
            <p className="font-light text-sm text-[#525252]">
              +91 {managerDetails?.mobile_number}
            </p>
          </div>
        )}

        {!_.isEmpty(managerDetails?.whatsapp_number) && (
          <div className="flex items-center space-x-2">
            <img src={WhatsAppIcon} alt="WhatsApp Icon" />
            <a
              href={`https://wa.me/${managerDetails?.whatsapp_number}`}
              className="font-normal text-sm text-[#16A34A]"
            >
              WhatsApp
            </a>
          </div>
        )}

        <p className="font-light text-xs text-[#525252]">
          Working Hours: Monday to Friday | 10 AM to 6PM
        </p>
      </div>
    </div>
  );
}

RelationshipManager.propTypes = {
  managerDetails: PropTypes.object,
};

export default RelationshipManager;
