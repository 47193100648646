import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PublicProfileCard from "../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../services/PublicProfileService";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import CheckBox from "../../stories/CheckBox";
import { Form, Formik } from "formik";
import checkmark from "../../assets/svgs/Check.svg";
import { oathData } from "../../Constants";

const OathCard = ({
  className,
  publicProfileData,
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({ oath: [] });
  const submitRef = React.useRef();
  const cancelRef = React.useRef();
  const [showError, setError] = useState(false);

  useEffect(() => {
    setInitialValues({
      oath: publicProfileData?.oath_taken ? Array(6).fill(true) : [],
    });
  }, [publicProfileData]);

  const handleSubmit = async (data) => {
    const isValid = (data.oath ?? []).filter((o) => o === true).length === 6;
    if (!isValid) {
      setError(true);
      setInitialValues(data);
      document.getElementsByClassName("oath-error")[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    } else {
      setError(false);
    }
    try {
      const result = await PublicProfileService.updatePublicProfile({
        oath_taken: true,
      });
      if (result.message) {
        CustomToast.success(result.message);
        onUpdate();
        setIsEditing(false);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="NFP Ethics Oath"
        subHeading={
          "Take Network FP's 'Ethics at Heart' Oath. Ethics Icon will be displayed on profile"
        }
        id="ethics-oath-card"
        isEditing={isEditing}
        isLoading={isLoading}
        setIsEditing={setIsEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isEmpty={!isEditing && !publicProfileData?.oath_taken}
        cardBody={
          !isEditing && !publicProfileData?.oath_taken ? (
            <EmptyData
              message=<div>
                To take Network FP&apos;s <b>Ethics at Heart</b> Oath, click on
                the below button.
              </div>
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-4"
                  onClick={() => {
                    document.querySelector(`#ethics-oath-card`).scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    setIsEditing(true);
                  }}
                >
                  Take Oath
                </Button>
              }
            />
          ) : isEditing ? (
            <div className="w-full mt-5">
              <div className="flex">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  {({ values, ...formProps }) => (
                    <Form className="flex flex-col w-full space-y-3">
                      {oathData.map((oath, index) => (
                        <div
                          key={`${oath.title}-${index}`}
                          className="flex flex-col p-4 space-y-3 border border-gray-200 rounded"
                        >
                          <CheckBox
                            inputLabel={oath.title}
                            labelClassName="font-semibold"
                            name={`oath.${index}`}
                            errorMessage=""
                            errorColor=""
                            className=""
                            checked={values.oath[index]}
                            value={values.oath[index]}
                            type="checkbox"
                          />
                          <OathDescription description={oath.description} />
                        </div>
                      ))}
                      {showError && (
                        <div className="flex w-full mt-1 text-sm text-left text-red-700 oath-error">
                          Please select complete oath
                        </div>
                      )}
                      <button
                        className="hidden"
                        ref={submitRef}
                        disabled={formProps.isSubmitting}
                        type="submit"
                      >
                        submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div className="w-full mt-5">
              <div className="flex flex-col w-full space-y-3">
                {oathData.map((oath, index) => (
                  <div
                    key={`${oath.title}-${index}`}
                    className="flex flex-col p-4 space-y-3 border border-gray-200 rounded"
                  >
                    <div className="flex">
                      <div className="w-4 h-4 mr-2 min-w-[16px] rounded-full flex items-center justify-center bg-theme-orange">
                        <img
                          src={checkmark}
                          alt="checkmark"
                          className={classNames("h-3 w-3 min-w-[12px]")}
                        />
                      </div>
                      <div className="flex text-sm leading-4 text-theme-black-300">
                        {oath.title}
                      </div>
                    </div>
                    <OathDescription description={oath.description} />
                  </div>
                ))}
              </div>
            </div>
          )
        }
      />
    </div>
  );
};
const OathDescription = ({ description }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="relative flex w-full text-sm font-light leading-5">
      <div
        className={classNames(
          "flex w-full text-theme-black-300 ",
          !showMore && "line-clamp-1 h-4 leading-4"
        )}
      >
        {description}
      </div>
      {!showMore && (
        <div
          onClick={() => {
            setShowMore(true);
          }}
          className="min-w-[76px] leading-4 cursor-pointer text-theme-orange"
        >
          Read more
        </div>
      )}
    </div>
  );
};

OathDescription.propTypes = {
  description: PropTypes.any,
};

OathCard.propTypes = {
  className: PropTypes.any,
  isLoading: PropTypes.any,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default OathCard;
