import PropTypes from "prop-types";
import React, { useState } from "react";
import ImageComponent from "../../stories/ImageComponent";
import { useNavigate } from "@reach/router";
import ProMemberDetailPopup from "./ProMemberDetailPopup";
import { BulletCard } from "../../stories/BulletList";

const CourseDetails = ({ courseDetails, isNewUser = false }) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const renderCards = () => {
    return (
      <React.Fragment>
        <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2 ">
          <BulletCard
            header={<b>Eligibility</b>}
            list={courseDetails?.eligibility}
          />
          <BulletCard
            header={<b>Program Benefits</b>}
            list={courseDetails?.perks}
          />
        </div>
        <div
          className="hidden mt-2 text-xs tracking-tight cursor-pointer w-fit lg:flex text-theme-red hover:underline"
          onClick={() => (isNewUser ? window.history.back() : navigate("/"))}
        >
          Back to {isNewUser ? "Get Started" : "Dashboard"}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-col w-full p-4">
        <div className="flex w-full">
          <ImageComponent
            src={courseDetails?.banner_image}
            width="100%"
            errorComponent={<div className="w-full !bg-white h-36"></div>}
            height="auto"
            className="object-contain !bg-white w-full"
          />
        </div>
        <div className="flex mt-4 text-lg font-semibold tracking-tight text-theme-black-color">
          {courseDetails.name ?? ""}
        </div>
        <div className="block mt-1 text-sm tracking-tight text-theme-black-300">
          {courseDetails?.description?.split("\n").map((item, idx) => (
            <span key={idx}>
              {item}
              <br></br>
            </span>
          )) ?? ""}
        </div>
        <div
          className="flex mt-3 text-sm font-semibold leading-none tracking-tight cursor-pointer lg:hidden text-theme-red w-fit"
          onClick={() => setShowPopup(true)}
        >
          VIEW MORE DETAILS
        </div>
        <span
          className="flex mt-3 text-xs tracking-tight cursor-pointer lg:hidden text-theme-red hover:underline"
          onClick={() => (isNewUser ? window.history.back() : navigate("/"))}
        >
          Back to {isNewUser ? "Get Started" : "Dashboard"}
        </span>
        <div className="flex-col hidden lg:flex">{renderCards()}</div>
      </div>
      {showPopup && (
        <ProMemberDetailPopup
          heading={courseDetails.name ?? "Event Details"}
          handleClose={() => setShowPopup(false)}
          open={showPopup}
          className="!p-0"
          modalBody={renderCards()}
        />
      )}
    </React.Fragment>
  );
};

CourseDetails.propTypes = {
  courseDetails: PropTypes.shape({
    attendees: PropTypes.any,
    banner_image: PropTypes.string,
    contact_email: PropTypes.any,
    contact_name: PropTypes.any,
    contact_phone_number: PropTypes.any,
    description: PropTypes.shape({
      split: PropTypes.func,
    }),
    detail_link: PropTypes.any,
    eligibility: PropTypes.any,
    name: PropTypes.string,
    perks: PropTypes.any,
  }),
  isNewUser: PropTypes.bool,
};

export default CourseDetails;
