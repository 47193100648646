import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "./Label";
import { PencilSimpleLine, UploadSimple } from "@phosphor-icons/react";
import { useField } from "formik";
import _ from "lodash";
import ShowFormError from "./ShowFormError";

const FileInput1 = ({
  className,
  id,
  name,
  label,
  placeholder,
  suggestion,
  onFileChange,
  showPointer,
  labelClassName,
  ...props
}) => {
  const [field] = useField({ name, ...props });
  const [hasFile, setHasFile] = useState(false);

  const openFileHandler = (e) => {
    const fileInput = document.getElementById(`fileInput-${name}`);
    fileInput.click();
  };
  return (
    <div className={classNames("flex flex-col items-start w-full")}>
      <Label
        labelFor={name}
        className={classNames(
          "text-xs font-semibold leading-none capitalize text-theme-black-300",
          labelClassName
        )}
      >
        {label}
      </Label>
      <div
        className={classNames("flex w-full", className)}
        onClick={openFileHandler}
      >
        <div className="flex flex-col w-full">
          <div
            className={classNames(
              " flex items-center flex-grow text-sm tracking-tight rounded-[4px] mt-1 pl-3 shadow-sm border border-[#e5e7eb] h-9",
              field.value === null
                ? "text-[#a3a3a3]"
                : "text-theme-black-color",
              showPointer ? "hover:cursor-pointer" : ""
            )}
          >
            {field.value === null || field.value === undefined
              ? placeholder ?? label ?? ""
              : field.value?.signed_url
              ? _.last(field.value?.signed_url?.split("?")[0].split("/"))
              : field.value.name}
          </div>
          <input
            type="file"
            name={name}
            onChange={(e) => {
              if (e.target.files[0]) {
                setHasFile(true);
                onFileChange(e.target.files[0]);
                return;
              }
              setHasFile(false);
            }}
            className="hidden"
            id={`fileInput-${name}`}
            {...props}
          />
          <div className="flex mt-1 text-xs leading-none tracking-tight text-theme-black-300">
            {suggestion}
          </div>
          <ShowFormError name={name} />
        </div>
        <div className="flex flex-col justify-center h-11 ml-[15px] hover:cursor-pointer	">
          {!hasFile ? (
            <UploadSimple size={24} className="text-theme-red" />
          ) : (
            <PencilSimpleLine className="text-theme-red" size={24} />
          )}
        </div>
      </div>
    </div>
  );
};

FileInput1.defaultProps = {
  className: "",
  height: "",
  suggestion: "",
};

FileInput1.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  showPointer: PropTypes.bool,
  suggestion: PropTypes.string,
};

export default FileInput1;
