import { useNavigate } from "@reach/router";
import PropTypes from "prop-types";
import React from "react";
import networkfp_logo from "../../assets/svgs/NFP_logo.svg";

const Header = ({ content = {}, isLoggedIn = false }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-row items-center justify-between px-5 py-4 lg:px-28">
        <div className="">
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-9 lg:h-12"
          />
        </div>
        <div className="flex items-center gap-4 lg:gap-8">
          <div
            onClick={() => {
              document
                .getElementById("contact-form-container")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className="text-sm font-semibold cursor-pointer lg:text-lg text-theme-black-color"
          >
            Contact
          </div>
          <div
            className="ml-2 text-sm font-bold cursor-pointer lg:text-lg text-theme-red"
            onClick={() => {
              navigate(isLoggedIn ? "/" : "/login");
            }}
          >
            {isLoggedIn ? "GO TO DASHBOARD" : "LOGIN"}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center px-5 text-white bg-center bg-no-repeat bg-cover bg-theme-orange-400 bg-landing_bg lg:px-28">
        <div className="mt-10 text-3xl font-bold tracking-tight text-center lg:mt-20 lg:text-6xl">
          Network FP Programs
        </div>
        <div className="max-w-5xl mt-8 text-xl font-light leading-7 text-center lg:font-normal">
          Below are the various programs offered by Network FP for Personal
          Finance Professionals. Kindly go through the eligibility criteria,
          benefits, fees and register online here directly.<br></br>For
          assistance or demo WhatsApp us on{" "}
          <a
            className="underline bold"
            href={`https://wa.me/${content.hero_mobile_number}`}
            target="_blank"
            rel="noreferrer"
          >
            {content.hero_mobile_number}
          </a>{" "}
          or Email on{" "}
          <a
            className="underline bold"
            href={`mailto:${content.hero_email}`}
            target="_blank"
            rel="noreferrer"
          >
            {content.hero_email}
          </a>
          .
        </div>
        <div className="w-full h-8 lg:h-64 lg:mt-14" />
      </div>
    </>
  );
};

Header.propTypes = {
  content: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};

export default Header;
