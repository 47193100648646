/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import Button from "../../stories/Button";
import TextInput from "../../stories/TextInput";
import { Form, Formik } from "formik";
import { CustomToast } from "../../stories/Toast";
import { PublicProfileService } from "../../services/PublicProfileService";
import { useProfile } from "../../context/userProvider";
import { X } from "@phosphor-icons/react";
import { Validations } from "../../constants/validations";

const ChangeMobileNumberModal = ({ open, onClose, className }) => {
  const [initialValues, setInitialValues] = useState({
    phone_number: "",
  });
  const { userDetails, updateUserData } = useProfile();

  useEffect(() => {
    setInitialValues({
      phone_number: userDetails.phone_number,
    });
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 pb-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="mb-3 text-base font-semibold leading-6 text-theme-black-color"
              >
                Update Number
              </Dialog.Title>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={() =>
                  Validations.popup.changePhoneNumberPopupValidationSchema
                }
                onSubmit={async (values) => {
                  try {
                    const res = await PublicProfileService.updatePhoneNumber(
                      values
                    );
                    await updateUserData();
                    CustomToast.success(res.message);
                    onClose();
                  } catch (e) {
                    CustomToast.error(e.error_messages[0]);
                  }
                }}
              >
                <Form>
                  <div className="flex flex-col mt-4">
                    <div className={classNames("flex w-full flex-col")}>
                      <TextInput
                        name="phone_number"
                        label="Mobile Number (10 digits)"
                        placeholder="10 Digit Mobile No."
                        id="phone_number"
                        type="number"
                        autoFocus={true}
                      />
                    </div>

                    <div className="flex flex-col items-end w-full mt-4">
                      <Button
                        buttonStyle="primary"
                        type="submit"
                        size="md"
                        height="36px"
                        width="100px"
                      >
                        SAVE
                      </Button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ChangeMobileNumberModal.defaultProps = {
  open: false,
  className: "",
};

ChangeMobileNumberModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default ChangeMobileNumberModal;
