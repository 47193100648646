/* eslint-disable react/prop-types */
import classNames from "classnames";
import { User } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import { useState } from "react";
import Button from "./Button";
import ImageComponent from "./ImageComponent";

const CommentInput = ({
  userImage,
  userName,
  prefilledContent,
  isReply = true,
  disableCommentButton = false,
  height = "h-32",
  placeholder,
  replyingTo,
  buttonText = "LEAVE COMMENT",
  textErrorMessage = "",
  ...props
}) => {
  const [value, setValue] = useState(prefilledContent ?? "");
  const [showEmptyTextError, setShowEmptyTextError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <div>
      <div className="flex space-x-2 ">
        {!isReply && (
          <ImageComponent
            src={userImage}
            alt="user"
            errorComponent={
              <div className="flex items-center justify-center w-8 h-8 min-w-[32px] bg-gray-200 rounded-full md:h-12 md:w-12 md:min-w-[48px]">
                <User
                  weight="bold"
                  className="w-6 h-6 text-gray-400 min-w-[24px]"
                />
              </div>
            }
            className="w-8 h-8 rounded-full md:h-12 md:w-12  min-w-[24px]"
          />
        )}
        {!isReply && <div className="flex flex-col space-y-1.5"> </div>}
        <div className="flex flex-col w-full">
          <textarea
            rows={5}
            name="comment"
            id="comment"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            maxLength={1024}
            className="block w-full border-gray-300 rounded-md shadow-sm resize-none focus:ring-theme-red focus:border-theme-red sm:text-sm"
            placeholder={placeholder ?? null}
          />

          <div className="flex justify-between mt-1 text-xs leading-none tracking-tight text-theme-gray-600">
            <div className="flex flex-col mb-2 text-sm leading-none text-theme-black-300">
              {showEmptyTextError && value === "" && (
                <div className="flex w-full mt-1 text-sm text-left text-red-700">
                  {textErrorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "flex mt-2",
          isReply ? "justify-start" : "justify-end"
        )}
      >
        <Button
          buttonStyle={disableCommentButton ? "disabled" : "primary"}
          className="px-4 min-w-[135px] text-sm rounded-md sm:py-2 md:px-11 "
          height="40px"
          loading={isSubmitting}
          onClick={async () => {
            try {
              setIsSubmitting(true);
              if (value === "") {
                setShowEmptyTextError(true);
                return;
              } else {
                setShowEmptyTextError(false);
              }
              await props.onSubmit(value, value === "");
              setValue("");
            } catch (e) {
              console.log(e);
            } finally {
              setIsSubmitting(false);
            }
          }}
        >
          {buttonText ?? "Add Comment"}
        </Button>
      </div>
    </div>
  );
};

CommentInput.defaultProps = {};

CommentInput.propTypes = {
  buttonText: PropTypes.string,
  disableCommentButton: PropTypes.bool,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  prefilledContent: PropTypes.string,
  replyingTo: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  isReply: PropTypes.bool,
  textErrorMessage: PropTypes.string,
  userImage: PropTypes.string.isRequired,
  userName: PropTypes.string,
};
export default CommentInput;
