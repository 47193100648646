/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useNavigate } from "@reach/router";
import ImageComponent from "../../stories/ImageComponent";
import AppSkeleton from "../AppSkeleton";
import LoadingModal from "../Modals/LoadingModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import { confettiAnimation } from "../../util/confettiAnimation";
import { useProfile } from "../../context/userProvider";
import EventLogoImg from "../../assets/svgs/thank-you-img.svg";

const EventRegistrationThankyouScreen = (props) => {
  const navigate = useNavigate();
  const confettiCanvas = useRef();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const [propData, setPropData] = useState({});
  const { userDetails } = useProfile();

  useEffect(() => {
    if (_.isEmpty(props?.location?.state)) {
      navigate(-1);
      return;
    } else {
      setPropData(
        props?.location?.state ?? {
          eventDetails: {},
          formData: {},
        }
      );
    }
  }, []);

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      {!_.isEmpty(props?.location?.state) && (
        <LoadingModal open={_.isEmpty(props?.location?.state)} />
      )}
      <div className="flex flex-col items-center py-6 p x-4 lg:px-16 h-fit">
        <div className="relative flex flex-col items-center w-full max-w-5xl px-6 pb-10 rounded-xl bg-white lg:py-10">
          <div className="flex flex-col items-center w-full max-w-3xl bg-white">
            <div className="flex mt-2 tracking-tight text-center text-theme-orange text-2xl font-bold">
              <p className="text-black font-medium">
                {userDetails?.first_name !== undefined ? "Hey" : ""} &nbsp;
              </p>
              {userDetails?.first_name !== undefined
                ? ` ${userDetails?.first_name + userDetails?.last_name}`
                : ""}{" "}
            </div>
            <div className="flex justify-center w-full max-w-xl text-2xl font-medium leading-none tracking-tight text-center text-theme-black-color">
              Congratulations , You have successfully registered to{" "}
              {propData?.eventDetails?.title ?? "Event"}
            </div>

            <ImageComponent
              src={EventLogoImg}
              className="mt-8 mb-5 h-40 !bg-white"
            />
            <div className="flex flex-col justify-start w-full">
              <div className=" max-w-xl text-xl font-medium leading-none tracking-tight text-theme-black-color">
                {propData?.eventDetails?.title ?? "Event"}
              </div>
              <div className="flex mt-1 tracking-tight text-theme-black-300">
                You are successfully registered for{" "}
                {propData?.eventDetails?.title ?? "event"}. Below are details
                and important next steps and links.
              </div>
            </div>
          </div>
          <div
            className={`grid grid-cols-1 ${
              !_.isEmpty(props?.location?.state?.eventDetails?.time) &&
              !_.isEmpty(props?.location?.state?.eventDetails?.place)
                ? "md:grid-cols-2 max-w-3xl gap-y-6 gap-x-6"
                : "md:max-w-md w-full gap-y-6"
            } mt-5 w-full`}
          >
            {!_.isEmpty(props?.location?.state?.eventDetails?.time) && (
              <div className="bg-white border border-gray-300 rounded-lg p-6 w-full">
                <h3 className="text-xl font-semibold mb-4">When?</h3>
                <ul className="space-y-2 text-gray-700">
                  {props?.location?.state?.eventDetails?.time.map(
                    (item, index) => (
                      <li
                        key={`time-${index}`}
                        className="flex items-start justify-start gap-2"
                      >
                        <CircularDisc /> {item}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            {!_.isEmpty(props?.location?.state?.eventDetails?.place) && (
              <div className="bg-white border border-gray-300 rounded-lg p-6 w-full">
                <h3 className="text-xl font-semibold mb-4">Where?</h3>
                <ul className="space-y-2 text-gray-700">
                  {props?.location?.state?.eventDetails?.place.map(
                    (item, index) => (
                      <li
                        key={`where-${index}`}
                        className="flex items-start justify-start gap-2"
                      >
                        <CircularDisc /> {item}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            {!_.isEmpty(props?.location?.state?.eventDetails?.next_step) && (
              <div className="bg-white border border-gray-300 rounded-lg p-6 md:col-span-2 md:max-w-md md:mx-auto w-full">
                <h3 className="text-xl font-semibold mb-4">What Next?</h3>
                {props?.location?.state?.eventDetails?.next_step.map(
                  (item, index) => (
                    <p
                      className="text-gray-700 flex items-start justify-start gap-2 w-full"
                      key={`what-next-${index}`}
                    >
                      <CircularDisc /> {item}
                    </p>
                  )
                )}
              </div>
            )}
            {!props?.location?.state?.isNewUser && (
              <div
                className="flex items-center justify-center bg-theme-red border border-gray-300 rounded-lg p-3 text-white w-full md:col-span-2 md:max-w-md md:mx-auto cursor-pointer"
                onClick={() => navigate("/")}
              >
                CONTINUE TO DASHBOARD
              </div>
            )}
          </div>
          {props?.location?.state?.isNewUser && (
            <div className="w-full max-w-3xl mt-5">
              We have sent reset password link on your email id
              {props?.location?.state?.formData?.email && (
                <span className="font-bold">
                  {" "}
                  {props?.location?.state?.formData?.email}
                </span>
              )}
              {". "}
              Please check your mail for further step.
            </div>
          )}
        </div>
      </div>
    </AppSkeleton>
  );
};

const CircularDisc = () => {
  return <div className="p-1.5 mt-1.5 rounded-full bg-theme-orange"></div>;
};

EventRegistrationThankyouScreen.propTypes = {
  eventDetails: PropTypes.shape({
    description: PropTypes.string,
    short_title: PropTypes.string,
    title: PropTypes.string,
    whatNextContent: PropTypes.array,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
};

export default EventRegistrationThankyouScreen;
