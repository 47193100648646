import { useNavigate } from "@reach/router";
import moment from "moment";
import PropTypes from "prop-types";
import AccordionComponent from "./AccordionComponent";
import { Check } from "@phosphor-icons/react";
import Link from "../stories/Link";

const AceQuizAccordian = ({ item, index, isDesktop }) => {
  return (
    <div className="p-2 bg-white md:p-4">
      <div className="flex p-4 border">
        <AccordionComponent
          Heading={(headingProps) => (
            <AccordianHeading data={item} open={headingProps.open} />
          )}
          Children={(childrenProps) => <AccordianChildren data={item} />}
          CloseButton={
            <button className="w-full px-6 pt-2 text-sm font-semibold text-center border-t text-theme-black-color">
              HIDE DETAILS
            </button>
          }
        />
      </div>
    </div>
  );
};

AceQuizAccordian.propTypes = {
  index: PropTypes.number,
  isDesktop: PropTypes.bool,
  item: PropTypes.object,
};

const AccordianHeading = ({ data, open = false }) => {
  return (
    <div className="flex flex-col">
      {open && (
        <div className="text-sm font-medium sm:text-xs text-neutral-600">
          SESSION
        </div>
      )}
      <div className="flex items-center text-base leading-none tracking-tight text-theme-black-color ">
        {data.title}
      </div>
    </div>
  );
};

AccordianHeading.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
  }),
  open: PropTypes.any,
};
const AccordianChildren = ({ data }) => {
  const navigate = useNavigate();
  const quizLink = `/programs/${data?.quiz_details?.course_id}/learn/${data?.quiz_details?.course_session_content_id}`;
  return (
    <div className="py-4 md:pb-0">
      <div className="grid grid-cols-2 gap-4 pb-4 xl:grid-cols-3">
        <DataComponent
          title="DATE"
          data={moment(data.display_date, "DD-MM-YYYY").format("DD-MMM-YYYY")}
        />
        <DataComponent
          title="QPFP Applicable"
          data={
            data.qpfp_applicable ? (
              <Check
                weight="fill"
                className="rounded-full mr-auto w-5 h-5 text-green-600 bg-green-400/30 px-0.5"
              />
            ) : (
              "-"
            )
          }
        />
        <DataComponent
          title="CFP Applicable"
          data={
            data.cfp_applicable ? (
              <Check
                weight="fill"
                className="rounded-full mr-auto w-5 h-5 text-green-600 bg-green-400/30 px-0.5"
              />
            ) : (
              "-"
            )
          }
        />
        <DataComponent title="SCORE" data={data.obtained_score} />
        <DataComponent title="POINTS" data={data.ace_points} />
        <DataComponent
          title="POINTS"
          data={
            <Link
              textColor="text-blue-600"
              activeTextColor="text-blue-600"
              showUnderLine={false}
              underLineOnlyOnHover={false}
              fontSize="sm"
              href={quizLink}
              className="font-normal leading-5 tracking-tight"
            >
              Click here
            </Link>
          }
        />
      </div>
      <button
        onClick={() => {
          navigate(
            `/programs/${data?.quiz_details?.course_id}/learn/${data?.quiz_details?.course_session_content_id}`
          );
        }}
        className="w-full px-6 py-2 text-sm font-semibold text-center text-white bg-theme-red"
      >
        View Quiz Result
      </button>
    </div>
  );
};

AccordianChildren.propTypes = {
  data: PropTypes.shape({
    ace_points: PropTypes.string,
    cfp_applicable: PropTypes.any,
    display_date: PropTypes.string,
    obtained_score: PropTypes.any,
    qpfp_applicable: PropTypes.any,
    quiz_details: PropTypes.shape({
      course_id: PropTypes.any,
      course_session_content_id: PropTypes.any,
    }),
  }),
};

const DataComponent = ({ title, data }) => {
  return (
    <div className="items-center">
      <div className="text-sm font-medium sm:text-xs text-neutral-600">
        {title}
      </div>
      <div className="text-base font-medium sm:text-sm text-theme-black-color">
        {data}
      </div>
    </div>
  );
};

DataComponent.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
};
export default AceQuizAccordian;
