/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import API from "../api/manager";
import config from "../api/config";

const usePosts = (postType, filter, page, searchQuery) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [lastFilter, setLastFilter] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (
      lastPage !== page ||
      lastFilter !== filter ||
      lastSearchQuery !== searchQuery
    ) {
      setLastPage(page);
      if (lastFilter !== filter) {
        setLastFilter(filter);
        setPosts([]);
      }
      if (lastSearchQuery !== searchQuery) {
        setLastSearchQuery(searchQuery);
        setPosts([]);
      }
      API(
        {
          method: "GET",
          url: config.urls.clientConnect.posts(
            postType,
            filter,
            page,
            searchQuery
          ),
        },
        true
      )
        .then((res) => {
          setPosts((prevPosts) => {
            return [...prevPosts, ...res.posts];
          });
          setHasMore(!res.pagination.last_page);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [filter, page, searchQuery]);

  return { loading, error, posts, hasMore };
};
export default usePosts;
