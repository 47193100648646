import classNames from "classnames";
import { useField } from "formik";
import React, { useState } from "react";
import CropImageModal from "../components/Modals/CropImageModal";
import ImageComponent from "./ImageComponent";
import { CustomToast } from "./Toast";
import PropTypes from "prop-types";

const ProfilePictureInput = ({
  className,
  ErrorComponent,
  name,
  key = "",
  onFileChange,
  ...props
}) => {
  const [field] = useField({ name, ...props });
  const [showCropModal, setShowCropModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState();
  const openFileHandler = (e) => {
    const fileInput = document.getElementById(`fileInput-${name}`);
    fileInput.click();
  };

  return (
    <div
      className={classNames(
        "flex items-center lg:min-w-186 col-span-full",
        className
      )}
    >
      <div className="flex items-center justify-center w-20 h-20 rounded-full bg-theme-gray-300">
        {field.value !== undefined && field.value !== null ? (
          <ImageComponent
            src={
              typeof field.value !== "string"
                ? window.URL.createObjectURL(field.value)
                : field.value
            }
            errorComponent={<ErrorComponent />}
            className="object-cover w-full h-full rounded-full"
          />
        ) : (
          <ErrorComponent />
        )}
      </div>
      <div
        className="ml-6 text-sm font-semibold cursor-pointer text-theme-red"
        onClick={() => {
          openFileHandler();
        }}
      >
        EDIT
        <input
          accept="image/*"
          type="file"
          id={`fileInput-${name}`}
          name={`fileInput-${name}`}
          onChange={(e) => {
            if (e.target.files[0]) {
              const file = e.target.files[0];
              if (file.type.includes("image")) {
                setFileUploaded(e.target.files[0]);
                setShowCropModal(true);
              } else {
                CustomToast.error("Please upload a valid image");
              }
            }
          }}
          className="hidden"
        />
      </div>
      {showCropModal && (
        <CropImageModal
          open={showCropModal}
          file={fileUploaded}
          onSubmit={async (base64URL) => {
            if (base64URL === -1) {
              setFileUploaded(undefined);
              const fileInput = document.getElementById(`fileInput-${name}`);
              fileInput.value = "";
              onFileChange(-1);
              return;
            }
            const base64Response = await fetch(base64URL);
            const blob = await base64Response.blob();
            blob.name = fileUploaded.name ?? "profile_picture.jpeg";
            onFileChange(blob);
          }}
          onClose={() => {
            setShowCropModal(false);
          }}
        />
      )}
    </div>
  );
};

ProfilePictureInput.propTypes = {
  className: PropTypes.string,
  ErrorComponent: PropTypes.element,
  name: PropTypes.string,
  key: PropTypes.string,
  onFileChange: PropTypes.func,
};

export default ProfilePictureInput;
