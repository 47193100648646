import PropTypes from "prop-types";
import React, { useRef } from "react";
import heart from "../../assets/svgs/heart.svg";
import trustpilot from "../../assets/images/trustpilot.png";
import { CaretCircleLeft, CaretCircleRight, User } from "@phosphor-icons/react";
import ImageComponent from "../../stories/ImageComponent";

const Testimonials = ({ testimonials = [] }) => {
  const scrollableListRef = useRef();

  return (
    <div className="flex flex-col items-start px-5 pt-14 lg:px-28">
      <div className="block mb-5 text-4xl font-bold text-center lg:text-6xl text-stone-900">
        <span>Why People Love</span>
        <img
          src={heart}
          alt="heart"
          className="inline-block mx-4 lg:w-11 lg:h-11 w-7 h-7"
        />
        <span>Network FP</span>
      </div>
      <div className="text-lg text-center lg:text-left lg:font-medium text-neutral-800">
        NFP Team strives to deliver the best and is committed to the growth &
        success of our members, delegates and students. Here are some kind words
        shared by them.
      </div>
      <a
        href="https://www.trustpilot.com/review/networkfp.com"
        target="_blank"
        rel="noreferrer"
        className="flex items-center justify-center mx-auto my-8 text-base w-fit hover:underline text-theme-black-color"
      >
        See our 828 reviews on
        <img src={trustpilot} alt="heart" className="w-auto h-5 mx-1" />
      </a>

      <div
        ref={scrollableListRef}
        className="block w-full max-w-full pb-2 overflow-auto whitespace-nowrap mb-7"
      >
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="w-4/5 md:w-2/3 pr-6 lg:!w-full lg:max-w-lg inline-block"
          >
            <div className="flex bg-blue-50 flex-col gap-3.5 items-start p-6">
              <p className="text-sm tracking-wide break-words whitespace-pre-line text-theme-black-300">
                &quot;{testimonial.review}&quot;
              </p>
              <div className="flex items-center gap-2">
                <div className="p-0.5 border-2 rounded-full border-theme-orange-400/30 w-14 h-14">
                  <ImageComponent
                    src={testimonial.profile_picture}
                    errorComponent={
                      <div className="flex items-center justify-center w-full h-full  min-w-[32px] md:min-w-[48px] bg-gray-400 rounded-full md:h-12 md:w-12">
                        <User weight="bold" className="w-6 h-6 text-gray-100" />
                      </div>
                    }
                    alt="user"
                    className="object-cover w-full h-full min-w-[32px] md:min-w-[48px] rounded-full md:h-12 md:w-12"
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <div className="text-base font-semibold text-theme-black-color">
                    {testimonial.name}
                  </div>
                  <div className="text-base text-theme-black-color">
                    {testimonial.city}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full mb-10">
        <CaretCircleLeft
          size={40}
          weight="fill"
          className="cursor-pointer text-theme-gray-400"
          onClick={() => {
            scrollableListRef.current.scrollBy({
              left: -200,
              behavior: "smooth",
            });
          }}
        />
        <CaretCircleRight
          size={40}
          weight="fill"
          className="cursor-pointer text-theme-orange-400"
          onClick={() => {
            scrollableListRef.current.scrollBy({
              left: 200,
              behavior: "smooth",
            });
          }}
        />
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  testimonials: PropTypes.array,
};

export default Testimonials;
