import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import { AsteriskSimple, X } from "@phosphor-icons/react";
import _ from "lodash";
import classNames from "classnames";
import Label from "./Label";
import Tooltip from "./Tooltip";
import ShowFormError from "./ShowFormError";

const ChipInputField = ({
  preSelected,
  options = [],
  displayKey,
  idKey,
  onChange,
  labelClassName,
  label,
  isRequired,
  infoContent,
  name = "",
  className,
}) => {
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className={classNames("flex flex-col items-start w-full ", className)}>
      <Label
        className={classNames(
          "flex items-center text-left text-xs leading-none font-semibold text-theme-black-300",
          labelClassName
        )}
      >
        {label ?? "Text Input"}
        {isRequired && (
          <AsteriskSimple
            size={8}
            weight="bold"
            className="mb-2 text-theme-red"
          />
        )}
        {infoContent && <Tooltip infoContent={infoContent} />}
      </Label>
      <Combobox value={preSelected} onChange={onChange} multiple>
        <div
          className="flex flex-wrap gap-2.5 p-3 border w-full rounded wrap bg-theme-gray-200"
          onClick={() => inputRef.current.focus()}
        >
          {preSelected.length > 0 &&
            preSelected?.map((person, index) => (
              <div
                key={person[idKey]}
                className="border flex gap-2.5 text-theme-orange border-theme-orange h-fit rounded-full bg-theme-orange-400/10 px-3 py-1.5 text-xs"
              >
                {person[displayKey]}
                <X
                  className="cursor-pointer w-4 h-4 min-w-[16px] text-theme-orange"
                  size={16}
                  onClick={() => {
                    const tempPeople = [...preSelected];
                    tempPeople.splice(index, 1);
                    onChange([...tempPeople]);
                  }}
                />
              </div>
            ))}
          <Combobox.Input
            ref={inputRef}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search categories and select"
            className="flex flex-grow p-0 border-0 bg-theme-gray-200 min-w-186 focus:border-0 focus:ring-0"
          />
        </div>
        {!_.isEmpty(searchQuery) &&
          options.filter((p) =>
            p[displayKey].toLowerCase().includes(searchQuery.toLowerCase())
          ).length === 0 && (
            <span className="mt-1 text-sm text-theme-black-300">
              No categories matched
            </span>
          )}
        <Combobox.Options
          static
          className="flex mt-4 max-w-full overflow-auto gap-2.5 pb-2"
        >
          {(_.isEmpty(searchQuery)
            ? options
            : options.filter((p) =>
                p[displayKey].toLowerCase().includes(searchQuery.toLowerCase())
              )
          )?.map((e) => {
            if (preSelected.filter((sp) => sp[idKey] === e[idKey]).length !== 0)
              return null;
            return (
              <Combobox.Option
                key={e[idKey]}
                value={e}
                className="border h-fit whitespace-nowrap cursor-pointer rounded-full text-theme-black-color border-neutral-300 px-3 py-1.5 text-xs"
              >
                {e[displayKey]}
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </Combobox>
      <ShowFormError name={name} />
    </div>
  );
};

ChipInputField.propTypes = {
  className: PropTypes.string,
  displayKey: PropTypes.string,
  idKey: PropTypes.string,
  infoContent: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  preSelected: PropTypes.array,
};

export default ChipInputField;
