/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { CircleNotch, User, X } from "@phosphor-icons/react";
import Button from "../../../stories/Button";
import SearchBar from "../../../stories/SearchBar";
import promember_badge from "../../../assets/images/pro_badge.png";
import CheckBox from "../../../stories/CheckBox";
import { Form, Formik } from "formik";
import { PublicProfileService } from "../../../services/PublicProfileService";
import ImageComponent from "../../../stories/ImageComponent";
import _ from "lodash";
import { useProfile } from "../../../context/userProvider";

const useUsers = (page, searchQuery) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [lastSearchQuery, setLastSearchQuery] = useState("");
  const timeoutRef = useRef();

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (lastPage !== page || lastSearchQuery !== searchQuery) {
      setLastPage(page);
      if (lastSearchQuery !== searchQuery) {
        setLastSearchQuery(searchQuery);
        setUsers([]);
      }
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => debounceApiCall(), 600);
    }
  }, [page, searchQuery]);

  const debounceApiCall = () => {
    PublicProfileService.searchFirmUsers({
      per_page: 10,
      page: page,
      search: searchQuery,
    })
      .then((res) => {
        setUsers((prevUsers) => {
          return [...prevUsers, ...res.users];
        });
        setHasMore(!res.pagination.last_page);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
      });
  };

  return { loading, error, users, hasMore };
};

const SearchUserModal = ({
  open,
  onClose,
  onAdd,
  showPreselectedUsers = true,
  preSelectedUsers,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPageNumber] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState({});
  const { loading, users, hasMore } = useUsers(page, searchQuery);
  const [initialValues, setInitialValues] = useState({});
  const { userDetails } = useProfile();

  const observer = React.useRef();
  const lastUserElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (!_.isEmpty(preSelectedUsers) && showPreselectedUsers) {
      const tempObj = {};
      const tempInitialObj = {};
      preSelectedUsers.forEach((u) => {
        tempObj[u.id] = u;
        tempInitialObj[u.id] = true;
      });
      setInitialValues(tempInitialObj);
      setSelectedUsers(tempObj);
    }
  }, [preSelectedUsers]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-6 pr-6">
                <button
                  type="button"
                  className="bg-white text-theme-black-color focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="flex items-center mb-4 text-xl font-medium leading-6 tracking-tight text-theme-black-color"
              >
                Add Members{" "}
                <span className="ml-1 text-base text-theme-black-300">
                  ({_.values(selectedUsers).length} selected)
                </span>
              </Dialog.Title>

              <Formik
                initialValues={{ users: initialValues }}
                enableReinitialize
                onSubmit={(values) => {
                  if ((_.values(selectedUsers) ?? []).length === 0) {
                    onClose();
                    return;
                  }
                  onAdd && onAdd(_.values(selectedUsers));
                  onClose();
                }}
              >
                {({ values, ...formProps }) => {
                  return (
                    <Form
                      className="flex flex-col gap-4"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    >
                      <SearchBar
                        className="w-full overflow-hidden !h-10"
                        placeholder="Search by User Name or Firm Name"
                        searchTerm={searchQuery}
                        autoFocus={true}
                        onSearch={(val) => {
                          setSearchQuery(val);
                          setPageNumber(1);
                        }}
                      />
                      <div className="flex flex-col gap-4 overflow-auto max-h-72">
                        {_.values(selectedUsers)?.map((user, index) => {
                          if (
                            !`${user.first_name} ${user.last_name} ${
                              user.email
                            } ${user.city_name} ${user.firm_name ?? ""}`
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                            return null;
                          return (
                            <div
                              key={`selected-${user.id}`}
                              className="flex items-center gap-x-3.5"
                            >
                              <div className="">
                                <CheckBox
                                  name={`users.${user.id}`}
                                  className="!mr-0"
                                  onChange={(e) => {
                                    const tempSelectedUsers = {
                                      ...selectedUsers,
                                    };
                                    delete tempSelectedUsers[user.id];
                                    setSelectedUsers(tempSelectedUsers);
                                    formProps.setFieldValue(
                                      `users.${user.id}`,
                                      false
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex gap-x-1.5 items-center">
                                <ImageComponent
                                  alt="profile-icon"
                                  src={user?.profile_picture?.signed_url ?? ""}
                                  errorComponent={
                                    <div className="w-12 h-12 flex min-w-[48px] justify-center items-center bg-gray-200 rounded-full">
                                      <User
                                        size={24}
                                        weight="bold"
                                        className="text-gray-400"
                                      />
                                    </div>
                                  }
                                  className="object-cover w-12 h-12 rounded-full lg:h-12 lg:w-12 "
                                />
                                <div className="flex flex-col items-start">
                                  <div className="flex gap-1 text-xs font-medium leading-4 text-theme-black-color">
                                    {user.first_name} {user.last_name}
                                    {user.is_pro_member && (
                                      <img
                                        alt="pro"
                                        src={promember_badge}
                                        className="object-contain w-4 h-4 min-w-[16px]"
                                      />
                                    )}
                                  </div>
                                  {(user.city_name || user.firm_name) && (
                                    <div className="leading-4 tracking-tight text-xxs text-theme-black-300">
                                      {user.firm_name}
                                      {user.city_name &&
                                        user.firm_name &&
                                        " - "}
                                      {user.city_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {users.map((user, index) => {
                          if (
                            _.keys(selectedUsers).includes(
                              user.id.toString()
                            ) ||
                            userDetails.id?.toString() ===
                              user.id?.toString() ||
                            preSelectedUsers.findIndex(
                              (u) => u.id.toString() === user.id.toString()
                            ) !== -1
                          )
                            return <></>;
                          return (
                            <div
                              key={`unselected-${user.id}`}
                              ref={
                                users.length === index + 1
                                  ? lastUserElementRef
                                  : undefined
                              }
                              className="flex items-center gap-x-3.5"
                            >
                              <div className="">
                                <CheckBox
                                  name={`users.${user.id}`}
                                  className="!mr-0"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedUsers((prev) => ({
                                        ...prev,
                                        [user.id]: user,
                                      }));
                                    }
                                    formProps.setFieldValue(
                                      `users.${user.id}`,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex gap-x-1.5 items-center">
                                <ImageComponent
                                  alt="profile-icon"
                                  src={user?.profile_picture?.signed_url ?? ""}
                                  errorComponent={
                                    <div className="w-10 h-10 flex min-w-[40px] justify-center items-center bg-gray-200 rounded-full">
                                      <User
                                        size={20}
                                        weight="bold"
                                        className="text-gray-400"
                                      />
                                    </div>
                                  }
                                  className="object-cover w-8 h-8 rounded-full lg:h-10 lg:w-10 "
                                />
                                <div className="flex flex-col items-start">
                                  <div className="flex gap-1 text-xs font-medium leading-4 text-theme-black-color">
                                    {user.first_name} {user.last_name}
                                    {user.is_pro_member && (
                                      <img
                                        alt="pro"
                                        src={promember_badge}
                                        className="object-contain w-4 h-4 min-w-[16px]"
                                      />
                                    )}
                                  </div>
                                  {(user.city_name || user.firm_name) && (
                                    <div className="leading-4 tracking-tight text-xxs text-theme-black-300">
                                      {user.firm_name}
                                      {user.city_name &&
                                        user.firm_name &&
                                        " - "}
                                      {user.city_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {!loading &&
                          users.filter(
                            (user) =>
                              !(
                                _.keys(selectedUsers).includes(
                                  user.id.toString()
                                ) ||
                                userDetails.id?.toString() ===
                                  user.id?.toString() ||
                                preSelectedUsers.findIndex(
                                  (u) => u.id.toString() === user.id.toString()
                                ) !== -1
                              )
                          ).length === 0 &&
                          _.values(selectedUsers)?.filter((user) =>
                            `${user.first_name} ${user.last_name} ${
                              user.email
                            } ${user.city_name} ${user.firm_name ?? ""}`
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )?.length === 0 && (
                            <div className="text-base text-center text-theme-black-300">
                              No user found to invite
                            </div>
                          )}
                        {loading && (
                          <div className="mt-5">
                            <CircleNotch
                              className="mx-auto text-theme-black-color animate-spin"
                              size={44}
                              weight="fill"
                            />
                          </div>
                        )}
                      </div>
                      <Button
                        buttonStyle="primary"
                        type="submit"
                        size="md"
                        height="40px"
                        width="100%"
                        disabled={
                          !loading &&
                          users.filter(
                            (user) =>
                              !(
                                _.keys(selectedUsers).includes(
                                  user.id.toString()
                                ) ||
                                userDetails.id?.toString() ===
                                  user.id?.toString() ||
                                preSelectedUsers.findIndex(
                                  (u) => u.id.toString() === user.id.toString()
                                ) !== -1
                              )
                          ).length === 0 &&
                          _.values(selectedUsers)?.filter((user) =>
                            `${user.first_name} ${user.last_name} ${
                              user.email
                            } ${user.city_name} ${user.firm_name ?? ""}`
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )?.length === 0
                        }
                      >
                        ADD
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SearchUserModal.defaultProps = {
  open: false,
  className: "",
};

SearchUserModal.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  preSelectedUsers: PropTypes.array,
  showPreselectedUsers: PropTypes.bool,
};

export default SearchUserModal;
