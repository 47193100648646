import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "../index.css";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import Text from "./Text";
import { User as Usericon } from "@phosphor-icons/react";
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import { useProfile } from "../context/userProvider";
import promember_badge from "../assets/images/pro_badge.png";
import ImageComponent from "./ImageComponent";
import { User } from "../store";
import { useNavigate } from "@reach/router";
import { useLoginModal } from "../context/loginModalProvider";

const HeaderProfileIcon = ({ tabs, openLoginModal }) => {
  const isLoggedIn = !!User.getToken();
  const { userDetails } = useProfile();
  const navigate = useNavigate();

  const { setOpenLoginPopup } = useLoginModal();

  if (!isLoggedIn)
    return (
      <div
        className="ml-2 text-lg font-bold cursor-pointer text-theme-red"
        onClick={() => {
          if (openLoginModal) {
            setOpenLoginPopup(true);
          } else {
            navigate("/login");
          }
        }}
      >
        {isLoggedIn ? "GO TO DASHBOARD" : "LOGIN"}
      </div>
    );

  return (
    <div>
      <div className="flex items-center justify-end flex-grow lg:justify-between">
        <div className="flex items-center self-center flex-shrink-0">
          <span className="pr-2.5 hidden lg:block">
            {userDetails.first_name && (
              <Text
                content={`Hello ${userDetails.first_name}!`}
                type="body"
                size="text-sm"
                colorClass="text-theme-black-color"
              />
            )}
          </span>
          {userDetails.profile_picture?.signed_url ? (
            <div className="relative">
              <ImageComponent
                alt="profile-icon"
                src={userDetails.profile_picture?.signed_url ?? ""}
                errorComponent={
                  <Usericon size={24} weight="bold" className="text-gray-400" />
                }
                className="object-cover w-10 h-10 rounded-full lg:h-10 lg:w-10 "
              />
              {userDetails.is_pro_member &&
                ["approved", "expires_soon"].includes(
                  userDetails.pm_signup_status
                ) && (
                  <img
                    alt="pro"
                    src={promember_badge}
                    className="absolute -top-0.5 -right-0.5 object-contain w-4 h-4"
                  />
                )}
            </div>
          ) : (
            <div className="relative">
              <div className="flex items-center justify-center bg-gray-200 rounded-full h-7 w-7 lg:h-10 lg:w-10">
                <Usericon size={24} weight="bold" className="text-gray-400" />
              </div>
              {userDetails.is_pro_member &&
                ["approved", "expires_soon"].includes(
                  userDetails.pm_signup_status
                ) && (
                  <img
                    alt="pro"
                    src={promember_badge}
                    className="absolute object-contain w-4 h-4 -top-0.5 -right-0.5"
                  />
                )}
            </div>
          )}
          <Menu as="div" className="relative z-30 inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="flex items-center p-2 -m-2 text-gray-400 rounded-full hover:text-gray-600 focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <span className="py-[18px]">
                      {open ? (
                        <CaretUp
                          color="#141414"
                          className="w-6 h-6 ml-2"
                          weight="fill"
                        />
                      ) : (
                        <CaretDown
                          color="#141414"
                          className="w-6 h-6 ml-2"
                          weight="fill"
                        />
                      )}
                    </span>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 z-[100] w-56 top-[90%] origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      {tabs.map((tab, index) => (
                        <Menu.Item key={tab.name}>
                          {({ active }) => (
                            <div
                              onClick={tab.onClick}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex justify-between px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              {tab.name}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

HeaderProfileIcon.defaultProps = {
  openLoginModal: false,
};

HeaderProfileIcon.propTypes = {
  tabs: PropTypes.array,
  openLoginModal: PropTypes.bool,
};

export default HeaderProfileIcon;
