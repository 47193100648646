/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../stories/Button";
import { useProfile } from "../../../context/userProvider";
import HeroSectionUserCard from "../../../stories/QPFPDashboard/HeroSectionUserCard";
import { useNavigate } from "@reach/router";
import { UserService } from "../../../services/UserService";
import { confettiAnimation } from "../../../util/confettiAnimation";

const SuccessScreen = () => {
  const confettiCanvas = useRef();
  const { userDetails, updateUserData } = useProfile();
  const navigate = useNavigate();
  const [status, setStatus] = useState({});

  const fetchData = async () => {
    const response = await UserService.fetchQPFPCertificationStatus();
    setStatus(response ?? {});
    updateUserData();
  };

  useEffect(() => {
    fetchData();

    confettiAnimation(confettiCanvas.current);
  }, []);

  return (
    <div className="relative w-full max-w-3xl items-center gap-6 lg:gap-10 text-center flex flex-col bg-white shadow-card-shadow -mt-20 mx-auto rounded p-5 lg:p-14 z-10">
      <HeroSectionUserCard
        validFrom={status.valid_from}
        validTill={status.valid_upto}
      />
      <div className="flex flex-col items-center lg:mt-2.5 gap-5">
        <div className="text-xl lg:text-2xl leading-7">
          Congratulations,{" "}
          <span className="font-bold">
            {userDetails?.first_name} {userDetails?.last_name}!
          </span>
        </div>
        <div className="font-medium text-sm tracking-wide lg:text-xl text-theme-black-300">
          🎉 You are now a Qualified Personal Finance Professional
          <sup>&reg;</sup> ! 🎉
        </div>
        <div className="text-sm text-theme-black-300">
          Your dedication and hard work have paid off! With your new
          qualification, you&apos;re now equipped with the advanced skills and
          knowledge to excel in financial planning.
          <br />
          Your updated certificate will be generated in 10-15mins. If not,
          please contact NetworkFP team for support.
        </div>
        <Button
          onClick={() => navigate("/public-profile", { replace: true })}
          buttonStyle="primary"
          className="text-sm font-medium !h-10 uppercase"
        >
          Update your NFP Profile with QPFP Credentials
        </Button>
        <Button
          onClick={() => {
            navigate("/qpfp-certificant/dashboard", { replace: true });
          }}
          buttonStyle="transparent-textonly-primary"
          className="text-sm font-medium !h-10 uppercase"
        >
          Go to QPFP Benefits Dashboard
        </Button>
      </div>
      <canvas ref={confettiCanvas} className="absolute z-50" />
    </div>
  );
};

export default SuccessScreen;
