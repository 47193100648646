import PropTypes from "prop-types";
import { Info } from "@phosphor-icons/react";
import React from "react";
import { useNavigate } from "@reach/router";

const MembershipRSVPRenewalBar = ({ entity_type, status }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex flex-col justify-between px-4 py-3 rounded md:items-center bg-amber-50 md:flex-row">
        <div className="flex items-start text-base font-medium leading-5 tracking-tight text-amber-800">
          <Info
            size={20}
            weight="fill"
            className="mr-3 min-w-[20px] text-amber-300"
          />
          Renew your{" "}
          {entity_type === "pro_member"
            ? "ProMembership"
            : "Community Membership"}{" "}
          and get UpSkill Series Free!
        </div>
        <button
          onClick={() => navigate("/payments")}
          className="h-8 mt-4 text-sm text-white rounded-sm bg-amber-400 md:mt-0 md:w-32"
        >
          RENEW
        </button>
      </div>
    </React.Fragment>
  );
};

MembershipRSVPRenewalBar.propTypes = {
  entity_type: PropTypes.string,
  prefillData: PropTypes.any,
  status: PropTypes.any,
};

export default MembershipRSVPRenewalBar;
