import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PublicProfileCard from "../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../services/PublicProfileService";
import _ from "lodash";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import FormBuilder from "../../stories/FormBuilder";
import { Validations } from "../../constants/validations";
import { Formik, useFormikContext } from "formik";

const ContactInfoCard = ({
  className,
  publicProfileData,
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isPhoneSameAsWhatsapp, setIsPhoneSameAsWhatsapp] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  useEffect(() => {
    const isSame =
      publicProfileData.profile_detail?.public_mobile_number ===
      publicProfileData.profile_detail?.whatsapp_link;
    setIsPhoneSameAsWhatsapp(isSame);

    setInitialValues({
      email: publicProfileData.profile_detail?.public_email ?? "",
      phone:
        publicProfileData.profile_detail?.public_mobile_number ??
        publicProfileData.phone_number ??
        "",
      whatsapp_link: isSame
        ? publicProfileData.profile_detail?.public_mobile_number ??
          publicProfileData.phone_number ??
          ""
        : publicProfileData.profile_detail?.whatsapp_link ?? "",
      isPhoneSameAsWhatsapp: isSame,
    });
  }, [publicProfileData]);

  const handleSubmit = async (data) => {
    try {
      let postBody = {
        public_email: data.email,
        whatsapp_link: isPhoneSameAsWhatsapp ? data.phone : data.whatsapp_link,
      };
      if (publicProfileData["profile_detail"]?.id) {
        postBody["id"] = publicProfileData["profile_detail"].id;
      }
      if (data.phone !== undefined && data.phone !== null) {
        postBody = {
          ...postBody,
          public_mobile_number: data.phone,
        };
      }
      try {
        const result = await PublicProfileService.updatePublicProfile({
          profile_detail_attributes: postBody,
        });
        if (result.message) {
          CustomToast.success(result.message);
          onUpdate();
          setIsEditing(false);
        }
      } catch (e) {
        CustomToast.error(e.error_messages[0]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setIsEmpty(
      Object.values(initialValues).filter((val) => !_.isEmpty(val)).length === 0
    );
  }, [initialValues, isPhoneSameAsWhatsapp]);

  // Custom hook for accessing Formik context
  const CheckboxWithFormik = ({ handleClick }) => {
    const formik = useFormikContext();

    const handleCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      setIsPhoneSameAsWhatsapp(isChecked);

      // Update Formik values for `whatsapp_link` to match `phone` if checked
      if (isChecked) {
        handleClick({ ...formik.values, whatsapp_link: formik.values.phone });
        formik.setFieldValue("whatsapp_link", formik.values.phone);
      } else {
        // If unchecked, revert to the initial WhatsApp link
        formik.setFieldValue("whatsapp_link", initialValues.whatsapp_link);
      }

      // Update Formik's `isPhoneSameAsWhatsapp` state
      formik.setFieldValue("isPhoneSameAsWhatsapp", isChecked);
    };
    CheckboxWithFormik.propTypes = {
      handleClick: PropTypes.func.isRequired, // Make sure this line is present
    };

    useEffect(() => {
      if (isPhoneSameAsWhatsapp) {
        formik.setFieldValue("whatsapp_link", formik.values.phone);
      }
    }, [formik.values.phone, isPhoneSameAsWhatsapp]);

    return (
      <div className="flex items-center justify-start mb-4">
        <input
          type="checkbox"
          checked={isPhoneSameAsWhatsapp}
          onChange={handleCheckboxChange}
          disabled={!isEditing}
        />
        <label className="ml-2">WhatsApp number is the same as Phone</label>
      </div>
    );
  };

  const textInputDetails = [
    {
      label: "Email Address",
      name: "email",
      placeholder: "Email Address",
      className: "min-w-186",
      isViewOnly: !isEditing,
    },
    {
      label: "Mobile No. (10 digits)",
      name: "phone",
      placeholder: "10 Digit Mobile No.",
      className: "min-w-186 ",
      type: "number",
      isRequired: false,
      isViewOnly: !isEditing,
    },
    {
      label: "WhatsApp No. (10 digits)",
      name: "whatsapp_link",
      placeholder: "10 Digit Mobile No.",
      className: "min-w-186",
      isRequired: false,
      isViewOnly: !isEditing || isPhoneSameAsWhatsapp,
    },
  ];

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Contact Info"
        subHeading={
          "Update your Contact information here for public to contact you."
        }
        id={"contact-details-card"}
        isEditing={isEditing}
        isLoading={isLoading}
        setIsEditing={setIsEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isEmpty={!isEditing && isEmpty}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              message="To see contact information here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#contact-details-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              <Formik
                initialValues={initialValues}
                validationSchema={
                  Validations.publicProfile.contactInfoValidationSchema
                }
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-y-5 gap-x-6">
                  <FormBuilder
                    initialValues={initialValues}
                    validationSchema={
                      Validations.publicProfile.contactInfoValidationSchema
                    }
                    formClassName="grid w-full gap-y-5 gap-x-6 grid-cols-1 xl:grid-cols-2"
                    inputFieldsDetails={textInputDetails}
                    onFormSubmit={handleSubmit}
                    submitButtonRef={submitRef}
                    cancelButtonRef={cancelRef}
                    enableReinitialize
                  />
                  {isEditing && (
                    <CheckboxWithFormik
                      handleClick={(e) => {
                        handleSubmit(e);
                      }}
                    />
                  )}
                </div>
              </Formik>
            </div>
          )
        }
      />
    </div>
  );
};

ContactInfoCard.propTypes = {
  className: PropTypes.any,
  isLoading: PropTypes.any,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default ContactInfoCard;
