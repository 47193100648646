import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { CircleNotch } from "@phosphor-icons/react";

const ToggleSwitch = ({
  className = "",
  isLoading = false,
  defaultValue,
  onChange = () => {},
}) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setEnabled(defaultValue);
  }, [defaultValue]);

  if (isLoading)
    return (
      <div className={classNames("flex items-center h-6 w-11", className)}>
        <CircleNotch className="mx-auto animate-spin" size={20} weight="fill" />
      </div>
    );

  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      className={classNames(
        enabled ? "bg-theme-red-300" : "bg-gray-200",
        "relative inline-flex h-6 w-11 items-center rounded-full",
        className
      )}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
};
export default ToggleSwitch;
