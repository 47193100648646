/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import API from "../api/manager";
import config from "../api/config";

const useComments = (entity_id, page) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (lastPage !== page) {
      setLastPage(page);
      API(
        {
          method: "GET",
          url: config.urls.exchange_forum.fetch_comments,
          data: {},
          params: { exchange_forum_id: entity_id, page, per_page: 10 },
        },
        true
      )
        .then((res) => {
          setComments((prevComments) => {
            return [...prevComments, ...res.forum_comments];
          });
          setHasMore(
            res.pagination.total_count === 0 ? false : !res.pagination.last_page
          );
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [page]);

  return { loading, error, comments, hasMore };
};
export default useComments;
