import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import promember_badge from "../../assets/svgs/Promember.svg";
const PromemberMessage = ({
  message = "",
  imageComponent,
  showIcon = true,
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex max-w-[365px] rounded-lg flex-row text-white bg-theme-black-color shadow-md ",
        className
      )}
    >
      {showIcon && (
        <div className="flex flex-col items-start justify-center pl-3">
          <div className="flex h-10 w-10 bg-[#f8efe2] rounded-full items-center justify-center">
            {imageComponent || (
              <img src={promember_badge} alt="Pro badge" className="w-8 h-8" />
            )}
          </div>
        </div>
      )}
      <div className={classNames("flex flex-col py-2 pr-4 ml-3")}>
        <div className="text-sm tracking-tight text-left">{message}</div>
      </div>
    </div>
  );
};

PromemberMessage.propTypes = {
  className: PropTypes.string,
  imageComponent: PropTypes.any,
  message: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default PromemberMessage;
