import { useEffect, useState } from "react";

const useDocumentTitle = (title) => {
  const [documentTitle, setDoucmentTitle] = useState(title);
  useEffect(() => {
    document.title = `${documentTitle} | Network FP Member Learning Portal`;
  }, [documentTitle]);

  return [documentTitle, setDoucmentTitle];
};

export { useDocumentTitle };
