import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import PublicProfileCard from "../../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../../services/PublicProfileService";
import _ from "lodash";
import Button from "../../../stories/Button";
import { CustomToast } from "../../../stories/Toast";
import FormBuilder from "../../../stories/FormBuilder";
import { Validations } from "../../../constants/validations";

const PracticeInfoCard = ({
  className,
  corporateProfileData = {},
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialValues, setInitialValues] = useState({
    experience: 0,
    noOfFamilies: "",
    assets: "",
    natureOfEarnings: "",
    categoryServed: "",
    licenses: [],
  });
  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      experience:
        corporateProfileData.firm?.["professional_detail"]?.work_experience ??
        "",
      noOfFamilies:
        corporateProfileData.firm?.["professional_detail"]?.families_served ??
        "",
      assets:
        corporateProfileData.firm?.["professional_detail"]?.auma_in_crores ??
        "",
      natureOfEarnings:
        corporateProfileData.firm?.["professional_detail"]?.nature_of_earning
          ?.id ?? "",
      categoryServed:
        corporateProfileData.firm?.["professional_detail"]
          ?.client_category_served ?? "",
      licenses: (
        corporateProfileData.firm?.["professional_detail"]?.licenses ?? []
      ).map((e) => e.id),
      service_delivery_model: (
        corporateProfileData.firm?.["professional_detail"]
          ?.service_delivery_model ?? []
      ).map((e) => e.id),
    }));
  }, [corporateProfileData.firm]);

  useEffect(() => {
    setIsEmpty(
      _.size(
        _.filter(
          _.values(initialValues) ?? [],
          (val) =>
            val !== undefined &&
            val !== null &&
            val !== "" &&
            !(_.isArray(val) && val.length === 0)
        )
      ) === 0
    );
  }, [initialValues]);

  const getLicenseLabel = (id) => {
    return (
      <span key={id}>
        {options["industry_licence"]?.filter((e) => e.id === id)[0]?.value ??
          ""}
      </span>
    );
  };

  const getServiceDeliveryModal = (id) => {
    return (
      <span key={id}>
        {options["service_delivery_model"]?.filter((e) => e.id === id)[0]
          ?.value ?? ""}
      </span>
    );
  };

  const onFormSubmit = async (values) => {
    // same shape as initial valuesd
    let postBody = {
      work_experience:
        typeof values.experience == "number" ? values.experience : 0,
      families_served:
        typeof values.noOfFamilies == "number" ? values.noOfFamilies : 0,
      auma_in_crores: typeof values.assets == "number" ? values.assets : 0,
      nature_of_earnings_id: values.natureOfEarnings,
      client_category_served: values.categoryServed,
      licences_ids: values.licenses,
      service_delivery_model_ids: values.service_delivery_model,
    };
    if (corporateProfileData.firm["professional_detail"]?.id) {
      postBody = {
        ...postBody,
        id: corporateProfileData.firm["professional_detail"].id,
      };
    }
    try {
      const result = await PublicProfileService.updateCorporateInfo({
        data: { professional_detail_attributes: postBody },
        id: corporateProfileData?.firm?.id,
      });
      if (result.message) {
        CustomToast.success(result.message);
        onUpdate();
        setIsEditing(false);
      }
      return result;
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  const textInputDetails = [
    {
      label: "Experience (in Years)",
      name: "experience",
      placeholder: "20",
      className: "lg:min-w-186",
      type: "number",
      isRequired: false,
      infoContent:
        "Mention number of years of experience in Personal Finance field",
      isViewOnly: !isEditing,
    },
    {
      label: "No. of Families Served",
      name: "noOfFamilies",
      placeholder: "3",
      className: "lg:min-w-186",
      type: "number",
      isRequired: false,
      infoContent:
        "Mention number of active clients/families being serviced by you",
      isViewOnly: !isEditing,
    },
    {
      label: "Assets Managed (in Crores)",
      name: "assets",
      placeholder: "10",
      className: "lg:min-w-186",
      type: "number",
      isRequired: false,
      infoContent:
        "Mention approximate assets under management and advice (AUMA) being managed across all investments/products on which either you are either earning commissions or fees.",
      isViewOnly: !isEditing,
    },
    {
      label: "Nature of Earnings",
      placeholder: "Select Option",
      type: "dropdown",
      name: "natureOfEarnings",
      renderValue: (values) =>
        _.head(
          _.filter(
            options["nature_of_earning"],
            (e) => e.id === values?.natureOfEarnings
          ) ?? []
        )?.value ?? "",
      displayCurrentOption: true,
      options: options["nature_of_earning"],
      displayKey: "value",
      idKey: "id",
      className: "lg:min-w-186",
      isRequired: false,
      isViewOnly: !isEditing,
      infoContent:
        "Mention how you would be earning from your clients for the products / services provided",
    },
    {
      label: "Client Category Served",
      name: "categoryServed",
      placeholder: "Write down here . . .",
      className: "col-span-full",
      labelClassName: "text-xs leading-4 tracking-tight",
      rows: 4,
      type: "textarea",
      isRequired: false,
      isViewOnly: !isEditing,
      infoContent:
        "Mention kind of clients you prefer / specialize in serving like income bracket, niche, minimum assets, location etc",
    },
    {
      label: "INDUSTRY LICENSES HELD",
      name: "licenses",
      type: "checklist",
      options: options["industry_licence"],
      displayKey: "value",
      idKey: "id",
      isViewOnly: !isEditing,
      viewOnlyValues: (e) => {
        if (_.isEmpty(e)) return "-";
        return <div className="flex flex-col">{_.map(e, getLicenseLabel)}</div>;
      },
    },
    {
      label: "Service Delivery Model",
      name: "service_delivery_model",
      type: "checklist",
      options: options["service_delivery_model"],
      displayKey: "value",
      idKey: "id",
      isViewOnly: !isEditing,
      infoContent:
        "Mention how you would be connecting with the clients to provided them with the services requested",
      viewOnlyValues: (e) => {
        if (_.isEmpty(e)) return "-";
        return (
          <div className="flex flex-col">
            {_.map(e, getServiceDeliveryModal)}
          </div>
        );
      },
    },
  ];
  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Practice Info"
        subHeading="Update your Practice Info here."
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        id="practice-info-card"
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isEmpty={!isEditing && isEmpty}
        isLoading={isLoading}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              heading="Add Practice Information"
              className={"!mt-0 !mb-4"}
              message="To see practice information here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#practice-info-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              <div className="flex ">
                <FormBuilder
                  initialValues={initialValues}
                  validationSchema={
                    Validations.publicProfile.practiceInfoValidationSchema
                  }
                  onFormSubmit={onFormSubmit}
                  submitButtonRef={submitRef}
                  inputFieldsDetails={textInputDetails}
                  cancelButtonRef={cancelRef}
                  formClassName="grid w-full grid-cols-2 1440px:grid-cols-3 gap-y-5 gap-x-6"
                />
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

PracticeInfoCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  corporateProfileData: PropTypes.object,
};

export default PracticeInfoCard;
