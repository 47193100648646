import React from "react";
import PropTypes from "prop-types";
import { Eye, Star } from "@phosphor-icons/react";
import classNames from "classnames";

const ProfileStats = ({ stats, type, className, onClick }) => {
  return (
    <div
      className={classNames(
        "flex items-center p-2 border rounded-3xl w-fit h-fit",
        className
      )}
      onClick={onClick}
    >
      {type === "points" ? (
        <div className="mr-2">
          <StarIcon />
        </div>
      ) : (
        <Eye
          weight="fill"
          className="mr-2 w-6 h-6 min-w-[24px] text-theme-orange"
        />
      )}
      <div className="text-xs">{stats}</div>
    </div>
  );
};

const StarIcon = () => {
  return (
    <div className="flex justify-center w-6 h-6 min-w-[24px] border rounded-full bg-theme-orange ">
      <div className="flex items-center">
        <Star className="text-white" weight="fill" />
      </div>
    </div>
  );
};
ProfileStats.defaultProps = {
  type: "points",
};

ProfileStats.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  stats: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["points", "views"]),
};

export default ProfileStats;
