import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "@reach/router";
import { User } from "../../store";

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  return (
    <>
      {User.getToken() ? (
        <Component {...restOfProps} />
      ) : (
        <Redirect to="/login/otp" state={restOfProps.location} noThrow />
      )}
    </>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any,
};

export default ProtectedRoute;
