/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import API from "../api/manager";
import config from "../api/config";

const useNotifications = (type, status = "all", page) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [notifications, setNotifications] = useState({
    notification: {
      all: [],
      unread: [],
      read: [],
    },
    alert: {
      all: [],
      unread: [],
      read: [],
    },
  });
  const [hasMore, setHasMore] = useState({
    notification: {
      all: false,
      unread: false,
      read: false,
    },
    alert: {
      all: false,
      unread: false,
      read: false,
    },
  });
  const [lastPage, setLastPage] = useState({
    notification: {
      all: 0,
      unread: 0,
      read: 0,
    },
    alert: {
      all: 0,
      unread: 0,
      read: 0,
    },
  });
  const [lastStatus, setLastStatus] = useState("all");
  const [lastType, setLastType] = useState("notification");

  useEffect(() => {
    if (
      (lastStatus !== status || lastType !== type) &&
      (notifications[type][status] ?? []).length !== 0
    )
      return;
    if (lastPage[type][status] !== page) {
      setLoading(true);
      setError(false);
      setLastPage((prevLastPage) => ({
        ...prevLastPage,
        [type]: { ...prevLastPage[type], [status]: page },
      }));
      if (lastStatus !== status) {
        setLastStatus(status);
      }
      if (lastType !== type) {
        setLastType(type);
      }
      API(
        {
          method: "GET",
          url: config.urls.user.fetch_notifications,
          params: {
            kind: type,
            filter: status === "all" ? undefined : status,
            page: page,
          },
        },
        true
      )
        .then((res) => {
          setNotifications((prevNotifications) => {
            return {
              ...prevNotifications,
              [type]: {
                ...prevNotifications[type],
                [status]: [
                  ...prevNotifications[type][status],
                  ...res.user_notifications,
                ],
              },
            };
          });
          setHasMore((prevHasMore) => ({
            ...prevHasMore,
            [type]: {
              ...prevHasMore[type],
              [status]: !res.pagination.last_page,
            },
          }));
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [status, page, type]);

  return {
    loading,
    error,
    notifications: notifications[type][status] ?? [],
    hasMore: hasMore[type][status] ?? false,
  };
};
export default useNotifications;
