import Tabs from "../../stories/Tabs";
import AppSkeleton from "../AppSkeleton";
import * as Constants from "../../Constants";
import InvoiceTable from "../../stories/InvoiceTable";
import SubscriptionTable from "../../stories/SubscriptionTable";
import { useInvoices, useSubscriptions } from "../../services/BasicDetails";
import _ from "lodash";
import { ErrorScreen } from "../../stories/ErrorScreen";
import SearchBar from "../../stories/SearchBar";
import { useState } from "react";

const MyPayments = () => {
  const {
    loading: isSubscriptionsLoading,
    subscriptions,
    error: subscriptionError,
    mutate: mutateSubscriptions,
  } = useSubscriptions({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const {
    loading: isInvoicesLoading,
    invoices,
    error: invoiceError,
    mutate: mutateInvoices,
  } = useInvoices({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const tabList = [
    {
      heading: "Subscriptions",
      component: (
        <SubscriptionTable
          key={1}
          isLoading={isSubscriptionsLoading}
          data={subscriptions.filter(
            (s) =>
              currentIndex !== 0 ||
              (currentIndex === 0 &&
                `${s.name} ${s.course_type} ${s.payment_status} ${s.subscription_status}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()))
          )}
          error={subscriptionError}
          refreshData={() => {
            mutateSubscriptions();
            mutateInvoices();
          }}
          showEmptyState={!isSubscriptionsLoading && _.isEmpty(subscriptions)}
        />
      ),
    },
    {
      heading: "Invoices",
      component: (
        <InvoiceTable
          key={2}
          isLoading={isInvoicesLoading}
          columns={Constants.InvoiceColumns}
          data={invoices.filter(
            (s) =>
              currentIndex !== 1 ||
              (currentIndex === 1 &&
                `${s.program_name} ${s.program_detail} ${s.invoice_number} ${s.start_date}`
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()))
          )}
          error={invoiceError}
          showEmptyState={!isInvoicesLoading && _.isEmpty(invoices)}
        />
      ),
    },
  ];

  if (subscriptionError && invoiceError) {
    return (
      <AppSkeleton
        pageLabel="My Payments"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          { name: "My Payments", to: "/payments", current: true },
        ]}
      >
        <ErrorScreen />
      </AppSkeleton>
    );
  }
  return (
    <AppSkeleton
      pageLabel="My Payments"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "My Payments", to: "/payments", current: true },
      ]}
    >
      <div className="flex flex-col w-full px-6 py-4 h-fit">
        <Tabs
          tabList={tabList}
          onTabClick={(index) => {
            setCurrentIndex(index);
            setSearchQuery("");
          }}
          headingLastComponent={
            <div className="justify-end hidden w-full md:flex grow">
              <div className="w-full max-w-lg">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={
                    currentIndex === 0
                      ? "Search subscription..."
                      : "Search invoice..."
                  }
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
          }
          middleComponent={
            <div className="flex justify-start w-full md:hidden">
              <div className="w-full">
                <SearchBar
                  className="w-full"
                  backgroundColor="bg-white"
                  placeholder={
                    currentIndex === 0
                      ? "Search subscription..."
                      : "Search invoice..."
                  }
                  searchTerm={searchQuery}
                  onSearch={(val) => setSearchQuery(val)}
                />
              </div>
            </div>
          }
        />
        <div className="w-full h-16" />
      </div>
    </AppSkeleton>
  );
};

export default MyPayments;
