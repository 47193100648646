/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import QPFPBg from "../../../assets/images/QPFPBg.png";
import QPFPFullWhiteLogoTransparent from "../../../assets/images/QPFPFullWhiteLogoTransparent.png";
import { Check } from "@phosphor-icons/react";
import RegistrationDetails from "./RegistrationDetails";
import EthicsOath from "./EthicsOath";
import PaymentScreen from "./PaymentScreen";
import SuccessScreen from "./SuccessScreen";
import {
  useCurrentUserPublicProfile,
  useMembershipDetails,
} from "../../../services/BasicDetails";
import { useProfile } from "../../../context/userProvider";
import _ from "lodash";
import { useNavigate } from "@reach/router";
import { CustomToast } from "../../../stories/Toast";
import { UserService } from "../../../services/UserService";
import Button from "../../../stories/Button";

const QPFPCertificationRegistration = ({ course_id }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { membership: currentMembershipDetails, loading: membershipLoading } =
    useMembershipDetails(
      "qpfp",
      {
        revalidateOption: {
          revalidateOnFocus: false,
          revalidateIfStale: false,
          revalidateOnMount: true,
        },
      },
      "",
      course_id
    );
  const { loading: publicProfileLoading, publicProfileData } =
    useCurrentUserPublicProfile({
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    });
  const [aceEligible, setAceEligible] = useState(true);

  useEffect(() => {
    if (_.isEmpty(userDetails)) {
      return;
    }
    if (!isFirstLoad) {
      return;
    }
    setIsFirstLoad(false);
    if (userDetails.qpfp_certification_status === "inactive") {
      CustomToast.info(
        "You are not eligible for QPFP Certificate Registration Process."
      );
      navigate("/");
      return;
    }
    if (
      !["not_registered", "overdue", "expires_soon"].includes(
        userDetails.qpfp_certification_status
      )
    ) {
      CustomToast.info("You QPFP certificate is not due for renewal.");
      navigate("/qpfp-certificant/dashboard");
      return;
    }
    if (
      ["overdue", "expires_soon"].includes(
        userDetails.qpfp_certification_status
      )
    ) {
      checkAceEligibility();
    }
  }, [userDetails]);

  const checkAceEligibility = async () => {
    try {
      const response = await UserService.fetchQPFPCertificationStatus();
      const aceData = response?.ace_points_data ?? {};
      if (aceData?.ace_points_earned < aceData?.ace_points_required) {
        setAceEligible(false);
      }
    } catch (e) {}
  };

  if (!aceEligible) {
    return (
      <QPFPSkeleton showSidebar={false}>
        <div className="pt-15 pb-36 relative px-5 flex flex-col w-full justify-center gap-6 items-center z-0">
          <img
            src={QPFPBg}
            className="absolute top-0 w-full h-full object-cover -z-10"
            alt="QPFP Full White Logo background"
          />
          <img
            src={QPFPFullWhiteLogoTransparent}
            className="h-14 lg:h-21 object-contain"
            alt="QPFP Full White Logo"
          />
          <div className="font-medium text-2xl text-center lg:text-3xl text-white">
            QPFP<sup className="font-bold">&reg;</sup> Certification
            Registration
          </div>
          <div className="text-sm lg:text-base text-white max-w-3xl text-center leading-6">
            Ohh no! You are not eligible to renewal of QPFP Certification. You
            don&apos;t have required number of ace points to become eligible for
            renewal. Please visit your QPFP Dashboard for more details.
          </div>
          <Button
            onClick={() => {
              navigate("/qpfp-certificant/dashboard", { replace: true });
            }}
            buttonStyle="white"
            className="text-sm !text-theme-red-300 font-medium !h-10 uppercase"
          >
            Go to QPFP Benefits Dashboard
          </Button>
        </div>
      </QPFPSkeleton>
    );
  }

  return (
    <QPFPSkeleton showSidebar={false}>
      <div className="pt-15 pb-36 relative px-5 flex flex-col w-full justify-center gap-6 items-center z-0">
        <img
          src={QPFPBg}
          className="absolute top-0 w-full h-full object-cover -z-10"
          alt="QPFP Full White Logo background"
        />
        <img
          src={QPFPFullWhiteLogoTransparent}
          className="h-14 lg:h-21 object-contain"
          alt="QPFP Full White Logo"
        />
        <div className="font-medium text-2xl text-center lg:text-3xl text-white">
          QPFP<sup className="font-bold">&reg;</sup> Certification Registration
        </div>
        <div className="text-sm lg:text-base text-white max-w-3xl text-center leading-6">
          Congratulations! You are eligible to register for QPFP Certification.
          Upon registration, you will be awarded with QPFP Certificate, allowed
          to use QPFP marks and enjoy the ongoing QPFP Certification & Renewal
          benefits
        </div>
        <div className="w-full max-w-3xl sm:flex justify-center">
          <Stepper
            currentStep={currentStep}
            steps={["Details", "Ethics", "Payment"]}
          />
        </div>
      </div>
      <div className="mx-5 z-10">
        {currentStep === 0 && (
          <RegistrationDetails
            currentMembershipDetails={currentMembershipDetails}
            loading={membershipLoading && publicProfileLoading}
            onSubmit={() => {
              setCurrentStep(1);
            }}
          />
        )}
        {currentStep === 1 && (
          <EthicsOath
            loading={membershipLoading && publicProfileLoading}
            onSubmit={() => {
              setCurrentStep(2);
            }}
          />
        )}
        {currentStep === 2 && (
          <PaymentScreen
            loading={membershipLoading && publicProfileLoading}
            courseId={currentMembershipDetails.course?.id}
            publicProfileData={publicProfileData}
            onSubmit={() => {
              setCurrentStep(3);
            }}
          />
        )}
        {currentStep === 3 && <SuccessScreen />}
      </div>
    </QPFPSkeleton>
  );
};

const Stepper = ({ currentStep, steps }) => {
  return (
    <div className="flex items-center">
      {steps.map((step, index) => {
        return (
          <>
            <div
              key={`${step}-${index}`}
              className="flex w-40 relative flex-col items-center justify-start gap-4 bg-transparent"
            >
              <div className="rounded-full border-2 flex items-center justify-center border-white w-4 h-4">
                {index < currentStep && (
                  <div className="bg-white w-3 h-3 rounded-full">
                    <Check
                      size={12}
                      weight="bold"
                      className="text-theme-orange"
                    />
                  </div>
                )}
                {index === currentStep && (
                  <div className="bg-white w-2 h-2 rounded-full"></div>
                )}
              </div>
              {index > 0 && (
                <div className="w-[calc(100%-16px)] absolute top-2 -z-10 -left-[calc(50%-8px)] bg-white h-[1px]" />
              )}
              <div className="text-white font-medium text-sm">
                {steps[index]}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.any,
  steps: PropTypes.shape({
    map: PropTypes.func,
  }),
};
QPFPCertificationRegistration.propTypes = {
  course_id: PropTypes.string,
};

export default QPFPCertificationRegistration;
