import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useField, useFormikContext } from "formik";
import classNames from "classnames";
import Label from "./Label";
import { AsteriskSimple } from "@phosphor-icons/react";
import ShowFormError from "./ShowFormError";
import PropTypes from "prop-types";

const Datepicker = ({
  name,
  placeholder,
  includeTime = false,
  label,
  isRequired,
  height = "h-10",
  maxDate,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name, ...props });

  return (
    <div className="w-full">
      <Label
        labelFor={props.name}
        className={
          "flex items-center text-left text-xs leading-none font-semibold text-theme-black-300"
        }
      >
        {label ?? "Text Input"}
        {isRequired && (
          <AsteriskSimple
            size={8}
            weight="bold"
            className="mb-2 text-theme-red"
          />
        )}
      </Label>
      <DatePicker
        placeholderText={placeholder}
        className={classNames(
          "inline-flex items-center justify-between cursor-pointer",
          "text-left text-sm text-theme-black-color font-medium tracking-tight",
          "pl-2.5 pr-3 py-2.5 mt-1 w-full border focus:ring-0 focus:outline-none focus:border ",
          meta.touched && meta.error
            ? "border-theme-red-200 focus:ring-theme-red-200 focus:border-theme-red-200"
            : "border-gray-200 focus:ring-gray-900 focus:border-theme-black-color",
          "rounded-sm shadow-sm",
          height ?? "h-10"
        )}
        dateFormat={includeTime ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"}
        showTimeInput={includeTime ? true : false}
        timeInputLabel={includeTime ? "Time:" : null}
        name={name}
        id={name}
        {...field}
        {...props}
        onFocus={(e) => e.target.blur()}
        showMonthDropdown
        showYearDropdown
        maxDate={maxDate ?? new Date()}
        dropdownMode="select"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        autoComplete="off"
        autoFocus={false}
        onChangeRaw={(e) => {
          setFieldTouched(field.name, true, true);
        }}
      />
      <ShowFormError name={name} />
    </div>
  );
};

Datepicker.defaultProps = {
  includeTime: false,
  isRequired: false,
  height: "",
};

Datepicker.propTypes = {
  height: PropTypes.string,
  includeTime: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxDate: PropTypes.any,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Datepicker;
