/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AppSkeleton from "../AppSkeleton";
import Tabs from "../../stories/Tabs";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import moment from "moment";
import _ from "lodash";
import { CalendarService } from "../../services/CalendarService";
import CalendarSessionDetails from "../../stories/CalendarSessionDetails";
import WeekView from "./WeekView";
import MonthView from "./MonthView";
import CalendarSessionDetailsModal from "../Modals/CalendarSessionDetailsModal";
import { useWindowSize } from "../../hooks/useWindowSize";

// const data = {
//   "01 Mar, 2023": [],
//   "02 Mar, 2023": [
//     {
//       course_name: "SuccessTalks",
//       date: "02 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//   ],
//   "03 Mar, 2023": [
//     {
//       course_name: "SuccessTalks",
//       date: "03 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//     {
//       course_name: "SuccessTalks",
//       date: "03 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//     {
//       course_name: "SuccessTalks",
//       date: "03 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//   ],
//   "04 Mar, 2023": [
//     {
//       course_name: "SuccessTalks",
//       date: "04 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//   ],
//   "05 Mar, 2023": [
//     {
//       course_name: "SuccessTalks",
//       date: "05 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//   ],
//   "06 Mar, 2023": [
//     {
//       course_name: "SuccessTalks",
//       date: "06 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//   ],
//   "07 Mar, 2023": [
//     {
//       course_name: "SuccessTalks",
//       date: "07 Mar, 2023",
//       session_name: "General Insurance",
//       topic: "My dream of Rolls Royce for my clients",
//       trainer: "Ganjendra Kothari",
//       time: "4 PM to 5 PM",
//       for: "Community Members + QPFP Candidates + ProMembers",
//       link: "Link",
//     },
//   ],
// };

const getDateRangeForCalendarView = ({ view, day, month, year }) => {
  const date = moment();
  day && date.date(day);
  month && date.month(month);
  year && date.year(year);

  let startCalendarFrom = "";
  let endCalendarAt = "";
  if (view === "month") {
    startCalendarFrom = date
      .clone()
      .startOf("month")
      .startOf("week")
      .add(1, "days");
    endCalendarAt =
      date.clone().endOf("month").day() === 0
        ? date.clone().endOf("month")
        : date.clone().endOf("month").endOf("week").add(1, "day");
  } else if (view === "week") {
    startCalendarFrom = date.clone().startOf("week").add(1, "days");
    endCalendarAt = date.clone().endOf("week").add(1, "day");
  }
  const dateArray = [];
  let currentDate = startCalendarFrom.clone();
  while (currentDate.isSameOrBefore(endCalendarAt)) {
    dateArray.push(currentDate.format("DD-MM-YYYY"));
    currentDate = currentDate.add(1, "day");
  }
  return dateArray;
};

const MiddleComponent = ({
  onClickNext,
  selectedView,
  onClickPrev,
  dateRange,
}) => {
  const startDate = _.head(dateRange);
  const middleDate = dateRange[8] || "";
  const lastDate = _.last(dateRange);
  let dateString = "";
  if (_.isEmpty(startDate) || _.isEmpty(lastDate)) {
    dateString = "";
  }
  if (selectedView === "week") {
    dateString = `${moment(startDate, "DD-MM-YYYY").format(
      "DD MMM"
    )} - ${moment(lastDate, "DD-MM-YYYY").format("DD MMM, YYYY")}`;
  } else {
    dateString = `${moment(middleDate, "DD-MM-YYYY").format("MMMM YYYY")}`;
  }
  return (
    <div className="flex gap-2.5 px-6 justify-center lg:justify-start">
      <CaretLeft
        size={24}
        weight="fill"
        className="cursor-pointer"
        onClick={onClickPrev}
      />
      <span className="text-xl font-bold leading-6">{dateString}</span>
      <CaretRight
        size={24}
        weight="fill"
        className="cursor-pointer"
        onClick={onClickNext}
      />
    </div>
  );
};

MiddleComponent.propTypes = {
  dateRange: PropTypes.array,
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
  selectedView: PropTypes.any,
};

const tabList = ({ setEventToDisplay, eventToDisplay, events, dateRange }) => [
  {
    heading: "Week",
    buttonId: "week",
    component: (
      <WeekView
        setEventToDisplay={setEventToDisplay}
        eventToDisplay={eventToDisplay}
        events={events}
        dateRange={dateRange}
      />
    ),
  },
  {
    heading: "Month",
    buttonId: "month",
    component: (
      <MonthView
        setEventToDisplay={setEventToDisplay}
        eventToDisplay={eventToDisplay}
        events={events}
        dateRange={dateRange}
      />
    ),
  },
];

const NFPCalendar = () => {
  const [selectedView, setSelectedView] = useState("week"); // 0 - week, 1 - month
  const [eventToDisplay, setEventToDisplay] = useState(undefined);
  const [events, setEvents] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const { width } = useWindowSize();

  const fetchData = async () => {
    const today = moment();
    const newDateRange = getDateRangeForCalendarView({
      view: selectedView,
      day: today.date(),
      month: today.month(),
      year: today.year(),
    });
    setDateRange(newDateRange);
    const response = await CalendarService.fetchCalendarSessions({
      start_date: _.head(newDateRange),
      end_date: _.last(newDateRange),
    });
    const eventsObj = {};
    response.course_session_contents?.forEach((content) => {
      if (_.isEmpty(eventsObj[moment(content.date).format("DD-MM-YYYY")])) {
        eventsObj[moment(content.date).format("DD-MM-YYYY")] = [];
      }
      eventsObj[moment(content.date).format("DD-MM-YYYY")].push(content);
    });
    setEvents(eventsObj);
  };

  useEffect(() => {
    fetchData();
  }, [selectedView]);

  const onClickNext = async () => {
    const lastDate = moment(_.last(dateRange), "DD-MM-YYYY").add(1, "day");
    const newDateRange = getDateRangeForCalendarView({
      view: selectedView,
      day: lastDate.date(),
      month: lastDate.month(),
      year: lastDate.year(),
    });
    setDateRange(newDateRange);
    const response = await CalendarService.fetchCalendarSessions({
      start_date: _.head(newDateRange),
      end_date: _.last(newDateRange),
    });
    const eventsObj = {};
    response.course_session_contents?.forEach((content) => {
      if (_.isEmpty(eventsObj[moment(content.date).format("DD-MM-YYYY")])) {
        eventsObj[moment(content.date).format("DD-MM-YYYY")] = [];
      }
      eventsObj[moment(content.date).format("DD-MM-YYYY")].push(content);
    });
    setEvents(eventsObj);
  };

  const onClickPrev = async () => {
    const lastDate = moment(_.head(dateRange), "DD-MM-YYYY").subtract(2, "day");
    const newDateRange = getDateRangeForCalendarView({
      view: selectedView,
      day: lastDate.date(),
      month: lastDate.month(),
      year: lastDate.year(),
    });
    setDateRange(newDateRange);
    const response = await CalendarService.fetchCalendarSessions({
      start_date: _.head(newDateRange),
      end_date: _.last(newDateRange),
    });
    const eventsObj = {};
    response.course_session_contents?.forEach((content) => {
      if (_.isEmpty(eventsObj[moment(content.date).format("DD-MM-YYYY")])) {
        eventsObj[moment(content.date).format("DD-MM-YYYY")] = [];
      }
      eventsObj[moment(content.date).format("DD-MM-YYYY")].push(content);
    });
    setEvents(eventsObj);
  };

  return (
    <AppSkeleton
      pageLabel="NFP Calendar"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "NFP Calendar", to: "/nfp-calendar", current: true },
      ]}
    >
      <div className="flex m-6">
        <div className="relative flex w-full gap-6">
          <div className="flex flex-col w-full bg-white shadow-card-shadow">
            <Tabs
              tabList={tabList({
                setEventToDisplay,
                eventToDisplay,
                dateRange,
                events,
              })}
              middleComponent={
                <MiddleComponent
                  onClickNext={onClickNext}
                  onClickPrev={onClickPrev}
                  dateRange={dateRange}
                  selectedView={selectedView}
                />
              }
              tabListClassName="my-6 px-6 justify-center lg:justify-start"
              panelClassName="mb-0"
              onTabClick={(index) =>
                setSelectedView(index === 0 ? "week" : "month")
              }
            />
          </div>
          {!_.isEmpty(eventToDisplay) && (
            <CalendarSessionDetails
              eventToDisplay={eventToDisplay}
              className="hidden max-w-xs 1440px:flex"
              setEventToDisplay={setEventToDisplay}
            />
          )}
          {!_.isEmpty(eventToDisplay) && width > 1024 && width < 1440 && (
            <CalendarSessionDetailsModal
              open={!_.isEmpty(eventToDisplay) && width > 1024 && width < 1440}
              onClose={() => setEventToDisplay([])}
              eventToDisplay={eventToDisplay}
              setEventToDisplay={setEventToDisplay}
            />
          )}
        </div>
      </div>
    </AppSkeleton>
  );
};

export default NFPCalendar;
