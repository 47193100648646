import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import classNames from "classnames";
import { WarningCircle } from "@phosphor-icons/react";
import React from "react";
import Card from "../../stories/Card";
import StepperProgressBar1 from "../../stories/StepperProgressBar1";
import UploadDocumentsBar from "../../stories/UploadDocumentsBar";
import _ from "lodash";

const MembershipSection = ({
  data = {},
  program_title,
  entity_type,
  program_description,
  reason,
}) => {
  const navigate = useNavigate();
  const next_step = data.next_step;

  const SuggestionMessage = () => {
    const getSuggestionMessage = () => {
      if (["rejected", "approved", "provisional"].includes(data.status))
        return reason;
      if (next_step === 1) {
        return "Make a payment and continue with your application";
      }
      if (next_step < 3) return "Please continue with your application";
      if (_.isEmpty(reason) && !data.has_uploaded_documents) {
        return "Please upload your documents to complete the registration.";
      }
      return null;
    };

    if (
      next_step === 3 &&
      data.has_uploaded_documents &&
      !["rejected", "approved", "provisional"].includes(data.status)
    )
      return null;

    return (
      <div className="flex items-start w-full mt-6">
        <WarningCircle
          size={16}
          className="w-4 mx-0 min-w-[16px] mt-1 text-theme-orange"
        />
        <div className="block">
          <span className="ml-1 text-sm font-semibold leading-5 tracking-tight text-theme-black-color">
            {getSuggestionMessage()}
          </span>
          <span
            className="ml-3.5 text-sm leading-5 font-semibold tracking-tight text-theme-red cursor-pointer"
            onClick={() =>
              navigate(`/members/${entity_type}/register`, {
                state: {
                  course_id: data?.course_id,
                },
              })
            }
          >
            {next_step === 1
              ? "PAY NOW"
              : next_step < 3
              ? "VIEW APPLICATION"
              : data.status === "rejected"
              ? "RE-SUBMIT"
              : !data.has_uploaded_documents && "UPLOAD"}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col">
      {!data.has_uploaded_documents &&
        data.next_step > 2 &&
        !data.reason &&
        data.status !== "approved" && (
          <UploadDocumentsBar
            onClick={() =>
              navigate(`/members/${entity_type}/register`, {
                state: {
                  course_id: data?.course_id,
                },
              })
            }
          />
        )}
      <div
        className={classNames(
          "grid p-3 lg:p-6 bg-white lg:grid-cols-3",
          !data.has_uploaded_documents &&
            data.next_step > 2 &&
            !data.reason &&
            data.status !== "approved" &&
            "mt-6"
        )}
      >
        <div className="lg:mr-6">
          <Card
            className="w-full min-w-full bg-white border lg:border-none lg:h-full lg:mb-0 lg:bg-theme-gray-300"
            cardType="filled"
            header={
              <div className="flex flex-row items-center text-2xl font-bold leading-none tracking-tighter text-black-color">
                {program_title}
              </div>
            }
            cta={<div></div>}
            cardBody={
              <div className="flex flex-col mt-4 mb-5 text-sm tracking-tight text-black-color-300">
                {program_description}
              </div>
            }
          />
        </div>
        <Card
          className="min-w-full col-span-2 mt-6 border-none lg:border lg:flex lg:mt-0 p-0 lg:!p-4"
          header={
            <div>
              <div className="flex items-center mb-1 text-lg font-bold leading-none tracking-tight lg:text-2xl text-theme-black-color">
                Your application status
              </div>
              {entity_type === "qpfp" && (
                <div className="font-medium">
                  Course name:{" "}
                  <span className="text-theme-red">{data.course_name}</span>{" "}
                </div>
              )}
            </div>
          }
          cta={<></>}
          cardType="outline"
          cardBody={
            <div className="flex flex-col w-full mt-4">
              <StepperProgressBar1
                className="w-full"
                currentStep={
                  ["rejected", "approved", "provisional"].includes(data.status)
                    ? 4
                    : next_step
                }
                steps={[
                  data.status === "initiated"
                    ? "Application Initiated"
                    : "Payment Done",
                  "Application Submitted",
                  data.status === "rejected"
                    ? "Application Rejected"
                    : data.status === "provisional"
                    ? "Provisional Member"
                    : data.status === "approved"
                    ? "Application Accepted"
                    : "Application Accepted/Rejected",
                ]}
              />
              {data.status !== "approved" && <SuggestionMessage />}
            </div>
          }
        />
      </div>
    </div>
  );
};

MembershipSection.propTypes = {
  data: PropTypes.object,
  entity_type: PropTypes.string,
  program_description: PropTypes.string,
  program_title: PropTypes.string,
  reason: PropTypes.string,
};

export default MembershipSection;
