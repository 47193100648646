import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import EmptyState from "./EmptyState";
import error_img from "../assets/svgs/error_img.svg";

export const ErrorScreen = ({ heading, subHeading, cta }) => {
  return (
    <EmptyState
      heading={
        heading ?? (
          <span className="text-3xl text-center">
            Oops, Something went wrong
          </span>
        )
      }
      subHeading={
        subHeading ?? (
          <span className="text-base font-light tracking-tight">
            Try reloading the page.<br></br>We&apos;re working hard to fix it
            for you as soon as possible.
          </span>
        )
      }
      cta={
        <Button className="!mt-4" onClick={() => window.location.reload()}>
          <div className="flex items-center px-3 py-1">
            <span>RELOAD PAGE</span>
          </div>
        </Button>
      }
      image={
        <img alt="emptyStatePlaceHolder" className="py-9 " src={error_img} />
      }
    />
  );
};

ErrorScreen.propTypes = {
  cta: PropTypes.any,
  heading: PropTypes.any,
  subHeading: PropTypes.any,
};
