/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import { useProfile } from "../../context/userProvider";
import PaymentForm from "../Payments/PaymentForm";
import { Form, Formik } from "formik";
import { PaymentServices } from "../../services/PaymentServices";
import { CustomToast } from "../../stories/Toast";
import { CircleWavyCheck, X } from "@phosphor-icons/react";
import classNames from "classnames";
import { Validations } from "../../constants/validations";
import Loader from "../Shimmer/Loader";

const CorporatePaymentModal = ({
  open,
  title,
  paymentEntityProps,
  onPaymentSuccessCallback = () => {},
  onClose,
  className,
}) => {
  const submitRef = React.useRef();
  const { userDetails } = useProfile();
  const [priceDetails, setPriceDetails] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firmName: "",
    wantGST: true,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState: "",
  });

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchGSTDetails();
    await fetchPricingDetails({});
    setIsLoading(false);
  };
  const fetchGSTDetails = async () => {
    try {
      const response = await PaymentServices.fetchPreviousGSTDetails();

      setInitialValues((prevState) => {
        if (response.gst_invoice_check === false) return prevState;
        return {
          ...prevState,
          wantGST: response.gst_invoice_check,
          gstin: response.gst_number,
          gstFirmName: response.firm_name,
          gstCommunicationAddress: response.address.line_1,
          gstPincode: response.address.pin,
          gstCity_id: response.address.city_id,
          gstCity: response.address.city,
          gstState_id: response.address.state_id,
        };
      });
    } catch (e) {
      console.log("no previous details found");
    }
  };
  const fetchPricingDetails = async ({ discount_codes = [] }) => {
    const response = await PaymentServices.fetchCorporatePricingDetails({
      entities: JSON.stringify(paymentEntityProps),
      discount_codes: discount_codes,
      firm_id: userDetails.firm_id,
    });
    setPriceDetails(response.corporate_purchase_detail);
    return response.corporate_purchase_detail ?? response;
  };

  const makePayment = async (values) => {
    let postBody = {
      coupon_ids: priceDetails.coupons?.map((e) => e.code),
      payment_gateway: "razorpay",
      course_fees: priceDetails.course_fees,
      gst: priceDetails.gst,
      to_be_paid: priceDetails.to_be_paid,
    };
    if (values.wantGST) {
      postBody = {
        ...postBody,
        gst_invoice_check: values.wantGST,
        gst_number: values.gstin,
        firm_name: values.gstFirmName,
        address_attributes: {
          line_1: values.gstCommunicationAddress,
          city_id: values.gstCity_id,
          pin: values.gstPincode,
        },
      };
    }
    let corporate_purchase_id;
    try {
      const orderDetails = await PaymentServices.createCorporatePurchaseDetail({
        data: {
          corporate_purchase: {
            firm_id: userDetails.firm_id,
            total_amount: priceDetails.to_be_paid,
            corporate_purchase_items_attributes: paymentEntityProps,
          },
        },
      });
      corporate_purchase_id = orderDetails.corporate_purchase_id;
    } catch (e) {
      CustomToast.error(e.error_messages);
      return;
    }
    try {
      const orderDetails1 = await PaymentServices.createOrder({
        purchase: postBody,
        entity_id: corporate_purchase_id,
        entity_type: "corporate_purchase",
      });
      if (orderDetails1 && orderDetails1.payment_status === "initiated") {
        await PaymentServices.makeRazorpayPayment(
          {
            name: userDetails.first_name + " " + userDetails.last_name,
            email: userDetails.email,
            contact: userDetails.phone_number,
          },
          orderDetails1,
          () => {
            CustomToast.success("Payment Successful" ?? "");
            setShowSuccess(true);
            onPaymentSuccessCallback({ purchase_id: orderDetails1.id });
          }
        );
      } else if (orderDetails1 && orderDetails1.payment_status === "success") {
        CustomToast.info(orderDetails1.message);
        setShowSuccess(true);
      } else {
        CustomToast.error(orderDetails1.message);
      }
    } catch (e) {
      CustomToast.error(e.error_messages);
    }
  };
  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-center justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the Modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classNames(
                  "inline-block p-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:w-full sm:p-6 min-w-328",
                  showSuccess ? "sm:max-w-lg" : "sm:max-w-2xl"
                )}
              >
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6 min-w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                {!showSuccess && (
                  <Dialog.Title
                    as="h3"
                    className="mb-3 mr-6 text-base font-semibold leading-6 text-theme-black-color"
                  >
                    Corporate payment
                  </Dialog.Title>
                )}
                {isLoading ? (
                  <Loader />
                ) : showSuccess ? (
                  <div className="flex flex-col items-center mt-2">
                    <CircleWavyCheck
                      size={32}
                      weight="duotone"
                      className="text-green-500"
                    />
                    <div className="text-lg font-medium leading-6 text-gray-900">
                      Payment successful
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-center text-gray-500">
                        You have successfully completed payment
                        {/* for{" "}
                        <span className="font-medium text-gray-900">
                          {title}
                        </span> */}
                      </p>
                    </div>
                    <Button
                      buttonStyle="primary"
                      height="40px"
                      width="100%"
                      className="mt-3"
                      onClick={async () => {
                        onClose(true);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col mt-2">
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={
                        Validations.popup.paymentPopupValidationSchema
                      }
                      onSubmit={async (values) => {
                        try {
                          const result1 = await makePayment(values);
                          return result1;
                        } catch (e) {
                          CustomToast.error(e.error_messages[0]);
                          if (e.error_code === "already_paid") {
                          }
                          console.log("error saving the form");
                          return e;
                        }
                      }}
                    >
                      {({ values, ...formProps }) => {
                        return (
                          <Form className="w-full">
                            <PaymentForm
                              programTitle={title}
                              allowPartPayment={false}
                              entityProps={{}}
                              availablePartPaymentOptions={[]}
                              priceDetails={priceDetails}
                              setPriceDetails={setPriceDetails}
                              submitRef={submitRef}
                              fetchPricingDetails={fetchPricingDetails}
                              formikProps={{ values, ...formProps }}
                              isReadOnly={false}
                              cta={
                                <React.Fragment>
                                  <Button
                                    buttonStyle="primary"
                                    height="40px"
                                    width="100%"
                                    className="mt-3"
                                    onClick={async () => {
                                      await submitRef.current?.click();
                                    }}
                                  >
                                    PAY NOW
                                  </Button>
                                </React.Fragment>
                              }
                            />
                            <button
                              className="hidden"
                              ref={submitRef}
                              type="submit"
                            >
                              submit
                            </button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

CorporatePaymentModal.defaultProps = {
  open: false,
  className: "",
  availablePartPaymentOptions: [],
};

CorporatePaymentModal.propTypes = {
  availablePartPaymentOptions: PropTypes.array,
  className: PropTypes.string,
  entityProps: PropTypes.object,
  onClose: PropTypes.func,
  onPaymentSuccessCallback: PropTypes.func,
  open: PropTypes.bool,
  paymentEntityProps: PropTypes.any,
  title: PropTypes.string,
};

export default CorporatePaymentModal;
