import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "@phosphor-icons/react";
import React, { useState } from "react";

const ImageComponent = ({ errorComponent, alt, className, src, ...props }) => {
  const [error, setError] = useState(false);
  if (src) {
    return error ? (
      errorComponent ?? (
        <Image
          size={32}
          weight="light"
          className={classNames("bg-gray-200", className)}
        />
      )
    ) : (
      <img
        alt={alt ?? ""}
        {...props}
        className={classNames("bg-gray-200", className)}
        src={src}
        onError={(e) => {
          setError(true);
        }}
      />
    );
  } else {
    return (
      errorComponent ?? (
        <Image
          size={32}
          weight="light"
          className={classNames("bg-gray-200", className)}
        />
      )
    );
  }
};

ImageComponent.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  errorComponent: PropTypes.element,
};

export default ImageComponent;
