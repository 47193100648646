/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useNavigate } from "@reach/router";
import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useCurrentUserPublicProfile } from "../../../services/BasicDetails";
import { EventsService } from "../../../services/EventsService";
import { CustomToast } from "../../../stories/Toast";
import EventDetails from "../EventDetails";
import RSVPForm from "../RSVPForm";
import _ from "lodash";
import AppSkeleton from "../../AppSkeleton";
import classNames from "classnames";
import { useProfile } from "../../../context/userProvider";
import LoadingModal from "../../Modals/LoadingModal";

const EventRSVP = (props) => {
  const { width } = useWindowSize();
  const eventScreenRef = useRef();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState({});
  const { userDetails } = useProfile();
  const isDesktop = width > 1024;
  const { publicProfileData, loading } = useCurrentUserPublicProfile({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.querySelector("body").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  const fetchEventDetails = async (event_id) => {
    const response = await EventsService.fetchEventDetails(event_id);
    const { event } = response;
    if (
      event?.has_event_registered?.has_registered &&
      event?.has_event_registered?.is_paid === "paid"
    ) {
      navigate(`/programs/${event.course_id ?? ""}`);
      CustomToast.info(
        "You have already registered for this event. We are redirecting you to course."
      );
      return;
    }
    const tempEvent = {
      ...event,
    };

    tempEvent.whenContent = [
      ..._.flattenDeep(event.time.map((e) => e?.split("\n"))),
    ];
    tempEvent.whereContent = [
      ..._.flattenDeep(event.place.map((e) => e?.split("\n"))),
    ];
    tempEvent.whatNextContent = [
      ..._.flattenDeep(event.next_step.map((e) => e?.split("\n"))),
    ];
    setEventDetails(tempEvent);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchEventDetails(props.event_id);
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (
      (!_.isEmpty(userDetails) &&
        !_.isEmpty(eventDetails) &&
        !eventDetails.enable_rsvp &&
        !eventDetails.promote_membership) ||
      (eventDetails.enable_rsvp &&
        !(
          [
            userDetails?.pm_signup_status,
            userDetails?.provisonal_member_signup_status,
          ].includes("approved") ||
          userDetails?.pm_signup_status === "provisional" ||
          [
            userDetails?.pm_signup_status,
            userDetails?.provisonal_member_signup_status,
          ].includes("expiring_soon")
        ))
    ) {
      navigate(`/events/${props.event_id}/register`, { replace: true });
    }
  }, [eventDetails, userDetails]);

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      <div className="flex justify-center px-4 py-6 lg:px-16 h-fit">
        <div className="flex flex-col justify-center w-full space-x-0 space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
          <div className="flex bg-white rounded-sm shadow-none h-fit basis-full lg:basis-5/12 lg:shadow-sm">
            <EventDetails
              className=""
              isNewUser={false}
              event_id={props.event_id}
              eventDetails={eventDetails}
            />
          </div>
          <div className="flex flex-col basis-full lg:basis-7/12">
            <RSVPForm
              eventDetails={eventDetails}
              loading={loading}
              prefillData={props?.location?.state?.prefill_data}
              publicProfileData={publicProfileData}
              className={classNames(
                "bg-transparent lg:bg-white rounded-sm shadow-none lg:shadow-sm"
              )}
              onSubmitSuccess={() => {
                eventScreenRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
                navigate(`/events/${props.event_id}/rsvp/thank-you`, {
                  state: {
                    isNewUser: false,
                    event_id: props.event_id,
                    eventDetails: eventDetails,
                  },
                });
              }}
              entity_type={eventDetails.event_type}
              entity_id={props.event_id}
            />
          </div>
        </div>
      </div>
      {(isLoading || loading) && <LoadingModal open={isLoading || loading} />}
    </AppSkeleton>
  );
};

export default EventRSVP;
