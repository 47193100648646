import React from "react";
import PropTypes from "prop-types";

/**
 * Component for ACE Points Tracking Component
 * @return {React.ReactElement} ACE Points Tracking Component
 */
function ACEPoints({ label, points }) {
  return (
    <div className="flex flex-col space-y-1">
      <p className="text-xs">{label}</p>
      <p className="font-semibold text-sm">{points} Points</p>
    </div>
  );
}

ACEPoints.propTypes = {
  label: PropTypes.string,
  points: PropTypes.number,
};

/**
 * Component for ACE Points Tracker Dashboard Element
 * @return {React.ReactElement} ACE Points Tracker Dashboard Element
 */
function ACEPointsTracker({ ace_points_data }) {
  const pointsToBeTracked = [
    {
      label: "ACE Required",
      points: ace_points_data?.ace_points_required,
    },
    { label: "ACE Completed", points: ace_points_data?.ace_points_earned },
    {
      label: "ACE Remaining",
      points: ace_points_data?.ace_points_remaining,
    },
  ];

  return (
    <>
      <h4 className="mb-4 font-semibold text-sm">
        Advanced Continuous Education (ACE)
      </h4>
      <div className="flex justify-between items-center">
        {pointsToBeTracked?.map((point, index) => (
          <ACEPoints
            key={`ace-point-${index}`}
            label={point?.label}
            points={point?.points}
          />
        ))}
      </div>
    </>
  );
}

ACEPointsTracker.propTypes = {
  ace_points_data: PropTypes.object,
};

export default ACEPointsTracker;
