import React from "react";
import networkfp_logo from "../../assets/svgs/NFP_logo_White.svg";
import facebook from "../../assets/svgs/facebook.svg";
import whatsapp from "../../assets/svgs/whatsapp.svg";
import youtube from "../../assets/svgs/youtube.svg";
import {
  EnvelopeSimple,
  LinkedinLogo,
  TwitterLogo,
} from "@phosphor-icons/react";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col justify-between px-5 pt-16 pb-8 bg-black lg:px-20 gap-y-12 lg:flex-row">
        <div className="flex flex-col items-start max-w-md lg:w-2/5">
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-10 lg:h-14"
          />
          <div className="mt-3 text-sm text-white/80">
            Network FP is India&apos;s Leading Online Knowledge Platform for
            Personal Financial Advisors. Network FP&apos;s various programs and
            events are designed at helping financial advisors build and grow
            their practice / career
          </div>
          <div className="flex justify-start gap-2 mt-7">
            <a
              href="mailto:contact@networkfp.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-white/20 hover:bg-theme-orange"
            >
              <EnvelopeSimple
                size={20}
                className="w-5 min-w-[20px] text-white"
              />
            </a>
            <a
              href="https://wa.me/919892018773"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-white/20 hover:bg-theme-orange"
            >
              <img
                alt="whatsapp"
                src={whatsapp}
                className="h-5 w-5 min-w-[20px]"
              />
            </a>
            <a
              href="https://twitter.com/networkfp"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-white/20 hover:bg-theme-orange"
            >
              <TwitterLogo size={20} className="w-5 min-w-[20px] text-white" />
            </a>
            <a
              href="https://www.facebook.com/networkfp/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-white/20 hover:bg-theme-orange"
            >
              <img
                alt="facebook"
                src={facebook}
                className="h-5 w-5 min-w-[20px]"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/3591420/admin/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-white/20 hover:bg-theme-orange"
            >
              <LinkedinLogo size={20} className="w-5 min-w-[20px] text-white" />
            </a>
            <a
              href="https://www.youtube.com/@NetworkFPIndia"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-white/20 hover:bg-theme-orange"
            >
              <img
                alt="youtube"
                src={youtube}
                className="h-5 w-5 min-w-[20px]"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start gap-6 lg:w-1/5">
          <div className="text-lg font-bold leading-7 text-white">
            Program & Events
          </div>
          <div className="flex flex-col gap-5 text-white/80">
            <a
              href="https://networkfp.com/qpfp/"
              target="_blank"
              rel="noreferrer"
              className="text-sm hover:text-theme-orange"
            >
              QPFP Certification Program
            </a>
            <a
              href="https://networkfp.com/promember/"
              target="_blank"
              rel="noreferrer"
              className="text-sm hover:text-theme-orange"
            >
              ProMembership Program
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start gap-6 lg:w-1/5">
          <div className="text-lg font-bold leading-7 text-white">Contact</div>
          <div className="flex flex-col gap-5 text-white/80">
            <div className="text-sm">
              Network FP
              <br></br>
              <br></br>
              #352, A to Z Industrial Estate,<br></br> GK Marg, Lower Parel (W),
              <br></br>
              Mumbai - 400013
            </div>
            <div className="text-sm">
              Phone - 022-24905134<br></br>WhatsApp - 022-24905134
            </div>
            <div className="text-sm">
              Email -
              <a
                className="hover:underline"
                href="mailto:contact@networkfp.com"
              >
                contact@networkfp.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between p-5 text-white lg:px-20 lg:py-12 lg:flex-row bg-theme-black-color/90">
        <div className="">&copy; 2024 Network FP. All Rights Reserved.</div>
        <div className="">
          Designed and Developed by{" "}
          <a href="https://www.f22labs.com/">F22 Labs.</a>
        </div>
      </div>
    </>
  );
};

export default Footer;
