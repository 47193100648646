/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const register = (data, zero_amount_paid) => {
  let body = { user_event: data };
  if (zero_amount_paid) {
    body = { ...body, zero_amount_paid };
  }
  return API(
    {
      method: "POST",
      url: config.urls.events.register,
      data: body,
    },
    true
  );
};
const directRegister = (data, zero_amount_paid) => {
  let body = { user_event: data };
  if (zero_amount_paid) {
    body = { ...body, zero_amount_paid };
  }
  return API(
    {
      method: "POST",
      url: config.urls.events.direct_register,
      data: body,
    },
    false
  );
};

const createRSVP = (data, token) => {
  return API(
    {
      method: "POST",
      url: config.urls.events.create_rsvp,
      data: { rsvp: data },
    },
    true,
    token
  );
};

const fetchEventDetails = (event_id) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.fetch_event(event_id),
    },
    true
  );
};

const fetchVoluntaryDetails = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.fetch_voluntary_detail(id),
    },
    true
  );
};

const fetchRSVPEventDetails = (token = undefined) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.fetch_rsvp_events,
    },
    true,
    token
  );
};

const fetchRSVPOptions = (data, token = undefined) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.fetch_rsvp_options,
      params: data,
    },
    true,
    token
  );
};

const fetchGuestEvents = () => {
  return API(
    {
      method: "GET",
      url: config.urls.events.guest_events,
    },
    true
  );
};
const fetchGuestList = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.guest_list,
      params: { ...data, event_type: "member" },
    },
    true
  );
};
const fetchContacts = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.contacts,
      params: data,
    },
    true
  );
};

const fetchAllActivePrograms = () => {
  return API(
    {
      method: "GET",
      url: config.urls.landing.all_programs,
    },
    true
  );
};
const fetchTestimonials = () => {
  return API(
    {
      method: "GET",
      url: config.urls.landing.testimonials,
    },
    true
  );
};

const submitLandingContactForm = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.landing.contact_form,
      data: { contact_form_landing: data },
    },
    false
  );
};

const fetchVenueTicketAvlStatus = (venue_ticket_id, event_id) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.check_venue_ticket_avl_status(venue_ticket_id),
      params: { event_id },
    },
    false
  );
};

const fetchTicketAvlStatus = (ticket_type_id, event_id) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.check_ticket_avl_status(ticket_type_id),
      params: { event_id },
    },
    false
  );
};

const fetchDelegatesList = (event_id, query, city_id, page, itemPerPage) => {
  return API(
    {
      method: "GET",
      url: config.urls.events.fetch_delegates(
        event_id,
        query,
        city_id,
        page,
        itemPerPage
      ),
    },
    false
  );
};

export const EventsService = {
  register,
  directRegister,
  fetchEventDetails,
  fetchVoluntaryDetails,
  createRSVP,
  fetchRSVPEventDetails,
  fetchRSVPOptions,
  fetchGuestEvents,
  fetchGuestList,
  fetchContacts,
  fetchAllActivePrograms,
  fetchTestimonials,
  submitLandingContactForm,
  fetchVenueTicketAvlStatus,
  fetchTicketAvlStatus,
  fetchDelegatesList,
};
