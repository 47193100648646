import PropTypes from "prop-types";
import React from "react";
import GoogleLogo from "../assets/svgs/google.svg";
// import { useLocation } from "@reach/router";
import { useProfile } from "../context/userProvider";
import { CalendarService } from "../services/CalendarService";
import { CustomToast } from "./Toast";

export const getGoogleUrl = (from) => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

  const options = {
    redirect_uri: `${window.location.origin}/redirectTo/google`,
    client_id: process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/calendar",
    ].join(" "),
    state: from,
  };

  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};

const GoogleOAuth = ({ course_session_content_id }) => {
  // const location = useLocation();
  const { userDetails } = useProfile();

  const addToCalendar = async () => {
    const response = await CalendarService.addMeetingToGoogleCalendar({
      data: {
        provider: "google",
        course_session_content_id: course_session_content_id,
      },
    });
    CustomToast.success(response.message);
  };

  if (userDetails.google_sign_in) {
    return (
      <div className="text-xs text-theme-black-300">
        Add this event to Google Calendar:
        <button
          className="flex items-center justify-start w-full py-2 mb-3 text-sm font-semibold leading-snug transition duration-150 ease-in-out rounded shadow-md text-theme-black-color px-7 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
          // style={{ backgroundColor: "#3b5998" }}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          onClick={async () => {
            await addToCalendar();
          }}
        >
          <img
            className="pr-4"
            src={GoogleLogo}
            alt=""
            style={{ height: "1.5rem" }}
          />
          Sign in with Google
        </button>
      </div>
    );
  }
  return (
    <div className="text-xs text-theme-black-300">
      Add this event to Google Calendar:
      <a
        className="flex items-center justify-start w-full py-2 mb-3 text-sm font-semibold leading-snug transition duration-150 ease-in-out rounded shadow-md text-theme-black-color px-7 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
        // style={{ backgroundColor: "#3b5998" }}
        href={getGoogleUrl(
          JSON.stringify({
            course_session_content_id,
            action: "addToCalendar",
          })
        )}
        role="button"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
      >
        <img
          className="pr-2"
          src={GoogleLogo}
          alt=""
          style={{ height: "1.5rem" }}
        />
        Sign in with Google
      </a>
    </div>
  );
};

GoogleOAuth.propTypes = {
  course_session_content_id: PropTypes.any,
};

export default GoogleOAuth;
