import PropTypes from "prop-types";
import React from "react";

import Button from "./Button";
import { BulletList } from "./BulletList";

import QPFPFullWhiteLogoOrange from "../assets/images/QPFPFullWhiteLogoOrange.png";
import { useNavigate } from "@reach/router";

/**
 * Component for QPFP Renewal Section in the NFP Dashboard
 * @return {React.ReactElement} QPFP Renewal Section in the NFP Dashboard
 */
function QPFPCertificateRenewalSection({ status }) {
  const navigate = useNavigate();
  const qpfpBenefits = [
    "Continue using QPFP Certificates, Badges, Marks & Designation",
    "Access QPFP Latest Courseware and ProTools",
    "Pursue ACE Programs (Advanced Continuous Education)",
    "Use NFP PROfiles",
  ];

  if (!["overdue", "expires_soon"].includes(status)) return null;

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1 p-3 lg:p-6 bg-white gap-x-0 gap-y-3 xl:grid-cols-3 xl:gap-x-3 xl:gap-y-0">
        <div className="col-span-2 flex flex-col items-start space-x-0 space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
          <img src={QPFPFullWhiteLogoOrange} alt="QPFP Full Orange Logo" />

          <div className="flex flex-col space-y-3 items-start">
            <h3 className="font-bold text-2xl">
              QPFP <sup>&reg;</sup> Certification Renewal
            </h3>
            <p className="font-normal text-md text-[#525252]">
              Congratulations! You are eligible to register for QPFP
              Certification. Upon registration, you will be awarded with QPFP
              Certificate, allowed to use QPFP marks and enjoy the ongoing QPFP
              Certification &amp; Renewal benefits
            </p>

            <Button
              onClick={() => navigate("/qpfp-certificant/renewal")}
              className="!mt-4 !font-normal !rounded-sm uppercase"
            >
              Renew Certificate
            </Button>
          </div>
        </div>

        <div className="border border-[#E0E0E0] px-4 py-3 flex flex-col space-y-3 rounded-[3px]">
          <p className="font-medium text-sm">QPFP Renewal Benefits</p>

          <BulletList list={qpfpBenefits} />
        </div>
      </div>
    </div>
  );
}

QPFPCertificateRenewalSection.propTypes = {
  status: PropTypes.string,
};

export default QPFPCertificateRenewalSection;
