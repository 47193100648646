import _ from "lodash";

export const downloadFile = async (url, filename) => {
  try {
    filename = filename ?? _.head(_.split(_.head(_.split(url, "?")), "/"));
    const response = await fetch(url);
    const blob = response.blob();
    const blobURL = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobURL;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobURL);
  } catch (e) {
    console.log(e);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    const filename = _.last(_.head(url.split("?")).split("/"));
    a.download = filename;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};
