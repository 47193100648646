import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "./Text";
import networkfp_logo from "../assets/svgs/NFP_logo.svg";
import { X, List } from "@phosphor-icons/react";
import Breadcrumb from "./Breadcrumb";
import _ from "lodash";
import HeaderProfileIcon from "./HeaderProfileIcon";
import ProfileStats from "./ProfileStats";
import { useProfile } from "../context/userProvider";
import { useNavigate } from "@reach/router";
import HeaderNotificationIcon from "./HeaderNotificationIcon";
import { getDirectoryMemberURL } from "../util/getURLs";

const Header = ({
  hideSidebar,
  hideProfileDetails,
  hideHeaderLabel,
  hideHeaderLogo,
  onLogoClick,
  isLogoClickable,
  title,
  avatar,
  breadCrumbData,
  tabs,
  className,
  mobileSideBarComponent,
  openLoginModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { userDetails } = useProfile();
  const navigate = useNavigate();

  const SidebarToggleIcon = ({ isSidebarOpen }) => {
    if (isSidebarOpen)
      return (
        <X className="w-6 h-6" onClick={() => setIsOpen((value) => !value)} />
      );
    else
      return (
        <List
          className="w-6 h-6"
          onClick={() => setIsOpen((value) => !value)}
        />
      );
  };
  SidebarToggleIcon.propTypes = {
    isSidebarOpen: PropTypes.bool,
  };

  const NetworkFPLogo = () => (
    <div
      className={classNames(
        "flex items-center w-56 justify-start h-12 bg-white",
        !hideSidebar && "lg:hidden",
        isLogoClickable && "cursor-pointer"
      )}
    >
      <img
        alt="network fp logo"
        src={networkfp_logo}
        className="h-9 lg:h-12"
        onClick={onLogoClick}
      />
    </div>
  );

  const ShowBreadCrumb = () => (
    <div
      className={classNames(
        "hidden lg:flex justify-start flex-col",
        avatar && "flex items-start"
      )}
    >
      <Text
        content={title}
        type="title"
        size="text-2xl"
        className="mb-1 leading-7 tracking-tighter line-clamp-1 text-theme-black-color"
      />
      {!_.isEmpty(breadCrumbData) && (
        <Breadcrumb pages={breadCrumbData} type="chevron" />
      )}
    </div>
  );

  return (
    <div className={classNames("relative h-full shadow-sm w-full", className)}>
      <div className="flex items-center h-full pl-6 pr-6 bg-white border-0 border-b border-l-0 lg:border-b-0 lg:border-l sm:flex-nowrap border-theme-gray-300">
        {!hideSidebar && (
          <div
            className={classNames(
              "flex items-center justify-center w-6 h-6 mr-3",
              !hideSidebar && "lg:hidden"
            )}
          >
            <SidebarToggleIcon isSidebarOpen={isOpen} />
          </div>
        )}
        {!hideHeaderLogo && <NetworkFPLogo />}
        <div className="flex items-center justify-end flex-grow lg:justify-between">
          {!hideHeaderLabel ? <ShowBreadCrumb /> : <div />}
          {!hideProfileDetails && (
            <div className="flex items-center justify-center space-x-2">
              {["active", "expires_soon", "overdue"].includes(
                userDetails.qpfp_certification_status
              ) && (
                <ProfileStats
                  className="hidden cursor-pointer md:flex"
                  type="points"
                  stats={`${
                    userDetails?.pro_member_attributes?.ace_points ?? 0
                  } ACE Points`}
                  onClick={() =>
                    navigate("/qpfp-certificant/ace-points-tracker")
                  }
                />
              )}
              {userDetails.is_pro_member &&
                ["approved", "expires_soon"].includes(
                  userDetails.pm_signup_status
                ) && (
                  <a
                    href={getDirectoryMemberURL(userDetails?.profile_slug)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ProfileStats
                      className="hidden cursor-pointer md:flex"
                      type="views"
                      stats={`${
                        userDetails?.pro_member_attributes?.profile_views ?? 0
                      } Profile Views`}
                    />
                  </a>
                )}

              <div className="flex items-center justify-center flex-shrink-0 mr-3">
                <HeaderNotificationIcon />
              </div>
              <div className="flex items-center self-center flex-shrink-0">
                <HeaderProfileIcon
                  tabs={tabs}
                  openLoginModal={openLoginModal}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpen && mobileSideBarComponent}
    </div>
  );
};

Header.defaultProps = {
  avatar: true,
  hideSidebar: false,
  breadCrumbData: [],
  tabs: [],
  className: "",
  showMore: false,
  title: "Dashboard",
  hideProfileDetails: false,
  openLoginModal: false,
};

Header.propTypes = {
  avatar: PropTypes.bool,
  breadCrumbData: PropTypes.array,
  className: PropTypes.string,
  hideHeaderLabel: PropTypes.bool,
  hideHeaderLogo: PropTypes.bool,
  hideProfileDetails: PropTypes.bool,
  hideSidebar: PropTypes.bool,
  isLogoClickable: PropTypes.bool,
  mobileSideBarComponent: PropTypes.element,
  onLogoClick: PropTypes.func,
  showMore: PropTypes.bool,
  tabs: PropTypes.array,
  title: PropTypes.string,
  openLoginModal: PropTypes.bool,
};

export default Header;
