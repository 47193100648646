/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";

const Tabs = ({
  tabList,
  stickyTabList = false,
  headingLastComponent = undefined,
  middleComponent = undefined,
  tabListClassName = "",
  tabClassName = "",
  panelClassName = "",
  onTabClick = () => {},
}) => {
  const [refs, setRefs] = useState([]);
  useEffect(() => {
    const tempRefs = [];
    tabList.forEach((item, index) => {
      if (!item.hide) tempRefs.push(React.createRef());
    });
    setRefs(tempRefs);
  }, []);
  return (
    <Tab.Group>
      <Tab.List
        id="tab-list"
        className={classNames(
          "flex overflow-x-scroll relative w-full transition-all duration-1000 overflow-y-hidden no-scrollbar items-end",
          stickyTabList ? "sticky top-0 z-[16] pt-1 bg-white" : "",
          tabListClassName
        )}
      >
        {tabList.map((item, h_i) => {
          const { hide = false, heading } = item;
          if (hide) {
            return null;
          }
          return (
            <Tab
              key={h_i}
              ref={refs[h_i]}
              className={({ selected }) =>
                classNames(
                  "font-medium z-10 relative text-lg text-theme-black-color py-1.5 focus:outline-none focus:ring-0",
                  tabClassName,
                  selected &&
                    "bg-gradient-to-t from-theme-orange/25 to-theme-orange/0"
                )
              }
              onClick={(e) => {
                onTabClick(h_i);
                const offsets = e.target.getBoundingClientRect();
                if (
                  offsets.left >= 0 &&
                  offsets.right <=
                    (window.innerWidth || document.documentElement.clientWidth)
                ) {
                  return;
                }
                document
                  .querySelector("#tab-list")
                  ?.scrollTo(offsets.left / 2, 0);
              }}
            >
              {({ selected }) => (
                <>
                  <span className="px-6 md:px-8 min-w-fit whitespace-nowrap">
                    {heading}
                  </span>
                  {selected && (
                    <div className="absolute bottom-0 w-full z-20 rounded-full h-[3px] bg-theme-orange"></div>
                  )}
                </>
              )}
            </Tab>
          );
        })}
        <div className="w-full pb-2">{headingLastComponent}</div>
        <div className="absolute bottom-0 w-full z- 0 rounded-full h-[3px] bg-gray-200"></div>
      </Tab.List>
      {middleComponent}
      <Tab.Panels>
        {tabList.map((item, c_i) => {
          const { hide = false, component } = item;
          if (hide) {
            return null;
          }
          return (
            <Tab.Panel
              key={c_i}
              className={classNames("mt-5 mb-5 outline-none", panelClassName)}
            >
              {component}
            </Tab.Panel>
          );
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};

Tabs.propTypes = {
  headingLastComponent: PropTypes.any,
  middleComponent: PropTypes.any,
  onTabClick: PropTypes.func,
  panelClassName: PropTypes.string,
  stickyTabList: PropTypes.bool,
  tabClassName: PropTypes.string,
  tabList: PropTypes.array,
  tabListClassName: PropTypes.string,
};

export default Tabs;
