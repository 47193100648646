/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Label = forwardRef(({ children, labelFor, className, onClick }, ref) => {
  return (
    <label ref={ref} className={className} htmlFor={labelFor} onClick={onClick}>
      {children}
    </label>
  );
});

Label.defaultProps = { className: "" };

Label.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  labelFor: PropTypes.string,
  onClick: PropTypes.func,
};

export default Label;
