/* eslint-disable new-cap */
import axios from "axios";
import API from "../api/manager";
import config from "../api/config";
import _ from "lodash";

const fileUploadManager = async ({ file, key = "", token }) => {
  try {
    const fileName = file.name
      .substring(0, file.name.lastIndexOf("."))
      .replace(/[^a-zA-Z0-9]/g, "_");
    const extension = _.last(file.name.split("."));
    const file_type = file.type.includes("image")
      ? "image"
      : file.type.includes("application")
      ? "document"
      : "other";
    const result = await API(
      {
        method: "GET",
        url: config.urls.file.upload,
        data: {},
        params: {
          key: `${key}/${fileName}.${extension}`,
        },
      },
      true,
      token
    );

    await axios.put(result.url, file, {
      headers: {
        "Content-Type": "image/*",
      },
    });
    return { signed_url: result.url, file_type: file_type, success: true };
  } catch (e) {
    console.log("error uploading file", e, e.response);
    return { success: false };
  }
};

export default fileUploadManager;
