/* eslint-disable new-cap */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThumbsUp } from "@phosphor-icons/react";
import API from "../api/manager";
import config from "../api/config";

const ContentIcon = ({ isLiked }) => {
  switch (isLiked) {
    case true:
      return (
        <ThumbsUp
          weight="fill"
          className="text-lg md:text-xl text-theme-orange"
        />
      );
    case false:
    default:
      return <ThumbsUp className="text-lg md:text-xl" />;
  }
};

ContentIcon.propTypes = {
  isLiked: PropTypes.bool,
};
const likePost = async (postId, like) => {
  const response = await API(
    {
      method: like ? "POST" : "DELETE",
      url: config.urls.clientConnect.likePost,
      data: {
        entity_id: postId,
        entity_type: "Post",
      },
    },
    true
  );
  return response;
};
const CCActionBar = ({ postId, isLiked, initialLikes = 0 }) => {
  const [isLikedState, setIsLikedState] = useState(isLiked);
  const [likesCount, setLikesCount] = useState(initialLikes);
  const handleLike = () => {
    setIsLikedState(!isLikedState);
    if (isLikedState) {
      setLikesCount(likesCount - 1);
      // Need to handle if API Fails, For which backend needs to throw error on failure
      likePost(postId, false);
    } else {
      setLikesCount(likesCount + 1);
      likePost(postId, true);
    }
  };
  return (
    <div className="flex items-center p-4 space-x-3 bg-white border-t">
      <button
        onClick={() => {
          handleLike();
        }}
      >
        <ContentIcon isLiked={isLikedState} />
      </button>
      <span className="text-sm text-black-color md:text-base">
        {likesCount}
      </span>
    </div>
  );
};
CCActionBar.propTypes = {
  initialLikes: PropTypes.number,
  isLiked: PropTypes.bool,
  postId: PropTypes.any,
};
export default CCActionBar;
