import React from "react";
import { useNavigate } from "@reach/router";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CaretLeft } from "@phosphor-icons/react";
const MobileHeader = ({ heading, className, hideBackButton = false }) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        "flex flex-start h-full min-h-[72px] px-4 items-center gap-4 bg-white shadow-sm",
        className
      )}
    >
      {!hideBackButton && (
        <CaretLeft
          className="w-4 h-4 min-w-[16px] cursor-pointer text-theme-black-color"
          aria-hidden={true}
          onClick={() => navigate(-1)}
        />
      )}

      <div className="text-base font-bold text-theme-black-color">
        {heading}
      </div>
    </div>
  );
};
MobileHeader.defaultProps = { className: "" };

MobileHeader.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.any.isRequired,
  hideBackButton: PropTypes.bool,
};
export default MobileHeader;
