import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";

export const initTrackers = () => {
  if (process.env.REACT_APP_ENV === "PROD") {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_KEY, {}, options);
  }
  mixpanel.init(process.env.REACT_APP_PROD_MIXPANEL_PROJECT_ID, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
};

export const trackPageView = () => {
  if (process.env.REACT_APP_ENV === "PROD") {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    ReactPixel.pageView();
  }
  mixpanel.track_pageview();
};
