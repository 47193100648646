/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import Button from "../../stories/Button";
import { useNavigate } from "@reach/router";
import { CircleWavyCheck, TrendUp } from "@phosphor-icons/react";
import { confettiAnimation } from "../../util/confettiAnimation.js";

const ThankyouScreen = ({ eventDetails, ...props }) => {
  const navigate = useNavigate();
  const confettiCanvas = useRef();

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <div className="relative flex flex-col items-center w-full max-w-3xl p-6 bg-white">
      <div className="flex justify-center w-full max-w-xl text-xl font-semibold leading-none tracking-tight text-center text-theme-black-color">
        Thank You! Your Payment towards {eventDetails.title ?? ""} is
        successful.
      </div>
      <CircleWavyCheck
        size={84}
        weight="fill"
        className="mt-8 mb-10 text-theme-red"
      />
      <div className="flex text-lg font-medium tracking-tight text-theme-black-color">
        {eventDetails.title ?? ""}
      </div>
      <div className="flex mt-1 text-base font-medium text-green-600">
        <TrendUp size={20} className="min-w-[16px] mr-3" /> Your contribution
        will educate {Math.floor((props.amount ?? 0) / 100)} investors.
      </div>
      <div className="grid w-full grid-cols-1 gap-4 mt-6 lg:grid-cols-2">
        <div className="justify-center w-full mx-auto col-span-full lg:w-1/2">
          <Button
            buttonStyle="primary"
            size="xl"
            width="100%"
            onClick={() => {
              navigate("/", { replace: true });
            }}
          >
            {props.isNewUser ? "CONTINUE TO LOGIN" : "CONTINUE TO DASHBOARD"}
          </Button>
        </div>
      </div>
      <canvas ref={confettiCanvas} className="absolute z-50 w-full h-full" />
    </div>
  );
};

ThankyouScreen.propTypes = {
  eventDetails: PropTypes.shape({
    description: PropTypes.string,
    short_title: PropTypes.string,
    title: PropTypes.string,
    whatNextContent: PropTypes.array,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
};

export default ThankyouScreen;
