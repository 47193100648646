/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Validations } from "../../constants/validations";
import TextInput from "../../stories/TextInput";
import CheckList from "../../stories/CheckList";
import Dropdown from "../../stories/Dropdown";
import Button from "../../stories/Button";
import { PromemberService } from "../../services/PromemberService";
import { CustomToast } from "../../stories/Toast";
import { UserService } from "../../services/UserService";

const ShareDetailsScreen = ({
  isNewUser = false,
  currentMembershipDetails = {},
  publicProfileData = {},
  options = {},
  stepProps = {},
  userTempTokenRef = undefined,
  entityProps = {},
  loading = true,
  onUpdate,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const fetchInitialData = () => {
    setInitialValues((prev) => ({
      firm_name:
        currentMembershipDetails?.firm_name ??
        publicProfileData?.firm?.name ??
        "",
      designation: currentMembershipDetails?.designation ?? "",
      address_attributes: {
        id: currentMembershipDetails?.address?.id ?? undefined,
        line_1:
          currentMembershipDetails?.address?.line_1 === "-"
            ? ""
            : currentMembershipDetails?.address?.line_1 ?? "",
        city:
          currentMembershipDetails?.address?.city ??
          publicProfileData?.city?.name ??
          "",
        city_id:
          currentMembershipDetails?.address?.city_id ??
          publicProfileData?.city?.id ??
          "",
        pin:
          currentMembershipDetails?.address?.pin === "-"
            ? ""
            : currentMembershipDetails?.address?.pin ?? "",
        state:
          currentMembershipDetails?.address?.state ??
          publicProfileData?.state?.name ??
          "",
        state_id:
          currentMembershipDetails?.address?.state_id ??
          publicProfileData?.state?.id ??
          "",
      },
      graduation_id: publicProfileData.graduation_id,
      professional_ids: publicProfileData.professional_ids ?? [],
      industry_ids: publicProfileData.industry_ids ?? [],
      entity_type:
        currentMembershipDetails.entity_type ?? entityProps.entity_type,
      entity_id: currentMembershipDetails?.entity_id,
    }));
  };

  const onFormSubmit = async (values) => {
    const {
      address_attributes,
      entity_id,
      entity_type,
      graduation_id,
      professional_ids,
      industry_ids,
      ...formValues
    } = values;

    try {
      let postBody = { ...formValues };
      const { line_1, pin, city_id } = address_attributes;
      postBody = { ...postBody, address_attributes: { line_1, pin, city_id } };

      postBody["entity_type"] = values.entity_type ?? entityProps.entity_type;
      const result = await PromemberService.updatePromember(
        postBody,
        entity_type,
        entity_id,
        undefined,
        userTempTokenRef.current
      );
      await UserService.updateAdditionalDetails({
        user: {
          graduation_id,
          professional_ids,
          industry_ids,
        },
      });
      CustomToast.success(result.message ?? "");
      stepProps.handleNext();
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0] ?? "");
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [loading]);

  return (
    <div>
      <div className={classNames("flex flex-col w-full items-start")}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            Validations.membership.shareDetailsScreenValidationSchema
          }
          onSubmit={onFormSubmit}
        >
          {({ values, ...formProps }) => {
            return (
              <Form className="w-full">
                <div className="flex flex-col w-full gap-6 mt-2 bg-white rounded-sm shadow-sm lg:shadow-none">
                  <div className="flex flex-col w-full">
                    <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
                      Your Details
                    </div>
                    <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
                      Fill firm & education details
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <TextInput
                      name="firm_name"
                      label="Firm Name"
                      placeholder="XYZ Company"
                      id="firm_name"
                      type="text"
                    />
                    <TextInput
                      name="designation"
                      label="Designation"
                      placeholder="Personal Finance Professional"
                      id="designation"
                      type="text"
                    />
                    <TextInput
                      name="address_attributes.line_1"
                      label="Communication Address"
                      placeholder="12/14, Vaju Kotak Marg, Ballard Estate"
                      id="line_1"
                      type="text"
                      isRequired
                    />
                    <TextInput
                      name="address_attributes.pin"
                      label="Pincode"
                      placeholder="Pincode"
                      id="pin"
                      type="text"
                      isRequired
                    />
                  </div>
                  <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
                    <div className="col-span-full md:col-span-1">
                      <Dropdown
                        name="graduation_id"
                        id="graduation_id"
                        type="text"
                        label="Which of the following graduation stream have you completed ?"
                        placeholder="Select Graduation Stream"
                        options={options["graduation"]}
                        displayCurrentOption={true}
                        displayKey="value"
                        idKey="id"
                        isRequired
                      />
                    </div>
                    <div className="col-span-full">
                      <CheckList
                        label="Which Professional Exams / Qualifications / Certifications have you completed ?"
                        isRequired={entityProps.entity_type !== "qpfp"}
                        name="professional_ids"
                        formProps={formProps}
                        values={values}
                        options={options["professional"]}
                        displayKey="value"
                        idKey="id"
                      />
                    </div>
                    <div className="col-span-full">
                      <CheckList
                        label="Which Industry Exams have you passed ?"
                        isRequired={entityProps.entity_type !== "qpfp"}
                        name="industry_ids"
                        formProps={formProps}
                        values={values}
                        options={options["industry"]}
                        displayKey="value"
                        idKey="id"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                    <Button
                      buttonStyle="gray"
                      height="40px"
                      className="order-2 lg:order-1"
                      onClick={async () => {
                        stepProps.handleBack();
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      buttonStyle="primary"
                      height="40px"
                      width="100%"
                      className="order-1 lg:order-2"
                      disabled={formProps.isSubmitting}
                      type="submit"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      NEXT
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

ShareDetailsScreen.propTypes = {
  currentMembershipDetails: PropTypes.object,
  entityProps: PropTypes.object,
  isNewUser: PropTypes.bool,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
  stepProps: PropTypes.object,
  userTempTokenRef: PropTypes.any,
};

export default ShareDetailsScreen;
