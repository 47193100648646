import React from "react";
import PropTypes from "prop-types";
import { File, ImageSquare, PlayCircle } from "@phosphor-icons/react";
const ContentIcon = ({ type }) => {
  switch (type) {
    case "image":
      return <ImageSquare weight="fill" className="text-lg md:text-xl" />;
    case "video":
      return <PlayCircle weight="fill" className="text-lg md:text-xl" />;
    case "pdf":
    case "text":
    default:
      return <File weight="fill" className="text-lg md:text-xl" />;
  }
};

ContentIcon.propTypes = {
  type: PropTypes.string,
};
const CCHeader = ({ attachments, title }) => {
  return (
    <div>
      <div className="flex items-center p-4 space-x-3 bg-white border-b">
        {attachments.length > 0 ? (
          attachments.map((attachment, index) => (
            <ContentIcon
              type={attachment.file_type ?? "text"}
              key={attachment.id}
            />
          ))
        ) : (
          <ContentIcon />
        )}
        <span className="text-sm text-black-color md:text-base">{title}</span>
      </div>
    </div>
  );
};
CCHeader.propTypes = {
  attachments: PropTypes.array,
  title: PropTypes.string,
};
export default CCHeader;
