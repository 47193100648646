import React, { useEffect, useRef, useState } from "react";
import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import { useCourseCurriculum } from "../../../services/CourseDetails";
import Loader from "../../Shimmer/Loader";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import ShowModules from "../../Programs/ShowModules";
import { UserService } from "../../../services/UserService";

const QPFPCourseware = () => {
  const curriculumRef = useRef();
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const {
    curriculum,
    loading: isLoading,
    error,
    mutate: mutateCurriculum,
  } = useCourseCurriculum(status?.qpfp_courseware_id, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });

  const fetchData = async () => {
    const response = await UserService.fetchQPFPCertificationStatus();
    setStatus(response);
    setLoading(false);
  };

  useEffect(() => {
    curriculumRef.current = curriculum;
  }, [curriculum]);

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading || loading) {
    return (
      <QPFPSkeleton>
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </QPFPSkeleton>
    );
  }
  if (error) {
    return (
      <QPFPSkeleton>
        <ErrorScreen />
      </QPFPSkeleton>
    );
  }
  return (
    <QPFPSkeleton>
      <div className="flex flex-col w-full py-10 relative z-0">
        <div className="font-bold text-2xl  px-6">
          QPFP Brand Resources and Guidelines
        </div>
        <div className="text-sm text-theme-black-300 mt-3 mb-10 px-6">
          As a Qualified Personal Finance Professional (QPFP), you have earned
          the right to proudly display your certification. This guide provides
          practical examples and tips on where and how to use the QPFP mark,
          logo, and designation to enhance your professional presence and
          credibility.
        </div>
        <ShowModules
          curriculum={curriculum}
          curriculumRef={curriculumRef}
          modules={curriculum.modules}
          isMembershipBenefitVisible={false}
          eventDetails={{}}
          allowUserToSkipRenewal={true}
          availablePartPaymentOptions={undefined}
          remainingAmount={0}
          courseSessionStats={{}}
          courseName={curriculum.name}
          entityType={curriculum.course_type}
          entityId={curriculum.entity_id}
          refreshCallback={() => {
            mutateCurriculum();
          }}
          sessionCountList={null}
          bgColorClassList={[
            "bg-white",
            "lg:bg-gray-100 bg-white drop-shadow-sm",
          ]}
        />
      </div>
    </QPFPSkeleton>
  );
};

export default QPFPCourseware;
