import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import _ from "lodash";

const ProgressBar = ({
  className,
  topLeftLabel,
  topRightLabel,
  percent,
  darkBg = false,
  showProgress,
  isCircular,
  title,
  progressBarClassname,
}) => {
  const LinearProgress = () => {
    return (
      <div className={classNames("flex flex-col w-full", className)}>
        <div
          className={classNames(
            "flex justify-between",
            showProgress ? "mb-3" : ""
          )}
        >
          {topLeftLabel && (
            <div className="flex items-center">{topLeftLabel}</div>
          )}
          {(showProgress || topRightLabel) && (
            <div className="flex justify-end ml-0">{topRightLabel}</div>
          )}
        </div>
        {showProgress && (
          <div
            className={classNames(
              "relative h-1",
              darkBg ? "bg-theme-gray-700" : "bg-theme-gray-200",
              progressBarClassname
            )}
          >
            <div
              className={`absolute h-full bg-theme-orange w-full`}
              style={{ maxWidth: `${percent}%` }}
            ></div>
          </div>
        )}
      </div>
    );
  };
  const CircularProgress = () => {
    return (
      <CircularProgressbarWithChildren
        value={percent}
        strokeWidth={10}
        styles={buildStyles({
          pathColor: "#F36C04",
          trailColor: "#CCCCCC",
          pathTransitionDuration: 0.5,
          strokeLinecap: "round",
        })}
      >
        <div className="text-xs">{title ?? percent}</div>
      </CircularProgressbarWithChildren>
    );
  };

  if (isCircular) return <CircularProgress />;
  return <LinearProgress />;
};

ProgressBar.default = {
  showProgress: true,
  isCircular: false,
};
ProgressBar.propTypes = {
  showProgress: PropTypes.bool,
  showTopLeftLabel: PropTypes.bool,
  showTopRightLabel: PropTypes.bool,
  className: PropTypes.string,
  topLeftLabel: PropTypes.any,
  topRightLabel: PropTypes.any,
  percent: PropTypes.number.isRequired,
  darkBg: PropTypes.bool,
  isCircular: PropTypes.bool,
  title: PropTypes.string,
  progressBarClassname: PropTypes.string,
};

export default ProgressBar;

export const QPFPProgressBar = ({
  label,
  userPercent,
  batchPercent,
  level,
  barHeight = "h-3",
  showPosition = true,
  className = "",
}) => {
  const bgColors = [
    "bg-theme-red/20",
    "bg-theme-orange/20",
    "bg-theme-yellow/20",
    "bg-green-600/20",
  ];
  const userProgressColors = [
    "bg-theme-red",
    "bg-theme-orange",
    "bg-theme-yellow",
    "bg-green-600",
  ];
  const batchProgressColors = [
    "bg-theme-red/20",
    "bg-theme-orange/20",
    "bg-theme-yellow/20",
    "bg-green-600/20",
  ];

  return (
    <div
      className={classNames(
        "flex w-full items-start flex-col gap-0.5",
        className
      )}
    >
      {!_.isEmpty(label) && (
        <div className="text-xs font-medium text-theme-black-color">
          {label}
        </div>
      )}
      <div
        className={classNames(
          "relative rounded-full w-full z-0",
          barHeight,
          bgColors[(level - 1) % bgColors.length]
        )}
      >
        <div
          className={classNames(
            "absolute top-0 left-0 w-full h-full z-1 rounded-full",
            batchPercent < 100 && "rounded-r-none",
            batchProgressColors[(level - 1) % batchProgressColors.length]
          )}
          style={{ maxWidth: `${batchPercent}%` }}
        >
          {batchPercent !== userPercent && (
            <PositionPointer
              percent={batchPercent}
              label="batch"
              showPosition={showPosition}
            />
          )}
        </div>
        <div
          className={classNames(
            "absolute top-0 left-0 w-full h-full z-2 rounded-full",
            userPercent < 100 && "rounded-r-none",
            userProgressColors[(level - 1) % userProgressColors.length]
          )}
          style={{ maxWidth: `${userPercent}%` }}
        >
          {batchPercent === userPercent ? (
            <PositionPointer
              percent={userPercent}
              label="you & batch"
              showPosition={showPosition}
            />
          ) : (
            <PositionPointer
              percent={userPercent}
              label="you"
              showPosition={showPosition}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const PositionPointer = ({ percent, label, showPosition }) => {
  // const className =
  //   label === "batch"
  //     ? "flex-col-reverse -ml-6 top-0"
  //     : "flex-col -ml-[18px] bottom-0";

  if (percent === 0 || !showPosition) return null;
  return (
    <>
      <div
        className={classNames(
          "absolute right-0",
          label === "batch" ? "top-6" : "bottom-6"
        )}
      >
        <div
          className={classNames(
            "flex items-center w-fit ml-[50%]",
            "bg-theme-gray-500 capitalize rounded-full px-2 py-1 text-xs font-medium text-theme-black text-center whitespace-nowrap"
          )}
        >
          {label}
        </div>
      </div>
      <div
        className={classNames(
          "w-[1px] h-6 bg-theme-gray-500 mr-0 absolute right-0",
          label === "batch" ? "top-0" : "bottom-0"
        )}
      />
    </>
  );
};

PositionPointer.propTypes = {
  label: PropTypes.string,
  percent: PropTypes.number,
  showPosition: PropTypes.bool,
};

QPFPProgressBar.propTypes = {
  barHeight: PropTypes.string,
  batchPercent: PropTypes.number,
  className: PropTypes.string,
  label: PropTypes.string,
  level: PropTypes.number,
  showPosition: PropTypes.bool,
  userPercent: PropTypes.number,
};
