/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PaymentScreen from "./PaymentScreen";
import ShareDetailsScreen from "./ShareDetailsScreen";
import DocumentUploadScreen from "./DocumentUploadScreen";
import SubmittedScreen from "./SubmittedScreen";
import RSVPDetails from "./RSVPDetails";
import { useProfile } from "../../context/userProvider";
import _ from "lodash";
import { useNavigate } from "@reach/router";

const MembershipFormContainer = ({
  className = "",
  isNewUser = false,
  currentMembershipDetails = {},
  publicProfileData = {},
  prefillData = {},
  userTempTokenRef = undefined,
  options = {},
  entityProps = {},
  course_id,
  onUpdate,
  loading = true,
  hasRSVP = false,
}) => {
  const { userDetails } = useProfile();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    if (currentStep === 4) {
      navigate("/", { replace: true });
    }
    window.scroll(0, 0);

    if (currentStep === 2 && !hasRSVP) {
      setCurrentStep(4);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
    onUpdate();
  };
  const handleBack = () => {
    if (currentStep === 0) return;
    window.scroll(0, 0);
    if (currentStep === 4 && !hasRSVP) {
      setCurrentStep(2);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (
        ((entityProps.entity_type === "pro_member" &&
          ["approved", "expiring_soon", "provisional"].includes(
            userDetails?.pm_signup_status
          )) ||
          (["community_member", "provisional_member"].includes(
            entityProps.entity_type
          ) &&
            ["approved", "expiring_soon"].includes(
              userDetails?.provisonal_member_signup_status
            ))) &&
        hasRSVP
      ) {
        setCurrentStep(4);
        return;
      }
      if (!_.isEmpty(currentMembershipDetails)) {
        setCurrentStep(currentMembershipDetails.next_step - 1);
      }
    }
  }, [loading]);

  return (
    <div className={className}>
      <div className="flex flex-col items-start w-full gap-4 lg:py-4 lg:bg-white">
        {currentStep < 4 && (
          <>
            <div className="w-full p-3 bg-white rounded shadow-sm lg:rounded-none lg:shadow-none lg:px-4 lg:pb-2">
              <Stepper
                currentStep={currentStep}
                steps={[
                  "Make Payment",
                  "Share Details",
                  "Submit Documents",
                  ...(hasRSVP ? ["RSVP"] : []),
                ]}
              />
            </div>
            <div className="h-[1px] hidden lg:block bg-neutral-300 w-full" />
          </>
        )}
        <div className="w-full p-4 pt-0 bg-white rounded shadow-sm lg:rounded-none lg:shadow-none">
          {currentStep === 0 && (
            <PaymentScreen
              isNewUser={isNewUser}
              currentMembershipDetails={currentMembershipDetails}
              publicProfileData={publicProfileData}
              options={options}
              userTempTokenRef={userTempTokenRef}
              stepProps={{
                currentStep,
                handleNext,
                handleBack,
              }}
              entityProps={entityProps}
              loading={loading}
              prefillData={prefillData}
              course_id={course_id}
              onUpdate={onUpdate}
            />
          )}
          {currentStep === 1 && (
            <ShareDetailsScreen
              isNewUser={isNewUser}
              currentMembershipDetails={currentMembershipDetails}
              publicProfileData={publicProfileData}
              options={options}
              userTempTokenRef={userTempTokenRef}
              stepProps={{
                currentStep,
                handleNext,
                handleBack,
              }}
              entityProps={entityProps}
              prefillData={prefillData}
              loading={loading}
              onUpdate={onUpdate}
            />
          )}
          {currentStep === 2 && (
            <DocumentUploadScreen
              isNewUser={isNewUser}
              currentMembershipDetails={currentMembershipDetails}
              publicProfileData={publicProfileData}
              options={options}
              userTempTokenRef={userTempTokenRef}
              stepProps={{
                currentStep,
                handleNext,
                handleBack,
              }}
              entityProps={entityProps}
              prefillData={prefillData}
              loading={loading}
              onUpdate={onUpdate}
            />
          )}
          {currentStep === 3 && (
            <RSVPDetails
              stepProps={{
                currentStep,
                handleNext,
                handleBack,
              }}
              entity_type={entityProps.entity_type}
              isNewUser={isNewUser}
              userTempTokenRef={userTempTokenRef}
              publicProfileData={publicProfileData}
              loading={loading}
              currentMembershipDetails={currentMembershipDetails}
            />
          )}
          {currentStep === 4 && (
            <SubmittedScreen
              stepProps={{
                currentStep,
                handleNext,
                handleBack,
              }}
              entity_type={entityProps.entity_type}
              isNewUser={isNewUser}
              hasRSVP={hasRSVP}
              userTempTokenRef={userTempTokenRef}
              loading={loading}
              email={currentMembershipDetails.email}
            />
          )}
        </div>
      </div>
    </div>
  );
};

MembershipFormContainer.propTypes = {
  className: PropTypes.string,
  course_id: PropTypes.any,
  currentMembershipDetails: PropTypes.object,
  entityProps: PropTypes.object,
  hasRSVP: PropTypes.bool,
  isNewUser: PropTypes.bool,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  prefillData: PropTypes.object,
  publicProfileData: PropTypes.object,
  userTempTokenRef: PropTypes.any,
};

const Stepper = ({ className, currentStep, steps = [] }) => {
  return (
    <div className={classNames("flex w-full", className)}>
      {steps.map((step, index) => (
        <Step
          label={step}
          index={index}
          key={step}
          totalSteps={steps.length}
          isCompleted={index < currentStep}
          isActive={index === currentStep}
        />
      ))}
    </div>
  );
};

Stepper.propTypes = {
  className: PropTypes.any,
  currentStep: PropTypes.any,
  steps: PropTypes.array,
};

const Step = ({
  label = "",
  index = 0,
  totalSteps = 1,
  isCompleted = false,
  isActive = false,
}) => {
  return (
    <div className="relative flex flex-col items-start w-full gap-1">
      {index < totalSteps - 1 && (
        <div
          className={classNames(
            "w-full top-2 absolute h-[2px]",
            isCompleted ? "bg-theme-orange" : "bg-neutral-100"
          )}
        />
      )}
      <div
        className={classNames(
          "z-10 flex items-center justify-center w-4 h-4 rounded-full bg-neutral-100",
          isCompleted && "border border-theme-orange"
        )}
      >
        {(isActive || isCompleted) && (
          <div className="w-2.5 h-2.5 bg-theme-orange rounded-full" />
        )}
      </div>
      <div className="mr-2 text-base font-medium leading-5">{label}</div>
    </div>
  );
};

Step.propTypes = {
  index: PropTypes.number,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
  label: PropTypes.string,
  totalSteps: PropTypes.number,
};

export default MembershipFormContainer;
