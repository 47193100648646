import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const StepperProgressBar = ({
  className,
  currentStep,
  totalSteps,
  percentCompleted,
}) => {
  const [myarr, setMyArr] = useState([]);
  const [percent, setPercent] = useState(percentCompleted);
  useEffect(() => {
    const slot = parseInt(100 / totalSteps);
    if (percentCompleted === null) {
      setPercent(slot * currentStep);
    } else {
      setPercent(percentCompleted);
    }
    if (totalSteps > 0) {
      setMyArr([]);
      [...Array(totalSteps - 1).keys()].forEach((i) => {
        setMyArr((val) => [...val, slot * (i + 1)]);
      });
    }
  }, [currentStep, totalSteps, percentCompleted]);

  return (
    <div className={classNames(className, "relative h-1 bg-theme-gray-200")}>
      <div
        className="absolute z-10 h-full bg-theme-orange"
        style={{ width: `${percent}%` }}
      ></div>

      {myarr.map((e, index) => {
        return (
          <div
            key={index}
            className="absolute z-20 h-full w-0.5 bg-theme-gray-200"
            style={{ left: `${e}%` }}
          ></div>
        );
      })}
    </div>
  );
};

StepperProgressBar.default = {
  currentStep: 1,
  totalSteps: 5,
  percentCompleted: 0,
  className: "",
};
StepperProgressBar.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  percentCompleted: PropTypes.number,
};

export default StepperProgressBar;
