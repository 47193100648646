import PropTypes from "prop-types";
import React, { useState } from "react";
import CommentInput from "../../../../stories/CommentInput";
import Ratings from "../../../../stories/Ratings";
import RatingsCard from "../../../../stories/RatingsCard";
import Text from "../../../../stories/Text";
import _ from "lodash";
import { useProfile } from "../../../../context/userProvider";
import classNames from "classnames";
import CommentCard from "../../../../stories/CommentCard";

const ReviewsTab = ({
  reviews,
  onPostReview,
  authorName = "Admin",
  shouldAllowReview,
  hasRatedBefore = false,
}) => {
  const [rating, setRating] = useState(0.0);
  const { userDetails } = useProfile();
  const [showAll, setShowAll] = useState(false);
  const [showRatingError, setShowRatingError] = useState(false);
  return (
    <div className="mb-10 space-y-5 transition-all duration-500">
      {_.take(reviews, showAll ? reviews.length : 3)?.map((review, index) => (
        <div key={review.id} className="flex flex-col w-full">
          <RatingsCard
            id={review.id}
            profileSlug={review.profile_slug}
            name={review.reviewer_name}
            userImage={review.reviewer_picture ?? ""}
            time={review.created_at}
            content={review.review}
            rating={review.rating_value}
          />
          {review.admin_reply && (
            <div className="w-full mt-4">
              <CommentCard
                isCommentReply={false}
                id={review.id}
                userName={"Admin"}
                userImage={""}
                location={"NFP Team"}
                time={review.updated_at}
                content={review.admin_reply}
                canReply={false}
                isReply={{
                  first_name: review.first_name,
                  last_name: review.last_name,
                  profile_slug: review.profile_slug,
                }}
                replyCallback={null}
              />
            </div>
          )}
        </div>
      ))}
      {reviews.length > 3 && (
        <button
          onClick={() => setShowAll((val) => !val)}
          className="py-1 text-sm font-semibold text-theme-red"
        >
          {showAll ? "SEE LESS" : "SEE MORE"}
        </button>
      )}
      {!(shouldAllowReview && !hasRatedBefore) && (
        <Text
          type="text"
          size="text-sm md:text-base"
          className={classNames("font-semibold text-theme-black-color pb-10")}
          content={
            shouldAllowReview
              ? hasRatedBefore
                ? "You have reviewed this session."
                : ""
              : "You can write review once you complete this session."
          }
        />
      )}
      {shouldAllowReview && !hasRatedBefore && (
        <React.Fragment>
          <Text
            type="text"
            size="text-sm md:text-base"
            className="font-semibold text-theme-black-color"
            content="Rate this Session"
          />
          <div className="">
            <Ratings
              onChange={(val) => setRating(val)}
              ratings={rating}
              enabled={true}
              className={classNames(
                !(showRatingError && rating < 1.0) && "leading-none",
                "text-2xl md:text-3xl"
              )}
            />
            {showRatingError && rating < 1.0 && (
              <div className="flex w-full mt-1 text-sm text-left text-red-700">
                Please add ratings
              </div>
            )}
          </div>
          <CommentInput
            userImage={userDetails.profile_picture?.signed_url ?? ""}
            buttonText="SUBMIT"
            textErrorMessage="Please add a review"
            placeholder={"Share your feedback here"}
            isReply={false}
            onSubmit={async (value, hasError) => {
              if (rating < 1.0) {
                setShowRatingError(true);
                throw Error("rating not added");
              } else {
                setShowRatingError(false);
              }
              await onPostReview({ reviews: value, rating_value: rating });
              setRating(0.0);
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

ReviewsTab.propTypes = {
  authorName: PropTypes.string,
  hasRatedBefore: PropTypes.bool,
  onPostReview: PropTypes.func,
  reviews: PropTypes.array,
  shouldAllowReview: PropTypes.bool,
};

export default ReviewsTab;
