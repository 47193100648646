/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import React, { useEffect, useRef } from "react";
import AppSkeleton from "../AppSkeleton";
import { Formik } from "formik";
import Dropdown from "../../stories/Dropdown";
import API from "../../api/manager";
import config from "../../api/config";
import ClientConnectCard from "../../stories/ClientConnectCard";
import { useLocation, useNavigate } from "@reach/router";
import queryString from "qs";
import classNames from "classnames";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import NFPLogo from "../../assets/images/nfp.png";
import usePosts from "../../hooks/usePost";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import SearchBar from "../../stories/SearchBar";
import { CustomToast } from "../../stories/Toast";
import { useProfile } from "../../context/userProvider";
import CustomLink from "../../stories/Link";
const initialValues = {
  filter: null,
};

const filterOptions = [
  { filter: "All", value: "" },
  { filter: "Images", value: "image" },
  { filter: "Videos", value: "video" },
  { filter: "Documents", value: "document" },
  { filter: "Text Content", value: "text_content" },
];

const likePost = async (postId) => {
  const response = await API(
    {
      method: "POST",
      url: config.urls.clientConnect.likePost,
      data: {
        entity_id: postId,
        entity_type: "Post",
      },
    },
    true
  );
  return response;
};

const NFPResources = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useProfile();
  const searchTimeoutRef = useRef();
  const parsed = queryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );

  const [filter, setFilter] = React.useState("");
  const [page, setPageNumber] = React.useState(parsed.pageNo ?? 1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const { loading, error, posts, hasMore } = usePosts("resource", filter, page);

  const pageData = [
    { name: "Dashboard", to: "/", current: false },
    { name: "My Programs", to: "/programs", current: false },
    { name: "NFP Resources", current: true },
  ];

  const observer = React.useRef();
  const lastPostElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (userDetails?.pm_signup_status === "overdue") {
      CustomToast.error(
        "Your ProMembership has expired. Please renew it to continue learning."
      );
      navigate(
        `/programs/${props.location?.state?.curriculumData?.course?.id}`
      );
    }
  }, [userDetails]);

  return (
    <AppSkeleton
      showNfpLogo={true}
      pageLabel={"My Programs"}
      hideSidebar={true}
      breadCrumbData={pageData}
      showMobileHeader={true}
      secondaryHeading="NFP Resources"
    >
      <div
        className={classNames(
          "flex flex-col justify-start py-12 pt-0 lg:pt-12 sm:px-6 lg:px-8 min-h-[80vh]"
        )}
      >
        <div className="mx-6 md:mx-auto md:w-full md:max-w-3xl xl:max-w-6xl">
          {posts && (
            <div>
              <div className="pb-2 lg:grid lg:max-w-3xl lg:grid-cols-2">
                <div className="hidden lg:flex lg:flex-col lg:w-fit">
                  <span className="text-3xl font-bold text-theme-black-color pb-1.5">
                    NFP Resources
                  </span>
                  <div className="flex">
                    <img alt="NFP" className="w-8 h-8 mr-2.5" src={NFPLogo} />
                    <span className="self-center text-sm font-semibold text-theme-black-color">
                      Network FP
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end justify-between mb-3 space-y-2 md:flex-row md:space-y-0 md:space-x-6">
                <SearchBar
                  className="w-full basis-2/3"
                  backgroundColor="bg-white !border-gray-200 !border-1 !border-solid"
                  placeholder={"Search here..."}
                  searchTerm={searchQuery}
                  onSearch={(val) => {
                    clearTimeout(searchTimeoutRef.current);
                    searchTimeoutRef.current = setTimeout(() => {
                      setSearchQuery(val);
                      setPageNumber(1);
                    }, 700);
                  }}
                />
                <div className="self-center w-full basis-1/3 justify-self-end">
                  <Formik initialValues={initialValues} enableReinitialize>
                    <Dropdown
                      name="filter"
                      id="filter"
                      type="text"
                      label="Filter by"
                      placeholder="None"
                      height={"h-12"}
                      options={filterOptions}
                      keepShadow={false}
                      buttonBgColor="bg-white"
                      displayCurrentOption={true}
                      displayKey="filter"
                      idKey="value"
                      onChange={(e) => {
                        setFilter(e ?? "");
                        setPageNumber(1);
                      }}
                    />
                  </Formik>
                </div>
              </div>
              <div className="pb-6 ">
                <b>Steps to effectively use the Resources folder:-</b>
                <ol className="ml-4 text-sm list-decimal text-theme-black-300">
                  <li className="pl-1">
                    {" "}
                    Search for a material you might be needing.
                  </li>
                  <li className="pl-1">
                    {" "}
                    Download to your system or refer directly here
                  </li>
                  <li className="pl-1">
                    {" "}
                    Check the complete Index here:&nbsp;
                    <CustomLink
                      target="_blank"
                      href="https://bit.ly/NFP-Resources-Index"
                      textColor="text-theme-red-300"
                      activeTextColor="text-red-700"
                      showUnderLine={false}
                      underLineOnlyOnHover={true}
                      className="leading-none"
                    >
                      https://bit.ly/NFP-Resources-Index
                    </CustomLink>
                  </li>
                  <li className="pl-1">
                    {" "}
                    Link to share feedback on existing Tools or contribute a new
                    Tool - &nbsp;
                    <CustomLink
                      target="_blank"
                      href="https://bit.ly/NFP-Contribute-Tools-Resources"
                      textColor="text-theme-red-300"
                      activeTextColor="text-red-700"
                      showUnderLine={false}
                      underLineOnlyOnHover={true}
                      className="leading-none"
                    >
                      https://bit.ly/NFP-Contribute-Tools-Resources
                    </CustomLink>
                  </li>
                  <li className="pl-1">
                    {" "}
                    NFP Exchange Archives- - &nbsp;
                    <CustomLink
                      target="_blank"
                      href="https://bit.ly/NFP-Exchange-Archives"
                      textColor="text-theme-red-300"
                      activeTextColor="text-red-700"
                      showUnderLine={false}
                      underLineOnlyOnHover={true}
                      className="leading-none"
                    >
                      https://bit.ly/NFP-Exchange-Archives
                    </CustomLink>
                  </li>
                </ol>
              </div>
              {!loading && posts?.length === 0 && (
                <div className="w-full p-3 mt-6 text-center lg:p-6">
                  <EmptyState
                    heading="No post available"
                    subHeading={
                      searchQuery === ""
                        ? "We will add posts soon. Please come back after sometime"
                        : "No result found. Please try again with another keywords."
                    }
                    cta={<></>}
                    image={
                      <img
                        alt="emptyStatePlaceHolder"
                        className="py-9 max-h-80"
                        src={EmptyStatePlaceHolder}
                      />
                    }
                  />
                </div>
              )}
              {posts?.length > 0 && (
                <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                  {posts.map((post, index) => {
                    if (posts.length === index + 1)
                      return (
                        <div
                          key={post.id}
                          ref={lastPostElementRef}
                          className="pb-3"
                        >
                          <ClientConnectCard
                            postId={post.id}
                            title={post.title}
                            isLiked={post.is_user_liked ?? false}
                            likesCount={post.votes_count ?? 0}
                            attachments={post.attachments}
                            textContent={post.text_content}
                            onLike={() => {
                              likePost(post.id);
                            }}
                          />
                        </div>
                      );
                    return (
                      <div key={post.id} className="pb-3">
                        <ClientConnectCard
                          postId={post.id}
                          title={post.title}
                          isLiked={post.is_user_liked ?? false}
                          likesCount={post.votes_count ?? 0}
                          attachments={post.attachments}
                          textContent={post.text_content}
                          onLike={() => {
                            likePost(post.id);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {loading && (
                <div className="mt-5">
                  <Loader />
                </div>
              )}
              <div>{!loading && error && <ErrorScreen />}</div>
            </div>
          )}
        </div>
      </div>
    </AppSkeleton>
  );
};
export default NFPResources;
