/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const memberAttribute = (obj) => {
  return {
    member: obj,
  };
};
const userAttribute = (obj) => {
  if (obj)
    return {
      user: obj,
    };
  else return undefined;
};

const fetchPromember = (entity_type = "pro_member") => {
  return API(
    {
      method: "GET",
      url: config.urls.promember.fetch_profile(entity_type),
      data: {},
    },
    true
  );
};
const createPromember = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.promember.create_profile,
      data: memberAttribute(data),
    },
    true
  );
};

const updatePromember = (data, entity_type, entity_id, user_data, token) => {
  return API(
    {
      method: "PUT",
      url: config.urls.promember.update_profile,
      data: {
        ...memberAttribute(data),
        ...userAttribute(user_data),
        entity_type: entity_type,
        entity_id: entity_id,
      },
    },
    true,
    token
  );
};

const updatePromemberDirectRegister = (
  data,
  entity_type,
  entity_id,
  user_data
) => {
  return API(
    {
      method: "PUT",
      url: config.urls.promember.direct_register,
      data: {
        ...memberAttribute(data),
        ...userAttribute(user_data),
        entity_type: entity_type,
        entity_id: entity_id,
      },
    },
    true
  );
};

export const PromemberService = {
  fetchPromember,
  createPromember,
  updatePromember,
  updatePromemberDirectRegister,
};
