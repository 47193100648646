import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PublicProfileCard from "../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../services/PublicProfileService";
import _ from "lodash";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import FormBuilder from "../../stories/FormBuilder";
import { Validations } from "../../constants/validations";

const ComplimentaryServicesCard = ({
  className,
  publicProfileData,
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialValues, setInitialValues] = useState({ solutions: [] });

  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  useEffect(() => {
    setInitialValues((prevState) => ({
      solutions: (
        publicProfileData.professional_detail?.complementary_services ?? []
      ).map((e) => e.id),
    }));
  }, [publicProfileData]);

  const getSolutionLabel = (id) => {
    return (
      <span>
        {options["complementary_service"]?.filter((e) => e.id === id)[0]
          ?.value ?? ""}
      </span>
    );
  };
  useEffect(() => {
    setIsEmpty(
      Object.values(initialValues).filter((val) => !_.isEmpty(val)).length === 0
    );
  }, [initialValues]);

  const onFormSubmit = async (values) => {
    // same shape as initial values
    const postBody = {
      professional_detail_attributes: {
        complementary_services_ids: values.solutions,
      },
    };
    if (publicProfileData["professional_detail"]?.id) {
      postBody["professional_detail_attributes"]["id"] =
        publicProfileData["professional_detail"].id;
    }
    try {
      const result = await PublicProfileService.updatePublicProfile(postBody);
      if (result.message) {
        CustomToast.success(result.message);
        onUpdate();
        setIsEditing(false);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  const textInputDetails = [
    {
      label: "Services",
      viewOnlyValues: (e) => {
        if (_.isEmpty(e)) return "-";
        return (
          <div className="flex flex-col">{_.map(e, getSolutionLabel)}</div>
        );
      },
      name: "solutions",
      type: "checklist",
      options: options["complementary_service"],
      displayKey: "value",
      idKey: "id",
      isViewOnly: !isEditing,
    },
  ];

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Complimentary Services"
        subHeading="Update your free offering here."
        id="complimentary-service-card"
        isEditing={isEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isLoading={isLoading}
        setIsEditing={setIsEditing}
        isEmpty={!isEditing && isEmpty}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              heading="Add Complimentary Services"
              className={"!mt-0 !mb-4"}
              message="To see complimentary services here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#complimentary-service-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              <div className="flex ">
                <FormBuilder
                  initialValues={initialValues}
                  validationSchema={
                    Validations.publicProfile
                      .complimentaryServicesCardValidationSchema
                  }
                  onFormSubmit={onFormSubmit}
                  inputFieldsDetails={textInputDetails}
                  formClassName="grid w-full grid-cols-1 gap-y-3 gap-x-4"
                  submitButtonRef={submitRef}
                  cancelButtonRef={cancelRef}
                />
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

ComplimentaryServicesCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default ComplimentaryServicesCard;
