import PropTypes from "prop-types";
import { Disclosure } from "@headlessui/react";
import { CaretDown, Check, ExclamationMark } from "@phosphor-icons/react";
import React from "react";
import { TopicTestTrackerCard, LevelExamTrackerCard } from "./Cards";
import Tooltip from "../../../stories/Tooltip";

const getIcon = (status) => {
  switch (status) {
    case "Complete":
      return (
        <Tooltip infoContent="Completed" messageClassName="!min-w-fit">
          <div className="w-8 min-w-[32px] flex items-center justify-center h-8 rounded-full bg-green-600/20">
            <Check
              className="text-green-600 min-w-[22px]"
              weight="bold"
              size={22}
            />
          </div>
        </Tooltip>
      );

    default:
      return (
        <Tooltip infoContent="Incomplete" messageClassName="!min-w-fit">
          <div className="w-8 min-w-[32px] flex items-center justify-center h-8 rounded-full bg-yellow-600/20">
            <ExclamationMark
              className="text-yellow-600 min-w-[22px]"
              weight="bold"
              size={22}
            />
          </div>
        </Tooltip>
      );
  }
};

const SessionTracker = ({ courseId, module, index, onLockedClick }) => {
  return (
    <div className="flex flex-col w-full gap-3">
      <Disclosure
        as="div"
        defaultOpen={index === 0}
        className="bg-blue-50 rounded-xl border border-gray-200"
      >
        <Disclosure.Button className="p-3 w-full flex items-center justify-between flex-row gap-4">
          <div className="font-medium text-left text-base text-theme-black-color">
            {module.name}
          </div>
          {getIcon(module.module_status)}
          <CaretDown
            weight="fill"
            className="w-5 min-w-[20px] ml-auto group-data-[open]:rotate-180"
          />
        </Disclosure.Button>
        <Disclosure.Panel className="p-3 lg:p-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-5 !pt-0">
          <div className="flex flex-col items-start text-left col-span-full w-full">
            <div className="italic font-light text-md">
              *All topic tests and level exams should be completed to get a
              Badge and to mark module as complete
            </div>
            <div className="italic font-light text-md">
              *It may take up to 45 minutes to generate a badge/certificate and
              to mark module as complete
            </div>
          </div>
          {module?.quizzes?.map((quiz, index) => {
            if (quiz.quiz_type === "topic_test") {
              return (
                <TopicTestTrackerCard
                  key={quiz.id}
                  quiz={quiz}
                  courseId={courseId}
                  moduleIsLock={module.is_locked}
                  onLockedClick={() => {
                    onLockedClick(quiz.course_session_content_id);
                  }}
                />
              );
            } else {
              return (
                <div key={quiz.id} className="col-span-full w-full">
                  <LevelExamTrackerCard
                    quiz={quiz}
                    courseId={courseId}
                    moduleIsLock={module.is_locked}
                    onLockedClick={() => {
                      onLockedClick(quiz.course_session_content_id);
                    }}
                  />
                </div>
              );
            }
          })}
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

SessionTracker.propTypes = {
  courseId: PropTypes.number,
  module: PropTypes.object,
  index: PropTypes.number,
  onLockedClick: PropTypes.func,
};

export default SessionTracker;
