import PropTypes from "prop-types";
import React from "react";
import QuizRadioGroup from "../../stories/QuizRadioGroup.js";

const QuizComponent = ({
  quizQuestion,
  quizOptions,
  onOptionSelect,
  selected,
  score,
}) => {
  return (
    <div className="bg-transparent">
      <QuizRadioGroup
        score={score}
        question={quizQuestion}
        options={quizOptions}
        selected={selected[0] ?? false}
        onOptionSelect={(val) => onOptionSelect([val])}
      />
    </div>
  );
};

QuizComponent.propTypes = {
  onOptionSelect: PropTypes.func,
  quizOptions: PropTypes.array,
  quizQuestion: PropTypes.any,
  score: PropTypes.any,
  selected: PropTypes.any,
};
export default QuizComponent;
