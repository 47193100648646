/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import EventDetails from "./EventDetails";
import EventForm from "./EventForm";
import classNames from "classnames";
import { EventsService } from "../../services/EventsService";
import { useCurrentUserPublicProfile } from "../../services/BasicDetails";
import { useWindowSize } from "../../hooks/useWindowSize";
import AppSkeleton from "../AppSkeleton";
import { User } from "../../store";
import AccountCreationBar from "../../stories/AccountCreationBar";
import MembershipRSVPRegisterBar from "../../stories/MembershipRSVPRegisterBar";
import _ from "lodash";
import { useNavigate } from "@reach/router";
import { CustomToast } from "../../stories/Toast";
import { useProfile } from "../../context/userProvider";
import MembershipRSVPRenewalBar from "../../stories/MembershipRSVPRenewal";
import { BulletItem } from "../../stories/BulletList";
import Text from "../../stories/Text";
import { MonitorPlay, UsersThree } from "@phosphor-icons/react";

const EventRegistration = (props) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState({});
  const [selectedTicketType, setSelectedTicketType] = useState();
  const isDesktop = width > 1024;
  const isNewUser = !User.getToken();
  const { userDetails } = useProfile();
  const { publicProfileData, loading } = useCurrentUserPublicProfile({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.querySelector("body").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  const fetchEventDetails = async (event_id) => {
    const response = await EventsService.fetchEventDetails(event_id);
    const { event } = response;
    if (
      event?.has_event_registered?.has_registered &&
      event?.has_event_registered?.is_paid === "paid"
    ) {
      if (event?.event_type === "national_event")
        navigate(`/national-events/${props.event_id}/register/thank-you`, {
          state: {
            isNewUser: isNewUser,
            event_id: props.event_id,
            eventDetails: event,
            formData: {
              ticket_type: { label: event?.has_event_registered?.ticket_type },
              email: userDetails?.email ?? "",
            },
          },
        });
      else
        navigate(`/events/${props.event_id}/register/thank-you`, {
          state: {
            isNewUser: isNewUser,
            event_id: props.event_id,
            eventDetails: event,
            formData: {
              ticket_type: { label: event?.has_event_registered?.ticket_type },
              email: userDetails?.email ?? "",
            },
          },
        });
      CustomToast.info("You have already registered for this event.");
      return;
    }
    const tempEvent = {
      ...event,
    };

    tempEvent.whenContent = [
      ..._.flattenDeep(event.time.map((e) => e?.split("\n"))),
    ];
    tempEvent.whereContent = [
      ..._.flattenDeep(event.place.map((e) => e?.split("\n"))),
    ];
    tempEvent.whatNextContent = [
      ..._.flattenDeep(event.next_step.map((e) => e?.split("\n"))),
    ];
    setEventDetails(tempEvent);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchEventDetails(props.event_id);
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(userDetails) &&
      !_.isEmpty(eventDetails) &&
      eventDetails.enable_rsvp &&
      eventDetails.promote_membership &&
      ([
        userDetails?.pm_signup_status,
        userDetails?.provisonal_member_signup_status,
      ].includes("approved") ||
        userDetails?.pm_signup_status === "provisional" ||
        [
          userDetails?.pm_signup_status,
          userDetails?.provisonal_member_signup_status,
        ].includes("expiring_soon"))
    ) {
      navigate(`/events/${props.event_id}/rsvp`, { replace: true });
    }
  }, [eventDetails, userDetails]);

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideHeaderLabel={true}
      openLoginModal={true}
    >
      <div className="flex justify-center px-4 py-6 lg:px-16 h-fit">
        <div className="flex flex-col justify-center w-full space-x-0 space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
          <div className="h-fit basis-full lg:basis-5/12">
            <div className="flex bg-white rounded-sm shadow-none lg:shadow-sm">
              <EventDetails
                className=""
                isNewUser={isNewUser}
                event_id={props.event_id}
                eventDetails={eventDetails}
                selectedTicketType={selectedTicketType}
              />
            </div>
            {eventDetails.event_type === "national_event" && (
              <div className="flex mt-4 p-6 bg-white rounded-sm shadow-none lg:shadow-sm flex-col w-full gap-4">
                <div className="flex text-lg font-semibold tracking-tight text-theme-black-color">
                  Tickets Types & What You Get
                </div>
                {(eventDetails.ticketing ?? []).map((ticketType) => (
                  <TicketTypeBenefit
                    key={ticketType.id}
                    label={ticketType.label}
                    benefits={ticketType.benefits}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col basis-full lg:basis-7/12 gap-4">
            {isNewUser && (
              <div className="mb-2">
                <AccountCreationBar />
              </div>
            )}
            {eventDetails.promote_membership &&
              ["not_registered", "initiated"].includes(
                userDetails?.pm_signup_status
              ) && (
                <MembershipRSVPRegisterBar
                  prefillData={props?.location?.state?.prefill_data}
                />
              )}
            {eventDetails.promote_membership &&
              ["overdue", "expires_soon"].includes(
                userDetails?.pm_signup_status
              ) && (
                <MembershipRSVPRenewalBar
                  entity_type={"pro_member"}
                  status={userDetails?.pm_signup_status}
                />
              )}
            <EventForm
              isNewUser={isNewUser}
              eventDetails={eventDetails}
              loading={isLoading || loading}
              prefillData={props?.location?.state?.prefill_data ?? {}}
              selectedTicketType={selectedTicketType?.id}
              publicProfileData={publicProfileData}
              onSubmitSuccess={(formData) => {
                window.scroll(0, 0);
                if (eventDetails?.event_type === "national_event")
                  navigate(
                    `/national-events/${props.event_id}/register/thank-you`,
                    {
                      state: {
                        isNewUser: isNewUser,
                        event_id: props.event_id,
                        eventDetails: eventDetails,
                        formData: {
                          ticket_type: {
                            label:
                              eventDetails?.has_event_registered?.ticket_type,
                          },
                          email: userDetails?.email ?? "",
                        },
                      },
                    }
                  );
                else
                  navigate(`/events/${props.event_id}/register/thank-you`, {
                    state: {
                      isNewUser: isNewUser,
                      event_id: props.event_id,
                      eventDetails: eventDetails,
                      formData: {
                        ticket_type: {
                          label:
                            eventDetails?.has_event_registered?.ticket_type,
                        },
                        email: userDetails?.email ?? formData?.email ?? "",
                      },
                    },
                  });
              }}
              entity_type={eventDetails.event_type}
              entity_id={props.event_id}
              onTicketTypeChange={(props) => {
                if (eventDetails.event_type === "regional_event") {
                  const { id, ticketing_status } = props;
                  setSelectedTicketType({
                    id,
                    ticketing_status,
                  });
                  return;
                }
                if (eventDetails.event_type === "national_event") {
                  const { id, label, ticketing_status } = props;
                  setSelectedTicketType({
                    id,
                    label,
                    ticketing_status,
                  });
                  return;
                }
              }}
            />
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

const TicketTypeBenefit = ({ label, benefits }) => {
  const isGold = _.toLower(label).includes("gold");
  const Icon = _.toLower(label).includes("physical") ? UsersThree : MonitorPlay;
  return (
    <div className="rounded-lg overflow-clip flex flex-col">
      <div
        className={classNames(
          "flex items-center gap-2 bg-gradient-to-r px-4 py-2.5 text-white",
          isGold ? "from-[#EFB714] to-[#FDE966]" : "from-[#A6B8B8] to-[#E7F0EF]"
        )}
      >
        <div
          className={classNames(
            "w-8 h-8 bg-white flex justify-center items-center rounded-full border",
            isGold ? "border-[#E5A80F]" : "border-[#92A4A4]"
          )}
        >
          <Icon
            className={classNames(
              "min-w-[16px]",
              isGold ? "text-[#E5A80F]" : "text-[#92A4A4]"
            )}
            weight="fill"
            size={16}
          />
        </div>
        <div className="font-extrabold text-lg uppercase">{label}</div>
      </div>
      {!_.isEmpty(benefits) && (
        <div className="grid grid-cols-2 gap-2.5 p-4 border border-gray-200 border-t-0 overflow-clip rounded-b-lg">
          {benefits?.split("\r\n").map((benefit, index) => (
            <BulletItem key={index}>
              <Text
                content={benefit}
                type="text"
                className="tracking-tight text-theme-black-300"
                size="text-sm"
              />
            </BulletItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventRegistration;
