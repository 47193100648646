import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import SearchBar from "../../stories/SearchBar";
import ImageComponent from "../../stories/ImageComponent";
import Dropdown from "../../stories/Dropdown";
import Loader from "../Shimmer/Loader.js";
import { AuthService } from "../../services/AuthService.js";
import { EventsService } from "../../services/EventsService.js";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import DelegatesBanner from "../../assets/images/delegates-banner.png";
import ProMemberBadge from "../../assets/svgs/promember_badge_orange.svg";
import UserIcon from "../../assets/svgs/user-avatar.svg";
import EmailIcon from "../../assets/svgs/qpfp-email-icon.svg";
import PhoneIcon from "../../assets/svgs/qpfp-phone-icon.svg";
import WhatsAppIcon from "../../assets/svgs/qpfp-whatsapp-icon.svg";
import EventLogoImg from "../../assets/images/event_logo.png";
import { CircleNotch } from "@phosphor-icons/react";
import Pagination from "./Pagination.js";
import EmptyData from "../PublicProfile/EmptyData.js";
import { getDirectoryMemberURL } from "../../util/getURLs.js";
import { User } from "../../store";

const EventDelegates = (props) => {
  const [query, setQuery] = useState("");
  const [cityId, setCityId] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [delegates, setDelegates] = useState([]);
  const [delegatesPagination, setDelegatesPagination] = useState(null);
  const [fetchingDelegates, setFetechingDelegates] = useState(false);

  // const [page, page] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const entriesPerPage = 50; // Show 50 entries per page

  // Setting it to 0 on mount to handle search and filter actions
  const [page, setPage] = useState(0);

  useDebounceEffect(
    () => {
      if (query.length > 3 || query === "") {
        // Setting the page to 0 first and then to 1 so that
        // when user performs a search action while page is 1
        // the API could refetch data
        setPage(0);
        setPage(1);
      }
    },
    500,
    [query]
  );

  useDebounceEffect(
    () => {
      // Only execute this block if page is not zero to avoid multiple API calls onMount
      if (page > 0) {
        if (cityId || cityId === "") {
          // Setting the page to 0 first and then to 1 so that
          // when user performs a filter action while page is 1
          // the API could refetch data
          setPage(0);
          setPage(1);
        }
      }
    },
    500,
    [cityId]
  );

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const result = await AuthService.getCities("cities");
        if (result.locations) {
          setCityOptions(result.locations);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchDelegates = async () => {
      setFetechingDelegates(true);
      try {
        const res = await EventsService.fetchDelegatesList(
          Number(props.event_id),
          query,
          cityId,
          page,
          entriesPerPage
        );

        setDelegates(res.user_events);
        setTotalEntries(res.pagination.total_count ?? 0);

        setDelegatesPagination(res.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setFetechingDelegates(false);
      }
    };

    // This is to ignore page being 0 onMount and after a search/filter action
    if (page > 0) {
      fetchDelegates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!delegatesPagination) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }
  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  return (
    <div className="flex bg-theme-gray-200">
      <div className="relative flex flex-col w-full nfp-page-container">
        <div className="top-0 z-20 w-full h-20 sticky">
          <div className="h-full shadow-sm w-full">
            <div className="flex justify-between items-center h-full px-4 py-6 bg-white border-0 border-b border-l-0 lg:border-b-0 lg:border-l sm:flex-nowrap border-theme-gray-300 lg:px-16">
              <div className="flex items-center w-56 justify-start h-12 bg-white">
                <ImageComponent src={EventLogoImg} className="h-9 lg:h-12" />
              </div>
              {User.getToken() ? (
                <a
                  href="/"
                  className="font-medium text-md text-theme-orange-400"
                >
                  Home
                </a>
              ) : (
                <a
                  href="/login/otp"
                  className="font-medium text-md text-theme-orange-400"
                >
                  Login
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="relative py-8 flex flex-col justify-center items-center">
          <img
            src={DelegatesBanner}
            alt=""
            className="absolute top-0 left-0 object-cover w-full h-full"
          />
          <h1 className="mb-4 font-bold text-3xl text-white text-center z-10">
            NFP National 2025 Delegates
          </h1>
          <p className="w-full font-medium text-lg text-white text-center z-10 md:w-1/2">
            Here is the list of delegates registered for NFP National 2025. The
            list is to help delegates connect with each other before, during and
            after Summit.
          </p>
        </div>

        <div className="mt-8 px-4 py-6 flex items-center space-x-2 lg:px-16 h-fit">
          <img
            src={ProMemberBadge}
            alt="ProMember Badge"
            className="w-10 h-10"
          />{" "}
          <div className="flex flex-col space-y-1">
            <p className="font-bold text-md text-theme-black-color">
              ProMembers
            </p>
            <p className="text-sm text-theme-black-300">
              ProMembers are registered members of Network FP who meet the
              eligibility criteria and completed required qualifications like
              QPFP, CFP, CFA etc. And are listed on{" "}
              <a
                href="https://members.networkfp.com/"
                className="text-[#1B2ED6]"
                target="_blank"
                rel="noreferrer"
              >
                Network FP Public Directory
              </a>
              .
            </p>
          </div>
        </div>

        <div className="mt-8 px-4 py-6 lg:px-16 h-fit">
          <div className="flex flex-col items-stretch space-x-0 space-y-4 md:flex-row md:items-center md:space-x-8 md:space-y-0">
            <div className="flex-1 flex flex-col space-y-1">
              <label className="font-medium text-xs tracking-tight leading-none text-theme-black-300">
                Search Delegates
              </label>
              <SearchBar
                className="!h-auto border border-gray-200 !rounded-sm shadow-sm !bg-theme-white"
                onSearch={(val) => setQuery(val)}
                backgroundColor="bg-theme-white"
                placeholder="Search by name, company"
                flipSearchIcon={true}
                size="lg"
              />
            </div>
            <Formik enableReinitialize initialValues={{ city: null }}>
              {({ values, ...formProps }) => {
                return (
                  <Form className="flex-1">
                    <Dropdown
                      label="Search by City"
                      placeholder="Type City"
                      type="text"
                      name="city"
                      key="city"
                      id="city"
                      displayCurrentOption={true}
                      options={cityOptions ?? []}
                      displayKey="name"
                      idKey="id"
                      isRequired={false}
                      onChange={(val) => {
                        setCityId(val);
                      }}
                      isClearable={true}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        <div className="px-4 py-6 lg:px-16 h-fit">
          <div className="mb-3 flex flex-col justify-between items-stretch space-y-2 md:flex-row md:items-center md:space-y-0">
            <p className="font-bold text-xs text-theme-black-300">
              {delegatesPagination?.total_count} delegates are attending NFP
              National 2025
            </p>
            <p className="text-xs text-theme-black-300/60">
              <span className="font-bold text-theme-black-color">Note:</span>{" "}
              Last registered will be first listed
            </p>
          </div>

          <div className="overflow-x-scroll lg:overflow-x-auto">
            <table className="w-full bg-white border-collapse overflow-y-clip">
              <thead>
                <tr className="w-full text-sm font-medium tracking-normal text-left text-white h-9 bg-theme-black-color">
                  <th className="px-4">SR NO</th>
                  <th className="px-4">DELEGATE NAME </th>
                  <th className="px-4 whitespace-nowrap">ORGANIZATION</th>
                  <th className="px-4">CITY</th>
                  <th className="px-4">TICKET</th>
                  <th className="px-4">MEMBER</th>
                  <th className="px-4 whitespace-nowrap">PROFILE LINK</th>
                </tr>
              </thead>
              <tbody>
                {fetchingDelegates && (
                  <tr className="">
                    <td colSpan={6} className="pb-10">
                      <CircleNotch
                        className="mx-auto animate-spin"
                        size={20}
                        weight="fill"
                      />{" "}
                    </td>
                  </tr>
                )}
                {!fetchingDelegates && delegates.length === 0 && (
                  <tr className="">
                    <td colSpan={7} className="pb-10">
                      <EmptyData
                        heading="No registrations"
                        message={`The  event does not have any attendees.`}
                      />
                    </td>
                  </tr>
                )}
                {!fetchingDelegates &&
                  delegates.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-sm border-b last:border-b-0 text-theme-black border-neutral-300/70"
                      >
                        <td className="p-4">
                          {(page - 1) * entriesPerPage + (index + 1)}
                        </td>
                        <td className="p-4 whitespace-nowrap">
                          {/* {`${item.first_name} ${item.last_name}`}{" "}
                          {item?.delegate_member_status === "ProMember" && (
                            <img
                              alt="pro"
                              src={ProMemberBadge}
                              className="object-contain w-4 h-4"
                            />
                          )} */}
                          <div className="flex items-center">
                            {item.first_name ?? "-"} {item.last_name}
                            <div className="ml-3">
                              {item?.delegate_member_status === "ProMember" && (
                                <img
                                  alt="pro"
                                  src={ProMemberBadge}
                                  className="object-contain w-4 h-4"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="p-4">{item.firm_name}</td>
                        <td className="p-4">{item.city_name}</td>
                        <td className="p-4">{item.ticket_type}</td>
                        <td className="p-4">{item?.delegate_member_status}</td>
                        <td className="p-4">
                          {item?.delegate_member_status !== "ProMember" ? (
                            "--"
                          ) : (
                            <a
                              href={getDirectoryMemberURL(item.profile_slug)}
                              className="font-light text-sm text-[#006FFE]"
                              target="_blank"
                              rel="noreferrer"
                            >
                              PROfile
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                <tr className="">
                  <td colSpan={7} className="pt-10 pb-6 px-4">
                    <Pagination
                      totalEntries={totalEntries}
                      entriesPerPage={entriesPerPage}
                      onPageChange={onPageChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="px-4 py-6 lg:px-16 h-fit">
          <div className="flex flex-col space-y-2 w-full p-4 bg-[#F2F7FF] border border-[#D9E7FF] rounded-md">
            <p className="font-bold text-md text-center text-theme-black-300">
              Delegates Assistance
            </p>
            <p className="text-sm text-center text-theme-black-300">
              For any assistance, kindly contact NFP Team
            </p>

            <div className="flex flex-col justify-center items-center space-x-0 space-y-3 md:flex-row md:space-x-3 md:space-y-0">
              <div className="flex items-center space-x-2">
                <img src={UserIcon} alt="Email Icon" />
                <p className="text-sm text-theme-black-300">Krisha</p>
              </div>

              <p className="hidden text-sm text-theme-black-300 md:block">|</p>

              <a href="mailto:contact@networkfp.com">
                <div className="flex items-center space-x-2">
                  <img src={EmailIcon} alt="Email Icon" />
                  <p className="text-sm text-theme-black-300">
                    contact@networkfp.com
                  </p>
                </div>
              </a>

              <p className="hidden text-sm text-theme-black-300 md:block">|</p>

              <a href="tel:+919892098959">
                <div className="flex items-center space-x-2">
                  <img src={PhoneIcon} alt="Phone Icon" />
                  <p className="text-sm text-theme-black-300">
                    +91 9892 098 959
                  </p>
                </div>
              </a>

              <p className="hidden text-sm text-theme-black-300 md:block">|</p>

              <a href="https://wa.me/+919892098959">
                <div className="flex items-center space-x-2">
                  <img src={WhatsAppIcon} alt="WhatsApp Icon" />
                  <p className="text-sm text-[#16A34A]">Direct WhatsApp</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-2 px-4 py-6 lg:px-16 h-fit">
          <div className="flex flex-col space-y-2 w-full p-4 bg-theme-orange-400 bg-opacity-10 border border-theme-orange-400 rounded-md">
            <div className="flex flex-col space-x-0 space-y-3 justify-center items-center md:flex-row md:space-x-3 md:space-y-0">
              <a
                href="https://networkfp.com/"
                className="font-bold text-sm text-theme-orange-400"
                target="_blank"
                rel="noreferrer"
              >
                NFP Website
              </a>
              <p className="hidden font-bold text-sm text-theme-orange-400 md:block">
                |
              </p>
              <a
                href="https://networkfp.com/qpfp/"
                className="font-bold text-sm text-theme-orange-400"
                target="_blank"
                rel="noreferrer"
              >
                QPFP Website
              </a>
              <p className="hidden font-bold text-sm text-theme-orange-400 md:block">
                |
              </p>
              <a
                href="https://networkfp.com/promember/"
                className="font-bold text-sm text-theme-orange-400"
                target="_blank"
                rel="noreferrer"
              >
                ProMember Website
              </a>
              <p className="hidden font-bold text-sm text-theme-orange-400 md:block">
                |
              </p>
              <a
                href="https://networkfp.com/national"
                className="font-bold text-sm text-theme-orange-400"
                target="_blank"
                rel="noreferrer"
              >
                National Website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventDelegates.propTypes = {
  path: PropTypes.string,
  event_id: PropTypes.string,
  uri: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    protocol: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    port: PropTypes.string,
    state: PropTypes.any,
    key: PropTypes.string,
  }),
};

export default EventDelegates;
