import React from "react";
import networkfp_logo from "../assets/images/networkfp-logo.png";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PropTypes from "prop-types";

const QuizSideBar = ({
  quizName,
  progress = 0,
  timeLeft,
  numberOfQuizResponse = 0,
  totalQuestions = "0",
  passPercentage = "",
  negativeMarking = "0",
  aceEligibility = "0",
}) => {
  const renderLogo = () => (
    <div className="flex items-center justify-between w-full h-0 lg:h-12 lg:w-72">
      <img
        alt="Network FP"
        src={networkfp_logo}
        // onClick={() => navigate("/", { replace: true })}
        className="h-0 lg:h-12"
      />
    </div>
  );

  const ShowTimeLeft = () => {
    if (timeLeft < 3600)
      return new Date(timeLeft * 1000).toISOString().substring(14, 19);

    return new Date(timeLeft * 1000).toISOString().substring(11, 19);
  };

  return (
    <div className="fixed bg-white flex flex-col h-[calc(100vh-70px)] lg:h-screen transition-all duration-300 lg:shadow-xl justify-start overflow-x-hidden w-full lg:w-72 lg:min-w-72 p-6 z-50">
      <div className="flex flex-col justify-start">
        {renderLogo()}
        <div className="mt-0 mb-2 text-sm lg:mt-16 text-theme-black-300">
          Session
        </div>
        <div className="text-base font-bold text-theme-black-color">
          {quizName}
        </div>
      </div>
      <div className="flex justify-center my-8">
        <div className="p-4 border-2 border-gray-200 rounded-full w-44 h-44">
          <CircularProgressbarWithChildren
            minValue={0}
            maxValue={100}
            value={progress}
            strokeWidth={3}
            styles={{
              root: {},
              path: {
                stroke: "#F36C04",
                strokeLinecap: "round",
                transition: "stroke-dashoffset 0.5s ease 0s",
              },
              trail: {
                stroke: `#CCCCCC`,
                strokeLinecap: "butt",
              },
            }}
          >
            <div className="text-xs font-normal text-theme-black-300">
              Progress
            </div>
            <div className="text-xl font-bold text-center">
              {progress === 0
                ? "0"
                : progress.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    minimumIntegerDigits: 2,
                  })}
              %
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <DisplayData label="Questions">{totalQuestions}</DisplayData>
        <div className="p-3 border rounded-md">
          <div className="text-xs font-normal text-theme-black-300">
            Time Left
          </div>
          <div className="text-sm font-bold">
            <ShowTimeLeft />
          </div>
        </div>
        <DisplayData label="Pass Percentage">{passPercentage}%</DisplayData>
        <DisplayData label="Negative Marking">{negativeMarking}</DisplayData>
        <DisplayData label="ACE Points">{aceEligibility}</DisplayData>
        <DisplayData label="No. of Quiz takers">
          {numberOfQuizResponse}
        </DisplayData>
      </div>
    </div>
  );
};

const DisplayData = ({ label, children }) => {
  return (
    <div className="p-3 border rounded-md">
      <div className="text-xs font-normal text-theme-black-300">{label}</div>
      <div className="text-sm font-bold">{children}</div>
    </div>
  );
};

DisplayData.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element,
};
QuizSideBar.defaultProps = {
  totalQuestions: "0",
  passPercentage: "",
  negativeMarking: "0",
  aceEligibility: "0",
};
QuizSideBar.propTypes = {
  aceEligibility: PropTypes.string,
  negativeMarking: PropTypes.string,
  numberOfQuizResponse: PropTypes.number,
  passPercentage: PropTypes.string,
  progress: PropTypes.number,
  quizName: PropTypes.string,
  timeLeft: PropTypes.number,
  totalQuestions: PropTypes.number,
};

export default QuizSideBar;
