import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const StepperProgressBar2 = ({
  className,
  currentStep,
  steps = [],
  changeStep,
}) => {
  return (
    <div className={classNames("w-full lg:w-60", className)}>
      <div className="w-full">
        <ul className="flex items-start w-full lg:flex-col progressBar2">
          {steps.map((e, index) => {
            return (
              <li
                key={index}
                className={classNames(
                  "list-none float-left w-full lg:h-[90px] relative text-center flex flex-col lg:flex-row items-center lg:items-start",
                  "before:mr-0 lg:before:mr-3 before:mb-3 lg:before:mb-0 before:text-theme-gray before:z-10 before:w-4 before:h-4 before:content-[''] before:bg-center",
                  "after:content-[''] after:absolute after:w-full lg:after:w-[1px] after:h-[1px] lg:after:h-full after:top-2 after:left-[calc(-50%)] lg:after:top-[calc(-100%+16px/2)] lg:after:left-[calc(16px/2)]",
                  index + 1 < currentStep
                    ? "active before:bg-orange_radio_stepper after:text-theme-orange after:bg-theme-orange cursor-pointer"
                    : index + 1 === currentStep
                    ? "active before:bg-orange_bullet_stepper current after:bg-theme-orange cursor-pointer"
                    : "after:bg-theme-gray before:bg-unselected_radio_stepper ",
                  index === 0 ? "after:content-none" : ""
                )}
                onClick={() => {
                  changeStep(index);
                }}
              >
                <div className="flex flex-col items-start">
                  <div className="flex text-xs leading-[14px] lg:leading-none lg:text-lg font-light lg:font-medium tracking-tight text-theme-black-color max-w-[50px] lg:max-w-full">
                    {e.heading}
                  </div>
                  <div className="hidden text-sm font-light tracking-tight text-left lg:mt-0.5 lg:flex text-theme-black-300 ">
                    {e.description}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

StepperProgressBar2.defaultProps = {
  className: "",
  changeStep: () => {},
};

StepperProgressBar2.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  steps: PropTypes.array,
  changeStep: PropTypes.func,
};

export default StepperProgressBar2;
