import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import _ from "lodash";

const SearchBar = ({
  size,
  align,
  className,
  onSearch,
  searchTerm,
  placeholder,
  alwaysOpen = true,
  inputProps,
  autoFocus = false,
  backgroundColor = "bg-neutral-100",
  flipSearchIcon = false,
}) => {
  const heightStyle = () => {
    switch (size) {
      case "xs":
        return "py-1.5 text-xs";
      case "sm":
        return "py-2 text-sm ";
      case "md":
        return "py-2 text-sm ";
      case "lg":
        return "py-2 text-base ";
      case "xl":
        return "py-3 text-base ";
      default:
        return "";
    }
  };

  const alignType =
    align === "center"
      ? "justify-center"
      : align === "left"
      ? "justify-start"
      : align === "right"
      ? "justify-end"
      : "";
  const searchInputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(alwaysOpen);

  // Setting Value of Search Input
  const [searchVal, setSearchVal] = useState(searchTerm ?? "");

  // Change Handler
  const onChangeHandler = (e) => {
    setSearchVal(e?.target?.value ?? "");
    onSearch(e?.target?.value ?? "");
  };

  // For initial url appending
  useEffect(() => {
    setSearchVal(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    // bg-white is used only for storybook

    <div
      className={classNames(
        "flex items-center mt-3 h-12 lg:mt-0 md:max-w-3xl w-full md:mx-auto lg:max-w-none lg:mx-0 overflow-clip rounded",
        alignType,
        className
      )}
    >
      <div
        className={classNames(
          "transition-[width]",
          isOpen || alwaysOpen ? "w-full" : "w-10"
        )}
      >
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div
            className={classNames(
              "absolute inset-y-0 z-10 flex items-center",
              flipSearchIcon ? "right-0 pr-3" : "left-0 pl-3",
              flipSearchIcon && !_.isEmpty(searchVal) && "hidden",
              isOpen || alwaysOpen ? "pointer-events-none" : "cursor-pointer"
            )}
            onClick={() => {
              if (alwaysOpen) return;
              setIsOpen(true);
              searchInputRef.current.focus();
            }}
          >
            <MagnifyingGlass
              className={`w-5 h-5 ${
                flipSearchIcon
                  ? "text-theme-black-color"
                  : "text-theme-gray-600"
              }`}
            />
          </div>
          <input
            ref={searchInputRef}
            id="search"
            name="search"
            autoFocus={autoFocus}
            className={classNames(
              "block border-none rounded-sm py-2 text-base placeholder:text-theme-gray-600 placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:ring-0 focus:border-none",
              backgroundColor,
              flipSearchIcon ? "pl-3 pr-10" : "pl-10 pr-3",
              size ? heightStyle() : "",
              isOpen ? "w-full" : "w-0 !p-0"
              // isOpen ? ""
            )}
            onBlur={() => {
              if (alwaysOpen || !_.isEmpty(searchVal)) return;
              setIsOpen(false);
            }}
            placeholder={placeholder}
            type="text"
            value={searchVal}
            onChange={onChangeHandler}
            {...inputProps}
          />
          <div
            className={classNames(
              "absolute inset-y-0 right-0 z-10 flex items-center pr-3",
              _.isEmpty(searchVal) ? "hidden" : "cursor-pointer"
            )}
            onClick={() => {
              onChangeHandler();
            }}
          >
            <X className="w-5 h-5 text-theme-gray-600" />
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBar.defaultProps = {
  size: "xl",
  align: "right",
  primaryCta: "Search",
  placeholder: "Search here...",
  inputProps: {},
  alwaysOpen: true,
  flipSearchIcon: false,
};

SearchBar.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  alwaysOpen: PropTypes.bool,
  autoFocus: PropTypes.bool,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  primaryCta: PropTypes.string,
  searchTerm: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", ""]),
  flipSearchIcon: PropTypes.bool,
};

export default SearchBar;
