/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import ThankyouScreen from "./ThankyouScreen";
import classNames from "classnames";
import { EventsService } from "../../services/EventsService";
import { useWindowSize } from "../../hooks/useWindowSize";
import AppSkeleton from "../AppSkeleton";
import { User } from "../../store";
import ContributionDetails from "./ContributionDetails";
import _ from "lodash";
import { Form, Formik } from "formik";
import { Validations } from "../../constants/validations";
import { PaymentServices } from "../../services/PaymentServices";
import { CustomToast } from "../../stories/Toast";
import TextInput from "../../stories/TextInput";
import PaymentForm from "../Payments/PaymentForm";
import { useProfile } from "../../context/userProvider";
import LoadingModal from "../Modals/LoadingModal";
import Text from "../../stories/Text";
import { TrendUp } from "@phosphor-icons/react";

const VoluntaryContribution = (props) => {
  const { width } = useWindowSize();
  const eventScreenRef = useRef();
  const submitRef = React.useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contributionDetails, setContributionDetails] = useState({});
  const [entityProps, setEntityProps] = useState({});
  const isDesktop = width > 1024;
  const isNewUser = !User.getToken();
  const { userDetails } = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [initialValues, setInitialValues] = useState({
    amount: "",
    firmName: "",
    wantGST: true,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState_id: "",
  });
  const amountTimerRef = useRef();

  useEffect(() => {
    document.querySelector("body").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  const fetchVoluntaryDetails = async (contribution_id) => {
    const response = await EventsService.fetchVoluntaryDetails(contribution_id);
    const { voluntary_contribution } = response;

    const tempDetails = {
      ...voluntary_contribution,
    };

    tempDetails.instructions = [
      ..._.flattenDeep(
        voluntary_contribution.instructions?.map((e) => e?.split("\n"))
      ),
    ];
    setContributionDetails({ ...tempDetails });
  };

  const fetchGSTDetails = async () => {
    try {
      const response = await PaymentServices.fetchPreviousGSTDetails();

      setInitialValues((prevState) => {
        if (response.gst_invoice_check === false) return prevState;
        return {
          ...prevState,
          wantGST: response.gst_invoice_check,
          gstin: response.gst_number,
          gstFirmName: response.firm_name,
          gstCommunicationAddress: response.address.line_1,
          gstPincode: response.address.pin,
          gstCity_id: response.address.city_id,
          gstCity: response.address.city,
          gstState_id: response.address.state_id,
        };
      });
    } catch (e) {
      console.log("no previous details found");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchVoluntaryDetails(props.contribution_id);
      fetchGSTDetails();
      setEntityProps({
        entity_id: props.contribution_id,
        entity_type: "voluntary",
      });
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchData();
  }, []);

  const makePayment = async (values) => {
    const postBody = {
      voluntary_purchase: {
        voluntary_contribution_id: props.contribution_id,
        entity_id: userDetails.id,
        entity_type: "User",
        amount: priceDetails.amount,
        gst: priceDetails.gst,
        total_amount: priceDetails.to_be_paid,
        coupon_ids: priceDetails.coupons?.map((e) => e.code),
        payment_gateway: "razorpay",
      },
    };

    if (values.wantGST) {
      postBody["voluntary_purchase"] = {
        ...postBody.voluntary_purchase,
        gst_invoice_check: values.wantGST,
        gst_number: values.gstin,
        firm_name: values.gstFirmName,
        address_attributes: {
          line_1: values.gstCommunicationAddress,
          city_id: values.gstCity_id,
          pin: values.gstPincode,
        },
      };
    }

    const orderDetails = await PaymentServices.createOrder1(
      postBody,
      priceDetails.to_be_paid === 0
    );

    if (orderDetails && orderDetails.payment_status === "initiated") {
      await PaymentServices.makeRazorpayPayment(
        {
          name: userDetails.first_name + " " + userDetails.last_name,
          email: userDetails.email,
          contact: userDetails.phone_number,
        },
        orderDetails,
        () => {
          CustomToast.success("Payment is successful" ?? "");
          setIsSubmitted(true);
          eventScreenRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        },
        null,
        2
      );
    } else if (orderDetails && orderDetails.payment_status === "success") {
      if (orderDetails.amount !== 0) {
        CustomToast.info(orderDetails.message);
      }
      setIsSubmitted(true);
      eventScreenRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      CustomToast.error(orderDetails.message);
    }
  };

  return (
    <AppSkeleton
      isLogoClickable={!isDesktop}
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      <div className="flex justify-center px-4 py-6 lg:px-16 h-fit">
        <div className="flex flex-col justify-center w-full space-x-0 space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
          {isSubmitted ? (
            <ThankyouScreen
              className=""
              isNewUser={isNewUser}
              eventDetails={contributionDetails}
              amount={priceDetails.to_be_paid}
            />
          ) : (
            <React.Fragment>
              <div className="flex bg-white rounded-sm shadow-none h-fit basis-full lg:basis-5/12 lg:shadow-sm">
                <ContributionDetails
                  className=""
                  contribution_id={props.contribution_id}
                  contributionDetails={contributionDetails}
                />
              </div>
              <div className="flex flex-col basis-full lg:basis-7/12">
                <div className={props.className}>
                  <div
                    className={classNames("flex flex-col w-full items-start")}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={
                        Validations.contributionFormvalidationSchema
                      }
                      onSubmit={async (values) => {
                        try {
                          await makePayment(values);
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      {({ values, ...formProps }) => {
                        return (
                          <Form className="w-full">
                            <div className="py-4 bg-white rounded-sm shadow-sm lg:shadow-none">
                              <div className="flex px-4 text-lg font-semibold tracking-tight text-theme-black-color">
                                {contributionDetails.form_label
                                  ? contributionDetails.form_label
                                  : "Voluntary Contribution"}
                              </div>
                              {contributionDetails.purpose && (
                                <Text
                                  className="flex px-4 text-sm tracking-tight text-theme-black-300"
                                  content={contributionDetails.purpose}
                                  isMultiline={true}
                                />
                              )}
                              <div className="grid grid-cols-2 gap-6 px-4 mt-4">
                                <div className="flex flex-col col-span-full md:col-span-1">
                                  <TextInput
                                    name="amount"
                                    label="Enter Amount of Contribution (multiples of 1000)"
                                    placeholder="6000"
                                    id="amount"
                                    type="number"
                                    isRequired
                                    onKeyUp={(e) => {
                                      clearTimeout(amountTimerRef.current);
                                      amountTimerRef.current = setTimeout(
                                        async () => {
                                          const response =
                                            await PaymentServices.fetchPricing({
                                              amount: values.amount,
                                              entity_type:
                                                "VoluntaryContribution",
                                              entity_id: props.contribution_id,
                                            });
                                          formProps.setFieldValue(
                                            "couponInput",
                                            [""]
                                          );
                                          setPriceDetails(response);
                                        },
                                        800
                                      );
                                    }}
                                  />
                                  {values.amount &&
                                    values.amount !== 0 &&
                                    _.isEmpty(formProps.errors.amount) && (
                                      <div className="flex mt-1 text-sm leading-5 tracking-tight text-green-600 col-span-full">
                                        <TrendUp
                                          size={20}
                                          className="min-w-[16px] mr-3"
                                        />
                                        Your contribution will educate{" "}
                                        {values.amount / 100} investors
                                      </div>
                                    )}
                                </div>
                              </div>
                              <PaymentForm
                                entityProps={entityProps}
                                setPriceDetails={setPriceDetails}
                                fetchPricingDetails={async (data) => {
                                  const response =
                                    await PaymentServices.fetchPricing({
                                      entity_type: "VoluntaryContribution",
                                      entity_id: props.contribution_id,
                                      discount_codes: data.discount_codes ?? [],
                                      amount: values.amount,
                                    });
                                  return response;
                                }}
                                priceDetails={priceDetails}
                                formikProps={{ values, ...formProps }}
                                submitRef={submitRef}
                                className="p-4 bg-white rounded-sm shadow-sm lg:shadow-none"
                                programTitle={"Payment"}
                                amountLabel="Contribution Amount"
                                subTitle={
                                  "Make payment for voluntary contribution."
                                }
                              />
                              <button
                                disabled={formProps.isSubmitting}
                                className="hidden"
                                ref={submitRef}
                                type="submit"
                                onClick={() => {}}
                              >
                                submit
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {isLoading && <LoadingModal open={isLoading} />}
    </AppSkeleton>
  );
};

export default VoluntaryContribution;
