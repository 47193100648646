/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Validations } from "../../constants/validations";
import { EventsService } from "../../services/EventsService";
import Button from "../../stories/Button";
import Dropdown from "../../stories/Dropdown";
import TextInput from "../../stories/TextInput";

const RSVPForm = (props) => {
  const [initialValues, setInitialValues] = useState();
  const [entityProps, setEntityProps] = useState({});
  const [rsvpOptions, setRsvpOptions] = useState([]);

  const fetchRSVPOptions = async (daywise_labels = []) => {
    if (daywise_labels.length < 1) return;
    try {
      const promisesToAwait = [];
      daywise_labels?.forEach((label, index) => {
        promisesToAwait.push(
          EventsService.fetchRSVPOptions({
            entity_type: "ListItem",
            entity_id: label.id,
          })
        );
      });
      const responses = await Promise.all(promisesToAwait);
      setRsvpOptions(responses.map((e) => e.values));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!props.entity_type || !props.entity_id) {
        return false;
      }

      setEntityProps({
        entity_type: props.entity_type,
        entity_id: props.entity_id,
      });
    };
    fetchInitialData();
  }, [props.entity_type, props.entity_id]);

  useEffect(() => {
    setInitialValues((prev) => ({
      ...prev,
      firstName:
        props.publicProfileData?.first_name ??
        props.prefillData?.first_name ??
        "",
      lastName:
        props.publicProfileData?.last_name ??
        props.prefillData?.last_name ??
        "",
      email: props.publicProfileData?.email ?? props.prefillData?.email ?? "",
      mobileNumber:
        props.publicProfileData?.phone_number ??
        props.prefillData?.phone_number ??
        "",
      city: props.publicProfileData?.city?.id,
      firmName: props.publicProfileData?.firm?.name,
    }));
  }, [props.publicProfileData]);

  useEffect(() => {
    fetchRSVPOptions(props.eventDetails?.daywise_label);
    setInitialValues((prev) => ({
      ...prev,
      enable_rsvp: props.eventDetails?.enable_rsvp,
      rsvp: Array(props.eventDetails?.daywise_label?.length ?? 0).fill(null),
    }));
  }, [props.eventDetails]);

  return (
    <div className={props.className}>
      <div className={classNames("flex flex-col w-full items-start p-4")}>
        <div className="flex text-2xl font-semibold leading-none tracking-tight text-theme-black-color">
          RSVP - Confirm your Attendance
        </div>
        <div className="flex text-sm mt-1.5 tracking-tight text-theme-black-300">
          NFP Members are requested to make a voluntary contribution of Min ₹0 -
          Max ₹6,000 for Virtual attendance or Min ₹1,000 - Max ₹12,000 for
          Physical attendance during or after Summit. The money collected will
          be utilized for organizing Public Awareness Campaigns on Financial
          Wellbeing and Working with Professionals like you.
        </div>
        <div className="block text-sm mt-1.5 tracking-tight text-theme-black-300">
          <b>Note:&nbsp;</b> Your RSVP will be confirmed and valid only after
          renewal with an active membership.
        </div>
        <div className="flex mt-5 text-lg font-semibold tracking-tight text-theme-black-color">
          Attendee Details
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validations.rsvpFormvalidationSchema}
          onSubmit={async (values) => {
            const body = {
              user_id: props.publicProfileData.id,
              event_id: entityProps.entity_id,
              attendances_attributes: props.eventDetails?.daywise_label.map(
                (e, index) => ({
                  list_item_id: e.id,
                  rsvp_option_id: values.rsvp[index],
                })
              ),
            };
            try {
              await EventsService.createRSVP(body);
              props.onSubmitSuccess();
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {({ values, ...formProps }) => {
            return (
              <Form className="w-full mt-6">
                <div className="bg-white rounded-sm shadow-sm lg:shadow-none">
                  <div className="grid grid-cols-2 gap-6">
                    <div className="">
                      <TextInput
                        name="firstName"
                        label="First Name"
                        placeholder="Rahul"
                        id="firstName"
                        type="text"
                        disable={true}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="lastName"
                        label="Last Name"
                        placeholder="Subramanian"
                        id="experience"
                        type="text"
                        disable={true}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="email"
                        label="Email Address"
                        placeholder="rahul@xyz.com"
                        id="email"
                        type="text"
                        disable={true}
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="mobileNumber"
                        label="Mobile No. (10 digits)"
                        placeholder="9999999999"
                        id="mobileNumber"
                        type="number"
                        disable={true}
                        leadingComponent={
                          <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                            +91
                          </span>
                        }
                        isRequired
                      />
                    </div>
                    {props.eventDetails?.enable_rsvp && (
                      <div className="grid grid-cols-1 gap-6 col-span-full lg:grid-cols-2">
                        {props.eventDetails?.daywise_label?.map(
                          (rsvp, index) => (
                            <div key={`${rsvp.text_content}-${index}`}>
                              <Dropdown
                                name={`rsvp.${index}`}
                                id={`rsvp.${index}`}
                                type="text"
                                label={rsvp.text_content}
                                placeholder="Confirm your Attendance"
                                options={rsvpOptions[index]}
                                displayCurrentOption={true}
                                isSearchable={false}
                                displayKey="value"
                                idKey="id"
                                isRequired
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                    <div className="hidden lg:flex" />
                    <div className="">
                      <Button
                        buttonStyle="primary"
                        height="40px"
                        width="100%"
                        className="mt-3"
                        disabled={formProps.isSubmitting}
                        type="submit"
                        onClick={async () => {}}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default RSVPForm;
