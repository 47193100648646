/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import React from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CourseService } from "../../services/CourseService";
import Button from "../../stories/Button";
import Card from "../../stories/Card";
import ImageComponent from "../../stories/ImageComponent";
import { CustomToast } from "../../stories/Toast";
import { CalendarBlank } from "@phosphor-icons/react";
import { useProfile } from "../../context/userProvider";

const ShowEventCard = ({ program, index, program_type, prefillData }) => {
  const { userDetails } = useProfile();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const handleClick = async () => {
    if (program_type === "event") {
      navigate(`/events/${program.event_id}/register`, {
        state: { prefill_data: prefillData },
      });
    }
    if (program_type === "pro_member") {
      navigate("/members/pro_member/register", {
        state: { prefill_data: prefillData },
      });
    }
    if (program_type === "provisional_member") {
      navigate("/members/community_member/register", {
        state: { prefill_data: prefillData },
      });
    }
    if (program_type === "qpfp") {
      navigate("/members/qpfp/register", {
        state: {
          start_date: program.start_date,
          course_id: program.id,
          prefill_data: prefillData,
        },
      });
    }
    if (program_type === "free_training") {
      try {
        const response = await CourseService.createUserCourse(program.id);
        CustomToast.success(response.message ?? "Course Added Successfully");
        navigate(`/programs/${program.id}/`);
      } catch (e) {
        console.log(e);
        CustomToast.error(e.error_messages[0]);
      }
    }
    if (program_type === "voluntary") {
      navigate(`/voluntary/${program.id}/register`, {
        state: { prefill_data: prefillData },
      });
    }
    if (program_type === "corporate_account") {
      navigate(`/corporate/register`);
    }
  };

  const getButtonContent = () => {
    switch (program_type) {
      case "free_training":
        return "START COURSE";
      case "pro_member":
      case "qpfp":
        return "REGISTER";
      case "event":
        return program.enable_rsvp &&
          program.promote_membership &&
          ([
            userDetails?.pm_signup_status,
            userDetails?.provisonal_member_signup_status,
          ].includes("approved") ||
            [
              userDetails?.pm_signup_status,
              userDetails?.provisonal_member_signup_status,
            ].includes("provisional"))
          ? "RSVP"
          : "REGISTER";
      case "voluntary":
        return "VOLUNTARY CONTRIBUTION";
      case "corporate_account":
        return "CREATE NOW";
      default:
        return "REGISTER";
    }
  };

  return (
    <Card
      className="flex min-w-full max-h-72 min-h-[264px] nfp-register-event-card"
      header={
        <div className="flex items-center text-base font-semibold leading-none tracking-tight text-black-color">
          <ImageComponent
            src={program.course_logo}
            className="object-cover w-10 h-10 mr-2"
            errorComponent={
              <CalendarBlank
                size={28}
                weight="duotone"
                className="mr-2 text-theme-red"
              />
            }
          />
          {program.actual_name ?? program.title}
        </div>
      }
      cta={
        <>
          <div className="flex flex-row flex-wrap items-center justify-between mt-1 mb-2 text-sm leading-5 tracking-tight text-black-color-300">
            <div className="flex flex-row flex-wrap items-center">
              {program.course_type_str && program_type !== "voluntary" && (
                <div className="flex mt-1 text-xs leading-none tracking-tight text-zinc-500">
                  {program.course_type_str}
                </div>
              )}
              {program_type === "voluntary" && (
                <div className="flex mt-1 text-xs leading-none tracking-tight text-zinc-500">
                  Voluntary Contribution
                </div>
              )}
              {program.session_mode && (
                <span className="w-1 h-1 mx-2 mt-1 rounded-full bg-theme-gray-400"></span>
              )}
              {program.session_mode && (
                <div className="flex mt-1 text-xs leading-none tracking-tight text-zinc-500">
                  {program.session_mode}
                </div>
              )}
              {program.start_date && program_type !== "provisional_member" && (
                <span className="w-1 h-1 mx-2 mt-1 rounded-full bg-theme-gray-400"></span>
              )}
              {program.start_date && program_type !== "provisional_member" && (
                <div className="flex mt-1 text-xs leading-none tracking-tight text-zinc-500">
                  {program_type === "pro_member"
                    ? "Ongoing"
                    : `Starts on ${program.start_date}`}
                </div>
              )}
              {program.end_date && program_type === "voluntary" && (
                <span className="w-1 h-1 mx-2 mt-1 rounded-full bg-theme-gray-400"></span>
              )}
              {program.end_date && program_type === "voluntary" && (
                <div className="flex mt-1 text-xs leading-none tracking-tight text-zinc-500">
                  Ends on {program.end_date}
                </div>
              )}
            </div>
          </div>
          <Button
            buttonStyle={width < 1024 ? "primary" : "outline-primary"}
            size="xl"
            width="100%"
            onClick={() => handleClick()}
            className="nfp-register-event-btn"
          >
            {getButtonContent()}
          </Button>
        </>
      }
      cardType="outline"
      cardBody={
        <div className="flex flex-col w-full mt-2 mb-1">
          <div className="text-sm leading-5 tracking-tight line-clamp-5 text-theme-black-300">
            {program.description?.split("\n").map((item, idx) => (
              <span key={idx}>
                {item}
                <br></br>
              </span>
            ))}
          </div>
        </div>
      }
    />
  );
};

ShowEventCard.propTypes = {
  index: PropTypes.number,
  prefillData: PropTypes.any,
  program: PropTypes.shape({
    actual_name: PropTypes.any,
    course_duration: PropTypes.any,
    course_logo: PropTypes.any,
    course_type_str: PropTypes.any,
    description: PropTypes.string,
    event_id: PropTypes.any,
    id: PropTypes.any,
    session_mode: PropTypes.any,
    start_date: PropTypes.any,
  }),
  program_type: PropTypes.string,
};
export default ShowEventCard;
