import PropTypes from "prop-types";
import React from "react";
import Text from "../../../../stories/Text";
import TopicComponent from "../../../../stories/TopicComponent";

const QuizTab = ({
  quizCourseSessionContentList = [],
  courseId,
  courseDataRef,
  currentState,
}) => {
  return (
    <div className="grid grid-cols-1 gap-4 pb-10 md:grid-cols-2">
      <Text
        type="text"
        className="!text-base text-theme-black-color font-semibold col-span-full"
        content="List of Quiz"
      />
      {quizCourseSessionContentList.map((quiz, index) => (
        <TopicComponent
          key={quiz.id}
          courseId={courseId}
          sessionContentId={quiz.id}
          courseDataRef={courseDataRef}
          title={quiz.title}
          isCompleted={quiz.progress?.status === "completed" ?? false}
          type={quiz.kind}
          isDownloadable={quiz.is_downloadable ?? true}
          speaker={quiz.author_name}
          sessionTime={`${quiz.duration_in_hours ?? "00"}:${
            quiz.duration_in_mins ?? "00"
          }:${quiz.duration_in_sec ?? "00"}`}
          url={quiz?.attachment?.url ?? ""}
          currentState={currentState}
          className="!max-w-full border"
        />
      ))}
    </div>
  );
};

QuizTab.propTypes = {
  courseDataRef: PropTypes.any,
  courseId: PropTypes.any,
  currentState: PropTypes.any,
  quizCourseSessionContentList: PropTypes.array,
};

export default QuizTab;
