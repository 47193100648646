import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import { CheckCircle, Warning, Info, XCircle, X } from "@phosphor-icons/react";

const AlertIcon = ({ type }) => {
  switch (type) {
    case "success":
      return <CheckCircle className="w-5 h-5 text-emerald-400" />;
    case "warning":
      return <Warning className="w-5 h-5 text-yellow-400" />;
    case "error":
      return <XCircle className="w-5 h-5 text-red-400" />;
    default:
      return <Info className="w-5 h-5 text-blue-400" />;
  }
};

AlertIcon.propTypes = {
  type: PropTypes.oneOf(["warning", "error", "success", "info"]).isRequired,
};

const Alert = ({
  type,
  title,
  description,
  dismiss,
  dismissOnClick,
  ...rest
}) => {
  const bgStyle = () => {
    switch (type) {
      case "success":
        return "bg-emerald-50";
      case "warning":
        return "bg-amber-50 ";
      case "error":
        return "bg-red-50";
      case "info":
        return "bg-blue-50";
      default:
        return "";
    }
  };

  const textStyle = () => {
    switch (type) {
      case "success":
        return "text-emerald-800";
      case "warning":
        return "text-amber-800";
      case "error":
        return "text-red-800";
      case "info":
        return "text-blue-800";
      default:
        return "";
    }
  };

  const descStyle = () => {
    switch (type) {
      case "success":
        return "text-emerald-700";
      case "warning":
        return "text-amber-700";
      case "error":
        return "text-red-700";
      case "info":
        return "text-blue-700";
      default:
        return "";
    }
  };

  const buttonStyle = () => {
    switch (type) {
      case "success":
        return "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600";
      case "warning":
        return "bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600";
      case "error":
        return "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600";
      case "info":
        return "bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600";
      default:
        return "";
    }
  };

  return (
    <div className={classNames("rounded-md p-4", bgStyle())}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <AlertIcon type={type} />
        </div>
        <div className="ml-3">
          <h3 className={classNames("text-sm font-medium", textStyle())}>
            {title}
          </h3>
          {description && (
            <div className={classNames("mt-2 text-sm", descStyle())}>
              {description}
            </div>
          )}
        </div>
        {dismiss ? (
          <div className="items-center pl-3 ml-auto">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={classNames(
                  "inline-flex",
                  buttonStyle(),
                  "rounded-md p-1 focus:outline-none focus:ring-0 focus:ring-offset-2"
                )}
                onClick={dismissOnClick}
              >
                <span className="sr-only">Dismiss</span>
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

Alert.defaultProps = {
  type: "success",
  dismiss: false,
};

Alert.propTypes = {
  type: PropTypes.oneOf(["warning", "error", "success", "info"]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  dismiss: PropTypes.bool,
  dismissOnClick: PropTypes.func,
};

export default Alert;
