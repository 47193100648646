/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import AppSkeleton from "../AppSkeleton";
import Header from "./Header";
import ForumList from "../../stories/ForumList";
import classNames from "classnames";
import Footer from "./Footer";
import { useLocation, useNavigate } from "@reach/router";
import { ForumService } from "../../services/ForumService";
import { useEffect } from "react";
import queryString from "qs";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import _ from "lodash";
import EmptyState from "../../stories/EmptyState";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import { useForums } from "../../services/ForumDetails";
import ExchangeForumFilter from "./ExchangeForumFilter";
import Dropdown from "../../stories/Dropdown";
import { Formik } from "formik";
import Tooltip from "../../stories/Tooltip";
import ExchangeForumFilterModal from "../Modals/ExchangeForumFilterModal";
import { FadersHorizontal } from "@phosphor-icons/react";
import GenericPopup from "../Modals/GenericPopup";
import LoginUserModal from "../Modals/LoginUserModal";

const sortOptions = [
  { id: "most_liked", label: "Most liked" },
  { id: "most_commented", label: "Most commented" },
  { id: "most_viewed", label: "Most viewed" },
  { id: "most_recent", label: "Most recent" },
];

const ExchangeForum = (props) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const parsed = queryString.parse(
    location.search[0] === "?" ? location.search.substring(1) : location.search
  );
  const [selectedCategories, setSelectedCategories] = useState(
    parsed.filters ?? []
  );
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showProMemberPopup, setShowProMemberPopup] = useState(false);
  const [page, setPageNumber] = useState(parsed.pageNo ?? 0);
  const [searchQuery, setSearchQuery] = useState(parsed.search ?? "");
  const [selectedSort, setSelectedSort] = useState("");

  const perPageCount = 10;
  const { forums, pagination, loading, error } = useForums({
    per_page: perPageCount,
    page: page + 1,
    search: searchQuery,
    category_ids: selectedCategories,
    sort_by: selectedSort,
  });

  useEffect(() => {
    window.scroll(0, 0);
    fetchData();
  }, []);

  useEffect(() => {
    if (location.search === "") return;
    const temp = parsed.filters ?? [];
    setSelectedCategories(_.isArray(temp) ? temp : [parsed.filters]);
    setSearchQuery(parsed.search ?? "");
    setPageNumber(_.toNumber(parsed.pageNo) ?? 0);
  }, [window.location.search]);

  const fetchData = () => {
    fetchCategories();
  };

  const fetchCategories = async () => {
    const response = await ForumService.getAllForumCategories();
    setCategories(response.forum_categories);
  };
  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  const selectedCategoriesList = [...categories].filter((category) =>
    selectedCategories.includes(category.id)
  );
  return (
    <AppSkeleton
      hideSidebar
      pageLabel=""
      bgColor="bg-theme-grey-200"
      headerClassName="shadow-none"
      openLoginModal={true}
    >
      {props.children}
      <div
        className={classNames(
          "min-h-[calc(100vh-80px)]",
          ["/exchange/share-something", "/exchange/ask-question"].includes(
            props.location.pathname
          ) && "hidden lg:block"
        )}
      >
        <Header
          categories={categories}
          searchQuery={searchQuery}
          onSearchChange={_.debounce((val) => setSearchQuery(val), 700)}
          onClickCreateForum={(type) => navigate("/exchange/add")}
          setShowPopup={setShowProMemberPopup}
        />
        <div className="flex justify-center gap-6 px-4 py-6 lg:px-20">
          <div className="relative flex-col hidden w-full max-w-sm gap-4 lg:flex">
            <Formik
              initialValues={{ sort_by: selectedSort }}
              enableReinitialize
            >
              <Dropdown
                name="sort_by"
                id="sort_by"
                type="text"
                label=""
                placeholder="Sort by"
                height={"h-9"}
                className={"w-full"}
                options={sortOptions}
                keepShadow={false}
                buttonBgColor="bg-white"
                displayCurrentOption={true}
                displayKey="label"
                idKey="id"
                onChange={(e) => {
                  setSelectedSort(e ?? "");
                }}
              />
            </Formik>
            <ExchangeForumFilter
              categories={categories}
              submitFilters={(val) => {
                if (!_.isEqual(val, selectedCategories)) {
                  setSelectedCategories(val);
                }
              }}
            />
          </div>
          <div className="flex relative flex-col pb-6 w-full max-w-[938px]">
            <div
              className={classNames(
                "flex flex-col items-start gap-4 mb-4 lg:items-center lg:flex-row",
                _.isEmpty(selectedCategoriesList) && "lg:hidden"
              )}
            >
              <button
                className="flex items-center justify-center w-full bg-white border border-gray-200 rounded-lg h-9 lg:hidden"
                onClick={() => setOpenFilterModal(true)}
              >
                <FadersHorizontal size={24} className="mr-2 text-theme-black" />{" "}
                Filter by Category
              </button>
              <div className={classNames("hidden lg:flex gap-2.5 flex-grow")}>
                {selectedCategoriesList.slice(0, 2).map((category, index) => {
                  return (
                    <div
                      key={category.id}
                      className={classNames(
                        "border min-w-fit hover:border-theme-orange hover:text-theme-orange border-zinc-300 text-theme-black-color rounded-full py-1.5 px-3 text-xs leading-4",
                        selectedCategories.includes(category.id) &&
                          "bg-theme-orange-400/20 !border-theme-orange !text-theme-orange"
                      )}
                    >
                      {category.label}
                    </div>
                  );
                })}
                {selectedCategoriesList.length > 2 && (
                  <Tooltip
                    infoContent={selectedCategoriesList
                      .slice(2)
                      .map((c) => c.label)
                      .join(" | ")}
                  >
                    <div
                      className={classNames(
                        "border min-w-fit hover:border-theme-orange cursor-pointer hover:text-theme-orange border-zinc-300 text-theme-black-color rounded-full py-1.5 px-3 text-xs leading-4"
                      )}
                    >
                      +{selectedCategoriesList.length - 2}
                    </div>
                  </Tooltip>
                )}
              </div>
              <Formik
                initialValues={{ sort_by: selectedSort }}
                enableReinitialize
              >
                <Dropdown
                  name="sort_by"
                  id="sort_by"
                  type="text"
                  label=""
                  placeholder="Sort by"
                  height={"h-9"}
                  className={"w-full lg:max-w-186 lg:hidden"}
                  options={sortOptions}
                  keepShadow={false}
                  buttonBgColor="bg-white"
                  displayCurrentOption={true}
                  displayKey="label"
                  idKey="id"
                  onChange={(e) => {
                    setSelectedSort(e ?? "");
                  }}
                />
              </Formik>
            </div>

            <ForumList
              forumList={forums}
              categories={categories}
              pagination={pagination}
              setShowProMemberPopup={setShowProMemberPopup}
            />
            {!loading && !error && forums.length === 0 && (
              <EmptyState
                heading="No Discussion Yet!"
                subHeading=""
                image={
                  <img
                    alt="emptyStatePlaceHolder"
                    className="mb-4 max-h-44"
                    src={EmptyStatePlaceHolder}
                  />
                }
                className="my-10"
              />
            )}
            {loading && (
              <div className="mt-5">
                <Loader />
              </div>
            )}
            <div>{!loading && error && <ErrorScreen />}</div>
            <div className="flex flex-col items-center w-full gap-4 px-2 pt-4 pb-2 md:flex-row md:justify-between">
              <div className="block text-theme-black-300">
                Showing{" "}
                <span className="font-medium text-theme-black-color">
                  {pagination.current_page_count === 0
                    ? 0
                    : page * perPageCount + 1}
                </span>{" "}
                to{" "}
                <span className="font-medium text-theme-black-color">
                  {(page + 1) * perPageCount > pagination.total_count
                    ? pagination.total_count
                    : (page + 1) * perPageCount}
                </span>{" "}
                of{" "}
                <span className="font-medium text-theme-black-color">
                  {pagination.total_count ?? 0}
                </span>{" "}
                Entries
              </div>
              <div className="flex text-sm leading-4 bg-white border border-gray-200 rounded-md w-fit overflow-clip">
                <div
                  onClick={page === 0 ? null : () => onPageChange(page - 1)}
                  className={classNames(
                    "px-3 text-theme-black-color py-1.5 font-semibold",
                    page === 0
                      ? "text-theme-black-300 bg-theme-black-color/10 cursor-not-allowed"
                      : "text-theme-black-color cursor-pointer"
                  )}
                >
                  Previous
                </div>
                {page > 0 && (
                  <div
                    onClick={() => onPageChange(page - 1)}
                    className="px-3 cursor-pointer text-theme-black-300 py-1.5 border-l border-gray-200"
                  >
                    {page}
                  </div>
                )}
                <div className="px-3 cursor-pointer font-semibold text-theme-orange bg-theme-orange/10 py-1.5 border border-y-0 border-gray-200">
                  {page + 1}
                </div>
                {page + 1 < pagination.total_pages && (
                  <div
                    onClick={() => onPageChange(page + 1)}
                    className="px-3 cursor-pointer text-theme-black-300 py-1.5 border-r border-gray-200"
                  >
                    {page + 2}
                  </div>
                )}
                <div
                  onClick={
                    page + 1 === pagination.total_pages
                      ? null
                      : () => onPageChange(page + 1)
                  }
                  className={classNames(
                    "px-3 text-theme-black-color py-1.5 font-semibold",
                    page + 1 === pagination.total_pages
                      ? "text-theme-black-300 bg-theme-black-color/10 cursor-not-allowed"
                      : "text-theme-black-color cursor-pointer"
                  )}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {openFilterModal && (
        <ExchangeForumFilterModal
          open={openFilterModal}
          preSelectedCategories={selectedCategories}
          categories={categories}
          onSubmit={(val) => {
            if (!_.isEqual(val, selectedCategories)) {
              setSelectedCategories(val);
            }
            setOpenFilterModal(false);
          }}
          onClose={() => setOpenFilterModal(false)}
        />
      )}
      {showProMemberPopup && (
        <GenericPopup
          open={showProMemberPopup}
          heading="Become a ProMember to enjoy exclusive benefits"
          description="Do you want to register for ProMembership to proceed further?"
          closeLabel="No"
          confirmLabel="Yes"
          onClose={() => setShowProMemberPopup(false)}
          onConfirm={() => navigate("/members/pro_member/register")}
        />
      )}
      <LoginUserModal prefill_data={{}} />
    </AppSkeleton>
  );
};

export default ExchangeForum;
