import PropTypes from "prop-types";
import classNames from "classnames";
import { FieldArray } from "formik";
import { AsteriskSimple } from "@phosphor-icons/react";
import React from "react";
import CheckListItem from "./CheckListItem";
import Tooltip from "./Tooltip";

const CheckList = ({
  label,
  name,
  options,
  displayKey,
  idKey,
  formProps,
  className,
  rows,
  values,
  isRequired,
  infoContent,
}) => {
  return (
    <div
      className={classNames(
        "p-3 border border-dashed col-span-full",
        className,
        formProps.touched[name] && formProps.errors[name]
          ? "border-theme-red-300"
          : "border-gray-200"
      )}
    >
      <FieldArray name={name}>
        <div
          role="group"
          className={classNames(
            "grid w-full grid-cols-1 gap-3",
            rows !== 1 && "md:grid-cols-2"
          )}
        >
          <span className="flex items-center text-base font-semibold leading-5 tracking-tight text-left col-span-full text-theme-black-color">
            {label}
            {isRequired && (
              <AsteriskSimple
                size={8}
                weight="bold"
                className="mb-2 text-theme-red"
              />
            )}
            {infoContent && <Tooltip infoContent={infoContent} />}
          </span>
          {options?.map((option, index) => {
            return (
              <CheckListItem
                key={option[idKey]}
                label={option[displayKey]}
                name={name}
                value={option[idKey]}
                onClick={() => {
                  let tempList = [...(values[name] ?? [])];
                  if (tempList === null) {
                    tempList = [];
                  }
                  if (tempList.includes(option[idKey])) {
                    tempList.splice(tempList.indexOf(option[idKey]), 1);
                  } else {
                    tempList.push(option[idKey]);
                  }

                  formProps.setFieldValue(name, tempList, true);
                }}
              />
            );
          })}
        </div>
      </FieldArray>
      {formProps.touched[name] && formProps.errors[name] && (
        <div
          className={classNames(
            "flex w-full mt-2.5 text-theme-red-300 text-sm text-left leading-none tracking-tight",
            name + "ErrorMessage"
          )}
        >
          {formProps.errors[name]}
        </div>
      )}
    </div>
  );
};

CheckList.propTypes = {
  className: PropTypes.string,
  displayKey: PropTypes.string,
  formProps: PropTypes.object,
  idKey: PropTypes.string,
  infoContent: PropTypes.any,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  rows: PropTypes.number,
  values: PropTypes.object,
};

export default CheckList;
