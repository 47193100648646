/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Validations } from "../../constants/validations";
import { EventsService } from "../../services/EventsService";
import Dropdown from "../../stories/Dropdown";
import Loader from "../Shimmer/Loader";
import _ from "lodash";
import { BulletList } from "../../stories/BulletList";
import Button from "../../stories/Button";
import classNames from "classnames";

const RSVPDetails = ({
  stepProps,
  publicProfileData,
  currentMembershipDetails,
  onUpdate,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState({ rsvp: [] });
  const [rsvpEvents, setRsvpEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rsvpOptions, setRsvpOptions] = useState([]);

  const fetchRSVPOptions = async (events = []) => {
    if (events.length < 1) return;
    try {
      const promisesToAwait = [];
      events.forEach((event) => {
        const promisesToAwait1 = [];
        event.daywise_label?.forEach((label, index) => {
          promisesToAwait1.push(
            EventsService.fetchRSVPOptions({
              entity_type: "ListItem",
              entity_id: label.id,
            })
          );
        });
        promisesToAwait.push(Promise.all(promisesToAwait1));
      });
      const responses = await Promise.all(promisesToAwait);
      setRsvpOptions(responses.map((e) => e.map((e) => e.values)));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const response = await EventsService.fetchRSVPEventDetails();
      const tempevents = props.isNewUser
        ? response.events
        : response.events.filter((event) => !event.has_rsvp_registered);
      setRsvpEvents(tempevents);
      fetchRSVPOptions(tempevents);
      setInitialValues((prev) => ({
        ...prev,
        rsvp: tempevents.map((event) =>
          Array(event?.daywise_label?.length ?? 0).fill(null)
        ),
      }));
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchEvents();
  }, []);

  return (
    <div className={classNames(" w-full flex flex-col")}>
      <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
        RSVP - Confirm your Attendance
      </div>
      <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
        NFP Members are requested to make a voluntary contribution of Min ₹0 -
        Max ₹6,000 for Virtual attendance or Min ₹1,000 - Max ₹12,000 for
        Physical attendance during or after Summit. The money collected will be
        utilized for organizing Public Awareness Campaigns on Financial
        Wellbeing and Working with Professionals like you.
      </div>
      {isLoading && (
        <div className="my-6">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Validations.membership.rsvpInfoValidationSchema}
            onSubmit={async (values) => {
              setInitialValues(values);
              if (values.rsvp) {
                const promisesToAwait = [];
                try {
                  values.rsvp.forEach((event_rsvp, event_index) => {
                    const body = {
                      event_id: rsvpEvents[event_index].id,
                      attendances_attributes: rsvpEvents[
                        event_index
                      ].daywise_label.map((e, index) => ({
                        list_item_id: e.id,
                        rsvp_option_id: event_rsvp[index],
                      })),
                    };
                    if (!props.isNewUser) {
                      body["user_id"] = props.publicProfileData?.id;
                    }
                    promisesToAwait.push(
                      EventsService.createRSVP(
                        body,
                        props.isNewUser
                          ? props.userTempTokenRef.current
                          : undefined
                      )
                    );
                  });
                  await Promise.all(promisesToAwait);
                  stepProps.handleNext();
                } catch (e) {
                  if (e.error_code === "already_exists") {
                    stepProps.handleNext();
                    return;
                  }
                  console.log(e);
                }
              }
            }}
          >
            {({ values, ...formProps }) => {
              return (
                <Form className="flex flex-col w-full">
                  {rsvpEvents.map((event, event_index) => (
                    <React.Fragment key={event_index}>
                      <div className="block mt-3 text-sm font-normal tracking-tight text-theme-black-300">
                        <b>Note:&nbsp;</b>Your RSVP will be confirmed and valid
                        only after renewal with an active membership.
                      </div>
                      <div className="mt-3 lg:mt-6">
                        <div className="text-base font-semibold leading-5 tracking-tight text-theme-black-color">
                          RSVP - {event.title}
                        </div>
                        <div className="text-sm tracking-normal mt-0.5 text-theme-black-300">
                          {event.description}
                        </div>
                        <div className="grid grid-cols-4 gap-6 mt-4">
                          {!_.isEmpty(event.time) && (
                            <div className="flex flex-col col-span-2 text-xs">
                              <div className="flex font-semibold text-theme-black-color ">
                                When
                              </div>
                              <div className="flex tracking-tight mt-0.5 text-theme-black-300">
                                <BulletList
                                  list={_.flatten(
                                    event.time.map((p) => p.split("\r\n"))
                                  )}
                                />
                              </div>
                            </div>
                          )}
                          {!_.isEmpty(event.place) && (
                            <div className="flex flex-col col-span-2 text-xs">
                              <div className="flex font-semibold text-theme-black-color ">
                                Where
                              </div>
                              <div className="flex tracking-tight mt-0.5 text-theme-black-300">
                                <BulletList
                                  list={_.flatten(
                                    event.place.map((p) => p.split("\r\n"))
                                  )}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="grid grid-cols-2 gap-6 mt-6">
                          {event?.daywise_label?.map((rsvp, label_index) => (
                            <div key={`${event_index}.${label_index}`}>
                              <Dropdown
                                name={`rsvp.${event_index}.${label_index}`}
                                id={`rsvp.${event_index}.${label_index}`}
                                type="text"
                                label={rsvp.text_content}
                                placeholder="Confirm your Attendance"
                                options={
                                  rsvpOptions?.[event_index]?.[label_index] ??
                                  []
                                }
                                displayCurrentOption={true}
                                isSearchable={false}
                                displayKey="value"
                                idKey="id"
                                isRequired
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="grid grid-cols-1 gap-6 mt-6 lg:grid-cols-2">
                    <Button
                      buttonStyle="gray"
                      height="40px"
                      className="order-2 lg:order-1"
                      onClick={async () => {
                        stepProps.handleBack();
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      buttonStyle="primary"
                      height="40px"
                      width="100%"
                      className="order-1 lg:order-2"
                      disabled={formProps.isSubmitting}
                      type="submit"
                    >
                      NEXT
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

RSVPDetails.propTypes = {
  currentMembershipDetails: PropTypes.any,
  onUpdate: PropTypes.any,
  options: PropTypes.any,
  publicProfileData: PropTypes.any,
  stepProps: PropTypes.any,
};

export default RSVPDetails;
