/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ContentService } from "../../services/ContentService";
import { EventsService } from "../../services/EventsService";
import Loader from "../Shimmer/Loader";
import ContactForm from "./ContactForm";
import Events from "./Events";
import Footer from "./Footer";
import Header from "./Header";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import _ from "lodash";
import { User } from "../../store";

const LandingPage = () => {
  const [loading, setIsLoading] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [programs, setPrograms] = useState({});
  const [content, setContent] = useState({});
  const isLoggedIn = !!User.getToken();
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchPrograms();
    await fetchTestimonials();
    await fetchContent();
    setIsLoading(false);
  };
  const fetchContent = async () => {
    const response = await ContentService.fetchLandingPageContent();
    const data = response.dynamic_contents;
    const pricingData = data.filter((d) =>
      ["basic", "community", "qpfp", "pro_membership"].includes(d.kind)
    );
    const tooltipData = data
      .filter((d) => d.kind === "tool_tip")
      .map((e) => ({ ...e, section: _.last(e.section.split("_")) }));
    let otherData = data.filter((d) => d.kind === "other");
    otherData = _.mapValues(_.keyBy(otherData, "section"), "value");

    const contactFormData = data.filter(
      (d) => d.kind === "landing_contact_form"
    );
    const contactFormObj = {};
    contactFormData.forEach((d) => {
      if (!contactFormObj[d.section]) {
        contactFormObj[d.section] = [d.value];
        return;
      }
      contactFormObj[d.section].push(d.value);
    });

    const pricingObj = {};
    const pricingDescription = {};
    pricingData.forEach((d) => {
      if (d.section.toLowerCase() === "description") {
        pricingDescription[d.kind] = d.value;
        return;
      }
      if (d.section.includes("||")) {
        const key1 = d.section.split("||")[0].trim();
        const key2 = d.section.split("||")[1].trim();
        const order1 = d.order.split(".")[0];
        const order2 = d.order.split(".")[1];

        if (!pricingObj[key1]) {
          pricingObj[key1] = {
            heading: key1.trim(),
            data: {},
            order: parseInt(order1),
          };
        }
        if (!pricingObj[key1]["data"][key2]) {
          pricingObj[key1]["data"][key2] = {
            label: key2,
            tooltip: _.head(
              tooltipData.filter((e) => e.section.trim() === key2)
            )?.value,
            type: ["true", "false"].includes((d.value ?? "").toLowerCase())
              ? "checkbox"
              : false,
            basic: false,
            community: false,
            qpfp: false,
            pro_membership: false,
            order: parseInt(order2),
          };
        }
        pricingObj[key1]["data"][key2][d.kind] =
          (d.value ?? "").toLowerCase() === "true"
            ? true
            : (d.value ?? "").toLowerCase() === "false"
            ? false
            : d.value ?? "";
      }
    });
    setContent({
      pricing: _.sortBy(
        _.values(pricingObj).map((e) => ({
          heading: e.heading,
          order: e.order,
          data: _.sortBy(_.values(e.data), "order"),
        })),
        "order"
      ),
      pricingDescription: pricingDescription,
      other: otherData,
      contactFormData: contactFormObj,
    });
  };

  const fetchPrograms = async () => {
    try {
      const response = await EventsService.fetchAllActivePrograms();
      setPrograms(response);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchTestimonials = async () => {
    try {
      const response = await EventsService.fetchTestimonials();
      setTestimonials(response.testimonials);
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Header content={content.other} isLoggedIn={isLoggedIn} />
      <Pricing
        content={content.pricing}
        programs={programs}
        description={content.pricingDescription}
      />
      <Events programs={programs} />
      <Testimonials testimonials={testimonials} />
      <ContactForm content={content.contactFormData} />
      <Footer />
    </div>
  );
};

export default LandingPage;
