import classNames from "classnames";
import Pagination from "../../Events/Pagination";
import PropTypes from "prop-types";
import _ from "lodash";
import { CircleNotch } from "@phosphor-icons/react";

const LeaderBoardTable = ({
  columns = [],
  data = [],
  isLoading,
  totalEntries,
  onPageChange,
  entriesPerPage,
  currentPage,
}) => {
  const getBorder = (i, columnLength, isRowSelectable) => {
    if (i === 0 && !isRowSelectable) {
      return "rounded-tl";
    } else if (i === columnLength - 1) {
      return "rounded-tr";
    }
    return "";
  };
  return (
    <table className="w-full table-auto">
      <thead>
        <tr className={"bg-theme-black-color"}>
          {columns.map((item, i) => {
            return (
              <th
                key={`${item.label}-${i}`}
                scope="col"
                className={classNames(
                  "px-6 py-3 text-xs font-medium uppercase bg-theme-black-color border-b text-white",
                  item.headingClassname || "",
                  getBorder(i, columns.length, false),
                  item.align === "center"
                    ? "text-center"
                    : item.align === "right"
                    ? "text-right"
                    : "text-left"
                )}
              >
                {item.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr className="w-full h-40">
            <td colSpan={columns.length}>
              <CircleNotch
                className="mx-auto animate-spin"
                size={30}
                weight="fill"
              />
            </td>
          </tr>
        ) : _.isEmpty(data) ? (
          <tr className="border-b border-gray-200 text-theme-black-color">
            <td
              colSpan={columns.length}
              className="px-6 py-6 text-sm text-center"
            >
              No data
            </td>
          </tr>
        ) : (
          data?.map((item, i) => {
            return (
              <tr
                key={`id-${Math.random().toString(16).slice(2)}-${i}`}
                className="border-b border-gray-200 text-theme-black-color"
              >
                {columns.map((col, j) => {
                  if (col.renderCell)
                    return (
                      <td
                        key={`td-${col.name ?? col.id}-${
                          item.id ??
                          item.uid ??
                          "id-" + Math.random().toString(16).slice(2)
                        }-${i}`}
                        className={classNames(
                          "px-6 py-6 min-w-[150px] text-sm",
                          col.rowCellClassName,
                          col.align === "center"
                            ? "text-center"
                            : col.align === "right"
                            ? "text-right"
                            : "text-left"
                        )}
                      >
                        {col.renderCell({
                          rowValue: item,
                          column: col,
                          rowIndex: (currentPage - 1) * entriesPerPage + i,
                          columnIndex: j,
                        })}
                      </td>
                    );
                  return (
                    <td
                      key={`td1-${
                        item.id ??
                        item.uid ??
                        "id-" + Math.random().toString(16).slice(2)
                      }-${j}`}
                      className={classNames(
                        "px-6 min-w-[150px] py-6 text-sm",
                        col.rowCellClassName,
                        col.align === "center"
                          ? "text-center"
                          : col.align === "right"
                          ? "text-right"
                          : "text-left"
                      )}
                    >
                      {item[col.name]}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
      {!_.isEmpty(data) && (
        <tfoot>
          <tr className="">
            <td colSpan={columns.length} className="pt-10 pb-6 px-4">
              <Pagination
                totalEntries={totalEntries}
                entriesPerPage={entriesPerPage}
                onPageChange={onPageChange}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

LeaderBoardTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  totalEntries: PropTypes.number,
  entriesPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
};

export default LeaderBoardTable;
