import classNames from "classnames";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";

const OTPField = ({ className = "", index = 0 }) => {
  const [field] = useField({ name: `otp.${index}` });
  return (
    <input
      {...field}
      className={classNames(
        "flex flex-col items-center justify-center w-full h-full px-5 text-lg text-center bg-white border border-gray-200 outline-none otpInput rounded-xl focus:bg-gray-50 focus:ring-1 ring-blue-700",
        className
      )}
      type="text"
      name={`otp.${index}`}
      id={`otp.${index}`}
      maxLength={1}
      onChange={(e) => {
        const inputs = [...document.querySelectorAll("input.otpInput")];
        field.onChange(e);
        const index = inputs.indexOf(e.target);
        if (e.target.value) {
          if (index < inputs.length - 1) {
            inputs[index + 1].focus();
          }
        }
      }}
      onFocus={(e) => {
        e.target.select();
      }}
      onKeyUp={(e) => {
        if (
          !/^[0-9]{1}$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Delete" &&
          e.key !== "Tab" &&
          !e.metaKey
        ) {
          e.preventDefault();
        }
        const inputs = [...document.querySelectorAll("input.otpInput")];
        if (e.key === "Delete" || e.key === "Backspace") {
          const index = inputs.indexOf(e.target);
          if (index > 0) {
            inputs[index - 1].value = "";
            inputs[index - 1].focus();
          }
        }
      }}
    />
  );
};

OTPField.propTypes = {
  className: PropTypes.string,
  first: PropTypes.bool,
  index: PropTypes.number,
  last: PropTypes.bool,
};

export default OTPField;
