import PropTypes from "prop-types";
import React from "react";
import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import QPFPFullOrangeLogoTransparent from "../../../assets/images/QPFPFullOrangeLogoTransparent.png";
import QPFPFullWhiteLogoTransparent from "../../../assets/images/QPFPFullWhiteLogoTransparent.png";
import QPFPSquareOrangeLogoTransparent from "../../../assets/images/QPFPSquareOrangeLogoTransparent.png";
import QPFPSquareWhiteLogoTransparent from "../../../assets/images/QPFPSquareWhiteLogoTransparent.png";
import classNames from "classnames";

const benefits = [
  {
    heading: "Enhanced Credibility",
    description:
      "The QPFP logo and designation signal to clients and colleagues that you possess a high level of expertise and commitment to ethical financial planning.",
  },
  {
    heading: "Professional Recognition",
    description:
      "Displaying the QPFP mark helps distinguish you from peers, showcasing your dedication to continuous professional development.",
  },
  {
    heading: "Client Trust",
    description:
      "The QPFP certification reassures clients of your proficiency and adherence to industry standards, fostering trust and confidence in your services.",
  },
];

const tips = [
  {
    heading: "Consistency",
    description:
      "Use the QPFP logo and designation consistently across all professional materials.",
  },
  {
    heading: "Placement",
    description:
      "Position the logo in a prominent but not overpowering location, ensuring it complements your personal branding.",
  },
  {
    heading: "Quality",
    description:
      "Always use high-resolution images for print and digital formats to maintain a professional appearance.",
  },
];

const QPFPBrandGuidlines = () => {
  return (
    <QPFPSkeleton>
      <div className="flex flex-col w-full px-6 py-10 relative z-0">
        <div className="font-bold text-2xl">
          QPFP Brand Resources and Guidelines
        </div>
        <div className="text-sm text-theme-black-300 mt-3">
          As a Qualified Personal Finance Professional (QPFP), you have earned
          the right to proudly display your certification. This guide provides
          practical examples and tips on where and how to use the QPFP mark,
          logo, and designation to enhance your professional presence and
          credibility.
        </div>
        <div className="my-10 font-bold text-xl text-theme-black-color">
          Where to Use the QPFP Mark, Logo, and Designation
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div className="flex col-span-full flex-col items-start gap-3">
            <div className="font-bold text-base text-theme-black-color">
              1. Professional Documentation
            </div>
            <div className="text-theme-black-color text-base">
              Example: Business Cards
            </div>
            <div className="text-theme-black-color text-sm font-medium">
              Usage:
            </div>
            <ul className="list-disc list-inside text-theme-black-300">
              <li className="pl-2">
                Include the QPFP logo alongside your name and contact details.
              </li>
              <li className="pl-2">
                Use the designation “Qualified Personal Finance Professional”
                beneath your name.
              </li>
            </ul>
          </div>
          <QPFPBrandImageCard
            imageComponent={
              <img
                src={QPFPFullOrangeLogoTransparent}
                className="h-48 mx-auto object-contain"
                alt="QPFP Orange Logo Transparent"
              />
            }
            heading="Download the Full Orange Logo"
            description="High-resolution Full Orange logo"
            imageSrc={QPFPFullOrangeLogoTransparent}
          />
          <QPFPBrandImageCard
            type="orange"
            imageComponent={
              <img
                src={QPFPFullWhiteLogoTransparent}
                className="h-48 mx-auto object-contain"
                alt="QPFP White Logo Transparent"
              />
            }
            heading="Download the Full White Logo"
            description="High-resolution Full White logo"
            imageSrc={QPFPFullWhiteLogoTransparent}
          />
          <QPFPBrandImageCard
            imageComponent={
              <img
                src={QPFPSquareOrangeLogoTransparent}
                className="h-48 mx-auto object-contain"
                alt="QPFP Orange Logo Transparent"
              />
            }
            heading="Download Square Orange Logo"
            description="Download Square Orange Logo"
            imageSrc={QPFPSquareOrangeLogoTransparent}
          />
          <QPFPBrandImageCard
            type="orange"
            imageComponent={
              <img
                src={QPFPSquareWhiteLogoTransparent}
                className="h-48 mx-auto object-contain"
                alt="QPFP White Logo Transparent"
              />
            }
            heading="Download Square White Logo"
            description="Download Square White Logo"
            imageSrc={QPFPSquareWhiteLogoTransparent}
          />
          <div className="col-span-full text-theme-black-300 text-sm font-medium">
            <span className="font-medium text-theme-black-color">Tips: </span>
            Ensure the logo is high-resolution and prominently placed to attract
            attention.
          </div>
        </div>
        <div className="grid grid-cols-1 mt-10 gap-8">
          <div className="flex col-span-full flex-col items-start gap-3">
            <div className="font-bold text-base text-theme-black-color">
              2. Professional Documentation
            </div>
            <div className="text-theme-black-color text-base">
              Example: Business Cards
            </div>
            <div className="text-theme-black-color text-sm font-medium">
              Usage:
            </div>
            <ul className="list-disc list-inside text-theme-black-300">
              <li className="pl-2">
                Include the QPFP logo alongside your name and contact details.
              </li>
              <li className="pl-2">
                Use the designation “Qualified Personal Finance Professional”
                beneath your name.
              </li>
            </ul>
          </div>
          <QPFPBrandImageCard
            imageComponent={
              <img
                src={QPFPFullOrangeLogoTransparent}
                className="h-48 mx-auto object-contain"
                alt="QPFP Orange Logo Transparent"
              />
            }
            showDownload={false}
            className="col-span-full"
          />
          <div className="col-span-full text-theme-black-300 text-sm font-medium">
            <span className="font-medium text-theme-black-color">Tips: </span>
            Ensure the logo is high-resolution and prominently placed to attract
            attention.
          </div>
        </div>
        <div className="grid grid-cols-1 mt-10 gap-8">
          <div className="flex col-span-full flex-col items-start gap-3">
            <div className="font-bold text-base text-theme-black-color">
              3. Professional Documentation
            </div>
            <div className="text-theme-black-color text-base">
              Example: Business Cards
            </div>
            <div className="text-theme-black-color text-sm font-medium">
              Usage:
            </div>
            <ul className="list-disc list-inside text-theme-black-300">
              <li className="pl-2">
                Include the QPFP logo alongside your name and contact details.
              </li>
              <li className="pl-2">
                Use the designation “Qualified Personal Finance Professional”
                beneath your name.
              </li>
            </ul>
          </div>
          <QPFPBrandImageCard
            imageComponent={
              <img
                src={QPFPFullOrangeLogoTransparent}
                className="h-48 mx-auto object-contain"
                alt="QPFP Orange Logo Transparent"
              />
            }
            showDownload={false}
            className="col-span-full"
          />
          <div className="col-span-full text-theme-black-300 text-sm font-medium">
            <span className="font-medium text-theme-black-color">Tips: </span>
            Ensure the logo is high-resolution and prominently placed to attract
            attention.
          </div>
        </div>
        <div className="mt-15 p-8 flex flex-col items-start bg-white">
          <div className="text-theme-black-color text-xl font-bold">
            Benefits of Displaying Your QPFP Certification
          </div>
          <div className="grid grid-cols-3 gap-6 mt-6">
            {benefits.map((benefit, index) => (
              <div
                key={benefit}
                className="bg-[#FFBE5C33] border border-theme-orange/20 text-theme-black-color p-3 flex flex-col items-start"
              >
                <div className="font-bold text-base">
                  {index + 1}. {benefit.heading}:
                  <div className="font-normal pl-4">{benefit.description}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-theme-black-color text-xl font-bold mt-10">
            Design Tips for Using the QPFP Mark, Logo, and Designation
          </div>
          <ul className="list-disc list-inside mt-6">
            {tips.map((tip, index) => (
              <li key={index} className="text-sm leading-8">
                <b>{tip.heading}:</b> {tip.description}
              </li>
            ))}
          </ul>
        </div>
        <div className="h-24" />
      </div>
    </QPFPSkeleton>
  );
};

export default QPFPBrandGuidlines;

const QPFPBrandImageCard = ({
  type = "white",
  imageComponent,
  showDownload = true,
  className = "",
  heading,
  description,
  imageSrc,
}) => {
  return (
    <div
      className={classNames(
        "border border- gap-10 flex flex-col items-start py-10 px-10",
        type === "white"
          ? "bg-slate-100 text-theme-black-color"
          : "bg-[#F15922] text-white",
        className
      )}
    >
      <div className="w-full">{imageComponent}</div>
      {showDownload && (
        <div className="flex flex-col items-start gap-4">
          <div className="font-bold text-xl">{heading}</div>
          <div className="text-base">{description}</div>
          <a
            href={imageSrc ?? "#"}
            download={description}
            className={classNames(
              "md:max-w-[111px] w-full !h-8 lg:!h-10 !rounded-md flex items-center justify-center",
              type === "white"
                ? "bg-theme-red text-white"
                : "bg-white text-theme-red"
            )}
          >
            Download
          </a>
        </div>
      )}
    </div>
  );
};

QPFPBrandImageCard.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  imageComponent: PropTypes.element,
  imageSrc: PropTypes.any,
  showDownload: PropTypes.bool,
  type: PropTypes.string,
};
