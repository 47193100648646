/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import PaymentForm from "../../Payments/PaymentForm";
import { PaymentServices } from "../../../services/PaymentServices";
import { CustomToast } from "../../../stories/Toast";
import Button from "../../../stories/Button";
import { Validations } from "../../../constants/validations";
import classNames from "classnames";
import { useProfile } from "../../../context/userProvider";

const PaymentScreen = ({
  stepProps,
  corporateProfileData,
  onUpdate,
  ...props
}) => {
  const submitRef = React.useRef();
  const [entityProps, setEntityProps] = useState({});
  const [priceDetails, setPriceDetails] = useState({});
  const { userDetails } = useProfile();

  const [initialValues, setInitialValues] = useState({
    part_payment_order: 0,
    firmName: "",
    wantGST: true,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState_id: "",
  });

  useEffect(() => {
    if (corporateProfileData.payment) {
      setPriceDetails(corporateProfileData.payment);
      if (corporateProfileData.payment.gst_details) {
        setInitialValues((prev) => ({
          ...prev,
          wantGST: true,
          gstin: corporateProfileData.payment.gst_details.gst_number,
          gstFirmName: corporateProfileData.payment.gst_details.firm_name,
          gstCommunicationAddress:
            corporateProfileData.payment.gst_details.address.line_1,
          gstPincode: corporateProfileData.payment.gst_details.address.pin,
          gstCity_id: corporateProfileData.payment.gst_details.address.city_id,
          gstCity: corporateProfileData.payment.gst_details.address.city,
          gstState_id:
            corporateProfileData.payment.gst_details.address.state_id,
        }));
      }
      setEntityProps({
        entity_type: "firm",
        entity_id: null,
      });
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    await fetchGSTDetails();
    await fetchPricing({
      part_payment_order: initialValues.part_payment_order,
      discount_codes: [],
    });
  };
  const fetchGSTDetails = async () => {
    if (props.isNewUser) return null;
    try {
      const response = await PaymentServices.fetchPreviousGSTDetails();

      setInitialValues((prevState) => {
        if (response.gst_invoice_check === false) return prevState;
        return {
          ...prevState,
          wantGST: response.gst_invoice_check,
          gstin: response.gst_number,
          gstFirmName: response.firm_name,
          gstCommunicationAddress: response.address.line_1,
          gstPincode: response.address.pin,
          gstCity_id: response.address.city_id,
          gstCity: response.address.city,
          gstState_id: response.address.state_id,
        };
      });
    } catch (e) {
      console.log("no previous details found");
    }
  };
  const fetchPricing = async ({
    part_payment_order = initialValues.part_payment_order,
    discount_codes = [],
  }) => {
    const response = await PaymentServices.fetchPricingDetails({
      entity_type: "firm",
      entity_id: null,
      part_payment_order: part_payment_order,
      discount_codes: discount_codes,
    });
    setPriceDetails(response);
    setEntityProps({
      entity_type: "firm",
      entity_id: null,
    });
  };
  const makePayment = async (values) => {
    let postBody = {
      pricing_id: priceDetails.pricing_id,
      coupon_ids: priceDetails.coupons?.map((e) => e.code),
      payment_gateway: "razorpay",
      course_fees: priceDetails.course_fees,
      gst: priceDetails.gst,
      to_be_paid: priceDetails.to_be_paid,
    };
    if (values.wantGST) {
      postBody = {
        ...postBody,
        gst_invoice_check: values.wantGST,
        gst_number: values.gstin,
        firm_name: values.gstFirmName,
        address_attributes: {
          line_1: values.gstCommunicationAddress,
          city_id: values.gstCity_id,
          pin: values.gstPincode,
        },
      };
    }

    const orderDetails = await PaymentServices.createOrder({
      purchase: postBody,
      entity_type: "firm",
      entity_id: corporateProfileData?.firm?.id,
    });

    if (orderDetails && orderDetails.payment_status === "initiated") {
      await PaymentServices.makeRazorpayPayment(
        {
          name: `{$corporateProfileData.firm.name} - ${userDetails.first_name} ${userDetails.last_name}`,
          email: userDetails.email,
          contact: userDetails.phone_number,
        },
        orderDetails,
        () => {
          CustomToast.success("Payment Successful" ?? "");
          stepProps.handleNext();
          onUpdate();
        }
      );
    } else if (orderDetails && orderDetails.payment_status === "success") {
      CustomToast.info(orderDetails.message);
      stepProps.handleNext();
    } else {
      CustomToast.error(orderDetails.message);
    }
  };
  return (
    <div className={classNames("flex")}>
      <div
        className={classNames(
          "max-w-3xl lg:min-w-[764px] w-full flex flex-col p-3 bg-white rounded-sm shadow-sm lg:p-4"
        )}
      >
        <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
          {corporateProfileData.firm?.status !== "initiated"
            ? "Payment Success"
            : "Payment"}
        </div>
        <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
          {corporateProfileData.firm?.status !== "initiated"
            ? "Your account has been charged and your transaction is successful."
            : "Make payment for corporate registration."}
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            Validations.membership.paymentDetailsValidationSchema
          }
          onSubmit={async (values) => {
            try {
              const result1 = await makePayment(values);
              return result1;
            } catch (e) {
              console.log(e);
              CustomToast.error(e.error_messages[0]);
              if (e.error_code === "already_paid") {
                stepProps.handleNext();
              }
              console.log("error saving the form");
              return e;
            }
          }}
        >
          {({ values, ...formProps }) => {
            return (
              <Form className="w-full">
                <PaymentForm
                  programTitle="Corporate Account Registration Fee"
                  allowPartPayment={false}
                  entityProps={entityProps}
                  priceDetails={priceDetails}
                  setPriceDetails={setPriceDetails}
                  submitRef={submitRef}
                  formikProps={{ values, ...formProps }}
                  isReadOnly={corporateProfileData.payment}
                  cta={
                    corporateProfileData.payment ? (
                      <Button
                        buttonStyle="primary"
                        height="40px"
                        width="100%"
                        className="mt-3"
                        onClick={async () => {
                          stepProps.handleNext();
                        }}
                      >
                        SUBMIT DOCUMENTS
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          buttonStyle="primary"
                          height="40px"
                          width="100%"
                          className="mt-3"
                          disabled={formProps.isSubmitting}
                          onClick={async () => {
                            await submitRef.current?.click();
                          }}
                        >
                          PAY NOW
                        </Button>
                        <Button
                          buttonStyle="transparent-textonly-secondary"
                          height="40px"
                          width="100%"
                          className="mt-3"
                          onClick={async () => {
                            stepProps.changeStep(1);
                          }}
                        >
                          BACK
                        </Button>
                      </React.Fragment>
                    )
                  }
                />
                <button className="hidden" ref={submitRef} type="submit">
                  submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

PaymentScreen.propTypes = {
  currentMembershipDetails: PropTypes.shape({
    email: PropTypes.any,
    first_name: PropTypes.string,
    last_name: PropTypes.any,
    mobile_number: PropTypes.any,
    payment: PropTypes.shape({
      gst_details: PropTypes.shape({
        address: PropTypes.shape({
          city: PropTypes.any,
          city_id: PropTypes.any,
          line_1: PropTypes.any,
          pin: PropTypes.any,
          state: PropTypes.any,
        }),
        firm_name: PropTypes.any,
        gst_number: PropTypes.any,
      }),
    }),
    status: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
  stepProps: PropTypes.shape({
    changeStep: PropTypes.func,
    handleNext: PropTypes.func,
  }),
};

export default PaymentScreen;
