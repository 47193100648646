import React from "react";

import Button from "../../../stories/Button";
import { TextWithCheckMark } from "./ManageProfile";

import QPFPFullOrangeLogoTransparent from "../../../assets/images/QPFPFullOrangeLogoTransparent.png";

/**
 * Component for Brand Resources And Guide Dashboard Element
 * @return {React.ReactElement} Brand Resources And Guide Dashboard Element
 */
function BrandResourcesAndGuide() {
  const brandGuidelines = [
    "Use in your business card",
    "Use in your social media",
    "Use in your marketing collaterals",
    "Use in your professional bio",
  ];
  return (
    <div className="flex flex-col items-start space-y-3">
      <img
        alt="network fp orange logo"
        src={QPFPFullOrangeLogoTransparent}
        className="h-15"
      />
      <p className="font-normal text-md">Use QPFP Marks the right way</p>
      <p className="font-light text-xs text-[#525252]">
        As a QPFP Certificant, you have earned the permission to use QPFP marks,
        logo and designation to establish your credentials with clients and
        public. Here is a guide to
      </p>

      <div className="flex flex-col space-y-2">
        {brandGuidelines?.map((guideline, index) => (
          <TextWithCheckMark key={`guideline-${index}`} label={guideline} />
        ))}
      </div>

      <p className="font-light text-xs text-[#525252]">
        Read the guide and download the high resolution logo here.
      </p>

      <a href={QPFPFullOrangeLogoTransparent} download="QPFP Brand Logo">
        <Button className="!rounded-md">Download</Button>
      </a>
    </div>
  );
}

export default BrandResourcesAndGuide;
