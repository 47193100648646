import PropTypes from "prop-types";
import { CalendarBlank, MapPin, Wallet } from "@phosphor-icons/react";
import React from "react";
import ImageComponent from "../../stories/ImageComponent";
import classNames from "classnames";

const Events = ({ programs = {} }) => {
  if ((programs.event_courses ?? [])?.length === 0) return null;
  return (
    <div className="flex flex-col items-center px-5 py-16 bg-sky-100 lg:px-28">
      <div className="text-4xl font-semibold text-center lg:font-bold lg:text-6xl text-theme-black-color">
        Workshops & Trainings
      </div>
      <div className="mt-4 text-lg font-light text-center lg:font-normal text-neutral-800">
        Designed to help financial advisors, accelerate the growth of your
        practice, improve operational efficiency, and give significant value to
        your clients.
      </div>
      <div className="grid w-full grid-cols-1 gap-5 mt-6 lg:gap-10 lg:mt-10 lg:grid-cols-3">
        {programs.event_courses?.map((program) => (
          <div key={program.id} className="flex flex-col bg-white">
            <div className="flex flex-col p-6">
              <ImageComponent
                src={program.banner_image}
                width="100%"
                errorComponent={<div className="w-full !bg-white h-0"></div>}
                height="auto"
                className="object-contain !bg-white max-h-24 mb-6"
              />
              <div className="text-lg font-bold">{program.actual_name}</div>
              <div className="flex flex-col mt-4 gap-2.5">
                <div className="flex items-center gap-2.5">
                  <CalendarBlank className="text-theme-orange-400 w-4 min-w-[16px]" />
                  {program.start_date}
                </div>
                <div className="flex items-center gap-2.5">
                  <MapPin className="text-theme-orange-400 w-4 min-w-[16px]" />
                  {program.session_mode}
                </div>
                <div className="flex items-center gap-2.5">
                  <Wallet className="text-theme-orange-400 w-4 min-w-[16px]" />₹
                  {program.course_fees} + gst
                </div>
              </div>
            </div>
            <div className="w-full border border-slate-400/30"></div>
            <div className="flex flex-col justify-between h-full p-6">
              <div className="flex flex-col items-start w-full">
                {(program.highlights ?? []).length !== 0 && (
                  <div className="text-xs font-medium uppercase text-slate-400">
                    Highlights
                  </div>
                )}
                {(program.highlights ?? []).length !== 0 && (
                  <ul className="gap-1.5 pl-3 mt-2 text-base flex flex-col list-disc">
                    {program.highlights.map((h) => (
                      <li key={h.id}>{h.name}</li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="flex flex-col items-center w-full">
                <a
                  href={`/get-started?ref=event__${program.event_id}`}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    "w-full flex justify-center items-center text-base font-bold text-white uppercase rounded h-11 bg-theme-orange-400",
                    (program.highlights ?? []).length !== 0 && "mt-8"
                  )}
                >
                  register
                </a>
                {program.detail_link ? (
                  <a
                    href={program.detail_link || ""}
                    target="_blank"
                    rel="noreferrer"
                    className="px-5 mx-auto my-2.5 text-base capitalize bg-white hover:underline w-fit underline-offset-1 text-theme-orange-400"
                  >
                    know more
                  </a>
                ) : (
                  <span className="h-0 lg:h-11"></span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Events.propTypes = {
  programs: PropTypes.object,
};

export default Events;
