/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import { useEffect, useState } from "react";
import API from "../api/manager";
import config from "../api/config";

const useLeaderboard = (
  courseId,
  page,
  searchQuery,
  entriesPerPage = 10,
  setIsLoading
) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [lastSearchQuery, setLastSearchQuery] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setError(false);

    if (lastPage !== page || lastSearchQuery !== searchQuery) {
      setLastPage(page);
      if (lastSearchQuery !== searchQuery) {
        setLastSearchQuery(searchQuery);
        setData([]);
      }

      API(
        {
          method: "GET",
          url: config.urls.courses.qpfp_leaderboard(courseId),
          params: {
            per_page: entriesPerPage,
            page: page,
            search: searchQuery,
          },
        },
        true
      )
        .then((res) => {
          setData(res.users);
          setTotalEntries(res.pagination.total_count ?? 0);
          setHasMore(!res.pagination.last_page);
          setIsLoading(false);
        })
        .catch((e) => {
          setError(true);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [page, searchQuery]);

  return {
    error,
    data,
    hasMore,
    totalEntries,
  };
};
export default useLeaderboard;
