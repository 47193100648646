const ApiConfig = {
  baseUrl: process.env.REACT_APP_PROD_BASE_API_URL,
  urls: {
    landing: {
      contact_form: "contact_form_landings",
      dynamic_content: "dynamic_contents/landing",
      testimonials: "testimonials",
      all_programs: "courses/courses_list",
    },
    auth: {
      login: "users/login",
      sendOtp: "users/send_otp",
      signup: "users/signup",
      user_onboardings: "users/create_user_onboarding",
      get_started: "visitors",
      validate: "users/validate",
      forgotPassword: "users/forgot_password",
      resetPassword: "users/validate_reset_password",
      verifyEmail: "users/verify_email",
      sendEmailVerificationLink: "users/verification_email",
      getCities: (type) => `locations?location_type=${type}`,
      location: "locations",
      getCityDetails: "regions/city_details",
      getDescriptionOptions:
        "configs/options?sections[]=member_description&sections[]=graduation_status",
      getGradStatusOptions: "configs/options?section=graduation_statuses",
      fetchPincodeDetails: (pin) => `regions/${pin}/details`,
    },
    user: {
      user_details: "users",
      dashboard: "users/dashboard",
      dashboard_courses: "users/dashboard_courses",
      update_password: "users/change_password",
      all_programs: "users/courses_to_register",
      all_voluntary: "voluntary_contributions",
      certificates: "users/certificates",
      delete_user_device: "users/delete_user_device",
      send_dummy_notification: "users/send_dummy_notification",
      fetch_notifications: "notifications/user_notifications",
      read_notifications: (id) => `notifications/${id}/update_status`,
      qpfp_certification_status: "user_certifications/status",
    },
    public_profile: {
      fetch_profile: "users/profile",
      update_profile: "users",
      update_firm_info: "members/firm",
      search_firm: (val) => `firms/search?term=${val}`,
      fetch_dropdowns: "configs/options",
    },
    corporate_profile: {
      update_firm: (id) => `firms/${id ?? ""}`,
      search_firm_users: "users/search",
      get_firm_details: "firms/get_firm_details",
      invite_new_users: "firms/invite_new_user",
      update_firm_invitation_status: (id) =>
        `firm_users/${id}/update_firm_user`,
      delete_firm_users: "firm_users/destroy_firm_users",
    },
    promember: {
      fetch_profile: (type, course_id) =>
        course_id === undefined
          ? `members/user_membership_details?entity_type=${type}`
          : `members/user_membership_details?entity_type=${type}&course_id=${course_id}`,
      create_profile: "members/signup",
      update_profile: "members",
      direct_register: "members/direct_register",
      course_details: "courses/course_info",
    },
    events: {
      register: "events",
      direct_register: "events/direct_register",
      fetch_event: (id) => `events/${id}`,
      fetch_voluntary_detail: (id) => `voluntary_contributions/${id}`,
      create_rsvp: "rsvps",
      fetch_rsvp_events: "rsvps",
      fetch_rsvp_options: "configs/fetch_rsvp_options",
      guest_events: "guest_events?event_type=member&filter=all",
      guest_list: "guests",
      guest_list_export: "guests/export_csv",
      contacts: "contacts",
      contact_export: "contacts/export_csv",
      check_venue_ticket_avl_status: (venue_ticketing_id) =>
        `venue_tickets/${venue_ticketing_id}`,
      check_ticket_avl_status: (ticket_type_id) =>
        `ticket_types/${ticket_type_id}`,
      fetch_delegates: (event_id, query, city_id, page, itemsPerPage) =>
        `events/delegates?event_id=${event_id}&search=${query}&city_id=${city_id}&page=${page}&per_page=${itemsPerPage}`,
    },
    courses: {
      fetch_all_courses: "courses",
      curriculum: (id, search) =>
        `courses/${id}/overview${search !== "" ? "?search=" + search : ""}`,
      course_details: (id) => `courses/${id}`,
      course_lock_details: (id) => `courses/${id}/lock_details`,
      rating_stats: (id) => `courses/${id}/rating_stats`,
      course_content_stats: (id) => `courses/${id}/stats`,
      course_session_stats: (id) => `courses/${id}/session_completions`,
      fetch_resources: (id) => `course_sessions/${id}/resources`,
      update_course_progress: (id) => `courses/${id}/update_user_course`,
      create_user_course: (id) => `courses/${id}/create_user_courses`,
      fetch_comments: "comments",
      post_comments: "comments",
      fetch_reviews: "ratings",
      post_reviews: "ratings",
      quiz: {
        fetch_instruction: (id) => `quizzes/${id}/instructions`,
        quiz_api: (id) => `quizzes/${id}/`,
        update_current_view_question: (id) =>
          `quizzes/${id}/update_view_question`,
        quiz_result: (id) => `quizzes/${id}/quiz_result`,
      },
      fetch_active_memberships: "courses/active_memberships_courses",
      qpfp_quiz_tracker: (course_id) => `qpfp/progress_tracker/${course_id}`,
      qpfp_progress_tracker: (course_id) => `qpfp/user_progress/${course_id}`,
      qpfp_certificates_badges: (course_id) =>
        `qpfp/badges_and_certificates/${course_id}`,
      qpfp_leaderboard: (course_id) => `qpfp/users_leaderboard/${course_id}`,
      ace_programs: "qpfp/ace_programs",
    },
    purchases: {
      fetchPriceDetails: "purchases/compute_price",
      fetchPricing: "voluntary_purchases/compute_price",
      fetchDefaultPriceDetails: (id) => `events/${id}/default_price`,
      entityDetails: (type) => `members/entity_details?program_type=${type}`,
      createOrder: "purchases",
      contribute: "voluntary_contributions/create_voluntary_payment",
      verifyPayment: (id) => `purchases/${id}/status`,
      verifyContribute: (id) => `voluntary_purchases/${id}/status`,
      subscriptions: "users/subscriptions",
      invoices: "users/invoices",
      fetch_gst_details: "purchases/previous_purchase_details",
      add_user_external_purchase_details: "user_external_purchase_details",
      corporate_subscriptions: "firms/user_subscription_details",
      corporate_invoices: "corporate_purchases/invoices",
      fetchCorporatePriceDetails: "corporate_purchases/compute_price",
      createCorporatePurchaseDetail: "corporate_purchases",
      get_admin_payment_requests:
        "corporate_payment_requests/get_payment_requests",
      send_request_to_admin: "corporate_payment_requests",
    },
    ace_points: {
      fetch_quiz: "users/ace_point_quizzes",
    },
    file: {
      upload: "signed_url",
    },
    clientConnect: {
      posts: (postType, filter, page, searchQuery) =>
        filter === null || filter === ""
          ? `posts?per_page=10&post_type=${postType}&page=${page}&search=${
              searchQuery ?? ""
            }`
          : `posts?per_page=10&post_type=${postType}&page=${page}&type=${filter}&search=${
              searchQuery ?? ""
            }`,
      likePost: "votes",
    },
    calendar: {
      fetch_calendar_session: "course_session_contents/fetch_calendar_details",
      add_meetings_to_calendar: "users/sync_with_calender",
    },
    zoom: {
      add_registrant: "course_session_contents/user_zoom_registration",
    },
    oauth2: {
      google: {
        add_identity: "users/auth_credentials",
      },
    },
    exchange_forum: {
      list: "exchange_forums",
      fetch_forum: (id) => `exchange_forums/${id}`,
      fetch_comments: "forum_comments",
      create_comments: "forum_comments",
      create_reply: "forum_comments/create_replies",
      categories: "forum_categories",
      create: "exchange_forums",
    },
  },
};

export default ApiConfig;
