import React, { useCallback, useEffect, useState } from "react";
import OnboardingHeader from "../../stories/OnboardingHeader";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import { AuthService } from "../../services/AuthService";
import { useLocation, useNavigate } from "@reach/router";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { CustomToast } from "../../stories/Toast";
import OTPField from "../../stories/OTPField";
import Button from "../../stories/Button";
import { Form, Formik } from "formik";
import { Validations } from "../../constants/validations";
import ShowFormError from "../../stories/ShowFormError";
import { User } from "../../store";
import { useProfile } from "../../context/userProvider";
import _ from "lodash";
import mixpanel from "mixpanel-browser";
import moment from "moment";

const EmailVerification = (props) => {
  const { state } = useLocation();
  const { updateUserData } = useProfile();
  const navigate = useNavigate();
  useDocumentTitle("Verify Email");
  if (_.isEmpty(state?.email)) {
    navigate("/login");
  }
  const [seconds, setSeconds] = useState(0);

  const timeOutCallback = useCallback(
    () => setSeconds((currTimer) => currTimer - 1),
    []
  );
  const resetTimer = function () {
    if (!seconds) {
      setSeconds(120);
    }
  };
  useEffect(() => {
    seconds > 0 && setTimeout(timeOutCallback, 1000);
  }, [seconds, timeOutCallback]);

  const sendEmailVerificationLink = async (e) => {
    e.preventDefault();
    try {
      const { email } = state;
      const result = await AuthService.sendEmailVerificationLink({ email });
      if (result.message) {
        resetTimer();
        CustomToast.success(result.message);
      } else {
        CustomToast.error(result.errors);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          <div className="flex flex-col">
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Email Verification
            </div>
            <div className="block mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
              Your email is not verified yet. Please enter OTP sent to &nbsp;
              <b>
                *****
                {state?.email
                  ?.split("@")[0]
                  ?.substring(state?.email?.split("@")[0]?.length - 3)}
                @{state?.email.split("@")[1]}
              </b>
              .
            </div>
            <Formik
              initialValues={{ otp: Array(6).fill("") }}
              validationSchema={Validations.onBoarding.otpVerification}
              onSubmit={async (values, { setFieldError }) => {
                try {
                  const otp = values.otp.join("");
                  const result = await AuthService.verifyEmail(
                    state.email,
                    otp
                  );
                  if (result.message) {
                    User.setToken(result.token);
                    updateUserData(true).then((response) => {
                      mixpanel.people.set({
                        distinct_id: response["email"],
                        $email: response["email"],
                        $name: `${response["first_name"]} ${response["last_name"]}`,
                        $city: response["city"],
                        "User ID": response["id"],
                        "Phone number": response["phone_number"],
                        "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
                      });
                      mixpanel.identify(response["email"]);
                      mixpanel.track("Verify Email", {
                        distinct_id: response["email"],
                      });
                    });
                    if (result.src) {
                      const decodedData = JSON.parse(
                        window.atob(result.src.split(".")[1])
                      );
                      if (decodedData.entity_type === "event") {
                        setTimeout(
                          () =>
                            navigate(
                              `/events/${decodedData.entity_id}/register`
                            ),
                          1000
                        );
                      } else {
                        setTimeout(() => navigate("/"), 1000);
                      }
                    } else {
                      setTimeout(() => navigate("/"), 1000);
                    }
                    CustomToast.success(result.message);
                  } else {
                    CustomToast.error(result.errors);
                  }
                } catch (e) {
                  if (e.error_code === "invalid_otp") {
                    setFieldError("otp", "Invalid otp");
                    return;
                  }
                  if (e.error_code === "already_verified" && e.payload) {
                    User.setToken(e.payload.token);
                    updateUserData(true).then((response) => {
                      mixpanel.people.set({
                        distinct_id: response["email"],
                        $email: response["email"],
                        $name: `${response["first_name"]} ${response["last_name"]}`,
                        $city: response["city"],
                        "User ID": response["id"],
                        "Phone number": response["phone_number"],
                        "Last Login": moment().format("YYYY-MM-DDTHH:MM:SS"),
                      });
                      mixpanel.identify(response["email"]);
                      mixpanel.track("Verify Email", {
                        distinct_id: response["email"],
                      });
                    });
                    if (e.payload.src) {
                      const decodedData = JSON.parse(
                        window.atob(e.payload.src.split(".")[1])
                      );
                      if (decodedData.entity_type === "event") {
                        setTimeout(
                          () =>
                            navigate(
                              `/events/${decodedData.entity_id}/register`
                            ),
                          1000
                        );
                      } else {
                        setTimeout(() => navigate("/"), 1000);
                      }
                    } else {
                      setTimeout(() => navigate("/"), 1000);
                    }
                    CustomToast.error(e.error_messages[0]);
                  } else {
                    CustomToast.error(e.error_messages[0]);
                    // setTimeout(() => navigate("/login"), 1000);
                  }
                }
              }}
            >
              <Form className="flex flex-col items-start w-full mt-4">
                <div className="flex flex-row items-center justify-start w-full gap-2">
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField first={true} last={false} index={0} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField first={false} last={false} index={1} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField first={false} last={false} index={2} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField first={false} last={false} index={3} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField first={false} last={false} index={4} />
                  </div>
                  <div className="w-full h-16 xl:w-16 xl:h-16 lg:w-12 lg:h-12">
                    <OTPField first={false} last={true} index={5} />
                  </div>
                </div>
                <ShowFormError name="otp" />
                <div className="flex justify-end w-full mt-3">
                  <Button
                    disabled={seconds > 0}
                    buttonStyle="transparent-textonly-primary"
                    onClick={(e) => sendEmailVerificationLink(e)}
                  >
                    {seconds === 0 ? (
                      "Resend OTP"
                    ) : (
                      <>
                        Resend OTP in{" "}
                        {_.toInteger(seconds / 60) < 10
                          ? `0${_.toInteger(seconds / 60)}`
                          : _.toInteger(seconds / 60)}{" "}
                        :{" "}
                        {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                      </>
                    )}
                  </Button>
                </div>
                <Button
                  type="submit"
                  buttonStyle="primary"
                  height="60px"
                  width="100%"
                  className="mt-6"
                  // loading={loadingLocation || isSubmitting}
                  // disabled={loadingLocation || isSubmitting}
                >
                  VERIFY EMAIL
                </Button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
    </div>
  );
};

export default EmailVerification;
