import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import { BulletCard } from "../../stories/BulletList";
import ImageComponent from "../../stories/ImageComponent";
import Link from "../../stories/Link";
import ProMemberDetailPopup from "../MembershipRegistration/ProMemberDetailPopup";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Circle } from "@phosphor-icons/react";
import classNames from "classnames";
import _ from "lodash";

const EventDetails = ({ isNewUser, eventDetails, selectedTicketType }) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const renderCards = () => {
    return (
      <React.Fragment>
        <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2 ">
          <BulletCard header={<b>When?</b>} list={eventDetails?.whenContent} />
          <BulletCard
            header={<b>Where?</b>}
            list={eventDetails?.whereContent}
          />
          <BulletCard
            header={<b>Who should attend?</b>}
            list={eventDetails?.attendees}
          />
          <BulletCard
            header={<b>What you get?</b>}
            list={eventDetails?.perks}
          />
        </div>
        <BulletCard
          className="border-none"
          header={<b>Contact Us</b>}
          list={[
            eventDetails.contact_name
              ? `${eventDetails.contact_name} - ${eventDetails.contact_phone_number}`
              : `${eventDetails.contact_phone_number}`,
            eventDetails.contact_email,
          ]}
        />
        <div className="items-start justify-between block w-full mt-4 lg:flex-row lg:items-center">
          {eventDetails.detail_link && (
            <React.Fragment>
              <span className="text-lg font-semibold tracking-tight text-theme-black-color">
                Want to know more about{" "}
                {eventDetails.short_title ?? eventDetails.title ?? ""}? &nbsp;
              </span>
              <Link
                href={eventDetails.detail_link}
                target="_blank"
                textColor="text-theme-red"
                activeTextColor="text-theme-red"
                fontSize="lg"
                showUnderLine={false}
                className="font-semibold leading-5"
              >
                Click Here
              </Link>
            </React.Fragment>
          )}
          <span
            className="hidden mt-2 text-xs tracking-tight cursor-pointer lg:flex text-theme-red hover:underline"
            onClick={() => (isNewUser ? window.history.back() : navigate("/"))}
          >
            Back to {isNewUser ? "Get Started" : "Dashboard"}
          </span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-col w-full p-4">
        <div className="flex w-full">
          <ImageComponent
            src={eventDetails?.banner_image ?? ""}
            width="100%"
            errorComponent={<div className="w-full !bg-white h-36"></div>}
            height="auto"
            className="object-contain !bg-white"
          />
        </div>
        <div className="flex mt-4 text-lg font-semibold tracking-tight text-theme-black-color">
          {eventDetails.title ?? eventDetails.short_title ?? ""}
        </div>
        <div className="block mt-1 text-sm tracking-tight text-theme-black-300">
          {eventDetails?.description?.split("\n").map((item, idx) => (
            <span key={idx}>
              {item}
              <br></br>
            </span>
          )) ?? ""}
        </div>
        {eventDetails.ticketing && (
          <ShowTicketDetails
            ticketStats={eventDetails.ticketing}
            selectedTicketType={selectedTicketType}
            eventTitle={eventDetails.short_title}
            eventType={eventDetails.event_type}
            className="mt-4"
          />
        )}
        <div
          className="flex mt-3 text-sm font-semibold leading-none tracking-tight cursor-pointer lg:hidden text-theme-red w-fit"
          onClick={() => setShowPopup(true)}
        >
          VIEW MORE DETAILS
        </div>
        <span
          className="flex mt-3 text-xs tracking-tight cursor-pointer lg:hidden text-theme-red hover:underline"
          onClick={() => (isNewUser ? window.history.back() : navigate("/"))}
        >
          Back to {isNewUser ? "Get Started" : "Dashboard"}
        </span>
        <div className="flex-col hidden lg:flex">{renderCards()}</div>
      </div>
      {showPopup && (
        <ProMemberDetailPopup
          heading={
            eventDetails.short_title ?? eventDetails.title ?? "Event Details"
          }
          handleClose={() => setShowPopup(false)}
          open={showPopup}
          className="!p-0"
          modalBody={renderCards()}
        />
      )}
    </React.Fragment>
  );
};

EventDetails.propTypes = {
  eventDetails: PropTypes.shape({
    attendees: PropTypes.array,
    banner_image: PropTypes.string,
    contact_email: PropTypes.any,
    contact_name: PropTypes.any,
    contact_phone_number: PropTypes.any,
    description: PropTypes.string,
    detail_link: PropTypes.string,
    event_type: PropTypes.any,
    perks: PropTypes.array,
    short_title: PropTypes.string,
    ticketing: PropTypes.array,
    title: PropTypes.string,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
  isNewUser: PropTypes.any,
  selectedTicketType: PropTypes.any,
};

const ShowTicketDetails = ({
  eventType,
  eventTitle = "",
  ticketStats,
  selectedTicketType,
  className = "",
}) => {
  if (_.isEmpty(selectedTicketType))
    return (
      <SelectVenuePlaceholder eventType={eventType} className={className} />
    );

  const venue = (ticketStats ?? []).filter(
    (e) => e.id === selectedTicketType.id
  )[0];
  if (_.isEmpty(venue))
    return (
      <SelectVenuePlaceholder eventType={eventType} className={className} />
    );

  const buffer_tickets =
    selectedTicketType?.ticketing_status?.buffer_tickets ?? 0;
  const total_tickets =
    selectedTicketType?.ticketing_status?.total_tickets ?? 0;
  const available_tickets =
    selectedTicketType?.ticketing_status?.available_tickets ?? 0;
  const total_sold_tickets =
    selectedTicketType?.ticketing_status?.total_sold_tickets ?? 0;

  const final_available_tickets =
    total_sold_tickets + buffer_tickets >= total_tickets
      ? 0
      : available_tickets - buffer_tickets;
  const final_sold_tickets =
    total_sold_tickets + buffer_tickets >= total_tickets
      ? total_tickets
      : total_sold_tickets + buffer_tickets;

  const percent =
    total_tickets === 0
      ? 0
      : _.toInteger((final_sold_tickets * 100) / total_tickets);

  return (
    <div
      className={classNames(
        "relative z-0 w-full border-2 rounded-lg border-theme-orange",
        className
      )}
    >
      <div className="absolute top-0 bottom-0 flex items-center justify-between w-full h-full">
        <div className="w-2.5 h-5 bg-white border-2 border-l-0 rounded-r-[10px] box-border border-theme-orange -ml-0.5" />
        <div className="w-2.5 h-5 bg-white border-2 border-r-0 rounded-l-[10px] box-border border-theme-orange -mr-0.5" />
      </div>
      <div className="z-0 flex flex-col gap-6 p-7">
        <div className="flex text-lg font-bold leading-6 text-theme-orange">
          Ticket Selected - {selectedTicketType?.label?.split("@")[0]}
        </div>
        <div className="flex gap-10">
          <div className="w-20 h-20">
            <CircularProgressbarWithChildren
              minValue={0}
              maxValue={total_tickets}
              value={final_sold_tickets}
              strokeWidth={10}
              counterClockwise={false}
              styles={{
                root: {},
                path: {
                  stroke: "#E10000",
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                },
                trail: {
                  stroke: `#E5E7EB`,
                  strokeLinecap: "butt",
                },
              }}
            >
              <div className="text-lg font-bold leading-none text-center">
                {percent} %
              </div>
              <div className="text-xs font-normal text-theme-gray-500">
                Filled
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="flex flex-col items-start text-theme-black-color gap-2.5">
            <div className="text-sm font-medium ">
              {total_tickets}{" "}
              {final_available_tickets === 0
                ? "Tickets Sold Out"
                : "Tickets Available"}
            </div>
            <div className="text-sm font-medium ">
              <Circle
                size={12}
                weight="fill"
                className="inline mr-2 text-theme-red"
              />
              {final_sold_tickets} Tickets Sold
            </div>
            <div className="text-sm font-medium ">
              <Circle
                size={12}
                weight="fill"
                className="inline mr-2 text-theme-gray-100"
              />
              {final_available_tickets} Tickets Remaining
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShowTicketDetails.propTypes = {
  className: PropTypes.string,
  eventType: PropTypes.string,
  eventTitle: PropTypes.string,
  selectedTicketType: PropTypes.any,
  ticketStats: PropTypes.array,
};

const TicketStatusShimmer = () => {
  return (
    <svg
      viewBox="0 0 364 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="max-w-96"
    >
      <circle cx="40" cy="40" r="40" fill="#EDF2FA" />
      <circle cx="40" cy="40" r="27" stroke="#C2CEE0" strokeWidth="10" />
      <rect x="104" y="15" width="260" height="8" rx="4" fill="#C2CEE0" />
      <rect x="104" y="39" width="211" height="8" rx="4" fill="#EDF2FA" />
      <rect x="104" y="57" width="191" height="8" rx="4" fill="#EDF2FA" />
    </svg>
  );
};

const SelectVenuePlaceholder = ({ eventType, className = "" }) => {
  return (
    <div
      className={classNames(
        "relative z-0 border-[1.5px] rounded-lg border-neutral-300",
        className
      )}
    >
      <div className="absolute top-0 bottom-0 flex items-center justify-between w-full h-full">
        <div className="w-2.5 h-5 bg-white border-[1.5px] border-l-0 rounded-r-[10px] box-border border-neutral-300 -ml-0.5" />
        <div className="w-2.5 h-5 bg-white border-[1.5px] border-r-0 rounded-l-[10px] box-border border-neutral-300 -mr-0.5" />
      </div>
      <div className="flex flex-col items-start gap-6 p-7">
        <div className="text-lg font-semibold leading-5 text-theme-orange">
          Please select{" "}
          {eventType === "national_event" ? "summit ticket" : "venue"} to check
          tickets availability.
        </div>
        <TicketStatusShimmer />
      </div>
    </div>
  );
};

SelectVenuePlaceholder.propTypes = {
  eventType: PropTypes.string,
  className: PropTypes.string,
};

export default EventDetails;
