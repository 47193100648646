import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import play_icon from "../assets/svgs/play.svg";
import PropTypes from "prop-types";

const VideoPlayer = ({ src, startTime, thumbnail, className, ...props }) => {
  const [isReady, setIsReady] = useState(false);
  const currentVideoRef = useRef();
  useEffect(() => {
    if (isReady) {
      currentVideoRef?.current?.seekTo(startTime ?? 0);
    }
  }, [isReady, startTime]);

  return (
    <ReactPlayer
      ref={currentVideoRef}
      config={{
        file: { attributes: { controlsList: "nodownload" } },
        vimeo: {
          playerOptions: {
            // height: "100%",
            // width: "100%",
            responsive: false,
            playsinline: true,
          },
        },
      }}
      onReady={() => setIsReady(true)}
      onContextMenu={(e) => e.preventDefault()}
      url={src}
      className={classNames(className, "react-player", src.includes("vimeo"))}
      controls={true}
      light={thumbnail}
      width="full"
      height="full"
      style={{
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
        backgroundColor: "transparent",
      }}
      playIcon={<img src={play_icon} alt="play" className="w-8 h-8" />}
      {...props}
    />
  );
};
VideoPlayer.defaultProps = {
  src: "",
  startTime: 0,
  thumbnail: "",
  className: "",
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
  startTime: PropTypes.number,
  thumbnail: PropTypes.any,
  className: PropTypes.string,
};

export default VideoPlayer;
