import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Validations } from "../../../constants/validations";
import { useWindowSize } from "../../../hooks/useWindowSize";
import Button from "../../../stories/Button";
import FormBuilder from "../../../stories/FormBuilder";
import { PublicProfileService } from "../../../services/PublicProfileService";
import fileUploadManager from "../../../api/fileUploadManager";

const UploadDocuments = ({ stepProps, firmId, corporateProfileData }) => {
  const [initialValues, setInitialValues] = useState({});
  const submitRef = React.useRef();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;

  useEffect(() => {
    setInitialValues({
      incorporation_document:
        corporateProfileData?.firm?.firm_incorporation_document,
    });
  }, [corporateProfileData]);

  const textInputDetails = [
    {
      label: "Company Incorporation Document",
      name: "incorporation_document",
      placeholder: "Select File",
      suggestion: "Upload Company Incorporation Document",
      accept: "application/pdf, image/*",
      type: isDesktop ? "fileinput1" : "fileinput",
    },
  ];

  const onFormSubmit = async (values) => {
    try {
      let postObj = {};
      if (values.incorporation_document) {
        const res1 = await fileUploadManager({
          file: values.incorporation_document,
        });
        postObj = {
          id: firmId,
          attachments_attributes: [
            {
              id: corporateProfileData?.firm?.firm_incorporation_document?.id,
              file_type: res1.file_type,
              attachment_type: "firm.incorporation_document",
              url: res1.signed_url,
            },
          ],
        };
      } else {
        postObj = {
          id: firmId,
        };
      }
      await PublicProfileService.updateCorporateInfo({
        data: postObj,
        id: firmId,
      });
      stepProps.handleNext();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classNames("flex")}>
      <div
        className={classNames(
          "max-w-3xl lg:min-w-[764px] w-full flex flex-col p-3 bg-white rounded-sm shadow-sm lg:p-4"
        )}
      >
        <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
          Upload Document
        </div>
        <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
          Firm applying for Coporate Account should be a Private Limited Company
          / LLP / Partnership Firm. Please upload company incorporation
          certificate.
        </div>
        {initialValues && (
          <FormBuilder
            validationSchema={
              Validations.corporate.uploadDocumentValidationSchema
            }
            inputFieldsDetails={textInputDetails}
            initialValues={initialValues}
            submitButtonRef={submitRef}
            onFormSubmit={onFormSubmit}
            formClassName="grid w-full grid-cols-1 gap-y-3 mt-3 lg:mt-5 lg:gap-y-5 items-end"
          />
        )}
        <div className="grid grid-cols-1 mt-3 lg:mt-6 lg:grid-cols-2 gap-y-3 lg:gap-x-6">
          <Button
            buttonStyle="gray"
            height="40px"
            className="order-2 lg:order-1"
            onClick={async () => {
              stepProps.handleBack();
            }}
          >
            BACK
          </Button>
          <Button
            buttonStyle="primary"
            height="40px"
            className="order-1 lg:order-2"
            onClick={async () => {
              try {
                await submitRef.current.click();
              } catch (e) {
                console.log("error while subitting form", e);
              }
            }}
          >
            SUBMIT APPLICATION
          </Button>
        </div>
      </div>
    </div>
  );
};

UploadDocuments.propTypes = {
  corporateProfileData: PropTypes.any,
  firmId: PropTypes.any,
  stepProps: PropTypes.shape({
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
  }),
};

export default UploadDocuments;
