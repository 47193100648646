/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import FormBuilder from "../../stories/FormBuilder";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useProfile } from "../../context/userProvider";
import fileUploadManager from "../../api/fileUploadManager";
import { PromemberService } from "../../services/PromemberService";
import { CustomToast } from "../../stories/Toast";
import _ from "lodash";
import { Form, Formik } from "formik";
import CheckBox from "../../stories/CheckBox";
import { Validations } from "../../constants/validations";
import Button from "../../stories/Button";

const DocumentUploadScreen = ({
  stepProps,
  currentMembershipDetails,
  onUpdate,
  entityProps,
  userTempTokenRef,
  ...props
}) => {
  const submitRef = React.useRef();
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const { userDetails } = useProfile();

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const tempInitialValues = {};
    currentMembershipDetails?.certificates?.forEach((e) => {
      if (e.attachment_type === "certification.graduation_certificate") {
        tempInitialValues["graduationCertificate"] = e;
      } else if (
        e.attachment_type === "certification.industry_exam_certificate"
      ) {
        tempInitialValues["industryExamCertificate"] = e;
      } else if (
        e.attachment_type === "certification.professional_education_certificate"
      ) {
        tempInitialValues["professionalCertificate"] = e;
      } else if (e.attachment_type === "certification.photo_id_proof") {
        tempInitialValues["photoId"] = e;
      } else if (e.attachment_type === "certification.profile_photo") {
        tempInitialValues["profilePhoto"] = e;
      }
    });
    setInitialValues({
      ...tempInitialValues,
      entity_type:
        currentMembershipDetails.entity_type ?? entityProps.entity_type,
      entity_id: currentMembershipDetails.entity_id,
    });
  }, [currentMembershipDetails]);

  const onFormSubmit = async (values) => {
    const certification_list_object = {};
    let isDataUpdated = false;
    try {
      if (
        values.graduationCertificate !== undefined &&
        values.graduationCertificate !== null
      ) {
        if (typeof values.graduationCertificate.size === "number") {
          await fileUploadManager({
            file: values.graduationCertificate,
            token: userTempTokenRef.current,
          }).then((res) => {
            isDataUpdated = true;
            certification_list_object["graduation_certificate_attributes"] = {
              id: initialValues.graduationCertificate?.id,
              file_type: res.file_type,
              attachment_type: "certification.graduation_certificate",
              url: res.signed_url,
            };
          });
        } else {
          isDataUpdated =
            values.graduationCertificate?.isFetchedFromProMember ?? false;
          certification_list_object["graduation_certificate_attributes"] = {
            id: initialValues.graduationCertificate?.id,
            file_type: values.graduationCertificate.file_type,
            attachment_type: "certification.graduation_certificate",
            url: values.graduationCertificate.signed_url,
          };
        }
      }
      if (
        values.industryExamCertificate !== undefined &&
        values.industryExamCertificate !== null
      ) {
        if (typeof values.industryExamCertificate.size === "number") {
          await fileUploadManager({
            file: values.industryExamCertificate,
            token: userTempTokenRef.current,
          }).then((res) => {
            isDataUpdated = true;
            certification_list_object["industry_exam_certificate_attributes"] =
              {
                id: initialValues.industryExamCertificate?.id,
                file_type: res.file_type,
                attachment_type: "certification.industry_exam_certificate",
                url: res.signed_url,
              };
          });
        } else {
          isDataUpdated =
            values.industryExamCertificate?.isFetchedFromProMember ?? false;
          certification_list_object["industry_exam_certificate_attributes"] = {
            id: initialValues.industryExamCertificate?.id,
            file_type: values.industryExamCertificate.file_type,
            attachment_type: "certification.industry_exam_certificate",
            url: values.industryExamCertificate.signed_url,
          };
        }
      }
      if (
        values.professionalCertificate !== undefined &&
        values.professionalCertificate !== null
      ) {
        if (typeof values.professionalCertificate.size === "number") {
          await fileUploadManager({
            file: values.professionalCertificate,
            token: userTempTokenRef.current,
          }).then((res) => {
            isDataUpdated = true;
            certification_list_object[
              "professional_education_certificate_attributes"
            ] = {
              id: initialValues.professionalCertificate?.id,
              file_type: res.file_type,
              attachment_type:
                "certification.professional_education_certificate",
              url: res.signed_url,
            };
          });
        } else {
          isDataUpdated =
            values.professionalCertificate?.isFetchedFromProMember ?? false;
          certification_list_object[
            "professional_education_certificate_attributes"
          ] = {
            id: initialValues.professionalCertificate?.id,
            file_type: values.professionalCertificate.file_type,
            attachment_type: "certification.professional_education_certificate",
            url: values.professionalCertificate.signed_url,
          };
        }
      }
      if (values.photoId !== undefined && values.photoId !== null) {
        if (typeof values.photoId.size === "number") {
          await fileUploadManager({
            file: values.photoId,
            token: userTempTokenRef.current,
          }).then((res) => {
            isDataUpdated = true;
            certification_list_object["photo_id_proof_attributes"] = {
              id: initialValues.photoId?.id,
              file_type: res.file_type,
              attachment_type: "certification.photo_id_proof",
              url: res.signed_url,
            };
          });
        } else {
          isDataUpdated = values.photoId?.isFetchedFromProMember ?? false;
          certification_list_object["photo_id_proof_attributes"] = {
            id: initialValues.photoId?.id,
            file_type: values.photoId.file_type,
            attachment_type: "certification.photo_id_proof",
            url: values.photoId.signed_url,
          };
        }
      }
      if (values.profilePhoto !== undefined && values.profilePhoto !== null) {
        if (typeof values.profilePhoto.size === "number") {
          await fileUploadManager({
            file: values.profilePhoto,
            token: userTempTokenRef.current,
          }).then((res) => {
            isDataUpdated = true;
            certification_list_object["profile_photo_attributes"] = {
              id: initialValues.profilePhoto?.id,
              file_type: res.file_type,
              attachment_type: "certification.profile_photo",
              url: res.signed_url,
            };
          });
        } else {
          isDataUpdated = values.profilePhoto?.isFetchedFromProMember ?? false;
          certification_list_object["profile_photo_attributes"] = {
            id: initialValues.profilePhoto?.id,
            file_type: values.profilePhoto.file_type,
            attachment_type: "certification.profile_photo",
            url: values.profilePhoto.signed_url,
          };
        }
      }
      const postBody = {
        entity_type: values.entity_type ?? entityProps.entity_type,
        is_data_updated:
          entityProps.entity_type === "pro_member" &&
          currentMembershipDetails.status === "provisional" &&
          isDataUpdated,
      };
      postBody["certificates_attributes"] = _.values(certification_list_object);
      const result = await PromemberService.updatePromember(
        postBody,
        values.entity_type ?? entityProps.entity_type,
        values.entity_id ?? entityProps.entity_id,
        undefined,
        userTempTokenRef.current
      );
      CustomToast.success(result.message ?? "");
      onUpdate();
      stepProps.handleNext();
      return result;
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0] ?? "");
    }
  };

  const inputFieldsDetails = [
    {
      label: "1. Formal Education",
      name: "graduationCertificate",
      placeholder: "Select File",
      suggestion: "Upload Graduation Completion Certificate.",
      accept: "application/pdf, image/*",
      type: isDesktop ? "fileinput1" : "fileinput",
    },
    {
      label: "2. Industry Education",
      name: "industryExamCertificate",
      placeholder: "Select File",
      suggestion:
        "Upload Passing certificate of any one industry exam as mentioned in form.",
      accept: "application/pdf, image/*",
      type: isDesktop ? "fileinput1" : "fileinput",
      isHidden: () => entityProps.entity_type === "provisional_member",
    },
    {
      label: "3. Professional Education",
      name: "professionalCertificate",
      placeholder: "Select File",
      suggestion:
        "Upload Professional Exams/Qualifications/Certifications Completion Certificate.",
      accept: "application/pdf, image/*",
      type: isDesktop ? "fileinput1" : "fileinput",
      isHidden: () => entityProps.entity_type === "provisional_member",
    },
    {
      label: "4. Photo ID Proof",
      name: "photoId",
      placeholder: "Select File",
      suggestion:
        "Upload Any one Photo ID proof like Aadhar, D.License, VoterID etc.",
      accept: "application/pdf, image/*",
      type: isDesktop ? "fileinput1" : "fileinput",
      isHidden: () => entityProps.entity_type === "provisional_member",
    },
    {
      label: "5. Profile Photo",
      name: "profilePhoto",
      placeholder: "Select File",
      suggestion: "Upload a recent good quality profile photo of yours",
      accept: "application/pdf, image/*",
      type: isDesktop ? "fileinput1" : "fileinput",
      isHidden: () => entityProps.entity_type === "provisional_member",
    },
  ];

  return (
    <div>
      <div className="">
        <div className="flex flex-col w-full">
          <div className="text-lg font-semibold leading-5 tracking-tight text-theme-black-color">
            Submit Documents
          </div>
          <div className="mt-1 text-sm font-normal tracking-tight text-theme-black-300">
            Upload the following documents required to complete your
            registration. The application will be processed by NFP Admin upon
            receipt of all documents. Submit documents within 1 week of
            application
          </div>
        </div>
        <div className="mt-3 text-sm leading-4 tracking-tight text-left">
          <span className="font-semibold text-theme-black">
            Important Note:
          </span>{" "}
          Only .pdf .jpg .jpeg and .png. files are allowed. Max. file size: 2 MB
        </div>
        {entityProps.entity_type !== "pro_member" &&
          ["approved", "overdue"].includes(userDetails.pm_signup_status) && (
            <Formik initialValues={{ isPromember: false }}>
              {({ values, ...formProps }) => (
                <Form>
                  <CheckBox
                    name="isPromember"
                    className="my-4"
                    inputLabel="I am a ProMember (No need to submit any documents)"
                    onChange={async (e) => {
                      formProps.setFieldValue("isPromember", e.target.checked);
                      if (e.target.checked) {
                        const response = await PromemberService.fetchPromember(
                          "pro_member"
                        );
                        const tempInitialValues = {};

                        response.member?.certificates?.forEach((e) => {
                          delete e.id;
                          if (
                            e.attachment_type ===
                            "certification.graduation_certificate"
                          ) {
                            tempInitialValues["graduationCertificate"] = {
                              ...e,
                              isFetchedFromProMember: true,
                            };
                          } else if (
                            e.attachment_type ===
                            "certification.industry_exam_certificate"
                          ) {
                            tempInitialValues["industryExamCertificate"] = {
                              ...e,
                              isFetchedFromProMember: true,
                            };
                          } else if (
                            e.attachment_type ===
                            "certification.professional_education_certificate"
                          ) {
                            tempInitialValues["professionalCertificate"] = {
                              ...e,
                              isFetchedFromProMember: true,
                            };
                          } else if (
                            e.attachment_type === "certification.photo_id_proof"
                          ) {
                            tempInitialValues["photoId"] = {
                              ...e,
                              isFetchedFromProMember: true,
                            };
                          } else if (
                            e.attachment_type === "certification.profile_photo"
                          ) {
                            tempInitialValues["profilePhoto"] = {
                              ...e,
                              isFetchedFromProMember: true,
                            };
                          }
                        });
                        setInitialValues({
                          ...tempInitialValues,
                          entity_type:
                            currentMembershipDetails.entity_type ??
                            entityProps.entity_type,
                          entity_id: currentMembershipDetails.entity_id,
                        });
                      } else {
                        setInitialValues({
                          // ...tempInitialValues,
                          entity_type:
                            currentMembershipDetails.entity_type ??
                            entityProps.entity_type,
                          entity_id: currentMembershipDetails.entity_id,
                        });
                      }
                    }}
                  />
                </Form>
              )}
            </Formik>
          )}
        <FormBuilder
          enableReinitialize
          submitButtonRef={submitRef}
          formClassName="grid w-full grid-cols-1 mt-3 lg:mt-6 gap-y-5 lg:gap-y-5"
          inputFieldsDetails={inputFieldsDetails}
          initialValues={initialValues}
          validationSchema={
            Validations.membership.documentDetailsValidationSchema
          }
          onFormSubmit={async (values) => await onFormSubmit(values)}
        />

        <div className="grid grid-cols-1 gap-6 mt-6 lg:grid-cols-2">
          <Button
            buttonStyle="gray"
            height="40px"
            className="order-2 lg:order-1"
            onClick={async () => {
              stepProps.handleBack();
            }}
          >
            BACK
          </Button>
          <Button
            buttonStyle="primary"
            height="40px"
            width="100%"
            className="order-1 lg:order-2"
            // disabled={formProps.isSubmitting}
            type="submit"
            onClick={() => {
              submitRef.current.click();
            }}
          >
            SUBMIT APPLICATION
          </Button>
        </div>
      </div>
    </div>
  );
};

DocumentUploadScreen.propTypes = {
  currentMembershipDetails: PropTypes.shape({
    certificates: PropTypes.shape({
      forEach: PropTypes.func,
    }),
    entity_id: PropTypes.any,
    entity_type: PropTypes.any,
    status: PropTypes.string,
  }),
  entityProps: PropTypes.shape({
    entity_id: PropTypes.any,
    entity_type: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
  stepProps: PropTypes.shape({
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
  }),
  userTempTokenRef: PropTypes.shape({
    current: PropTypes.any,
  }),
};

export default DocumentUploadScreen;
