/* eslint-disable no-useless-escape */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CustomLink from "./Link";

const bullet = (
  <div className="rounded-full bg-theme-orange w-2 h-2 mr-2 mt-1.5" />
);

const Text = ({
  type,
  content,
  size,
  colorClass,
  className,
  bulletPoint,
  subtext,
  subtextclass,
  children,
  isMultiline = false,
  isSubTextMultiline = false,
  shouldBreakWord = false,
  onClick = () => {},
}) => {
  const textStyle = () => {
    switch (type) {
      case "title":
        return `font-bold ${
          colorClass !== "" ? colorClass : "text-theme-black-color"
        } ${size ? size : "text-3xl"}`;
      case "body":
        return `font-light ${
          colorClass !== "" ? colorClass : "text-gray-body-color"
        } ${size ? size : "text-lg"}`;
      case "text":
        return `${size ? size : "text-lg"}`;
      default:
        return "";
    }
  };
  const linkify = (text) => {
    const urlRegex =
      /^(?!.*(?:\.\.|\-\-))(?:https?:\/\/)?([0-9a-z.-]+\.[a-z]{2,})(?:\/|\?|'|"|:|&|\s|,|`|#|~|}|\*|$)/gi;
    if (text.split) {
      return (
        <>
          {text.split(" ").map((e, i) => {
            if (urlRegex.test(e)) {
              return (
                <CustomLink
                  key={i}
                  target="_blank"
                  textColor={colorClass || "text-theme-red"}
                  href={e.includes("http") ? e : `https://${e}`}
                >
                  {e}&nbsp;
                </CustomLink>
              );
            }
            return `${e} `;
          })}
        </>
      );
    }
    return text;
  };

  return (
    <div
      className={classNames(type ? textStyle() : "", className)}
      onClick={onClick}
    >
      <div className="flex">
        {bulletPoint ? <div>{bullet}</div> : null}
        <div
          className={classNames(
            "flex flex-col",
            shouldBreakWord && "break-all"
          )}
        >
          {children ?? (
            <div>
              {isMultiline
                ? content?.split("\n").map((item, idx) => (
                    <span key={idx}>
                      {linkify(item ?? "")}
                      <br></br>
                    </span>
                  ))
                : linkify(content ?? "")}
            </div>
          )}
          {subtext ? (
            <div
              className={classNames(
                subtextclass ?? "text-base text-neutral-600"
              )}
            >
              {isSubTextMultiline
                ? subtext?.split("\n").map((item, idx) => (
                    <span key={idx}>
                      {linkify(item ?? "")}
                      <br></br>
                    </span>
                  ))
                : linkify(subtext ?? "")}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Text.defaultProps = {
  type: "title",
  className: "",
};

Text.propTypes = {
  bulletPoint: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  colorClass: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMultiline: PropTypes.bool,
  isSubTextMultiline: PropTypes.bool,
  onClick: PropTypes.func,
  shouldBreakWord: PropTypes.bool,
  size: PropTypes.string,
  subtext: PropTypes.string,
  subtextclass: PropTypes.string,
  type: PropTypes.oneOf(["title", "body", "text"]),
};

export default Text;
