import PropTypes from "prop-types";
import React from "react";
import AccordionComponent from "./AccordionComponent";

const GuestListAccordian = ({ item, index }) => {
  return (
    <div className="p-2 bg-white md:p-4">
      <div className="flex p-4 border">
        <AccordionComponent
          Heading={() => <AccordianHeading data={item} />}
          Children={(open) => <AccordianChildren data={item} />}
          CloseButton={
            <button className="w-full px-6 pt-2 text-sm font-semibold text-center border-t text-theme-black-color">
              HIDE DETAILS
            </button>
          }
        />
      </div>
    </div>
  );
};

GuestListAccordian.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
};

const AccordianHeading = ({ data }) => {
  return (
    <div className="flex flex-col items-start text-base leading-none tracking-tight text-theme-black-color ">
      <div>
        {data.first_name} {data.last_name}
      </div>
      <div className="mt-1 text-sm text-theme-black-300">{data.email}</div>
    </div>
  );
};

AccordianHeading.propTypes = {
  data: PropTypes.shape({
    course_logo: PropTypes.any,
    email: PropTypes.any,
    first_name: PropTypes.any,
    last_name: PropTypes.any,
    program_name: PropTypes.any,
  }),
};
const AccordianChildren = ({ data, index }) => {
  return (
    <div className="py-4 md:pb-0">
      <div className="grid grid-cols-2 gap-4 pb-4 xl:grid-cols-3">
        {/* <DataComponent title="STATUS" data={data.subscription_status} /> */}
        {data.city && <DataComponent title="City" data={data.city} />}
        {data.mobile_number && (
          <DataComponent title="Mobile" data={data.mobile_number} />
        )}
        {data.age && <DataComponent title="Age" data={`${data.age} yrs`} />}
      </div>
    </div>
  );
};

AccordianChildren.propTypes = {
  data: PropTypes.shape({
    age: PropTypes.any,
    city: PropTypes.any,
    email: PropTypes.any,
    mobile_number: PropTypes.any,
  }),
  index: PropTypes.number,
};

const DataComponent = ({ title, data }) => {
  return (
    <div className="items-center">
      <div className="text-sm font-medium sm:text-xs text-neutral-600">
        {title}
      </div>
      <div className="text-base font-medium sm:text-sm text-theme-black-color">
        {data}
      </div>
    </div>
  );
};

DataComponent.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
};
export default GuestListAccordian;
