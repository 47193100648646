import React, { useState } from "react";

import QPFPHoveringMenuClosed from "../../assets/images/QPFPHoveringMenuClosed.png";
import CustomLink from "../Link";
import { qpfpSiderbarLinks } from "../../Constants";
import classNames from "classnames";

/**
 * Component for hovering menu in QPFP Dashboard
 * @return {React.ReactElement} Hovering menu in QPFP Dashboard
 */
function NavMenu() {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`hidden fixed ${
        open ? "top-24" : "top-44"
      } left-0 md:flex justify-center z-50`}
    >
      <div
        className={open ? "block rounded-r-3xl bg-transparent" : "hidden"}
        style={{
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="rounded-tr-3xl pl-5 pr-24 py-4 bg-theme-black-color">
          <h3 className="font-semibold text-xl text-white">
            My QPFP Certification
          </h3>
        </div>

        <div className="flex flex-col space-y-3 p-5 bg-white rounded-br-3xl">
          {qpfpSiderbarLinks.map((link, index) => {
            return (
              <CustomLink
                key={index}
                href={link.href}
                className={classNames(
                  "font-light text-md cursor-pointer hover:underline text-theme-black-300 hover:text-theme-black-color",
                  index === 0 && "font-bold text-base text-theme-black-color"
                )}
              >
                {index === 0 && <span className="text-xs mr-2">&#9654;</span>}
                {link.label}
              </CustomLink>
            );
          })}
        </div>
      </div>

      <div
        onClick={() => setOpen((prev) => !prev)}
        className="relative flex items-center cursor-pointer"
      >
        <img src={QPFPHoveringMenuClosed} alt="Hovering Menu Button" />
        <p className="absolute left-0 font-bold text-white text-lg transform -rotate-90">
          {open ? "Close" : "Menu"}
        </p>
      </div>
    </div>
  );
}

export default NavMenu;
