import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import classNames from "classnames";
import { WarningCircle } from "@phosphor-icons/react";
import React from "react";
import Card from "../../stories/Card";
import StepperProgressBar1 from "../../stories/StepperProgressBar1";
import UploadDocumentsBar from "../../stories/UploadDocumentsBar";
import _ from "lodash";

const CorporateSection = ({ status }) => {
  const navigate = useNavigate();

  if (
    _.isEmpty(status) ||
    !["initiated", "payment_done", "pending", "on_hold", "rejected"].includes(
      status
    )
  )
    return null;

  const getCurrentStep = () => {
    switch (status) {
      case "initiated":
      case "payment_done":
        return 2;
      case "pending":
        return 3;
      case "approved":
      case "on_hold":
      case "rejected":
        return 4;
      default:
        return 1;
    }
  };

  const SuggestionMessage = () => {
    return (
      <div className="flex items-start w-full mt-6">
        <WarningCircle
          size={16}
          className="w-4 mx-0 min-w-[16px] mt-1 text-theme-orange"
        />
        <div className="block">
          <span className="ml-1 text-sm font-semibold leading-5 tracking-tight text-theme-black-color">
            {status === "initiated"
              ? "Make a payment for corporate registration."
              : status === "payment_done"
              ? "Upload company incorporation document."
              : status === "on_hold"
              ? "Your firm should have atleast 2 Pro members."
              : "Add Practice Info, Services Offered, Complimentary Services."}
          </span>
          <span
            className="ml-3.5 text-sm leading-5 font-semibold tracking-tight text-theme-red cursor-pointer"
            onClick={() =>
              ["pending", "approved"].includes(status)
                ? navigate("/corporate/profile")
                : status === "on_hold"
                ? navigate("/corporate/users")
                : navigate("/corporate/register")
            }
          >
            {status === "initiated"
              ? "PAY NOW"
              : status === "payment_done"
              ? "UPLOAD"
              : status === "on_hold"
              ? "ADD MEMBERS "
              : "EDIT PROFILE"}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col">
      {(status === "pending" || status === "on_hold") && (
        <UploadDocumentsBar
          message={
            status === "on_hold"
              ? "Your firm doesn't have atleast 2 Pro members."
              : "Your corprate account application is under review meanwhile you can add other details."
          }
          onClick={() =>
            ["pending", "approved"].includes(status)
              ? navigate("/corporate/profile")
              : status === "on_hold"
              ? navigate("/corporate/users")
              : navigate("/corporate/register")
          }
          ctaLabel={
            status === "initiated"
              ? "PAY NOW"
              : status === "payment_done"
              ? "UPLOAD"
              : status === "on_hold"
              ? "ADD MEMBERS "
              : "EDIT PROFILE"
          }
        />
      )}
      <div
        className={classNames(
          "grid p-3 lg:p-6 bg-white lg:grid-cols-3",
          status !== "approved" && "mt-6"
        )}
      >
        <div className="lg:mr-6">
          <Card
            className="w-full min-w-full bg-white border lg:border-none lg:h-full lg:mb-0 lg:bg-theme-gray-300"
            cardType="filled"
            header={
              <div className="flex flex-row items-center text-2xl font-bold leading-none tracking-tighter text-black-color">
                NFP Corporate Membership
              </div>
            }
            cta={<div></div>}
            cardBody={
              <div className="flex flex-col mt-4 mb-5 text-sm tracking-tight text-black-color-300">
                NFP Corporate Membership allows organizations to stand out and
                create a defined identity for your company. Any organization
                having atleast two ProMembers can opt for Corporate Membership.
              </div>
            }
          />
        </div>
        <Card
          className="min-w-full col-span-2 mt-6 border-none lg:border lg:flex lg:mt-0 p-0 lg:!p-4"
          header={
            <div>
              <div className="flex items-center mb-1 text-lg font-bold leading-none tracking-tight lg:text-2xl text-theme-black-color">
                Your application status
              </div>
            </div>
          }
          cta={<></>}
          cardType="outline"
          cardBody={
            <div className="flex flex-col w-full mt-4">
              <StepperProgressBar1
                className="w-full"
                currentStep={getCurrentStep()}
                steps={[
                  "Application Submitted",
                  "Application Under Review",
                  status === "rejected"
                    ? "Application Rejected"
                    : status === "approved"
                    ? "Application Accepted"
                    : status === "on_hold"
                    ? "Application on hold"
                    : "Application Accepted/Rejected",
                ]}
              />
              {status !== "approved" && <SuggestionMessage />}
            </div>
          }
        />
      </div>
    </div>
  );
};

CorporateSection.propTypes = {
  status: PropTypes.any,
};

export default CorporateSection;
