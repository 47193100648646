import { UploadSimple } from "@phosphor-icons/react";
import React from "react";
import PropTypes from "prop-types";

const UploadDocumentsBar = ({
  onClick,
  message = "Your application is under review meanwhile you can submit your documents.",
  ctaLabel = "UPLOAD",
}) => {
  return (
    <div className="flex flex-col justify-between p-2 md:items-center bg-theme-orange md:flex-row">
      <div className="flex items-center text-base font-semibold leading-5 tracking-tight text-theme-gray-200">
        <div className="flex items-center justify-center mr-3 bg-white rounded-sm">
          <UploadSimple size={24} className="m-2 text-theme-black-color" />
        </div>
        {message}
      </div>
      <button
        onClick={onClick}
        className="h-8 mt-4 text-sm text-white bg-black rounded-sm md:mt-0 md:w-32"
      >
        {ctaLabel}
      </button>
    </div>
  );
};

UploadDocumentsBar.defaultProps = {
  onClick: () => {},
};

UploadDocumentsBar.propTypes = {
  ctaLabel: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
};

export default UploadDocumentsBar;
