import axios from "axios";
import { User } from "../store";
import { getAPIUrl } from "../util/getURLs";

axios.defaults.baseURL = getAPIUrl();
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    if (
      ["/login"].indexOf(config.url) === -1 &&
      !config.url.includes("amazonaws.com")
    ) {
      const token = User.getToken();
      if (token) config.headers["Authorization"] = token;
    } else {
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const makeReq = (method, url, data, config) => {
  const promiseResponse = { error: null, data: null };
  const onSuccess = (response) => {
    promiseResponse.data = response.data;
    return Promise.resolve(promiseResponse);
  };

  const onError = (error) => {
    const { response } = error;
    if (response) {
      const { data } = response;
      if (data.status === "500") {
        promiseResponse.error = {
          message: "Unable to connect with server. Try again after sometime",
        };
      } else if (data.error_code === "invalid_token") {
        User.clearToken();
        promiseResponse.error = {
          message: "Unauthorized user",
        };
        window.location.href = "/login";
      } else {
        promiseResponse.error = { message: data.message };
      }
      return Promise.resolve(promiseResponse);
    } else {
      const { message } = error;
      promiseResponse.error = { message };
      return Promise.resolve(promiseResponse);
    }
  };
  return axios({
    method,
    url,
    data,
  })
    .then(onSuccess)
    .catch(onError);
};

export const API = {
  post: (url, data) => makeReq("post", url, data),
  get: (url, data) => makeReq("get", url, data),
  put: (url, data) => makeReq("put", url, data),
  delete: (url, data) => makeReq("delete", url, data),
};

export const FETCHER = (url, token) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return axios({
    method: "get",
    url,
    headers: token ? { Authorization: token } : {},
  }).then(onSuccess);
};
