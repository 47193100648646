import React from "react";
import PropTypes from "prop-types";

import { useCourseCurriculum } from "../../../services/CourseDetails";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import { CircleNotch } from "@phosphor-icons/react";
import Button from "../../../stories/Button";

/**
 * Component for ACE Session
 * @return {React.ReactElement} ACE Session
 */
function ACESession({ title, subtitle, thumbnail, onClick }) {
  return (
    <div
      className="p-3 flex justify-between items-start space-x-4 border border-[#E5E7EB] rounded-lg"
      onClick={onClick}
    >
      <img
        className="w-32 h-20 rounded-lg md:w-48 md:h-28 object-cover"
        src={thumbnail}
        alt="Session thumbnail"
      />

      <div className="flex flex-col space-y-2 w-full">
        <p title={title} className="font-medium text-md line-clamp-2">
          {title}
        </p>
        <p title={subtitle} className="font-normal text-sm line-clamp-1">
          {subtitle}
        </p>
      </div>
    </div>
  );
}

ACESession.propTypes = {
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
};

/**
 * Component for ACE Sessions and Test Dashboard Element
 * @return {React.ReactElement} ACE Sessions and Test Dashboard Element
 */
function ACESessionsAndTest({ courseId }) {
  const { curriculum, loading: isLoading } = useCourseCurriculum(courseId, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <CircleNotch
        className="mx-auto text-theme-black-color animate-spin"
        size={56}
      />
    );
  }

  if (courseId === null || courseId === undefined) {
    return (
      <div className="flex flex-col gap-3">
        <p className="text-sm">No ACE Program Opted</p>
        <div>
          <Button
            className="!rounded-md"
            onClick={() => navigate("/qpfp-certificant/ace-program-and-tests")}
          >
            Select Program
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-3">
      <p className="text-sm mb-4">
        ACE Program Opted for: <b>{curriculum?.name ?? ""}</b>
      </p>

      {_.head(curriculum?.modules)?.sessions?.map((session, index) => (
        <ACESession
          key={`session-${index}`}
          title={session?.name}
          subtitle={session?.presenter}
          thumbnail={session?.session_thumbnail?.url}
          onClick={() => {
            navigate(
              `/programs/${curriculum.id}/learn/${
                _.head(session.session_content).id
              }`
            );
          }}
        />
      ))}
    </div>
  );
}

ACESessionsAndTest.propTypes = {
  courseId: PropTypes.number,
};

export default ACESessionsAndTest;
