import React from "react";
import { qpfpSiderbarLinks } from "../../Constants";
import CustomLink from "../Link";
import classNames from "classnames";
import { useLocation } from "@reach/router";

const QPFPSidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="bg-white w-full h-full flex flex-col z-20 drop-shadow-md">
      <div className="px-5 py-3.5 bg-theme-black-color">
        <h3 className="font-semibold text-lg text-white">
          My QPFP Certification
        </h3>
      </div>
      <div className="flex flex-col space-y-3 w-full p-5 items-start">
        {qpfpSiderbarLinks.map((link, index) => {
          const isActiveItem = currentPath === link.href;
          return (
            <CustomLink
              key={index}
              href={link.href ?? "#"}
              className={classNames(
                "font-light text-md cursor-pointer hover:underline text-theme-black-300 hover:text-theme-black-color",
                isActiveItem && "!font-bold !text-base !text-theme-black-color"
              )}
            >
              {isActiveItem && <span className="text-xs mr-2">&#9654;</span>}
              {link.label}
            </CustomLink>
          );
        })}
      </div>
    </div>
  );
};

export default QPFPSidebar;
