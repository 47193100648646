import PropTypes from "prop-types";
import classNames from "classnames";
import { Info } from "@phosphor-icons/react";
import React from "react";
import { createPopper } from "@popperjs/core";
import PromemberMessage from "../components/PublicProfile/PromemberMessage";

const Tooltip = ({
  infoContent,
  children,
  className = "",
  messageClassName = "",
}) => {
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      // placement: "top",
    });
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };
  return (
    <>
      <div className={classNames("flex flex-wrap", className)}>
        <div className="w-full text-center">
          <div
            ref={btnRef}
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            className="cursor-pointer"
          >
            {children ?? (
              <Info
                weight="fill"
                className={classNames(
                  "text-theme-black-color ml-1.5 w-3.5 h-3.5 min-w-[14px]"
                )}
              />
            )}
          </div>
          <div ref={tooltipRef} className="z-50">
            <PromemberMessage
              className={classNames(
                "min-w-328",
                messageClassName,
                tooltipShow ? "" : "hidden"
              )}
              message={infoContent}
              showIcon={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  infoContent: PropTypes.any,
  messageClassName: PropTypes.string,
};

export default Tooltip;
