import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import checkmark from "../assets/svgs/Check.svg";
import { useField } from "formik";

const CheckListItem = ({ name, label, onClick, ...props }) => {
  const [field] = useField({
    name,
    type: "checkbox",
    multiple: true,
    checked: true,
    ...props,
  });
  return (
    <div
      className="flex items-center justify-between w-full p-3 bg-white border rounded-sm shadow-sm cursor-pointer select-none border-theme-gray-300"
      onClick={onClick}
    >
      <div className="text-sm tracking-tight text-left text-theme-black-color">
        {label}
      </div>
      <div
        className={classNames(
          "w-4 h-4 min-w-[16px] rounded-full flex items-center justify-center",
          field.checked ? "bg-theme-orange" : "bg-white border border-gray-400"
        )}
      >
        <img
          src={checkmark}
          alt="checkmark"
          className={classNames(
            "h-3 w-3 min-w-[12px]",
            !field.checked && "hidden"
          )}
        />
      </div>
    </div>
  );
};

CheckListItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export default CheckListItem;
