/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../index.css";
import { Dialog, Transition } from "@headlessui/react";
import { Circle, X } from "@phosphor-icons/react";
import Button from "./Button";
import { CourseService } from "../services/CourseService";
import Loader from "../components/Shimmer/Loader";
import _ from "lodash";
import Text from "./Text";

const QuizIntroModal = ({
  primaryOnClick,
  secondaryOnClick,
  dismissButtonOnClick,
  open,
  primaryCtaLoading,
  quiz_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [instructionData, setInstructionData] = useState({});
  useEffect(() => {
    fetchInstruction();
  }, []);

  const fetchInstruction = async () => {
    setIsLoading(true);
    try {
      const response = await CourseService.fetchQuizInstruction({
        quiz_id: quiz_id,
      });
      setInstructionData(response.instructions);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-20 overflow-y-auto"
        open={open}
        onClose={dismissButtonOnClick}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              {isLoading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                      onClick={dismissButtonOnClick}
                    >
                      <span className="sr-only">Close</span>
                      <X className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="mb-3 text-base font-semibold leading-6 text-theme-black-color"
                  >
                    <div className="absolute top-0 right-0 block pt-4 pr-4">
                      <button
                        type="button"
                        className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                        onClick={dismissButtonOnClick}
                      >
                        <span className="sr-only">Close</span>
                        <X
                          className="w-6 h-6 min-w-[24px]"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    <div className="mr-6">{instructionData.heading}</div>
                  </Dialog.Title>
                  <div className="mt-2">
                    {_.isEmpty(instructionData.items) && (
                      <div className="">
                        Start the quiz by clicking the <b>START QUIZ</b> BUTTON
                      </div>
                    )}
                    {instructionData.items?.map((item, i) => (
                      <div key={i} className="flex mb-2 ">
                        {(instructionData.items ?? []).length > 1 ? (
                          <div>
                            <Circle
                              className="w-2 mr-2 mt-1.5"
                              weight="fill"
                              color="#f36c04"
                              size={8}
                            />
                          </div>
                        ) : null}
                        <Text
                          content={item}
                          type="text"
                          isMultiline={true}
                          size="text-sm"
                          className="text-sm font-normal tracking-tighter text-theme-black-300"
                        />
                      </div>
                    ))}
                    <div className="flex mt-6 mb-6 text-sm font-normal leading-5 tracking-tight text-theme-black-300">
                      {instructionData.footer}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    {/* <Button
                      className="w-full py-3 mr-2 rounded-sm "
                      buttonStyle="secondary"
                      onClick={secondaryOnClick}
                      height="40px"
                      width="100%"
                    >
                      <span>SKIP QUIZ</span>
                    </Button> */}
                    <div className="w-1/2">
                      <Button
                        className="w-1/2 py-3 rounded-sm md:mr-2 "
                        height="40px"
                        onClick={primaryOnClick}
                        loading={primaryCtaLoading}
                        width="100%"
                      >
                        <span>START QUIZ</span>
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

QuizIntroModal.defaultProps = {
  primaryCtaLoading: false,
};
QuizIntroModal.propTypes = {
  primaryOnClick: PropTypes.func,
  secondaryOnClick: PropTypes.func,
  dismissButtonOnClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  primaryCtaLoading: PropTypes.bool,
  quiz_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default QuizIntroModal;
