/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import Label from "./Label";
import { AsteriskSimple } from "@phosphor-icons/react";
import ShowFormError from "./ShowFormError";
import Tooltip from "./Tooltip";

const TextArea = ({
  showErrorIcon,
  infoContent,
  disable,
  maxLength,
  isRequired,
  rows,
  className,
  labelClassname,
  ...props
}) => {
  const [field, meta] = useField(props);

  const invalidChars = ["-", "+", "e", "E"];
  const checkChars = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={classNames("flex flex-col items-start w-full ", className)}>
      <div className="relative w-full mt-1">
        <Label
          labelFor={props.name}
          className={classNames(
            "flex items-center text-xs font-medium text-theme-black-300",
            labelClassname
          )}
        >
          {props.label ?? props.name}
          {isRequired && (
            <AsteriskSimple
              size={8}
              weight="bold"
              className="mb-2 min-w-[8px] text-theme-red"
            />
          )}
          {infoContent && <Tooltip infoContent={infoContent} />}
        </Label>
        <div className="flex flex-col">
          <textarea
            className={classNames(
              "flex items-center justify-between",
              "text-left text-sm text-theme-black-color font-medium tracking-tight",
              "p-2.5 pr-3 mt-1 w-full border focus:ring-0 focus:outline-none focus:border",
              meta.touched && meta.error
                ? "border-theme-red-300 focus:ring-theme-red-300 focus:border-theme-red-300"
                : "border-gray-200 focus:ring-gray-900 focus:border-theme-black-color",
              "rounded-lg lg:rounded-sm shadow-sm"
            )}
            style={{ height: `${(rows - 1) * 20 + 40}px` }}
            onKeyDown={(e) => (props.type === "number" ? checkChars(e) : "")}
            name={props.name}
            disabled={!!disable}
            maxLength={maxLength}
            {...field}
            {...props}
          />
          <div className="flex justify-between mt-1 text-xs leading-none tracking-tight text-theme-gray-600">
            <div className="">
              <ShowFormError name={props.name} className="mt-0 leading-none" />
            </div>
            <div className="flex min-w-fit">
              {maxLength - (field.value?.length ?? 0)} characters remaining
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TextArea.defaultProps = {
  showErrorIcon: false,
  infoContent: "",
  disable: false,
  maxLength: 255,
  rows: 2,
  type: "text",
};

TextArea.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  showErrorIcon: PropTypes.bool,
  infoContent: PropTypes.string,
  disable: PropTypes.bool,
  maxLength: PropTypes.number,
  isRequired: PropTypes.bool,
  rows: PropTypes.number,
  className: PropTypes.string,
  labelClassname: PropTypes.string,
};
export default TextArea;
