import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import { Form, Formik } from "formik";
import Dropdown from "../../stories/Dropdown";

const SelectQPFPModuleModal = ({
  open,
  onClose,
  options,
  onSelect,
  userName = "",
  preSelectedModule = {},
  className,
}) => {
  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full p-8 m-auto text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between text-lg font-medium tracking-tight text-theme-black-color"
                >
                  Select Module
                </Dialog.Title>
                <div className="flex flex-col w-full">
                  <div className="block mb-6 text-sm font-light text-theme-black-300">
                    Select QPFP Module for {userName}
                  </div>
                  <Formik
                    initialValues={{
                      module: preSelectedModule.part_payment_order,
                    }}
                    onSubmit={() => {}}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Dropdown
                          name="module"
                          id="module"
                          type="text"
                          label="Module"
                          placeholder="None"
                          height={"h-12"}
                          options={options}
                          keepShadow={false}
                          buttonBgColor="bg-white"
                          displayCurrentOption={true}
                          displayKey="label"
                          idKey="part_payment_order"
                        />
                        <div className="flex items-center w-full gap-2 mt-4">
                          <Button
                            buttonStyle="primary"
                            type="submit"
                            size="md"
                            height="36px"
                            width="50%"
                            onClick={() => {
                              if (onSelect)
                                onSelect(
                                  options.filter(
                                    (e) =>
                                      e.part_payment_order === values.module
                                  )[0]
                                );
                            }}
                          >
                            SAVE
                          </Button>
                          <Button
                            buttonStyle="transparent-textonly-secondary"
                            type="submit"
                            size="md"
                            height="36px"
                            width="50%"
                            onClick={() => {
                              onClose();
                            }}
                          >
                            CANCEL
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

SelectQPFPModuleModal.defaultProps = {
  open: false,
  className: "",
};

SelectQPFPModuleModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  options: PropTypes.shape({
    filter: PropTypes.func,
  }),
  preSelectedModule: PropTypes.object,
  userName: PropTypes.string,
};

export default SelectQPFPModuleModal;
