import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import Button from "../../../stories/Button";
import Loader from "../../Shimmer/Loader";
import { CourseService } from "../../../services/CourseService";
import { UserService } from "../../../services/UserService";
import _ from "lodash";
import { downloadFile } from "../../../util/downloadFile";

/**
 * Component for QPFP/ACE Badge
 * @return {React.ReactElement} QPFP/ACE Badge
 */
export function QPFPBadge({ title, description, imgSrc, certificantBadge }) {
  return (
    <div
      className={`p-4${
        certificantBadge ? "" : " w-2/3 flex-shrink-0 md:w-full md:flex-shrink"
      } flex flex-col justify-center space-y-2 bg-[#F5F7FB] border border-[#DEDEDE] rounded-md`}
    >
      <img src={imgSrc} alt="Your QPFP Badge" />
      <p className="font-semibold text-lg text-center">{title}</p>
      <p className="font-light text-sm text-center">{description}</p>
      <Button
        onClick={() => downloadFile(imgSrc)}
        className="self-center !mt-4 !rounded-md"
      >
        Download
      </Button>
    </div>
  );
}

QPFPBadge.defaultProps = {
  certificantBadge: false,
};

QPFPBadge.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
  certificantBadge: PropTypes.bool,
};

/**
 * Component for QPFP Certificate And Badges Page
 * @return {React.ReactElement} QPFP Certificate And Badges Page
 */
function CertificateAndBadges() {
  const [isLoading, setIsLoading] = useState(true);
  const error = false;
  const [certificates, setCertificates] = useState({});

  const fetchData = async () => {
    const response = await UserService.fetchQPFPCertificationStatus();
    const response1 = await CourseService.fetchQPFPCertificatesBadges({
      course_id: response?.qpfp_course_id,
    });
    setCertificates(response1);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <QPFPSkeleton showFooter={true} hideSidebar={true}>
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </QPFPSkeleton>
    );
  }
  if (error) {
    return (
      <QPFPSkeleton showFooter={true} hideSidebar={true}>
        <ErrorScreen />
      </QPFPSkeleton>
    );
  }
  return (
    <QPFPSkeleton showFooter={true} hideSidebar={true}>
      <div className="flex flex-col space-y-6 w-full py-10 relative z-0">
        <div className="flex flex-col space-y-4 px-6">
          <h1 className="font-bold text-2xl">QPFP Certificate and Badges</h1>
          <p className="text-sm text-theme-black-300 mt-3">
            You have worked hard to earn this prestigious certification. Share
            it with the world to establish your credentials.
          </p>
        </div>

        {!_.isEmpty(certificates?.qpfp_final_certificate) && (
          <div className="flex flex-col space-y-4 px-6">
            <h3 className="font-medium text-lg">Your QPFP Certificate</h3>
            <div className="flex flex-col items-center space-x-0 space-y-4 md:flex-row md:items-start md:space-x-8 md:space-y-0">
              <img
                src={certificates?.qpfp_final_certificate?.certificate_url}
                alt="Your QPFP Certificate"
                className="px-6 md:px-0 w-5/6 sm:w-2/3 md:w-1/4"
              />
              <div className="mt-4 flex flex-col items-start space-y-4">
                <p className="font-medium text-lg">QPFP Certification</p>
                <p className="font-light text-lg">
                  Download and share the digital certificate of your QPFP
                  Certificate with latest validity.
                </p>
                <p className="font-normal text-md">
                  Validity:{" "}
                  <span className="font-light text-[#606060]">
                    Till{" "}
                    {moment(
                      certificates?.qpfp_final_certificate?.valid_upto
                    ).format("DD MMM YY")}
                  </span>
                </p>

                <Button
                  onClick={() =>
                    downloadFile(
                      certificates?.qpfp_final_certificate?.certificate_url
                    )
                  }
                  className="!mt-6 !rounded-md"
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        )}

        {!_.isEmpty(certificates?.qpfp_badges) && (
          <div className="flex flex-col space-y-4 px-6">
            <h3 className="font-medium text-lg">QPFP Badges</h3>
            <div
              className={`${
                certificates?.qpfp_badges?.length < 2
                  ? "grid grid-cols-1 gap-4"
                  : "flex overflow-x-scroll space-x-4 md:overflow-x-hidden md:space-x-0 md:grid md:gap-4"
              } md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5`}
            >
              {certificates?.qpfp_badges?.map(
                (badge, index) =>
                  !_.isEmpty(badge.certificate_url) && (
                    <QPFPBadge
                      key={`qpfp-badge-${index}`}
                      title={badge?.title}
                      description={badge?.description}
                      imgSrc={badge?.certificate_url}
                      certificantBadge={certificates?.qpfp_badges?.length < 2}
                    />
                  )
              )}
            </div>
          </div>
        )}

        {/* <div className="flex flex-col space-y-4 px-6">
          <h3 className="font-medium text-lg">QPFP Certificant Badge</h3>
          <div
            className={`${
              certificantBadges?.length < 2
                ? "grid grid-cols-1 gap-4"
                : "flex overflow-x-scroll space-x-4 md:overflow-x-hidden md:space-x-0 md:grid md:gap-4"
            } md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5`}
          >
            {certificantBadges?.map((badge, index) => (
              <QPFPBadge
                key={`qpfp-certificant-badge-${index}`}
                title={badge?.title}
                description={badge?.description}
                imgSrc={TempQPFPBadge}
                certificantBadge={certificantBadges?.length < 2}
              />
            ))}
          </div>
        </div> */}

        {!_.isEmpty(certificates?.ace_program_badges) && (
          <div className="flex flex-col space-y-4 px-6">
            <h3 className="font-medium text-lg">ACE Program Badges</h3>
            <div
              className={`${
                certificates?.ace_program_badges?.length < 2
                  ? "grid grid-cols-1 gap-4"
                  : "flex overflow-x-scroll space-x-4 md:overflow-x-hidden md:space-x-0 md:grid md:gap-4"
              } md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5`}
            >
              {certificates?.ace_program_badges?.map(
                (badge, index) =>
                  !_.isEmpty(badge.certificate_url) && (
                    <QPFPBadge
                      key={`qpfp-certificant-badge-${index}`}
                      title={badge?.title}
                      description={badge?.description}
                      imgSrc={badge?.certificate_url}
                      certificantBadge={
                        certificates?.ace_program_badges?.length < 2
                      }
                    />
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </QPFPSkeleton>
  );
}

export default CertificateAndBadges;
