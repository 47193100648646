/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { UserService } from "../../services/UserService";
import AppSkeleton from "../AppSkeleton";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { Circle } from "@phosphor-icons/react";
import useNotifications from "../../hooks/useNotification";
import moment from "moment";
import Loader from "../Shimmer/Loader";
import { ErrorScreen } from "../../stories/ErrorScreen";
import { notificationRedirect } from "../../constants/notificationRedirect";
import { useNavigate } from "@reach/router";
import { useProfile } from "../../context/userProvider";

const Notifications = () => {
  const statusList = ["all", "unread", "read"];
  const typeList = [
    { label: "Notifications", id: "notification" },
    { label: "Alerts", id: "alert" },
  ];
  const [type, setType] = useState("notification");
  const [status, setStatus] = useState("all");
  const [page, setPageNumber] = React.useState({
    notification: {
      all: 1,
      unread: 1,
      read: 1,
    },
    alert: {
      all: 1,
      unread: 1,
      read: 1,
    },
  });
  const { setUnreadNotificationCount } = useProfile();
  const { notifications, loading, error, hasMore } = useNotifications(
    type,
    status,
    page[type][status]
  );
  const navigate = useNavigate();

  const observer = React.useRef();
  const lastPostElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => ({
            ...prevPageNumber,
            [type]: {
              ...prevPageNumber[type],
              [status]: prevPageNumber[type][status] + 1,
            },
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const onNotificationClick = async (notification, status) => {
    try {
      await UserService.readNotification(notification.id);
      if (status === "unread") {
        setUnreadNotificationCount((prev) => prev - 1);
      }
      notificationRedirect({
        entity_id: notification.entity_id,
        entity_type: notification.entity_type,
        additional_data: notification.additional_data,
        notification_key: notification.notification_key,
        navigate,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AppSkeleton
      pageLabel="Notifications"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "Notifications", to: "/notifications", current: true },
      ]}
    >
      {/* <button
        onClick={async () => {
          const response = await UserService.sendDummyNotifications();
        }}
      >
        Send Dummy Notification
      </button> */}
      <div className="flex m-6">
        <div className="flex flex-col w-full bg-white">
          <Tab.Group>
            <div className="flex flex-col p-6 pb-0 gap-y-4 lg:flex-row lg:justify-between">
              <div className="text-xl font-bold leading-none capitalize">
                {type}
              </div>
              <Tab.List className="flex p-0.5 space-x-1 w-full max-w-xs rounded bg-neutral-100">
                {typeList.map((category) => (
                  <Tab
                    key={category.id}
                    onClick={() => {
                      setType(category.id);
                      setStatus(statusList[0]);
                    }}
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded py-1.5 leading-none text-sm text-theme-black-color capitalize ring-offset-0 focus:outline-none focus:ring-0",
                        selected ? "bg-white shadow" : "hover:bg-white/[0.12]"
                      )
                    }
                  >
                    {category.label}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels>
              {typeList.map((kind, k_i) => (
                <Tab.Panel key={k_i}>
                  <Tab.Group selectedIndex={statusList.indexOf(status)}>
                    <Tab.List
                      id="tab-list"
                      className="flex items-center px-6 pt-4 pb-2 overflow-x-scroll overflow-y-hidden transition-all duration-1000 no-scrollbar"
                    >
                      {statusList.map((item, h_i) => {
                        return (
                          <Tab
                            key={h_i}
                            className={({ selected }) =>
                              classNames(
                                selected
                                  ? "font-semibold text-theme-black-color pb-1.5 border-b-2 border-theme-orange mr-5 sm:mr-10 last:mr-0 focus:outline-none focus:ring-0 shrink-0 capitalize"
                                  : "font-normal text-[#A3A3A3] pb-1.5 mr-5 sm:mr-10 last:mr-0 focus:outline-none focus:ring-0 shrink-0 capitalize"
                              )
                            }
                            onClick={(e) => {
                              setStatus(item);
                              const offsets = e.target.getBoundingClientRect();
                              if (
                                offsets.left >= 0 &&
                                offsets.right <=
                                  (window.innerWidth ||
                                    document.documentElement.clientWidth)
                              ) {
                                return;
                              }
                              document
                                .querySelector("#tab-list")
                                ?.scrollTo(offsets.left / 2, 0);
                            }}
                          >
                            {item}
                          </Tab>
                        );
                      })}
                    </Tab.List>
                    <div className="mt-5 mb-5 outline-none">
                      <div className="flex flex-col w-full">
                        {notifications.length === 0 && !loading && (
                          <div className="flex items-center justify-center gap-4 p-4 pb-0 text-base italic font-medium leading-4 text-theme-black-color">
                            No {status !== "all" && status} {type}
                          </div>
                        )}
                        {notifications.map((notification, index) => {
                          let date = undefined;
                          const prev = moment(
                            notifications[index - 1]?.created_at
                          );
                          const current = moment(
                            notifications[index]?.created_at
                          );
                          const today = moment();
                          if (
                            prev.dayOfYear() !== current.dayOfYear() ||
                            index === 0
                          ) {
                            if (current.dayOfYear() === today.dayOfYear()) {
                              date = "Today";
                            } else if (
                              current.dayOfYear() ===
                              today.dayOfYear() - 1
                            ) {
                              date = "Yesterday";
                            } else {
                              date = current.format("DD MMM, YYYY");
                            }
                          }
                          return (
                            <React.Fragment key={notification.id}>
                              {date && (
                                <div className="p-4 pb-2 text-sm lg:p-6 text-theme-black-300">
                                  {date}
                                </div>
                              )}
                              {index !== notifications.length - 1 && (
                                <div
                                  className={classNames(
                                    "flex gap-1 justify-between hover:bg-neutral-100/40 w-full cursor-pointer p-4 lg:p-6 border-b border-neutral-100",
                                    notification.status === "read" &&
                                      "bg-neutral-100/40"
                                  )}
                                  onClick={() =>
                                    onNotificationClick(
                                      notification,
                                      notification.status
                                    )
                                  }
                                >
                                  <div className="block text-theme-black-color">
                                    {notification?.title || ""}
                                    {notification.status === "unread" && (
                                      <Circle
                                        className="inline-block w-2 h-2 ml-4"
                                        weight="fill"
                                        color="#f36c04"
                                      />
                                    )}
                                  </div>
                                  <div className="text-xs text-neutral-400">
                                    {moment(notification?.created_at).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </div>
                              )}
                              {index === notifications.length - 1 && (
                                <div
                                  ref={lastPostElementRef}
                                  className={classNames(
                                    "flex gap-1 justify-between cursor-pointer w-full p-4 lg:p-6 border-b border-neutral-100 hover:bg-neutral-100/20",
                                    notification.status === "read" &&
                                      "bg-neutral-100/50"
                                  )}
                                  onClick={() =>
                                    onNotificationClick(
                                      notification,
                                      notification.status
                                    )
                                  }
                                >
                                  <div className="block text-theme-black-color">
                                    {notification?.title || ""}
                                    {notification.status === "unread" && (
                                      <Circle
                                        className="inline-block min-w-[8px] w-2 h-2 ml-4"
                                        weight="fill"
                                        color="#f36c04"
                                      />
                                    )}
                                  </div>
                                  <div className="text-xs text-neutral-400">
                                    {moment(notification?.created_at).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                        {loading && (
                          <div className="mt-5">
                            <Loader />
                          </div>
                        )}
                        <div>{!loading && error && <ErrorScreen />}</div>
                      </div>
                    </div>
                  </Tab.Group>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default Notifications;
