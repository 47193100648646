import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import clock_img from "../../assets/svgs/Clock.svg";
import { X } from "@phosphor-icons/react";
import { Button } from "@storybook/components";
import { useNavigate } from "@reach/router";
import Loader from "../Shimmer/Loader";

const LockSessionModal = ({
  event_type,
  open,
  courseId,
  dateTime,
  onClose,
  className,
}) => {
  const navigate = useNavigate();
  const [leftTime, setLeftTime] = useState({ loading: true });
  const [showNavigateButton, setShowNavigateButton] = useState(false);

  setInterval(() => {
    let delta = Math.abs(new Date() - new Date(dateTime)) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 60 / 60 / 24);
    delta -= days * 3600 * 24;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 60 / 60);
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = Math.floor(delta % 60);
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      setLeftTime({ days, hours, minutes, seconds });
      setShowNavigateButton(true);
    } else {
      setLeftTime({ days, hours, minutes, seconds });
    }
  }, 1000);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="flex items-center mb-3 text-2xl font-medium leading-6 tracking-tight text-theme-black-color"
              >
                <div className="flex items-center justify-center mr-4 rounded-full min-w-10 bg-theme-orange bg-opacity-30">
                  <img
                    src={clock_img}
                    className="w-6 h-6 m-2 min-w-[24px]"
                    alt="reload_icon"
                  />
                </div>
                Coming Soon
              </Dialog.Title>
              {leftTime.loading && <Loader />}
              {!leftTime.loading && (
                <>
                  <div className="flex pl-2 ml-0 space-x-6 text-base md:ml-12">
                    {leftTime.days !== 0 && (
                      <div className="flex flex-col items-center">
                        <div className="text-2xl font-semibold leading-7">
                          {leftTime.days?.toLocaleString("en-IN", {
                            minimumFractionDigits: 0,
                            minimumIntegerDigits: 2,
                          }) ?? "00"}
                        </div>
                        <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                          DAYS
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col items-center">
                      <div className="text-2xl font-semibold leading-7">
                        {leftTime.hours?.toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          minimumIntegerDigits: 2,
                        }) ?? "00"}
                      </div>
                      <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                        HOURS
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="text-2xl font-semibold leading-7">
                        {leftTime.minutes?.toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          minimumIntegerDigits: 2,
                        }) ?? "00"}
                      </div>
                      <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                        MINUTES
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="text-2xl font-semibold leading-7">
                        {leftTime.seconds?.toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          minimumIntegerDigits: 2,
                        }) ?? "00"}
                      </div>
                      <div className="font-medium tracking-tight text-xxs text-theme-black-300">
                        SECONDS
                      </div>
                    </div>
                  </div>
                  <div className="block pl-2 mt-4 ml-0 text-base text-left md:ml-12">
                    <span className="capitalize whitespace-pre">
                      {event_type}{" "}
                    </span>
                    has not yet started. It will be available in sometime.
                  </div>
                  {showNavigateButton && (
                    <div className="flex justify-end w-full mt-10">
                      <Button
                        buttonStyle="primary"
                        type="submit"
                        size="md"
                        height="40px"
                        width="204px"
                        onClick={() => navigate(`/programs/${courseId}/learn`)}
                      >
                        GO TO COURSE
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LockSessionModal.defaultProps = {
  open: false,
  className: "",
  event_type: "session",
};

LockSessionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  event_type: PropTypes.string,
  courseId: PropTypes.string,
  dateTime: PropTypes.string,
};

export default LockSessionModal;
