import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import empty_data from "../../assets/svgs/empty_data.svg";

const EmptyData = ({ heading, message, className, cta }) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center w-full mt-6",
        className
      )}
    >
      <div className="flex flex-col items-center mt-6">
        <img
          alt="No Data"
          src={empty_data}
          className="w-24 h-24 rounded-full "
        />
        {heading && (
          <div className="flex mt-3 text-base font-bold leading-5 tracking-tight text-center text-theme-black-color">
            {heading}
          </div>
        )}
        {message && (
          <div className="flex mt-3 text-sm tracking-tight text-center text-theme-black-300 ">
            {message}
          </div>
        )}
      </div>
      {cta && cta}
    </div>
  );
};

EmptyData.propTypes = {
  cta: PropTypes.element,
  heading: PropTypes.any,
  className: PropTypes.string,
  message: PropTypes.any,
};

export default EmptyData;
