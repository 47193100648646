/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import application_submitted from "../../assets/svgs/application_submitted.svg";
import Button from "../../stories/Button";
const SubmittedScreen = ({ stepProps, className, ...props }) => {
  return (
    <div className={classNames("flex m-3", className)}>
      <div
        className={classNames(
          "max-w-[764px] lg:min-w-3xl w-full flex flex-col items-center pt-4 pb-3 px-4 lg:p-4 bg-white rounded-sm shadow-sm "
        )}
      >
        <div className="max-w-xl text-2xl font-bold tracking-tight text-center lg:font-semibold lg:tracking-normal text-theme-black-color">
          Your application has been submitted successfully.
        </div>
        <div className="max-w-lg px-1 mt-3 text-base leading-5 text-center text-theme-black-300">
          {["provisional_member", "community_member"].includes(
            props.entity_type
          )
            ? "NFP admin shall review your application for documents. If approved, your membership will be activated within 2-3 working days along with confirmation. If not approved, we will get in touch with you for further process."
            : props.hasRSVP
            ? "NFP admin shall review your application for eligibility and documents. If approved, your membership will be activated within 3-7 working days along with confirmation for the event. If not approved, we will get in touch with you for further process."
            : "NFP admin shall review your application for eligibility and documents. If approved, your membership will be activated within 3 working days.If not, Network FP team will get in touch with you for further process."}
        </div>
        <img
          src={application_submitted}
          alt="thank_you_img"
          className="my-6 lg:mt-10 lg:mb-16"
          width={251}
          height={214}
        />

        <Button
          buttonStyle="primary"
          height="40px"
          width="354px"
          onClick={async () => {
            stepProps.handleNext();
          }}
        >
          <span className="text-sm font-semibold leading-4 -tracking-tight">
            {props.isNewUser ? "CONTINUE TO LOGIN" : "CONTINUE TO DASHBOARD"}
          </span>
        </Button>
        {props.isNewUser && (
          <div className="mt-6 text-sm font-medium leading-6 text-center -tracking-tight">
            Please check your email id{" "}
            <span className="font-bold underline underline-offset-4 text-theme-red">
              {props.email}
            </span>
            . We have sent an email to reset your password. Complete your
            sign-up process to access your account.
          </div>
        )}
      </div>
    </div>
  );
};

SubmittedScreen.propTypes = {
  className: PropTypes.string,
  stepProps: PropTypes.shape({
    handleNext: PropTypes.func,
  }),
};

export default SubmittedScreen;
