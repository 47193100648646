import PropTypes from "prop-types";
import React from "react";
import QPFPHeader from "./Header";
import networkfp_logo from "../../assets/svgs/NFP_logo.svg";
import BGImage from "../../assets/images/QPFP-Dash-BG.png";
import QPFPSidebar from "./QPFPSidebar";

const NetworkFPLogo = () => {
  return (
    <div className="flex items-center justify-start h-12">
      <img alt="network fp logo" src={networkfp_logo} className="h-9 lg:h-12" />
    </div>
  );
};

const QPFPSkeleton = ({
  headerClassNames,
  showSidebar = true,
  showFooter = false,
  children,
}) => {
  return (
    <div className="flex flex-col w-full bg-neutral-50 min-h-screen">
      <QPFPHeader className={headerClassNames} />
      <img
        src={BGImage}
        className="w-full object-contain h-auto absolute top-20 left-0 right-0 z-0"
        alt="for stylistic purposes"
      />
      <div className="flex w-full flex-grow z-10">
        {showSidebar && (
          <div className="hidden w-72 h-max lg:flex flex-col">
            <QPFPSidebar />
          </div>
        )}
        <div className="flex flex-col w-full relative">{children}</div>
      </div>
      {!showSidebar && <div className="h-18" />}
      {/* QPFP Footer */}
      {showFooter && (
        <div className="flex w-full bg-white">
          <div className="flex flex-col items-start mx-auto max-w-7xl py-8 px-6 lg:items-center md:flex-row gap-5 lg:gap-10">
            <NetworkFPLogo />
            <div className="flex flex-col w-full gap-3 lg:gap-5">
              <div className="text-base font-medium leading-5">
                About Network FP
              </div>
              <div className="text-xs font-normal text-theme-black-300">
                Network FP is India&apos;s leading knowledge platform offering
                continuous education, certifications & trainings for personal
                finance professionals. Network FP advocates client-first
                approach and knowledge-driven solutions.{" "}
                <a
                  href="https://networkfp.com"
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium cursor-pointer hover:underline text-theme-orange"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

QPFPSkeleton.propTypes = {
  children: PropTypes.any,
  showSidebar: PropTypes.bool,
  showFooter: PropTypes.bool,
  headerClassNames: PropTypes.string,
};

export default QPFPSkeleton;
