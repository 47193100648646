import PropTypes from "prop-types";
import React, { useState } from "react";
import SearchBar from "../../stories/SearchBar";
import { Form, Formik } from "formik";
import _ from "lodash";
import CheckListItem from "../../stories/CheckListItem";
import Tooltip from "../../stories/Tooltip";

const ExchangeForumFilter = ({
  preSelectedCategories,
  categories,
  submitFilters,
}) => {
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const selectedCategoriesList = [...categories].filter((category) =>
    selectedCategories.includes(category.id)
  );
  return (
    <div className="lg:sticky w-full  flex lg:top-24 h-full max-h-[calc(100vh-150px)] flex-col p-4 bg-white border border-gray-200">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold leading-none lg:font-bold lg:text-2xl text-theme-black-color">
          Categories
        </div>
        <div
          className="text-sm cursor-pointer text-theme-orange first-letter"
          onClick={() => {
            setSelectedCategories([]);
            submitFilters([]);
          }}
        >
          Clear all
        </div>
      </div>
      <div className="flex w-full my-4 md:max-w-lg">
        <SearchBar
          className="w-full !h-9 !border rounded-lg overflow-clip !border-gray-200"
          backgroundColor="bg-white !h-8"
          placeholder={"Search categories"}
          searchTerm={categorySearch}
          onSearch={_.debounce((val) => setCategorySearch(val), 700)}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={{ exchange_forum_category: selectedCategories }}
      >
        {(formProps) => (
          <Form className="flex flex-col gap-4 overflow-auto">
            {[...categories]
              .filter((category) =>
                (category.label ?? "")
                  .toLowerCase()
                  .includes((categorySearch ?? "").toLowerCase())
              )
              .map((category, index) => {
                return (
                  <CheckListItem
                    key={category.id}
                    label={category.label}
                    name={"exchange_forum_category"}
                    value={category.id}
                    checked={selectedCategories.includes(category.id)}
                    fo
                    onClick={() => {
                      const temp = [...selectedCategories];
                      if (selectedCategories.includes(category.id)) {
                        temp.splice(temp.indexOf(category.id), 1);
                      } else {
                        temp.push(category.id);
                      }
                      formProps.setFieldValue(
                        "exchange_forum_category",
                        [...temp],
                        true
                      );
                      setSelectedCategories([...temp]);
                    }}
                  />
                );
              })}
          </Form>
        )}
      </Formik>
      <div className="w-full h-[1px] bg-gray-200 my-4" />
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex items-center gap-1">
          <div className="">{selectedCategories.length} filters selected</div>
          <Tooltip
            infoContent={
              _.isEmpty(selectedCategoriesList)
                ? "No Categories Selected"
                : selectedCategoriesList.map((c) => c.label).join(" | ")
            }
          />
        </div>
        <button
          className="w-16 text-white uppercase border rounded h-9 bg-theme-red"
          onClick={() => {
            submitFilters(selectedCategories);
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

ExchangeForumFilter.propTypes = {
  categories: PropTypes.array,
  preSelectedCategories: PropTypes.array,
  submitFilters: PropTypes.func,
};

export default ExchangeForumFilter;
