import PropTypes from "prop-types";
import React from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import EmptyState from "./EmptyState";
import EmptyStatePlaceHolder from "../assets/svgs/empty_state_bg.svg";
import Button from "./Button";
import Table from "./Table";
import InvoiceAccordian from "./InvoiceAccordian";
import { useNavigate } from "@reach/router";
import { ErrorScreen } from "./ErrorScreen";

const InvoiceTable = ({
  columns,
  isLoading,
  error,
  data,
  showEmptyState = false,
}) => {
  const { width } = useWindowSize();
  const isDesktop = width >= 728;
  const navigate = useNavigate();

  if (error) return <ErrorScreen />;

  if (showEmptyState) {
    return (
      <EmptyState
        heading="No Invoices Yet!"
        subHeading={
          "When you will subscribe to any program, you will see relevant invoices here. To subscribe a program click on the below button."
        }
        cta={
          <Button className="!mt-4" onClick={() => navigate("/register")}>
            <div className="flex items-center px-3 py-1">
              <span>SUBSCRIBE PROGRAMS</span>
            </div>
          </Button>
        }
        image={
          <img
            alt="emptyStatePlaceHolder"
            className="py-9 max-h-80"
            src={EmptyStatePlaceHolder}
          />
        }
      />
    );
  }

  return (
    <Table
      isLoading={isLoading}
      data={data}
      columns={columns}
      showPagination={true}
      paginationProps={{
        perPage: 10,
      }}
      MobileViewCard={({ data, col, i }) => (
        <InvoiceAccordian item={data} key={i} index={i} isDesktop={isDesktop} />
      )}
    />
  );
};

InvoiceTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.any,
  headerBgColor: PropTypes.string,
  headerClass: PropTypes.string,
  isLoading: PropTypes.bool,
  showEmptyState: PropTypes.bool,
  showTable: PropTypes.bool,
};
export default InvoiceTable;
