/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CustomToast } from "../../stories/Toast";
import { AuthService } from "../../services/AuthService";
import { Validations } from "../../constants/validations";
import TextInput from "../../stories/TextInput";
import Dropdown from "../../stories/Dropdown";
import PaymentForm from "../Payments/PaymentForm";
import { PaymentServices } from "../../services/PaymentServices";
import Button from "../../stories/Button";
import { useNavigate } from "@reach/router";
import LoadingModal from "../Modals/LoadingModal";
import AppSkeleton from "../AppSkeleton";
import { useWindowSize } from "../../hooks/useWindowSize";
import onboarding_bg from "../../assets/images/onboarding/onboarding_bg.jpg";
import LoginUserModal from "../Modals/LoginUserModal";

const UnbounceOnboarding = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    city: "",
    wantGST: true,
    gstin: "",
    gstFirmName: "",
    gstCommunicationAddress: "",
    gstPincode: "",
    gstCity_id: "",
    gstState_id: "",
  };
  const [loading, setLoading] = useState(false);
  // const [forceLoginUser, setForceLoginUser] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const userTempToken = React.useRef(null);
  const submitRef = React.useRef();
  const navigate = useNavigate();
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  const onSubmitSuccess = () => {
    CustomToast.success(
      "Registration successfull. Please check you mail for further instructions."
    );
    navigate("/onboarding/thank_you", { state: { form_submit_status: true } });
    window.scrollTo(0, 0);
    return;
  };

  const fetchCities = async () => {
    try {
      const result = await AuthService.getCities("cities");
      if (result.locations) {
        setCityOptions(result.locations);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchPricingDetails = async () => {
    try {
      const result = await PaymentServices.fetchPricingDetails({
        entity_type: "user",
      });
      setPriceDetails(result);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchData = async () => {
    try {
      await Promise.all([fetchCities(), fetchPricingDetails()]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const registerAndPay = async (values, resetFormCallback) => {
    const postBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.mobileNumber,
      city_id: values.city,
    };

    let isRegistered = false;
    let isPaymentCompleted = false;
    let userId;
    try {
      const result = await AuthService.unbounceOnboarding(postBody);
      userTempToken.current = result.token;
      isRegistered = true;
    } catch (e) {
      if (e.error_code === "user_already_exists") {
        CustomToast.error(
          "You already have an account. Please login and check"
        );
        setOpenLoginPopup(true);
        return;
      }
      if (
        e.error_code === "free_user" ||
        e.error_code === "user_onboarding_already_done"
      ) {
        CustomToast.error("You have already onboarded on the platform.");
        setOpenLoginPopup(true);
        return;
      }
      if (e.error_code === "user_onboarding_payment_not_done") {
        userId = e.payload.entity_id;
        userTempToken.current = e.payload.token;
        isRegistered = true;
      }
      if (e.payload?.payment_status === "paid") {
        isRegistered = true;
        isPaymentCompleted = true;
        CustomToast.error("You have already onboarded on the platform.");
        setOpenLoginPopup(true);
        return;
      }
    }
    if (!isRegistered) {
      CustomToast.error("Something went wrong. Please try again.");
      return;
    }
    // register rsvp if needed.
    if (!isPaymentCompleted) {
      try {
        await makePayment(
          { entity_id: userId, ...values },
          userTempToken.current,
          resetFormCallback
        );
      } catch (e) {
        console.log(e);
      } finally {
        isPaymentCompleted = true;
      }
    }

    if (isRegistered && isPaymentCompleted) {
      console.log("registered for event and paid");
    }
  };

  const makePayment = async (values, userTempToken, resetFormCallback) => {
    let postBody = {
      pricing_id: priceDetails.pricing_id,
      coupon_ids: priceDetails.coupons?.map((e) => e.code),
      payment_gateway: "razorpay",
      course_fees: priceDetails.course_fees,
      gst: priceDetails.gst,
      to_be_paid: priceDetails.to_be_paid,
    };
    if (values.wantGST) {
      postBody = {
        ...postBody,
        gst_invoice_check: values.wantGST,
        gst_number: values.gstin,
        firm_name: values.gstFirmName,
        address_attributes: {
          line_1: values.gstCommunicationAddress,
          city_id: values.gstCity_id,
          pin: values.gstPincode,
        },
      };
    }

    const orderDetails = await PaymentServices.createOrder(
      {
        purchase: postBody,
        entity_type: "user",
        entity_id: values.entity_id,
      },
      userTempToken
    );

    if (orderDetails && orderDetails.payment_status === "initiated") {
      const response = await PaymentServices.makeRazorpayPayment(
        {
          name: values.firstName + " " + values.lastName,
          email: values.email,
          contact: values.mobileNumber,
        },
        orderDetails,
        () => {
          CustomToast.success("Payment is successful" ?? "");
          resetFormCallback();
          onSubmitSuccess();
        },
        userTempToken
      );
      console.log(response);
    } else if (orderDetails && orderDetails.payment_status === "success") {
      if (orderDetails.amount !== 0) {
        CustomToast.info(orderDetails.message);
      }
      resetFormCallback();
      onSubmitSuccess();
    } else {
      CustomToast.error(orderDetails.message);
    }
  };

  return (
    <AppSkeleton
      hideSidebar={true}
      hideProfileDetails={isDesktop}
      hideHeaderLabel={true}
    >
      <div
        className={classNames("flex relative flex-col w-full items-center ")}
      >
        <div className="relative z-0 flex flex-col items-center w-full gap-4 px-4 pt-10 text-white sm:pt-12 lg:pt-16 h-80">
          <img
            src={onboarding_bg}
            alt=""
            className="absolute top-0 z-0 object-cover object-top w-full h-full"
          />
          <div className="z-10 text-3xl font-bold text-center lg:text-4xl">
            Register for Client Acquisition Blueprint Workshop
          </div>
          <div className="z-10 max-w-3xl text-base text-center lg:text-lg">
            Get exact strategy, tools & clarity to generate 1,000+ leads and
            <br />
            convert 100+ clients for personal finance products & solutions
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validations.unbounceOnboardingFormvalidationSchema}
          onSubmit={async (values, { resetForm }) => {
            await registerAndPay(values, resetForm);
          }}
        >
          {({ values, ...formProps }) => {
            if (
              !formProps.isSubmitting &&
              !formProps.isValidating &&
              !formProps.isValid &&
              isSubmitClick
            ) {
              setIsSubmitClick(false);

              document
                .getElementsByClassName(
                  Object.keys(formProps.errors)[0] + "ErrorMessage"
                )[0]
                ?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
            }
            return (
              <Form className="z-10 w-full max-w-3xl px-4 mb-8 -mt-20">
                <div className="p-4 bg-white rounded shadow-sm lg:shadow-none">
                  <div className="flex text-lg font-semibold tracking-tight text-theme-black-color">
                    Registration Form
                  </div>
                  <div className="flex text-sm tracking-tight text-theme-black-300">
                    Fill your personal details here.
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-6">
                    <div className="">
                      <TextInput
                        name="firstName"
                        label="First Name"
                        placeholder="Rahul"
                        id="firstName"
                        type="text"
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="lastName"
                        label="Last Name"
                        placeholder="Subramanian"
                        id="experience"
                        type="text"
                        isRequired
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="email"
                        label="Email Address"
                        placeholder="rahul@xyz.com"
                        id="email"
                        type="text"
                        isRequired
                        onBlur={async (event) => {
                          setUpdatedEmail(event.target.value ?? "");
                          // const currentEmail = event.target.value;
                          // setForceLoginUser(false);
                          // try {
                          //   await AuthService.validateEmail(currentEmail);
                          //   setForceLoginUser(false);
                          // } catch (e) {
                          //   // Email already present
                          //   // ask user to login
                          //   setForceLoginUser(true);
                          // }
                        }}
                      />
                    </div>
                    <div className="">
                      <TextInput
                        name="mobileNumber"
                        label="Mobile No. (10 digits)"
                        placeholder="9876543210"
                        id="mobileNumber"
                        type="string"
                        leadingComponent={
                          <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                            +91
                          </span>
                        }
                        isRequired
                      />
                    </div>
                    {/* {forceLoginUser && (
                      <div className="flex items-start text-sm text-gray-900 col-span-full">
                        <LockKey
                          size={16}
                          className="my-1 mr-2 text-theme-red-300"
                        />
                        <div className="block">
                          Your Network FP account already exists.&nbsp;
                          <span
                            onClick={() => navigate("/login")}
                            className="underline cursor-pointer text-theme-red-300"
                          >
                            Please login
                          </span>
                        </div>
                      </div>
                    )} */}
                    <div className="w-full ">
                      <Dropdown
                        name="city"
                        id="city"
                        type="text"
                        label="City"
                        placeholder="Select City"
                        options={cityOptions}
                        displayCurrentOption={true}
                        displayKey="name"
                        idKey="id"
                        isRequired
                      />
                    </div>
                  </div>
                </div>
                <PaymentForm
                  entityProps={{ entity_type: "OnboardingForm" }}
                  setPriceDetails={setPriceDetails}
                  fetchPricingDetails={async (data) => {
                    const response = await PaymentServices.fetchDefaultPricing(
                      "props.entity_id",
                      data
                    );
                    return response;
                  }}
                  hideCouponField
                  priceDetails={priceDetails}
                  formikProps={{ values, ...formProps }}
                  submitRef={submitRef}
                  className="p-4 bg-white rounded shadow-sm lg:shadow-none"
                  programTitle="Registration payment"
                  cta={
                    <Button
                      buttonStyle="primary"
                      height="40px"
                      width="100%"
                      className="mt-3"
                      disabled={formProps.isSubmitting}
                      onClick={async () => {
                        submitRef.current?.click();
                      }}
                    >
                      PAY NOW
                    </Button>
                  }
                />
                <button
                  disabled={formProps.isSubmitting}
                  className="hidden"
                  ref={submitRef}
                  type="submit"
                  onClick={() => {
                    setIsSubmitClick(true);
                  }}
                >
                  submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
      {loading && <LoadingModal open={loading} />}
      {openLoginPopup && (
        <LoginUserModal
          prefill_data={{ email: updatedEmail }}
          className=""
          open={openLoginPopup}
          onClose={() => setOpenLoginPopup(false)}
        />
      )}
    </AppSkeleton>
  );
};

export default UnbounceOnboarding;
