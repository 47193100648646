import React, { useEffect, useState } from "react";

import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import Loader from "../../Shimmer/Loader";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import { UserService } from "../../../services/UserService";
import MyAcePoints from "../../AcePoints/MyAcePoints";

/**
 * Component for ACE Points Tracker Page
 * @return {React.ReactElement} ACE Points Tracker Page
 */
function ACEPointsTracker() {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [aceData, setAceData] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await UserService.fetchAceQuizData();
      setQuizData(response.quiz);
      setAceData(response.ace_points);
    } catch {
      setError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <QPFPSkeleton showFooter={true}>
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </QPFPSkeleton>
    );
  }
  if (error) {
    return (
      <QPFPSkeleton showFooter={true}>
        <ErrorScreen />
      </QPFPSkeleton>
    );
  }

  return (
    <QPFPSkeleton showFooter={true}>
      <div className="flex flex-col space-y-6 w-full px-6 py-10 relative z-0">
        <h1 className="font-bold text-2xl">ACE Points Tracker</h1>
        <MyAcePoints
          quizzes={quizData ?? []}
          aceData={aceData}
          showNFPAceDetails={true}
        />
      </div>
    </QPFPSkeleton>
  );
}

export default ACEPointsTracker;
