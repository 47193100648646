/* eslint-disable react/prop-types */
import React from "react";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import { useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import OnboardingHeader from "../../stories/OnboardingHeader";
import { AuthService } from "../../services/AuthService";
import { CustomToast } from "../../stories/Toast";
import { Validations } from "../../constants/validations";

const ResetPasswordScreen = (props) => {
  const navigate = useNavigate();
  useDocumentTitle("Reset Password");
  const token = new URLSearchParams(props.location.search).get("token");

  const initialValues = {
    password: "",
    cpassword: "",
  };

  const handleResetPassword = async ({ password, cpassword }) => {
    try {
      const result = await AuthService.resetPassword({
        password,
        password_confirmation: cpassword,
        token,
      });
      if (result.message) {
        CustomToast.success(result.message);
        setTimeout(() => navigate("/login"), 1000);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
      if (e.error_code === "user_onboarding_payment_not_done") {
        navigate("/onboarding");
      }
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          <div className="flex flex-col">
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Reset Password
            </div>
            <div className="flex mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
              Your new password must be different from your previously used
              password.
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={() =>
                Validations.onBoarding.resetPasswordValidationSchema
              }
              onSubmit={handleResetPassword}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-full mt-8 md:mt-12">
                    <AuthTextInput
                      name="password"
                      id="password"
                      type="password"
                      label="Password"
                    />
                  </div>
                  <div className="w-full mt-6">
                    <AuthTextInput
                      name="cpassword"
                      id="cpassword"
                      type="password"
                      label="Confirm Password"
                    />{" "}
                  </div>
                  <Button
                    type="submit"
                    buttonStyle="primary"
                    height="60px"
                    width="100%"
                    className="mt-6"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    RESET PASSWORD
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
