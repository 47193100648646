import PropTypes from "prop-types";
import React from "react";
import CCHeader from "./CCHeader";
import CCContent from "./CCContent";
import CCActionBar from "./CCActionBar";
const ClientConnectCard = ({
  postId,
  title,
  isLiked,
  likesCount,
  attachments,
  textContent,
  enableLike = true,
}) => {
  return (
    <div className="flex flex-col justify-between w-full h-full max-w-3xl bg-white border">
      <CCHeader attachments={attachments} title={title} />
      <CCContent textContent={textContent} attachments={attachments} />
      {enableLike ? (
        <CCActionBar
          isLiked={isLiked}
          initialLikes={likesCount}
          postId={postId}
        />
      ) : null}
    </div>
  );
};

ClientConnectCard.defaultProps = {
  isLiked: false,
  likesCount: 0,
};

ClientConnectCard.propTypes = {
  attachments: PropTypes.array.isRequired,
  enableLike: PropTypes.bool,
  isLiked: PropTypes.bool,
  likesCount: PropTypes.number,
  postId: PropTypes.any,
  textContent: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ClientConnectCard;
