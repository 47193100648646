/* eslint-disable new-cap */
import config from "../api/config";
import API from "../api/manager";
import { CustomToast } from "../stories/Toast";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.id = "razorpay-script";
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const unloadScript = () => {
  const script = document.getElementById("razorpay-script");
  script.remove();
  const razorpayContainer = document.querySelector("div.razorpay-container");
  razorpayContainer.remove();
};
const makeRazorpayPayment = async (
  userDetails,
  data,
  onCompleteCallback,
  userTempToken,
  paymentType = 1
) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  if (!data) {
    alert("Server error. Are you online?");
    return;
  }
  // Getting the order details back
  const { order_id, id } = data;

  const options = {
    key: process.env.RAZORPAY_LIVE_KEY,
    // amount: amount,
    // currency: "INR",
    name: "Network FP",
    description: "",
    // image: { networkfp_logo },
    order_id: order_id,
    handler: async function (response) {
      const res =
        paymentType === 1
          ? await verifyPayment(id, userTempToken)
          : await verifyContribute(id, userTempToken);
      if (res.payment_status !== "success") {
        alert("Payment Failed");
      }
      onCompleteCallback(res.payment_status === "success");
      unloadScript();
    },
    prefill: userDetails,
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.on("payment.failed", function (response) {
    CustomToast.error("Payment Failed" ?? "");
  });
  paymentObject.open();
};

const fetchDefaultPricing = (id, data) => {
  return API(
    {
      method: "GET",
      url: config.urls.purchases.fetchDefaultPriceDetails(id),
      data: {},
      params: data,
    },
    true
  );
};

const fetchPricingDetails = (data, token) => {
  return API(
    {
      method: "GET",
      url: config.urls.purchases.fetchPriceDetails,
      data: {},
      params: data,
    },
    true,
    token
  );
};

const fetchPricing = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.purchases.fetchPricing,
      data: {},
      params: data,
    },
    true
  );
};

const fetchEntityDetails = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.purchases.entityDetails(data),
      data: {},
    },
    true
  );
};

const fetchPreviousGSTDetails = (data) => {
  return API(
    {
      method: "GET",
      url: config.urls.purchases.fetch_gst_details,
      data: {},
      params: data,
    },
    true
  );
};

const createOrder = async (data, userTempToken) => {
  return await API(
    {
      method: "POST",
      url: config.urls.purchases.createOrder,
      data: data,
    },
    true,
    userTempToken
  );
};

const createOrder1 = (data, zero_amount_paid) => {
  let body = { ...data };
  if (zero_amount_paid) {
    body = { ...body, zero_amount_paid };
  }
  return API(
    {
      method: "POST",
      url: config.urls.purchases.contribute,
      data: body,
    },
    true
  );
};

const verifyPayment = async (purchase_id, userTempToken) => {
  return await API(
    {
      method: "GET",
      url: config.urls.purchases.verifyPayment(purchase_id),
      data: {},
    },
    true,
    userTempToken
  );
};

const verifyContribute = async (purchase_id) => {
  return await API(
    {
      method: "GET",
      url: config.urls.purchases.verifyContribute(purchase_id),
      data: {},
    },
    true
  );
};

const addUserExternalPurchaseDetails = async (data) => {
  return await API(
    {
      method: "POST",
      url: config.urls.purchases.add_user_external_purchase_details,
      data: data,
    },
    true
  );
};

const createCorporatePurchaseDetail = async ({ data }) => {
  return await API(
    {
      method: "POST",
      url: config.urls.purchases.createCorporatePurchaseDetail,
      data: data,
    },
    true
  );
};

const sendPaymentRequestToAdmin = async (data) => {
  return await API(
    {
      method: "POST",
      url: config.urls.purchases.send_request_to_admin,
      data: data,
    },
    true
  );
};

const fetchCorporatePricingDetails = (data, token) => {
  return API(
    {
      method: "GET",
      url: config.urls.purchases.fetchCorporatePriceDetails,
      data: {},
      params: data,
    },
    true,
    token
  );
};

const createCorporatePurchase = async (data, userTempToken) => {
  return await API(
    {
      method: "POST",
      url: config.urls.purchases.createCorporatePurchase,
      data: data,
    },
    true,
    userTempToken
  );
};

export const PaymentServices = {
  makeRazorpayPayment,
  fetchPricingDetails,
  fetchPricing,
  fetchDefaultPricing,
  createOrder,
  createOrder1,
  fetchEntityDetails,
  fetchPreviousGSTDetails,
  verifyPayment,
  verifyContribute,
  addUserExternalPurchaseDetails,
  sendPaymentRequestToAdmin,
  fetchCorporatePricingDetails,
  createCorporatePurchaseDetail,
  createCorporatePurchase,
};
