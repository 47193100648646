import PropTypes from "prop-types";
import classNames from "classnames";
import { Circle, User } from "@phosphor-icons/react";
import React from "react";
import Button from "../../stories/Button";
import Card from "../../stories/Card";
import ImageComponent from "../../stories/ImageComponent";
import ProgressBar from "../../stories/ProgressBar";
import { useNavigate } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import { useProfile } from "../../context/userProvider";
import { CustomToast } from "../../stories/Toast";

const publicProfileAdvantages = [
  "Reach out to more clients",
  "Increase visibility on services and products offered",
  "It acts as your Personal Website",
];
const ViewOnlyField = ({ label, value, className }) => {
  return (
    <div className={classNames("flex w-full flex-col", className)}>
      <div className="text-sm font-medium tracking-tight text-left text-theme-black-300">
        {label}
      </div>
      <div className="mt-0.5 break-words text-base leading-6 text-theme-black-color tracking-tight text-left font-medium">
        {value !== null && value !== "" ? value : "-"}
      </div>
    </div>
  );
};

ViewOnlyField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};
const ShortProfileDisplay = ({ dashboardData }) => {
  return (
    <div className="grid grid-cols-2 gap-x-5">
      <div className="flex flex-col items-center ">
        {dashboardData.profile_info.profile_picture?.signed_url ? (
          <ImageComponent
            src={dashboardData.profile_info.profile_picture?.signed_url ?? ""}
            errorComponent={
              <User size={24} weight="bold" className="text-gray-400" />
            }
            className="w-full object-cover h-full max-w-[160px] rounded-full max-h-40  my-4"
          />
        ) : (
          <div className="flex items-center justify-center my-4 bg-gray-200 rounded-full w-full h-full max-w-[160px]  max-h-40">
            <User
              weight="bold"
              className="w-auto h-full max-w-[60px] rounded-full max-h-[60px]  my-4 text-gray-400"
            />
          </div>
        )}
        <div className="flex items-center justify-center w-full mb-4 text-2xl font-bold leading-none tracking-tighter text-center text-theme-black-color">
          {dashboardData.profile_info.first_name +
            " " +
            dashboardData.profile_info.last_name}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 my-4">
        <ViewOnlyField
          label="Email"
          value={dashboardData.profile_info.email ?? ""}
          className="break-words"
        />
        <ViewOnlyField
          label="Date of Birth"
          value={
            dashboardData.profile_info.dob
              ? moment(dashboardData.profile_info.dob, "DD/MM/YYYY").format(
                  "DD-MM-YYYY"
                )
              : ""
          }
          className="break-words"
        />
        <ViewOnlyField
          label="Mobile Number"
          value={dashboardData.profile_info.phone_number}
          className="break-words"
        />
        <ViewOnlyField
          label="Gender"
          value={
            dashboardData.profile_info.gender
              ? dashboardData.profile_info.gender[0].toUpperCase() +
                dashboardData.profile_info.gender.substring(1)
              : ""
          }
          className="break-words"
        />
        <ViewOnlyField
          label="Years of Experience"
          value={
            dashboardData.profile_info.years_of_experience
              ? dashboardData.profile_info.years_of_experience + " years"
              : "-"
          }
          className="break-words"
        />
        <ViewOnlyField
          label="Field of Study"
          value={
            dashboardData.profile_info.educational_details === undefined
              ? ""
              : dashboardData.profile_info.educational_details[0]
                  ?.field_of_study ?? ""
          }
          className="break-words"
        />
        <ViewOnlyField
          label="City"
          value={dashboardData.profile_info.city_name ?? ""}
          className="break-words"
        />
        <ViewOnlyField
          label="Firm"
          value={dashboardData.profile_info?.firm?.name ?? ""}
          className="break-words"
        />
      </div>
    </div>
  );
};

ShortProfileDisplay.propTypes = {
  dashboardData: PropTypes.shape({
    profile_info: PropTypes.shape({
      city_name: PropTypes.string,
      dob: PropTypes.string,
      educational_details: PropTypes.any,
      email: PropTypes.string,
      firm: PropTypes.shape({
        name: PropTypes.string,
      }),
      first_name: PropTypes.string,
      gender: PropTypes.string,
      last_name: PropTypes.any,
      phone_number: PropTypes.any,
      profile_picture: PropTypes.shape({
        signed_url: PropTypes.string,
      }),
      years_of_experience: PropTypes.number,
    }),
  }),
};

const ProfileAdvantagesComponent = () => {
  return (
    <div className="grid h-full grid-cols-2">
      <div className="flex flex-col ">
        <div className="flex mb-3 text-lg font-bold text-left text-theme-black-color">
          Advantages of creating a public profile
        </div>
        <ul className="">
          {publicProfileAdvantages.map((e, index) => {
            return (
              <div
                key={`${e}-${index}`}
                className={classNames(
                  "flex font-normal items-center text-base tracking-tighter leading-none text-theme-black-color",
                  index + 1 !== publicProfileAdvantages.length ? "mb-4" : ""
                )}
              >
                <Circle
                  className="w-2 h-2 mr-2"
                  weight="fill"
                  color="#f36c04"
                />
                {e}
              </div>
            );
          })}
        </ul>
      </div>
      <div className="w-full h-full bg-center bg-no-repeat bg-contain bg-onboarding-illustration" />
    </div>
  );
};
const CreateProfileCard = ({ navigate }) => {
  const { userDetails } = useProfile();
  return (
    <Card
      className="min-w-full mb-6 mr-0 bg-white lg:mr-6 lg:mb-0 lg:bg-theme-gray-300"
      cardType="filled"
      header={
        <div className="flex items-center text-2xl font-semibold leading-none tracking-tighter text-theme-black-color">
          Create your public profile
        </div>
      }
      cta={
        <Button
          buttonStyle="primary"
          size="xl"
          width="100%"
          onClick={() => {
            if (
              !(
                userDetails.is_pro_member &&
                ["approved", "expires_soon"].includes(
                  userDetails.pm_signup_status
                )
              )
            ) {
              CustomToast.info(
                "Become an active Promember to create your profile."
              );
              return;
            }
            navigate("/public-profile");
          }}
        >
          CREATE PROFILE
        </Button>
      }
      buttonLabel="CREATE PROFILE"
      buttonStyle="primary"
      onButtonClick={() => navigate("/public-profile")}
      cardBody={
        <div className="flex flex-col mt-4 mb-5 text-sm leading-5 tracking-tight text-theme-black-300">
          Create your Public Profile and become a part of India&apos;s most
          authentic and reliable directory for financial advisors &
          distributors; that the public can trust, reach out and work with NFP
          Members with confidence.
        </div>
      }
    />
  );
};

CreateProfileCard.propTypes = {
  navigate: PropTypes.func,
};

const InCompleteProfileCard = ({ dashboardData, navigate }) => {
  return (
    <Card
      className="h-full min-w-full mb-6 mr-0 lg:mr-6 lg:mb-0"
      cardType="outline"
      header={
        <ProgressBar
          topLeftLabel={
            <div className="text-lg font-semibold leading-none tracking-tighter text-theme-black-color">
              Complete Profile
            </div>
          }
          topRightLabel={
            <div className="text-xs leading-none tracking-tight text-theme-black-color">
              {dashboardData.profile_info?.profile_sections
                ?.percentage_completion ?? 0}
              %
            </div>
          }
          percent={
            dashboardData.profile_info?.profile_sections
              ?.percentage_completion ?? 0
          }
          showProgress={true}
        />
      }
      cta={
        <Button
          buttonStyle="primary"
          size="xl"
          width="100%"
          onClick={() => {
            navigate("/public-profile");
          }}
        >
          COMPLETE PROFILE
        </Button>
      }
      onButtonClick={() => navigate("/public-profile")}
      cardBody={
        <div className="flex flex-col w-full mt-4 mb-5">
          {_.slice(
            dashboardData?.profile_info?.profile_completion_details
              ?.pending_section_messages ?? [],
            0,
            3
          ).map((e, index) => {
            return (
              <div
                key={`${e}-${index}`}
                className={classNames(
                  "flex items-start text-base tracking-tighter leading-none text-theme-black-300",
                  index + 1 !==
                    _.slice(
                      dashboardData?.profile_info?.profile_completion_details
                        ?.pending_section_messages ?? [],
                      0,
                      3
                    )?.length
                    ? "mb-3"
                    : ""
                )}
              >
                <div className="flex items-center h-4">
                  <Circle
                    className="h-2 mr-2"
                    // size={8}
                    weight="fill"
                    color="#f36c04"
                  />
                </div>{" "}
                {e}
              </div>
            );
          })}
        </div>
      }
    />
  );
};

InCompleteProfileCard.propTypes = {
  dashboardData: PropTypes.shape({
    profile_info: PropTypes.shape({
      profile_completion_details: PropTypes.shape({
        pending_section_messages: PropTypes.array,
      }),
      profile_sections: PropTypes.shape({
        percentage_completion: PropTypes.number,
      }),
    }),
  }),
  navigate: PropTypes.func,
};

const PublicProfileSection = ({ dashboardData }) => {
  const navigate = useNavigate();

  const profilePercentage =
    dashboardData?.profile_info?.profile_sections?.percentage_completion ?? 0;
  if (profilePercentage >= 100) {
    return null;
  }
  return (
    <div className="grid grid-cols-1 p-3 bg-white lg:p-6 lg:grid-cols-3 gap-x-6">
      <div className="lg:col-span-1">
        {profilePercentage > 0 ? (
          <InCompleteProfileCard
            // publicProfileData={publicProfileData}
            dashboardData={dashboardData}
            navigate={navigate}
          />
        ) : (
          <CreateProfileCard navigate={navigate} />
        )}
      </div>
      <div
        className={classNames(
          "lg:col-span-2 hidden lg:block",
          profilePercentage > 0 ? "border border-theme-gray-300" : ""
        )}
      >
        {profilePercentage > 0 ? (
          <ShortProfileDisplay dashboardData={dashboardData} />
        ) : (
          <ProfileAdvantagesComponent
            publicProfileAdvantages={publicProfileAdvantages}
          />
        )}
      </div>
    </div>
  );
};

PublicProfileSection.propTypes = {
  dashboardData: PropTypes.shape({
    profile_info: PropTypes.shape({
      profile_sections: PropTypes.shape({
        percentage_completion: PropTypes.number,
      }),
    }),
  }),
};

export default PublicProfileSection;
