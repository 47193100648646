import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import moment from "moment";
import ImageComponent from "./ImageComponent";
import { ArrowArcRight, User } from "@phosphor-icons/react";
import classNames from "classnames";
import CustomLink from "./Link";
import { getDirectoryMemberURL } from "../util/getURLs";
const CommentCard = ({
  id,
  profileSlug = "",
  userImage,
  userName,
  location,
  time,
  content,
  isReply,
  replyCallback,
  canReply,
  isCommentReply = true,
}) => {
  return (
    <div
      className={classNames(
        "flex space-x-2",
        isReply ? "bg-amber-50 rounded-lg pl-13 md:ml-14 p-3" : ""
      )}
    >
      {isCommentReply && (
        <CustomLink
          textColor="text-blue-600"
          activeTextColor="text-blue-600"
          showUnderLine={false}
          underLineOnlyOnHover={false}
          fontSize="sm"
          target="_blank"
          href={getDirectoryMemberURL(profileSlug)}
          className="font-normal leading-5 tracking-tight h-fit"
        >
          <ImageComponent
            src={userImage}
            errorComponent={
              <div className="flex items-center justify-center w-8 h-8  min-w-[32px] md:min-w-[48px] bg-gray-200 rounded-full md:h-12 md:w-12">
                <User weight="bold" className="w-6 h-6 text-gray-400" />
              </div>
            }
            alt="user"
            className="object-cover w-8 h-8 min-w-[32px] md:min-w-[48px] rounded-full md:h-12 md:w-12"
          />
        </CustomLink>
      )}
      <div className="flex w-full flex-col space-y-1.5">
        <Text
          content={
            isReply ? (
              <div className="flex items-center space-x-2">
                <div className="flex">
                  {isCommentReply ? (
                    <CustomLink
                      textColor="text-theme-black-color"
                      activeTextColor="text-theme-black-color"
                      showUnderLine={false}
                      underLineOnlyOnHover={false}
                      fontSize="base"
                      target="_blank"
                      href={getDirectoryMemberURL(profileSlug)}
                      className="font-semibold leading-5 tracking-tight h-fit"
                    >
                      {userName}
                    </CustomLink>
                  ) : (
                    `Reply from ${userName}`
                  )}
                </div>
                {isCommentReply && (
                  <>
                    <div className="flex">
                      <ArrowArcRight size={16} />
                    </div>
                    <div className="flex">
                      <CustomLink
                        textColor="text-theme-black-color"
                        activeTextColor="text-theme-black-color"
                        showUnderLine={false}
                        underLineOnlyOnHover={false}
                        fontSize="base"
                        target="_blank"
                        href={getDirectoryMemberURL(isReply.profile_slug)}
                        className="font-semibold leading-5 tracking-tight h-fit"
                      >{`${isReply.first_name} ${isReply.last_name}`}</CustomLink>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <CustomLink
                textColor="text-theme-black-color"
                activeTextColor="text-theme-black-color"
                showUnderLine={false}
                underLineOnlyOnHover={false}
                fontSize="base"
                target="_blank"
                href={getDirectoryMemberURL(profileSlug)}
                className="font-semibold leading-5 tracking-tight h-fit"
              >
                {userName}
              </CustomLink>
            )
          }
          type="text"
          size="text-sm md:text-base"
          className="font-semibold text-theme-black-color"
          subtext={
            <div className="flex items-center text-xs font-normal text-neutral-400 md:text-sm">
              {location}
              <div className="container rounded-full bg-gray-400 w-1 h-1 mx-1.5" />
              {moment(time).fromNow()}
            </div>
          }
          subtextclass="text-sm"
        />
        <Text
          content={content}
          type="text"
          className="font-normal text-theme-black-color"
          size="text-sm md:text-base"
        />
        <button
          className="self-start text-sm font-normal text-theme-red"
          onClick={() => replyCallback(id)}
        >
          {canReply ? "REPLY" : ""}
        </button>
      </div>
    </div>
  );
};

CommentCard.defaultProps = {
  isReply: false,
  canReply: false,
};

CommentCard.propTypes = {
  canReply: PropTypes.bool,
  content: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCommentReply: PropTypes.bool,
  isReply: PropTypes.bool,
  location: PropTypes.string,
  profileSlug: PropTypes.string,
  replyCallback: PropTypes.func,
  time: PropTypes.string.isRequired,
  userImage: PropTypes.string,
  userName: PropTypes.string.isRequired,
};

export default CommentCard;
