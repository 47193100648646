/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ComplimentaryServicesCard from "./ComplimentaryServicesCard";
import ContactInfoCard from "./ContactInfoCard";
import FirmInfoCard from "./FirmInfoCard";
import PracticeInfoCard from "./PracticeInfoCard";
import ProfileCompletionCard from "./ProfileCompletionCard";
import ServicesOfferedCard from "./ServicesOfferedCard";
import ViewProfileCard from "./ViewProfileCard";
import AppSkeleton from "../../AppSkeleton";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import {
  useCorporateProfile,
  useOptions,
} from "../../../services/BasicDetails";
import { useProfile } from "../../../context/userProvider";
import Loader from "../../Shimmer/Loader";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import OathCard from "./OathCard";
import { useNavigate } from "@reach/router";
import { CustomToast } from "../../../stories/Toast";
import _ from "lodash";
import { getDirectoryFirmURL } from "../../../util/getURLs";

const CorporateProfile = () => {
  const {
    loading: corporateProfileLoading,
    corporateProfileData,
    mutate: mutateCoporateProfile,
    error,
  } = useCorporateProfile({
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });
  const { userDetails, updateUserData } = useProfile();
  const navigate = useNavigate();
  useDocumentTitle("Profile | Corporate Account");

  const { options } = useOptions(
    [
      "industry_licence",
      "service_delivery_model",
      "solution",
      "product",
      "complementary_service",
      "nature_of_earning",
      "pro_member_graduation_stream_id",
      "pro_member_exam_id",
      "pro_member_qualification_id",
    ],
    {
      revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
    }
  );

  const fetchAllData = async () => {
    try {
      // await fetchPublicProfilesDropdowns();
      mutateCoporateProfile();
      await updateUserData();
      // mutateDashboard();
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userDetails) && !userDetails.firm_signup_status) {
      navigate("/");
      CustomToast.info("You cannot access this page");
    }
  }, [userDetails]);

  if (corporateProfileLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Loader />
      </div>
    );
  }
  if (error) {
    return (
      <AppSkeleton
        pageLabel="Corporate Profile"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          {
            name: "Corporate Profile",
            to: "/corporate/profile",
            current: true,
          },
        ]}
      >
        <ErrorScreen />
      </AppSkeleton>
    );
  }
  return (
    <AppSkeleton
      pageLabel="Corporate Profile"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "Corporate Profile", to: "/corporate/profile", current: true },
      ]}
    >
      <div className="flex flex-col w-full pb-3">
        <div className="flex flex-col h-full p-3 pt-0 lg:flex-row bg-theme-gray-200 ">
          <div className="flex flex-col order-2 w-full p-1 lg:p-3 lg:order-1">
            <div className="flex-col hidden w-full 1440px:flex-row lg:flex ">
              <ProfileCompletionCard
                className={
                  "my-3 1440px:mr-3 w-full 1440px:max-w-[55%] bg-white p-6 shadow-sm rounded-sm "
                }
                percentCompleted={
                  corporateProfileData?.firm?.firm_complete_percentage ?? 0
                }
                totalForms={5}
              />
              <ViewProfileCard
                className={
                  "my-3 1440px:ml-3 w-full hidden 1440px:flex 1440px:max-w-[45%] bg-white p-6 shadow-sm rounded-sm "
                }
                showProfileToDirectory={
                  corporateProfileData?.show_profile_to_directory
                }
                onUpdate={async () => {
                  await fetchAllData();
                }}
                isPromember={userDetails.is_pro_member}
                profileLink={getDirectoryFirmURL(
                  corporateProfileData?.firm?.uid
                )}
              />
            </div>
            <PracticeInfoCard
              className="my-3"
              corporateProfileData={corporateProfileData}
              isLoading={corporateProfileLoading}
              onUpdate={() => {
                fetchAllData();
              }}
              options={options}
            />
            <ServicesOfferedCard
              className="my-3 bg-white rounded-sm shadow-sm "
              corporateProfileData={corporateProfileData}
              isLoading={corporateProfileLoading}
              onUpdate={() => {
                fetchAllData();
              }}
              options={options}
            />
            <ComplimentaryServicesCard
              className="my-3 bg-white rounded-sm shadow-sm "
              corporateProfileData={corporateProfileData}
              isLoading={corporateProfileLoading}
              onUpdate={() => {
                fetchAllData();
              }}
              options={options}
            />
          </div>
          <div className="flex p-1 lg:p-3 order-1 lg:order-2 flex-col w-full min-w-full lg:min-w-[36%] max-w-full lg:max-w-md">
            <div className="flex flex-col w-full">
              <ProfileCompletionCard
                className={
                  "my-3 mr-3 lg:hidden bg-white p-6 shadow-sm rounded-sm"
                }
                percentCompleted={
                  corporateProfileData?.firm?.firm_complete_percentage ?? 0
                }
                totalForms={5}
              />
              <ViewProfileCard
                className={
                  "my-3 flex 1440px:hidden bg-white p-6 shadow-sm rounded-sm "
                }
                showProfileToDirectory={
                  corporateProfileData?.show_profile_to_directory
                }
                onUpdate={async () => {
                  await fetchAllData();
                }}
                isPromember={userDetails.is_pro_member}
                profileLink={getDirectoryFirmURL(
                  corporateProfileData?.firm?.uid
                )}
              />
            </div>
            <FirmInfoCard
              className="my-3 bg-white rounded-sm shadow-sm "
              corporateProfileData={corporateProfileData}
              isLoading={corporateProfileLoading}
              onUpdate={() => {
                fetchAllData();
              }}
              options={options}
            />
            <ContactInfoCard
              className="my-3 bg-white rounded-sm shadow-sm "
              corporateProfileData={corporateProfileData}
              isLoading={corporateProfileLoading}
              onUpdate={() => {
                fetchAllData();
              }}
              options={options}
            />
            <OathCard
              className="my-3 bg-white rounded-sm shadow-sm "
              corporateProfileData={corporateProfileData}
              isLoading={corporateProfileLoading}
              onUpdate={() => {
                fetchAllData();
              }}
              options={options}
            />
          </div>
        </div>
      </div>
    </AppSkeleton>
  );
};

export default CorporateProfile;
