import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import EmptyStatePlaceHolder from "../../../assets/svgs/empty_state_bg.svg";
import { useNavigate } from "@reach/router";
import CorporateSubscriptionAccordian from "../../../stories/CorporateSubscriptionAccordian";
import EmptyState from "../../../stories/EmptyState";
import Button from "../../../stories/Button";
import Table from "../../../stories/Table";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import { CurrencyInr } from "@phosphor-icons/react";
import CorporatePaymentModal from "../../Modals/CorporatePaymentModal";
import { CustomToast } from "../../../stories/Toast";
import _ from "lodash";

const CorporateSubscriptionTable = ({
  columns,
  isLoading,
  error,
  data,
  onClickSelectModule,
  removeSelectModule,
  disableCheckboxIndex = [],
  showEmptyState = false,
  selectedQPFPModules,
  refreshData,
}) => {
  const { width } = useWindowSize();
  const isDesktop = width >= 728;
  const navigate = useNavigate();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState({});
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const paymentEntitiesRef = useRef();

  if (error) return <ErrorScreen />;

  if (showEmptyState) {
    return (
      <EmptyState
        heading="No Invoices Yet!"
        subHeading={
          "When you will subscribe to any program, you will see relevant invoices here. To subscribe a program click on the below button."
        }
        cta={
          <Button className="!mt-4" onClick={() => navigate("/register")}>
            <div className="flex items-center px-3 py-1">
              <span>SUBSCRIBE PROGRAMS</span>
            </div>
          </Button>
        }
        image={
          <img
            alt="emptyStatePlaceHolder"
            className="py-9 max-h-80"
            src={EmptyStatePlaceHolder}
          />
        }
      />
    );
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        data={data}
        columns={columns}
        showPagination={true}
        isRowSelectable={true}
        disableCheckboxIndex={disableCheckboxIndex}
        paginationProps={{
          perPage: 10,
        }}
        preSelectedRow={{ ...selectedSubscriptions } ?? {}}
        onSelectChange={({ event, rowIndexInData }) => {
          if (event.target.checked) {
            setSelectedSubscriptions((prev) => ({
              ...prev,
              [rowIndexInData]: event.target.checked,
            }));
          } else {
            const temp = { ...selectedSubscriptions };
            delete temp[rowIndexInData];
            setSelectedSubscriptions(temp);
          }
        }}
        MobileViewCard={({ data, col, index, formikProps }) => (
          <CorporateSubscriptionAccordian
            item={data}
            key={index}
            index={index}
            formikProps={formikProps}
            onClickSelectModule={onClickSelectModule}
            removeSelectModule={removeSelectModule}
            disableCheckboxIndex={disableCheckboxIndex}
            selectedQPFPModules={selectedQPFPModules}
            onSelectChange={({ event, rowIndexInData }) => {
              if (event.target.checked) {
                setSelectedSubscriptions((prev) => ({
                  ...prev,
                  [rowIndexInData]: event.target.checked,
                }));
              } else {
                const temp = selectedSubscriptions;
                delete temp[rowIndexInData];
                setSelectedSubscriptions(temp);
              }
            }}
            isDesktop={isDesktop}
          />
        )}
      />
      <div className="flex flex-col items-center justify-end w-full gap-4 mt-6 lg:gap-6 lg:flex-row">
        <div className="block">
          <span className="inline text-base leading-5 text-theme-black-300">
            Total Amount + GST:
          </span>
          <span className="inline ml-2 text-base font-semibold leading-5 text-theme-black-color">
            <CurrencyInr size={16} weight="bold" className="inline w-fit" />
            {_.keys(selectedSubscriptions).reduce((prev, currV, currI) => {
              return (
                prev +
                (selectedQPFPModules[
                  `${data[currV].entity_details.entity_type}_${data[currV].entity_details.entity_id}`
                ]?.total_amount ??
                  data[currV].payment_details?.total_amount ??
                  data[currV].total_amount ??
                  0)
              );
            }, 0)}
          </span>
        </div>

        <Button
          buttonStyle="primary"
          type="button"
          size="md"
          height="36px"
          width="330px"
          disabled={
            _.keys(selectedSubscriptions).filter(
              (k) => selectedSubscriptions[k]
            ).length === 0
          }
          onClick={async () => {
            const filteredQPFPSubsc = _.keys(selectedSubscriptions).filter(
              (val, currI) => {
                return (
                  (data[val].course_type ?? data[val].course?.course_type) ===
                    "qpfp" &&
                  selectedQPFPModules[
                    `${data[val].entity_details.entity_type}_${data[val].entity_details.entity_id}`
                  ] === undefined &&
                  data[val].payment_details?.pricing_id === undefined &&
                  data[val].pricing_id === undefined
                );
              }
            );
            if (filteredQPFPSubsc.length > 0) {
              CustomToast.error(
                "Please select QPFP module for selected subscriptions"
              );
              return;
            }
            paymentEntitiesRef.current = _.keys(selectedSubscriptions).map(
              (e) => {
                const entityData = {
                  entity_type: data[e].entity_details.entity_type,
                  entity_id: data[e].entity_details.entity_id,
                };
                const avlPartPaymentOptions =
                  data[e].available_part_payment_option ??
                  data[e].payment_details?.available_part_payment_option;

                if ((avlPartPaymentOptions ?? []).length === 0) {
                  entityData["pricing_id"] =
                    data[e].payment_details?.pricing_id ?? data[e].pricing_id;
                  return entityData;
                }

                if (avlPartPaymentOptions) {
                  entityData["pricing_id"] =
                    data[e].entity_details.entity_type === "qpfp"
                      ? selectedQPFPModules[
                          `${data[e].entity_details.entity_type}_${data[e].entity_details.entity_id}`
                        ].id
                      : avlPartPaymentOptions[0].id;
                }

                return entityData;
              }
            );
            setOpenPaymentModal(true);
          }}
        >
          Make Payment
        </Button>
      </div>
      {openPaymentModal && (
        <CorporatePaymentModal
          open={openPaymentModal !== -1}
          title={"Make Payment"}
          paymentEntityProps={paymentEntitiesRef.current}
          onClose={(isSuccess) => {
            if (isSuccess === true) {
              refreshData();
              setSelectedSubscriptions({});
            }
            setOpenPaymentModal(false);
          }}
        />
      )}
    </>
  );
};

CorporateSubscriptionTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  disableCheckboxIndex: PropTypes.array,
  error: PropTypes.any,
  headerBgColor: PropTypes.string,
  headerClass: PropTypes.string,
  isLoading: PropTypes.bool,
  onClickSelectModule: PropTypes.any,
  refreshData: PropTypes.func,
  removeSelectModule: PropTypes.any,
  selectedQPFPModules: PropTypes.any,
  showEmptyState: PropTypes.bool,
  showTable: PropTypes.bool,
};
export default CorporateSubscriptionTable;
