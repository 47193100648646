import PropTypes from "prop-types";
import { CaretUp } from "@phosphor-icons/react";
import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";

const AccordionComponent = ({
  Heading,
  Children,
  accordianProps,
  CloseButton,
  panelClassNames,
  disclosureClass,
  alwaysOpen = true,
  stickyHeader,
  stickyTopPosition = "",
  stickyZIndex = "z-10",
}) => {
  return (
    <div className={classNames("w-full max-w-full ", disclosureClass)}>
      <Disclosure {...accordianProps}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                "flex justify-between w-full text-base font-medium text-left md:text-lg text-theme-black-color focus:outline-none focus-visible:ring space-x-4",
                stickyHeader && "sticky top-0 bg-white pt-4 pb-2",
                stickyHeader ? stickyTopPosition : "",
                stickyHeader ? stickyZIndex : ""
              )}
            >
              <div className="flex">
                <Heading open={open} />
              </div>
              <CaretUp
                weight="fill"
                size={24}
                className={`${
                  !open ? "transform rotate-180" : ""
                } min-w-[24px] text-theme-black-color`}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                static
                className={classNames(
                  "py-2 text-sm text-gray-500 lg:pl-4",
                  panelClassNames
                )}
              >
                {({ close }) => (
                  <div>
                    <Children
                      open={open}
                      onHide={() => {
                        close();
                      }}
                    />
                    <Disclosure.Button className="flex justify-between w-full text-base font-medium text-left md:text-lg text-theme-black-color focus:outline-none focus-visible:ring ">
                      {CloseButton}
                    </Disclosure.Button>
                  </div>
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

AccordionComponent.propTypes = {
  Children: PropTypes.any,
  CloseButton: PropTypes.any,
  Heading: PropTypes.any,
  accordianProps: PropTypes.object,
  alwaysOpen: PropTypes.bool,
  disclosureClass: PropTypes.string,
  panelClassNames: PropTypes.string,
  stickyHeader: PropTypes.bool,
  stickyTopPosition: PropTypes.string,
  stickyZIndex: PropTypes.string,
};
export default AccordionComponent;
