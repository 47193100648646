import PropTypes from "prop-types";
import React from "react";
import AccordionComponent from "./AccordionComponent";
import ImageComponent from "./ImageComponent";
import { CustomToast } from "./Toast";
import moment from "moment";
import { getInvoiceDownloadURL } from "../util/getURLs";

const InvoiceAccordian = ({ item, index }) => {
  return (
    <div className="p-2 bg-white md:p-4">
      <div className="flex p-4 border">
        <AccordionComponent
          Heading={() => <AccordianHeading data={item} />}
          Children={(open) => <AccordianChildren data={item} />}
          CloseButton={
            <button className="w-full px-6 pt-2 text-sm font-semibold text-center border-t text-theme-black-color">
              HIDE DETAILS
            </button>
          }
        />
      </div>
    </div>
  );
};

InvoiceAccordian.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
};

const AccordianHeading = ({ data }) => {
  return (
    <div className="flex items-center text-base leading-none tracking-tight text-theme-black-color ">
      <ImageComponent
        errorComponent={
          <div className="w-8 min-w-[32px] h-8 bg-gray-200 mr-4"></div>
        }
        src={data.course_logo ?? data?.voluntary_contribution_logo?.signed_url}
        className="object-cover w-8 min-w-[32px] h-8 mr-4"
      />
      <div>{data.program_name}</div>
    </div>
  );
};

AccordianHeading.propTypes = {
  data: PropTypes.shape({
    course_logo: PropTypes.any,
    program_name: PropTypes.any,
    voluntary_contribution_logo: PropTypes.shape({
      signed_url: PropTypes.any,
    }),
  }),
};
const AccordianChildren = ({ data, index }) => {
  return (
    <div className="py-4 md:pb-0">
      <div className="grid grid-cols-2 gap-4 pb-4 xl:grid-cols-3">
        {/* <DataComponent title="STATUS" data={data.subscription_status} /> */}
        {data.start_date && (
          <DataComponent
            title="START DATE"
            data={moment(data.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY")}
          />
        )}
        <DataComponent
          title="AMOUNT"
          data={`₹ ${
            data.amount
              ? data.amount === 0
                ? data.amount
                : data.amount.toLocaleString("en-IN", {
                    minimumFractionDigits: 0,
                    minimumIntegerDigits: 2,
                  })
              : 0
          }`}
        />
      </div>
      {data.purchase_id && (
        // Condition to show button as well as Show Download Invoice instead of PAY NOW if the Table is Invoice
        <React.Fragment>
          <button
            onClick={() => {
              const url = getInvoiceDownloadURL(data.purchase_id);
              fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const blobURL = window.URL.createObjectURL(blob);
                  const filename = data.invoice_number;
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = blobURL;
                  // the filename you want
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(blobURL);
                })
                .catch(() =>
                  CustomToast.error(
                    "Something went wrong!! Cannot download file"
                  )
                );
            }}
            className="w-full px-6 py-2 text-sm font-semibold text-center text-white bg-theme-red"
          >
            DOWNLOAD INVOICE
          </button>
          <a
            rel="noreferrer"
            href={getInvoiceDownloadURL(data.purchase_id)}
            id={`download-invoice-#${index}`}
            download
            target="_blank"
            className="hidden"
          >
            Click here
          </a>
        </React.Fragment>
      )}
    </div>
  );
};

AccordianChildren.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.number,
    invoice_number: PropTypes.any,
    purchase_id: PropTypes.any,
    start_date: PropTypes.any,
  }),
  index: PropTypes.number,
};

const DataComponent = ({ title, data }) => {
  return (
    <div className="items-center">
      <div className="text-sm font-medium sm:text-xs text-neutral-600">
        {title}
      </div>
      <div className="text-base font-medium sm:text-sm text-theme-black-color">
        {data}
      </div>
    </div>
  );
};

DataComponent.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
};
export default InvoiceAccordian;
