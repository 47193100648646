import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import reload_img from "../../assets/svgs/Reload.svg";
import { X } from "@phosphor-icons/react";
import PaymentModal from "./PaymentModal";
import _ from "lodash";

const RenewModal = ({
  open,
  entityType,
  entityId,
  courseName = "",
  allowUserToSkipRenewal = false,
  onClickSkipRenewal = () => {},
  remainingAmount,
  availablePartPaymentOptions,
  refreshCallback = () => {},
  onClose,
  className,
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6 min-w-[24px]" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="flex items-center mb-3 mr-6 text-xl font-semibold leading-6 tracking-tight md:text-2xl text-theme-black-color"
              >
                <div className="flex items-center justify-center mr-4 rounded-full min-w-10 bg-theme-orange bg-opacity-30">
                  <img
                    src={reload_img}
                    className="w-6 h-6 m-2 min-w-[24px]"
                    alt="reload_icon"
                  />
                </div>
                Renew Subscription
              </Dialog.Title>
              <div className="flex pl-2 ml-0 text-base md:ml-12">
                Your membership is due for renewal. Kindly pay ₹{" "}
                {remainingAmount === 0
                  ? 0
                  : remainingAmount.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      minimumIntegerDigits: 2,
                    })}{" "}
                to renew and continue membership benefits and access modules &
                sessions.
              </div>
              <div className="flex flex-col items-center w-full mt-10 space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 lg:flex-row lg:justify-end">
                {allowUserToSkipRenewal && (
                  <Button
                    buttonStyle="secondary"
                    type="submit"
                    size="md"
                    height="40px"
                    width="204px"
                    onClick={() => onClickSkipRenewal()}
                  >
                    SKIP TO COURSE
                  </Button>
                )}
                <Button
                  buttonStyle="primary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="204px"
                  onClick={() => setShowPaymentModal(true)}
                >
                  PAY NOW
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
        {showPaymentModal && (
          <PaymentModal
            open={showPaymentModal}
            title={`Payment for ${courseName}`}
            entityProps={{
              entity_type: entityType,
              entity_id: entityId,
              part_payment_order:
                _.head(availablePartPaymentOptions ?? [])?.part_payment_order ??
                0,
            }}
            availablePartPaymentOptions={availablePartPaymentOptions}
            onClose={() => {
              refreshCallback();
              setShowPaymentModal(false);
            }}
          />
        )}
      </Dialog>
    </Transition.Root>
  );
};

RenewModal.defaultProps = {
  open: false,
  className: "",
};

RenewModal.propTypes = {
  allowUserToSkipRenewal: PropTypes.bool,
  amount: PropTypes.number,
  availablePartPaymentOptions: PropTypes.array,
  className: PropTypes.string,
  courseName: PropTypes.string,
  entityId: PropTypes.any,
  entityType: PropTypes.any,
  onClickSkipRenewal: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refreshCallback: PropTypes.func,
  remainingAmount: PropTypes.number,
};

export default RenewModal;
