/* eslint-disable new-cap */
import API from "../api/manager";
import config from "../api/config";

const userAttribute = (obj) => {
  return {
    user: obj,
  };
};

const fetchPublicProfile = () => {
  return API(
    {
      method: "GET",
      url: config.urls.public_profile.fetch_profile,
      data: {},
    },
    true
  );
};

const updatePublicProfile = (data, token) => {
  if (data === null || data === "") {
    return;
  }
  return API(
    {
      method: "PUT",
      url: config.urls.public_profile.update_profile,
      data: userAttribute(data),
    },
    true,
    token
  );
};
const updateFirmInfo = (data) => {
  if (data === null || data === "") {
    return;
  }
  return API(
    {
      method: "PUT",
      url: config.urls.public_profile.update_firm_info,
      data: { member: data },
    },
    true
  );
};
const searchFirms = (val) => {
  return API(
    {
      method: "GET",
      url: config.urls.public_profile.search_firm(val),
      data: {},
    },
    true
  );
};

const updatePhoneNumber = ({ phone_number }) => {
  if (phone_number === null || phone_number === "") {
    return;
  }
  return API(
    {
      method: "PUT",
      url: config.urls.public_profile.update_profile,
      data: userAttribute({ phone_number }),
    },
    true
  );
};

const fetchDropdowns = (data) => {
  if (data === null || data === "") {
    return;
  }
  return API(
    {
      method: "GET",
      url: config.urls.public_profile.fetch_dropdowns,
      params: { sections: data },
    },
    true
  );
};

const updateCorporateInfo = ({ data, id }) => {
  return API(
    {
      method: "PUT",
      url: config.urls.corporate_profile.update_firm(id),
      data: { firm: data },
    },
    true
  );
};
const searchFirmUsers = (params) => {
  return API(
    {
      method: "GET",
      url: config.urls.corporate_profile.search_firm_users,
      params: params,
    },
    true
  );
};
const getFirmDetails = (params) => {
  return API(
    {
      method: "GET",
      url: config.urls.corporate_profile.get_firm_details,
    },
    true
  );
};

const inviteNewUsers = ({ data }) => {
  return API(
    {
      method: "POST",
      url: config.urls.corporate_profile.invite_new_users,
      data: { firm: data },
    },
    true
  );
};
const updateFirmInviteStatus = ({ firm_id, status }) => {
  return API(
    {
      method: "GET",
      url: config.urls.corporate_profile.update_firm_invitation_status(firm_id),
      params: { status: status },
    },
    true
  );
};
const deleteFirmUsers = ({ firm_user_list }) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.corporate_profile.delete_firm_users,
      params: { ids: firm_user_list },
    },
    true
  );
};

export const PublicProfileService = {
  fetchPublicProfile,
  updatePublicProfile,
  searchFirms,
  updateFirmInfo,
  fetchDropdowns,
  updatePhoneNumber,
  updateCorporateInfo,
  searchFirmUsers,
  getFirmDetails,
  inviteNewUsers,
  updateFirmInviteStatus,
  deleteFirmUsers,
};
