import PropTypes from "prop-types";
import { Info } from "@phosphor-icons/react";
import React, { useState } from "react";
import MembershipRegistrationModal from "../components/Modals/MembershipRegistrationModal";

const MembershipRSVPRegisterBar = ({ prefillData }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <div className="flex flex-col justify-between px-4 py-3 rounded md:items-center bg-blue-400/20 md:flex-row">
        <div className="flex items-start text-base font-medium leading-5 tracking-tight text-blue-800">
          <Info
            size={20}
            weight="fill"
            className="mr-3 min-w-[20px] text-blue-300"
          />
          Register for NFP ProMemberhip & get UpSkill Series Free!
        </div>
        <button
          onClick={() => setOpen(true)}
          className="h-8 mt-4 text-sm text-white bg-blue-800 rounded-sm md:mt-0 md:w-32"
        >
          REGISTER
        </button>
      </div>
      {open && (
        <MembershipRegistrationModal
          className=""
          open={open}
          prefillData={prefillData}
          onClose={() => setOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

MembershipRSVPRegisterBar.propTypes = {
  prefillData: PropTypes.any,
};

export default MembershipRSVPRegisterBar;
