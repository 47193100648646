/* eslint-disable react/prop-types */
import { createContext, useState, useContext, useEffect } from "react";
import { UserService } from "../services/UserService";
const ProfileContext = createContext();

export const useProfile = () => {
  return useContext(ProfileContext);
};
export const ProfileProvider = ({ children, initialValue }) => {
  const [isProfileLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(
    initialValue?.userDetails ?? {}
  );
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(
    userDetails.unread_notification_count ?? 0
  );

  useEffect(() => {
    setUserDetails(initialValue?.userDetails ?? {});
    if (initialValue?.userDetails) {
      setUnreadNotificationCount(
        initialValue?.userDetails?.unread_notification_count ?? 0
      );
      window.fcWidget?.user?.setFirstName(
        initialValue?.userDetails?.first_name
      );
      window.fcWidget?.user?.setLastName(initialValue?.userDetails?.last_name);
      window.fcWidget?.user?.setEmail(initialValue?.userDetails?.email);
      window.fcWidget?.user?.setPhone(initialValue?.userDetails?.phone_number);
      window.fcWidget?.user?.setProperties({
        "Message Sent From": window.location.origin,
      });
    }
  }, [initialValue]);

  const updateUserData = async (saveUserDetailsToFreshChat = false) => {
    try {
      const response1 = await UserService.fetchUserDetails();
      window.fcWidget.user
        ?.get()
        ?.then(async (res) => {
          window.fcWidget.user.clear();
        })
        .catch((e) => {
          console.log(e);
        });
      // await window.fcWidget.destroy();
      window.fcWidget.init({
        token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
        host: "https://wchat.in.freshchat.com",
        externalId: response1.email,
      });
      setUserDetails(response1);
      setUnreadNotificationCount(response1.unread_notification_count ?? 0);
      if (saveUserDetailsToFreshChat) {
        window.fcWidget?.user?.setFirstName(response1.first_name);
        window.fcWidget?.user?.setLastName(response1.last_name);
        window.fcWidget?.user?.setEmail(response1.email);
        window.fcWidget?.user?.setPhone(response1.phone_number);
        window.fcWidget?.user?.setProperties(
          "Message Sent From",
          window.location.origin
        );
      }
      return response1;
    } catch (e) {
      console.log(e);
      setUserDetails({});
      return {};
    }
  };

  const value = {
    isProfileLoading,
    userDetails,
    updateUserData,
    unreadNotificationCount,
    setUnreadNotificationCount,
  };

  return (
    <>
      <ProfileContext.Provider value={value}>
        {children}
      </ProfileContext.Provider>
    </>
  );
};
