/* eslint-disable react-hooks/exhaustive-deps */
import { CircleNotch } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useCourses } from "../../services/CourseDetails";
import { ErrorScreen } from "../../stories/ErrorScreen";
import ProgramCard from "../../stories/ProgramCard";
import SearchBar from "../../stories/SearchBar";
import Text from "../../stories/Text";
import AppSkeleton from "../AppSkeleton";
import _ from "lodash";
import EmptyStatePlaceHolder from "../../assets/svgs/empty_state_bg.svg";
import EmptyState from "../../stories/EmptyState";
import Button from "../../stories/Button";
import { useTourContext } from "../../context/tourProvider";

const MyPrograms = (props) => {
  const {
    courses: programs,
    loading: isLoading,
    error,
  } = useCourses({
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const { setState } = useTourContext();

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          run: true,
        }));
      }, 300);
    }
  }, [isLoading]);

  useEffect(() => {
    setFilteredPrograms(
      (programs ?? []).filter((program) => {
        const content = program.contents?.map((e) => e.name);
        const features = program.highlights?.map((e) => e.name);
        return `${program.title} ${program.course_type} ${program.name} ${
          program.description
        } ${content.join(" ")} ${features.join(" ")}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      })
    );
  }, [searchQuery, programs.length]);

  if (error) {
    return (
      <AppSkeleton
        pageLabel="My Programs"
        breadCrumbData={[
          { name: "Dashboard", to: "/" },
          { name: "My Programs", to: "/programs", current: true },
        ]}
      >
        <ErrorScreen />
      </AppSkeleton>
    );
  }
  return (
    <AppSkeleton
      pageLabel="My Programs"
      breadCrumbData={[
        { name: "Dashboard", to: "/" },
        { name: "My Programs", to: "/programs", current: true },
      ]}
      isLoading={isLoading}
    >
      <div className="flex flex-col justify-between p-6 md:items-center md:flex-row">
        <Text
          content="My Programs"
          type="title"
          size="text-xl"
          className="mb-1 leading-7 tracking-tighter line-clamp-1 text-theme-black-color"
        />
        <div className="flex w-full md:max-w-lg">
          <SearchBar
            className="w-full "
            backgroundColor="bg-white"
            placeholder={"Search by Program"}
            searchTerm={searchQuery}
            onSearch={(val) => setSearchQuery(val)}
          />
        </div>
      </div>
      <div className="flex flex-col w-full p-6 space-y-6 h-fit">
        {isLoading ? (
          <CircleNotch
            className="mx-auto animate-spin"
            size={20}
            weight="fill"
          />
        ) : !_.isEmpty(searchQuery) && _.isEmpty(filteredPrograms) ? (
          <div className="w-full p-3 mt-6 text-center lg:p-6">
            <EmptyState
              heading="No Result found!"
              subHeading={
                "Please modify your search to view result or click button below to resume learning"
              }
              cta={
                <Button className="!mt-4" onClick={() => setSearchQuery("")}>
                  <div className="flex items-center px-3 py-1">
                    <span>RESUME LEARNING</span>
                  </div>
                </Button>
              }
              image={
                <img
                  alt="emptyStatePlaceHolder"
                  className="py-9 max-h-80"
                  src={EmptyStatePlaceHolder}
                />
              }
            />
          </div>
        ) : (
          filteredPrograms.map((program) => {
            const content = program.contents?.map((e) => e.name);
            const features = program.highlights?.map((e) => e.name);
            return (
              <ProgramCard
                key={program.id}
                isStarted={program.has_started}
                courseName={program.title}
                course_icon={
                  program.last_viewed_content?.session_thumbnail ??
                  program.course_icon
                }
                courseTitle={program.name}
                courseType={program.course_type}
                courseId={program.id}
                lastViewedSessionContentId={
                  program?.last_viewed_content?.course_session_content_id
                }
                courseDescription={program.description}
                courseCompletionProgress={program.course_completion_percentage}
                courseContent={content}
                courseFeature={features}
              />
            );
          })
        )}
        <div className="w-full h-2">&nbsp;</div>
      </div>
    </AppSkeleton>
  );
};

export default MyPrograms;
