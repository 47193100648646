import PropTypes from "prop-types";
import classNames from "classnames";
import { Formik } from "formik";
import { Timer } from "@phosphor-icons/react";
import Dropdown from "../../stories/Dropdown";
import ProgressBar from "../../stories/ProgressBar";
import Text from "../../stories/Text";
import React from "react";

const QuizHeader = ({
  timeLeft,
  showReview = false,
  questionDropDownlist,
  currentQuizQuestion,
  questionList,
  totalQuizLength,
  quizProgress = 0,
  onDropdownSelect,
  className = "",
}) => {
  const ShowTimeLeft = () => {
    if (timeLeft < 3600)
      return new Date(timeLeft * 1000).toISOString().substring(14, 19);

    return new Date(timeLeft * 1000).toISOString().substring(11, 19);
  };
  return (
    <div
      className={classNames(
        "flex flex-col justify-between px-3 pt-4 pb-4 m-4 bg-white lg:flex-row lg:bg-transparent lg:m-0 lg:pb-6 lg:pt-0 lg:px-0",
        className
      )}
    >
      <div className="flex justify-between ">
        <Text type="body" size="lg:text-lg text-sm">
          {showReview
            ? "Review Answers & make Final Submission"
            : `QUESTION ${currentQuizQuestion + 1}/${totalQuizLength}`}
        </Text>
        <Text
          type="body"
          size="lg:text-lg text-sm"
          className="flex font-bold lg:hidden"
        >
          {quizProgress === 0
            ? "0"
            : quizProgress.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                minimumIntegerDigits: 2,
              })}
          %
        </Text>
      </div>
      <ProgressBar
        className="pb-6 lg:hidden"
        showProgress={true}
        percent={quizProgress}
        darkBg={true}
      />
      <div className="flex items-center justify-between">
        {showReview ? (
          `Total Question : ${totalQuizLength.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            minimumIntegerDigits: 2,
          })}`
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              name: currentQuizQuestion,
            }}
          >
            <Dropdown
              size="xl"
              displayCurrentOption={true}
              name="name"
              id="quiz_dropdown1"
              label="Select Question"
              type="text"
              placeholder="Select Question"
              displayKey="text"
              placeholderColor="bg-white"
              idKey="value"
              isClearable={false}
              isSearchable={false}
              getOptionLabel={(e) => {
                if (questionList?.[e["value"]]?.isSkipped === true) {
                  return (
                    <span className="flex justify-between h-5">
                      <span
                        className={classNames(
                          "flex",
                          e["value"] === currentQuizQuestion && "font-bold"
                        )}
                      >
                        {e["text"]}
                      </span>
                      <span
                        className={
                          "border-theme-red-300 bg-red-200 text-xs leading-none ml-3 text-theme-black-color px-2 py-1 rounded-full"
                        }
                      >
                        Skipped
                      </span>
                    </span>
                  );
                }
                if (questionList?.[e["value"]]?.isSubmitted === true) {
                  return (
                    <span className="flex justify-between h-5">
                      <span
                        className={classNames(
                          "flex",
                          e["value"] === currentQuizQuestion && "font-bold"
                        )}
                      >
                        {e["text"]}
                      </span>
                      <span
                        className={
                          "border-green-500 bg-green-200 text-xs leading-none ml-3 text-theme-black-color px-2 py-1 rounded-full"
                        }
                      >
                        Answered
                      </span>
                    </span>
                  );
                }
                return (
                  <span className="flex justify-between h-5">
                    <span
                      className={classNames(
                        "flex",
                        e["value"] === currentQuizQuestion && "font-bold"
                      )}
                    >
                      {e["text"]}
                    </span>
                  </span>
                );
              }}
              className="min-w-[220px]"
              options={questionDropDownlist ?? []}
              onChange={onDropdownSelect}
            />
          </Formik>
        )}
        <div className="display lg:hidden">
          <div
            className={classNames(
              "flex items-center px-3 py-2 border rounded-3xl w-fit h-fit bg-theme-orange/20"
            )}
          >
            <Timer weight="fill" className="mr-1 text-theme-orange" size={24} />
            <div className="text-sm font-bold text-theme-orange">
              <ShowTimeLeft />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuizHeader.propTypes = {
  className: PropTypes.string,
  currentQuizQuestion: PropTypes.number,
  onDropdownSelect: PropTypes.func,
  questionDropDownlist: PropTypes.array,
  questionList: PropTypes.array,
  quizProgress: PropTypes.number,
  showReview: PropTypes.bool,
  timeLeft: PropTypes.number,
  totalQuizLength: PropTypes.number,
};

export default QuizHeader;
