import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import { navigate } from "@reach/router";
import { CaretRight } from "@phosphor-icons/react";

const Breadcrumb = ({ type, pages, ...rest }) => {
  const navStyle = type === "bar" ? "bg-white border-b border-gray-200" : "";

  const listStyle = () => {
    switch (type) {
      case "contained":
        return "bg-white rounded-md shadow px-6";
      case "bar":
        return "max-w-screen-xl w-full mx-auto px-4 sm:px-6 lg:px-8";
      default:
        return "items-center";
    }
  };

  const Separater = () => {
    switch (type) {
      case "slash":
        return (
          <svg
            className="flex-shrink-0 w-5 h-5 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
        );
      case "chevron":
        return (
          <CaretRight
            className="flex-shrink-0 w-5 h-5 mr-4 text-blue-500"
            aria-hidden="true"
          />
        );
      default:
        return (
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
        );
    }
  };

  return (
    <nav
      className={classNames(navStyle, "flex")}
      aria-label="Breadcrumb"
      {...rest}
    >
      <ol className={classNames(listStyle(), " flex space-x-4")}>
        {pages?.map((page, idx) => (
          <li key={idx} className="flex">
            <div className="flex items-center">
              {idx !== 0 && <Separater />}
              <div
                onClick={() => (!page?.current ? navigate(page?.to) : "")}
                className="text-sm font-medium text-blue-500 cursor-pointer line-clamp-1 hover:text-blue-500"
              >
                {page?.name?.length > 40
                  ? page.name.substring(0, 40).trim() + "..."
                  : page.name}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

Breadcrumb.defaultProps = {
  type: "chevron",
};

Breadcrumb.propTypes = {
  type: PropTypes.oneOf(["contained", "bar", "chevron", "slash"]),
  pages: PropTypes.array,
};

export default Breadcrumb;
