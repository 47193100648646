import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
import Button from "../../stories/Button";
import { PublicProfileService } from "../../services/PublicProfileService";
import { CustomToast } from "../../stories/Toast";
import { useNavigate } from "@reach/router";

const FirmInviteRequestModal = ({ open, onClose, firmDetails = {} }) => {
  const navigate = useNavigate();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="flex items-center mb-3 text-2xl font-medium leading-6 tracking-tight text-theme-black-color"
              >
                Invitation
              </Dialog.Title>
              <div className="block mt-4 ml-0 text-base text-left">
                You have been invited to list as a corporate employee by{" "}
                <b>{firmDetails.firm_name}</b>. Please click{" "}
                <b className="tracking-wide text-theme-red">ACCEPT</b> button to
                join or click{" "}
                <span className="tracking-wide text-theme-red">REJECT</span>{" "}
                button to decline.
              </div>
              <div className="flex justify-end w-full gap-3 mt-10">
                <Button
                  buttonStyle="secondary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="204px"
                  onClick={async () => {
                    try {
                      const response =
                        await PublicProfileService.updateFirmInviteStatus({
                          firm_id: firmDetails.firm_id,
                          status: "rejected",
                        });
                      CustomToast.success(response.message);
                      setTimeout(() => navigate("/", 1500));
                      onClose();
                    } catch (e) {
                      console.log(e);
                      CustomToast.success(e.error_messages[0]);
                    }
                  }}
                >
                  REJECT
                </Button>
                <Button
                  buttonStyle="primary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="204px"
                  onClick={async () => {
                    try {
                      const response =
                        await PublicProfileService.updateFirmInviteStatus({
                          firm_id: firmDetails.firm_id,
                          status: "approved",
                        });
                      CustomToast.success(response.message);
                      setTimeout(() => navigate("/", 1500));
                      onClose();
                    } catch (e) {
                      console.log(e);
                      CustomToast.success(e.error_messages[0]);
                    }
                  }}
                >
                  ACCEPT
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

FirmInviteRequestModal.defaultProps = {
  open: false,
  className: "",
};

FirmInviteRequestModal.propTypes = {
  className: PropTypes.string,
  firmDetails: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.any,
  open: PropTypes.bool,
};

export default FirmInviteRequestModal;
