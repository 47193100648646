import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircle } from "@phosphor-icons/react";
import Button from "../../stories/Button";

const PaymentRequestSentModal = ({ open, onClose }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-6 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full ">
              <Dialog.Title
                as="h3"
                className="flex items-center mb-3 text-2xl font-medium leading-6 tracking-tight text-theme-black-color"
              >
                <div className="flex items-center justify-center mr-4 rounded-full min-w-10">
                  <CheckCircle
                    className="w-6 h-6 lg:w-10 lg:h-10 lg:m-2 !ml-0 lg:min-w-[40px] text-green-400"
                    weight="duotone"
                  />
                </div>
                Payment Requested
              </Dialog.Title>
              <div className="block mt-4 ml-0 text-base text-left lg:pl-6 md:ml-12">
                Payment request was sent successfully to your admin. Please
                contact your admin to make the payment.
              </div>
              <div className="flex justify-center w-full mt-10 md:justify-end">
                <Button
                  buttonStyle="primary"
                  type="submit"
                  size="md"
                  height="40px"
                  width="204px"
                  onClick={onClose}
                >
                  Okay
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

PaymentRequestSentModal.defaultProps = {
  open: false,
};

PaymentRequestSentModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default PaymentRequestSentModal;
