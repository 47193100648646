import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import networkfp_logo from "../../../assets/svgs/NFP_logo.svg";
import QPFPFullOrangeLogoTransparent from "../../../assets/images/QPFPFullOrangeLogoTransparent.png";
import { oathData } from "../../../Constants";
import { Form, Formik } from "formik";
import classNames from "classnames";
import Button from "../../../stories/Button";
import oathImage from "../../../assets/images/oath.png";
import PopupImage from "../../../stories/PopupImage";
import { PublicProfileService } from "../../../services/PublicProfileService";
import { CustomToast } from "../../../stories/Toast";
import { useProfile } from "../../../context/userProvider";
import moment from "moment";

const NetworkFPLogo = () => {
  return (
    <div className="flex items-center justify-start h-12">
      <img alt="network fp logo" src={networkfp_logo} className="h-9 lg:h-15" />
    </div>
  );
};

const QPFPLogo = () => {
  return (
    <div className="flex items-center justify-start h-15">
      <img
        alt="network fp orange logo"
        src={QPFPFullOrangeLogoTransparent}
        className="h-12 lg:h-21"
      />
    </div>
  );
};

const CheckSVG = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.46365 12.9949C8.04522 12.0804 13.7318 1.55668 20 0C13.1502 7.1058 8.85299 18.1726 8.85299 18.1726C8.85299 18.1726 7.20512 20 5.20194 20C4.52338 17.9687 2.74632 12.8249 0 10.2876C4.62034 6.32746 4.68496 9.9146 7.46365 12.9949Z"
      fill="#16A34A"
    />
  </svg>
);

CheckSVG.propTypes = {
  size: PropTypes.number,
};

const EthicsOath = ({ onSubmit }) => {
  const [initialValues] = useState({ oath: [], terms: false });
  const { userDetails } = useProfile();
  const submitRef = useRef();
  return (
    <div className="w-full max-w-5xl text-center flex flex-col shadow-card-shadow bg-white -mt-20 mx-auto rounded p-10 z-10">
      <div className="flex w-full justify-between items-center">
        <QPFPLogo />
        <NetworkFPLogo />
      </div>
      <div className="mt-5 lg:mt-8 font-bold text-2xl lg:text-3xl">
        Ethics Oath
      </div>
      <div className="mt-3 lg:mt-4 text-sm lg:text-base tracking-wide text-theme-black-300">
        I,{" "}
        <span className="font-bold text-theme-orange">
          {userDetails.display_name}
        </span>
        , as a Qualified Personal Finance Professional (QPFP), pledge to uphold
        the highest standards of ethics and integrity in all my professional
        endeavours. I commit to the following Code of conduct as a QPFP
        <sup>&reg;</sup> Certificant
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          const isValid =
            (values.oath ?? []).filter((o) => o === true).length === 6;
          if (!isValid) {
            CustomToast.error("You must take the oath to proceed.");
            return;
          }
          if (!values.terms) {
            CustomToast.error("You must take the oath to proceed.");
            return;
          }
          try {
            const result = await PublicProfileService.updatePublicProfile({
              oath_taken: true,
            });
            if (result.message) {
              CustomToast.success(result.message);
              window.scroll(0, 0);
              onSubmit();
            }
          } catch (e) {
            CustomToast.error(e.error_messages[0]);
          }
        }}
      >
        {({ values, ...formProps }) => (
          <Form className="w-full">
            <div className="grid mt-4 lg:mt-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {oathData.map((oath, index) => {
                return (
                  <div
                    key={oath.title}
                    className="bg-[#FFDFAE33] p-4 cursor-pointer text-left border border-[#EEE2CF] flex gap-3 items-start"
                    onClick={() => {
                      formProps.setFieldValue(
                        `oath.${index}`,
                        !values.oath[index]
                      );
                    }}
                  >
                    <div
                      className={classNames(
                        "flex w-4 h-4 min-w-[16px] lg:w-5 lg:h-5 lg:min-w-[20px] transition-all",
                        !values.oath[index] &&
                          "bg-white border border-[#EEE2CF]"
                      )}
                    >
                      <div
                        className={classNames(
                          "w-full max-w-0 transition-all overflow-clip",
                          !!values.oath[index] && "max-w-[16px] lg:max-w-[20px]"
                        )}
                      >
                        <span className="lg:hidden">
                          <CheckSVG size={16} />
                        </span>
                        <span className="hidden lg:flex">
                          <CheckSVG size={20} />
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-1">
                      <div className="text-base leading-5 font-medium text-theme-black-color">
                        {oath.title}
                      </div>
                      <div className="text-xs font-light text-theme-black-300">
                        {oath.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              className="hidden"
              ref={submitRef}
              disabled={formProps.isSubmitting}
              type="submit"
            >
              submit
            </button>
            <div className="mt-4 lg:mt-8 grid grid-cols-3 gap-4">
              <div className="col-span-full lg:col-span-2 flex flex-col items-start gap-4 lg:gap-8">
                <div className="text-[#2D64BC] text-3xl lg:text-5xl font-caligraphy">
                  {userDetails.display_name ?? ""}
                </div>
                <div className="text-theme-black-300 text-base">
                  Date of Oath:{" "}
                  <span className="font-bold ml-2">
                    {moment().format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="lg:hidden flex flex-col items-center gap-3.5 mt-1">
                  <PopupImage
                    imgSrc={oathImage}
                    title={"Ethics at Heart - The Oath"}
                    allowDownload={true}
                  >
                    <img
                      src={oathImage}
                      className="w-full max-w-186 h-auto object-cover cursor-pointer"
                      alt="QPFP Oath"
                    />
                  </PopupImage>
                  <div className="font-light text-xs text-theme-black-color">
                    We recommend you to display QPFP Ethics@Heart frame at your
                    workplace prominently
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <input
                    name="terms"
                    id="green-checkbox"
                    type="checkbox"
                    checked={values.terms}
                    onChange={(e) => {
                      formProps.setFieldValue("terms", e.target.checked);
                    }}
                    className="w-4 h-4 mt-1 text-green-600 bg-gray-100 border-gray-300 rounded-sm ring-0 focus:ring-0"
                  />
                  <div className="text-sm leading-5 text-left text-theme-black-300">
                    By taking this oath, I affirm my dedication to these
                    principles and understand that any breach of this commitment
                    may result in disciplinary actions, including the potential
                    revocation of my QPFP certification.
                  </div>
                </div>
                <Button
                  buttonStyle="primary"
                  height="40px"
                  width="100%"
                  className="lg:max-w-[243px] mt-5 lg:mt-8"
                  type="submit"
                >
                  Yes, I AGREE
                </Button>
              </div>
              <div className="hidden lg:flex flex-col items-center gap-3.5">
                <PopupImage
                  imgSrc={oathImage}
                  title={"Ethics at Heart - The Oath"}
                  allowDownload={true}
                >
                  <img
                    src={oathImage}
                    className="w-full max-w-186 h-auto object-cover cursor-pointer"
                    alt="QPFP Oath"
                  />
                </PopupImage>
                <div className="font-light text-xs text-theme-black-color">
                  We recommend you to display QPFP Ethics@Heart frame at your
                  workplace prominently
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EthicsOath.propTypes = {
  onSubmit: PropTypes.func,
};

export default EthicsOath;
