import React from "react";
import Logo from "./nfp-logo.png";
const Loader = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="">
        <img src={Logo} alt="NFP Logo" className="mb-4 w-36 lg:mb-6 lg:w-44" />
        <div className="">
          <div className="w-36 mx-auto h-0.5 bg-[#d6cec2] rounded-lg overflow-hidden relative lg:w-40">
            <div className="after:content-[''] after:w-20 after:h-0.5 after:absolute after:bg-theme-red-300 after:translate-x-full after:animate-loop"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
