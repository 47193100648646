import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import { X } from "@phosphor-icons/react";

const LoginLockModal = ({ open, onClose }) => {
  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex justify-between text-4xl font-bold leading-none text-theme-black-color"
                >
                  Welcome back
                </Dialog.Title>
                <div className="flex flex-col w-full">
                  <div className="flex mt-6 mb-6 text-lg font-light leading-none tracking-tight text-theme-black-300">
                    It seems you have not completed your signup yet. Please
                    click on the link you have recieved in the mail.
                  </div>
                  <Button
                    buttonStyle="primary"
                    height="40px"
                    width="100%"
                    className="mt-3"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

LoginLockModal.defaultProps = {
  open: false,
};

LoginLockModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default LoginLockModal;
