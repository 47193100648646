/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import Label from "./Label";
import ShowFormError from "./ShowFormError";

const AuthTextInput = ({
  showLeading = false,
  showErrorIcon = false,
  disable = false,
  height = "h-10",
  leadingComponent = null,
  ...props
}) => {
  const [field, meta] = useField(props);

  const invalidChars = ["-", "+", "e", "E"];
  const checkChars = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="flex">
        {showLeading && leadingComponent}
        <div
          className={
            "mt-1 relative group font-medium text-theme-gray-500 w-full"
          }
        >
          <Label
            labelFor={props.name}
            className={classNames(
              "absolute flex items-center pl-5 capitalize transition-all ease-out origin-top-left",
              "group-focus-within:font-light group-focus-within:text-theme-black-color group-focus-within:translate-x-0 group-focus-within:translate-y-[9px] md:group-focus-within:translate-y-3 group-focus-within:text-sm group-focus-within:leading-3",
              field.value !== ""
                ? "font-light text-theme-black-color translate-x-0 translate-y-[9px] md:translate-y-3 text-sm leading-3"
                : "translate-y-4 text-sm md:text-base lg:text-lg "
            )}
          >
            {props.label ?? "Text Input"}
          </Label>
          <div className="">
            <input
              className={classNames(
                "inline-flex items-center justify-between",
                "text-left text-theme-black-color font-medium leading-none",
                "px-5 pb-0 md:pb-2 rounded-sm w-full border border-theme-gray focus:border-[1px] focus:ring-0 focus:outline-none focus:border-theme-black-color",
                meta.touched &&
                  meta.error &&
                  "border border-theme-red-200 focus:ring-0 focus:border-theme-red-200",
                "h-[50px] lg:h-16 md:h-14 pt-3 md:pt-6"
              )}
              onKeyDown={(e) => (props.type === "number" ? checkChars(e) : "")}
              name={props.name}
              disabled={!!disable}
              {...field}
              {...props}
            />
          </div>
        </div>
      </div>
      <ShowFormError name={props.name} />
    </div>
  );
};

AuthTextInput.defaultProps = {};

AuthTextInput.propTypes = {
  disable: PropTypes.bool,
  height: PropTypes.string,
  leadingComponent: PropTypes.element,
  name: PropTypes.string,
  showErrorIcon: PropTypes.bool,
  showLeading: PropTypes.bool,
  type: PropTypes.string.isRequired,
};
export default AuthTextInput;
