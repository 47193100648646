/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import { Star, X } from "@phosphor-icons/react";
import Button from "./Button";
import TextArea from "./TextArea";
import ShowFormError from "./ShowFormError";
import { Validations } from "../constants/validations";

const ReviewModal = ({
  dismissButtonOnClick,
  onPostReview,
  open,
  authorName,
}) => {
  const [hover, setHover] = useState(0);

  const handleSubmit = async (values) => {
    await onPostReview({ reviews: values.review, rating_value: values.rating });
    dismissButtonOnClick(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={dismissButtonOnClick}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the ReviewModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block p-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
              <Dialog.Title
                as="h3"
                className="mb-3 text-base font-semibold leading-6 text-theme-black-color"
              >
                Rate this Session - by {authorName}
              </Dialog.Title>
              <div className="absolute top-0 right-0 hidden pt-6 pr-6 sm:block">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  onClick={dismissButtonOnClick}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" />
                </button>
              </div>
              <Formik
                initialValues={{ rating: 0, review: "" }}
                validationSchema={() =>
                  Validations.popup.reviewPopupValidationSchema
                }
                onSubmit={handleSubmit}
              >
                {({ values, ...props }) => {
                  return (
                    <Form>
                      <div className="flex mt-2 mb-1 ">
                        {[...Array(5)].map((star, index) => {
                          index += 1;
                          return (
                            <Star
                              key={index}
                              size={32}
                              onMouseEnter={() => setHover(index)}
                              onMouseLeave={() => setHover(values.rating)}
                              color={
                                index <= (hover || values.rating)
                                  ? "#ffc107"
                                  : "#e5e7eb"
                              }
                              className="cursor-pointer"
                              weight="fill"
                              onClick={() => {
                                props.setFieldValue("rating", index, true);
                                props.setFieldTouched("rating", true);
                              }}
                            />
                          );
                        })}
                      </div>
                      <ShowFormError
                        name={"rating"}
                        className="mt-1 leading-none"
                      />
                      <TextArea
                        name="review"
                        label="Write a review"
                        placeholder="Share your feedback here"
                        rows={4}
                        id="review"
                        type="text"
                        maxLength={1024}
                        labelClassname={
                          "text-base font-semibold text-theme-black-color mb-2"
                        }
                      />
                      <div className="justify-end mt-4 sm:flex">
                        <Button type="submit" className="w-full mr-2">
                          <span>SUBMIT</span>
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ReviewModal.defaultProps = {
  authorName: "Arjun Padnekar",
};

ReviewModal.propTypes = {
  dismissButtonOnClick: PropTypes.bool.isRequired,
  onPostReview: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  authorName: PropTypes.string,
};

export default ReviewModal;
