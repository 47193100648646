/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../stories/Button";
import { CircleNotch } from "@phosphor-icons/react";
import { useCourseCurriculum } from "../../../services/CourseDetails";
import _ from "lodash";
import { useNavigate } from "@reach/router";

const ProgramAccordian = ({ levelHeading, levelDetails }) => {
  const [countString, setCountString] = useState("");

  useEffect(() => {
    let sessionCount = 0;
    let protoolCount = 0;
    _.map(levelDetails.sessions, (session, index) => {
      sessionCount++;
      protoolCount += _.size(
        _.filter(
          session.session_content,
          (content) => content.kind === "protool"
        )
      );
    });
    setCountString(`${sessionCount} Sessions & ${protoolCount} ProTools`);
  }, []);

  return (
    <div className="flex flex-col w-full space-y-3 bg-[#FFDFAE20] border border-[#EED6BB65] rounded-lg p-2">
      <p className="font-semibold text-xs">{levelHeading}</p>
      <p className="text-xs">{countString}</p>
    </div>
  );
};

ProgramAccordian.propTypes = {
  levelHeading: PropTypes.string,
  levelDetails: PropTypes.object,
};

/**
 * Component for QPFP Program and ProTools Dashboard Element
 * @return {React.ReactElement} QPFP Program and ProTools Dashboard Element
 */
function ProgramAndProTools({ courseId }) {
  const { curriculum, loading: isLoading } = useCourseCurriculum(courseId, {
    revalidateOption: {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    },
  });
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-4 items-start">
      <p className="font-normal text-sm text-gray-500">
        Access the latest &amp; updated QPFP courseware and ProTools here
      </p>

      {isLoading && (
        <CircleNotch
          className="mx-auto text-theme-black-color animate-spin"
          size={56}
          weight="bold"
        />
      )}

      {curriculum?.modules?.map((level, index) => (
        <ProgramAccordian
          key={`level-${index}`}
          levelHeading={level?.name}
          levelDesc={level?.description}
          levelDetails={level}
        />
      ))}

      <Button
        onClick={() => navigate("/qpfp-certificant/courseware")}
        className="!rounded-md"
      >
        Watch Sessions and Download ProTools
      </Button>
    </div>
  );
}

ProgramAndProTools.propTypes = {
  courseId: PropTypes.number,
};

export default ProgramAndProTools;
