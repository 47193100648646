/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import PublicProfileCard from "../../stories/PublicProfileCard";
// import TextInput from "../../stories/TextInput";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../services/PublicProfileService";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import { Validations } from "../../constants/validations";
import { Minus } from "@phosphor-icons/react";
import Certificate from "../../assets/svgs/Certificate.svg";
import GraduationCap from "../../assets/svgs/GraduationCap.svg";
import ChalkboardTeacher from "../../assets/svgs/ChalkboardTeacher.svg";
import Dropdown from "../../stories/Dropdown";
import _ from "lodash";
import Tooltip from "../../stories/Tooltip";

const EducationInfoCard = ({
  className,
  publicProfileData = {},
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const submitRef = React.useRef();
  const [isEmpty, setIsEmpty] = useState(false);
  const cancelRef = React.useRef();

  const [initialValues, setInitialValues] = useState({
    formal: [{ id: -1, field_of_study_id: "", graduation_level: "formal" }],
    industry: [{ id: -1, field_of_study_id: "", graduation_level: "industry" }],
    professional: [
      { id: -1, field_of_study_id: "", graduation_level: "professional" },
    ],
  });

  useEffect(() => {
    setIsEmpty((publicProfileData.user_educational_details ?? []).length === 0);
    const tempGraduations = (publicProfileData.user_educational_details ?? [])
      ?.filter((e) => e.graduation_level === "formal")
      .map((e) => ({ id: e.id, field_of_study_id: e.field_of_study_id }));
    const tempIndustry = (publicProfileData.user_educational_details ?? [])
      ?.filter((e) => e.graduation_level === "industry")
      .map((e) => ({ id: e.id, field_of_study_id: e.field_of_study_id }));
    const tempProfessional = (publicProfileData.user_educational_details ?? [])
      ?.filter((e) => e.graduation_level === "professional")
      .map((e) => ({ id: e.id, field_of_study_id: e.field_of_study_id }));

    setInitialValues((prev) => ({
      formal:
        tempGraduations.length === 0
          ? [{ id: -1, field_of_study_id: "", graduation_level: "formal" }]
          : tempGraduations,
      industry:
        tempIndustry.length === 0
          ? [
              {
                id: -1,
                field_of_study_id: "",
                graduation_level: "industry",
              },
            ]
          : tempIndustry,
      professional:
        tempProfessional.length === 0
          ? [
              {
                id: -1,
                field_of_study_id: "",
                graduation_level: "professional",
              },
            ]
          : tempProfessional,
    }));
  }, [publicProfileData]);

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Education Info"
        subHeading="Update your Education Info here."
        id={"education-details-card"}
        isEditing={isEditing}
        isLoading={isLoading}
        setIsEditing={setIsEditing}
        promemberStatus={publicProfileData.membership}
        promemberValidationMessage={
          publicProfileData.membership === "provisional_member"
            ? "Submit your eligibilty documents to become a ProMember and update your Education Information"
            : "Upgrade to ProMembership to update and share your Education Info on your Public Profile"
        }
        isProMemberCard={true}
        isEmpty={!isEditing && isEmpty}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              heading="Add Education Information"
              className={"!mt-0 !mb-4"}
              message="To see education information here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#education-details-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              {isEditing ? (
                <div className="flex">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={() =>
                      Validations.publicProfile.educationInfoValidationSchema
                    }
                    onSubmit={async (values) => {
                      try {
                        const postArray = [];
                        const educationIds = [];
                        [
                          ...values.formal,
                          ...values.industry,
                          ...values.professional,
                        ].forEach((e) => {
                          if (e.field_of_study_id === "" && e.id === -1) return;
                          if (e.id === -1) {
                            postArray.push({
                              field_of_study_id: e.field_of_study_id,
                              graduation_level: e.graduation_level,
                            });
                            return;
                          }
                          educationIds.push(e.id);
                          postArray.push(e);
                        });

                        publicProfileData.user_educational_details?.forEach(
                          (e) => {
                            if (!educationIds.includes(e.id)) {
                              postArray.push({ id: e.id, _destroy: true });
                            }
                          }
                        );
                        console.log({
                          user_educational_details_attributes: postArray,
                        });
                        const result =
                          await PublicProfileService.updatePublicProfile({
                            user_educational_details_attributes: postArray,
                          });
                        if (result.message) {
                          CustomToast.success(result.message);
                          onUpdate();
                          setIsEditing(false);
                        }
                      } catch (e) {
                        console.log(e);
                        CustomToast.error(e.error_messages[0]);
                      }
                    }}
                  >
                    {({ values, ...props }) => {
                      return (
                        <Form
                          id="practice_info_form"
                          className="grid w-full grid-cols-1 gap-4"
                        >
                          <FieldArray name="formal">
                            {({ remove, push }) => (
                              <div className="flex flex-col">
                                <div className="mb-2 w-full flex justify-between text-base font-semibold leading-5 tracking-0.3px text-left text-theme-black-color">
                                  <div className="flex items-center">
                                    Formal Education{" "}
                                    <Tooltip
                                      infoContent={
                                        "Mention graduation  (10+2+3) & above completed in any stream from a recognized University or Institute"
                                      }
                                    />
                                  </div>
                                  <div
                                    className="flex px-2 py-1 text-sm border rounded cursor-pointer hover:bg-theme-gray-200 border-theme-gray-400"
                                    onClick={() =>
                                      push({
                                        id: -1,
                                        field_of_study_id: "",
                                        graduation_level: "formal",
                                      })
                                    }
                                  >
                                    + Add
                                  </div>
                                </div>
                                <div className="grid w-full grid-cols-1 gap-y-6 gap-x-5">
                                  {values.formal.map((e, index) => (
                                    <div
                                      key={`formal-${index}`}
                                      className="flex items-center col-span-full"
                                    >
                                      <div className="flex w-full">
                                        {/* <TextInput
                                          name={`formal.${index}.field_of_study_id`}
                                          id={`formal.${index}.field_of_study_id`}
                                          label="Graduation Completed"
                                          placeholder="BBA in Finance"
                                          type="text"
                                        /> */}
                                        <Dropdown
                                          label="Graduation Completed"
                                          placeholder="Select Option"
                                          type="text"
                                          name={`formal.${index}.field_of_study_id`}
                                          renderValue={(values) =>
                                            _.head(
                                              _.filter(
                                                options[
                                                  "pro_member_graduation_stream_id"
                                                ],
                                                (e) =>
                                                  e.id ===
                                                  values?.formal?.[index]
                                                    ?.field_of_study_id
                                              ) ?? []
                                            )?.value ?? ""
                                          }
                                          displayCurrentOption={true}
                                          options={options[
                                            "pro_member_graduation_stream_id"
                                          ].filter(
                                            (opt) =>
                                              values?.formal.findIndex(
                                                (e) =>
                                                  e.field_of_study_id === opt.id
                                              ) === -1 ||
                                              values?.formal?.[index]
                                                ?.field_of_study_id === opt.id
                                          )}
                                          displayKey="value"
                                          idKey="id"
                                          className="w-full"
                                          isRequired={false}
                                          isViewOnly={!isEditing}
                                          {...props}
                                          key={`formal.${index}.field_of_study_id`}
                                          id={`formal.${index}.field_of_study_id`}
                                          allowCreateNewOption={false}
                                        />
                                      </div>
                                      <div
                                        className="flex items-center p-1 mt-4 ml-2 rounded-full cursor-pointer bg-theme-red/10 min-w-fit"
                                        onClick={() => remove(index)}
                                      >
                                        <Minus
                                          size={16}
                                          weight="bold"
                                          className="text-theme-red"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </FieldArray>
                          <FieldArray name="industry">
                            {({ insert, remove, push }) => (
                              <div className="flex flex-col">
                                <div className="mb-2 w-full flex justify-between text-base font-semibold leading-5 tracking-0.3px text-left text-theme-black-color">
                                  <div className="flex items-center">
                                    Industry Education{" "}
                                    <Tooltip
                                      infoContent={
                                        "Mention Industry licensing education and exams as directed by AMFI / SEBI / IRDA / PFRDA / RBI (E.g. NISM VA / VB / XA / XB)"
                                      }
                                    />
                                  </div>
                                  <div
                                    className="flex px-2 py-1 text-sm border rounded cursor-pointer hover:bg-theme-gray-200 border-theme-gray-400"
                                    onClick={() =>
                                      push({
                                        id: -1,
                                        field_of_study_id: "",
                                        graduation_level: "industry",
                                      })
                                    }
                                  >
                                    + Add
                                  </div>
                                </div>
                                <div className="grid w-full grid-cols-1 gap-y-6 gap-x-5">
                                  {values.industry.map((e, index) => (
                                    <div
                                      key={`industry-${index}`}
                                      className="flex items-center col-span-full"
                                    >
                                      <div className="flex w-full">
                                        {/* <TextInput
                                          name={`industry.${index}.field_of_study_id`}
                                          id={`industry.${index}.field_of_study_id`}
                                          label="Industry Certification"
                                          placeholder="ex. NISM VA or VB"
                                          type="text"
                                        /> */}
                                        <Dropdown
                                          label="Industry Certification"
                                          placeholder="Select Option"
                                          type="text"
                                          name={`industry.${index}.field_of_study_id`}
                                          renderValue={(values) =>
                                            _.head(
                                              _.filter(
                                                options["pro_member_exam_id"],
                                                (e) =>
                                                  e.id ===
                                                  values?.industry?.[index]
                                                    ?.field_of_study_id
                                              ) ?? []
                                            )?.value ?? ""
                                          }
                                          displayCurrentOption={true}
                                          options={options[
                                            "pro_member_exam_id"
                                          ].filter(
                                            (opt) =>
                                              values?.industry.findIndex(
                                                (e) =>
                                                  e.field_of_study_id === opt.id
                                              ) === -1 ||
                                              values?.industry?.[index]
                                                ?.field_of_study_id === opt.id
                                          )}
                                          displayKey="value"
                                          idKey="id"
                                          className="w-full"
                                          isRequired={false}
                                          isViewOnly={!isEditing}
                                          {...props}
                                          key={`industry.${index}.field_of_study_id`}
                                          id={`industry.${index}.field_of_study_id`}
                                          allowCreateNewOption={false}
                                        />
                                      </div>
                                      <div
                                        className="flex items-center p-1 mt-4 ml-2 rounded-full cursor-pointer bg-theme-red/10 min-w-fit"
                                        onClick={() => remove(index)}
                                      >
                                        <Minus
                                          size={16}
                                          weight="bold"
                                          className="text-theme-red"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </FieldArray>
                          <FieldArray name="professional">
                            {({ insert, remove, push }) => (
                              <div className="flex flex-col">
                                <div className="mb-2 w-full flex justify-between text-base font-semibold leading-5 tracking-0.3px text-left text-theme-black-color">
                                  <div className="flex items-center">
                                    Professional Education
                                    <Tooltip
                                      infoContent={
                                        "Mention professional education (minimum 100 hours) completed in Personal Finance Field. Professional Education can be any of QPFP / CFP / CWM / CFA / CA / MBA or PG in Industry Related Areas"
                                      }
                                    />
                                  </div>
                                  <div
                                    className="flex px-2 py-1 text-sm border rounded cursor-pointer hover:bg-theme-gray-200 border-theme-gray-400"
                                    onClick={() =>
                                      push({
                                        id: -1,
                                        field_of_study_id: "",
                                        graduation_level: "professional",
                                      })
                                    }
                                  >
                                    + Add
                                  </div>
                                </div>
                                <div className="grid w-full grid-cols-1 gap-y-6 gap-x-5">
                                  {values.professional.map((e, index) => (
                                    <div
                                      key={`professional-${index}`}
                                      className="flex items-center col-span-full"
                                    >
                                      <div className="flex w-full">
                                        {/* <TextInput
                                          name={`professional.${index}.field_of_study_id`}
                                          id={`professional.${index}.field_of_study_id`}
                                          label="Professional Certification"
                                          placeholder="ex. Certified Financial Planner (CFP)"
                                          type="text"
                                        /> */}
                                        <Dropdown
                                          label="Professional Certification"
                                          placeholder="Select Option"
                                          type="text"
                                          name={`professional.${index}.field_of_study_id`}
                                          renderValue={(values) =>
                                            _.head(
                                              _.filter(
                                                options[
                                                  "pro_member_qualification_id"
                                                ],
                                                (e) =>
                                                  e.id ===
                                                  values?.formal?.[index]
                                                    ?.field_of_study_id
                                              ) ?? []
                                            )?.value ?? ""
                                          }
                                          displayCurrentOption={true}
                                          options={options[
                                            "pro_member_qualification_id"
                                          ].filter(
                                            (opt) =>
                                              values?.professional.findIndex(
                                                (e) =>
                                                  e.field_of_study_id === opt.id
                                              ) === -1 ||
                                              values?.professional?.[index]
                                                ?.field_of_study_id === opt.id
                                          )}
                                          displayKey="value"
                                          idKey="id"
                                          className="w-full"
                                          isRequired={false}
                                          isViewOnly={!isEditing}
                                          {...props}
                                          key={`professional.${index}.field_of_study_id`}
                                          id={`professional.${index}.field_of_study_id`}
                                          allowCreateNewOption={false}
                                        />
                                      </div>
                                      <div
                                        className="flex items-center p-1 mt-4 ml-2 rounded-full cursor-pointer bg-theme-red/10 min-w-fit"
                                        onClick={() => remove(index)}
                                      >
                                        <Minus
                                          size={16}
                                          weight="bold"
                                          className="text-theme-red"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </FieldArray>
                          <button
                            className="hidden"
                            ref={submitRef}
                            type="submit"
                            disabled={props.isSubmitting}
                            // onClick={() => {
                            // }}
                          >
                            submit
                          </button>
                          <button
                            className="hidden"
                            ref={cancelRef}
                            type="button"
                            onClick={() => {
                              props.resetForm();
                            }}
                          >
                            cancel
                          </button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              ) : (
                <div className="grid w-full grid-cols-1 gap-2">
                  {publicProfileData.user_educational_details
                    ?.filter(
                      (education) => education.graduation_level === "formal"
                    )
                    ?.map((education, index) => (
                      <DisplayEducation
                        key={education.id}
                        education={education}
                      />
                    ))}
                  {publicProfileData.user_educational_details
                    ?.filter(
                      (education) => education.graduation_level === "industry"
                    )
                    ?.map((education, index) => (
                      <DisplayEducation
                        key={education.id}
                        education={education}
                      />
                    ))}
                  {publicProfileData.user_educational_details
                    ?.filter(
                      (education) =>
                        education.graduation_level === "professional"
                    )
                    ?.map((education, index) => (
                      <DisplayEducation
                        key={education.id}
                        education={education}
                      />
                    ))}
                </div>
              )}
            </div>
          )
        }
      />
    </div>
  );
};

const DisplayEducation = ({ education }) => (
  <div className="flex p-1 border rounded-lg border-zinc-300">
    <div className="flex p-2.5">
      <img
        src={
          education.graduation_level === "formal"
            ? GraduationCap
            : education.graduation_level === "industry"
            ? Certificate
            : ChalkboardTeacher
        }
        alt={"Education_Icon"}
        className="w-6 min-w-[24px] h-6 min-h-[24px]"
      />
    </div>
    <div className="flex flex-col mt-2.5">
      <div className="flex">{education?.field_of_study?.value}</div>
    </div>
  </div>
);

EducationInfoCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default EducationInfoCard;
