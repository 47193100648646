/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import React, { useImperativeHandle, useState } from "react";
import { CircleNotch } from "@phosphor-icons/react";

export const Button = React.forwardRef(
  (
    {
      size,
      buttonStyle,
      type,
      iconAlso,
      iconPlacement,
      circular,
      children,
      className,
      loading,
      icon,
      width,
      height,
      disabled = false,
      ...rest
    },
    buttonRef
  ) => {
    const [shakeClass, setShakeClass] = useState(false);
    const style = () => {
      switch (buttonStyle) {
        case "secondary":
          return "bg-theme-red/10 text-theme-red hover:bg-theme-red/20 shadow-transparent";
        case "white":
          return "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300";
        case "gray":
          return "bg-gray-200 text-gray-700 hover:bg-gray-300 border border-gray-200";
        case "link":
          return "bg-white text-gray-700  border border-gray-300";
        case "danger":
          return "bg-red-600 hover:bg-red-700 text-white border border-red-300";
        case "success":
          return "bg-green-600 hover:bg-green-700 text-white border border-green-300";
        case "disabled":
          return "bg-gray-500 text-gray-200 cursor-not-allowed hover:bg-gray-500 opacity-50";
        case "outline-primary":
          return "bg-white text-theme-red-300 border border-theme-red-300 hover:bg-theme-red-300 hover:text-white";
        case "transparent-textonly-primary":
          return "bg-transparent text-theme-red-300 hover:text-theme-red shadow-transparent";
        case "transparent-textonly-secondary":
          return "bg-transparent text-theme-black-color font-semibold hover:text-theme-red shadow-transparent";
        case "transparent-primary":
          return "bg-theme-gray-50 hover:bg-theme-gray-100 text-theme-black-color font-semibold  shadow-transparent";
        case "orange":
          return "bg-theme-orange text-white hover:bg-theme-orange-400";
        default:
          return "bg-theme-red text-white hover:bg-red-700";
      }
    };

    const sizeStyle = () => {
      switch (size) {
        case "xs":
          return "px-2.5 py-1.5 text-xs";
        case "sm":
          return "px-3 py-2 text-sm ";
        case "md":
          return "px-4 py-2 text-sm";
        case "lg":
          return "px-6 py-2 text-base";
        case "xl":
          return "px-6 py-3 text-base";
        case "no-size":
          return "text-base";
        default:
          return "";
      }
    };
    const circularStyle = size === "xs" ? "rounded-sm" : "rounded-sm";

    const iconPlacementStyle =
      iconPlacement === "leading" ? "flex-row" : "flex-row-reverse";

    const iconHeight = size === "xs" || size === "sm" ? "h-4 w-4" : "h-5 w-5";

    const onlyIconStyle = () => {
      switch (size) {
        case "xs":
          return "h-5 w-5 m-1";
        case "sm":
          return "h-5 w-5 m-1.5";
        case "md":
          return "h-5 w-5 m-2";
        case "lg":
          return "h-6 w-6 m-2";
        case "xl":
          return "h-6 w-6 m-3";
        default:
          return "";
      }
    };

    useImperativeHandle(buttonRef, () => ({
      shake() {
        if (shakeClass) return;
        setShakeClass(true);
        setTimeout(() => {
          setShakeClass(false);
        }, 1000);
      },
    }));

    return (
      <button
        type={type}
        ref={buttonRef}
        className={classNames(
          "flex cursor-pointer items-center font-medium shadow-sm focus:outline-none focus:ring-0  justify-center",
          style(),
          disabled && "opacity-60 cursor-default",
          circular ? "rounded-full" : circularStyle,
          loading && "pointer-events-none opacity-60",
          shakeClass && "animate-shake",
          buttonStyle === "danger"
            ? "focus:ring-red-500"
            : "focus:ring-gray-500",
          className
        )}
        {...rest}
        style={{
          width: width !== "" ? width : "auto",
          height: height !== "" ? height : "auto",
        }}
        disabled={disabled}
      >
        {loading ? (
          <CircleNotch
            className={classNames(
              buttonStyle === "white" || buttonStyle === "secondary"
                ? "text-gray-900"
                : "text-white",
              sizeStyle(),
              "mx-auto animate-spin"
            )}
            size={20}
            weight="fill"
          />
        ) : (
          <>
            <div
              className={classNames(
                "items-center text-center flex",
                iconPlacementStyle,
                children && sizeStyle()
              )}
            >
              {icon?.type && (
                <icon.type
                  className={classNames(
                    children
                      ? iconPlacement === "leading"
                        ? "-ml-0.5 mr-2 "
                        : "ml-2 -mr-0.5"
                      : onlyIconStyle(),
                    iconHeight
                  )}
                  aria-hidden="true"
                />
              )}
              {children}
            </div>
          </>
        )}
      </button>
    );
  }
);

Button.defaultProps = {
  size: "md",
  buttonStyle: "primary",
  circular: false,
  type: "button",
  loading: false,
  width: "",
  height: "",
};

Button.propTypes = {
  buttonStyle: PropTypes.oneOf([
    "primary",
    "secondary",
    "white",
    "danger",
    "disabled",
    "success",
    "outline-primary",
    "transparent-textonly-primary",
    "gray",
    "transparent-textonly-secondary",
    "transparent-primary",
  ]).isRequired,
  children: PropTypes.any,
  circular: PropTypes.bool,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  icon: PropTypes.node,
  iconAlso: PropTypes.bool,
  iconPlacement: PropTypes.oneOf(["leading", "trailing"]),
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "no-size"]),
  type: PropTypes.string,
  width: PropTypes.string,
};

export default Button;
