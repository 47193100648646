/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "./Label";
import { useField } from "formik";
import _ from "lodash";
import ShowFormError from "./ShowFormError";

const FileInput = ({
  className,
  id,
  name,
  label,
  placeholder,
  height,
  suggestion,
  onFileChange,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });

  const openFileHandler = (e) => {
    const fileInput = document.getElementById(id + "-fileInput");
    fileInput.click();
  };

  return (
    <div className={classNames("flex flex-col items-start ")}>
      <Label
        labelFor={name}
        className={`capitalize font-semibold text-theme-black-300  text-xs leading-none`}
      >
        {label}
      </Label>
      <div className="flex flex-col w-full">
        <div
          className={classNames(
            "w-full flex rounded-[4px] mt-1 border relative overflow-auto no-scrollbar",
            meta.touched && meta.error
              ? "border-theme-red-300 focus:ring-theme-red-300 focus:border-theme-red-300"
              : "border-gray-200 focus:ring-gray-900 focus:border-theme-black-color",
            className,
            "rounded-[4px] shadow-sm",
            height
          )}
          onClick={openFileHandler}
        >
          <div
            className={classNames(
              "flex items-center grow pr-1 text-sm tracking-tight ml-2.5",
              field.value === null ? "text-[#a3a3a3]" : "text-theme-black-color"
            )}
          >
            {field.value === null || field.value === undefined
              ? placeholder ?? label ?? ""
              : field.value?.signed_url
              ? _.last(field.value?.signed_url?.split("?")[0].split("/"))
              : field.value.name}
          </div>
          <input
            type="file"
            name={name}
            onChange={(e) =>
              e.target.files[0] && onFileChange(e.target.files[0])
            }
            className="hidden"
            id={id + "-fileInput"}
            accept={props.accept ?? null}
          />
          <div className="flex cursor-pointer flex-row justify-center items-center bg-theme-gray-700 pl-[15px] pr-[17px] h-full text-sm font-semi-bold tracking-tight text-theme-red sticky right-0">
            {field.value === undefined || field.value === null
              ? "UPLOAD"
              : "EDIT"}
          </div>
        </div>
        <div className="flex mt-1 text-xs leading-none tracking-tight text-theme-black-300">
          {suggestion}
        </div>
      </div>
      <ShowFormError name={name} />
    </div>
  );
};

FileInput.defaultProps = {
  className: "",
  height: "",
  suggestion: "",
};

FileInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  height: PropTypes.string,
  suggestion: PropTypes.string,
  onFileChange: PropTypes.func.isRequired,
};

export default FileInput;
