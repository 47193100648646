import PropTypes from "prop-types";
import { useState } from "react";
import SearchBar from "../../stories/SearchBar";
import AppSkeleton from "../AppSkeleton";
import _ from "lodash";
import { leaderboardColumn } from "../../Constants";
import { useNavigate } from "@reach/router";
import { CaretLeft } from "@phosphor-icons/react";
import { useQPFPProgressTracker } from "../../services/CourseDetails";
import { QPFPModuleWiseProgressBar } from "./QPFPProgressTracker";
import { useProfile } from "../../context/userProvider";
import UserProfileAvatar from "../../stories/UserProfileAvatar";
import useLeaderboard from "../../hooks/useLeaderboard";
import LeaderBoardTable from "./QPFPProgressTracker/LeaderboardTable";

export const Leaderboard = (props) => {
  const navigate = useNavigate();
  const [page, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { userDetails } = useProfile();
  const entriesPerPage = 10;
  const { qpfpProgressTracker, loading: isProgressTrackerLoading } =
    useQPFPProgressTracker("qpfp", props.course_id, {
      revalidateOption: {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
      },
    });
  const avgBatchPercentage = qpfpProgressTracker?.modules?.reduce(
    (res, currV) => res + currV.batch_percentage,
    0
  );

  const { data: users, totalEntries } = useLeaderboard(
    props.course_id,
    page,
    searchQuery,
    entriesPerPage,
    setIsLoading
  );

  const pageData = _.isEmpty(users)
    ? []
    : [
        { name: "Dashboard", to: "/" },
        { name: "My Programs", to: "/programs" },
        {
          name: qpfpProgressTracker?.course_name,
          to: `/programs/${props.course_id}`,
        },
        {
          name: "Leaderboard",
          to: `/programs/${props.course_id}/leaderboard`,
          current: true,
        },
      ];
  return (
    <AppSkeleton
      hideSidebar={true}
      pageLabel="Leaderboard"
      secondaryHeading="Leaderboard"
      showMobileHeader={true}
      breadCrumbData={pageData}
      isLoading={isProgressTrackerLoading}
    >
      <>
        <div className="py-8 px-10 flex flex-col w-full">
          <div className="flex flex-col md:flex-row justify-between md:items-center gap-4">
            <div
              className="flex gap-2 items-center font-medium text-lg cursor-pointer"
              onClick={() => navigate(`/programs/${props.course_id}`)}
            >
              <CaretLeft />
              Back to QPFP Program
            </div>
            <div className="flex w-full md:max-w-lg">
              <SearchBar
                className="w-full !border border-neutral-200"
                backgroundColor="bg-white"
                placeholder="Search by name"
                searchTerm={searchQuery}
                onSearch={_.debounce((val) => {
                  setPageNumber(1);
                  setSearchQuery(val);
                }, 500)}
              />
            </div>
          </div>

          <div className="mt-8 flex w-full font-bold text-2xl text-theme-black-color">
            {props.location?.state?.course_name}
          </div>
          <div className="flex flex-col lg:flex-row  w-full gap-8 mt-4 lg:items-center">
            <div className="flex gap-3 items-center">
              <UserProfileAvatar
                imageUrl={userDetails.profile_picture?.signed_url}
                isLoggedInUser
              />
              <div className="flex flex-col gap-1">
                <div className="font-medium leading-6 line-clamp-1 whitespace-nowrap text-lg">
                  {userDetails.display_name}
                </div>
                <div className="text-xs leading-none text-theme-black-300">
                  {userDetails.city_name}
                </div>
              </div>
            </div>
            <QPFPModuleWiseProgressBar
              modules={qpfpProgressTracker?.modules ?? []}
              courseCompletionPercentage={
                qpfpProgressTracker?.course_completion_percentage ?? 0
              }
              isFinalExamCompleted={
                userDetails.qpfp_certification_status !== "inactive"
              }
            />
          </div>
          <div className="mt-8 md:overflow-x-scroll">
            <LeaderBoardTable
              columns={leaderboardColumn(
                avgBatchPercentage,
                qpfpProgressTracker ?? {}
              )}
              isLoading={isLoading}
              totalEntries={totalEntries}
              onPageChange={(page) => {
                setIsLoading(true);
                setPageNumber(page);
              }}
              data={users}
              entriesPerPage={entriesPerPage}
              currentPage={page}
            />
          </div>
        </div>
      </>
    </AppSkeleton>
  );
};

Leaderboard.propTypes = {
  course_id: PropTypes.number,
  course_name: PropTypes.string,
  location: PropTypes.object,
};
