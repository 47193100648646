import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import StepperProgressBar from "../../../stories/StepperProgressBar";

const ProfileCompletionCard = ({ className, percentCompleted, totalForms }) => {
  return (
    <div className={classNames("flex flex-col w-full", className)}>
      <div className="flex justify-between">
        <div className="flex flex-col items-start text-left">
          <div className="flex text-xl font-bold leading-none text-theme-black-color">
            Profile Completion
          </div>
          <div className="flex text-sm tracking-tight text-theme-black-300">
            Complete your public profile.
          </div>
        </div>
        <div className="flex flex-col justify-end text-xl font-bold leading-none text-theme-black-color">
          {Math.round(percentCompleted ?? 0)}%
        </div>
      </div>
      <div className="flex">
        <StepperProgressBar
          className="w-full mt-4"
          currentStep={5}
          percentCompleted={percentCompleted}
          totalSteps={totalForms}
        />
      </div>
    </div>
  );
};

ProfileCompletionCard.propTypes = {
  className: PropTypes.string,
  percentCompleted: PropTypes.number,
  totalForms: PropTypes.number,
};

export default ProfileCompletionCard;
