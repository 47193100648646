import React from "react";
import networkfp_logo from "../../assets/svgs/NFP_logo_White.svg";
import facebook from "../../assets/svgs/facebook.svg";
import whatsapp from "../../assets/svgs/whatsapp.svg";
import youtube from "../../assets/svgs/youtube.svg";
import {
  EnvelopeSimple,
  LinkedinLogo,
  TwitterLogo,
} from "@phosphor-icons/react";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col w-full px-10 py-10 lg:px-20 bg-theme-black-color">
        <div className="flex flex-col justify-between w-full gap-8 lg:flex-row">
          <img
            alt="network fp logo"
            src={networkfp_logo}
            className="h-10 lg:h-14"
          />
          <div className="flex flex-wrap justify-center gap-2">
            <a
              href="mailto:contact@networkfp.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12 bg-white/20 hover:bg-theme-orange"
            >
              <EnvelopeSimple
                size={20}
                className="w-5 min-w-[20px] text-white"
              />
            </a>
            <a
              href="https://wa.me/919892018773"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12 bg-white/20 hover:bg-theme-orange"
            >
              <img
                alt="whatsapp"
                src={whatsapp}
                className="h-5 w-5 min-w-[20px]"
              />
            </a>
            <a
              href="https://twitter.com/networkfp"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12 bg-white/20 hover:bg-theme-orange"
            >
              <TwitterLogo size={20} className="w-5 min-w-[20px] text-white" />
            </a>
            <a
              href="https://www.facebook.com/networkfp/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12 bg-white/20 hover:bg-theme-orange"
            >
              <img
                alt="facebook"
                src={facebook}
                className="h-5 w-5 min-w-[20px]"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/3591420/admin/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12 bg-white/20 hover:bg-theme-orange"
            >
              <LinkedinLogo size={20} className="w-5 min-w-[20px] text-white" />
            </a>
            <a
              href="https://www.youtube.com/@NetworkFPIndia"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer lg:w-12 lg:h-12 bg-white/20 hover:bg-theme-orange"
            >
              <img
                alt="youtube"
                src={youtube}
                className="h-5 w-5 min-w-[20px]"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between p-5 text-sm leading-none text-white lg:px-20 lg:py-5 lg:flex-row bg-theme-black-color/90">
        <div className="">&copy; 2024 Network FP. All Rights Reserved.</div>
        <div className="">
          Designed and Developed by{" "}
          <a href="https://www.f22labs.com/">F22 Labs.</a>
        </div>
      </div>
    </>
  );
};

export default Footer;
