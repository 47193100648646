import React from "react";
import toast from "react-hot-toast";
import Alert from "./Alert";
import { Transition } from "@headlessui/react";

const showToast = (type = "info", title, options = {}) => {
  const { position = "top-right", duration = 5000, dismiss = true } = options;

  return toast.custom(
    (t) => (
      <Transition
        show={t.visible}
        enter="transition duration-150 ease-in"
        enterFrom="translate-x-15 opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave="transition duration-150 ease-in"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-15 opacity-0"
      >
        <Alert
          type={type}
          title={title}
          dismiss={dismiss}
          dismissOnClick={() => toast.dismiss(t.id)}
        />
      </Transition>
    ),
    { id: "unique-notification", position: position, duration }
  );
};

export const CustomToast = {
  error: (title, options) => showToast("error", title, options),
  info: (title, options) => showToast("info", title, options),
  success: (title, options) => showToast("success", title, options),
};
