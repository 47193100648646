import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PublicProfileCard from "../../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../../services/PublicProfileService";
import _ from "lodash";
import Button from "../../../stories/Button";
import { CustomToast } from "../../../stories/Toast";
import FormBuilder from "../../../stories/FormBuilder";
import { Validations } from "../../../constants/validations";

const ServicesOfferedCard = ({
  className,
  corporateProfileData,
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialValues, setInitialValues] = useState({
    solutions: [],
    products: [],
  });

  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      solutions: (
        corporateProfileData.firm?.professional_detail?.services ?? []
      ).map((e) => e.id),
      products: (
        corporateProfileData.firm?.professional_detail?.products ?? []
      ).map((e) => e.id),
    }));
  }, [corporateProfileData.firm]);

  const getSolutionLabel = (id) => {
    return (
      <span key={id}>
        {options["solution"]?.filter((e) => e.id === id)[0]?.value ?? ""}
      </span>
    );
  };
  const getProductLabel = (id) => {
    return (
      <span key={id}>
        {options["product"]?.filter((e) => e.id === id)[0]?.value ?? ""}
      </span>
    );
  };
  useEffect(() => {
    setIsEmpty(
      Object.values(initialValues).filter((val) => !_.isEmpty(val)).length === 0
    );
  }, [initialValues]);
  const textInputDetails = [
    {
      label: <span className="flex">Solutions</span>,
      name: "solutions",
      type: "checklist",
      options: options["solution"],
      displayKey: "value",
      idKey: "id",
      rows: 1,
      className: "!col-span-1",
      isViewOnly: !isEditing,
      viewOnlyValues: (e) => {
        if (_.isEmpty(e)) return "-";
        return (
          <div className="flex flex-col">{_.map(e, getSolutionLabel)}</div>
        );
      },
    },
    {
      label: <span className="flex">Products</span>,
      name: "products",
      type: "checklist",
      className: "!col-span-1",
      options: options["product"],
      rows: 1,
      displayKey: "value",
      idKey: "id",
      isViewOnly: !isEditing,
      viewOnlyValues: (e) => {
        if (_.isEmpty(e)) return "-";
        return <div className="flex flex-col">{_.map(e, getProductLabel)}</div>;
      },
    },
  ];

  const onFormSubmit = async (values) => {
    const postBody = {
      professional_detail_attributes: {
        services_ids: values.solutions,
        products_ids: values.products,
      },
    };
    if (corporateProfileData.firm?.["professional_detail"]?.id) {
      postBody["professional_detail_attributes"]["id"] =
        corporateProfileData.firm?.["professional_detail"].id;
    }
    try {
      const result = await PublicProfileService.updateCorporateInfo({
        data: postBody,
        id: corporateProfileData.firm?.id,
      });
      if (result.message) {
        CustomToast.success(result.message);
        onUpdate();
        setIsEditing(false);
      }
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0]);
    }
  };
  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Services Offered"
        subHeading="Select all applicable to you"
        isEditing={isEditing}
        id="services-offered-card"
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isLoading={isLoading}
        isEmpty={!isEditing && isEmpty}
        setIsEditing={setIsEditing}
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              heading="Add Services"
              className={"!mt-0 !mb-4"}
              message="To see services offered here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#services-offered-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              <div className="flex ">
                <FormBuilder
                  initialValues={initialValues}
                  validationSchema={
                    Validations.publicProfile.servicesOfferedValidationSchema
                  }
                  onFormSubmit={onFormSubmit}
                  submitButtonRef={submitRef}
                  cancelButtonRef={cancelRef}
                  inputFieldsDetails={textInputDetails}
                  formClassName="grid w-full grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-4"
                />
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

ServicesOfferedCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  corporateProfileData: PropTypes.object,
};

export default ServicesOfferedCard;
