import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import { BulletCard } from "../../stories/BulletList";
import ImageComponent from "../../stories/ImageComponent";
import Link from "../../stories/Link";
import ProMemberDetailPopup from "../MembershipRegistration/ProMemberDetailPopup";

const ContributionDetails = ({ className, contributionDetails = {} }) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const renderCards = () => {
    return (
      <React.Fragment>
        <div className="grid grid-cols-1 gap-4 mt-4 ">
          <BulletCard
            header={<b>Voluntary Contribution details:</b>}
            list={contributionDetails?.instructions}
          />
        </div>
        <div className="items-start justify-between block w-full mt-4 lg:flex-row lg:items-center">
          {contributionDetails.detail_link && (
            <React.Fragment>
              <span className="text-lg font-semibold tracking-tight text-theme-black-color">
                Want to know more about{" "}
                {contributionDetails.short_title ??
                  contributionDetails.title ??
                  ""}
                ? &nbsp;
              </span>
              <Link
                href={contributionDetails.detail_link}
                target="_blank"
                textColor="text-theme-red"
                activeTextColor="text-theme-red"
                fontSize="lg"
                showUnderLine={false}
                className="font-semibold leading-5"
              >
                Click Here
              </Link>
            </React.Fragment>
          )}
          <span
            className="hidden mt-2 text-xs tracking-tight cursor-pointer lg:flex text-theme-red hover:underline"
            onClick={() => navigate("/")}
          >
            Back to Dashboard
          </span>
        </div>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <div className="flex flex-col w-full p-4">
        <div className="flex w-full">
          <ImageComponent
            src={contributionDetails?.banner_image?.url ?? ""}
            width="100%"
            errorComponent={<div className="w-full !bg-white h-36"></div>}
            height="auto"
            className="object-contain !bg-white max-h-36"
          />
        </div>
        <div className="flex mt-4 text-lg font-semibold tracking-tight text-theme-black-color">
          {contributionDetails.title ?? contributionDetails.short_title ?? ""}
        </div>
        <div className="block mt-1 text-sm tracking-tight text-theme-black-300">
          {contributionDetails?.description?.split("\n").map((item, idx) => (
            <span key={idx}>
              {item}
              <br></br>
            </span>
          )) ?? ""}
        </div>
        <div
          className="flex mt-3 text-sm font-semibold leading-none tracking-tight cursor-pointer lg:hidden text-theme-red w-fit"
          onClick={() => setShowPopup(true)}
        >
          VIEW MORE DETAILS
        </div>
        <span
          className="flex mt-3 text-xs tracking-tight cursor-pointer lg:hidden text-theme-red hover:underline"
          onClick={() => navigate("/")}
        >
          Back to Dashboard
        </span>
        <div className="flex-col hidden lg:flex">{renderCards()}</div>
      </div>
      {showPopup && (
        <ProMemberDetailPopup
          heading={
            contributionDetails.short_title ??
            contributionDetails.title ??
            "Event Details"
          }
          handleClose={() => setShowPopup(false)}
          open={showPopup}
          className="!p-0"
          modalBody={renderCards()}
        />
      )}
    </React.Fragment>
  );
};

ContributionDetails.propTypes = {
  className: PropTypes.string,
  contributionDetails: PropTypes.shape({
    attendees: PropTypes.array,
    banner_image: PropTypes.shape({
      url: PropTypes.string,
    }),
    contact_email: PropTypes.any,
    contact_name: PropTypes.any,
    contact_phone_number: PropTypes.any,
    description: PropTypes.shape({
      split: PropTypes.func,
    }),
    detail_link: PropTypes.string,
    instructions: PropTypes.any,
    perks: PropTypes.array,
    short_title: PropTypes.string,
    title: PropTypes.string,
    whenContent: PropTypes.array,
    whereContent: PropTypes.array,
  }),
  isNewUser: PropTypes.any,
};

export default ContributionDetails;
