import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import {
  DownloadSimple,
  File,
  VideoCamera,
  ImageSquare,
  FileVideo,
  FilePdf,
  FileDoc,
  FileImage,
} from "@phosphor-icons/react";
import _ from "lodash";
import { CustomToast } from "./Toast";
import classNames from "classnames";

const ResourceCard = ({
  id,
  title,
  styleType = "common",
  type,
  downloadLink,
}) => {
  const typeLogoClass = "text-gray-300 text-sm md:text-xl";

  const TypeSideLogo = () => {
    const url = downloadLink?.split("?")[0];
    const type = url.split(".").pop();
    if (_.toLower(type)) {
      if (["mp4", "webm"].includes(type)) {
        return (
          <FileVideo
            size={40}
            weight="fill"
            className="min-w-[40px] text-white"
          />
        );
      } else if (["docx", "xlsx", "csv", "pptx", "ppt", "doc"].includes(type)) {
        return (
          <FileDoc
            size={40}
            weight="fill"
            className="min-w-[40px] text-white"
          />
        );
      } else if (["pdf"].includes(type)) {
        return (
          <FilePdf
            size={40}
            weight="fill"
            className="min-w-[40px] text-white"
          />
        );
      } else if (["png", "svg", "jpg", "jpeg"].includes(type)) {
        return (
          <FileImage
            size={40}
            weight="fill"
            className="min-w-[40px] text-white"
          />
        );
      } else {
        return (
          <FileDoc
            size={40}
            weight="fill"
            className="min-w-[40px] text-white"
          />
        );
      }
    }
  };
  const TypeLogo = ({ type }) => {
    switch (_.toLower(type)) {
      case "video":
        return <VideoCamera className={typeLogoClass} weight="fill" />;
      case "document":
      case "file":
        return <File className={typeLogoClass} weight="fill" />;
      case "image":
        return <ImageSquare className={typeLogoClass} weight="fill" />;
      default:
        return <File className={typeLogoClass} weight="fill" />;
    }
  };
  return (
    <div className="flex rounded-sm">
      {styleType === "resourcesScreen" && (
        <div className="p-5 bg-gray-400 rounded-l-sm">
          <TypeSideLogo type={type} />
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col space-y-1.5 p-4 bg-gray-50  md:min-w-[440px]",
          styleType === "resourcesScreen" ? "rounded-r-sm" : "rounded-sm"
        )}
      >
        <Text
          type="text"
          size="text-sm md:text-base"
          className="text-theme-black-color"
          content={title}
        />
        <div className="flex justify-between ">
          <div className="flex items-center space-x-1.5">
            <TypeLogo type={type} />
            <div className="text-xs text-gray-400 md:text-sm">
              {_.toUpper(_.last(_.head(downloadLink.split("?")).split(".")))}
            </div>
          </div>
          <button
            onClick={() => {
              fetch(downloadLink)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const blobURL = window.URL.createObjectURL(blob);
                  const filename = _.last(
                    _.head(downloadLink.split("?")).split("/")
                  );
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = blobURL;
                  // the filename you want
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(blobURL);
                })
                .catch(() =>
                  CustomToast.error(
                    "Something went wrong!! Cannot download file"
                  )
                );
            }}
            className="flex items-center space-x-1.5 hover:cursor-pointer"
          >
            <DownloadSimple
              className="text-sm text-theme-red md:text-lg"
              weight="fill"
            />
            <div className="text-xs md:text-sm text-theme-red">DOWNLOAD</div>
          </button>
          <a
            rel="noreferrer"
            href={downloadLink}
            id={`resource-#${id}`}
            download
            target="_blank"
            className="hidden"
          >
            Click
          </a>
        </div>
      </div>
    </div>
  );
};

ResourceCard.propTypes = {
  downloadLink: PropTypes.string,
  id: PropTypes.any,
  styleType: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default ResourceCard;
