export const getDirectoryMemberURL = (profileSlug) => {
  return `${process.env.REACT_APP_PROD_PUBLIC_DIRECTORY_URL}member/${profileSlug}`;
};

export const getDirectoryFirmURL = (profileSlug) => {
  return `${process.env.REACT_APP_PROD_PUBLIC_DIRECTORY_URL}company/${profileSlug}`;
};
export const getInvoiceDownloadURL = (purchaseId) => {
  return `${process.env.REACT_APP_PROD_BASE_API_URL}/purchases/${purchaseId}/download_invoice`;
};
export const getAPIUrl = () => {
  return process.env.REACT_APP_PROD_BASE_API_URL;
};
