import ApiConfig from "../api/config";
import { useAPI } from "./SWRService";
import _ from "lodash";
import qs from "qs";

export const useForums = (params, config = {}) => {
  const key = `${ApiConfig.urls.exchange_forum.list}?${qs.stringify(params, {
    arrayFormat: "brackets",
    encode: false,
  })}`;
  const { data, error, loading, mutate, isValidating } = useAPI(
    !_.isEmpty(params) ? key : null,
    config
  );
  return {
    forums: _.isEmpty(data) ? [] : data.exchange_forums,
    pagination: _.isEmpty(data) ? {} : data.pagination,
    error,
    loading,
    mutate,
    isValidating,
  };
};
