/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import QuizSideBar from "../../stories/QuizSideBar.js";
import QuizComponent from "./QuizComponent.js";
import Button from "../../stories/Button.js";
import { useLocation, useNavigate } from "@reach/router";
import { CourseService } from "../../services/CourseService.js";
import { CustomToast } from "../../stories/Toast.js";
import _ from "lodash";
import AppSkeleton from "../AppSkeleton.js";
import Loader from "../Shimmer/Loader.js";
import QuizHeader from "./QuizHeader.js";
import QuizFooter from "./QuizFooter.js";
import ConfirmSubmitQuizPopup from "./ConfirmSubmitQuizPopup.js";
import application_submitted from "../../assets/svgs/quiz_submitted.svg";

const Quiz = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [timeLeft, setTimeLeft] = useState(undefined);
  const [showSkippedQuestion, setShowSkippedQuestion] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [questionDropDownlist, setQuestionDropdownList] = useState([]);
  const [currentQuizQuestion, setCurrentQuizQuestion] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const isQuizSubmittedRef = React.useRef();
  const timeLeftRef = React.useRef();
  const quizDurationRef = React.useRef();
  const skipAutoSubmmitOnRouteChangeRef = React.useRef(true);
  const [isTimerRunning, setTimerRunning] = useState(false);
  const [showQuizReview, setShowQuizReview] = useState(false);
  const [openSubmitQuizPopup, setOpenSubmitQuizPopup] = useState(false);
  const [showThankYouScreen, setShowThankYouScreen] = useState(false);
  const timerIntervalRef = React.useRef();
  const quizProgress =
    _.toNumber(
      quizQuestions.filter(
        (e) => e.chosen_answer_ids.length > 0 && e.isSubmitted === true
      ).length / quizQuestions.length
    ) * 100;
  const searchParam = useRef({});

  useEffect(() => {
    isQuizSubmittedRef.current = false;
    let temp = window.location.href.split("?")[1];
    if (temp === undefined) {
      CustomToast.error("You don't have proper access it seems");
      localStorage.setItem(`was_quiz_${props.quiz_id}_opened`, false);
      window.close();
      return;
    }
    searchParam.current = _.fromPairs(temp.split("&").map((s) => s.split("=")));
    fetchQuiz();
    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (skipAutoSubmmitOnRouteChangeRef.current === false) {
      let response = window.confirm("Are you sure you want to close the quiz");
      submitQuiz({ skip: !response });
      if (!response) {
        updateCurrentViewQuestion({
          isClosingTab: true,
          secondsSpent: quizDurationRef.current - timeLeftRef.current,
        }).then(() => {
          navigate(1);
        });
      }
    }
    return () => {
      if (skipAutoSubmmitOnRouteChangeRef.current === false) {
        let response = window.confirm(
          "Are you sure you want to close the quiz"
        );
        submitQuiz({ skip: !response });
        if (!response) {
          updateCurrentViewQuestion({
            isClosingTab: true,
            secondsSpent: quizDurationRef.current - timeLeftRef.current,
          }).then(() => {
            navigate(1);
          });
        }
      }
    };
  }, [location]);

  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 0) {
          if (isQuizSubmittedRef.current === false) {
            isQuizSubmittedRef.current = true;
            submitQuiz({ skip: false });
          }
          clearInterval(timerIntervalRef.current);
          timeLeftRef.current = 0;
          return 0;
        } else {
          timeLeftRef.current = prev - 1;
          return prev - 1;
        }
      });
    }, 1000);
  };

  const updateQuizProgress = async (status) => {
    await CourseService.updateCourseProgress({
      course_id: props.course_id,
      last_viewed_content: {
        course_session_content_id: parseInt(searchParam.current.id),
        last_seen_in_sec: 0,
      },
      progress: {
        course_content_id: `${parseInt(searchParam.current.id)}`,
        value: { progress_in_sec: 0, status: status },
      },
    });
  };

  const submitQuiz = async ({ skip = false }) => {
    if (skip === true) return null;
    try {
      const responseData = {
        quiz_id: props.quiz_id,
        is_submit: true,
        seconds_spent: quizDurationRef.current - timeLeftRef.current ?? 0,
      };
      const response = await CourseService.submitQuizQuestionResponse(
        responseData
      );
      localStorage.setItem(`quiz_${props.quiz_id}_submit_status`, "submitted");
      setTimerRunning(false);
      clearInterval(timerIntervalRef.current);
      await updateQuizProgress("completed");
      setIsSubmittingAnswer(false);
      CustomToast.success(response.message);
      skipAutoSubmmitOnRouteChangeRef.current = true;
    } catch (e) {
      console.log(e);
    } finally {
      setShowThankYouScreen(true);
      // window.close();
    }
  };

  const submitUserQuestionResponse = async ({
    currentQuestionIndex = currentQuizQuestion,
    answer_ids,
  }) => {
    let responseData = {
      quiz_id: props.quiz_id,
      quiz_question_id: quizQuestions[currentQuestionIndex].id,
      response: answer_ids,
      is_submit: false,
    };
    const response = await CourseService.submitQuizQuestionResponse(
      responseData
    );
    const tempQuestionList = [...quizQuestions];
    tempQuestionList[currentQuestionIndex]["isSubmitted"] = true;
    tempQuestionList[currentQuestionIndex]["isSkipped"] =
      answer_ids.length === 0;
    tempQuestionList[currentQuestionIndex]["chosen_answer_ids"] = answer_ids;
    setQuizQuestions(tempQuestionList);
  };

  const submitAnswer = async ({ is_submit = false }) => {
    setIsSubmittingAnswer(true);
    try {
      if (
        quizQuestions[currentQuizQuestion].chosen_answer_ids.length === 0 &&
        is_submit
      ) {
        await submitQuiz({ skip: false });
        skipAutoSubmmitOnRouteChangeRef.current = true;
        return;
      }
      if (quizQuestions[currentQuizQuestion].chosen_answer_ids.length > 0) {
        let responseData = {
          quiz_id: props.quiz_id,
          quiz_question_id: quizQuestions[currentQuizQuestion].id,
          response: quizQuestions[currentQuizQuestion].chosen_answer_ids,
          is_submit: is_submit,
        };
        if (is_submit) {
          responseData = {
            ...responseData,
            seconds_spent: quizData.duration_in_seconds - timeLeft ?? 0,
          };
        }
        const response = await CourseService.submitQuizQuestionResponse(
          responseData
        );
        const tempQuestionList = [...quizQuestions];
        tempQuestionList[currentQuizQuestion]["isSubmitted"] = true;
        tempQuestionList[currentQuizQuestion]["isSkipped"] = false;
        setQuizQuestions(tempQuestionList);
        if (is_submit) {
          setTimerRunning(false);
          clearInterval(timerIntervalRef.current);
          localStorage.setItem(
            `quiz_${props.quiz_id}_submit_status`,
            "submitted"
          );
          await updateQuizProgress("completed");
          skipAutoSubmmitOnRouteChangeRef.current = true;
          setShowThankYouScreen(true);
          // window.close();
          CustomToast.success(response.message);
        }
      } else {
        const tempQuestionList = [...quizQuestions];
        tempQuestionList[currentQuizQuestion]["isSkipped"] = true;
        setQuizQuestions(tempQuestionList);
      }
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0]);
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const fetchQuiz = async () => {
    try {
      setIsLoading(true);
      const response = await CourseService.fetchQuiz({
        quiz_id: props.quiz_id,
      });
      setQuizData(response);
      const tempQuestions = response.questions;
      let currentQuestion = [];
      if (response.current_view_question_id) {
        currentQuestion = _.remove(
          tempQuestions,
          (q) => q.id === response.current_view_question_id
        );
      }
      const answered = tempQuestions
        .filter((q) => q.chosen_answer_ids.length > 0)
        .map((q) => ({ ...q, isSubmitted: true }));
      const unanswered = tempQuestions.filter(
        (q) => q.chosen_answer_ids.length === 0
      );
      const sortedQuestions = [...answered, ...currentQuestion, ...unanswered];
      setQuizQuestions(sortedQuestions);
      setQuestionDropdownList(
        sortedQuestions.map((e, index) => {
          return {
            text: `Question ${index + 1}`,
            value: index,
          };
        })
      );
      localStorage.setItem(`was_quiz_${props.quiz_id}_opened`, true);
      if (response.duration_in_seconds === response.seconds_spent) {
        skipAutoSubmmitOnRouteChangeRef.current = true;
        await submitQuiz({ skip: false });
        isQuizSubmittedRef.current = true;
        return;
      }
      skipAutoSubmmitOnRouteChangeRef.current = false;
      quizDurationRef.current = response.duration_in_seconds;
      if (response.current_view_question_id) {
        sortedQuestions.forEach((e, index) => {
          if (e.id === response.current_view_question_id) {
            setCurrentQuizQuestion(index);
            setTimeLeft(
              response.duration_in_seconds - (response.seconds_spent ?? 0)
            );
            startTimer();
            return;
          }
        });
      } else {
        setCurrentQuizQuestion(0);
        const startedAt = new Date().getTime();
        await CourseService.updateCurrentQuizQuestion({
          quiz_id: props.quiz_id,
          data: {
            quiz_question_id: response.questions[0]?.id,
            started_at: startedAt,
          },
        });
        setTimeLeft(
          response.duration_in_seconds - (response.seconds_spent ?? 0)
        );
        startTimer();
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      localStorage.setItem(`was_quiz_${props.quiz_id}_opened`, false);
      if (e.error_messages[0]) {
        localStorage.setItem(
          `quiz_${props.quiz_id}_error_message`,
          e.error_messages[0]
        );
      }
      skipAutoSubmmitOnRouteChangeRef.current = true;
      CustomToast.error(e.error_messages[0]);
      await submitQuiz({ skip: false });
      // await updateQuizProgress("completed");
      // navigate(-1);
    }
  };
  const browserCloseCallback = async (event) => {
    // if (!isSubmittingQuiz) {
    if (!localStorage.getItem(`quiz_${props.quiz_id}_submit_status`)) {
      event.preventDefault();
      event.returnValue = "Are you sure you want to exit?";
      localStorage.setItem(
        `quiz_${props.quiz_id}_submit_status`,
        "not_submitted"
      );
      await updateCurrentViewQuestion({
        index: currentQuizQuestion,
        isClosingTab: true,
        secondsSpent: quizDurationRef.current - timeLeftRef.current,
      });
    }
    // }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", browserCloseCallback);
    return () =>
      window.removeEventListener("beforeunload", browserCloseCallback);
  }, [timeLeft]);

  const updateCurrentViewQuestion = async ({
    index,
    isClosingTab = false,
    secondsSpent,
  }) => {
    if (isClosingTab) {
      localStorage.setItem(`quiz_time_spent_${props.quiz_id}`, secondsSpent);
    }
    try {
      let spentSeconds =
        secondsSpent ?? quizData.duration_in_seconds - timeLeft ?? 0;
      await CourseService.updateCurrentQuizQuestion(
        isClosingTab
          ? {
              quiz_id: props.quiz_id,
              data: {
                seconds_spent: spentSeconds,
                quiz_question_id: quizQuestions[index]?.id,
              },
            }
          : {
              quiz_id: props.quiz_id,
              data: {
                quiz_question_id: quizQuestions[index]?.id,
                started_at: null,
              },
            }
      );
    } catch (e) {
      console.log(e);
    }
    setCurrentQuizQuestion(index);
  };

  if (isLoading)
    return (
      <div className="bg-theme-gray-200">
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </div>
    );

  if (showThankYouScreen) {
    return (
      <div className="w-full h-full bg-white ">
        <AppSkeleton
          hideSidebar={true}
          hideMobileBackButton={true}
          hideHeaderLogo={true}
          hideProfileDetails={true}
          breadCrumbData={[]}
          showMobileHeader={true}
          pageLabel={quizData.title}
          secondaryHeading={quizData.title}
        >
          <div className="w-full h-[calc(100vh-80px)] flex items-center py-auto">
            <div className="flex flex-col items-center w-full max-w-3xl p-8 mx-auto my-2 bg-white">
              <img
                src={application_submitted}
                alt="thank_you_img"
                width={158}
                height={214}
              />
              <div className="mt-10 text-2xl font-bold tracking-tight text-center lg:font-semibold lg:tracking-normal text-theme-black-color">
                Thank you!
              </div>
              <div className="max-w-lg mt-3 text-base leading-5 text-center text-theme-black-300">
                Thank You for taking the Quiz. Your quiz has been submitted
                successfully.
              </div>
              <div className="max-w-lg mt-10 text-base leading-5 text-center text-red-600">
                Please close this tab to see results.
              </div>
              <Button className="!mt-4" onClick={() => window.close()}>
                <div className="flex items-center px-3 py-1">
                  <span>CLOSE WINDOW</span>
                </div>
              </Button>
            </div>
          </div>
        </AppSkeleton>
      </div>
    );
  }
  return (
    <div className="bg-theme-gray-200">
      <div className="flex">
        <div className="hidden w-full shadow-2xl lg:flex lg:w-72 lg:min-w-72">
          <QuizSideBar
            progress={quizProgress}
            totalQuestions={quizQuestions.length}
            timeLeftInSec={timeLeft}
            passPercentage={quizData.eligible_score}
            negativeMarking={
              quizQuestions[currentQuizQuestion]?.["negative_marking"]
            }
            numberOfQuizResponse={quizData.number_of_quiz_response}
            timeLeft={timeLeft}
            quizName={quizData.title}
            aceEligibility={quizData.ace_points}
            isTimerRunning={isTimerRunning}
          />
        </div>
        <div className="flex flex-col w-screen h-screen">
          <AppSkeleton
            hideSidebar={true}
            hideMobileBackButton={true}
            hideHeaderLogo={true}
            hideProfileDetails={true}
            breadCrumbData={[]}
            showMobileHeader={true}
            pageLabel={quizData.title}
            secondaryHeading={quizData.title}
          >
            <input type={"radio"} className="hidden" autoFocus id="myRadio" />
            <div className="flex justify-center lg:pt-14 ">
              <div className="flex flex-col items-center justify-center w-full">
                <QuizHeader
                  className="w-full lg:max-w-2xl "
                  timeLeft={timeLeft}
                  showReview={showQuizReview}
                  questionDropDownlist={questionDropDownlist}
                  currentQuizQuestion={currentQuizQuestion}
                  questionList={quizQuestions}
                  totalQuizLength={quizQuestions.length}
                  quizProgress={quizProgress}
                  onDropdownSelect={async (e) => {
                    if (
                      (
                        quizQuestions[currentQuizQuestion].chosen_answer_ids ??
                        []
                      ).length === 0
                    ) {
                      const tempQuestionList = [...quizQuestions];
                      tempQuestionList[currentQuizQuestion]["isSkipped"] = true;
                      setQuizQuestions(tempQuestionList);
                    }
                    setCurrentQuizQuestion(e);
                    await updateCurrentViewQuestion({ index: e });
                    setShowSkippedQuestion(false);
                  }}
                />
                <div className="flex flex-col items-center w-full p-3 mx-4 mt-0 mb-4 bg-white rounded-md lg:bg-transparent lg:m-0 lg:p-0">
                  {showQuizReview ? (
                    quizQuestions.map((question, index) => (
                      <React.Fragment key={question.id}>
                        <div className="w-full lg:max-w-2xl">
                          <div className="mt-2 mb-6 text-base font-semibold text-theme-orange">{`Question ${
                            (index ?? 0) + 1
                          }`}</div>
                          <QuizComponent
                            key={question.id}
                            quizQuestion={question?.content}
                            quizOptions={question?.answers}
                            selected={question?.chosen_answer_ids ?? []}
                            score={question?.score}
                            onOptionSelect={async (val) => {
                              await submitUserQuestionResponse({
                                currentQuestionIndex: index,
                                answer_ids: val[0] === null ? [] : val,
                              });
                              // const tempQuestionList = [...quizQuestions];
                              // if (val[0] === null) {
                              //   tempQuestionList[currentQuizQuestion] = {
                              //     ...tempQuestionList[currentQuizQuestion],
                              //     chosen_answer_ids: [],
                              //     isSubmitted: false,
                              //   };
                              // } else {
                              //   tempQuestionList[currentQuizQuestion] = {
                              //     ...tempQuestionList[currentQuizQuestion],
                              //     chosen_answer_ids: val,
                              //     isSubmitted: false,
                              //   };
                              // }
                              // setQuizQuestions(tempQuestionList);
                            }}
                          />
                        </div>
                        {index !== quizQuestions.length - 1 && (
                          <div
                            key={`divider_${question.id}`}
                            className="bg-gray-200 h-[1px] w-full my-8"
                          ></div>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <div className="w-full lg:max-w-2xl">
                      <QuizComponent
                        quizQuestion={
                          quizQuestions[currentQuizQuestion]?.content
                        }
                        quizOptions={
                          quizQuestions[currentQuizQuestion]?.answers
                        }
                        selected={
                          quizQuestions[currentQuizQuestion]
                            ?.chosen_answer_ids ?? []
                        }
                        score={quizQuestions[currentQuizQuestion]?.score}
                        onOptionSelect={async (val) => {
                          await submitUserQuestionResponse({
                            currentQuestionIndex: currentQuizQuestion,
                            answer_ids: val[0] === null ? [] : val,
                          });
                          // const tempQuestionList = [...quizQuestions];
                          // if (val[0] === null) {
                          //   tempQuestionList[currentQuizQuestion] = {
                          //     ...tempQuestionList[currentQuizQuestion],
                          //     chosen_answer_ids: [],
                          //     isSubmitted: false,
                          //   };
                          // } else {
                          //   tempQuestionList[currentQuizQuestion] = {
                          //     ...tempQuestionList[currentQuizQuestion],
                          //     chosen_answer_ids: val,
                          //     isSubmitted: false,
                          //   };
                          // }
                          // setQuizQuestions(tempQuestionList);
                        }}
                      />
                    </div>
                  )}
                  <div className="flex justify-center mt-10 mb-6">
                    <QuizFooter
                      showReview={showQuizReview}
                      onShowReviewBtnClick={() => setShowQuizReview(true)}
                      quizQuestions={quizQuestions}
                      currentQuizQuestion={currentQuizQuestion}
                      isSubmittingAnswer={isSubmittingAnswer}
                      showSkippedQuestion={showSkippedQuestion}
                      onNextBtnClick={async () => {
                        setIsSubmittingAnswer(true);
                        if (
                          (
                            quizQuestions[currentQuizQuestion]
                              .chosen_answer_ids ?? []
                          ).length === 0
                        ) {
                          const tempQuestionList = [...quizQuestions];
                          tempQuestionList[currentQuizQuestion][
                            "isSkipped"
                          ] = true;
                          setQuizQuestions(tempQuestionList);
                        }
                        // await submitAnswer({
                        //   is_submit:
                        //     currentQuizQuestion === quizQuestions.length - 1,
                        // });
                        if (showSkippedQuestion) {
                          const tempIndex = _.findIndex(
                            quizQuestions,
                            (e, index) =>
                              _.size(e.chosen_answer_ids) === 0 &&
                              currentQuizQuestion !== index &&
                              index > currentQuizQuestion
                          );
                          if (tempIndex !== -1) {
                            await updateCurrentViewQuestion({
                              index: tempIndex,
                            });
                          } else {
                            // const tempIndex = _.findIndex(
                            //   quizQuestions,
                            //   (e, index) =>
                            //     _.size(e.chosen_answer_ids) === 0 &&
                            //     currentQuizQuestion !== index
                            // );
                            // await updateCurrentViewQuestion({
                            //   index: tempIndex,
                            // });
                          }
                        } else {
                          const prevQuestionNo = currentQuizQuestion;
                          if (prevQuestionNo < quizQuestions.length - 1) {
                            await updateCurrentViewQuestion({
                              index: prevQuestionNo + 1,
                            });
                          }
                        }
                        setIsSubmittingAnswer(false);
                      }}
                      onSubmitBtnClick={() => {
                        setOpenSubmitQuizPopup(true);
                      }}
                      onStartSkippedQuestionBtnClick={async () => {
                        // await submitAnswer({ is_submit: false });
                        setShowSkippedQuestion(true);
                        const tempIndex = _.findIndex(
                          quizQuestions,
                          (e) => _.size(e.chosen_answer_ids) === 0
                        );
                        if (tempIndex !== -1) {
                          await updateCurrentViewQuestion({ index: tempIndex });
                        }
                      }}
                      onPreviousBtnClick={() => {
                        setCurrentQuizQuestion((val) => {
                          if (val > 0) {
                            return val - 1;
                          } else {
                            return val;
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </AppSkeleton>
        </div>
      </div>
      {openSubmitQuizPopup && (
        <ConfirmSubmitQuizPopup
          hasSkippedQuestions={
            quizQuestions.filter((e) => !e.isSubmitted).length > 1 ||
            (quizQuestions.filter((e) => !e.isSubmitted).length === 1 &&
              quizQuestions.findIndex((e) => !e.isSubmitted) ===
                currentQuizQuestion &&
              quizQuestions[currentQuizQuestion].chosen_answer_ids.length === 0)
          }
          open={openSubmitQuizPopup}
          onClose={() => setOpenSubmitQuizPopup(false)}
          onConfirm={async () => {
            // await submitAnswer({ is_submit: true });
            await submitQuiz({ skip: false });
            setOpenSubmitQuizPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Quiz;
