import React from "react";
import OnboardingHeader from "../../stories/OnboardingHeader";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import { AuthService } from "../../services/AuthService";
import { useLocation } from "@reach/router";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Link from "../../stories/Link";
import { CustomToast } from "../../stories/Toast";
import email_anim from "../../assets/animations/email_lottie_anim.gif";

const ResendVerification = (props) => {
  const { state } = useLocation();
  useDocumentTitle("Verify Email");

  const sendEmailVerificationLink = async (e) => {
    e.preventDefault();
    try {
      const { email } = state;
      const result = await AuthService.sendEmailVerificationLink({ email });

      if (result.message) {
        CustomToast.success(result.message);
      } else {
        CustomToast.error(result.errors);
      }
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3">
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          <div className="flex flex-col">
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Verify your email
            </div>
            <div className="block mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
              Your email is not verified yet. Please click on the link that
              you&apos;ve received in the verification mail. &nbsp;
              <span>
                <Link
                  textColor="text-theme-red"
                  activeTextColor="text-theme-red"
                  showUnderLine={false}
                  className="text-base leading-none"
                  onClick={(e) => sendEmailVerificationLink(e)}
                >
                  Click here
                </Link>
              </span>
              , if you have not received the verification email.
            </div>
            <div className="w-full flex items-center mt-1.5">
              <img
                src={email_anim}
                alt="email sent"
                width="378.3px"
                height="329px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
    </div>
  );
};

export default ResendVerification;
