/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { CustomToast } from "./Toast";
import { CourseService } from "../services/CourseService";
import { useProfile } from "../context/userProvider";
import { KJUR } from "jsrsasign";
import { useNavigate } from "@reach/router";

const REACT_APP_ZOOM_CLIENT_KEY = "_ZgQItMXQpSw5OfDSlC3ow";
const REACT_APP_ZOOM_CLIENT_SECRET = "dydnRdU8gf9BRJXqizxdzrg9ruAKhgfZ";

const generateSignature = (meetingNumber) => {
  const iat = Math.round(new Date().getTime() / 1000) - 30;
  const exp = iat + 60 * 60 * 2;
  const oHeader = { alg: "HS256", typ: "JWT" };

  const oPayload = {
    sdkKey: REACT_APP_ZOOM_CLIENT_KEY,
    appKey: REACT_APP_ZOOM_CLIENT_KEY,
    mn: meetingNumber,
    role: 0,
    iat: iat,
    exp: exp,
    tokenExp: exp,
  };

  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(oPayload);
  const sdkJWT = KJUR.jws.JWS.sign(
    "HS256",
    sHeader,
    sPayload,
    REACT_APP_ZOOM_CLIENT_SECRET
  );
  return sdkJWT;
};

const WatchLiveSession = (props) => {
  const { userDetails } = useProfile();
  const navigate = useNavigate();

  const startWebinar = async () => {
    const { ZoomMtg } = await import("@zoomus/websdk");
    document.getElementById("zmmtg-root").style.display = "block";
    let registrantToken = "";
    let meetingNumber = "";
    let passcode = "";

    try {
      const registerResponse = await CourseService.addRegistrantToZoomWebinar({
        course_session_content_id:
          props.location?.state.course_session_content_id,
      });
      meetingNumber = registerResponse?.zoom_registration_detail?.id.toString();
      registrantToken = new URL(
        registerResponse?.zoom_registration_detail?.join_url
      ).searchParams.get("tk");
    } catch (e) {
      registrantToken = "";
      meetingNumber = e.payload.meeting_id;
      passcode = e.payload.passcode;
    }

    try {
      ZoomMtg.setZoomJSLib("https://source.zoom.us/2.11.0/lib", "/av");
      // loads WebAssembly assets
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      // loads language files, also passes any error messages to the ui
      ZoomMtg.i18n.load("en-US");
      ZoomMtg.i18n.reload("en-US");
      const signature = generateSignature(meetingNumber);
      ZoomMtg.init({
        leaveUrl: props.location?.state?.leaveURL ?? "",
        disablePreview: true,
        error: (error) => console.log("error in signature:", error),
        success: (success) => {
          console.log(success, "init success");
          ZoomMtg.join({
            sdkKey: REACT_APP_ZOOM_CLIENT_KEY,
            signature: signature,
            meetingNumber: meetingNumber,
            userName: `${userDetails.first_name} ${userDetails.last_name}`,
            userEmail: userDetails.email,
            tk: registrantToken,
            passWord: passcode,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
      });
    } catch (e) {
      console.log(e, "qs");
      navigate(-1);
      CustomToast.error(e.error_messages);
    }
  };

  useEffect(() => {
    if (_.isEmpty(props.location?.state) || _.isEmpty(userDetails)) {
      navigate("/");
      return;
    }
    startWebinar();

    return () => {
      // document.getElementById("zmmtg-root").style.display = "none";
      // ZoomMtg.endMeeting();
      document.querySelector("#zmmtg-root").style.display = "none";
    };
  }, [userDetails, props.location?.state]);

  return (
    <div>
      <link
        type="text/css"
        rel="stylesheet"
        href="https://source.zoom.us/2.11.0/css/bootstrap.css"
      />
      <link
        type="text/css"
        rel="stylesheet"
        href="https://source.zoom.us/2.11.0/css/react-select.css"
      />
    </div>
  );
};

export default WatchLiveSession;

// import React from "react";

// const WatchLiveSession = () => {
//   return <div>WatchLiveSession</div>;
// };

// export default WatchLiveSession;
