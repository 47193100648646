import PropTypes from "prop-types";
import React from "react";

import CardWithHeaderAsLink from "./CardWithHeaderAsLink";
import CardWithProfilePercentage from "./CardWithProfilePercentage";
import CertsAndBadges from "./CertsAndBadges";
import QPFPProMembership from "./QPFPProMembership";
import BrandResourcesAndGuide from "./BrandResourcesAndGuide";
import RelationshipManager from "./RelationshipManager";
import ManageProfile from "./ManageProfile";
import { useProfile } from "../../../context/userProvider";
import ProgramAndProTools from "./ProgramAndProTools";
import ACESessionsAndTest from "./ACESessionsAndTest";
import { RenewUnavailable } from "../Renewal";
import moment from "moment";
import ACEPointsTracker from "./ACEPointsTracker";
import _ from "lodash";

/**
 * Component for QPFP Dashbaord Main Section
 * @return {React.ReactElement} QPFP Dashbaord Main Section
 */
function MainSection({ certificationStatus }) {
  const { userDetails } = useProfile();
  return (
    <div className="grid grid-cols-1 auto-rows-auto gap-6 px-4 py-6 pt-10 z-30 md:grid-cols-2 lg:px-20 xl:grid-cols-3">
      {["expires_soon", "overdue"].includes(
        userDetails?.qpfp_certification_status
      ) && (
        <div className="flex w-full flex-col col-span-full">
          <RenewUnavailable
            renewalDate={moment(certificationStatus?.valid_upto).format(
              "DD MMMM YYYY"
            )}
            status={userDetails?.qpfp_certification_status}
            acePointsRequired={
              certificationStatus?.ace_points_data?.ace_points_remaining ?? 0
            }
            hideLabel={true}
            course_id={certificationStatus?.qpfp_course_id}
          />
        </div>
      )}

      <div className="flex flex-col space-y-6">
        <CardWithHeaderAsLink
          label="QPFP Certificate and Badges"
          url="/qpfp-certificant/certificates"
        >
          <CertsAndBadges
            certificateUrl={certificationStatus.certificate_url}
            validUpto={certificationStatus.valid_upto}
          />
        </CardWithHeaderAsLink>

        <CardWithHeaderAsLink
          label="QPFP Brand Resources & Guide"
          url="/qpfp-certificant/brand-guidlines"
        >
          <BrandResourcesAndGuide />
        </CardWithHeaderAsLink>

        <CardWithProfilePercentage
          label="Manage Your Profile"
          url="/public-profile"
        >
          <ManageProfile />
        </CardWithProfilePercentage>

        {!_.isEmpty(certificationStatus?.relationship_manager) && (
          <CardWithHeaderAsLink label="Your Relationship Manager">
            <RelationshipManager
              managerDetails={certificationStatus?.relationship_manager}
            />
          </CardWithHeaderAsLink>
        )}
      </div>

      <div className="flex flex-col space-y-6">
        <CardWithHeaderAsLink
          label="QPFP Program & ProTools"
          url="/qpfp-certificant/courseware"
        >
          <ProgramAndProTools
            courseId={certificationStatus?.qpfp_courseware_id}
          />
        </CardWithHeaderAsLink>

        <CardWithHeaderAsLink
          label={
            userDetails.is_pro_member
              ? "Access ProMembership Benefits"
              : "Add ProMembership"
          }
        >
          <QPFPProMembership
            heading={
              userDetails.is_pro_member
                ? "Access additional and advanced membership benefits for ProMembers."
                : "Get additional and advanced membership benefits for personal finance professionals. Get 50% discount."
            }
            courseId={certificationStatus.pro_member_course_id}
            proMemberStatus={userDetails.pm_signup_status}
          />
        </CardWithHeaderAsLink>
      </div>

      <div className="flex flex-col space-y-6">
        <CardWithHeaderAsLink
          label="ACE Points Tracker"
          url="/qpfp-certificant/ace-points-tracker"
        >
          <ACEPointsTracker
            ace_points_data={certificationStatus?.ace_points_data}
          />
        </CardWithHeaderAsLink>

        <CardWithHeaderAsLink
          label="Your ACE Sessions & Tests"
          url="/qpfp-certificant/ace-program-and-tests"
        >
          <ACESessionsAndTest courseId={certificationStatus?.ace_program_id} />
        </CardWithHeaderAsLink>
      </div>
    </div>
  );
}

MainSection.propTypes = {
  certificationStatus: PropTypes.object,
};

export default MainSection;
