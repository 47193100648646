/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useField, useFormikContext } from "formik";
import Label from "./Label";
import { CaretUp } from "@phosphor-icons/react";
import ShowFormError from "./ShowFormError";
import Select, { components } from "react-select";

const AuthDropdown = ({
  name,
  placeholder,
  searchPlaceholder,
  options = [],
  displayKey,
  idKey,
  size,
  setCategoryAttribute = null,
  displayCurrentOption = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, ...props });
  const [selectedVal, setSelectedVal] = useState(field?.value ?? "");
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const lableRef = useRef(null);

  useEffect(() => {
    const temp = options.filter((e) => e[idKey] === field?.value)[0];
    setSelectedVal(temp ? temp : "");
  }, [displayCurrentOption, displayKey, field?.value, idKey, options]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {
          <CaretUp
            className={classNames(
              "w-5 h-5 ml-2 text-theme-black-color",
              !props.selectProps.menuIsOpen ? "transform rotate-180" : ""
            )}
            weight="fill"
          />
        }
      </components.DropdownIndicator>
    );
  };
  const OptionComponent = ({ children, ...rest }) => {
    return (
      <components.Option
        {...rest}
        className={classNames(
          rest.isFocused
            ? "!bg-gray-100 !text-neutral-900 cursor-pointer"
            : "!text-neutral-700",
          "block !px-4 !py-3 !bg-white !text-sm focus:!outline-none"
        )}
      >
        {children}
      </components.Option>
    );
  };

  const ControlComponent = ({ children, ...rest }) => {
    return (
      <components.Control
        {...rest}
        className={classNames(
          "!text-sm md:!text-lg min-w-36 text-theme-black-color pt-1 h-[50px] lg:h-16 md:h-14 font-medium tracking-tight w-full !border pl-2.5 focus:!outline-none focus:border hover:!border-gray-200 hover:!shadow-1",
          // selectedVal && "pt-2",
          meta.touched && meta.error
            ? "!border-theme-red-300 focus:!ring-theme-red-300 focus:!border-theme-red-300"
            : "!border-theme-gray focus:!ring-gray-900 focus:!border-theme-black-color",
          "rounded-sm !shadow-none"
          // height ?? "h-10"
        )}
      >
        {children}
      </components.Control>
    );
  };
  const inputStyles = {
    singleValue: (styles) => {
      return {
        ...styles,
        paddingTop: "8px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 11,
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        zIndex: 11,
        maxHeight: "200px",
        borderRadius: "4px",
      };
    },
    // indicatorSeparator: (styles) => {
    // 	return {
    // 		...styles,
    // 		display: "none",
    // 	};
    // },
    input: (styles) => {
      return {
        ...styles,
        "input:focus": {
          boxShadow: "none",
        },
      };
    },
  };
  const selectRef = React.useRef();
  return (
    <React.Fragment>
      <div className="relative w-full mt-1 font-medium text-theme-gray-500">
        <div className="relative inline-block w-full text-left bg-transparent">
          <Label
            ref={lableRef}
            labelFor={name}
            className={classNames(
              "absolute z-10 pl-5 pr-12 leading-6 pointer-events-none translate-y-4 md:translate-y-5 text-sm origin-top-left transition-all ease-out line-clamp-1 md:!text-base",
              selectedVal &&
                "translate-x-0 !translate-y-1 md:!translate-y-2 !text-theme-black-color font-light md:!text-xs line-clamp-1",
              selectedVal ? "!opacity-100" : "!opacity-0"
            )}
          >
            {placeholder}
          </Label>
          <Select
            ref={selectRef}
            classNamePrefix="select"
            className="mt-1"
            classNames={{
              menuList: () => "!divide-y !divide-gray-100",
            }}
            value={selectedVal}
            placeholder={placeholder}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name={name}
            onChange={(val) => {
              setFieldValue(name, val ? val[idKey] : null);
            }}
            getOptionLabel={(e) => e[displayKey]}
            styles={inputStyles}
            components={{
              DropdownIndicator,
              Option: OptionComponent,
              Control: ControlComponent,
            }}
            options={filteredOptions}
          />
        </div>
        <ShowFormError name={name} />
      </div>
    </React.Fragment>
  );
};

AuthDropdown.propTypes = {
  displayCurrentOption: PropTypes.bool,
  displayKey: PropTypes.string,
  idKey: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  setCategoryAttribute: PropTypes.func,
  size: PropTypes.any,
};

export default AuthDropdown;
