import PropTypes from "prop-types";
import ResourceCard from "../../../../stories/ResourceCard";
import Text from "../../../../stories/Text";
import _ from "lodash";

const ResourcesTab = ({ programUsefulLinksData, programResourcesData }) => {
  return (
    <div className="space-y-5 ">
      {_.isEmpty(programResourcesData) && (
        <Text
          type="text"
          className="!text-base text-theme-black-color font-semibold"
          content="No downloadable resources available"
        />
      )}
      {!_.isEmpty(programResourcesData) && (
        <div>
          <Text
            type="text"
            className="!text-lg text-theme-black-color font-semibold"
            content="Downloadable Resources"
          />
          <div className="flex flex-wrap py-3">
            {programResourcesData?.map((resource, index) => (
              <div key={resource.id} className="w-full my-2 mr-6 md:max-w-md">
                <ResourceCard
                  title={resource.title}
                  type={resource?.attachment?.file_type ?? "FILE"}
                  downloadLink={resource?.attachment?.url ?? ""}
                  id={resource.id}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* uncomment below code to show usefull links on portal */}
      {/* {!_.isEmpty(programUsefulLinksData) && (
        <div className="space-y-5">
          <Text
            type="text"
            size="text-base md:text-lg"
            className="font-semibold text-theme-black-color"
            content="Useful Links"
          />
          {programUsefulLinksData?.map((links, index) => (
            <Text
              type="text"
              size="text-sm md:text-base"
              bulletPoint={true}
              className="font-semibold text-theme-black-color"
              content={
                <div>
                  <span>{links.title + " - "}</span>
                  <a
                    href="links.link"
                    className="text-sm font-normal text-blue-600"
                  >
                    {links.online_link}{" "}
                  </a>
                </div>
              }
            />
          ))}
        </div>
      )} */}
    </div>
  );
};

ResourcesTab.propTypes = {
  programResourcesData: PropTypes.array,
  programUsefulLinksData: PropTypes.array,
};

export default ResourcesTab;
