/* eslint-disable no-useless-escape */
import PropTypes from "prop-types";
import { Circle, File, User, X } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";
import { ContentBox } from "./CCContent";
import forum_comment from "../assets/svgs/forum_comment.svg";
import fileUploadManager from "../api/fileUploadManager";
import moment from "moment";
import _ from "lodash";
import { ForumService } from "../services/ForumService";
import { MentionsInput, Mention } from "react-mentions";
import { PublicProfileService } from "../services/PublicProfileService";
import ImageComponent from "./ImageComponent";
import CustomLink from "./Link";
import { CustomToast } from "./Toast";
import mixpanel from "mixpanel-browser";
import { useProfile } from "../context/userProvider";
import { getDirectoryMemberURL } from "../util/getURLs";

const fetchUsers = (query, callback) => {
  if (!query) return;
  PublicProfileService.searchFirmUsers({
    per_page: 10,
    page: 1,
    search: query,
  })
    .then((res) =>
      res.users.map((user) => ({
        display: `${user.first_name} ${user.last_name}`,
        id: user.profile_slug,
      }))
    )
    .then(callback);
};

export const ForumCommentAndReplies = ({ comment = {}, forum_id }) => {
  const [newComments, setNewComments] = useState([]);
  const { userDetails } = useProfile();
  return (
    <>
      <ForumComment
        data={comment}
        forum_id={forum_id}
        onAddNewReply={(comment) => {
          setNewComments((prev) => [...prev, comment]);
          mixpanel.track("Exchange Forum Comment", {
            distinct_id: userDetails.email,
            "Forum ID": forum_id,
            Comment: comment.content,
          });
        }}
      />
      <div className="flex flex-col gap-6 pl-5 my-4 lg:mt-6 lg:pl-16">
        {[...(newComments ?? []), ...(comment.replies ?? [])]?.map((reply) => (
          <ForumComment
            key={reply.id}
            forum_id={forum_id}
            data={reply}
            hideReplyBox={true}
          />
        ))}
      </div>
    </>
  );
};

ForumCommentAndReplies.propTypes = {
  comment: PropTypes.object,
  forum_id: PropTypes.any,
};

const ForumComment = ({
  data = {},
  forum_id,
  hideReplyBox = false,
  onAddNewReply = () => {},
}) => {
  const [showReplyBox, setShowReplyBox] = useState(false);
  return (
    <>
      <div className="flex flex-col gap-3 p-3 bg-white lg:p-6 lg:gap-4">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-full bg-theme-black-300/20">
            <CustomLink
              textColor="text-blue-600"
              activeTextColor="text-blue-600"
              showUnderLine={false}
              underLineOnlyOnHover={false}
              fontSize="sm"
              target="_blank"
              href={getDirectoryMemberURL(data?.profile_slug)}
              className="font-normal leading-5 tracking-tight h-fit"
            >
              <ImageComponent
                src={data?.profile_picture}
                errorComponent={
                  <div className="flex items-center justify-center w-8 h-8  min-w-[32px] bg-gray-200 rounded-full">
                    <User weight="bold" className="w-6 h-6 text-gray-400" />
                  </div>
                }
                alt="user"
                className="object-cover w-8 h-8 min-w-[32px] rounded-full"
              />
            </CustomLink>
          </div>
          <div className="flex flex-col gap-1">
            <div className="block text-xs leading-none lg:text-sm">
              <span className="text-theme-black-color">Posted by</span>{" "}
              <a
                href={getDirectoryMemberURL(data?.profile_slug)}
                className="font-medium text-blue-600"
              >
                {data.first_name} {data.last_name}
              </a>
            </div>
            <div className="block leading-none text-xxs lg:text-xs">
              {data.is_pro_member && (
                <>
                  <span className="inline-block font-medium text-theme-red">
                    ProMember
                  </span>{" "}
                  <Circle
                    size={4}
                    weight="fill"
                    className="inline-block mx-1 text-theme-gray min-w-[4px]"
                  />
                </>
              )}
              <span className="inline-block text-theme-gray-600">
                {moment(data.created_at).fromNow()}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full border-t border-gray-100"></div>
        <div className="flex flex-col gap-2 pt-1 lg:pt-0">
          <div className="text-sm lg:text-base text-theme-black-300">
            {data.content
              ?.replace(/@\[.*?\]\((.*?)\)/g, "@$1")
              .split(" ")
              .map((word, index) => {
                const urlRegex =
                  /^(?!.*(?:\.\.|\-\-))(?:https?:\/\/)?([0-9a-z.-]+\.[a-z]{2,})(?:\/|\?|'|"|:|&|\s|,|`|#|~|}|\*|$)/gi;
                if (word[0] === "@")
                  return (
                    <CustomLink
                      key={`${word}-${index}`}
                      textColor="text-theme-orange-400"
                      activeTextColor="text-theme-orange-400"
                      showUnderLine={false}
                      underLineOnlyOnHover={false}
                      fontSize="lg"
                      target="_blank"
                      href={getDirectoryMemberURL(word.replace("@", ""))}
                      className="font-normal leading-5 tracking-tight h-fit"
                    >
                      {word}{" "}
                    </CustomLink>
                  );
                if (urlRegex.test(word))
                  return (
                    <CustomLink
                      key={`${word}-${index}`}
                      target="_blank"
                      textColor="text-theme-orange-400"
                      href={word.includes("http") ? word : `https://${word}`}
                    >
                      {word}&nbsp;
                    </CustomLink>
                  );
                return word + " ";
              })}
          </div>
          {!_.isEmpty(data.attachments) &&
            data.attachments.map((attachment, index) => {
              return (
                <ContentBox
                  key={index}
                  type={attachment.file_type}
                  src={attachment.signed_url}
                />
              );
            })}
        </div>
        {!hideReplyBox && (
          <>
            <div className="w-full border-t border-gray-100"></div>
            <div
              className="flex items-center justify-start cursor-pointer text-xxs lg:text-xs text-theme-black-color"
              onClick={() => {
                setShowReplyBox(true);
              }}
            >
              <img
                src={forum_comment}
                className="w-4 h-4 inline-block mr-1.5"
                alt="Comment button"
              />
              <span className="text-theme-black-300 ml-0.5"> Reply</span>
            </div>
          </>
        )}
      </div>
      {showReplyBox && (
        <ForumReplyBox
          forum_id={forum_id}
          parentCommentId={data.id}
          onHideReplyClick={() => setShowReplyBox(false)}
          afterInsertCommentCallback={(comment) => {
            onAddNewReply(comment);
            setShowReplyBox(false);
          }}
        />
      )}
    </>
  );
};

ForumComment.propTypes = {
  data: PropTypes.object,
  forum_id: PropTypes.any,
  hideReplyBox: PropTypes.bool,
  onAddNewReply: PropTypes.func,
};

const ShowFile = ({ file }) => {
  return (
    <div className="flex items-center gap-1">
      <File weight="fill" className="text-theme-orange w-4 h-4 min-w-[16px]" />
      {file.name}
    </div>
  );
};

ShowFile.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.any,
  }),
};

export const ForumReplyBox = ({
  forum_id,
  parentCommentId,
  onHideReplyClick,
  afterInsertCommentCallback = () => {},
}) => {
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef();
  const onSubmitReply = async () => {
    try {
      const promisesToAwait = [];
      [...files]?.forEach((file, index) => {
        promisesToAwait.push(
          fileUploadManager({
            file: file,
            key: "forum-comment-attachment",
          })
        );
      });
      const responses = await Promise.all(promisesToAwait);
      const data = {
        exchange_forum_id: forum_id,
        content: comment,
        attachments_attributes: responses.map((val) => ({
          file_type: val.file_type,
          attachment_type: "exchange_forum.comment",
          url: val.signed_url,
        })),
      };
      if (parentCommentId) {
        data["forum_comment_id"] = parentCommentId;
      }
      const response = !parentCommentId
        ? await ForumService.createComment({ data })
        : await ForumService.createCommentReply({ data });
      afterInsertCommentCallback(
        response.forum_comment || response.forum_comment_reply
      );
      CustomToast.success("Comment added successfully.");
      setComment("");
      setFiles([]);
      fileInputRef.current.value = "";
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <div className="flex flex-col gap-3 p-3 bg-white lg:p-6 lg:gap-4">
      <div className="flex justify-between text-sm font-medium leading-4">
        Add your response{" "}
        {onHideReplyClick && (
          <div
            className="flex items-center gap-1 cursor-pointer text-theme-orange"
            onClick={() => onHideReplyClick()}
          >
            <X className="w-4 h-4 min-w-[16px]" />
            Close
          </div>
        )}
      </div>
      <MentionsInput
        value={comment}
        onChange={(e, newValue, mentions) => {
          setComment(newValue);
        }}
        singleLine={false}
        className="mentions-input-container"
        style={{ height: "120px", suggestions: { top: "16px" } }}
        placeholder=""
        a11ySuggestionsListLabel={""}
      >
        <Mention
          displayTransform={(login) => `@${login}`}
          trigger="@"
          data={fetchUsers}
        />
      </MentionsInput>
      <div className="flex flex-col items-start w-full gap-1 text-sm text-theme-black-300">
        {[...files].map((file, index) => (
          <ShowFile key={index} file={file} />
        ))}
      </div>
      <input
        type="file"
        multiple={false}
        ref={fileInputRef}
        name="attachments"
        // files={files}
        accept="application/pdf,audio/*,video/*,image/*"
        className="hidden"
        onChange={(e) => {
          setFiles(e.target.files);
        }}
      />
      <div className="flex gap-2.5 justify-end w-full">
        <button
          className="w-full h-10 uppercase border rounded lg:w-48 text-theme-orange border-theme-orange"
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          ADD ATTACHMENTS
        </button>
        <button
          className="w-full h-10 text-white uppercase rounded lg:w-48 bg-theme-orange disabled:bg-theme-orange/70"
          onClick={onSubmitReply}
          disabled={comment.length === 0 && files.length === 0}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

ForumReplyBox.propTypes = {
  afterInsertCommentCallback: PropTypes.func,
  forum_id: PropTypes.any,
  onHideReplyClick: PropTypes.func,
  parentCommentId: PropTypes.any,
};

export default ForumComment;
