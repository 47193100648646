import PropTypes from "prop-types";
import classNames from "classnames";
import { Circle } from "@phosphor-icons/react";
import React, { useState } from "react";
import StepperProgressBar2 from "../../stories/StepperProgressBar2";
import ProMemberDetailPopup from "./ProMemberDetailPopup";
import _ from "lodash";
import { useNavigate } from "@reach/router";
const DetailsAndStepper = ({
  isNewUser,
  className,
  heading,
  displayPoints,
  stepProps,
}) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className={classNames("flex flex-col ", className)}>
      <div
        className={classNames(
          "lg:max-w-[274px] bg-white p-4 shadow-sm flex flex-col items-start"
        )}
      >
        <div className="text-base font-semibold leading-none lg:text-lg -tracking-tight">
          {heading}
        </div>
        <ul className="mt-3">
          {!displayPoints.loading &&
            _.slice(displayPoints, 0, 2)?.map(
              (e, index) =>
                e && (
                  <li
                    key={index}
                    className={classNames(
                      "flex items-start",
                      displayPoints.length !== 1 &&
                        index + 1 !== displayPoints.length
                        ? "mb-2"
                        : ""
                    )}
                  >
                    <div className="flex">
                      <Circle
                        weight="fill"
                        className="h-5 mr-1.5 text-theme-orange"
                        size={8}
                      />
                    </div>
                    <div className="text-sm font-normal tracking-tighter text-theme-black-300">
                      {e}
                    </div>
                  </li>
                )
            )}
        </ul>
        {displayPoints.length > 2 && (
          <div className="mt-3 text-sm leading-none tracking-tight">
            <span
              className="font-semibold cursor-pointer text-theme-red hover:underline"
              onClick={() => setShowPopup(true)}
            >
              Click here
            </span>{" "}
            for more details
          </div>
        )}
        <StepperProgressBar2
          currentStep={stepProps.currentStep + 1}
          className="hidden mt-6 lg:flex"
          changeStep={stepProps.changeStep}
          steps={stepProps.steps}
        />
        <span
          className="mt-3 text-xs tracking-tight cursor-pointer lg:mt-0 lg:flex text-theme-red hover:underline"
          onClick={() => (isNewUser ? window.history.back() : navigate("/"))}
        >
          {isNewUser ? "Go Back" : "Back to Dashboard"}
        </span>
      </div>
      <div className="flex w-full p-3 mt-4 bg-white lg:hidden">
        <StepperProgressBar2
          currentStep={stepProps.currentStep + 1}
          // className="mt-6"
          changeStep={stepProps.changeStep}
          steps={stepProps.steps}
        />
      </div>
      {showPopup && (
        <ProMemberDetailPopup
          heading={heading}
          handleClose={() => setShowPopup(false)}
          open={showPopup}
          modalBody={
            <ul className="mt-3">
              {displayPoints.map(
                (e, index) =>
                  e && (
                    <li
                      key={index}
                      className={classNames(
                        "flex items-start",
                        displayPoints.length !== 1 &&
                          index + 1 !== displayPoints.length
                          ? "mb-2"
                          : ""
                      )}
                    >
                      <div className="flex">
                        <Circle
                          weight="fill"
                          className="h-5 mr-1.5 text-theme-orange"
                          size={8}
                        />
                      </div>
                      <div className="flex flex-col text-sm font-normal tracking-tighter text-theme-black-300">
                        {e}
                      </div>
                    </li>
                  )
              )}
            </ul>
          }
        />
      )}
    </div>
  );
};

DetailsAndStepper.propTypes = {
  className: PropTypes.any,
  displayPoints: PropTypes.any,
  heading: PropTypes.any,
  isNewUser: PropTypes.any,
  stepProps: PropTypes.shape({
    changeStep: PropTypes.any,
    steps: PropTypes.any,
    currentStep: PropTypes.number,
  }),
};

export default DetailsAndStepper;
