/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
import { PublicProfileService } from "../../services/PublicProfileService";
import { useProfile } from "../../context/userProvider";
import { X } from "@phosphor-icons/react";
import { Validations } from "../../constants/validations";
import { AuthService } from "../../services/AuthService";
import FormBuilder from "../../stories/FormBuilder";
import _ from "lodash";

const UpdateAddressModal = ({ open, onClose, className }) => {
  const [initialValues, setInitialValues] = useState({
    phone_number: "",
  });
  const submitRef = React.useRef();
  const { userDetails, updateUserData } = useProfile();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState(
    !_.isEmpty(userDetails.address)
      ? [
          {
            id: userDetails?.address?.city_id,
            name: userDetails?.address?.city ?? "",
          },
        ]
      : []
  );

  const fetchStates = async () => {
    const response1 = await AuthService.getCities("states");
    setStates(response1.locations);
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const textInputDetails = [
    {
      label: "Communication Address",
      name: "address_attributes.line_1",
      placeholder: "12/14, Vaju Kotak Marg, Ballard Estate",
      className: "col-span-full",
    },
    {
      label: "State",
      placeholder: "Select state",
      type: "dropdown",
      name: "address_attributes.state_id",
      displayCurrentOption: true,
      options: states,
      displayKey: "name",
      idKey: "id",
      isRequired: false,
      className: "col-span-full",
      onOptionSelect: async ({ value, formProps }) => {
        formProps.setFieldValue("address_attributes.state_id", value["id"]);
        formProps.setFieldValue("address_attributes.state", value["name"]);
        formProps.setFieldValue("address_attributes.city_id", "");
        const response2 = await AuthService.getCities("cities", value["id"]);
        setCities(response2.locations);
      },
    },
    {
      label: "City",
      placeholder: "Select city",
      type: "dropdown",
      name: "address_attributes.city_id",
      displayCurrentOption: true,
      options: cities,
      displayKey: "name",
      idKey: "id",
      className: "col-span-full",
      isRequired: false,
      onOptionSelect: async ({ value, formProps }) => {
        formProps.setFieldValue("address_attributes.city_id", value["id"]);
        formProps.setFieldValue("address_attributes.city", value["name"]);
      },
    },
    {
      label: "Pincode",
      placeholder: "Pincode",
      name: "address_attributes.pin",
      className: "col-span-full",
    },
  ];

  const onFormSubmit = async (values) => {
    try {
      const result = await PublicProfileService.updatePublicProfile(values);
      if (result.message) {
        CustomToast.success(result.message);
        updateUserData();
        onClose();
      }
      return result;
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full p-4 pb-4 mx-2 my-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <X className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="mb-3 text-base font-semibold leading-6 text-theme-black-color"
              >
                Update Address
              </Dialog.Title>
              {initialValues && (
                <FormBuilder
                  validationSchema={
                    Validations.popup.updateAddressValidationSchema
                  }
                  enableReinitialize
                  inputFieldsDetails={textInputDetails}
                  initialValues={{
                    address_attributes: userDetails.address ?? {
                      line_1: "",
                      pin: "",
                    },
                  }}
                  submitButtonRef={submitRef}
                  onFormSubmit={onFormSubmit}
                  formClassName="grid w-full grid-cols-2 gap-x-3 gap-y-3 lg:gap-y-5 lg:gap-x-6 items-end"
                />
              )}
              <div className="flex flex-col items-end w-full mt-4">
                <Button
                  buttonStyle="primary"
                  type="submit"
                  size="md"
                  height="36px"
                  width="100px"
                  onClick={() => {
                    submitRef.current.click();
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

UpdateAddressModal.defaultProps = {
  open: false,
  className: "",
};

UpdateAddressModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default UpdateAddressModal;
