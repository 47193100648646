import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "@phosphor-icons/react";
import Loader from "../Shimmer/Loader";
import classNames from "classnames";
import ShowEventCard from "../Register/ShowEventCard";
import { CourseService } from "../../services/CourseService";

const MembershipRegistrationModal = ({ open, prefillData, onClose }) => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMembership = async () => {
    setLoading(true);
    const response = await CourseService.fetchActiveMemberships();
    setPrograms([...response.pro_member_courses, ...response.qpfp_courses]);
    setLoading(false);
  };
  useEffect(() => {
    fetchMembership();
  }, []);

  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full p-8 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex justify-between text-2xl font-bold md:text-3xl text-theme-black-color"
                >
                  Register for NFP Membership
                </Dialog.Title>
                {loading && <Loader />}
                <div
                  className={classNames(
                    "flex flex-col w-full",
                    loading && "hidden"
                  )}
                >
                  <div className="flex mt-2 mb-6 text-base font-light leading-5 md:text-lg text-theme-black-300">
                    Register for NFP ProMemberhip & get UpSkill Series Free!
                  </div>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    {programs.map((program, index) => {
                      return (
                        <ShowEventCard
                          key={program.id}
                          program_type={program.course_type}
                          program={program}
                          index={index}
                          prefillData={prefillData}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

MembershipRegistrationModal.defaultProps = {
  open: false,
  className: "",
};

MembershipRegistrationModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  prefillData: PropTypes.any,
};

export default MembershipRegistrationModal;
