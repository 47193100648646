/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import PublicProfileCard from "../../stories/PublicProfileCard";
import EmptyData from "./EmptyData";
import { PublicProfileService } from "../../services/PublicProfileService";
import _ from "lodash";
import fileUploadManager from "../../api/fileUploadManager";
// import { Buildings } from "@phosphor-icons/react";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";
// import ImageComponent from "../../stories/ImageComponent";
import FormBuilder from "../../stories/FormBuilder";
import { Validations } from "../../constants/validations";
import { Buildings } from "@phosphor-icons/react";
import ImageComponent from "../../stories/ImageComponent";
import { useProfile } from "../../context/userProvider";
import { AuthService } from "../../services/AuthService";

const FirmInfoCard = ({
  className,
  publicProfileData = {},
  options = {},
  isLoading,
  onUpdate = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const submitRef = React.useRef();
  const cancelRef = React.useRef();

  const [address, setAddress] = useState("");
  const { userDetails } = useProfile();
  const [states, setStates] = useState([]);

  // const fetchFirmList = async () => {
  //   const response = await PublicProfileService.searchFirms("");
  //   setFirmList(response?.response?.firms ?? []);
  // };
  const fetchStates = async () => {
    const response1 = await AuthService.getCities("states");
    setStates(response1.locations);
  };

  useEffect(() => {
    // fetchFirmList();
    fetchStates();
  }, []);

  useEffect(() => {
    setInitialValues({
      firmId: publicProfileData?.firm?.id ?? -1,
      firmImage: publicProfileData?.firm?.firm_logo?.signed_url ?? null,
      firmName: publicProfileData?.firm?.name ?? "",
      firmWebsite: publicProfileData?.firm?.website ?? "",
      addressLine1: publicProfileData?.firm?.address?.line_1 ?? "",
      city: publicProfileData?.firm?.address?.city ?? "",
      city_id: publicProfileData?.firm?.address?.city_id ?? "",
      state: publicProfileData?.firm?.address?.state ?? "",
      state_id: publicProfileData?.firm?.address?.state_id ?? "",
      pincode: publicProfileData?.firm?.address?.pin ?? "",
      is_corporate: false,
      options: {
        cities: [
          {
            id: publicProfileData?.firm?.address?.city_id,
            name: publicProfileData?.firm?.address?.city,
          },
        ],
      },
    });
    if (publicProfileData.firm?.address) {
      setAddress((_) => {
        let s = [];
        s = [...s, publicProfileData.firm?.address?.line_1 ?? ""];
        s = [...s, publicProfileData.firm?.address?.city ?? ""];
        s = [...s, publicProfileData.firm?.address?.state ?? ""];
        s = [...s, publicProfileData.firm?.address?.pin ?? ""];
        return s.join(", ");
      });
    }
  }, [publicProfileData]);

  const textInputDetails = [
    // Uncomment below code to display firmImage Upload in frontend
    // {
    //   name: "firmImage",
    //   type: "avatarImgUpload",
    //   isRequired: false,
    //   ErrorComponent: () => (
    //     <Buildings
    //       size={40}
    //       weight="bold"
    //       className="text-theme-black-300"
    //     />
    //   ),
    // },
    {
      label: "Firm Name",
      name: "firmName",
      placeholder: "Firm",
      className: "lg:min-w-186",
      type: "text",
      isRequired: true,
      isHidden: (values) => values.is_corporate,
    },
    {
      label: "Firm Website",
      name: "firmWebsite",
      placeholder: "www.xyz.com",
      className: "lg:min-w-186",
      type: "text",
      isRequired: false,
    },
    {
      label: "Address Line",
      name: "addressLine1",
      placeholder: "12/14, Vaju Kotak Marg, Ballard Estate",
      className: "lg:min-w-186 col-span-full",
      type: "text",
    },
    {
      label: "State",
      placeholder: "Select state",
      type: "dropdown",
      name: "state_id",
      displayCurrentOption: true,
      options: states,
      displayKey: "name",
      idKey: "id",
      className: "lg:min-w-186",
      isRequired: false,
      isViewOnly: !isEditing,
      onOptionSelect: async ({ value, formProps }) => {
        formProps.setFieldValue("state_id", value["id"]);
        formProps.setFieldValue("state", value["name"]);
        formProps.setFieldValue("city_id", "");
        const response2 = await AuthService.getCities("cities", value["id"]);
        formProps.setFieldValue("options.cities", response2.locations);
      },
    },
    {
      label: "City",
      placeholder: "Select city",
      type: "dropdown",
      name: "city_id",
      displayCurrentOption: true,
      options: [],
      optionFormFormikName: "cities",
      displayKey: "name",
      idKey: "id",
      className: "lg:min-w-186",
      isRequired: false,
      isViewOnly: !isEditing,
    },
    {
      label: "Pincode",
      name: "pincode",
      placeholder: "400001",
      className: "lg:min-w-186 col-span-full",
      maxLength: 6,
      type: "number",
    },
  ];

  const handleSubmit = async (data) => {
    try {
      let firmLogoResponse;
      const postBody = {
        firm_attributes: {
          name: data.firmName,
          website: data.firmWebsite,
          address_attributes: {
            line_1: data.addressLine1,
            pin: data.pincode,
          },
        },
      };
      if (data.city_id !== undefined || data.city_id !== null) {
        postBody.firm_attributes["address_attributes"]["city_id"] =
          data.city_id;
      }
      if (
        publicProfileData.firm?.address?.id !== undefined ||
        publicProfileData.firm?.address?.id !== null
      ) {
        postBody.firm_attributes["address_attributes"]["id"] =
          data.firmId !== -1 && data?.address?.addressId;
      }

      if (
        typeof data.firmImage !== "string" &&
        typeof data.firmImage !== "undefined" &&
        data.firmImage !== null
      ) {
        firmLogoResponse = await fileUploadManager({
          file: data.firmImage,
          key: "firm-logos",
        });
        postBody.firm_attributes["logo_attributes"] = {
          url: firmLogoResponse.signed_url,
          file_type: "image",
          attachment_type: "logo",
          id: data.firmId !== -1 && publicProfileData?.firm?.logo?.id,
        };
      }

      if (data.firmId !== -1) {
        postBody.firm_attributes["id"] = data.firmId;
      }

      try {
        const result = await PublicProfileService.updateFirmInfo(postBody);
        if (result.message) {
          CustomToast.success(result.message);
          onUpdate();
          setIsEditing(false);
        }
      } catch (e) {
        CustomToast.error(e.error_messages[0]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setIsEmpty(_.isEmpty(publicProfileData?.firm));
  }, [initialValues]);

  return (
    <div className={classNames("", className)}>
      <PublicProfileCard
        heading="Firm Info"
        subHeading="Update your Firm Info here."
        isEditing={isEditing}
        isLoading={isLoading}
        id="firm-details-card"
        setIsEditing={setIsEditing}
        submitButtonRef={submitRef}
        cancelButtonRef={cancelRef}
        isEmpty={!isEditing && isEmpty}
        lockedIfCorporateAccount={true}
        promemberValidationMessage={
          publicProfileData.firm?.firm_admin_id === userDetails.id
            ? "You are part of a corporate profile. Please go to your corporate profile and update these details"
            : "You are part of a corporate profile. Please contact your admin to update these details."
        }
        cardBody={
          !isEditing && isEmpty ? (
            <EmptyData
              heading="Add Firm Information"
              message="To see firm information here, add it by clicking on the below button."
              cta={
                <Button
                  buttonStyle="primary"
                  width="100px"
                  height="32px"
                  className="mt-2"
                  onClick={() => {
                    document
                      .querySelector(`#firm-details-card`)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    setIsEditing(true);
                  }}
                >
                  + Add
                </Button>
              }
            />
          ) : (
            <div className="w-full mt-5">
              {isEditing ? (
                <div className="flex ">
                  <FormBuilder
                    initialValues={initialValues}
                    validationSchema={
                      Validations.publicProfile.firmInfoValidationSchema
                    }
                    onFormSubmit={handleSubmit}
                    submitButtonRef={submitRef}
                    cancelButtonRef={cancelRef}
                    inputFieldsDetails={textInputDetails}
                    formClassName="grid w-full grid-cols-2 gap-y-5 gap-x-6"
                  />
                </div>
              ) : (
                <div className="flex flex-col items-start w-full">
                  <div className="flex justify-start w-full mt-4">
                    <div className="flex flex-col min-w-[90px] lg:min-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                      Firm
                    </div>
                    <div className="flex flex-col w-full max-w-[231px] text-sm text-theme-black-color tracking-tight text-left font-medium">
                      <div className="flex space-x-3">
                        {/* Uncomment below code to display firmImage Upload in frontend */}
                        <div className="flex items-center justify-center w-8 h-8 text-white rounded-full bg-theme-gray-300">
                          {initialValues.firmImage ? (
                            <ImageComponent
                              src={initialValues.firmImage}
                              className="object-cover w-full h-full rounded-full"
                            />
                          ) : (
                            <Buildings
                              size={18}
                              weight="bold"
                              className="text-theme-black-300"
                            />
                          )}
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="text-sm font-normal tracking-tight text-left text-theme-black-color">
                            {initialValues?.firmName}
                          </div>
                          <div className="text-sm font-normal leading-none text-left text-neutral-400">
                            {initialValues.firmWebsite}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-between w-full mt-7">
                    <div className="flex flex-col min-w-[90px] lg:min-w-[153px] max-w-[153px] text-sm text-theme-gray-600 tracking-tight text-left font-medium">
                      Firm Address
                    </div>
                    <div className="flex flex-col w-full text-sm font-normal tracking-tight text-left text-theme-black-color">
                      {address}
                    </div>
                  </div>
                </div>
              )}{" "}
            </div>
          )
        }
      />
    </div>
  );
};

FirmInfoCard.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdate: PropTypes.func,
  options: PropTypes.object,
  publicProfileData: PropTypes.object,
};

export default FirmInfoCard;
