import { createContext, useContext, useMemo, useState } from "react";

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const TourContext = createContext({
  state: appState,
  setState: () => undefined,
});
TourContext.displayName = "TourContext";

export const TourProvider = (props) => {
  const [state, setState] = useState(appState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state]
  );

  return <TourContext.Provider value={value} {...props} />;
};

export const useTourContext = () => {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error("useTourContext must be used within a TourProvider");
  }

  return context;
};
