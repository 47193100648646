/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import application_submitted from "../../../assets/svgs/application_submitted.svg";
import Button from "../../../stories/Button";
import { confettiAnimation } from "../../../util/confettiAnimation.js";

const ThankYouScreen = ({ stepProps, className, ...props }) => {
  const confettiCanvas = useRef();

  useEffect(() => {
    if (confettiCanvas.current) {
      confettiAnimation(confettiCanvas.current);
    }
  }, [confettiCanvas]);

  return (
    <div className={classNames("flex", className)}>
      <div
        className={classNames(
          "relative max-w-[764px] lg:min-w-3xl w-full flex flex-col items-center pt-4 pb-3 px-4 lg:p-4 bg-white rounded-sm shadow-sm "
        )}
      >
        <div className="max-w-xl text-2xl font-bold tracking-tight text-center lg:font-semibold lg:tracking-normal text-theme-black-color">
          Your application has been submitted successfully
        </div>
        <div className="max-w-lg px-1 mt-3 text-base leading-5 text-center text-theme-black-300">
          NFP admin shall review your application for eligibility and documents.
          If approved, your membership will be activated within 3 working days.
        </div>
        <img
          src={application_submitted}
          alt="thank_you_img"
          className="my-6 lg:mt-10"
          width={251}
          height={214}
        />

        <Button
          buttonStyle="primary"
          className="mt-10"
          height="40px"
          width="354px"
          onClick={async () => {
            stepProps.handleNext();
          }}
        >
          <span className="text-sm font-semibold leading-4 -tracking-tight">
            {props.isNewUser ? "CONTINUE TO LOGIN" : "CONTINUE TO DASHBOARD"}
          </span>
        </Button>
        {props.isNewUser && (
          <div className="text-sm font-semibold leading-4 -tracking-tight">
            Please check your email id {props.email}. We have sent an email to
            reset your password. Complete your sign-up process to access your
            account.
          </div>
        )}
        <canvas ref={confettiCanvas} className="absolute z-50 w-full h-full" />
      </div>
    </div>
  );
};

ThankYouScreen.propTypes = {
  className: PropTypes.string,
  stepProps: PropTypes.shape({
    handleNext: PropTypes.func,
  }),
};

export default ThankYouScreen;
