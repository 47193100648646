import PropTypes from "prop-types";
import Text from "../../../../stories/Text";
import _ from "lodash";

const OverviewTab = ({
  courseTitle,
  programHighlightsData,
  courseDescription,
}) => {
  return (
    <div className="pb-10 space-y-8">
      <div className="flex flex-col">
        <Text
          type="text"
          size="text-sm md:text-base"
          className="font-semibold text-theme-black-color"
          content={courseTitle}
        />
        <Text
          type="text"
          size="text-sm md:text-base"
          className="text-gray-600"
          content={courseDescription}
          isMultiline
        />
      </div>
      {!_.isEmpty(programHighlightsData) && (
        <div className="space-y-5">
          <Text
            type="text"
            size="text-sm md:text-base"
            className="font-semibold text-theme-black-color"
            content={`Highlights of ${courseTitle ?? "program"}`}
          />
          {programHighlightsData?.map((highlight, index) => (
            <Text
              key={highlight.id}
              type="text"
              size="text-sm md:text-base"
              bulletPoint={true}
              className="font-semibold text-theme-black-color"
              content={highlight.name}
              isSubTextMultiline
              subtext={highlight.description}
              subtextclass="text-sm md:text-base text-neutral-600 font-normal"
            />
          ))}
        </div>
      )}
    </div>
  );
};

OverviewTab.propTypes = {
  courseDescription: PropTypes.string,
  courseTitle: PropTypes.string,
  programHighlightsData: PropTypes.array,
};

export default OverviewTab;
