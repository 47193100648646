import AccordionComponent from "./AccordionComponent";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";
import CheckBox from "./CheckBox";
import { Circle } from "@phosphor-icons/react";

const CorporateSubscriptionAccordian = ({
  item,
  index,
  formikProps = {},
  selectedQPFPModules,
  onClickSelectModule,
  removeSelectModule,
  disableCheckboxIndex = [],
  onSelectChange,
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full p-2 bg-white md:p-4",
        className
      )}
    >
      <div className="flex h-full p-4 border">
        <AccordionComponent
          Heading={() => (
            <AccordianHeading
              data={item}
              index={index}
              onSelectChange={onSelectChange}
              formikProps={formikProps}
              disableCheckboxIndex={disableCheckboxIndex}
            />
          )}
          Children={(open) => (
            <AccordianChildren
              data={item}
              selectedQPFPModules={selectedQPFPModules}
              onClickSelectModule={onClickSelectModule}
              removeSelectModule={removeSelectModule}
            />
          )}
          CloseButton={
            <button className="w-full px-6 pt-2 text-sm font-semibold text-center border-t text-theme-black-color">
              HIDE DETAILS
            </button>
          }
        />
      </div>
      <div className="hidden mt-1 text-xs leading-none tracking-tight max-h-3 md:flex text-theme-gray-500">
        {item.payment_status_hint}
      </div>
    </div>
  );
};

const AccordianHeading = ({
  data,
  index,
  onSelectChange,
  formikProps = {},
  disableCheckboxIndex = [],
}) => {
  return (
    <div className="flex items-center gap-3 text-base leading-none tracking-tight text-theme-black-color ">
      <CheckBox
        labelClassName="font-semibold"
        name={`select_row.${index}`}
        errorMessage=""
        errorColor=""
        className=""
        checked={formikProps.values?.select_row[index]}
        value={formikProps.values?.select_row[index]}
        disabled={disableCheckboxIndex.includes(index)}
        onChange={(event) => {
          if (onSelectChange) {
            formikProps.setFieldValue(
              `select_row.${index}`,
              event.target.checked
            );
            onSelectChange({
              event,
              rowIndexInTable: index,
              rowIndexInData: index,
              rowValue: data,
              formikProps: formikProps,
            });
          } else {
            formikProps.setFieldValue(
              `select_row.${index}`,
              event.target.checked
            );
          }
        }}
        type="checkbox"
      />
      <div>
        <DataComponent title="MEMBER" data={data.user_full_name} />
        <div className="flex-row items-center hidden mt-1 text-xs leading-5 tracking-tight md:flex text-theme-black-300">
          {data.subscription && (
            <div className="flex text-xs leading-none tracking-tight text-theme-gray-500">
              {data.subscription}
            </div>
          )}
          {data.subscription !== "Free" && (
            <span className="w-1 h-1 mx-1 rounded-full bg-theme-gray-400"></span>
          )}
          {data.subscription !== "Free" && (
            <div
              className={classNames(
                "flex text-xs leading-none tracking-tight",
                data.payment_status === "Paid"
                  ? "text-theme-gray-500"
                  : data.payment_status === "Overdue"
                  ? "text-theme-red-300"
                  : "text-yellow-500"
              )}
            >
              {data.payment_status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const AccordianChildren = ({
  data,
  index,
  selectedQPFPModules,
  onClickSelectModule,
  removeSelectModule,
}) => {
  const SelectQPFPModule = () => {
    if (
      data.course_type !== "qpfp" ||
      (!["Pending", "Overdue", "Expires Soon"].includes(
        data.payment_status ?? data.status
      ) &&
        data.payment_details?.available_part_payment_option?.length < 1)
    )
      return null;

    return (
      <DataComponent
        title="SELECT QPFP MODULE"
        data={(() => {
          const module =
            selectedQPFPModules[
              `${data.entity_details.entity_type}_${data.entity_details.entity_id}`
            ];
          if (module) {
            return (
              <div className="flex flex-col">
                <div className="text-sm text-theme-black-color">
                  {module.label}
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <div
                    className="cursor-pointer text-theme-red"
                    onClick={() =>
                      onClickSelectModule({ rowValue: data, rowIndex: index })
                    }
                  >
                    EDIT
                  </div>
                  <Circle size={4} weight="fill" />
                  <div
                    className="cursor-pointer text-theme-red"
                    onClick={() =>
                      removeSelectModule({ rowValue: data, rowIndex: index })
                    }
                  >
                    REMOVE
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div
              className="text-sm cursor-pointer text-theme-red"
              onClick={() =>
                onClickSelectModule({ rowValue: data, rowIndex: index })
              }
            >
              SELECT MODULE
            </div>
          );
        })()}
      />
    );
  };

  return (
    <div className="flex flex-col justify-between h-full py-4 md:pb-0">
      <div className="grid grid-cols-2 gap-4 pb-4 xl:grid-cols-3">
        <DataComponent
          title="PROGRAM NAME"
          data={data.name ?? data.course?.course_name}
        />
        <DataComponent
          title="COURSE TYPE"
          data={(data.course_type ?? data.course?.course_type ?? "")
            .toUpperCase()
            .replace("_", " ")}
        />
        {data.subscription && (
          <DataComponent title="SUBSCRIPTION TYPE" data={data.subscription} />
        )}
        {data.status && (
          <DataComponent
            title="STATUS"
            data={(() => {
              switch (data.status) {
                case "Paid":
                  return <span className="text-sm text-green-600">Paid</span>;
                case "Overdue":
                  return (
                    <span className="text-sm text-theme-red">Overdue</span>
                  );
                case "pending":
                  return (
                    <span className="text-sm text-theme-yellow">
                      Payment Pending
                    </span>
                  );
                case "Free":
                  return (
                    <span className="text-sm text-theme-orange">Free</span>
                  );
                default:
                  return (
                    <span className="text-sm text-theme-black-color">-</span>
                  );
              }
            })()}
          />
        )}
        {data.payment_status && (
          <DataComponent
            title="STATUS"
            data={(() => {
              switch (data.payment_status) {
                case "Paid":
                  return <span className="text-sm text-green-600">Paid</span>;
                case "Overdue":
                  return (
                    <span className="text-sm text-theme-red">Overdue</span>
                  );
                case "Expires Soon":
                  return (
                    <span className="text-sm text-theme-red">Expires Soon</span>
                  );
                case "Payment pending":
                  return (
                    <span className="text-sm text-theme-yellow">
                      Payment Pending
                    </span>
                  );
                default:
                  return (
                    <span className="text-sm text-theme-black-color">
                      {data.subscription_status}
                    </span>
                  );
              }
            })()}
          />
        )}
        {data.due_date && (
          <DataComponent
            title="NEXT DUE DATE"
            data={moment(data.due_date, "DD-MM-YYYY").format("DD-MMM-YYYY")}
          />
        )}
        {data.payment_status !== "Free" && (
          <DataComponent
            title="AMOUNT"
            data={`₹ ${
              data.amount
                ? data.amount === 0
                  ? data.amount
                  : data.amount.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      minimumIntegerDigits: 2,
                    })
                : 0
            }`}
          />
        )}
        <SelectQPFPModule />
      </div>
    </div>
  );
};

const DataComponent = ({ title, data }) => {
  return (
    <div className="items-center">
      <div className="text-sm font-normal sm:text-xs text-neutral-600">
        {title}
      </div>
      <div className="text-base font-normal sm:text-sm text-theme-black-color">
        {data}
      </div>
    </div>
  );
};

CorporateSubscriptionAccordian.defaultProps = {
  isDesktop: true,
};

CorporateSubscriptionAccordian.propTypes = {
  MobileViewCard: PropTypes.element,
  className: PropTypes.any,
  disableCheckboxIndex: PropTypes.array,
  formikProps: PropTypes.object,
  index: PropTypes.any,
  isDesktop: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onClickSelectModule: PropTypes.any,
  onPayNowClick: PropTypes.func.isRequired,
  onSelectChange: PropTypes.any,
  removeSelectModule: PropTypes.any,
  selectedQPFPModules: PropTypes.any,
};
AccordianHeading.propTypes = {
  data: PropTypes.object.isRequired,
  disableCheckboxIndex: PropTypes.shape({
    includes: PropTypes.func,
  }),
  formikProps: PropTypes.shape({
    formProps: PropTypes.shape({
      setFieldValue: PropTypes.func,
    }),
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      select_row: PropTypes.any,
    }),
  }),
  index: PropTypes.any,
  onSelectChange: PropTypes.func,
};
AccordianChildren.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.any,
  onClickSelectModule: PropTypes.func,
  onPayNowClick: PropTypes.func.isRequired,
  removeSelectModule: PropTypes.func,
  selectedQPFPModules: PropTypes.any,
};
DataComponent.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
};
export default CorporateSubscriptionAccordian;
