/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import OnboardingRightContainer from "../../stories/OnboardingRightContainer";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "@reach/router";
import OnboardingHeader from "../../stories/OnboardingHeader";
import AuthTextInput from "../../stories/AuthTextInput";
import Button from "../../stories/Button";
import AuthDropdown from "../../stories/AuthDropdown";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { CustomToast } from "../../stories/Toast";
import { useLocationList } from "../../services/BasicDetails";
import { useRef } from "react";
import { User } from "../../store";
import { useProfile } from "../../context/userProvider";
import { Validations } from "../../constants/validations";
import FirmInviteRequestModal from "../Modals/FirmInviteRequestModal";
import { PublicProfileService } from "../../services/PublicProfileService";

const CompleteSignUpScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  useDocumentTitle("Signup");
  const signupScreenRef = useRef();
  const { updateUserData } = useProfile();

  const { locations, loading: loadingLocation } = useLocationList();
  const [showFirmInviteModal, setShowFirmInviteModal] = useState(false);
  const [firmData, setFirmData] = useState({});

  const [initialValues, setInitialValues] = useState({
    fname: "",
    lname: "",
    mobileno: "",
    city: "",
  });

  useEffect(() => {
    if (state && state.token) {
      if (state.user) {
        setInitialValues((prev) => ({
          fname: state.user?.first_name ?? prev.fname,
          lname: state.user?.last_name ?? prev.lname,
          mobileno: state.user?.phone_number ?? prev.mobileno,
          city: state.user?.city_id ?? prev.city,
          description: state.user?.description_id ?? prev.description,
          graduationStatus:
            state.user?.graduation_status_id ?? prev.graduationStatus,
        }));
      }
    } else {
      CustomToast.error("Please complete the first step to register yourself");
      navigate("/signup");
    }
  }, []);

  const handleSignUp = async (data) => {
    try {
      const { token } = state;
      const { fname, lname, mobileno, city } = data;
      const result = await PublicProfileService.updatePublicProfile(
        {
          first_name: fname,
          last_name: lname,
          phone_number: mobileno,
          city_id: city,
        },
        token
      );
      User.setToken(token);
      const decodedData = JSON.parse(window.atob(token.split(".")[1]));
      window.fcWidget?.init({
        token: "238a151e-c5f5-4dc5-8866-6be57b0cfedb",
        host: "https://wchat.in.freshchat.com",
        externalId: decodedData?.data?.unique_id ?? null,
      });
      updateUserData(true);
      if (result?.firm_user?.firm_user_id) {
        setFirmData({
          firm_id: result?.firm_user?.firm_user_id,
          firm_name: result?.firm_user?.firm_name,
        });
        setShowFirmInviteModal(true);
      } else {
        setTimeout(() => navigate("/"), 1000);
        CustomToast.success(result.message);
      }
      return;
    } catch (e) {
      console.log(e);
      if (!e.success) {
        CustomToast.error(e.error_messages[0]);
      } else {
        CustomToast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="flex h-screen max-h-screen overflow-y-auto">
      <div
        ref={signupScreenRef}
        className="flex flex-col w-full bg-white lg:w-2/5 xl:w-1/3"
      >
        <OnboardingHeader />
        <div className="flex px-8 pb-20 mt-10 bg-white md:px-16 lg:mt-20">
          <div className="flex flex-col">
            <div className="flex text-3xl font-bold leading-8 tracking-tight text-left lg:text-5xl text-theme-black-color md:leading-tight">
              Complete Sign Up
            </div>
            <div className="flex mt-6 text-base leading-6 tracking-tight text-left md:leading-5 lg:text-lg text-theme-black-300 ">
              Set-up your free account to access free trainings, create your
              profile & register for all programs and events.
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={() =>
                Validations.onBoarding.completeSignupValidationSchema
              }
              onSubmit={handleSignUp}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <div className="w-full mt-8 md:mt-12">
                      <AuthTextInput
                        name="fname"
                        id="fname"
                        type="text"
                        label="First Name"
                      />
                    </div>
                    <div className="w-full mt-5">
                      <AuthTextInput
                        name="lname"
                        id="lname"
                        type="text"
                        label="Last Name"
                      />
                    </div>
                    <div className="w-full mt-5">
                      <AuthTextInput
                        showLeading
                        name="mobileno"
                        id="mobileno"
                        type="text"
                        label="Mobile No."
                        leadingComponent={
                          <span className="inline-flex items-center px-4 mt-1 text-base text-gray-500 border border-r-0 border-gray-300 bg-gray-50 lg:text-lg">
                            +91
                          </span>
                        }
                      />
                    </div>
                    <div className="w-full mt-5">
                      <AuthDropdown
                        name="city"
                        id="city"
                        type="text"
                        placeholder="City"
                        options={locations}
                        displayCurrentOption
                        displayKey="name"
                        idKey="id"
                      />
                    </div>
                    <Button
                      buttonStyle="primary"
                      height="60px"
                      width="100%"
                      className="w-full mt-6"
                      type="submit"
                      loading={loadingLocation || isSubmitting}
                      disabled={loadingLocation || isSubmitting}
                    >
                      SIGN UP
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div className="hidden h-full lg:flex lg:w-3/5 xl:w-2/3">
        <OnboardingRightContainer className="hidden w-full h-full lg:flex" />
      </div>
      {showFirmInviteModal && (
        <FirmInviteRequestModal
          open={showFirmInviteModal}
          onClose={() => {
            setShowFirmInviteModal(false);
            setFirmData({});
            CustomToast.success("Successfully logged in");
            navigate("/");
          }}
          firmDetails={firmData}
        />
      )}
    </div>
  );
};

export default CompleteSignUpScreen;
