/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { useProfile } from "../../context/userProvider";
import { CustomToast } from "../../stories/Toast";
import AppSkeleton from "../AppSkeleton";
import _ from "lodash";

const CorporateFlow = () => {
  const { userDetails } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!_.isEmpty(userDetails)) return;
    CustomToast.error("Please become a corporate admin to access this page");
    if (!userDetails.firm_id) navigate("/");
  }, [userDetails]);

  return <AppSkeleton></AppSkeleton>;
};

export default CorporateFlow;
