import PropTypes from "prop-types";
import React from "react";

import Button from "../../../stories/Button";
import _ from "lodash";
import moment from "moment";

/**
 * Component for QPFP Certificates and Badges Dashboard Element
 * @return {React.ReactElement} QPFP Certificates and Badges Dashboard Element
 */
function CertsAndBadges({ certificateUrl, validUpto }) {
  const filename = _.head(_.split(_.head(_.split(certificateUrl, "?")), "/"));
  return (
    <div className="flex flex-col space-y-4 items-start relative">
      <img
        src={certificateUrl}
        alt="Your QPFP Certificate"
        className="self-center"
      />
      <p className="font-semibold text-sm">Your QPFP Certification</p>
      <p className="text-sm">
        Download, copy and share your QPFP Certificate and Badges
      </p>
      <div className="self-stretch flex justify-between items-center">
        <a href={certificateUrl} download={filename}>
          <Button className="!rounded-md">Download</Button>
        </a>
        <p className="font-semibold text-xs">
          Validity:
          <span className="font-normal text-gray-500 ml-2">
            Till {moment(validUpto).format("DD MMM YY")}
          </span>
        </p>
      </div>
    </div>
  );
}

CertsAndBadges.propTypes = {
  certificateUrl: PropTypes.string,
  validUpto: PropTypes.string,
};

export default CertsAndBadges;
