import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const StepperProgressBar1 = ({ className, currentStep, steps = [] }) => {
  return (
    <div className={classNames("h-fit lg:h-[74px]", className)}>
      <div className="w-full">
        <ul className="">
          {steps.map((e, index) => {
            return (
              <li
                key={index}
                className={classNames(
                  "flex items-start leading-10 lg:leading-5 lg:list-item list-none float-left w-full  lg:w-1/3 relative text-center text-sm tracking-tight text-theme-black-color counter-increment",
                  "before:counter-result before:leading-10 before:rounded-full before:w-10 before:h-10 before:border-2 before:text-center before:mt-0 lg:before:mx-auto before:mr-2 lg:before:mb-2.5 before:z-10 before:flex before:justify-center before:items-center before:text-base before:font-bold",
                  "after:content-[''] after:absolute after:h-[calc(40px)] after:w-0.5 lg:after:w-[calc(100%-40px)] lg:after:h-0.5 after:left-5 after:top-[calc(-60px+40px/2)] lg:after:top-5 lg:after:left-[calc(-50%+40px/2)]",
                  index + 1 < currentStep
                    ? e === "Application Rejected"
                      ? "active text-theme-red before:text-white before:bg-theme-red before:border-theme-red after:bg-theme-red"
                      : "active text-theme-orange before:text-white before:bg-theme-orange before:border-theme-orange after:bg-theme-orange"
                    : index + 1 === currentStep
                    ? "before:text-theme-orange before:border-theme-orange after:bg-theme-orange"
                    : "active before:bg-white before:text-theme-gray before:border-theme-gray after:bg-theme-gray",
                  index === 0 ? "after:content-none" : "",
                  index + 1 === steps.length ? "h-10" : "h-[80px]"
                )}
              >
                {e}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

StepperProgressBar1.defaultProps = {
  className: "",
};
StepperProgressBar1.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  steps: PropTypes.array,
};

export default StepperProgressBar1;
