import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { CircleNotch } from "@phosphor-icons/react";

const LoadingModal = ({ open }) => {
  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50"
          open={open}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pb-20 text-center p4-4 sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-300 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the Modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block p-4 overflow-hidden text-left align-bottom transition-all transform  rounded sm:my-8 sm:align-middle sm:max-w-[240px] sm:w-full sm:p-6">
                <div className="flex flex-col mt-2">
                  <CircleNotch
                    className="mx-auto text-theme-black-color animate-spin"
                    size={56}
                    weight="fill"
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

LoadingModal.defaultProps = {
  open: false,
};

LoadingModal.propTypes = {
  open: PropTypes.bool,
};

export default LoadingModal;
