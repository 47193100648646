import PropTypes from "prop-types";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import React from "react";
import client_connect_logo from "../../assets/images/client_connect_logo.png";
import protool_logo from "../../assets/images/protool_logo.png";
import resources_logo from "../../assets/images/resources_logo.png";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CaretDown } from "@phosphor-icons/react";
import ModuleCard from "../../stories/ModuleCard";

const MembershipBenefit = ({
  curriculumRef,
  allowUserToSkipRenewal,
  isLocked,
  availablePartPaymentOptions,
  hasMadeFullPayment,
  refreshCallback,
  remainingAmount,
  eventDetails,
  entityType,
  courseId,
  showClientConnect,
  showProtools,
}) => {
  const { width } = useWindowSize();

  if (entityType === "qpfp") return <></>;

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div className="flex flex-col rounded-sm p-3 lg:p-6 bg-white">
          <Disclosure.Button
            as="div"
            className={classNames(
              "flex justify-between text-lg font-bold leading-none bg-transparent focus-visible:outline-none text-theme-black-color",
              width < 1024 && open && "mb-3",
              width >= 1024 && "mb-3"
            )}
          >
            Membership Benefits
            {width < 1024 && (
              <CaretDown
                size={20}
                weight="fill"
                className={
                  open
                    ? "transform duration-300 rotate-180"
                    : "transform duration-300 rotate-0"
                }
              />
            )}
          </Disclosure.Button>
          <Disclosure.Panel
            static={width > 1024}
            className={classNames(
              "mt-1 grid grid-cols-1 gap-3 lg:gap-6 lg:grid-cols-3"
            )}
          >
            {showClientConnect && (
              <ModuleCard
                isClientConnect={true}
                curriculumRef={curriculumRef}
                allowUserToSkipRenewal={allowUserToSkipRenewal}
                isLocked={isLocked}
                availablePartPaymentOptions={availablePartPaymentOptions}
                hasMadeFullPayment={hasMadeFullPayment}
                refreshCallback={refreshCallback}
                remainingAmount={remainingAmount}
                eventDetails={eventDetails}
                entityType={entityType}
                percentCompleted={null}
                firstSessionContentId={""}
                session_thumnail_src={client_connect_logo ?? ""}
                hasCompletedUnlockedModule={true}
                courseDate="Download & share educational images with your clients, prospects and contact."
                courseTitle={"NFP ClientConnect"}
                course_id={courseId}
                href="/nfp-clientconnect"
                status=""
                authorName=""
              />
            )}
            {showProtools && (
              <ModuleCard
                isClientConnect={true}
                curriculumRef={curriculumRef}
                allowUserToSkipRenewal={allowUserToSkipRenewal}
                isLocked={isLocked}
                availablePartPaymentOptions={availablePartPaymentOptions}
                hasMadeFullPayment={hasMadeFullPayment}
                refreshCallback={refreshCallback}
                remainingAmount={remainingAmount}
                eventDetails={eventDetails}
                entityType={entityType}
                percentCompleted={null}
                course_id={courseId}
                session_thumnail_src={protool_logo ?? ""}
                href="/nfp-protools"
                courseDate="Download & use files, formats, calculators & templates for your practice and with your clients."
                courseTitle="NFP ProTools"
                status=""
                authorName=""
              />
            )}
            {showClientConnect && (
              <ModuleCard
                isClientConnect={true}
                curriculumRef={curriculumRef}
                allowUserToSkipRenewal={allowUserToSkipRenewal}
                isLocked={isLocked}
                availablePartPaymentOptions={availablePartPaymentOptions}
                hasMadeFullPayment={hasMadeFullPayment}
                refreshCallback={refreshCallback}
                remainingAmount={remainingAmount}
                eventDetails={eventDetails}
                entityType={entityType}
                percentCompleted={null}
                firstSessionContentId={""}
                hasCompletedUnlockedModule={true}
                course_id={courseId}
                session_thumnail_src={resources_logo ?? ""}
                href="/nfp-resources"
                courseDate="Download & refer reports, reading material, templates from the common library of useful resources for members."
                courseTitle="NFP Resources"
                status=""
                authorName=""
              />
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

MembershipBenefit.propTypes = {
  allowUserToSkipRenewal: PropTypes.any,
  availablePartPaymentOptions: PropTypes.any,
  courseId: PropTypes.any,
  curriculumRef: PropTypes.any,
  entityId: PropTypes.any,
  entityType: PropTypes.any,
  eventDetails: PropTypes.any,
  hasMadeFullPayment: PropTypes.any,
  isLocked: PropTypes.any,
  refreshCallback: PropTypes.any,
  remainingAmount: PropTypes.any,
  showClientConnect: PropTypes.any,
  showProtools: PropTypes.any,
};

export default MembershipBenefit;
